import React from 'react'
import { BusinessUserState } from '#src/generated/types'
import {
  BusinessUserForDeactivatedExpertChatBannerFragment,
  PlaceholderExpertForDeactivatedExpertChatBannerFragment
} from './DeactivatedExpertChatBanner.generated'
import styles from './styles.css'

interface DeactivatedExpertChatBannerProps {
  conversationId: string
  deactivatedExpert: BusinessUserForDeactivatedExpertChatBannerFragment
  placeholderExpert?: PlaceholderExpertForDeactivatedExpertChatBannerFragment
}

const DeactivatedExpertChatBanner: React.FC<
  React.PropsWithChildren<DeactivatedExpertChatBannerProps>
> = ({ deactivatedExpert }) => {
  if (deactivatedExpert.state === BusinessUserState.DEACTIVATED) {
    return (
      <div className={styles.messageList}>
        <div className={styles.notAvailable}>
          {deactivatedExpert.displayName} is no longer available
        </div>
      </div>
    )
  }

  return null
}

export default DeactivatedExpertChatBanner
