import React from 'react'
import { TouchScrollable } from 'react-scrolllock'
import numeral from 'numeral'
import { getFilteredLineItems, groupLineItems } from '#src/app/services/cart'
import { useBlackFridayPromotion } from '#src/app/hooks/useBlackFridayPromotion'
import TrialInfoBox from '#src/app/components/TrialInfoBox'
import { OrderUserMessageForOrderMessagesFragment } from '#src/app/components/OrderMessages/OrderMessages.generated'
import OrderMessages from '#src/app/components/OrderMessages'
import { OrderUserMessageForCartOutOfStockFragment } from '../OutOfStock/OutOfStock.generated'
import { OrderForLoggedInCartFragment } from '../../ShoppingCart.generated'
import ProductLineItemGroup from '../ProductLineItemGroup'
import OutOfStock from '../OutOfStock'
import OrderPromotionMessage from '../OrderPromotionMessage'
import Footer from '../Footer'
import EmptyCart from '../EmptyCart'
import { CreditProgressTracker } from '../../../../CreditProgressTracker'
import InfoIcon from './info.svg'
import styles from './styles.css'

interface OrderLineItemsAndSummaryProps {
  order: OrderForLoggedInCartFragment
}

const OrderLineItemsAndSummary: React.FC<OrderLineItemsAndSummaryProps> = ({ order }) => {
  const flashSaleCreditTrackerTermsPath = useBlackFridayPromotion()?.termsPath

  const filteredLineItems = getFilteredLineItems(order.lineItems)

  const groupedLineItems = groupLineItems({ lineItems: filteredLineItems })

  const oosOrderMessages: OrderUserMessageForCartOutOfStockFragment[] = []
  const otherOrderMessages: OrderUserMessageForOrderMessagesFragment[] = []

  order.orderUserMessages.forEach(message => {
    if (
      message.__typename === 'AdHocSellableNotAvailableOrderUserMessage' ||
      message.__typename === 'SellableNotAvailableOrderUserMessage'
    ) {
      oosOrderMessages.push(message)
    } else {
      otherOrderMessages.push(message)
    }
  })

  // if any line item is a trial fee, the entire order is a trial order
  const isOrderForTrial = order.lineItems.some(item => item.source.type === 'TRIAL_FEE')

  return (
    <div className={styles.content}>
      {(order.appliedPromotionEntry ||
        order.notEligibleForPromotionReason ||
        otherOrderMessages.length > 0) && (
        <div className={styles.fixedHeader}>
          {(order.appliedPromotionEntry || order.notEligibleForPromotionReason) && (
            <CreditProgressTracker order={order} termsPath={flashSaleCreditTrackerTermsPath} />
          )}
          {order.notEligibleForPromotionReason && (
            <OrderPromotionMessage reason={order.notEligibleForPromotionReason} />
          )}
          {otherOrderMessages.length > 0 && <OrderMessages messages={otherOrderMessages} />}
        </div>
      )}
      <TouchScrollable>
        <div className={styles.items}>
          {filteredLineItems.length > 0 ? (
            <div>
              {isOrderForTrial && (
                <div className={styles.trialNotice}>
                  <InfoIcon className={styles.infoIcon} />
                  <span>
                    Deposit required: {numeral(order.subtotal.amount).format('$0,0.00')} + shipping
                    and tax
                  </span>
                </div>
              )}

              <div className={styles.lineItems}>
                {groupedLineItems.map(group => (
                  <ProductLineItemGroup
                    key={group.parentLineItem.id}
                    orderId={order.id}
                    group={group}
                    hasAppliedPromotion={!!order.appliedPromotionEntry?.applied}
                  />
                ))}
              </div>

              {isOrderForTrial && (
                <div className={styles.trialInfoBoxContainer}>
                  <TrialInfoBox />
                </div>
              )}
            </div>
          ) : (
            <EmptyCart />
          )}

          <OutOfStock messages={oosOrderMessages} testId="cart-out-of-stock" />
        </div>
      </TouchScrollable>
      {filteredLineItems.length > 0 && <Footer order={order} />}
    </div>
  )
}

export default OrderLineItemsAndSummary
