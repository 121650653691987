import { HandleType } from '#src/generated/types'
import { formatPhoneNumber } from '#src/app/services/phone'
import handleTypeFromHandle from './handleTypeFromHandle'

export default function formatHandle(handle: string): string {
  const handleType = handleTypeFromHandle(handle)

  if (handleType === HandleType.PHONE) {
    return formatPhoneNumber(handle)
  }

  return handle
}
