type GridSpecifications = (
  | {
      [area: string]: number
    }
  | null
  | false
)[]

export function generateGrid(specifications: GridSpecifications) {
  let grid = ''

  specifications.forEach(specification => {
    if (!specification) return

    let gridRow = ''

    for (const areaLabel in specification) {
      if (gridRow !== '') {
        gridRow += ' '
      }

      const areaString = new Array(specification[areaLabel]).fill(areaLabel).join(' ')

      gridRow += areaString
    }

    grid += `"${gridRow}"\r`
  })

  return grid
}
