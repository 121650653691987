import React from 'react'
import { PageKeyPriority, Route, Switch } from '@deal/router'
import { NotFound } from '#src/app/components/Errors'
import FeedPage from './ForYouPage'
import FeedNotificationPage from './FeedNotificationPage'
import FeedDetailPage from './FeedDetailPage'

const ForYouRoute: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Switch>
      <Route
        path="/for-you/notifications"
        pageKey="for-you-notifications-page"
        priority={PageKeyPriority.PAGE}
        component={FeedNotificationPage}
      />
      <Route
        path="/for-you/:feedPostId"
        pageKey="for-you-detail-page"
        priority={PageKeyPriority.PAGE}
        render={({ match }) => {
          const feedPostId = match.params.feedPostId
          if (feedPostId) {
            return <FeedDetailPage feedPostId={feedPostId} />
          } else {
            return <NotFound />
          }
        }}
      />
      <Route path="/for-you" component={FeedPage} />
    </Switch>
  )
}
export { ForYouRoute }
