import React, { useEffect, useRef, useState } from 'react'
import { useOnScreenCallbackOnce } from '@deal/dom-hooks'

type AITextProps = {
  text: string
}

const AIText: React.FC<AITextProps> = ({ text }) => {
  const ref = useRef<HTMLSpanElement>(null)
  const [displayText, setDisplayText] = useState('')
  const interval = useRef<NodeJS.Timer>()

  useOnScreenCallbackOnce({
    ref: ref,
    callback: () => {
      let i = 0
      interval.current = setInterval(() => {
        setDisplayText(text.slice(0, i++))
        if (i > text.length) {
          clearInterval(interval.current)
        }
      }, 20)
    }
  })

  useEffect(() => () => clearInterval(interval.current), [])
  return <span ref={ref}>{displayText}</span>
}

const KeySpecificAIText: React.FC<AITextProps> = ({ text }) => {
  return <AIText key={text} text={text} />
}

export { KeySpecificAIText as AIText }
