import React, { useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Button } from '@deal/components'
import { BrandLogo } from '@deal/bluxome'
import { ExpertApplicationState } from '#src/generated/types'
import { IdentityContext } from '#src/app/containers/Identity'
import config from '#src/app/config'
import styles from './styles.css'

interface Props {}

const ExpertProfileDraftFullPageTakeover: React.FC<React.PropsWithChildren<Props>> = () => {
  const { myself } = useContext(IdentityContext)
  const businessUser = myself && myself.user.assignedBusinessUsers[0]

  // we currently have v1 ramped down to 0% so we can likely remove this next time it is touched
  // unless we want to update for the v1+ hiring flows
  useEffect(() => {
    setIsOpen(
      businessUser?.expertApplication?.state === ExpertApplicationState.PENDING &&
        businessUser?.expertApplication?.hiringFlow?.slug === 'v1' &&
        (!businessUser.expertProfile || businessUser.expertProfile.isDraft)
        ? true
        : false
    )
  }, [])

  const [isOpen, setIsOpen] = useState(false)

  const { protocol, host, port } = config.get('business')

  const takeover = (
    <div className={styles.takeover}>
      <Button className={styles.button} variant="secondary-link" onClick={() => setIsOpen(false)}>
        Continue to Curated.com
      </Button>
      <div className={styles.content}>
        <BrandLogo color="black" />
        <div className={styles.break} />
        <div className={styles.title}>Time to set up your expert profile </div>
        <div className={styles.message}>
          Nice work on your expert application! The next step is to create your expert profile.
          Click the button below to get started
        </div>
        <a href={`${protocol}://${host}:${port}/profile/setup/introduction`}>
          <Button>Create my expert profile</Button>
        </a>
      </div>
    </div>
  )

  return isOpen ? ReactDOM.createPortal(takeover, document.querySelector('#deal') as Element) : null
}

export default ExpertProfileDraftFullPageTakeover
