// @ts-nocheck
import type * as Types from '../../../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { BusinessUserForAskAboutVisualFragmentDoc } from '../../../../../../../components/AskAboutVisual/AskAboutVisual.generated';
import { LeadForCategoryPageHeroWithLeadCtaFragmentDoc } from '../HeroWithLeadCta/HeroWithLeadCta.generated';
import { BusinessUserForAnnotationWithExpertAvatarFragmentDoc } from '../../../../../../../components/AnnotationWithExpertAvatar/AnnotationWithExpertAvatar.generated';
import { BusinessUserForExpertForAvatarWithPopoverFragmentDoc, BusinessUserForExpertForAvatarFragmentDoc } from '../../../../../../../services/avatars/BusinessUserForExpertForAvatar.generated';
import { BusinessUserForGetExpertExpertiseFragmentDoc, BusinessUserForGetExpertProfileLinkFragmentDoc } from '../../../../../../../services/expert/expert.generated';
import { LeadForCategoryPageHeroWithLeadHeaderFragmentDoc, BrowseCategoryPageViewModelForCategoryPageHeroWithLeadHeaderFragmentDoc } from '../HeroWithLeadHeader/HeroWithLeadHeader.generated';
import { CategoryForGetAskAboutAssetsFragmentDoc, CategoryForGetAskGenericImageFragmentDoc } from '../../../../../../../components/AskAboutVisual/assets/AskAboutAssets.generated';
export type LeadForCategoryPageHeroWithLeadWithoutNeedsFragment = { __typename: 'Lead', id: any, expert: { __typename: 'BusinessUser', id: any, shortName: string, displayName: string, vanityId?: string | null, state: Types.BusinessUserState, expertProfile?: { __typename: 'ExpertProfile', id: any, headline: string, background: string, backgroundSummaryLines: Array<string>, profilePhoto?: { __typename: 'MediaFile', id: any, url: string } | null } | null, expertAttributes: { __typename: 'BusinessUserExpertAttributes', category: { __typename: 'Category', id: any, slug: string, singularDisplayName: string, department?: { __typename: 'Department', id: any, type: Types.DepartmentType } | null, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, expert?: { __typename: 'CategoryAssetNoun', singular: string } | null } | null }, specialtyCategory?: { __typename: 'Category', id: any, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, expert?: { __typename: 'CategoryAssetNoun', singular: string } | null } | null } | null }, expertStats?: { __typename: 'ExpertStats', totalReviews: number, totalLeadsParticipated: number, averageRating?: number | null } | null, articleAuthorProfile?: { __typename: 'ExpertProfile', id: any, headline: string } | null } };

export type BrowseCategoryPageViewModelForCategoryPageHeroWithLeadWithoutNeedsFragment = { __typename: 'BrowseCategoryPageViewModel', category: { __typename: 'Category', id: any, pluralDisplayName: string, slug: string, department?: { __typename: 'Department', id: any, type: Types.DepartmentType } | null }, pageMetadata?: { __typename: 'CategoryPageMetadata', pageTitle: string } | null };

export const LeadForCategoryPageHeroWithLeadWithoutNeedsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LeadForCategoryPageHeroWithLeadWithoutNeeds"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Lead"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"expert"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForAskAboutVisual"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"LeadForCategoryPageHeroWithLeadCta"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"LeadForCategoryPageHeroWithLeadHeader"}}]}}]} as unknown as DocumentNode;
export const BrowseCategoryPageViewModelForCategoryPageHeroWithLeadWithoutNeedsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BrowseCategoryPageViewModelForCategoryPageHeroWithLeadWithoutNeeds"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BrowseCategoryPageViewModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"category"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CategoryForGetAskAboutAssets"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"CategoryForGetAskGenericImage"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"BrowseCategoryPageViewModelForCategoryPageHeroWithLeadHeader"}}]}}]} as unknown as DocumentNode;