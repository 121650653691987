/**
 * Validate a string as a phone number
 */
export function isValidPhoneNumber(phoneNumber: string) {
  return /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(phoneNumber)
}

/**
 * Format 10-digit US phone number, optionally prefixed with the "+1" country code. A more
 *   full-featured solution will be necessary if we want to support more international
 *   phone numbers. Non-US numbers are returned as provided (unformatted).
 */
export function formatPhoneNumber(phoneNumber: string) {
  const phoneNumberWithoutSpaces = phoneNumber.replace(/\s+/g, '')

  if (/^(\+1)?\d{10}$/.test(phoneNumberWithoutSpaces)) {
    const phoneNumberWithoutCountryCode = phoneNumber.replace(/^\+1/, '')

    const areaCode = phoneNumberWithoutCountryCode.substring(0, 3)
    const telephonePrefix = phoneNumberWithoutCountryCode.substring(3, 6)
    const lineNumber = phoneNumberWithoutCountryCode.substring(6)

    return `(${areaCode}) ${telephonePrefix}-${lineNumber}`
  }

  return phoneNumber
}
