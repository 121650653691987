import React, { ReactNode, createContext, useContext } from 'react'
import { useHistory } from 'react-router'
import { getSellableUrl } from '#src/app/services/sellable'
import { SellableUrlFragment } from '#src/app/fragments/SellableUrl.generated'

interface VariantSelectorContextProps {
  handleVariationSelected: (sellable: SellableUrlFragment) => void
}

const VariantSelectorContext = createContext<VariantSelectorContextProps | undefined>(undefined)

export const VariantSelectorProvider: React.FC<{
  onVariationSelected?: (sellable: SellableUrlFragment) => void
  children: ReactNode
}> = ({ onVariationSelected, children }) => {
  const {
    location: { search },
    replace: historyReplace
  } = useHistory()

  const replaceHistoryWithSellable = (sellable: SellableUrlFragment) => {
    const params = new URLSearchParams(search)
    params.set('ref', 'variation-matrix')
    params.delete('condition')
    historyReplace({
      pathname: getSellableUrl(sellable),
      search: params.toString(),
      state: {
        resetScroll: false
      }
    })
  }

  return (
    <VariantSelectorContext.Provider
      value={{ handleVariationSelected: onVariationSelected ?? replaceHistoryWithSellable }}
    >
      {children}
    </VariantSelectorContext.Provider>
  )
}

export const useVariantSelector = (): VariantSelectorContextProps => {
  const context = useContext(VariantSelectorContext)
  if (!context) {
    throw new Error('useVariantSelector must be used within a VariantSelectorProvider')
  }
  return context
}
