// @ts-nocheck
import type * as Types from '../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { CategoryForCategoryOrProductListingPageFragmentDoc } from '../../../../../services/search/CategoryForCategoryOrProductListingPage.generated';
import { MediaFileFragmentDoc } from '../../../../../fragments/MediaFile.generated';
export type CategoryForBrowseSubCategoryNavigationFragment = { __typename: 'Category', id: any, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, inventory?: { __typename: 'CategoryAssetNoun', plural: string } | null } | null };

export type CategorySubCategoryTileViewModelForBrowseSubCategoryNavigationFragment = { __typename: 'CategorySubCategoryTileViewModel', category: { __typename: 'Category', id: any, pluralDisplayName: string, slug: string, pageLayoutType: Types.CategoryPageLayoutType }, media?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null };

export const CategoryForBrowseSubCategoryNavigationFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CategoryForBrowseSubCategoryNavigation"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Category"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"categoryAssetRevision"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"inventory"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"plural"}}]}}]}}]}}]} as unknown as DocumentNode;
export const CategorySubCategoryTileViewModelForBrowseSubCategoryNavigationFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CategorySubCategoryTileViewModelForBrowseSubCategoryNavigation"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CategorySubCategoryTileViewModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"category"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"pluralDisplayName"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"CategoryForCategoryOrProductListingPage"}}]}},{"kind":"Field","name":{"kind":"Name","value":"media"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaFile"}}]}}]}}]} as unknown as DocumentNode;