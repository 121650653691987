// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
export type OrderUserMessageForOrderMessages_AdHocSellableNotAvailableOrderUserMessage_Fragment = { __typename: 'AdHocSellableNotAvailableOrderUserMessage', id: any, message: string };

export type OrderUserMessageForOrderMessages_AddonSellableQuantityOrderUserMessage_Fragment = { __typename: 'AddonSellableQuantityOrderUserMessage', id: any, message: string };

export type OrderUserMessageForOrderMessages_DefaultOrderUserMessage_Fragment = { __typename: 'DefaultOrderUserMessage', id: any, message: string };

export type OrderUserMessageForOrderMessages_SellableCustomizationNotAvailableOrderUserMessage_Fragment = { __typename: 'SellableCustomizationNotAvailableOrderUserMessage', id: any, message: string };

export type OrderUserMessageForOrderMessages_SellableLimitedQuantityOrderUserMessage_Fragment = { __typename: 'SellableLimitedQuantityOrderUserMessage', id: any, message: string };

export type OrderUserMessageForOrderMessages_SellableNotAvailableOrderUserMessage_Fragment = { __typename: 'SellableNotAvailableOrderUserMessage', id: any, message: string };

export type OrderUserMessageForOrderMessages_WishlistArchivedGiftCardOrderUserMessage_Fragment = { __typename: 'WishlistArchivedGiftCardOrderUserMessage', id: any, message: string };

export type OrderUserMessageForOrderMessages_WishlistArchivedSellableOrderUserMessage_Fragment = { __typename: 'WishlistArchivedSellableOrderUserMessage', id: any, message: string };

export type OrderUserMessageForOrderMessages_WishlistItemArchivedGiftCardOrderUserMessage_Fragment = { __typename: 'WishlistItemArchivedGiftCardOrderUserMessage', id: any, message: string };

export type OrderUserMessageForOrderMessages_WishlistItemArchivedSellableOrderUserMessage_Fragment = { __typename: 'WishlistItemArchivedSellableOrderUserMessage', id: any, message: string };

export type OrderUserMessageForOrderMessages_WishlistItemDeletedDuePurchasedSellableOrderUserMessage_Fragment = { __typename: 'WishlistItemDeletedDuePurchasedSellableOrderUserMessage', id: any, message: string };

export type OrderUserMessageForOrderMessages_WishlistItemQuantityChangedDuePurchasedSellableOrderUserMessage_Fragment = { __typename: 'WishlistItemQuantityChangedDuePurchasedSellableOrderUserMessage', id: any, message: string };

export type OrderUserMessageForOrderMessages_WishlistPrivateGiftCardOrderUserMessage_Fragment = { __typename: 'WishlistPrivateGiftCardOrderUserMessage', id: any, message: string };

export type OrderUserMessageForOrderMessages_WishlistPrivateSellableOrderUserMessage_Fragment = { __typename: 'WishlistPrivateSellableOrderUserMessage', id: any, message: string };

export type OrderUserMessageForOrderMessagesFragment = OrderUserMessageForOrderMessages_AdHocSellableNotAvailableOrderUserMessage_Fragment | OrderUserMessageForOrderMessages_AddonSellableQuantityOrderUserMessage_Fragment | OrderUserMessageForOrderMessages_DefaultOrderUserMessage_Fragment | OrderUserMessageForOrderMessages_SellableCustomizationNotAvailableOrderUserMessage_Fragment | OrderUserMessageForOrderMessages_SellableLimitedQuantityOrderUserMessage_Fragment | OrderUserMessageForOrderMessages_SellableNotAvailableOrderUserMessage_Fragment | OrderUserMessageForOrderMessages_WishlistArchivedGiftCardOrderUserMessage_Fragment | OrderUserMessageForOrderMessages_WishlistArchivedSellableOrderUserMessage_Fragment | OrderUserMessageForOrderMessages_WishlistItemArchivedGiftCardOrderUserMessage_Fragment | OrderUserMessageForOrderMessages_WishlistItemArchivedSellableOrderUserMessage_Fragment | OrderUserMessageForOrderMessages_WishlistItemDeletedDuePurchasedSellableOrderUserMessage_Fragment | OrderUserMessageForOrderMessages_WishlistItemQuantityChangedDuePurchasedSellableOrderUserMessage_Fragment | OrderUserMessageForOrderMessages_WishlistPrivateGiftCardOrderUserMessage_Fragment | OrderUserMessageForOrderMessages_WishlistPrivateSellableOrderUserMessage_Fragment;

export const OrderUserMessageForOrderMessagesFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"OrderUserMessageForOrderMessages"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"OrderUserMessage"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]} as unknown as DocumentNode;