// @ts-nocheck
import type * as Types from '../../../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { SellableForGetBluxomeProductTagsFromSellableFragmentDoc } from '../../../../../../../services/sellable/getBluxomeProductTagsFromSellable/getBluxomeProductTagsFromSellable.generated';
import { SellableForIsSellablePurchasableFragmentDoc, SellableForGetSellableLinkRelFragmentDoc } from '../../../../../../../services/sellable/Sellable.generated';
import { SellableForIsSellableAvailableForPreorderFragmentDoc } from '../../../../../../../services/sellable/isSellableAvailableForPreorder/isSellableAvailableForPreorder.generated';
import { SellableForIsSellableOnClearanceFragmentDoc } from '../../../../../../../services/sellable/isSellableOnClearance/isSellableOnClearance.generated';
export type ExpertCuratedItemForGetBluxomeProductTagsFromItemFragment = { __typename: 'ExpertCuratedItem', id: any, purchasable: boolean, sellable: { __typename: 'Sellable', id: any, inShortSupply: boolean, variantParent: boolean, active: boolean, areAllVariantsAvailableForPreOrder: boolean, areAllVariantsOutOfStock: boolean, purchasable: boolean, availableForPreOrder: boolean, metrics?: { __typename: 'SellableMetrics', totalPurchasesInLast30Days?: number | null } | null, topLists: Array<{ __typename: 'SellableTopListItemPosition', position: number }>, editorialTopLists: Array<{ __typename: 'SellableEditorialTopListItemPosition', position: number }>, expertiseScore?: { __typename: 'SellableExpertiseScore', quality: Types.SellableExpertiseFitQuality } | null, badges: Array<{ __typename: 'SellableBadgeWithDisplayName', badge: Types.SellableBadge }> } };

export const ExpertCuratedItemForGetBluxomeProductTagsFromItemFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ExpertCuratedItemForGetBluxomeProductTagsFromItem"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ExpertCuratedItem"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"purchasable"}},{"kind":"Field","name":{"kind":"Name","value":"sellable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForGetBluxomeProductTagsFromSellable"}}]}}]}}]} as unknown as DocumentNode;