import React, { useState } from 'react'
import { ConsumerTracking } from '@deal/web-tracking/types'
import { useLocation } from '@deal/router'
import { useExperiment, useExperimentClientContext } from '@deal/experiment-js'
import { setLandingPageKey, setRegistrationDepartment } from '#src/app/services/registrationValues'
import loggerClient from '#src/app/services/loggerClient'
import { useBlackFridayPromotion } from '#src/app/hooks/useBlackFridayPromotion'
import { EngagementChannelViewedEvent } from '#src/app/events/EngagementChannelViewedEvent'
import { EngagementChannelSubmittedEvent } from '#src/app/events/EngagementChannelSubmittedEvent'
import { EngagementChannelDismissedEvent } from '#src/app/events/EngagementChannelDismissedEvent'
import { useIdentityContext } from '#src/app/containers/Identity'
import { useAnalyticsContext } from '#src/app/containers/Analytics'
import { ArticleForConversationStartersV2Fragment } from './EngagementModal/ArticleConversationStarterTemplate/ConversationStarterTemplate.generated'
import {
  CategoryForEngagementChannelsFragment,
  ContentSelectionResultForEngagementChannelsFragment,
  SellableForEngagementChannelsFragment
} from './EngagementChannels.generated'
import EngagementChannelsHandler from './EngagementChannelsHandler'
import { EngagementChannelTrackingContextProvider } from './components/EngagementChannelTracking/index'

const FAREWELL_SALE_START_DATE = new Date('2025-02-10T08:01:00.000Z')

interface EngagementChannelsSharedProps {
  sellable?: SellableForEngagementChannelsFragment
  category?: CategoryForEngagementChannelsFragment
  pathSchemaSlug?: string
  articleConversationStarters?: ArticleForConversationStartersV2Fragment
}

interface EngagementChannelsProps extends EngagementChannelsSharedProps {
  contentSelectionResult: ContentSelectionResultForEngagementChannelsFragment
}

const EngagementChannels: React.FC<EngagementChannelsProps> = ({
  category,
  contentSelectionResult,
  ...props
}) => {
  const { myself } = useIdentityContext()
  const analytics = useAnalyticsContext()
  const { treatment: getExperimentTreatment } = useExperimentClientContext()
  const isBlackFridayModalDisabled = !useLocation().pathname.startsWith('/journal')

  const isFarewellSaleActive = useState(Date.now() > FAREWELL_SALE_START_DATE.valueOf())

  // If the black friday modal is enabled, disable all engagement channels
  const isBlackFridaySaleActive = !!useBlackFridayPromotion({ skip: isBlackFridayModalDisabled })
  const { result } = useExperiment({
    experiment: 'black-friday-2024-modal',
    defaultTreatment: 'control',
    options: { skip: !isBlackFridaySaleActive }
  })
  if (result === 'enabled') {
    return null
  }

  // As of 2025-02-10, the FarewellModal (going out of business sale) is active across the site,
  // so we won't ever render the EngagementModal
  if (isFarewellSaleActive) {
    return null
  }

  const { template, treatment, promotion, defaultPathSelection, rule, ruleV2 } =
    contentSelectionResult
  if (template?.pageComponentViews) {
    const variation = treatment || template?.pageComponentViews?.pageTemplate.name || ''
    const csrKeyword = rule?.keyword || ruleV2?.title
    const contentSelectionRuleExperiment = defaultPathSelection?.experiment
    return (
      <EngagementChannelTrackingContextProvider
        value={{
          engagementChannelViewed: (engagementChannel: ConsumerTracking.EngagementChannel) => {
            analytics?.track(
              new EngagementChannelViewedEvent({
                engagement_channel: engagementChannel,
                variation,
                category: category?.slug,
                trait: csrKeyword
              })
            )
          },
          engagementChannelDismissed: (engagementChannel: ConsumerTracking.EngagementChannel) => {
            analytics?.track(
              new EngagementChannelDismissedEvent({
                engagement_channel: engagementChannel,
                variation,
                category: category?.slug,
                trait: csrKeyword
              })
            )
          },
          engagementChannelSubmitted: (engagementChannel: ConsumerTracking.EngagementChannel) => {
            analytics?.track(
              new EngagementChannelSubmittedEvent({
                engagement_channel: engagementChannel,
                variation,
                category: category?.slug,
                trait: csrKeyword
              })
            )

            if (contentSelectionRuleExperiment) {
              getExperimentTreatment({
                experiment: `${contentSelectionRuleExperiment}-cta`,
                defaultTreatment: 'control'
              })
            }
          },
          setCampaignContext: () => {
            // We would only ever set this for guest users and if something is shown
            if (!myself) {
              /**
               * Set the landing page key so we can directly target a specific
               * creative on the campaign side
               */
              if (treatment) {
                setLandingPageKey(treatment)
              }

              /**
               * Set the registration department for user, this can be overridden in the
               * event they navigate to another page and register there.
               */
              const maybeDepartmentSlug = category?.department?.slug
              setRegistrationDepartment(maybeDepartmentSlug, true)
            }
          },
          trait: csrKeyword
        }}
      >
        <EngagementChannelsHandler
          variation={variation}
          category={category}
          promotion={promotion || template.pageComponentViews.pageTemplate.promotion}
          pathSchemaId={defaultPathSelection?.pathSchemaId}
          pageTemplateData={template.pageComponentViews}
          {...props}
        />
      </EngagementChannelTrackingContextProvider>
    )
  } else if (!template && treatment && treatment !== 'off' && treatment !== 'control') {
    loggerClient.captureError(
      new Error(
        'Invalid engagement channel experiment treatment for rule ' + (rule?.id || ruleV2?.id)
      ),
      {
        treatment
      }
    )
  }

  return null
}

export default EngagementChannels
