// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { MediaFileFragmentDoc } from '../../../fragments/MediaFile.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type BusinessUserForExpertTopBrandsFragment = { __typename: 'BusinessUser', id: any, expertProfile?: { __typename: 'ExpertProfile', id: any, profilePhoto?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null, favoriteBrands: Array<{ __typename: 'Brand', id: any, slug: string, logo?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null }> } | null, department: { __typename: 'Department', id: any, topLevelCategory: { __typename: 'Category', id: any, slug: string } } };

export type ExpertTopBrandsQueryVariables = Types.Exact<{
  query: Types.ExpertTopRecommendedBrandsInput;
}>;


export type ExpertTopBrandsQuery = { __typename: 'Query', expertTopRecommendedBrands: { __typename: 'ExpertTopRecommendedBrandsPayload', topRecommendedBrands: Array<{ __typename: 'ExpertTopRecommendedBrand', brand: { __typename: 'Brand', id: any, displayName: string, name: string, slug: string, logo?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null } }> } };

export const BusinessUserForExpertTopBrandsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BusinessUserForExpertTopBrands"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"expertProfile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"profilePhoto"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaFile"}}]}},{"kind":"Field","name":{"kind":"Name","value":"favoriteBrands"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}},{"kind":"Field","name":{"kind":"Name","value":"logo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaFile"}}]}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"department"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"topLevelCategory"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}}]}}]}}]}}]} as unknown as DocumentNode;
export const ExpertTopBrandsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ExpertTopBrands"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"query"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ExpertTopRecommendedBrandsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"expertTopRecommendedBrands"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"query"},"value":{"kind":"Variable","name":{"kind":"Name","value":"query"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"topRecommendedBrands"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"brand"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}},{"kind":"Field","name":{"kind":"Name","value":"logo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaFile"}}]}}]}}]}}]}}]}},...MediaFileFragmentDoc.definitions]} as unknown as DocumentNode;
export type ExpertTopBrandsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ExpertTopBrandsQuery, ExpertTopBrandsQueryVariables>, 'query'> & ({ variables: ExpertTopBrandsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ExpertTopBrandsComponent = (props: ExpertTopBrandsComponentProps) => (
      <ApolloReactComponents.Query<ExpertTopBrandsQuery, ExpertTopBrandsQueryVariables> query={ExpertTopBrandsDocument} {...props} />
    );
    

/**
 * __useExpertTopBrandsQuery__
 *
 * To run a query within a React component, call `useExpertTopBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpertTopBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertTopBrandsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useExpertTopBrandsQuery(baseOptions: Apollo.QueryHookOptions<ExpertTopBrandsQuery, ExpertTopBrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpertTopBrandsQuery, ExpertTopBrandsQueryVariables>(ExpertTopBrandsDocument, options);
      }
export function useExpertTopBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpertTopBrandsQuery, ExpertTopBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpertTopBrandsQuery, ExpertTopBrandsQueryVariables>(ExpertTopBrandsDocument, options);
        }
export function useExpertTopBrandsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ExpertTopBrandsQuery, ExpertTopBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExpertTopBrandsQuery, ExpertTopBrandsQueryVariables>(ExpertTopBrandsDocument, options);
        }
export type ExpertTopBrandsQueryHookResult = ReturnType<typeof useExpertTopBrandsQuery>;
export type ExpertTopBrandsLazyQueryHookResult = ReturnType<typeof useExpertTopBrandsLazyQuery>;
export type ExpertTopBrandsSuspenseQueryHookResult = ReturnType<typeof useExpertTopBrandsSuspenseQuery>;
export type ExpertTopBrandsQueryResult = Apollo.QueryResult<ExpertTopBrandsQuery, ExpertTopBrandsQueryVariables>;