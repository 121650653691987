import React from 'react'
import { Avatar, AvatarProps } from '@deal/components'
import { getExpertImageAltText } from '#src/app/services/expert'
import { ExpertForExpertAvatarLegacyFragment } from './ExpertAvatarLegacy.generated'

interface ExpertAvatarLegacy extends Omit<AvatarProps, 'src' | 'name'> {
  expert: ExpertForExpertAvatarLegacyFragment
  photoRequired?: boolean
}

const ExpertAvatarLegacy: React.FC<React.PropsWithChildren<ExpertAvatarLegacy>> = ({
  expert,
  photoRequired = false,
  htmlAttributes,
  ...rest
}) => {
  if (photoRequired && !expert.expertProfile?.profilePhoto?.url) {
    return null
  }

  return (
    <Avatar
      name={expert.displayName}
      src={expert.expertProfile?.profilePhoto?.url || undefined}
      htmlAttributes={Object.assign({ alt: getExpertImageAltText(expert) }, htmlAttributes)}
      {...rest}
    />
  )
}

export default ExpertAvatarLegacy
