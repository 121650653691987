import { useContext, useEffect, useState } from 'react'
import { ChatContext } from '@deal/chat-firebase'
import loggerClient from '#src/app/services/loggerClient'
import { useChatWidgetStateContext } from '../context/chat-widget-state'

export default function useSendMessage() {
  const { expandChatWidget } = useChatWidgetStateContext()
  const { client: chatClient, authenticatedUserId: authenticatedChatUserId } =
    useContext(ChatContext)
  const [isMessageSent, setIsMessageSent] = useState(false)
  const [messageText, setMessageText] = useState<string>()
  const [metadata, setMetadata] = useState<{ [key: string]: string }>()
  const [loading, setLoading] = useState(false)
  const [conversationId, setConversationId] = useState<string>()
  const [source, setSource] = useState<string>()

  // Send the message once the user is authenticated and the conversation has initialized
  useEffect(() => {
    if (authenticatedChatUserId && conversationId && messageText) {
      if (chatClient) {
        const conversation = chatClient.getConversation(conversationId)
        new Promise(resolve => {
          if (conversation.initialized) {
            resolve(undefined)
          } else {
            conversation.on('initialized', () => {
              resolve(undefined)
            })
          }
        }).then(() => {
          const messageToSend = conversation.createDraftMessage()
          messageToSend.addDraftPart('TEXT', {
            text: messageText
          })
          if (metadata) {
            messageToSend.metadata = metadata
          }
          conversation.sendMessage(messageToSend)
          setIsMessageSent(true)
          setMessageText(undefined)
          setMetadata(undefined)
          setLoading(false)
          expandChatWidget(`${source}-message-sent`)
          setSource(undefined)
        })
      } else {
        loggerClient.captureError(new Error('Attempted to send message without Chat Client'))
      }
    }
  }, [authenticatedChatUserId, conversationId, messageText])

  const sendMessage = (
    message: string,
    conversationId: string,
    source: string,
    metadata?: { [key: string]: string }
  ) => {
    setConversationId(conversationId)
    setMessageText(message)
    setLoading(true)
    setSource(source)
    setMetadata(metadata)
  }

  const resetIsMessageSent = () => setIsMessageSent(false)

  return { sendMessage, isMessageSent, resetIsMessageSent, loading }
}
