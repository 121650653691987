// @ts-nocheck
import type * as Types from '../../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { BusinessUserForDefaultPopupFragmentDoc } from './DefaultPopup/DefaultPopup.generated';
import { ExpertForExpertAvatarLegacyFragmentDoc } from '../../../../../../components/ExpertAvatarLegacy/ExpertAvatarLegacy.generated';
import { MediaFileFragmentDoc } from '../../../../../../fragments/MediaFile.generated';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type LeadForExpertPopupFragment = { __typename: 'Lead', id: any, expert: { __typename: 'BusinessUser', id: any, vanityId?: string | null, shortName: string, displayName: string, expertAttributes: { __typename: 'BusinessUserExpertAttributes', placeholderExpert: boolean }, expertProfile?: { __typename: 'ExpertProfile', id: any, headline: string, profilePhoto?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null } | null }, category: { __typename: 'Category', id: any, slug: string, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, modalHeaderImage?: { __typename: 'CategoryAssetImage', image: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } } | null } | null } };

export type SetConsumerInitialLeadActionForExpertPopupMutationVariables = Types.Exact<{
  leadId: Types.Scalars['LeadId']['input'];
  action: Types.ConsumerInitialLeadAction;
}>;


export type SetConsumerInitialLeadActionForExpertPopupMutation = { __typename: 'Mutation', setConsumerInitialLeadAction: { __typename: 'SetConsumerInitialLeadActionPayload', lead?: { __typename: 'Lead', id: any, conversation: { __typename: 'Conversation', id: any, externalId: string }, category: { __typename: 'Category', id: any, slug: string } } | null } };

export const LeadForExpertPopupFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LeadForExpertPopup"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Lead"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"expert"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"vanityId"}},{"kind":"Field","name":{"kind":"Name","value":"expertAttributes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"placeholderExpert"}}]}},{"kind":"Field","name":{"kind":"Name","value":"expertProfile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForDefaultPopup"}}]}},{"kind":"Field","name":{"kind":"Name","value":"category"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}},{"kind":"Field","name":{"kind":"Name","value":"categoryAssetRevision"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"modalHeaderImage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"image"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaFile"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export const SetConsumerInitialLeadActionForExpertPopupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetConsumerInitialLeadActionForExpertPopup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"leadId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LeadId"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"action"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ConsumerInitialLeadAction"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setConsumerInitialLeadAction"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"leadId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"leadId"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"action"},"value":{"kind":"Variable","name":{"kind":"Name","value":"action"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"lead"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"conversation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"externalId"}}]}},{"kind":"Field","name":{"kind":"Name","value":"category"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type SetConsumerInitialLeadActionForExpertPopupMutationFn = Apollo.MutationFunction<SetConsumerInitialLeadActionForExpertPopupMutation, SetConsumerInitialLeadActionForExpertPopupMutationVariables>;
export type SetConsumerInitialLeadActionForExpertPopupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetConsumerInitialLeadActionForExpertPopupMutation, SetConsumerInitialLeadActionForExpertPopupMutationVariables>, 'mutation'>;

    export const SetConsumerInitialLeadActionForExpertPopupComponent = (props: SetConsumerInitialLeadActionForExpertPopupComponentProps) => (
      <ApolloReactComponents.Mutation<SetConsumerInitialLeadActionForExpertPopupMutation, SetConsumerInitialLeadActionForExpertPopupMutationVariables> mutation={SetConsumerInitialLeadActionForExpertPopupDocument} {...props} />
    );
    

/**
 * __useSetConsumerInitialLeadActionForExpertPopupMutation__
 *
 * To run a mutation, you first call `useSetConsumerInitialLeadActionForExpertPopupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetConsumerInitialLeadActionForExpertPopupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setConsumerInitialLeadActionForExpertPopupMutation, { data, loading, error }] = useSetConsumerInitialLeadActionForExpertPopupMutation({
 *   variables: {
 *      leadId: // value for 'leadId'
 *      action: // value for 'action'
 *   },
 * });
 */
export function useSetConsumerInitialLeadActionForExpertPopupMutation(baseOptions?: Apollo.MutationHookOptions<SetConsumerInitialLeadActionForExpertPopupMutation, SetConsumerInitialLeadActionForExpertPopupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetConsumerInitialLeadActionForExpertPopupMutation, SetConsumerInitialLeadActionForExpertPopupMutationVariables>(SetConsumerInitialLeadActionForExpertPopupDocument, options);
      }
export type SetConsumerInitialLeadActionForExpertPopupMutationHookResult = ReturnType<typeof useSetConsumerInitialLeadActionForExpertPopupMutation>;
export type SetConsumerInitialLeadActionForExpertPopupMutationResult = Apollo.MutationResult<SetConsumerInitialLeadActionForExpertPopupMutation>;
export type SetConsumerInitialLeadActionForExpertPopupMutationOptions = Apollo.BaseMutationOptions<SetConsumerInitialLeadActionForExpertPopupMutation, SetConsumerInitialLeadActionForExpertPopupMutationVariables>;