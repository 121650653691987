import React from 'react'
import { Area, Grid } from '@deal/bluxome'
import { Skeleton } from '#src/app/components/SkeletonLoader'
import styles from './styles.css'

export const Loader: React.FC = () => {
  return (
    <Grid rowGap={24}>
      <Area column="1 / -1" bleed="full">
        <Skeleton width="100%" height={210} />
      </Area>
      <Area column="1 / -1">
        <div className={styles.container}>
          {new Array(3).fill(null).map((_, i) => (
            <Skeleton key={i} width="100%" className={styles.reviewItem} />
          ))}
        </div>
      </Area>
      <Area column="1 / -1">
        <Skeleton width="100%" height={160} />
      </Area>
    </Grid>
  )
}
