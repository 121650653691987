// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { DisplayFacetFragmentDoc, BucketedDisplayFacetFragmentDoc, BucketedRangeDisplayFacetFragmentDoc, NumericRangeDisplayFacetFragmentDoc } from '../../fragments/Facets.generated';
export type SellablesPaginationForFilterListFragment = { __typename: 'SellablesPagination', count: number, maximumExceeded: boolean, facets: Array<{ __typename: 'BucketedDisplayFacet', displayName: string, name: string, alias?: string | null, buckets: Array<{ __typename: 'IndividualBucketedDisplayFacet', displayName: string, value: string, parentValue?: string | null, count?: any | null, seoShouldFollow: boolean }> } | { __typename: 'BucketedRangeDisplayFacet', displayName: string, name: string, alias?: string | null } | { __typename: 'NumericRangeDisplayFacet', displayName: string, name: string, alias?: string | null, type: Types.NumericRangeDisplayFacetType, lower: any, upper: any, scale: number, upperCapped: boolean }> };

export type CategorySellablesPaginationForFilterListFragment = { __typename: 'CategorySellablesPagination', count: number, maximumExceeded: boolean, facets: Array<{ __typename: 'BucketedDisplayFacet', displayName: string, name: string, alias?: string | null, buckets: Array<{ __typename: 'IndividualBucketedDisplayFacet', displayName: string, value: string, parentValue?: string | null, count?: any | null, seoShouldFollow: boolean }> } | { __typename: 'BucketedRangeDisplayFacet', displayName: string, name: string, alias?: string | null } | { __typename: 'NumericRangeDisplayFacet', displayName: string, name: string, alias?: string | null, type: Types.NumericRangeDisplayFacetType, lower: any, upper: any, scale: number, upperCapped: boolean }> };

export const SellablesPaginationForFilterListFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellablesPaginationForFilterList"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SellablesPagination"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"facets"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"DisplayFacet"}}]}},{"kind":"Field","name":{"kind":"Name","value":"maximumExceeded"}}]}}]} as unknown as DocumentNode;
export const CategorySellablesPaginationForFilterListFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CategorySellablesPaginationForFilterList"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CategorySellablesPagination"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"facets"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"DisplayFacet"}}]}},{"kind":"Field","name":{"kind":"Name","value":"maximumExceeded"}}]}}]} as unknown as DocumentNode;