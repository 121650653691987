import React, { useEffect, useState } from 'react'
import { Button, Rounded, Text } from '@deal/bluxome'
import { CCPAConsentRequestedEvent } from '#src/app/events/CCPAConsentRequestedEvent'
import { CCPAConsentGrantedEvent } from '#src/app/events/CCPAConsentGrantedEvent'
import { CCPAConsentDeniedEvent } from '#src/app/events/CCPAConsentDeniedEvent'
import styles from './styles.css'

interface CCPAConsentBannerProps {}

const CCPAConsentBanner: React.FC<CCPAConsentBannerProps> = () => {
  // `undefined`: the component hasn't mounted yet, or the tracking client hasn't been initialized
  // `null`: the user has not responded to the consent banner yet
  // `true`: the user has explicitly opted-in
  // `false: the user has explicitly opted-out
  const [ccpaConsent, setCcpaConsent] = useState<boolean | null | undefined>(undefined)

  useEffect(() => {
    window.tracking?.ready(() => {
      setCcpaConsent(window.tracking?.getCCPAConsent())

      if (ccpaConsent !== null) {
        window.tracking?.track(new CCPAConsentRequestedEvent())
      }
    })
  })

  // Only show the banner if the user hasn't responded yet
  if (ccpaConsent !== null) {
    return null
  }

  return (
    <div className={styles.banner}>
      <Rounded
        corners="top"
        bgColor="neutrals-black"
        bgClassName={styles.bannerBackground}
        className={styles.bannerContent}
      >
        <div className={styles.language}>
          <Text style="small-tight" color="neutrals-white">
            We use cookies to enable essential site functionality, analytics and personalization.
            See our{' '}
            <a href="/legal/privacy-policy" target="_blank" className={styles.bannerLink}>
              privacy policy
            </a>
            .
          </Text>
        </div>
        <div className={styles.ctas}>
          <Button
            size="small"
            color="neutral-light"
            fullWidth={true}
            onPress={() => {
              window.tracking?.optInCCPA()
              window.tracking?.track(new CCPAConsentGrantedEvent())
              setCcpaConsent(window.tracking?.getCCPAConsent())
            }}
          >
            Accept
          </Button>
          <Button
            size="small"
            color="neutral-light"
            style="outline"
            isInverted={true}
            fullWidth={true}
            onPress={() => {
              window.tracking?.optOutCCPA()
              window.tracking?.track(new CCPAConsentDeniedEvent())
              setCcpaConsent(window.tracking?.getCCPAConsent())
            }}
          >
            Deny
          </Button>
        </div>
      </Rounded>
    </div>
  )
}

export default CCPAConsentBanner
