import React from 'react'
import { Area, Grid } from '@deal/bluxome'
import { Skeleton } from '#src/app/components/SkeletonLoader'
import styles from './styles.css'

export const HeroSkeleton: React.FC = () => {
  const areas = `
    "mobileCopy mobileCopy mobileCopy mobileCopy mobileCopy mobileCopy"
    "curation curation curation curation curation curation"
    "mobileCta mobileCta mobileCta mobileCta mobileCta mobileCta"
  `

  const areasMd = `
    "desktopCopyCta desktopCopyCta desktopCopyCta curation curation curation"
  `

  const areasLg = `
    "desktopCopyCta desktopCopyCta desktopCopyCta desktopCopyCta desktopCopyCta spacer curation curation curation curation curation curation"
  `

  const copy = (
    <>
      <div className={styles.heading}>
        <Skeleton height="62px" />
      </div>
      <div className={styles.subheading}>
        <Skeleton height="48px" />
      </div>
    </>
  )

  const cta = (
    <>
      <div className={styles.button}>
        <Skeleton height="44px" width="180px" />
      </div>
      <Skeleton height="40px" width="200px" />
    </>
  )

  return (
    <div>
      <Grid areas={areas} areasMd={areasMd} areasLg={areasLg}>
        <Area name="mobileCopy" hideAbove="md">
          {copy}
        </Area>
        <Area name="mobileCta" hideAbove="md">
          {cta}
        </Area>
        <Area name="desktopCopyCta" hideBelow="md">
          <div className={styles.heroContentDesktop}>
            {copy}
            {cta}
          </div>
        </Area>
        <Area name="curation">
          <Skeleton height="300px" width="100%" />
        </Area>
      </Grid>
    </div>
  )
}
