import { Request, Response } from 'express'
import addDays from 'date-fns/addDays'
import Cookie, { CookieSerializeOptions } from 'cookie'
import { CookieContextType, CookieOptions, DEFAULT_COOKIE_OPTIONS } from './index'

/**
 * Convert our internal "CookieOptions" type to the shape expected by the "cookie" package,
 *  and include some default cookie options.
 */
function getDefaultCookieSerializeOptions(options?: CookieOptions): CookieSerializeOptions {
  let expiresAt: Date | undefined

  // If a number is passed for the "expires" option, it is interpreted as "X days from now"
  if (typeof options?.expires === 'number') {
    expiresAt = addDays(new Date(), options.expires) as Date
  } else {
    expiresAt = options?.expires
  }

  const cookieSerializeOptions: CookieSerializeOptions = {
    expires: expiresAt,
    path: options?.path,
    domain: options?.domain,
    secure: options?.secure,
    sameSite: options?.sameSite,
    httpOnly: options?.httpOnly
  }

  return Object.assign({}, DEFAULT_COOKIE_OPTIONS, cookieSerializeOptions)
}

export default function ({ req, res }: { req: Request; res: Response }): CookieContextType {
  const header = req.header('Cookie')
  const cookies = header && Cookie.parse(header)

  return {
    getCookie: (cookieName: string) => {
      return cookies && cookies[cookieName.toLowerCase()]
    },

    /**
     * Note: This will generally not work as expected with HTTP response streaming.
     *
     * The return value (a boolean) indicates whether the Set-Cookie was successfully set.
     */
    setCookie: (cookieName, cookieValue, cookieOptions) => {
      if (!res.headersSent) {
        res.cookie(cookieName, cookieValue, getDefaultCookieSerializeOptions(cookieOptions))

        return true
      } else {
        return false
      }
    },

    removeCookie: (cookieName, cookieOptions) => {
      res.clearCookie(cookieName, getDefaultCookieSerializeOptions(cookieOptions))
    }
  }
}
