import React, { HTMLAttributes } from 'react'
import camelCase from 'lodash/camelCase'
import classnames from 'classnames'
import styles from './styles.css'

interface UnderlinedTextProps extends HTMLAttributes<HTMLSpanElement> {
  // Controls the alignment of the underline, relative to the text
  //   TODO: Should we use ('start' | 'end' | 'center') instead, and respect RTL text?
  align?: 'left' | 'right' | 'center'

  // Controls the thickness of the underline
  thickness?: number

  // Controls the color of the underline (defaults to inheriting the color of the text)
  underlineColor?: string
  className?: string
}

const UnderlinedText: React.FC<React.PropsWithChildren<UnderlinedTextProps>> = ({
  children,
  align = 'left',
  thickness = 2,
  underlineColor,
  className
}) => {
  return (
    <span className={classnames(styles.text, className)}>
      {children}
      <hr
        className={classnames(styles.underline, styles[camelCase(`align-${align}`)])}
        style={{
          height: `${thickness}px`,
          bottom: `-${thickness}px`,
          backgroundColor: underlineColor
        }}
      />
    </span>
  )
}

export default UnderlinedText
