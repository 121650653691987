import React from 'react'
import { toast } from 'react-toastify'
import { ButtonProps } from '@deal/components'
import { useSetSellableStockAlertMutation } from '#src/app/mutations/SetSellableStockAlert.generated'
import { useIdentityContext } from '#src/app/containers/Identity'
import SellableStockAlertButtonBase from '#src/app/components/SellableStockAlertButtonBaseLegacy'
import { SellableForSellableStockAlertButtonLegacyFragment } from './SellableStockAlertButton.generated'

interface SellableStockAlertButtonProps {
  sellable: SellableForSellableStockAlertButtonLegacyFragment
  isCartPlacement?: boolean
  disabled?: boolean
  className?: string
  size?: ButtonProps['size']
}

const SellableStockAlertButton: React.FC<SellableStockAlertButtonProps> = ({
  sellable,
  disabled,
  ...buttonProps
}) => {
  const { myself } = useIdentityContext()

  const [setAlert, { loading }] = useSetSellableStockAlertMutation({
    onCompleted: ({ setSellableStockAlert: { error } }) => {
      if (error) {
        toast.error(error.errorMessage)
      }
    },
    onError: () => toast.error('Uh oh. Something went wrong. Please try again.')
  })

  if (myself) {
    return (
      <SellableStockAlertButtonBase
        sellable={sellable}
        onSetAlert={() =>
          setAlert({ variables: { sellableId: sellable.id, subscriberId: myself.id } })
        }
        disabled={loading || disabled}
        {...buttonProps}
      />
    )
  }
  return null
}

export default SellableStockAlertButton
