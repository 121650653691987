import React, { useRef, useState } from 'react'
import { useElementInteractionTracking } from '@deal/dom-hooks'
import { Modal, RemoveButton, Rounded, RoundedImage, Text } from '@deal/bluxome'
import { useProductComparisonContext } from '#src/app/context/product-comparison'
import SellableLink from '#src/app/components/SellableLink'
import DefaultProductImage from '#src/app/assets/images/default-product.png'
import { useSellablesForProductComparisonModalQuery } from './ProductComparisonModal.generated'
import SearchIcon from './search.svg'
import styles from './styles.css'

export const ProductComparisonModal: React.FC = () => {
  // useElementInteractionTracking requires a ref, but it's not used for the click tracking
  const dummyRef = useRef<HTMLElement>(null)
  const { trackElementClickedEvent } = useElementInteractionTracking({
    ref: dummyRef,
    name: 'compare-modal-cta'
  })
  const [placeholderImages, setPlaceholderImages] = useState<(string | undefined)[]>([])
  const {
    sellableIds,
    removeAllSellableIds,
    removeSellableId,
    exceedsFourSellables,
    overlayProps,
    compareModalState,
    compareRouteLocation
  } = useProductComparisonContext()
  const { isOpen: isCompareModalOpen, close: closeCompareModal } = compareModalState

  const {
    data: newData,
    previousData,
    loading
  } = useSellablesForProductComparisonModalQuery({
    variables: { sellableIds },
    onCompleted: ({ sellables }) =>
      setPlaceholderImages(sellables.map(sellable => sellable.primaryImage?.url)),
    skip: !isCompareModalOpen
  })
  const data = newData || previousData
  const sellables = data?.sellables

  const placeholderCount = 4 - (sellables?.length || 0)
  const notEnoughSellablesToCompare = sellables && sellables.length <= 1

  return (
    <Modal
      state={compareModalState}
      width="large"
      title="Product Comparison"
      overlayProps={overlayProps}
      actions={{
        color: 'neutral-dark',
        primaryAction: {
          label: 'Compare products',
          link: {
            to: compareRouteLocation,
            onClick: () => {
              trackElementClickedEvent()
              closeCompareModal()
            }
          },
          isDisabled: !sellables || notEnoughSellablesToCompare || exceedsFourSellables || loading
        },
        secondaryAction: {
          label: 'Clear all',
          onPress: () => {
            removeAllSellableIds()
            closeCompareModal()
          },
          isDisabled: !sellableIds || sellableIds.length === 0 || loading
        }
      }}
    >
      <div className={styles.container}>
        <div className={styles.copyContainer}>
          <Text
            style="large-semibold"
            color={exceedsFourSellables ? 'feedback-critical-400' : 'neutrals-black'}
          >{`${exceedsFourSellables ? '5' : sellableIds?.length || 0}/4 items`}</Text>
          <Text color="neutrals-600">
            {!sellableIds || sellableIds.length < 2
              ? 'To compare products you need 2 items minimum'
              : sellableIds.length >= 4
              ? 'Delete a product to add a new one to compare'
              : 'Select up to 4 products to compare'}
          </Text>
        </div>
        {sellables?.map((sellable, idx) => (
          <div
            key={sellable.id}
            className={styles.imageContainer}
            style={{ gridArea: `image${idx}` }}
          >
            <SellableLink sellable={sellable} onClick={() => closeCompareModal()}>
              <RoundedImage
                imageProps={{
                  src: sellable.primaryImage?.url || DefaultProductImage,
                  size: '150px',
                  lazyLoad: false,
                  className: styles.image,
                  htmlAttributes: {
                    alt: sellable.title
                  }
                }}
                aspectRatio="1/1"
                objectFit="contain"
                roundedProps={{ bgColor: 'neutrals-100' }}
              />
            </SellableLink>
            <div className={styles.removeButtonContainer}>
              <RemoveButton
                color="neutral-light"
                style="outline"
                onPress={() => {
                  removeSellableId(sellable.id)
                  if (!sellableIds || sellableIds.length <= 1) {
                    closeCompareModal()
                  }
                }}
                aria-label="Remove item from compare table"
              />
            </div>
          </div>
        ))}
        {new Array(placeholderCount).fill(null).map((_, idx) => {
          // attempt to show a sellable image while the modal closes
          const placeholderImgUrl = !sellables && placeholderImages.at(idx)
          return (
            <div
              key={placeholderImgUrl || idx}
              className={styles.imageContainer}
              style={{ gridArea: `image${idx + (sellables?.length || 0)}` }}
            >
              {placeholderImgUrl ? (
                <RoundedImage
                  imageProps={{
                    src: placeholderImgUrl,
                    size: '150px',
                    lazyLoad: false
                  }}
                  aspectRatio="1/1"
                  objectFit="contain"
                  roundedProps={{ bgColor: 'neutrals-300' }}
                />
              ) : (
                <Rounded borderColor="neutrals-300" borderWidth={1.5} borderStyle="dashed">
                  <div className={styles.searchIconContainer}>
                    <SearchIcon className={styles.searchIcon} />
                  </div>
                </Rounded>
              )}
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
