// @ts-nocheck
import type * as Types from '../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { FacetSelectionPayloadFragmentDoc } from '../../../../../fragments/FacetSelectionPayload.generated';
export type SellableSearchPageLinkArticleRevisionFragment = { __typename: 'ArticleRevision', articleId: any, articleRevisionId: any, articleFriendlyId: string, articleTitle: string, articleSlug: string };

export type SellableSearchPageLinkTopListFragment = { __typename: 'SellableTopList', consumerUrl: string, sellableTopListId: any, sellableTopListFriendlyId: any, sellableTopListTitle: string, sellableTopListSlug: string };

export type SellableSearchPageLinkSellableEditorialTopListRevisionFragment = { __typename: 'SellableEditorialTopListRevision', sellableEditorialTopListRevisionId: any, sellableEditorialTopListFriendlyId: any, sellableEditorialTopListSlug: string, sellableEditorialTopListTitle: string };

export type SellableSearchPageLinkRelatedSearchFragment = { __typename: 'SellableSearchPageRelatedSearch', relatedSearchTitle: string, category: { __typename: 'Category', id: any, slug: string }, facets: Array<{ __typename: 'FacetSelectionPayload', name: string, alias?: string | null, displayName: string, bucketValues?: Array<string> | null, lower?: any | null, upper?: any | null }> };

export type SellableSearchPageRelatedLinksForRelatedSearchesFragment = { __typename: 'SellableSearchPageRelatedLinks', title: string, type: Types.SellableSearchPageRelatedLinksType, items: Array<{ __typename: 'ArticleRevision', articleId: any, articleRevisionId: any, articleFriendlyId: string, articleTitle: string, articleSlug: string } | { __typename: 'SellableEditorialTopListRevision', sellableEditorialTopListRevisionId: any, sellableEditorialTopListFriendlyId: any, sellableEditorialTopListSlug: string, sellableEditorialTopListTitle: string } | { __typename: 'SellableSearchPageRelatedSearch', relatedSearchTitle: string, category: { __typename: 'Category', id: any, slug: string }, facets: Array<{ __typename: 'FacetSelectionPayload', name: string, alias?: string | null, displayName: string, bucketValues?: Array<string> | null, lower?: any | null, upper?: any | null }> } | { __typename: 'SellableTopList', consumerUrl: string, sellableTopListId: any, sellableTopListFriendlyId: any, sellableTopListTitle: string, sellableTopListSlug: string }> };

export const SellableSearchPageLinkArticleRevisionFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableSearchPageLinkArticleRevision"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ArticleRevision"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"articleId"}},{"kind":"Field","alias":{"kind":"Name","value":"articleRevisionId"},"name":{"kind":"Name","value":"id"}},{"kind":"Field","alias":{"kind":"Name","value":"articleFriendlyId"},"name":{"kind":"Name","value":"friendlyId"}},{"kind":"Field","alias":{"kind":"Name","value":"articleTitle"},"name":{"kind":"Name","value":"title"}},{"kind":"Field","alias":{"kind":"Name","value":"articleSlug"},"name":{"kind":"Name","value":"slug"}}]}}]} as unknown as DocumentNode;
export const SellableSearchPageLinkTopListFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableSearchPageLinkTopList"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SellableTopList"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"sellableTopListId"},"name":{"kind":"Name","value":"id"}},{"kind":"Field","alias":{"kind":"Name","value":"sellableTopListFriendlyId"},"name":{"kind":"Name","value":"friendlyId"}},{"kind":"Field","alias":{"kind":"Name","value":"sellableTopListTitle"},"name":{"kind":"Name","value":"title"}},{"kind":"Field","alias":{"kind":"Name","value":"sellableTopListSlug"},"name":{"kind":"Name","value":"slug"}},{"kind":"Field","name":{"kind":"Name","value":"consumerUrl"}}]}}]} as unknown as DocumentNode;
export const SellableSearchPageLinkSellableEditorialTopListRevisionFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableSearchPageLinkSellableEditorialTopListRevision"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SellableEditorialTopListRevision"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"sellableEditorialTopListRevisionId"},"name":{"kind":"Name","value":"id"}},{"kind":"Field","alias":{"kind":"Name","value":"sellableEditorialTopListFriendlyId"},"name":{"kind":"Name","value":"friendlyId"}},{"kind":"Field","alias":{"kind":"Name","value":"sellableEditorialTopListSlug"},"name":{"kind":"Name","value":"slug"}},{"kind":"Field","alias":{"kind":"Name","value":"sellableEditorialTopListTitle"},"name":{"kind":"Name","value":"title"}}]}}]} as unknown as DocumentNode;
export const SellableSearchPageLinkRelatedSearchFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableSearchPageLinkRelatedSearch"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SellableSearchPageRelatedSearch"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"relatedSearchTitle"},"name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"category"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}}]}},{"kind":"Field","name":{"kind":"Name","value":"facets"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FacetSelectionPayload"}}]}}]}}]} as unknown as DocumentNode;
export const SellableSearchPageRelatedLinksForRelatedSearchesFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableSearchPageRelatedLinksForRelatedSearches"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SellableSearchPageRelatedLinks"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ArticleRevision"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableSearchPageLinkArticleRevision"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SellableTopList"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableSearchPageLinkTopList"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SellableEditorialTopListRevision"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableSearchPageLinkSellableEditorialTopListRevision"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SellableSearchPageRelatedSearch"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableSearchPageLinkRelatedSearch"}}]}}]}}]}}]} as unknown as DocumentNode;