import React from 'react'
import { CircleLoader } from '@deal/components'
import styles from './styles.css'

interface ForYouLoaderProps {}

const ForYouLoader: React.FC<ForYouLoaderProps> = () => {
  return <CircleLoader className={styles.loader} />
}

export default ForYouLoader
