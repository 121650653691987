import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Text } from '@deal/bluxome'
import { useFindContactQuery } from '../../../FindContact.generated'
import { AuthenticatedUserFragment } from '../../../../../fragments/AuthenticatedUser.generated'
import { AuthIntent, AuthenticationFlowViewLayoutProps } from '../../../index'
import OAuthButtons from '../../../components/OAuthButtons'
import EmailOrPhoneLoginField from '../../../components/EmailOrPhoneLoginField/rebranded'
import AuthenticationFlowViewLayout from '../../../components/AuthenticationFlowViewLayout/rebranded'
import styles from './styles.css'

export interface LoginFormProps extends AuthenticationFlowViewLayoutProps {
  onIntentChanged: (intent: AuthIntent) => void
  onLogin: (user: AuthenticatedUserFragment) => void
  handleId?: string
}

export const LoginForm: React.FC<LoginFormProps> = ({
  onLogin,
  onIntentChanged,
  handleId,
  headline = 'Welcome Back',
  subHeadline = 'Login to access this page',
  ...props
}) => {
  const [googleAuthEnforced, setGoogleAuthEnforced] = useState(false)
  const { data, loading } = useFindContactQuery({
    variables: {
      contactId: handleId!
    },
    skip: !handleId
  })

  return (
    <AuthenticationFlowViewLayout headline={headline} subHeadline={subHeadline} {...props}>
      <EmailOrPhoneLoginField
        key={data?.findContact?.value}
        onIntentChanged={onIntentChanged}
        onLogin={onLogin}
        loading={loading}
        defaultHandle={data?.findContact?.value}
        onGoogleAuthEnforced={enforced => setGoogleAuthEnforced(enforced)}
      />
      {!googleAuthEnforced && (
        <div className={styles.or}>
          <hr />
          <Text>or</Text>
          <hr />
        </div>
      )}

      <OAuthButtons
        onFailure={toast.error}
        googleAuthOnly={googleAuthEnforced}
        onSuccess={(user: AuthenticatedUserFragment) => onLogin(user)}
        isRebranded
      />
    </AuthenticationFlowViewLayout>
  )
}
