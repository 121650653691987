import cookies from 'js-cookie'
import { DEFAULT_COOKIE_OPTIONS } from '#src/app/context/cookies'

/**
 * These methods are used purely to add values to the UserSource so we can
 * branch campaigns off of the data. These are not reliable sources of
 * user attribution.
 */

export const setRegistrationDepartment = (
  departmentSlug: string | null | undefined,
  override: boolean = false
) => {
  if (!departmentSlug) {
    return
  }

  /**
   * Only set this if the cookie isn't present or if we're overriding. The nature of
   * branching paths could otherwise result in us setting a registration department
   * slug that we don't want.
   */
  const shouldSetCookie = override || !cookies.get('curated_registration_department_slug')
  if (shouldSetCookie) {
    cookies.set('curated_registration_department_slug', departmentSlug, DEFAULT_COOKIE_OPTIONS)
  }
}

export const setLandingPageKey = (landingPageKey: string) => {
  cookies.set('curated_landing_page_key', landingPageKey, DEFAULT_COOKIE_OPTIONS)
}
