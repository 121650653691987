// @ts-nocheck
import type * as Types from '../../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { BusinessUserForExpertNameTagFragmentDoc } from '../../../../../../components/ExpertNameTag/ExpertNameTag.generated';
import { BusinessUserForExpertForAvatarWithPopoverFragmentDoc, BusinessUserForExpertForAvatarFragmentDoc } from '../../../../../../services/avatars/BusinessUserForExpertForAvatar.generated';
import { BusinessUserForGetExpertExpertiseFragmentDoc, BusinessUserForGetExpertProfileLinkFragmentDoc } from '../../../../../../services/expert/expert.generated';
export type ProductQuestionThemeForQuestionDetailsModalFragment = { __typename: 'ProductQuestionTheme', displayName: string, questions: Array<{ __typename: 'ProductQuestion', id: any, questionText: string, theme: string, answers: Array<{ __typename: 'ProductAnswer', id: any, answerText: string, answeredBy: { __typename: 'BusinessUser', id: any, displayName: string, vanityId?: string | null, state: Types.BusinessUserState, expertProfile?: { __typename: 'ExpertProfile', id: any, background: string, backgroundSummaryLines: Array<string>, headline: string, profilePhoto?: { __typename: 'MediaFile', id: any, url: string } | null } | null, expertStats?: { __typename: 'ExpertStats', totalReviews: number, totalLeadsParticipated: number, averageRating?: number | null } | null, expertAttributes: { __typename: 'BusinessUserExpertAttributes', category: { __typename: 'Category', id: any, slug: string, singularDisplayName: string, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, expert?: { __typename: 'CategoryAssetNoun', singular: string } | null } | null }, specialtyCategory?: { __typename: 'Category', id: any, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, expert?: { __typename: 'CategoryAssetNoun', singular: string } | null } | null } | null }, articleAuthorProfile?: { __typename: 'ExpertProfile', id: any, headline: string } | null } }> }> };

export type ProductQuestionForQuestionDetailsModalFragment = { __typename: 'ProductQuestion', id: any, theme: string };

export const ProductQuestionThemeForQuestionDetailsModalFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ProductQuestionThemeForQuestionDetailsModal"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ProductQuestionTheme"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"questions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"questionText"}},{"kind":"Field","name":{"kind":"Name","value":"answers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"answerText"}},{"kind":"Field","name":{"kind":"Name","value":"answeredBy"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForExpertNameTag"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"theme"}}]}}]}}]} as unknown as DocumentNode;
export const ProductQuestionForQuestionDetailsModalFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ProductQuestionForQuestionDetailsModal"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ProductQuestion"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"theme"}}]}}]} as unknown as DocumentNode;