import { useHistory, useLocation , usePageKey } from '@deal/router'
import { relativeToAbsoluteUrl } from '#src/app/services/navigation'

interface PathPageTrackingCodes {
  originalPageUrl: string
  originalPageContext: string | null
  pageKey: string
}

export default function usePathPageTrackingCodes(): PathPageTrackingCodes {
  const pageKey = usePageKey()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const context = params.get('ctx') || params.get('k')
  const history = useHistory()
  const urlString = relativeToAbsoluteUrl(
    history.createHref({
      ...location,
      hash: undefined
    })
  )
  return {
    originalPageUrl: urlString,
    originalPageContext: context,
    pageKey: pageKey || ''
  }
}
