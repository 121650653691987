import React from 'react'
import { useLocation, useParams } from 'react-router'
import loadable from '@loadable/component'
import { useExperiment } from '@deal/experiment-js'
import usePreferredExpert from '#src/app/hooks/usePreferredExpert'
import { useIdentityContext } from '#src/app/containers/Identity'
import Page from '#src/app/components/Page'
import { Unauthorized } from '#src/app/components/Errors'
import {
  useFindSellableExpertiseRequestsForSystemCurationQuery,
  useSellableExpertiseRequestsForSystemCurationQuery
} from './SystemCuration.generated'
import { SystemCurationPageSkeleton } from './components/SystemCurationPageSkeleton'

const PageComponent = loadable(() => import(/* webpackChunkName: "system-curations" */ './page'), {
  resolveComponent: components => components.SystemCurationsPage
})

const PageComponentV2 = loadable(
  () => import(/* webpackChunkName: "system-curations" */ './pageV2'),
  {
    resolveComponent: components => components.SystemCurationsPage
  }
)

interface SystemCurationPageProps {}

export const SystemCurationPage: React.FC<SystemCurationPageProps> = () => {
  const location = useLocation()
  const { result, loading } = useExperiment({
    experiment: 'expertise-everywhere-v2',
    defaultTreatment: 'control'
  })

  const isV2 = result === 'on'
  const { myself } = useIdentityContext()
  const params = new URLSearchParams(location.search)
  const previewConsumerId = params.get('previewConsumerId')
  const loginRequired = params.get('loginRequired') === 'true'
  const ownerId = previewConsumerId || myself?.consumer?.id
  const redirectToLogin = loginRequired && !ownerId
  const { requestId } = useParams<{ requestId: string }>()
  const preferredExpertVanityId = usePreferredExpert()
  const queryV1 = useFindSellableExpertiseRequestsForSystemCurationQuery({
    variables: {
      filter: {
        ownerId
      },
      recommendedSellableInput: {},
      recommendedCountsInput: {},
      preferredExpertVanityId,
      first: myself ? 5 : 3
    },
    skip: redirectToLogin || loading || isV2
  })
  const queryV2 = useSellableExpertiseRequestsForSystemCurationQuery({
    variables: {
      filter: {
        ownerId
      },
      recommendedSellableInput: {},
      recommendedCountsInput: {},
      greatDealInput: {},
      preferredExpertVanityId,
      first: 3,
      requestId
    },
    skip: redirectToLogin || loading || !isV2
  })
  if (loading) {
    return null
  }

  if (redirectToLogin) {
    return <Unauthorized />
  }

  if (isV2) {
    return (
      <Page
        query={queryV2}
        pageKey="system-curations"
        seoTitle={undefined}
        seoDescription={undefined}
        seoIndexable={false}
        chat={true}
        expert={data => data.currentRequest.lead?.primaryExpert || undefined}
        category={undefined}
        department={undefined}
        sellable={undefined}
        pageComponent={PageComponentV2}
        loadingComponent={SystemCurationPageSkeleton}
        ogImageUrl={undefined}
        actionBarProps={{
          hideCompareButton: true
        }}
      />
    )
  } else {
    return (
      <Page
        query={queryV1}
        pageKey="system-curations"
        seoTitle={undefined}
        seoDescription={undefined}
        seoIndexable={false}
        chat={true}
        expert={data =>
          data.findSellableExpertiseRequests
            .map(request => request.lead?.primaryExpert)
            .filter(expert => !!expert)
            .at(0) || undefined
        }
        category={undefined}
        department={undefined}
        sellable={undefined}
        pageComponent={PageComponent}
        loadingComponent={SystemCurationPageSkeleton}
        ogImageUrl={undefined}
      />
    )
  }
}
