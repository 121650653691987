import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router'
import { createPath } from 'history'
import { atom, useAtom, useSetAtom } from '@deal/atomic-state'

const previousLocationAtom = atom<string>()
const currentPreviousLocationAtom = atom<{ current: string; previous?: string }>()

export const useUpdatePreviousLocationAtom = () => {
  const location = useLocation()
  const previousLocationRef = useRef<string>()
  const setPreviousLocation = useSetAtom(previousLocationAtom)
  const setCurrentPreviousLocation = useSetAtom(currentPreviousLocationAtom)
  useEffect(() => {
    const previousLocation = previousLocationRef.current
    const currentLocation = createPath(location)
    previousLocationRef.current = createPath(location)
    setPreviousLocation(previousLocation)
    setCurrentPreviousLocation({
      current: currentLocation,
      previous: previousLocation
    })
  }, [location])
}

export const usePreviousLocationAtom = () => useAtom(previousLocationAtom)
export const useCurrentPreviousLocationAtom = () => useAtom(currentPreviousLocationAtom)
