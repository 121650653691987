import React, { useContext } from 'react'
import { ConsumerTracking } from '@deal/web-tracking/types'

export type EngagementChannelTrackingContextType =
  | {
      engagementChannelViewed: (engagementChannel: ConsumerTracking.EngagementChannel) => void
      engagementChannelDismissed: (engagementChannel: ConsumerTracking.EngagementChannel) => void
      engagementChannelSubmitted: (engagementChannel: ConsumerTracking.EngagementChannel) => void
      setCampaignContext: () => void
      trait?: string
    }
  | undefined

const EngagementChannelTrackingContext =
  React.createContext<EngagementChannelTrackingContextType>(undefined)

const EngagementChannelTrackingContextProvider = EngagementChannelTrackingContext.Provider
const EngagementChannelTrackingContextConsumer = EngagementChannelTrackingContext.Consumer

const useEngagementChannelTrackingContext = () => {
  const engagementChannelTrackingContext = useContext(EngagementChannelTrackingContext)

  if (!engagementChannelTrackingContext) {
    throw new Error('Invoked EngagementChannelTrackingContext outside of provider')
  }

  return engagementChannelTrackingContext
}

export {
  EngagementChannelTrackingContextConsumer,
  EngagementChannelTrackingContextProvider,
  EngagementChannelTrackingContext,
  useEngagementChannelTrackingContext
}
