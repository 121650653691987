import React from 'react'
import RedFireIcon from './red-fire.svg'
import styles from './styles.css'

const ShortSupply: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className={styles.shortSupply}>
      <RedFireIcon className={styles.icon} />
      Only a few units left
    </div>
  )
}

export default ShortSupply
