// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ExpertForExpertChatContainerQueryVariables = Types.Exact<{
  vanityIdOrId: Types.Scalars['String']['input'];
}>;


export type ExpertForExpertChatContainerQuery = { __typename: 'Query', findExpert?: { __typename: 'BusinessUser', id: any, active: boolean, expertAttributes: { __typename: 'BusinessUserExpertAttributes', placeholderExpert: boolean } } | null };

export type BusinessUserForUseExpertChatFragment = { __typename: 'BusinessUser', id: any, active: boolean, expertAttributes: { __typename: 'BusinessUserExpertAttributes', placeholderExpert: boolean } };

export const BusinessUserForUseExpertChatFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BusinessUserForUseExpertChat"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"active"}},{"kind":"Field","name":{"kind":"Name","value":"expertAttributes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"placeholderExpert"}}]}}]}}]} as unknown as DocumentNode;
export const ExpertForExpertChatContainerDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ExpertForExpertChatContainer"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"vanityIdOrId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"findExpert"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"vanityIdOrId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"vanityIdOrId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForUseExpertChat"}}]}}]}},...BusinessUserForUseExpertChatFragmentDoc.definitions]} as unknown as DocumentNode;
export type ExpertForExpertChatContainerComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ExpertForExpertChatContainerQuery, ExpertForExpertChatContainerQueryVariables>, 'query'> & ({ variables: ExpertForExpertChatContainerQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ExpertForExpertChatContainerComponent = (props: ExpertForExpertChatContainerComponentProps) => (
      <ApolloReactComponents.Query<ExpertForExpertChatContainerQuery, ExpertForExpertChatContainerQueryVariables> query={ExpertForExpertChatContainerDocument} {...props} />
    );
    

/**
 * __useExpertForExpertChatContainerQuery__
 *
 * To run a query within a React component, call `useExpertForExpertChatContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpertForExpertChatContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertForExpertChatContainerQuery({
 *   variables: {
 *      vanityIdOrId: // value for 'vanityIdOrId'
 *   },
 * });
 */
export function useExpertForExpertChatContainerQuery(baseOptions: Apollo.QueryHookOptions<ExpertForExpertChatContainerQuery, ExpertForExpertChatContainerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpertForExpertChatContainerQuery, ExpertForExpertChatContainerQueryVariables>(ExpertForExpertChatContainerDocument, options);
      }
export function useExpertForExpertChatContainerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpertForExpertChatContainerQuery, ExpertForExpertChatContainerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpertForExpertChatContainerQuery, ExpertForExpertChatContainerQueryVariables>(ExpertForExpertChatContainerDocument, options);
        }
export function useExpertForExpertChatContainerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ExpertForExpertChatContainerQuery, ExpertForExpertChatContainerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExpertForExpertChatContainerQuery, ExpertForExpertChatContainerQueryVariables>(ExpertForExpertChatContainerDocument, options);
        }
export type ExpertForExpertChatContainerQueryHookResult = ReturnType<typeof useExpertForExpertChatContainerQuery>;
export type ExpertForExpertChatContainerLazyQueryHookResult = ReturnType<typeof useExpertForExpertChatContainerLazyQuery>;
export type ExpertForExpertChatContainerSuspenseQueryHookResult = ReturnType<typeof useExpertForExpertChatContainerSuspenseQuery>;
export type ExpertForExpertChatContainerQueryResult = Apollo.QueryResult<ExpertForExpertChatContainerQuery, ExpertForExpertChatContainerQueryVariables>;