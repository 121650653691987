const shouldEndInEs = (word: string) => {
  return ['ch', 's', 'sh', 'ss', 'x', 'z'].some(ending => word.endsWith(ending))
}

/**
 * **NOTE**: this does not handle all plural rules
 *
 * @param word The word to pluralize
 * @param count How many of the word exist
 * @param inclusive Whether to prefix with the number (e.g. 3 ducks)
 * @returns The word pluralized
 */
export const pluralize = (word: string, count: number, inclusive?: boolean) => {
  const prefix = inclusive ? `${count} ` : ''
  const plural = count === 1 ? '' : shouldEndInEs(word) ? 'es' : 's'
  return `${prefix}${word}${plural}`
}
