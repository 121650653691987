// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SellableForProductCardSaveToCurationButtonFragment = { __typename: 'Sellable', id: any, parent?: { __typename: 'Sellable', id: any } | null, savedSellable?: { __typename: 'SavedSellable', id: any, saved: boolean, savedReason?: Types.SavedSellableReason | null } | null, recentExpertCuratedItem?: { __typename: 'ExpertCuratedItem', id: any, sellable: { __typename: 'Sellable', id: any } } | null };

export type SaveSellableForReviewForProductCardSaveToCurationButtonMutationVariables = Types.Exact<{
  input: Types.SaveSellableForReviewInput;
}>;


export type SaveSellableForReviewForProductCardSaveToCurationButtonMutation = { __typename: 'Mutation', saveSellableForReview: { __typename: 'SaveSellableForReviewPayload', savedSellable?: { __typename: 'SavedSellable', id: any, sellable: { __typename: 'Sellable', id: any, savedSellable?: { __typename: 'SavedSellable', id: any, saved: boolean, savedReason?: Types.SavedSellableReason | null } | null } } | null, error?: { __typename: 'DefaultErrorMessage', errorMessage: string } | null } };

export const SellableForProductCardSaveToCurationButtonFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableForProductCardSaveToCurationButton"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Sellable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"parent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"savedSellable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"saved"}},{"kind":"Field","name":{"kind":"Name","value":"savedReason"}}]}},{"kind":"Field","name":{"kind":"Name","value":"recentExpertCuratedItem"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"sellable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode;
export const SaveSellableForReviewForProductCardSaveToCurationButtonDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SaveSellableForReviewForProductCardSaveToCurationButton"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SaveSellableForReviewInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"saveSellableForReview"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"savedSellable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"sellable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"savedSellable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"saved"}},{"kind":"Field","name":{"kind":"Name","value":"savedReason"}}]}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"error"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ErrorMessage"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"errorMessage"}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type SaveSellableForReviewForProductCardSaveToCurationButtonMutationFn = Apollo.MutationFunction<SaveSellableForReviewForProductCardSaveToCurationButtonMutation, SaveSellableForReviewForProductCardSaveToCurationButtonMutationVariables>;
export type SaveSellableForReviewForProductCardSaveToCurationButtonComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SaveSellableForReviewForProductCardSaveToCurationButtonMutation, SaveSellableForReviewForProductCardSaveToCurationButtonMutationVariables>, 'mutation'>;

    export const SaveSellableForReviewForProductCardSaveToCurationButtonComponent = (props: SaveSellableForReviewForProductCardSaveToCurationButtonComponentProps) => (
      <ApolloReactComponents.Mutation<SaveSellableForReviewForProductCardSaveToCurationButtonMutation, SaveSellableForReviewForProductCardSaveToCurationButtonMutationVariables> mutation={SaveSellableForReviewForProductCardSaveToCurationButtonDocument} {...props} />
    );
    

/**
 * __useSaveSellableForReviewForProductCardSaveToCurationButtonMutation__
 *
 * To run a mutation, you first call `useSaveSellableForReviewForProductCardSaveToCurationButtonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSellableForReviewForProductCardSaveToCurationButtonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSellableForReviewForProductCardSaveToCurationButtonMutation, { data, loading, error }] = useSaveSellableForReviewForProductCardSaveToCurationButtonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveSellableForReviewForProductCardSaveToCurationButtonMutation(baseOptions?: Apollo.MutationHookOptions<SaveSellableForReviewForProductCardSaveToCurationButtonMutation, SaveSellableForReviewForProductCardSaveToCurationButtonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveSellableForReviewForProductCardSaveToCurationButtonMutation, SaveSellableForReviewForProductCardSaveToCurationButtonMutationVariables>(SaveSellableForReviewForProductCardSaveToCurationButtonDocument, options);
      }
export type SaveSellableForReviewForProductCardSaveToCurationButtonMutationHookResult = ReturnType<typeof useSaveSellableForReviewForProductCardSaveToCurationButtonMutation>;
export type SaveSellableForReviewForProductCardSaveToCurationButtonMutationResult = Apollo.MutationResult<SaveSellableForReviewForProductCardSaveToCurationButtonMutation>;
export type SaveSellableForReviewForProductCardSaveToCurationButtonMutationOptions = Apollo.BaseMutationOptions<SaveSellableForReviewForProductCardSaveToCurationButtonMutation, SaveSellableForReviewForProductCardSaveToCurationButtonMutationVariables>;