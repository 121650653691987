import React from 'react'
import loadable from '@loadable/component'
import { FullPageLoader } from '@deal/components'
import Page from '#src/app/components/Page'
import Footer from '#src/app/components/Footer'
import { useSaleRouteQuery } from './page/SaleRoute.generated'

const PageComponent = loadable(() => import(/* webpackChunkName: "sale" */ './page'), {
  resolveComponent: page => page.SalePage
})

const SaleRoute: React.FC = () => {
  const query = useSaleRouteQuery()

  return (
    <>
      <Page
        query={query}
        pageComponent={PageComponent}
        chat={true}
        seoTitle="Curated Sale"
        seoDescription={() => undefined}
        seoIndexable
        expert={query.data?.me?.user.leads.at(0)?.expert || undefined}
        category={undefined}
        department={undefined}
        sellable={undefined}
        ogImageUrl={undefined}
        loadingComponent={() => <FullPageLoader />}
        pageKey="sale-page"
      />
      <Footer />
    </>
  )
}

export { SaleRoute }
