import React from 'react'
import { Heading } from '@deal/bluxome'
import { AIText } from '#src/app/routes/products/page/components/AIConversationStarters/AIText'
import { ConversationStartersForConversationStartersV2Fragment } from './ConversationStartersV2.generated'
import {
  ConversationStartersV2Textfield,
  ConversationStartersV2TextfieldProps
} from './ConversationStartersV2Textfield'
import { ConversationStartersV2Suggestions } from './ConversationStartersV2Suggestions'
import styles from './styles.css'

interface ConversationStartersV2Props extends ConversationStartersV2TextfieldProps {
  conversationStarters: ConversationStartersForConversationStartersV2Fragment
}

const ConversationStartersV2: React.FC<ConversationStartersV2Props> = ({
  conversationStarters,
  infoButton,
  ...rest
}) => {
  return (
    <div className={styles.container}>
      {conversationStarters.summary && (
        <Heading size="h4" as="h3" aria-label={conversationStarters.summary}>
          <AIText text={conversationStarters.summary} />
        </Heading>
      )}
      <div />
      <div className={styles.questions}>
        <ConversationStartersV2Suggestions conversationStarters={conversationStarters} {...rest} />
      </div>
      <ConversationStartersV2Textfield infoButton={infoButton} {...rest} />
    </div>
  )
}

export { ConversationStartersV2 }
