import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import { AttributeType } from '#src/generated/types'
import useCreateProductRecommendationPath from '#src/app/services/path/useCreateProductRecommendationPath'
import { TrackingCodes } from '#src/app/services/path/hooks/useCreateRequestPath'
import { formatPathUrl } from '#src/app/services/path'
import { useChatWidgetPathContext } from '#src/app/context/chatWidgetPath'
import { useGetLatestInferredCategorLazyQuery } from './ChatSuggestions.generated'

export const useProductPathRecommendationSuggestion = (placement: 'widget' | 'default') => {
  const history = useHistory()
  const { setPathUrl } = useChatWidgetPathContext()
  const [createPath, { loading }] = useCreateProductRecommendationPath({
    onPathCreated: ({ pathGraphFlow, pathGraphFlowSlug, nodeSlugOrId }) => {
      if (pathGraphFlow) {
        const pathURL = formatPathUrl({
          pathGraphFlowId: pathGraphFlow.pathGraphFlowId,
          nodeSlugOrId,
          pathGraphFlowSlug,
          pathPlacement: placement
        })
        switch (placement) {
          case 'default':
            history.push(pathURL)
            break

          case 'widget':
            setPathUrl(pathURL)
            break
        }
      } else {
        throw new Error('Error creating path for switch to real expert!')
      }
    },
    onError: ({ errorMessage }) => {
      toast.error(errorMessage)
      throw new Error(errorMessage)
    }
  })

  const [getLatestInferredCategory, { loading: getCategoryLoading }] =
    useGetLatestInferredCategorLazyQuery()

  const switchToProductPathRecommendation = (props: {
    trackingCodes: TrackingCodes
    leadId: string
  }) => {
    getLatestInferredCategory({
      variables: { leadId: props.leadId },
      onCompleted: data => {
        if (!data?.getLatestInferredCategory?.id) {
          toast.error('Error getting latest inferred category')
          return
        }
        createPath({
          pathSelector: { categoryIdHierarchyLookup: data.getLatestInferredCategory.id },
          trackingCodes: props.trackingCodes,
          initialAttributes: props.leadId
            ? [
                {
                  name: 'productRecommendationPathLeadId',
                  type: AttributeType.ID,
                  idValue: props.leadId
                }
              ]
            : []
        })
      }
    })
  }

  return { switchToProductPathRecommendation, loading: getCategoryLoading || loading }
}
