import numeral from 'numeral'
import { OrderLineItemSourceType } from '#src/generated/types'
import {
  OrderLineItemForGetLineItemBundleParentIdMapFragment,
  OrderLineItemForGetLineItemsBundleTitleFragment,
  ProductBundleRevisionForFindBundleWithLargestStartingPriceFragment,
  ProductBundleRevisionForFormatProductBundlePricingFragment
} from './ProductBundle.generated'
import { formatMonetaryAmountAsJsx } from '../money'

export function formatProductBundlePricing(
  productBundle: ProductBundleRevisionForFormatProductBundlePricingFragment
) {
  const { originalPrice, salePrice, salePriceRange } = productBundle

  const originalPriceFormatted = originalPrice && formatMonetaryAmountAsJsx(originalPrice)
  const salePriceFormmated = salePrice && formatMonetaryAmountAsJsx(salePrice)
  const salePriceRangeFromFormatted =
    salePriceRange?.from && formatMonetaryAmountAsJsx(salePriceRange.from)

  const salePriceDiffers =
    !!salePriceRange?.from &&
    !!salePriceRange.to &&
    salePriceRange.from.amount !== salePriceRange.to.amount

  const percentSavingsDisplayThreshold = 5
  const savings =
    originalPrice && salePrice && parseFloat(originalPrice.amount) - parseFloat(salePrice.amount)
  const showSavings =
    !!savings &&
    (savings / parseFloat(originalPrice!.amount)) * 100 > percentSavingsDisplayThreshold

  const savingsFormatted = savings && savings > 0 && numeral(savings).format('$0,0.00')

  return {
    originalPrice: originalPriceFormatted || undefined,
    salePrice: salePriceFormmated || undefined,
    salePriceRangeFrom: salePriceRangeFromFormatted,
    salePriceDiffers,
    savings: (showSavings && savingsFormatted) || undefined
  }
}

export function getLineItemsBundleTitleMap(
  lineItems: OrderLineItemForGetLineItemsBundleTitleFragment[]
) {
  const orderLineItemsBundleTitleMap: { [key: string]: string } = {}
  lineItems.forEach(lineItem => {
    if (lineItem.source.__typename === 'OrderLineItemProductBundleSource') {
      orderLineItemsBundleTitleMap[lineItem.id] = lineItem.source.bundle.title
    }
  })
  return orderLineItemsBundleTitleMap
}

/**
 * Returns a map where the key is the line item id and the value is the bundle line item id
 */
export function getLineItemBundleParentIdMap(
  lineItems: OrderLineItemForGetLineItemBundleParentIdMapFragment[]
) {
  // create a map of every line item is bundle
  const lineItemIsBundleMap: { [key: string]: boolean } = {}
  lineItems.forEach(lineItem => {
    lineItemIsBundleMap[lineItem.id] =
      lineItem.source.type === OrderLineItemSourceType.PRODUCT_BUNDLE
  })
  // create a map of line items that are in a bundle or is a bundle and with the value being the bundle line item id
  const lineItemBundleParentIdMap: { [key: string]: string } = {}
  lineItems.forEach(lineItem => {
    const parentLineItemId = lineItem.parentId || lineItem.id
    if (lineItemIsBundleMap[parentLineItemId]) {
      lineItemBundleParentIdMap[lineItem.id] = parentLineItemId
    }
  })
  return lineItemBundleParentIdMap
}

export function findBundleWithLargestStartingPrice<
  T extends ProductBundleRevisionForFindBundleWithLargestStartingPriceFragment
>(bundles: T[]): T {
  return [...bundles]
    .filter(bundle => !!bundle.salePriceRange?.from)
    .sort((a, b) => {
      const aFrom = a.salePriceRange?.from ? parseFloat(a.salePriceRange.from.amount) : 0
      const bFrom = b.salePriceRange?.from ? parseFloat(b.salePriceRange.from.amount) : 0

      return bFrom - aFrom
    })[0]
}
