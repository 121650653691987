import React from 'react'
import { getSelectedItemFeaturesGroupedByTypeLegacy } from '#src/app/services/expertCuratedItem'
import { OrderLineItemForProductLineItemVariantAndCustomizationFeatureValuesFragment } from './ProductLineItemVariantAndCustomizationFeatureValues.generated'
import styles from './styles.css'

interface ProductLineItemVariantAndCustomizationFeatureValuesProps {
  productLineItem: OrderLineItemForProductLineItemVariantAndCustomizationFeatureValuesFragment
}

const ProductLineItemVariantAndCustomizationFeatureValues: React.FC<
  ProductLineItemVariantAndCustomizationFeatureValuesProps
> = ({ productLineItem }) => {
  // if the line item is a curated item, get features from the curated item
  if (
    productLineItem.source.__typename === 'OrderLineItemSellableSource' &&
    productLineItem.source.customizationRevision
  ) {
    const { attributeAndVariantFeatures, productFeatures, addonFeatureGroups } =
      getSelectedItemFeaturesGroupedByTypeLegacy({
        sellable: productLineItem.source.sellable,
        customization: productLineItem.source.customizationRevision,
        selectedCustomizationFeatures: productLineItem.source.selectedCustomizationFeatures
      })

    const allSelectedItemFeaturesGroupedByType = [
      ...attributeAndVariantFeatures,
      ...productFeatures,
      ...addonFeatureGroups
    ]

    const attributeAndProductFeaturesHtmlList = [
      ...attributeAndVariantFeatures,
      ...productFeatures
    ].map(feature => {
      return `<b>${feature.displayName}: </b>${feature.displayValueHtml}`
    })

    // addon features are grouped
    const addonFeaturesHtmlList = addonFeatureGroups.map(group => {
      const featuresHtmlString = group.features
        .map(feature => `${feature.displayValueHtml}`)
        .join(', ')

      return `<b>${group.groupName}: </b>${featuresHtmlString}</br>`
    })

    return allSelectedItemFeaturesGroupedByType.length > 0 ? (
      <div
        className={styles.features}
        dangerouslySetInnerHTML={{
          __html: [...attributeAndProductFeaturesHtmlList, ...addonFeaturesHtmlList].join('</br>')
        }}
      />
    ) : null
  }

  // else, get features from the sellable variants
  const variants =
    productLineItem.source.__typename === 'OrderLineItemSellableSource'
      ? productLineItem.source.sellable.variants
      : null

  return variants && variants.length > 0 ? (
    <div
      className={styles.features}
      dangerouslySetInnerHTML={{
        __html: variants
          .map(({ displayName, displayValueHtml }) => `<b>${displayName}: </b>${displayValueHtml}`)
          .join('</br>')
      }}
    />
  ) : null
}

export default ProductLineItemVariantAndCustomizationFeatureValues
