import React from 'react'
import loadable from '@loadable/component'
import { Route, Switch, useParams } from '@deal/router'
import usePreferredExpert from '#src/app/hooks/usePreferredExpert'
import Page from '#src/app/components/Page'
import { NotFound } from '#src/app/components/Errors'
import { useTopListsRouteQuery } from './TopListsRoute.generated'
import TopListSkeletonLoader from './loading'
import TopListHubRoute from './hub'
import DefaultOgImage from './assets/default-og-image.jpg'

const PageComponent = loadable(() => import(/* webpackChunkName: "top-list" */ './page'), {
  resolveComponent: components => components.TopListPage
})

const TopListsRoute: React.FC = () => {
  const preferredExpertVanityId = usePreferredExpert()
  const { idOrFriendlyId } = useParams<{ idOrFriendlyId: string }>()

  const query = useTopListsRouteQuery({
    variables: {
      topListId: idOrFriendlyId,
      preferredExpertVanityId
    }
  })

  return (
    <Page
      query={query}
      pageKey={'top-list'}
      seoTitle={data =>
        data.findPublishedSellableEditorialTopListRevisionByExternalIdOrFriendlyId?.title
      }
      seoDescription={data => {
        const itemSellableTitles =
          data.findPublishedSellableEditorialTopListRevisionByExternalIdOrFriendlyId?.itemGroups
            .map(itemGroup => {
              return itemGroup.items.map(item => item.sellable.title)
            })
            .flat()

        if (!itemSellableTitles || itemSellableTitles.length === 0) {
          return undefined
        }

        // join with a "middle dot" ·
        return itemSellableTitles.join(' \u00B7 ')
      }}
      seoIndexable={true}
      canonicalPath={({
        findPublishedSellableEditorialTopListRevisionByExternalIdOrFriendlyId: topList
      }) => {
        return topList ? `/top-lists/${topList.friendlyId}/${topList.slug}` : undefined
      }}
      chat={false}
      expert={undefined}
      category={data =>
        data.findPublishedSellableEditorialTopListRevisionByExternalIdOrFriendlyId?.categories.at(0)
      }
      department={data =>
        data.findPublishedSellableEditorialTopListRevisionByExternalIdOrFriendlyId?.categories.at(0)
          ?.department ?? undefined
      }
      sellable={undefined}
      pageComponent={PageComponent}
      loadingComponent={TopListSkeletonLoader}
      ogImageUrl={data => {
        const imageUrl =
          data.findPublishedSellableEditorialTopListRevisionByExternalIdOrFriendlyId?.categories.at(
            0
          )?.categoryAssetRevision?.openGraphRecommendedListImage?.image.url
        return imageUrl || DefaultOgImage
      }}
    />
  )
}

const TopListsRouteParser: React.FC = () => {
  return (
    <Switch>
      <Route path="/top-lists/:idOrFriendlyId" component={TopListsRoute} />
      <Route path="/top-lists" component={TopListHubRoute} />
      <Route component={NotFound} />
    </Switch>
  )
}

export { TopListsRouteParser }
