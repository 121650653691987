// @ts-nocheck
import type * as Types from '../../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { SellableForMatchScoreItemCardFragmentDoc } from '../MatchScoreItemCard/MatchScoreItemCard.generated';
import { MediaFileFragmentDoc } from '../../../../../../fragments/MediaFile.generated';
import { ConversationStartersForConversationStartersV2FragmentDoc } from '../../../../../../components/ConversationStartersV2/ConversationStartersV2.generated';
import { SellableUrlFragmentDoc } from '../../../../../../fragments/SellableUrl.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SellableForProductRecommendationExplanationFragment = { __typename: 'Sellable', id: any, productRecommendationFitScore?: number | null, friendlyId?: string | null, slug: string, type: Types.SellableType, conversationStartersV2: { __typename: 'ConversationStarters', summary?: string | null, suggestions: Array<string> }, primaryImage?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null };

export type SellableWithExplanationForProductRecommendationExplanationQueryVariables = Types.Exact<{
  id: Types.Scalars['SellableId']['input'];
}>;


export type SellableWithExplanationForProductRecommendationExplanationQuery = { __typename: 'Query', sellable: { __typename: 'Sellable', id: any, productRecommendationFitExplanation?: string | null } };

export const SellableForProductRecommendationExplanationFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableForProductRecommendationExplanation"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Sellable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForMatchScoreItemCard"}},{"kind":"Field","name":{"kind":"Name","value":"conversationStartersV2"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConversationStartersForConversationStartersV2"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableUrl"}}]}}]} as unknown as DocumentNode;
export const SellableWithExplanationForProductRecommendationExplanationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SellableWithExplanationForProductRecommendationExplanation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SellableId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sellable"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"productRecommendationFitExplanation"}}]}}]}}]} as unknown as DocumentNode;
export type SellableWithExplanationForProductRecommendationExplanationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SellableWithExplanationForProductRecommendationExplanationQuery, SellableWithExplanationForProductRecommendationExplanationQueryVariables>, 'query'> & ({ variables: SellableWithExplanationForProductRecommendationExplanationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SellableWithExplanationForProductRecommendationExplanationComponent = (props: SellableWithExplanationForProductRecommendationExplanationComponentProps) => (
      <ApolloReactComponents.Query<SellableWithExplanationForProductRecommendationExplanationQuery, SellableWithExplanationForProductRecommendationExplanationQueryVariables> query={SellableWithExplanationForProductRecommendationExplanationDocument} {...props} />
    );
    

/**
 * __useSellableWithExplanationForProductRecommendationExplanationQuery__
 *
 * To run a query within a React component, call `useSellableWithExplanationForProductRecommendationExplanationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellableWithExplanationForProductRecommendationExplanationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellableWithExplanationForProductRecommendationExplanationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSellableWithExplanationForProductRecommendationExplanationQuery(baseOptions: Apollo.QueryHookOptions<SellableWithExplanationForProductRecommendationExplanationQuery, SellableWithExplanationForProductRecommendationExplanationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SellableWithExplanationForProductRecommendationExplanationQuery, SellableWithExplanationForProductRecommendationExplanationQueryVariables>(SellableWithExplanationForProductRecommendationExplanationDocument, options);
      }
export function useSellableWithExplanationForProductRecommendationExplanationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SellableWithExplanationForProductRecommendationExplanationQuery, SellableWithExplanationForProductRecommendationExplanationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SellableWithExplanationForProductRecommendationExplanationQuery, SellableWithExplanationForProductRecommendationExplanationQueryVariables>(SellableWithExplanationForProductRecommendationExplanationDocument, options);
        }
export function useSellableWithExplanationForProductRecommendationExplanationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SellableWithExplanationForProductRecommendationExplanationQuery, SellableWithExplanationForProductRecommendationExplanationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SellableWithExplanationForProductRecommendationExplanationQuery, SellableWithExplanationForProductRecommendationExplanationQueryVariables>(SellableWithExplanationForProductRecommendationExplanationDocument, options);
        }
export type SellableWithExplanationForProductRecommendationExplanationQueryHookResult = ReturnType<typeof useSellableWithExplanationForProductRecommendationExplanationQuery>;
export type SellableWithExplanationForProductRecommendationExplanationLazyQueryHookResult = ReturnType<typeof useSellableWithExplanationForProductRecommendationExplanationLazyQuery>;
export type SellableWithExplanationForProductRecommendationExplanationSuspenseQueryHookResult = ReturnType<typeof useSellableWithExplanationForProductRecommendationExplanationSuspenseQuery>;
export type SellableWithExplanationForProductRecommendationExplanationQueryResult = Apollo.QueryResult<SellableWithExplanationForProductRecommendationExplanationQuery, SellableWithExplanationForProductRecommendationExplanationQueryVariables>;