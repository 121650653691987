// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { DisplayFacetFragmentDoc, BucketedDisplayFacetFragmentDoc, BucketedRangeDisplayFacetFragmentDoc, NumericRangeDisplayFacetFragmentDoc } from '../../fragments/Facets.generated';
export type SellablesPaginationForResultsTopBarFragment = { __typename: 'SellablesPagination', count: number, maximumExceeded: boolean, facets: Array<{ __typename: 'BucketedDisplayFacet', displayName: string, name: string, alias?: string | null, buckets: Array<{ __typename: 'IndividualBucketedDisplayFacet', displayName: string, value: string, parentValue?: string | null, count?: any | null, seoShouldFollow: boolean }> } | { __typename: 'BucketedRangeDisplayFacet', displayName: string, name: string, alias?: string | null } | { __typename: 'NumericRangeDisplayFacet', displayName: string, name: string, alias?: string | null, type: Types.NumericRangeDisplayFacetType, lower: any, upper: any, scale: number, upperCapped: boolean }> };

export type CategorySellablesPaginationForResultsTopBarFragment = { __typename: 'CategorySellablesPagination', count: number, maximumExceeded: boolean, facets: Array<{ __typename: 'BucketedDisplayFacet', displayName: string, name: string, alias?: string | null, buckets: Array<{ __typename: 'IndividualBucketedDisplayFacet', displayName: string, value: string, parentValue?: string | null, count?: any | null, seoShouldFollow: boolean }> } | { __typename: 'BucketedRangeDisplayFacet', displayName: string, name: string, alias?: string | null } | { __typename: 'NumericRangeDisplayFacet', displayName: string, name: string, alias?: string | null, type: Types.NumericRangeDisplayFacetType, lower: any, upper: any, scale: number, upperCapped: boolean }>, expertiseRequest?: { __typename: 'SellableExpertiseRequest', id: any, recommendationCounts: Array<{ __typename: 'SellableExpertiseRecommendationCount', quality: Types.SellableExpertiseFitQuality, count: number }> } | null };

export const SellablesPaginationForResultsTopBarFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellablesPaginationForResultsTopBar"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SellablesPagination"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"maximumExceeded"}},{"kind":"Field","name":{"kind":"Name","value":"facets"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"DisplayFacet"}}]}}]}}]} as unknown as DocumentNode;
export const CategorySellablesPaginationForResultsTopBarFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CategorySellablesPaginationForResultsTopBar"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CategorySellablesPagination"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"maximumExceeded"}},{"kind":"Field","name":{"kind":"Name","value":"facets"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"DisplayFacet"}}]}},{"kind":"Field","name":{"kind":"Name","value":"expertiseRequest"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"recommendationCounts"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertiseRecommendationCountsInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"quality"}},{"kind":"Field","name":{"kind":"Name","value":"count"}}]}}]}}]}}]} as unknown as DocumentNode;