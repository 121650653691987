// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type StartExpertConsumerConversationForProductQuestionWrapperMutationVariables = Types.Exact<{
  input: Types.StartExpertConsumerConversationInput;
}>;


export type StartExpertConsumerConversationForProductQuestionWrapperMutation = { __typename: 'Mutation', startExpertConsumerConversation: { __typename: 'StartExpertConsumerConversationPayload', error?: string | null } };

export type ExpertForProductQuestionWrapperFragment = { __typename: 'BusinessUser', id: any };

export const ExpertForProductQuestionWrapperFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ExpertForProductQuestionWrapper"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]} as unknown as DocumentNode;
export const StartExpertConsumerConversationForProductQuestionWrapperDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"startExpertConsumerConversationForProductQuestionWrapper"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"StartExpertConsumerConversationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"startExpertConsumerConversation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"error"}}]}}]}}]} as unknown as DocumentNode;
export type StartExpertConsumerConversationForProductQuestionWrapperMutationFn = Apollo.MutationFunction<StartExpertConsumerConversationForProductQuestionWrapperMutation, StartExpertConsumerConversationForProductQuestionWrapperMutationVariables>;
export type StartExpertConsumerConversationForProductQuestionWrapperComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<StartExpertConsumerConversationForProductQuestionWrapperMutation, StartExpertConsumerConversationForProductQuestionWrapperMutationVariables>, 'mutation'>;

    export const StartExpertConsumerConversationForProductQuestionWrapperComponent = (props: StartExpertConsumerConversationForProductQuestionWrapperComponentProps) => (
      <ApolloReactComponents.Mutation<StartExpertConsumerConversationForProductQuestionWrapperMutation, StartExpertConsumerConversationForProductQuestionWrapperMutationVariables> mutation={StartExpertConsumerConversationForProductQuestionWrapperDocument} {...props} />
    );
    

/**
 * __useStartExpertConsumerConversationForProductQuestionWrapperMutation__
 *
 * To run a mutation, you first call `useStartExpertConsumerConversationForProductQuestionWrapperMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartExpertConsumerConversationForProductQuestionWrapperMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startExpertConsumerConversationForProductQuestionWrapperMutation, { data, loading, error }] = useStartExpertConsumerConversationForProductQuestionWrapperMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartExpertConsumerConversationForProductQuestionWrapperMutation(baseOptions?: Apollo.MutationHookOptions<StartExpertConsumerConversationForProductQuestionWrapperMutation, StartExpertConsumerConversationForProductQuestionWrapperMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartExpertConsumerConversationForProductQuestionWrapperMutation, StartExpertConsumerConversationForProductQuestionWrapperMutationVariables>(StartExpertConsumerConversationForProductQuestionWrapperDocument, options);
      }
export type StartExpertConsumerConversationForProductQuestionWrapperMutationHookResult = ReturnType<typeof useStartExpertConsumerConversationForProductQuestionWrapperMutation>;
export type StartExpertConsumerConversationForProductQuestionWrapperMutationResult = Apollo.MutationResult<StartExpertConsumerConversationForProductQuestionWrapperMutation>;
export type StartExpertConsumerConversationForProductQuestionWrapperMutationOptions = Apollo.BaseMutationOptions<StartExpertConsumerConversationForProductQuestionWrapperMutation, StartExpertConsumerConversationForProductQuestionWrapperMutationVariables>;