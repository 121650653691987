import { useContext, useEffect } from 'react'
import { usePrevious } from '@deal/dom-hooks'
import { MyselfFragment } from './Myself.generated'
import { IdentityContext } from './index'

/**
 * Invoke a callback when a user logs in or assumes a new identity via impersonation.
 */
export default function useLoginCallback(callbackFn: (newMyself: MyselfFragment) => void) {
  const { myself } = useContext(IdentityContext)
  const previousMyself = usePrevious(myself)

  useEffect(() => {
    // The `previousMyself` will be `undefined` initially. After that, it may be `null`,
    //   but should never be `undefined` again.
    if (previousMyself === undefined) {
      return
    }

    if (myself && myself.id !== previousMyself?.id) {
      callbackFn(myself)
    }
  }, [myself?.id, previousMyself?.id])
}
