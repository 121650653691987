// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { ConsumerProductReviewTargetForProductReviewItemsListFragmentDoc, ConsumerProductReviewForProductReviewItemsListFragmentDoc } from './components/ProductReviewItemsList/ProductReviewItemsList.generated';
import { SellableForProductReviewItemFragmentDoc, OrderLineItemForProductReviewItemFragmentDoc } from './components/ProductReviewItemsList/ProductReviewItem/ProductReviewItem.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ConsumerProductReviewForProductReviewsPageQueryVariables = Types.Exact<{
  consumerProductReviewId: Types.Scalars['ConsumerProductReviewId']['input'];
}>;


export type ConsumerProductReviewForProductReviewsPageQuery = { __typename: 'Query', consumerProductReview: { __typename: 'ConsumerProductReview', consumerName?: string | null, consumerLocation?: string | null, id: any, rating: number, title?: string | null, comments?: string | null, recommended?: boolean | null, sellable: { __typename: 'Sellable', id: any, title: string, primaryImage?: { __typename: 'MediaFile', id: any, url: string } | null }, source: { __typename: 'ConsumerProductReviewSourceCurated', purchasedAt: any } | { __typename: 'ConsumerProductReviewSourceImported' } } };

export type ConsumerProductReviewTargetForProductReviewsPageFragment = { __typename: 'ConsumerProductReviewTarget', suggestedAuthorName: string, suggestedAuthorLocation: string, purchasedSellable: { __typename: 'Sellable', id: any, title: string, primaryImage?: { __typename: 'MediaFile', id: any, url: string } | null }, orderLineItem: { __typename: 'OrderLineItem', id: any, order: { __typename: 'Order', id: any, paidAt?: any | null, sortTimestamp: any } } };

export type ConsumerProductReviewForProductReviewsPageFragment = { __typename: 'ConsumerProductReview', consumerName?: string | null, consumerLocation?: string | null, id: any, rating: number, title?: string | null, comments?: string | null, recommended?: boolean | null, sellable: { __typename: 'Sellable', id: any, title: string, primaryImage?: { __typename: 'MediaFile', id: any, url: string } | null }, source: { __typename: 'ConsumerProductReviewSourceCurated', purchasedAt: any } | { __typename: 'ConsumerProductReviewSourceImported' } };

export const ConsumerProductReviewTargetForProductReviewsPageFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ConsumerProductReviewTargetForProductReviewsPage"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ConsumerProductReviewTarget"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"suggestedAuthorName"}},{"kind":"Field","name":{"kind":"Name","value":"suggestedAuthorLocation"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConsumerProductReviewTargetForProductReviewItemsList"}}]}}]} as unknown as DocumentNode;
export const ConsumerProductReviewForProductReviewsPageFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ConsumerProductReviewForProductReviewsPage"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ConsumerProductReview"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"consumerName"}},{"kind":"Field","name":{"kind":"Name","value":"consumerLocation"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConsumerProductReviewForProductReviewItemsList"}}]}}]} as unknown as DocumentNode;
export const ConsumerProductReviewForProductReviewsPageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"consumerProductReviewForProductReviewsPage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"consumerProductReviewId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ConsumerProductReviewId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"consumerProductReview"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"consumerProductReviewId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConsumerProductReviewForProductReviewsPage"}}]}}]}},...ConsumerProductReviewForProductReviewsPageFragmentDoc.definitions,...ConsumerProductReviewForProductReviewItemsListFragmentDoc.definitions,...SellableForProductReviewItemFragmentDoc.definitions]} as unknown as DocumentNode;
export type ConsumerProductReviewForProductReviewsPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ConsumerProductReviewForProductReviewsPageQuery, ConsumerProductReviewForProductReviewsPageQueryVariables>, 'query'> & ({ variables: ConsumerProductReviewForProductReviewsPageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ConsumerProductReviewForProductReviewsPageComponent = (props: ConsumerProductReviewForProductReviewsPageComponentProps) => (
      <ApolloReactComponents.Query<ConsumerProductReviewForProductReviewsPageQuery, ConsumerProductReviewForProductReviewsPageQueryVariables> query={ConsumerProductReviewForProductReviewsPageDocument} {...props} />
    );
    

/**
 * __useConsumerProductReviewForProductReviewsPageQuery__
 *
 * To run a query within a React component, call `useConsumerProductReviewForProductReviewsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsumerProductReviewForProductReviewsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsumerProductReviewForProductReviewsPageQuery({
 *   variables: {
 *      consumerProductReviewId: // value for 'consumerProductReviewId'
 *   },
 * });
 */
export function useConsumerProductReviewForProductReviewsPageQuery(baseOptions: Apollo.QueryHookOptions<ConsumerProductReviewForProductReviewsPageQuery, ConsumerProductReviewForProductReviewsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsumerProductReviewForProductReviewsPageQuery, ConsumerProductReviewForProductReviewsPageQueryVariables>(ConsumerProductReviewForProductReviewsPageDocument, options);
      }
export function useConsumerProductReviewForProductReviewsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsumerProductReviewForProductReviewsPageQuery, ConsumerProductReviewForProductReviewsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsumerProductReviewForProductReviewsPageQuery, ConsumerProductReviewForProductReviewsPageQueryVariables>(ConsumerProductReviewForProductReviewsPageDocument, options);
        }
export function useConsumerProductReviewForProductReviewsPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ConsumerProductReviewForProductReviewsPageQuery, ConsumerProductReviewForProductReviewsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ConsumerProductReviewForProductReviewsPageQuery, ConsumerProductReviewForProductReviewsPageQueryVariables>(ConsumerProductReviewForProductReviewsPageDocument, options);
        }
export type ConsumerProductReviewForProductReviewsPageQueryHookResult = ReturnType<typeof useConsumerProductReviewForProductReviewsPageQuery>;
export type ConsumerProductReviewForProductReviewsPageLazyQueryHookResult = ReturnType<typeof useConsumerProductReviewForProductReviewsPageLazyQuery>;
export type ConsumerProductReviewForProductReviewsPageSuspenseQueryHookResult = ReturnType<typeof useConsumerProductReviewForProductReviewsPageSuspenseQuery>;
export type ConsumerProductReviewForProductReviewsPageQueryResult = Apollo.QueryResult<ConsumerProductReviewForProductReviewsPageQuery, ConsumerProductReviewForProductReviewsPageQueryVariables>;