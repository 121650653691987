import React, { useEffect, useRef, useState } from 'react'
import { Button, Heading, InlineNotification, Text } from '@deal/bluxome'
import { AuthenticationResult } from '#src/generated/types'
import { PasswordField } from '#src/app/components/PasswordField/rebranded'
import { useResetPasswordMutation } from '../../../ResetPassword.generated'
import { useInitiateResetPasswordMutation } from '../../../InitiateResetPassword.generated'
import { useAuthenticationSessionStateQuery } from '../../../AuthenticationSessionState.generated'
import { AuthenticatedUserFragment } from '../../../../../fragments/AuthenticatedUser.generated'
import handleTypeFromHandle from '../../../util/handleTypeFromHandle'
import formatHandle from '../../../util/formatHandle'
import { AuthIntent, AuthenticationFlowViewLayoutProps } from '../../../index'
import SixDigitCodeField from '../../../components/SixDigitCodeField'
import AuthenticationFlowViewLayout from '../../../components/AuthenticationFlowViewLayout/rebranded'
import styles from './styles.css'

interface ResetPasswordProps extends AuthenticationFlowViewLayoutProps {
  onIntentChanged: (intent: AuthIntent) => void
  onLogin: (user: AuthenticatedUserFragment) => void
}

const ResetPassword: React.FC<React.PropsWithChildren<ResetPasswordProps>> = ({
  onLogin,
  onIntentChanged,
  standalone
}) => {
  const formRef = useRef<HTMLFormElement>(null)
  const authenticationSession = useAuthenticationSessionStateQuery()
  const [initiateResetPassword] = useInitiateResetPasswordMutation()
  const [resetPassword, resetPasswordResult] = useResetPasswordMutation()
  const [resetPasswordCode, setResetPasswordCode] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordValid, setNewPasswordValid] = useState(false)
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')
  const [newPasswordConfirmationPristine, setNewPasswordConfirmationPristine] = useState(true)

  const authenticationSessionState =
    authenticationSession.data && authenticationSession.data.session.authenticationState

  const handleType = authenticationSessionState?.handle
    ? handleTypeFromHandle(authenticationSessionState.handle)
    : undefined

  /**
   * Initiate the reset password flow when the component mounts. The user
   *   will receive a code to their handle.
   */
  useEffect(() => {
    if (authenticationSessionState?.handle) {
      initiateResetPassword({
        variables: {
          input: {
            handle: authenticationSessionState.handle,
            handleType: handleType
          }
        }
      })
    }
  }, [])

  // If the authentication session has expired, send the user back to the LoginView
  if (!authenticationSessionState) {
    onIntentChanged(AuthIntent.Login)
    return null
  }

  /**
   * Submit the reset password code, along with the new password.
   */
  const handleSubmit: React.FormEventHandler = e => {
    e.preventDefault()

    resetPassword({
      variables: {
        input: {
          newPassword,
          challenge: resetPasswordCode,
          handle: authenticationSessionState.handle,
          handleType: handleType
        }
      }
    }).then(result => {
      if (result.data && result.data.resetPassword.result === AuthenticationResult.SUCCESS) {
        onLogin(result.data.resetPassword.user!)
      }
    })
  }

  const resetPasswordErrors =
    resetPasswordResult.data &&
    resetPasswordResult.data.resetPassword.result === AuthenticationResult.FAILURE &&
    resetPasswordResult.data.resetPassword.message

  const isSubmittable =
    resetPasswordCode.length === 6 && newPasswordValid && newPassword === newPasswordConfirmation

  return (
    <AuthenticationFlowViewLayout
      headline="Don't worry, it happens"
      subHeadline={
        <Text color="neutrals-600">
          {authenticationSessionState && authenticationSessionState.handle ? (
            <>
              We sent a code to <strong>{formatHandle(authenticationSessionState.handle)}</strong>.
            </>
          ) : (
            `We sent you a 6-digit code.`
          )}{' '}
          Enter it below, and then choose a new password.
        </Text>
      }
      standalone={standalone}
    >
      <form className={styles.resetPassword} onSubmit={handleSubmit} ref={formRef}>
        {resetPasswordErrors && (
          <InlineNotification type="negative" style="prominent" title={resetPasswordErrors} />
        )}
        <SixDigitCodeField
          value={resetPasswordCode}
          onChange={value => setResetPasswordCode(value)}
          isRebranded
        />
        <div className={styles.newPasswordHeadline}>
          <Heading size="h4">Create a new password</Heading>
        </div>
        <PasswordField
          name="newPassword"
          label="Enter a new password"
          type="password"
          showStrength
          value={newPassword}
          onChange={setNewPassword}
          onFieldValid={() => setNewPasswordValid(true)}
          onFieldInvalid={() => setNewPasswordValid(false)}
        />
        <PasswordField
          name="newPasswordConfirmation"
          label="Confirm your new password"
          type="password"
          value={newPasswordConfirmation}
          onChange={setNewPasswordConfirmation}
          onBlur={() => setNewPasswordConfirmationPristine(false)}
          validation={
            !newPasswordConfirmationPristine && newPassword !== newPasswordConfirmation
              ? { state: 'critical', label: 'Passwords do not match' }
              : undefined
          }
        />
        <Button color="learn-blue" fullWidth type="submit" isDisabled={!isSubmittable}>
          Continue →
        </Button>
      </form>
    </AuthenticationFlowViewLayout>
  )
}

export { ResetPassword }
