import React from 'react'
import ReactMarkdown from 'react-markdown'
import { ButtonVariant } from '@deal/components/es/components/Button'
import { Box, Button, FancyContent, FancyShowcase, Image } from '@deal/components'
import { ComponentValue, getTextStyles } from '#src/app/services/pageTemplate'
import { useBreakpoint } from '#src/app/hooks/useBreakpoint'
import clientOnly from '#src/app/hocs/clientOnly'
import PathSubmitButtonLegacy from '#src/app/components/PathSubmitButtonLegacy'
import { ModalPath } from '#src/app/components/Path/ModalPath'
import { PromotionForEngagementChannelsFragment } from '../../EngagementChannels.generated'
import EngagementChannelPromotionBanner from '../../components/EngagementChannelPromotionBanner'
import styles from './styles.css'

export interface PathModalTemplateProps {
  promotion?: PromotionForEngagementChannelsFragment | null
  pathLoading?: boolean
  isOpen: boolean
  componentValue: { [key: string]: ComponentValue }
  onSubmit?: () => void
  onDismiss?: () => void
  onReturnToPreviousContext?: () => void
  defaultSubmitToken?: string
}

enum ParamName {
  Title = 'Title',
  Description = 'Description',
  YesButton = 'Yes Button',
  NoButton = 'No Button',
  BackgroundDesktopImage = 'Background-Desktop',
  BackgroundMobileImage = 'Background-Mobile',
  FinePrint = 'Fine Print'
}

const PathModalTemplate: React.FC<PathModalTemplateProps> = ({
  pathLoading = false,
  promotion,
  isOpen,
  componentValue,
  onSubmit,
  onDismiss,
  onReturnToPreviousContext,
  defaultSubmitToken
}) => {
  const isMobile = !useBreakpoint('sm')

  const desktopImageUrl = componentValue[ParamName.BackgroundDesktopImage]?.imageUrl
  const mobileImageUrl = componentValue[ParamName.BackgroundMobileImage]?.imageUrl
  const desktopImageAltText = componentValue[ParamName.BackgroundDesktopImage]?.altText
  const mobileImageAltText = componentValue[ParamName.BackgroundMobileImage]?.altText

  return (
    <ModalPath
      isOpen={isOpen}
      onClose={onDismiss}
      onReturnToPreviousContext={onReturnToPreviousContext}
      defaultSubmitToken={defaultSubmitToken}
      fixedMinHeight={!!defaultSubmitToken}
      fallback={
        <div className={styles.modalContent}>
          <FancyShowcase className={styles.imageContainer}>
            {isMobile
              ? mobileImageUrl && (
                  <Image
                    src={mobileImageUrl}
                    htmlAttributes={{ alt: mobileImageAltText ?? undefined }}
                    size="100vw"
                    imgixParams={{ fit: 'crop' }}
                    className={styles.image}
                  />
                )
              : desktopImageUrl && (
                  <Image
                    src={desktopImageUrl}
                    htmlAttributes={{ alt: desktopImageAltText ?? undefined }}
                    width={400}
                    height={350}
                    imgixParams={{ fit: 'crop' }}
                    className={styles.image}
                  />
                )}
          </FancyShowcase>
          <FancyContent className={styles.contentContainer}>
            {!!promotion && <EngagementChannelPromotionBanner promotion={promotion} />}
            <div className={styles.title} style={getTextStyles(componentValue[ParamName.Title])}>
              {componentValue[ParamName.Title]?.text}
            </div>
            <div
              className={styles.subtitle}
              style={getTextStyles(componentValue[ParamName.Description])}
            >
              {componentValue[ParamName.Description]?.text}
            </div>
            <Box spacing="tight">
              <Box.Item grow={isMobile ? 1 : undefined}>
                <PathSubmitButtonLegacy
                  onClick={onSubmit}
                  loading={pathLoading}
                  className={styles.submitButton}
                  variant={componentValue[ParamName.YesButton]?.buttonType as ButtonVariant}
                >
                  {componentValue[ParamName.YesButton]?.text || 'Get started'}
                </PathSubmitButtonLegacy>
              </Box.Item>
              {!isMobile && (
                <Button
                  variant={
                    (componentValue[ParamName.NoButton]?.buttonType as ButtonVariant) ||
                    'neutral-dark-link'
                  }
                  onClick={onDismiss}
                  className="whitespace-nowrap"
                >
                  {componentValue[ParamName.NoButton]?.text || 'No thanks'}
                </Button>
              )}
            </Box>
            {componentValue[ParamName.FinePrint]?.markdownText && (
              <ReactMarkdown
                source={componentValue[ParamName.FinePrint].markdownText}
                className={styles.finePrint}
              />
            )}
          </FancyContent>
        </div>
      }
    />
  )
}

export default clientOnly(PathModalTemplate)
