import { Location, createLocation } from 'history'
import { CategoryPageLayoutType } from '#src/generated/types'
import { CategoryForCategoryOrProductListingPageFragment } from './CategoryForCategoryOrProductListingPage.generated'

export function buildCategoryOrProductListingPageUrl(
  category: CategoryForCategoryOrProductListingPageFragment
): Location {
  const getPathStartForCategory = (category: CategoryForCategoryOrProductListingPageFragment) => {
    if (category.pageLayoutType === CategoryPageLayoutType.BROWSE_CATEGORY_LAYOUT) {
      return '/shop'
    } else {
      return '/c'
    }
  }

  const pathStart = getPathStartForCategory(category)
  const pathName = `${pathStart}/${category.slug}`

  return createLocation({
    pathname: pathName
  })
}
