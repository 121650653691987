import flatMap from 'lodash/flatMap'
import hashIndex from '@deal/hash-index'
import { DepartmentType } from '#src/generated/types'
import { ReferralDepartmentFragment } from '#src/app/routes/referral/ReferralCategory/ActiveExpertCategories.generated'
import { CategoryForGetActiveExpertCountForCategoryFragment } from './CategoryForGetActiveExpertCountForCategory.generated'

// HACK: for referral page "Path Single Select" component
export function getActiveCategoriesFromDepartments({
  departments,
  forceOnlyWinterSportsCategories
}: {
  departments: ReferralDepartmentFragment[]
  forceOnlyWinterSportsCategories: boolean
}) {
  // All categories
  let categories = flatMap(departments, department => department.topLevelCategory.expertCategories)

  // HACK: Filter out Winter Sports category from expert categories
  categories = categories.filter(category => category.slug !== 'winter-sports')

  // If we know what expert to refer them to and the department is winter sports we need them to choose between ski and snowboard
  if (forceOnlyWinterSportsCategories) {
    categories = categories.filter(
      category => category.department?.type === DepartmentType.WINTER_SPORTS
    )
  }

  return categories
}

export function doesDeptSupportFreeShipping(departmentType: DepartmentType) {
  return departmentType !== DepartmentType.CYCLING
}

export function doesDeptSupportReturns(departmentType: DepartmentType) {
  return departmentType !== DepartmentType.CYCLING
}

export function getActiveExpertCountForCategory(
  category: CategoryForGetActiveExpertCountForCategoryFragment
) {
  const expertCount = category.department?.expertStats.activeExpertCount

  // Add +/- 10% to the expert count to add some variance to the counts for categories within the same department
  return expertCount && expertCount > 0
    ? Math.round(expertCount * 0.9 + hashIndex(category?.id, expertCount * 0.2))
    : 0
}
