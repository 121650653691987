// @ts-nocheck
import type * as Types from '../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { MediaFileFragmentDoc } from '../../../../fragments/MediaFile.generated';
export type SellableForImageVectorFragment = { __typename: 'Sellable', id: any, primaryImage?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null };

export type VectorForImageVectorSelectorFragment = { __typename: 'VariationMatrixVector', value: string, displayValueHtml: string, sellableIds: Array<any> };

export const SellableForImageVectorFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableForImageVector"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Sellable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"primaryImage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaFile"}}]}}]}}]} as unknown as DocumentNode;
export const VectorForImageVectorSelectorFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"VectorForImageVectorSelector"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"VariationMatrixVector"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"displayValueHtml"}},{"kind":"Field","name":{"kind":"Name","value":"sellableIds"}}]}}]} as unknown as DocumentNode;