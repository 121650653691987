import React, { useState } from 'react'
import uniq from 'lodash/uniq'
import loadable from '@loadable/component'
import { Route, Switch, useLocation, useParams } from '@deal/router'
import { useExperiment } from '@deal/experiment-js'
import {
  ContentSelectionChannelType,
  SellableSearchFeature,
  SellableSearchFilter,
  SellableSort,
  SortOrder
} from '#src/generated/types'
import { getSellableUrl } from '#src/app/services/sellable'
import { parsePlaPageUrl } from '#src/app/services/search/parsePlaPageUrl'
import { buildPlaPath } from '#src/app/services/search/buildPlaPageUrl'
import { facetSelectionsMapToGraphQLInput } from '#src/app/services/search'
import usePreferredExpert from '#src/app/hooks/usePreferredExpert'
import useGetContentSelectionCriteriaInput from '#src/app/hooks/useGetContentSelectionCriteriaInput'
import { useBreakpoint } from '#src/app/hooks/useBreakpoint'
import { RecentlyViewedProductsContextProvider } from '#src/app/context/recently-viewed-products'
import { PathCtaContextProvider } from '#src/app/context/path-cta'
import { useIdentityContext } from '#src/app/containers/Identity'
import Redirect from '#src/app/components/Redirect'
import Page from '#src/app/components/Page'
import Footer from '#src/app/components/Footer'
import { NotFound } from '#src/app/components/Errors'
import { usePlaRouteQuery } from './PLARoute.generated'
import { ExtraPLAPageProps } from './page/index'
import { Loader } from './loading'

const PageComponent = loadable(() => import('./page/index'), {
  resolveComponent: page => page.PLAPage
})

export const usePlaRouteResultPageSize = () => {
  const isMobile = !useBreakpoint('lg', 'bluxome')
  return isMobile ? 8 : 12
}

const PLARoute: React.FC = () => {
  // Evaluate the PLA experiment(s) for exposure tracking only. The actual experimentation is done
  //   server-side to ensure the redirect happens as quickly as possible (see server/index.tsx),
  //   but we must evaluate the experiment in the browser as well to track the exposure.
  const { search } = useLocation()
  const urlQueryParams = new URLSearchParams(search)
  const isGoogleShoppingTraffic =
    urlQueryParams.get('utm_source') === 'google' && urlQueryParams.get('ad_type') === 'pla'
  const isAffiliateTraffic = urlQueryParams.get('utm_source') === 'impact'

  useExperiment({
    experiment: 'pla-route',
    defaultTreatment: 'control',
    options: {
      skip: !isGoogleShoppingTraffic
    }
  })
  useExperiment({
    experiment: 'pla-route-affiliate',
    defaultTreatment: 'control',
    options: {
      skip: !isAffiliateTraffic
    }
  })

  const { friendlyIdOrId } = useParams<{ friendlyIdOrId: string }>()
  const { isLoggedIn } = useIdentityContext()
  const location = useLocation()
  const preferredExpertVanityId = usePreferredExpert()

  const similarSellableQuery = parsePlaPageUrl(location)

  const resutsPageSize = usePlaRouteResultPageSize()

  const contentSelectionInput = useGetContentSelectionCriteriaInput({
    channelType: ContentSelectionChannelType.PRODUCT_DETAIL
  })

  const [categoryIds, setCategoryIds] = useState<string[]>()

  const query = usePlaRouteQuery({
    variables: {
      friendlyIdOrId,
      isLoggedOut: !isLoggedIn,
      contentSelectionCriteriaInput: contentSelectionInput,
      preferredExpertVanityId: preferredExpertVanityId,
      sellableSearchQuery: {
        anchorSellableFriendlyIdOrId: friendlyIdOrId,
        facets: facetSelectionsMapToGraphQLInput(similarSellableQuery.selections || {}),
        filters: uniq([
          SellableSearchFilter.INCLUDE_IN_STOCK_OR_PRE_ORDER,
          ...(similarSellableQuery.filters || [])
        ]),
        maxFacetTerms: 100,
        searchFeatures: [SellableSearchFeature.HIERARCHICAL_CATEGORY_FACET],
        categoryIds: categoryIds
      },
      order: similarSellableQuery.sortOrder ?? SortOrder.DESC,
      sortBy: similarSellableQuery.sortBy ?? SellableSort.SIMILARITY,
      limit: resutsPageSize,
      offset: (similarSellableQuery.page - 1) * resutsPageSize,
      isShowingLLMExpertQuestionAndAnswers: true,
      isShowingSellableFamilyRelationship: false
    },
    onCompleted(data) {
      if (data.findSellable?.categories) {
        setCategoryIds(
          data.findSellable.categories.map(category => {
            return category.id
          })
        )
      }
    }
  })

  const sellable = query.data?.findSellable

  const csrResult = sellable?.contentForCriteria
  const promotion = csrResult?.promotion
  const pathSchemaId = csrResult?.defaultPathSelection?.pathSchemaId
  const csrKeyword = csrResult?.rule?.keyword || csrResult?.ruleV2?.title || undefined

  // Check the URL and make sure it is using the correct friendly ID and slug. If it's incorrect,
  //   redirect to the canonical URL. There are two cases to consider:
  //
  // 1) Using the non-friendly ID in the URL. This shouldn't happen so long as we are formatting URLs
  //    correctly everywhere in our codebase and sitemap.
  //
  //    For example: curated.com/pla/AgAAADAAC3Bh8YnZQN2LdXoBavLUxg/any-slug
  //
  // 2) Using the an incorrect slug in the URL. This can happen if the slug changes after generating
  //    the sitemap, or by otherwise accessing an outdated link (e.g. via email or SMS).
  //
  //    For example: curated.com/pla/123456789/some-outdated-slug
  //
  // In both cases, we need to make sure we are comparing the URL to the correct sellable. The
  //   `sellable` from the query could be a different sellable entirely if we have just navigated to
  //   a related sellable from a PDP. The URL will update before the Apollo cache updates; we need
  //   to ensure we're comparing apples to apples before redirecting.
  const urlMatchesSellableId =
    friendlyIdOrId === sellable?.id || friendlyIdOrId === sellable?.friendlyId
  const canonicalUrl = sellable ? buildPlaPath(sellable) : undefined

  if (urlMatchesSellableId && canonicalUrl && !location.pathname.includes(canonicalUrl)) {
    return <Redirect to={canonicalUrl} statusCode={301} />
  }

  // If the sellable is inactive, redirect to the default variant (assuming it is active)
  if (
    sellable &&
    !sellable.active &&
    sellable.variant &&
    sellable.variationMatrix?.defaultVariant.active
  ) {
    return <Redirect to={buildPlaPath(sellable.variationMatrix.defaultVariant)} />
  }

  return (
    <>
      <PathCtaContextProvider value={{ promotionId: promotion?.id, pathSchemaId, csrKeyword }}>
        <RecentlyViewedProductsContextProvider>
          <Page
            query={query}
            chat={true}
            department={data => data.findSellable?.department || undefined}
            expert={data =>
              data.findSellable?.categories.at(0)?.recommendedExpertForPage.expert || undefined
            }
            category={data => data.findSellable?.categories.at(0) || undefined}
            loadingComponent={Loader}
            ogImageUrl={undefined}
            engagementChannels
            overridePageKeyForEngagementChannels="product-details"
            pageComponent={PageComponent}
            pageKey="product-details-pla"
            canonicalPath={sellable ? getSellableUrl(sellable.parent || sellable) : undefined}
            seoTitle={data => data.findSellable?.parent?.title || data.findSellable?.title}
            seoDescription={() => undefined}
            seoIndexable={data =>
              data.findSellable?.indexable && !!data.findSellable.department ? true : false
            }
            sellable={data => data.findSellable || undefined}
            pageProps={{ csrResult } as ExtraPLAPageProps}
          />
        </RecentlyViewedProductsContextProvider>
      </PathCtaContextProvider>

      <Footer />
    </>
  )
}

export const PLARouteParser: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Switch>
      <Route path="/pla/:friendlyIdOrId/:slug" component={PLARoute} />
      <Route component={NotFound} />
    </Switch>
  )
}
