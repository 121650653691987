// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { AuthenticationStateFragmentDoc } from './AuthenticationState.generated';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type InitiateAuthenticationMutationVariables = Types.Exact<{
  input: Types.InitiateAuthenticationInput;
}>;


export type InitiateAuthenticationMutation = { __typename: 'Mutation', initiateAuthentication: { __typename: 'InitiateAuthenticationPayload', isValidHandle: boolean, challengeType?: Types.ChallengeType | null, session: { __typename: 'Session', id: any, authenticationMethod?: Types.AuthenticationMethod | null, authenticationState: { __typename: 'AuthenticationSessionState', handle?: string | null, handleId?: any | null, loginChallengeType?: Types.ChallengeType | null, resetPasswordChallengeVerified: boolean } } } };


export const InitiateAuthenticationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"InitiateAuthentication"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"InitiateAuthenticationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"initiateAuthentication"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isValidHandle"}},{"kind":"Field","name":{"kind":"Name","value":"challengeType"}},{"kind":"Field","name":{"kind":"Name","value":"session"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AuthenticationState"}}]}}]}}]}},...AuthenticationStateFragmentDoc.definitions]} as unknown as DocumentNode;
export type InitiateAuthenticationMutationFn = Apollo.MutationFunction<InitiateAuthenticationMutation, InitiateAuthenticationMutationVariables>;
export type InitiateAuthenticationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InitiateAuthenticationMutation, InitiateAuthenticationMutationVariables>, 'mutation'>;

    export const InitiateAuthenticationComponent = (props: InitiateAuthenticationComponentProps) => (
      <ApolloReactComponents.Mutation<InitiateAuthenticationMutation, InitiateAuthenticationMutationVariables> mutation={InitiateAuthenticationDocument} {...props} />
    );
    

/**
 * __useInitiateAuthenticationMutation__
 *
 * To run a mutation, you first call `useInitiateAuthenticationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateAuthenticationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateAuthenticationMutation, { data, loading, error }] = useInitiateAuthenticationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitiateAuthenticationMutation(baseOptions?: Apollo.MutationHookOptions<InitiateAuthenticationMutation, InitiateAuthenticationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitiateAuthenticationMutation, InitiateAuthenticationMutationVariables>(InitiateAuthenticationDocument, options);
      }
export type InitiateAuthenticationMutationHookResult = ReturnType<typeof useInitiateAuthenticationMutation>;
export type InitiateAuthenticationMutationResult = Apollo.MutationResult<InitiateAuthenticationMutation>;
export type InitiateAuthenticationMutationOptions = Apollo.BaseMutationOptions<InitiateAuthenticationMutation, InitiateAuthenticationMutationVariables>;