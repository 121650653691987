// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { CategoryForTopItemsFragmentDoc } from './CategorySelector/CategorySelector.generated';
import { ExpertTopRecommendedSellableForTopItemsFragmentDoc } from './SellableRow/SellableRow.generated';
import { MediaFileFragmentDoc } from '../../../fragments/MediaFile.generated';
import { SellableForSellableLinkFragmentDoc } from '../../SellableLink/SellableLink.generated';
import { SellableForGetSellableLinkRelFragmentDoc, SellableForIsSellablePurchasableFragmentDoc } from '../../../services/sellable/Sellable.generated';
import { SellableUrlFragmentDoc } from '../../../fragments/SellableUrl.generated';
export type ExpertCategoryTopRecommendedSellableForTopItemsFragment = { __typename: 'ExpertCategoryTopRecommendedSellable', displayTitle: string, category: { __typename: 'Category', id: any, pluralDisplayName: string }, topRecommendedSellables: Array<{ __typename: 'ExpertTopRecommendedSellable', timesRecommended?: number | null, sellable: { __typename: 'Sellable', id: any, title: string, active: boolean, adHoc: boolean, friendlyId?: string | null, slug: string, type: Types.SellableType, images: Array<{ __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null }>, brand?: { __typename: 'Brand', id: any, displayName: string, logo?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null } | null } }> };

export const ExpertCategoryTopRecommendedSellableForTopItemsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ExpertCategoryTopRecommendedSellableForTopItems"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ExpertCategoryTopRecommendedSellable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"displayTitle"}},{"kind":"Field","name":{"kind":"Name","value":"category"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CategoryForTopItems"}}]}},{"kind":"Field","name":{"kind":"Name","value":"topRecommendedSellables"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ExpertTopRecommendedSellableForTopItems"}}]}}]}}]} as unknown as DocumentNode;