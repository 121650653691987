// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type LeadForSwitchToRealExpertButtonFragment = { __typename: 'Lead', id: any, conversation: { __typename: 'Conversation', id: any, isAiChatV2: boolean, isAiGuestChat: boolean }, expert: { __typename: 'BusinessUser', id: any, expertAttributes: { __typename: 'BusinessUserExpertAttributes', placeholderExpert: boolean } } };

export type ConversationForSwitchToRealExpertButtonFragment = { __typename: 'Conversation', id: any, isAiChatV2: boolean, isAiGuestChat: boolean, lead?: { __typename: 'Lead', id: any, expert: { __typename: 'BusinessUser', id: any, expertAttributes: { __typename: 'BusinessUserExpertAttributes', placeholderExpert: boolean } } } | null };

export type BusinessUserForSwitchToRealExpertButtonFragment = { __typename: 'BusinessUser', id: any, existingLeadForConsumer?: { __typename: 'Lead', id: any, conversation: { __typename: 'Conversation', id: any, isAiChatV2: boolean, isAiGuestChat: boolean } } | null, expertAttributes: { __typename: 'BusinessUserExpertAttributes', placeholderExpert: boolean } };

export type ConsumerLeadChangedForSwitchToRealExpertButtonSubscriptionVariables = Types.Exact<{
  leadId: Types.Scalars['LeadId']['input'];
}>;


export type ConsumerLeadChangedForSwitchToRealExpertButtonSubscription = { __typename: 'Subscription', consumerLeadChanged: { __typename: 'ExpertChangedConsumerLeadChangeEvent', lead: { __typename: 'Lead', id: any, conversation: { __typename: 'Conversation', id: any, isAiChatV2: boolean, isAiGuestChat: boolean }, expert: { __typename: 'BusinessUser', id: any, expertAttributes: { __typename: 'BusinessUserExpertAttributes', placeholderExpert: boolean } } } } };

export const LeadForSwitchToRealExpertButtonFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LeadForSwitchToRealExpertButton"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Lead"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"conversation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isAiChatV2"}},{"kind":"Field","name":{"kind":"Name","value":"isAiGuestChat"}}]}},{"kind":"Field","name":{"kind":"Name","value":"expert"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"expertAttributes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"placeholderExpert"}}]}}]}}]}}]} as unknown as DocumentNode;
export const ConversationForSwitchToRealExpertButtonFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ConversationForSwitchToRealExpertButton"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Conversation"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isAiChatV2"}},{"kind":"Field","name":{"kind":"Name","value":"isAiGuestChat"}},{"kind":"Field","name":{"kind":"Name","value":"lead"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"expert"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"expertAttributes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"placeholderExpert"}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export const BusinessUserForSwitchToRealExpertButtonFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BusinessUserForSwitchToRealExpertButton"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"existingLeadForConsumer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"conversation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isAiChatV2"}},{"kind":"Field","name":{"kind":"Name","value":"isAiGuestChat"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"expertAttributes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"placeholderExpert"}}]}}]}}]} as unknown as DocumentNode;
export const ConsumerLeadChangedForSwitchToRealExpertButtonDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"ConsumerLeadChangedForSwitchToRealExpertButton"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"leadId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LeadId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"consumerLeadChanged"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"leadId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"leadId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"lead"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LeadForSwitchToRealExpertButton"}}]}}]}}]}},...LeadForSwitchToRealExpertButtonFragmentDoc.definitions]} as unknown as DocumentNode;
export type ConsumerLeadChangedForSwitchToRealExpertButtonComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<ConsumerLeadChangedForSwitchToRealExpertButtonSubscription, ConsumerLeadChangedForSwitchToRealExpertButtonSubscriptionVariables>, 'subscription'>;

    export const ConsumerLeadChangedForSwitchToRealExpertButtonComponent = (props: ConsumerLeadChangedForSwitchToRealExpertButtonComponentProps) => (
      <ApolloReactComponents.Subscription<ConsumerLeadChangedForSwitchToRealExpertButtonSubscription, ConsumerLeadChangedForSwitchToRealExpertButtonSubscriptionVariables> subscription={ConsumerLeadChangedForSwitchToRealExpertButtonDocument} {...props} />
    );
    

/**
 * __useConsumerLeadChangedForSwitchToRealExpertButtonSubscription__
 *
 * To run a query within a React component, call `useConsumerLeadChangedForSwitchToRealExpertButtonSubscription` and pass it any options that fit your needs.
 * When your component renders, `useConsumerLeadChangedForSwitchToRealExpertButtonSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsumerLeadChangedForSwitchToRealExpertButtonSubscription({
 *   variables: {
 *      leadId: // value for 'leadId'
 *   },
 * });
 */
export function useConsumerLeadChangedForSwitchToRealExpertButtonSubscription(baseOptions: Apollo.SubscriptionHookOptions<ConsumerLeadChangedForSwitchToRealExpertButtonSubscription, ConsumerLeadChangedForSwitchToRealExpertButtonSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ConsumerLeadChangedForSwitchToRealExpertButtonSubscription, ConsumerLeadChangedForSwitchToRealExpertButtonSubscriptionVariables>(ConsumerLeadChangedForSwitchToRealExpertButtonDocument, options);
      }
export type ConsumerLeadChangedForSwitchToRealExpertButtonSubscriptionHookResult = ReturnType<typeof useConsumerLeadChangedForSwitchToRealExpertButtonSubscription>;
export type ConsumerLeadChangedForSwitchToRealExpertButtonSubscriptionResult = Apollo.SubscriptionResult<ConsumerLeadChangedForSwitchToRealExpertButtonSubscription>;