import React from 'react'
import { Link } from '@deal/router'
import { ProgressBar, Rounded, Text } from '@deal/bluxome'
import { useCartVisibilityContext } from '#src/app/context/cart-visibility'
import styles from './styles.css'

interface PromotionProgressTrackerProps {
  title: string
  subtitle?: string
  progressPc: number
  label?: string
  termsPath?: string
}

export const PromotionProgressTracker: React.FC<PromotionProgressTrackerProps> = ({
  title,
  subtitle,
  progressPc,
  label,
  termsPath
}) => {
  const { setCartVisibility } = useCartVisibilityContext()
  return (
    <Rounded bgColor="feedback-success-200">
      <div className={styles.wrapper}>
        <Text style="large-medium">{title}</Text>
        {subtitle && <Text style="small">{subtitle}</Text>}
        {termsPath && (
          <Text style="small">
            <Link onClick={() => setCartVisibility(false)} className={styles.link} to={termsPath}>
              See terms
            </Link>
          </Text>
        )}
        <div className={styles.progress}>
          <ProgressBar progressPc={progressPc} label={label} />
        </div>
      </div>
    </Rounded>
  )
}
