import React from 'react'
import { Skeleton } from '#src/app/components/SkeletonLoader'
import { CompareTableRow } from '../../../../components/CompareTableRow'
import styles from './styles.css'

export const ProductSpecsComparison: React.FC = () => {
  return (
    <>
      {new Array(6).fill(null).map((_, rowIdx) => (
        <CompareTableRow
          firstDataColumnDropshadow={false}
          key={`spec-row-${rowIdx}`}
          className={styles.specsTableRow}
          header={
            <div className={styles.specName}>
              <Skeleton width="75%" height="21px" />
            </div>
          }
          data={new Array(4).fill(null).map((_, colIdx) => ({
            sellableId: `${rowIdx}-${colIdx}`,
            cell: (
              <div key={`${rowIdx}-${colIdx}`} className={styles.specValue}>
                <Skeleton width="75%" height="21px" />
              </div>
            )
          }))}
        />
      ))}
    </>
  )
}
