import React, { useContext } from 'react'
import classnames from 'classnames'
import { isBusinessMobileNative } from '#src/app/services/mobile'
import { IdentityContext } from '#src/app/containers/Identity'
import { AuthenticatedUserFragment } from '../../fragments/AuthenticatedUser.generated'
import UnderlinedText from '../UnderlinedText'
import ResetPasswordView from './views/ResetPasswordView'
import RegisterView from './views/RegisterView'
import LogoutView from './views/LogoutView'
import { LoginForm } from './views/LoginView'
import LoginChallengeOneTimePasswordView from './views/LoginChallengeOneTimePasswordView'
import styles from './styles.css'

export enum AuthIntent {
  Login = 'Login',
  LoginChallengeOneTimePassword = 'Login with One Time Password',
  ResetPassword = 'Reset Password',
  Register = 'Register',
  Logout = 'Logout'
}

export enum RegistrationHandle {
  PHONE,
  EMAIL,
  PHONE_AND_EMAIL
}

export interface AuthenticationFlowViewLayoutProps {
  footer?: React.ReactNode
  headline?: React.ReactNode
  subHeadline?: React.ReactNode
  bottomSection?: React.ReactNode
  standalone?: boolean
}
interface AuthenticationFlowViewProps extends AuthenticationFlowViewLayoutProps {
  intent: AuthIntent
  onIntentChanged: (intent: AuthIntent) => void
  onLogin: (user: AuthenticatedUserFragment) => void
  onRegister: (user: AuthenticatedUserFragment) => void
  onLogout: () => void
  onHandleIdChanged?: (handleId: string) => void
  handleId?: string
  registerViaPath?: boolean
  registrationHandle?: RegistrationHandle
  isRebranded?: boolean
}

const AuthenticationFlowView: React.FC<React.PropsWithChildren<AuthenticationFlowViewProps>> = ({
  ...props
}) => {
  switch (props.intent) {
    case AuthIntent.Login:
      return <LoginForm {...props} />
    case AuthIntent.LoginChallengeOneTimePassword:
      return <LoginChallengeOneTimePasswordView {...props} />
    case AuthIntent.Register:
      return (
        <RegisterView
          {...props}
          onAlreadyRegistered={handleId => {
            props.onIntentChanged(AuthIntent.Login)
            props.onHandleIdChanged && props.onHandleIdChanged(handleId)
          }}
        />
      )
    case AuthIntent.ResetPassword:
      return <ResetPasswordView {...props} />
    case AuthIntent.Logout:
      return <LogoutView {...props} />
  }
}

interface ButtonProps {
  onIntentChanged: (intent: AuthIntent) => void
  intent: AuthIntent
}

interface SignupButtonProps extends ButtonProps {}

const SignUpButton: React.FC<React.PropsWithChildren<SignupButtonProps>> = ({
  onIntentChanged,
  intent
}) => {
  return (
    <div
      data-testid="auth-signup-button"
      className={classnames(styles.tab, {
        [styles.tabSelected]: intent === AuthIntent.Register
      })}
      onClick={() => onIntentChanged(AuthIntent.Register)}
    >
      <UnderlinedText
        align="center"
        thickness={4}
        underlineColor={intent === AuthIntent.Register ? '#e32900' : 'transparent'}
      >
        Sign Up
      </UnderlinedText>
    </div>
  )
}

interface LoginButtonProps extends ButtonProps {}

const LoginButton: React.FC<React.PropsWithChildren<LoginButtonProps>> = ({
  onIntentChanged,
  intent
}) => {
  return (
    <div
      data-testid="auth-login-button"
      className={classnames(styles.tab, {
        [styles.tabSelected]: intent !== AuthIntent.Register
      })}
      onClick={() => onIntentChanged(AuthIntent.Login)}
    >
      <UnderlinedText
        align="center"
        thickness={4}
        underlineColor={intent !== AuthIntent.Register ? '#e32900' : 'transparent'}
      >
        Login
      </UnderlinedText>
    </div>
  )
}

export interface AuthenticationFlowProps
  extends Omit<
    AuthenticationFlowViewProps,
    'onIntentChanged' | 'onLogin' | 'onRegister' | 'onLogout'
  > {
  onIntentChanged?: (intent: AuthIntent) => void
  onLogin?: (user: AuthenticatedUserFragment) => void
  onRegister?: (user: AuthenticatedUserFragment) => void
  onLogout?: () => void
  swapLoginSignupOrder?: boolean
  showTabs?: boolean
}

const AuthenticationFlow: React.FC<React.PropsWithChildren<AuthenticationFlowProps>> = ({
  intent,
  onIntentChanged = () => {},
  onLogin = () => {},
  onRegister = () => {},
  onLogout = () => {},
  standalone = false,
  swapLoginSignupOrder = false,
  showTabs = true,
  ...props
}) => {
  const { refetch: refetchIdentity } = useContext(IdentityContext)

  const handleLogin = (user: AuthenticatedUserFragment) => {
    if (refetchIdentity) {
      refetchIdentity().then(() => onLogin(user))
    } else {
      onLogin(user)
    }
  }

  const handleRegister = (user: AuthenticatedUserFragment) => {
    if (refetchIdentity) {
      refetchIdentity().then(() => onRegister(user))
    } else {
      onRegister(user)
    }
  }

  const handleLogout = () => {
    if (refetchIdentity) {
      refetchIdentity().then(() => onLogout())
    } else {
      onLogout()
    }
  }

  return (
    <div
      className={classnames(styles.container, {
        [styles.standalone]: standalone
      })}
    >
      {showTabs && (
        <div className={styles.tabs}>
          {swapLoginSignupOrder ? (
            <>
              {!isBusinessMobileNative() && (
                <SignUpButton onIntentChanged={onIntentChanged} intent={intent} />
              )}
              <LoginButton onIntentChanged={onIntentChanged} intent={intent} />
            </>
          ) : (
            <>
              <LoginButton onIntentChanged={onIntentChanged} intent={intent} />
              {!isBusinessMobileNative() && (
                <SignUpButton onIntentChanged={onIntentChanged} intent={intent} />
              )}
            </>
          )}
        </div>
      )}

      <AuthenticationFlowView
        intent={intent}
        onIntentChanged={onIntentChanged}
        onLogin={handleLogin}
        onRegister={handleRegister}
        onLogout={handleLogout}
        standalone={standalone}
        {...props}
      />
    </div>
  )
}

export default AuthenticationFlow
