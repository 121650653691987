import React from 'react'
import { PromotionProgressTracker } from '#src/app/components/PromotionProgressTracker'
import {
  OrderLineItemNotEligibleForPromotionReasonFragment,
  OrderNotEligibleForPromotionReasonFragment
} from './OrderPromotionMessage.generated'
import WarningIcon from './warning.svg'
import InfoIcon from './info.svg'
import styles from './styles.css'

interface Props {
  reason?:
    | OrderNotEligibleForPromotionReasonFragment
    | OrderLineItemNotEligibleForPromotionReasonFragment
    | null
  isLineItemReason?: boolean
}

const OrderPromotionMessage: React.FC<React.PropsWithChildren<Props>> = ({
  reason,
  isLineItemReason
}) => {
  if (!reason || !reason.message) {
    return null
  }

  // For promotions that are only applicable to a single item (or limited number of items),
  //   we do not want to show an error message for every other item in the order. The behavior
  //   is fairly self-explanatory without it.
  if (reason.__typename === 'ItemCountNotEligibleForPromotionReason') {
    return null
  }

  if (reason.__typename === 'OrderAmountNotEligibleForPromotionReason') {
    if (reason.actionAppliedWhenEligible?.type === 'REWARD_CREDIT') return null

    return (
      <PromotionProgressTracker
        title={reason.message}
        progressPc={reason?.percentageOfEligibleOrderAmount || 0}
        label={`${reason?.percentageOfEligibleOrderAmount?.toString()}%`}
      />
    )
  }
  return (
    <div className={styles.warningMessageContainer}>
      {isLineItemReason ? <WarningIcon /> : <InfoIcon />}
      <div className={styles.warningMessage}>{reason.message}</div>
    </div>
  )
}

export default OrderPromotionMessage
