import uniqWith from 'lodash/uniqWith'
import {
  ProductCustomizationSelectedFeatureForGetSelectedItemFeaturesGroupedByTypeLegacyFragment,
  ProductCustomizationSpecForGetSelectedItemFeaturesGroupedByTypeLegacyFragment,
  SellableForGetSelectedItemFeaturesGroupedByTypeLegacyFragment
} from './getSelectedItemFeaturesGroupedByType.generated'

/** A normalized curated item feature which covers both sellable variant features
 * and curated item customization features.
 */
interface NormalizedFeature {
  name: string
  displayName: string
  displayValueHtml: string
}

export interface AddonFeatureGroupWithNormalizedFeatures {
  groupName: string
  features: NormalizedFeature[]
}

/** Sets of normalized features grouped by type (with attribute selections and variants merged).
 * The `addon` features are further grouped by their feature group name, since addons are always
 * associated with a feature group set.
 */
interface NormalizedFeaturesByType {
  attributeAndVariantFeatures: NormalizedFeature[]
  productFeatures: NormalizedFeature[]
  addonFeatureGroups: AddonFeatureGroupWithNormalizedFeatures[]
}

/**
 * Gets all selected variant and customization features for a given `ExpertCuratedItem`.
 *
 * Features are normalized and grouped by the type (with attribute and variants are merged).
 *
 * If a variant feature is superseded by a customization, the variant feature is
 * not included in the returned array.
 *
 * The `addon` features are further grouped by their feature group name, since addons are always
 * associated with a feature group set.
 *
 * @returns an object with the sets of variant and customization features grouped by type, with feature parameters normalized to common names.
 */
export const getSelectedItemFeaturesGroupedByTypeLegacy = (item: {
  sellable: SellableForGetSelectedItemFeaturesGroupedByTypeLegacyFragment
  customization: ProductCustomizationSpecForGetSelectedItemFeaturesGroupedByTypeLegacyFragment
  selectedCustomizationFeatures: ProductCustomizationSelectedFeatureForGetSelectedItemFeaturesGroupedByTypeLegacyFragment[]
}): NormalizedFeaturesByType => {
  const { customization, selectedCustomizationFeatures } = item
  const { variants } = item.sellable

  const normalizedFeaturesFromVariants: NormalizedFeature[] = variants.map(variant => {
    return {
      name: variant.name,
      displayName: variant.displayName || '',
      displayValueHtml: variant.displayValueHtml
    }
  })

  if (!customization) {
    return {
      attributeAndVariantFeatures: normalizedFeaturesFromVariants,
      productFeatures: [],
      addonFeatureGroups: []
    }
  }

  const normalizedFeaturesFromAttributeFeatureSelections: NormalizedFeature[] = []
  const normalizedFeaturesFromProductFeatureSelections: NormalizedFeature[] = []
  const featureGroupsWithNormalizedFeaturesFromAddonFeatureSelections: AddonFeatureGroupWithNormalizedFeatures[] =
    []

  // normalized addon features are grouped into feature groups
  const featureGroupIdMappedToFeatureGroupNameAndAddonFeatures = new Map<
    string,
    AddonFeatureGroupWithNormalizedFeatures
  >()

  // create a map of all grouped feature IDs to their parent feature groups.
  // this is so that finding parent groups in the below loop is an n(1) op
  const featureIdsMappedToFeatureGroup = customization.featureGroups.reduce((map, group) => {
    group.featureIds.forEach(featureId =>
      map.set(featureId, {
        groupId: group.id,
        groupName: group.name
      })
    )

    return map
  }, new Map<string, { groupId: string; groupName: string }>())

  // in order to ensure the addon selections are ordered according to the spec, a map of featureId to spec index is created,
  // then the selected features are sorted by their index in the spec.

  // get a map of featureId to spec index
  const featureIdsMappedToCustomizationSpecIndex = new Map<string, number>(
    customization.features.map((feature, idx) => [feature.id, idx])
  )

  // sort the selected features by their index location in the customization spec
  const sortedSelectedCustomizationFeatures = [...selectedCustomizationFeatures].sort(
    (firstFeature, secondFeature) => {
      const firstFeatureIndex = featureIdsMappedToCustomizationSpecIndex.get(
        firstFeature.feature?.id
      )
      const secondFeatureIndex = featureIdsMappedToCustomizationSpecIndex.get(
        secondFeature.feature?.id
      )

      if (firstFeatureIndex === undefined || secondFeatureIndex === undefined) {
        return 0
      }

      return firstFeatureIndex - secondFeatureIndex
    }
  )

  sortedSelectedCustomizationFeatures.forEach(selectedFeature => {
    const { selection, feature } = selectedFeature

    if (!feature) {
      return
    }

    switch (selection.__typename) {
      case 'ProductCustomizationAttributeFeatureSelection': {
        const formattedAttribute = selection.attribute.formatted

        // shouldn't typically happen, but there are cases where a customization
        // spec is uprevised and this value can return null
        if (!formattedAttribute) {
          return
        }

        // if there is a variant feature that matches the customization feature, use the `displayName` from the variant.
        const overwritingDisplayNameFromVariant = variants.find(variant => {
          return variant.name === formattedAttribute.internalName
        })?.displayName

        normalizedFeaturesFromAttributeFeatureSelections.push({
          name: formattedAttribute.internalName,
          displayName: overwritingDisplayNameFromVariant || feature.name,
          displayValueHtml: formattedAttribute.displayHtml
        })

        break
      }

      case 'ProductCustomizationProductFeatureSelection':
        normalizedFeaturesFromProductFeatureSelections.push({
          name: feature.name,
          displayName: feature.name,
          displayValueHtml: selection.sellable.title
        })

        break

      case 'ProductCustomizationAddOnFeatureSelection': {
        if (!selection.featureName) {
          return
        }

        const parentFeatureGroup = featureIdsMappedToFeatureGroup.get(feature.id)
        if (!parentFeatureGroup) {
          return
        }

        const existingFeatureGroupFeatures =
          featureGroupIdMappedToFeatureGroupNameAndAddonFeatures.get(parentFeatureGroup.groupId)
            ?.features || []

        featureGroupIdMappedToFeatureGroupNameAndAddonFeatures.set(parentFeatureGroup.groupId, {
          groupName: parentFeatureGroup.groupName,
          features: [
            ...existingFeatureGroupFeatures,
            {
              name: feature.name,
              displayName: selection.featureName,
              displayValueHtml: selection.featureName
            }
          ]
        })
      }
    }
  })

  featureGroupsWithNormalizedFeaturesFromAddonFeatureSelections.push(
    ...featureGroupIdMappedToFeatureGroupNameAndAddonFeatures.values()
  )

  // merge variant and attribute selection features (since they can overlap).
  //
  // when merging, if there are attribute selections that replace a variant feature,
  // only include the attribute selection.
  //
  // this is done by comparing feature names for uniqueness, and only including
  // the first instance of the feature in the array (attribute selection in this case).
  const mergedVariantAndAttributeSelectionFeatures = uniqWith(
    [...normalizedFeaturesFromAttributeFeatureSelections, ...normalizedFeaturesFromVariants],
    (firstFeature, secondFeature) => {
      return firstFeature.name === secondFeature.name
    }
  )

  return {
    attributeAndVariantFeatures: mergedVariantAndAttributeSelectionFeatures,
    productFeatures: normalizedFeaturesFromProductFeatureSelections,
    addonFeatureGroups: featureGroupsWithNormalizedFeaturesFromAddonFeatureSelections
  }
}
