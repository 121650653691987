import { FacetSelection as GraphQLFacetSelection } from '#src/generated/types'
import { FacetSerializer } from '../types'

interface NumericRangeFacetSelection extends GraphQLFacetSelection {
  lower?: string
  upper?: string
}

const NumericRangeFacetSerializer: FacetSerializer<NumericRangeFacetSelection> = {
  SERIALIZATION_PREFIX: 'nrange',

  isSerializable(
    facetSelection: GraphQLFacetSelection
  ): facetSelection is NumericRangeFacetSelection {
    return facetSelection.lower !== undefined || facetSelection.upper !== undefined
  },

  serialize(selection: NumericRangeFacetSelection) {
    const lower = selection.lower ? parseInt(selection.lower, 10) : ''
    const upper = selection.upper ? parseInt(selection.upper, 10) : ''

    return `${lower}-${upper}`
  },

  deserialize(value: string) {
    const [lower, upper] = value.split('-')

    return {
      lower: lower || undefined,
      upper: upper || undefined
    }
  }
}

export default NumericRangeFacetSerializer
