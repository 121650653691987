// @ts-nocheck
import type * as Types from '../../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { ConsumerProductReviewForConsumerProductReviewGalleryFragmentDoc } from '../ConsumerProductReviewGallery/ConsumerProductReviewGallery.generated';
import { ConsumerProductReviewForConsumerProductReviewFragmentDoc } from '../ConsumerProductReview/ConsumerProductReview.generated';
import { ConsumerProductReviewForConsumerProductReviewReactionsFragmentDoc, ConsumerProductReviewReactionSummaryForConsumerProductReviewReactionsFragmentDoc } from '../ConsumerProductReviewReactions/ConsumerProductReviewReactions.generated';
import { MediaFileWithCaptionFragmentDoc } from '../../../../../../fragments/MediaFileWithCaption.generated';
import { MediaFileFragmentDoc } from '../../../../../../fragments/MediaFile.generated';
export type ConsumerProductReviewForConsumerProductReviewCardFragment = { __typename: 'ConsumerProductReview', id: any, rating: number, title?: string | null, comments?: string | null, reviewedAt: any, recommended?: boolean | null, verifiedPurchaser?: boolean | null, consumerName?: string | null, consumerLocation?: string | null, medias: Array<{ __typename: 'MediaFileWithCaption', alternativeText?: string | null, caption?: string | null, mediaFile: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } }>, reviewReactionSummary?: { __typename: 'ConsumerProductReviewReactionSummary', reactionTypeAndCount: Array<{ __typename: 'ConsumerProductReviewReactionTypeAndCount', reactionType: Types.ConsumerProductReviewReactionType, count: number, consumerReaction?: { __typename: 'ConsumerProductReviewReaction', id: any, reactionType: Types.ConsumerProductReviewReactionType } | null }> } | null, source: { __typename: 'ConsumerProductReviewSourceCurated', type: Types.ConsumerProductReviewSourceType, name: string } | { __typename: 'ConsumerProductReviewSourceImported', type: Types.ConsumerProductReviewSourceType, name: string } };

export const ConsumerProductReviewForConsumerProductReviewCardFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ConsumerProductReviewForConsumerProductReviewCard"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ConsumerProductReview"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConsumerProductReviewForConsumerProductReviewGallery"}},{"kind":"Field","name":{"kind":"Name","value":"medias"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaFileWithCaption"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConsumerProductReviewForConsumerProductReviewReactions"}}]}}]} as unknown as DocumentNode;