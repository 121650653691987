// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { ConversationForIsAiConversationFragmentDoc, BusinessUserForIsAiConversationFragmentDoc, LeadForIsAiConversationFragmentDoc } from '../../services/aiChat/isAIConversation.generated';
import { BusinessUserForChatWidgetFragmentDoc } from './components/ChatWidget/ChatWidget.generated';
import { BusinessUserForChatWidgetChatFragmentDoc } from './components/Chat/Chat.generated';
import { PlaceholderExpertForDeactivatedExpertChatBannerFragmentDoc, BusinessUserForDeactivatedExpertChatBannerFragmentDoc } from '../DeactivatedExpertChatBanner/DeactivatedExpertChatBanner.generated';
import { MediaFileFragmentDoc } from '../../fragments/MediaFile.generated';
import { BusinessUserForChatExpertProfilePreviewFragmentDoc } from '../ChatExpertProfilePreview/ConversationStarterExpert.generated';
import { BusinessUserForExpertProfileLinkFragmentDoc } from '../ExpertProfileLink/ExpertProfileLink.generated';
import { ExpertForExpertAvatarLegacyFragmentDoc } from '../ExpertAvatarLegacy/ExpertAvatarLegacy.generated';
import { BusinessUserForChatWidgetBaseFragmentDoc } from '../ChatWidgetBase/ChatWidgetBase.generated';
import { BusinessUserForSwitchToRealExpertButtonFragmentDoc, ConversationForSwitchToRealExpertButtonFragmentDoc } from '../SwitchToRealExpertButton/SwitchToRealExpertButton.generated';
export type BusinessUserForActionBarFragment = { __typename: 'BusinessUser', id: any, state: Types.BusinessUserState, displayName: string, vanityId?: string | null, expertAttributes: { __typename: 'BusinessUserExpertAttributes', placeholderExpert: boolean }, existingLeadForConsumer?: { __typename: 'Lead', id: any, conversationId: any, conversation: { __typename: 'Conversation', id: any, isAiChatV2: boolean, isAiGuestChat: boolean } } | null, department: { __typename: 'Department', id: any, placeholderExpert: { __typename: 'BusinessUser', id: any, displayName: string, expertProfile?: { __typename: 'ExpertProfile', id: any, profilePhoto?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null } | null } }, expertProfile?: { __typename: 'ExpertProfile', id: any, headline: string, highlights: Array<{ __typename: 'ProfileHighlight', value: string, secondaryValue: string, type: { __typename: 'ProfileHighlightType', id: any, icon: Types.ProfileHighlightIconType } }>, profilePhoto?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null } | null, expertStats?: { __typename: 'ExpertStats', totalMessagesSent: number, totalReviews: number } | null };

export const BusinessUserForActionBarFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BusinessUserForActionBar"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"expertAttributes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"placeholderExpert"}}]}},{"kind":"Field","name":{"kind":"Name","value":"existingLeadForConsumer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"conversation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConversationForIsAIConversation"}}]}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForChatWidget"}}]}}]} as unknown as DocumentNode;