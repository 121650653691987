import { Event, ViewerContext } from '@deal/web-tracking'

export interface UserLoggedInEventProperties {
  user_id: string
  context:
    | 'sign-in-modal'
    | 'login'
    | 'login-challenge'
    | 'password-reset'
    | 'guest-checkout'
    | 'for-you-feed'
    | 'expert-ai-chat-login'
}

export class UserLoggedInEvent extends Event<UserLoggedInEventProperties> {
  static displayName = 'User Logged In'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
