import React from 'react'
import loadable from '@loadable/component'
import { useLocation } from '@deal/router'
import { parseCategoryPageUrl } from '#src/app/services/search/parseCategoryPageUrl'
import useFeedHomePageEligibilityCheck from '#src/app/services/for-you/hooks/useFeedHomePageEligibilityCheck'
import usePreferredExpert from '#src/app/hooks/usePreferredExpert'
import Page from '#src/app/components/Page'
import Footer from '#src/app/components/Footer'
import { useCategoryRouteQuery } from './CategoryRoute.generated'
import { CategoryRouteLoader } from './loading'

const PageComponent = loadable(() => import(/* webpackChunkName: "shop" */ './page'), {
  resolveComponent: page => page.CategoryPage
})

export const CategoryRoute: React.FC = () => {
  const location = useLocation()

  const searchQuery = parseCategoryPageUrl(location)

  // Prefer the expert from the `preferred_expert` cookie, if available
  const preferredExpertVanityId = usePreferredExpert()

  const query = useCategoryRouteQuery({
    variables: {
      categorySlug: searchQuery.categorySlug,
      preferredExpertVanityId
    }
  })

  const eligibleForFeedHomePage = useFeedHomePageEligibilityCheck(
    query.data?.categoryViewBySlug?.category
  )

  return (
    <>
      <Page
        query={query}
        pageKey="category"
        chat={!eligibleForFeedHomePage}
        seoTitle={({ categoryViewBySlug }) => {
          if (
            !categoryViewBySlug ||
            categoryViewBySlug.__typename !== 'BrowseCategoryPageViewModel'
          ) {
            return
          }

          return (
            categoryViewBySlug.pageMetadata?.pageTitle ||
            categoryViewBySlug.category.pluralDisplayName
          )
        }}
        seoDescription={({ categoryViewBySlug }) =>
          categoryViewBySlug?.category?.categoryAssetRevision?.openGraphDescription?.plainText
        }
        seoIndexable={({ categoryViewBySlug }) => {
          if (
            !categoryViewBySlug ||
            categoryViewBySlug.__typename !== 'BrowseCategoryPageViewModel'
          ) {
            return false
          }

          return !!categoryViewBySlug.indexable && !!categoryViewBySlug.canonicalPath
        }}
        expert={({ categoryViewBySlug }) =>
          categoryViewBySlug?.category?.recommendExpertForConsumer.expert || undefined
        }
        category={({ categoryViewBySlug }) => categoryViewBySlug?.category || undefined}
        department={({ categoryViewBySlug }) =>
          categoryViewBySlug?.category?.department || undefined
        }
        sellable={() => undefined}
        pageComponent={PageComponent}
        loadingComponent={CategoryRouteLoader}
        ogImageUrl={({ categoryViewBySlug }) =>
          categoryViewBySlug?.category?.categoryAssetRevision?.openGraphImage?.image.url
        }
        canonicalPath={({ categoryViewBySlug }) => {
          if (
            !categoryViewBySlug ||
            categoryViewBySlug.__typename !== 'BrowseCategoryPageViewModel'
          ) {
            return undefined
          }

          return categoryViewBySlug.canonicalPath
        }}
      />
      <Footer />
    </>
  )
}
