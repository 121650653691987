import { Expert, ExpertWithPopover } from '@deal/bluxome'
import {
  BusinessUserForExpertForAvatarFragment,
  BusinessUserForExpertForAvatarWithPopoverFragment
} from './BusinessUserForExpertForAvatar.generated'
import { getExpertExpertise, getExpertProfileLink } from '../expert'

/**
 * Converts a BusinessUser from GraphQL to the data structure expected by our
 *   Bluxome components.
 */
export function expertForAvatar(expert: BusinessUserForExpertForAvatarFragment): Expert {
  const profile = expert.expertProfile
  const stats = expert.expertStats
  return {
    name: expert.displayName,
    image: profile?.profilePhoto?.url,
    rating: stats?.averageRating ?? undefined,
    expertise: getExpertExpertise(expert)
  }
}

/**
 * Converts a BusinessUser from GraphQL to the data structure expected by our
 *   Bluxome components.
 */
export function expertForAvatarWithPopover(
  expert: BusinessUserForExpertForAvatarWithPopoverFragment,
  onAskAQuestionPress?: () => void
): ExpertWithPopover {
  const profile = expert.expertProfile
  const stats = expert.expertStats
  const { link, rel } = getExpertProfileLink(expert)
  return {
    ...expertForAvatar(expert),
    popover: {
      bio:
        profile?.backgroundSummaryLines && profile.backgroundSummaryLines.length > 0
          ? profile.backgroundSummaryLines.join('. ')
          : profile?.background ?? undefined,
      reviewCount: stats?.totalReviews,
      customerCount: stats?.totalLeadsParticipated,
      profileLink: link,
      profileLinkRel: rel,
      onAskAQuestionPress
    }
  }
}
