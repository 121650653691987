// @ts-nocheck
import type * as Types from '../../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { BusinessUserForExpertSelectionCardFragmentDoc } from './ExpertSelectionCard/ExpertSelectionCard.generated';
import { ExpertForExpertRatingAvatarFragmentDoc } from '../../../../../../components/ExpertRatingAvatar/ExpertRatingAvatar.generated';
export type LeadRecommendedExpertForExpertSelectionCardsFragment = { __typename: 'LeadRecommendedExpert', matchedAttributes: Array<{ __typename: 'Attribute', formatted?: { __typename: 'FormattedAttribute', displayValue: string, name: string } | null }>, expert: { __typename: 'BusinessUser', id: any, shortName: string, displayName: string, location?: { __typename: 'UserLocation', city?: string | null, state?: string | null } | null, expertStats?: { __typename: 'ExpertStats', averageRating?: number | null } | null, expertProfile?: { __typename: 'ExpertProfile', id: any, backgroundSummaryLines: Array<string> } | null } };

export type ConversationForExpertSelectionCardsFragment = { __typename: 'Conversation', id: any, lead?: { __typename: 'Lead', id: any, recommendFallbackExperts: Array<{ __typename: 'LeadRecommendedExpert', matchedAttributes: Array<{ __typename: 'Attribute', formatted?: { __typename: 'FormattedAttribute', displayValue: string, name: string } | null }>, expert: { __typename: 'BusinessUser', id: any, shortName: string, displayName: string, location?: { __typename: 'UserLocation', city?: string | null, state?: string | null } | null, expertStats?: { __typename: 'ExpertStats', averageRating?: number | null } | null, expertProfile?: { __typename: 'ExpertProfile', id: any, backgroundSummaryLines: Array<string> } | null } }> } | null };

export const LeadRecommendedExpertForExpertSelectionCardsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LeadRecommendedExpertForExpertSelectionCards"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"LeadRecommendedExpert"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"matchedAttributes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"formatted"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"displayValue"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"expert"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"shortName"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForExpertSelectionCard"}}]}}]}}]} as unknown as DocumentNode;
export const ConversationForExpertSelectionCardsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ConversationForExpertSelectionCards"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Conversation"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"lead"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"recommendFallbackExperts"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LeadRecommendedExpertForExpertSelectionCards"}}]}}]}}]}}]} as unknown as DocumentNode;