import React from 'react'
import loadable from '@loadable/component'
import { useIdentityContext } from '#src/app/containers/Identity'
import Page from '#src/app/components/Page'
import {
  LeadChangedForInboxDocument,
  useInboxConversationRouteQuery
} from './InboxConversationRoute.generated'
import LoadingComponent from '../loading'

const PageComponent = loadable(
  () => import(/* webpackChunkName: "inbox-conversation" */ './page'),
  {
    resolveComponent: components => components.Inbox
  }
)

interface InboxConversationRouteProps {
  conversationId: string
}

const InboxConversationRoute: React.FC<InboxConversationRouteProps> = ({ conversationId }) => {
  const { myself } = useIdentityContext()
  const query = useInboxConversationRouteQuery({
    variables: {
      consumerId: myself!.user.id,
      conversationId
    }
  })

  return (
    <Page
      fixed
      query={query}
      pageKey="inbox-conversation"
      chat={false}
      seoTitle={undefined}
      seoDescription={undefined}
      seoIndexable={false}
      expert={data => data.conversation.lead?.expert}
      category={data => data.conversation.lead?.category}
      department={data => data.conversation.lead?.department}
      sellable={undefined}
      pageComponent={PageComponent}
      loadingComponent={LoadingComponent}
      pageProps={{
        onSubscribeToMore: (leadId: string) => {
          query.subscribeToMore({
            document: LeadChangedForInboxDocument,
            variables: {
              leadId: leadId
            },
            updateQuery: (previousQueryResults, { subscriptionData }) => {
              if (!subscriptionData) return previousQueryResults
              return Object.assign({}, previousQueryResults, {
                ...subscriptionData.data
              })
            }
          })
        }
      }}
      ogImageUrl={undefined}
      canonicalPath={undefined}
    />
  )
}

export default InboxConversationRoute
