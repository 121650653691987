import React, { useContext } from 'react'
import lowerCase from 'lodash/lowerCase'
import { useHistory } from '@deal/router'
import { formatPathUrl, getOnboardingPathSlug, useCreateRequestPath } from '#src/app/services/path'
import { DepartmentContext } from '#src/app/context/department'
import PathSubmitButtonLegacy from '#src/app/components/PathSubmitButtonLegacy'
import CuratedBenefitsList from '#src/app/components/CuratedBenefitsList'
import AuthenticationFlowViewLayout from '#src/app/components/AuthenticationFlow/components/AuthenticationFlowViewLayout/v1'
import { RegisterViewProps } from '../../'
import styles from './styles.css'

export const RegisterViaPath: React.FC<React.PropsWithChildren<RegisterViewProps>> = ({
  standalone
}) => {
  const history = useHistory()
  const { department } = useContext(DepartmentContext)

  const [createPath, { loading }] = useCreateRequestPath({
    onPathCreated: ({ pathGraphFlow, pathGraphFlowSlug, nodeSlugOrId }) => {
      if (pathGraphFlow) {
        history.push(
          formatPathUrl({
            pathGraphFlowId: pathGraphFlow.pathGraphFlowId,
            nodeSlugOrId,
            pathGraphFlowSlug
          })
        )
      } else {
        throw new Error('Error creating path for `RegisterViaPath` component!')
      }
    }
  })

  return (
    <>
      <AuthenticationFlowViewLayout standalone={standalone}>
        <div className={styles.header}>{`Sign up for Curated and work with a real ${
          lowerCase(department?.displayName) || ''
        } expert who will`}</div>
        <CuratedBenefitsList />

        <PathSubmitButtonLegacy
          loading={loading}
          onClick={() => {
            createPath({
              pathSelector: department
                ? {
                    categorySlugHierarchyLookup: department.slug
                  }
                : {
                    pathSchemaSlug: getOnboardingPathSlug()
                  },
              trackingCodes: {
                ctaName: 'register-via-path',
                sourceKey: 'register-via-path'
              }
            })
          }}
          variant="primary"
          className={styles.submitButton}
        >
          Get started
        </PathSubmitButtonLegacy>
      </AuthenticationFlowViewLayout>
    </>
  )
}
