import React from 'react'
import { Route } from '@deal/router'

interface RouteWithStatusCodeProps {
  statusCode: number
}

const RouteWithStatusCode: React.FC<React.PropsWithChildren<RouteWithStatusCodeProps>> = ({ statusCode, children }) => {
  return (
    <Route
      render={({ staticContext }) => {
        // StaticContext will be undefined in the browser
        if (staticContext) {
          staticContext.statusCode = statusCode
        }

        return <>{children}</>
      }}
    />
  )
}

export default RouteWithStatusCode
