// @ts-nocheck
import type * as Types from '../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type GetLatestMessageSuggestionsQueryVariables = Types.Exact<{
  leadId: Types.Scalars['LeadId']['input'];
}>;


export type GetLatestMessageSuggestionsQuery = { __typename: 'Query', getLatestMessageSuggestions: Array<{ __typename: 'AIGuestChatActionSuggestion', label: string, action: Types.AiGuestChatSuggestedAction } | { __typename: 'AIGuestChatMessageSuggestion', label: string }> };

export type GetLatestInferredCategorQueryVariables = Types.Exact<{
  leadId: Types.Scalars['LeadId']['input'];
}>;


export type GetLatestInferredCategorQuery = { __typename: 'Query', getLatestInferredCategory?: { __typename: 'Category', id: any } | null };


export const GetLatestMessageSuggestionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetLatestMessageSuggestions"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"leadId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LeadId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getLatestMessageSuggestions"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"leadId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"leadId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AIGuestChatActionSuggestion"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"action"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AIGuestChatMessageSuggestion"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"label"}}]}}]}}]}}]} as unknown as DocumentNode;
export type GetLatestMessageSuggestionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLatestMessageSuggestionsQuery, GetLatestMessageSuggestionsQueryVariables>, 'query'> & ({ variables: GetLatestMessageSuggestionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLatestMessageSuggestionsComponent = (props: GetLatestMessageSuggestionsComponentProps) => (
      <ApolloReactComponents.Query<GetLatestMessageSuggestionsQuery, GetLatestMessageSuggestionsQueryVariables> query={GetLatestMessageSuggestionsDocument} {...props} />
    );
    

/**
 * __useGetLatestMessageSuggestionsQuery__
 *
 * To run a query within a React component, call `useGetLatestMessageSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestMessageSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestMessageSuggestionsQuery({
 *   variables: {
 *      leadId: // value for 'leadId'
 *   },
 * });
 */
export function useGetLatestMessageSuggestionsQuery(baseOptions: Apollo.QueryHookOptions<GetLatestMessageSuggestionsQuery, GetLatestMessageSuggestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestMessageSuggestionsQuery, GetLatestMessageSuggestionsQueryVariables>(GetLatestMessageSuggestionsDocument, options);
      }
export function useGetLatestMessageSuggestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestMessageSuggestionsQuery, GetLatestMessageSuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestMessageSuggestionsQuery, GetLatestMessageSuggestionsQueryVariables>(GetLatestMessageSuggestionsDocument, options);
        }
export function useGetLatestMessageSuggestionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLatestMessageSuggestionsQuery, GetLatestMessageSuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLatestMessageSuggestionsQuery, GetLatestMessageSuggestionsQueryVariables>(GetLatestMessageSuggestionsDocument, options);
        }
export type GetLatestMessageSuggestionsQueryHookResult = ReturnType<typeof useGetLatestMessageSuggestionsQuery>;
export type GetLatestMessageSuggestionsLazyQueryHookResult = ReturnType<typeof useGetLatestMessageSuggestionsLazyQuery>;
export type GetLatestMessageSuggestionsSuspenseQueryHookResult = ReturnType<typeof useGetLatestMessageSuggestionsSuspenseQuery>;
export type GetLatestMessageSuggestionsQueryResult = Apollo.QueryResult<GetLatestMessageSuggestionsQuery, GetLatestMessageSuggestionsQueryVariables>;
export const GetLatestInferredCategorDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetLatestInferredCategor"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"leadId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LeadId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getLatestInferredCategory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"leadId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"leadId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export type GetLatestInferredCategorComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLatestInferredCategorQuery, GetLatestInferredCategorQueryVariables>, 'query'> & ({ variables: GetLatestInferredCategorQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLatestInferredCategorComponent = (props: GetLatestInferredCategorComponentProps) => (
      <ApolloReactComponents.Query<GetLatestInferredCategorQuery, GetLatestInferredCategorQueryVariables> query={GetLatestInferredCategorDocument} {...props} />
    );
    

/**
 * __useGetLatestInferredCategorQuery__
 *
 * To run a query within a React component, call `useGetLatestInferredCategorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestInferredCategorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestInferredCategorQuery({
 *   variables: {
 *      leadId: // value for 'leadId'
 *   },
 * });
 */
export function useGetLatestInferredCategorQuery(baseOptions: Apollo.QueryHookOptions<GetLatestInferredCategorQuery, GetLatestInferredCategorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestInferredCategorQuery, GetLatestInferredCategorQueryVariables>(GetLatestInferredCategorDocument, options);
      }
export function useGetLatestInferredCategorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestInferredCategorQuery, GetLatestInferredCategorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestInferredCategorQuery, GetLatestInferredCategorQueryVariables>(GetLatestInferredCategorDocument, options);
        }
export function useGetLatestInferredCategorSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLatestInferredCategorQuery, GetLatestInferredCategorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLatestInferredCategorQuery, GetLatestInferredCategorQueryVariables>(GetLatestInferredCategorDocument, options);
        }
export type GetLatestInferredCategorQueryHookResult = ReturnType<typeof useGetLatestInferredCategorQuery>;
export type GetLatestInferredCategorLazyQueryHookResult = ReturnType<typeof useGetLatestInferredCategorLazyQuery>;
export type GetLatestInferredCategorSuspenseQueryHookResult = ReturnType<typeof useGetLatestInferredCategorSuspenseQuery>;
export type GetLatestInferredCategorQueryResult = Apollo.QueryResult<GetLatestInferredCategorQuery, GetLatestInferredCategorQueryVariables>;