import React from 'react'
import times from 'lodash/times'
import { useBreakpoint } from '#src/app/hooks/useBreakpoint'
import { Skeleton } from '#src/app/components/SkeletonLoader'
import SkeletonListItem from '../components/SkeletonListItem'
import styles from './styles.css'

const Loading: React.FC = () => {
  const isMobile = !useBreakpoint('md')

  return (
    <div className={styles.inbox}>
      <div className={styles.wrapper}>
        <div className={styles.column}>
          <ul>
            {times(4).map(entry => (
              <SkeletonListItem key={entry} />
            ))}
          </ul>
        </div>

        {!isMobile && (
          <div className={styles.empty}>
            <Skeleton width={450} height={250} />
          </div>
        )}
      </div>
    </div>
  )
}

export default Loading
