// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { ConversationStartersFragmentDoc } from '../../fragments/ConversationStarter.generated';
import { SellableForFormatSellablePricingFragmentDoc } from '../../fragments/SellableForFormatSellablePricing.generated';
import { MonetaryAmountFragmentDoc } from '../../fragments/MonetaryAmount.generated';
import { SellableForNormalizeSellablePricingFragmentDoc } from '../../fragments/SellableForNormalizeSellablePricing.generated';
export type SellableForConversationStartersPillsFragment = { __typename: 'Sellable', id: any, title: string, bestOfferVisibility: Types.SellableBestOfferVisibility, images: Array<{ __typename: 'MediaFile', id: any, url: string }>, conversationStartersV2: { __typename: 'ConversationStarters', summary?: string | null, suggestions: Array<string> }, productBundle?: { __typename: 'ProductBundleRevision', id: any, salePriceRange?: { __typename: 'MonetaryAmountRange', from?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null, to?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null } | null } | null, bestOfferPrice?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null, salePrice?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null, originalPrice?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null, variationMatrix?: { __typename: 'VariationMatrix', maximumSavingsPercent?: number | null, priceRange?: { __typename: 'VariationMatrixPriceRange', min: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string }, max: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } } | null, originalPriceRange?: { __typename: 'VariationMatrixPriceRange', min: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string }, max: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } } | null, maximumSavingsAmount?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null } | null };

export type BusinessUserForConversationStartersPillsFragment = { __typename: 'BusinessUser', id: any, conversationStarters?: { __typename: 'ConversationStarters', summary?: string | null, suggestions: Array<string> } | null };

export type CategoryForConversationStartersPillsFragment = { __typename: 'Category', id: any, slug: string };

export type InfluencerForConversationStartersPillsFragment = { __typename: 'Influencer', id: any, profile: { __typename: 'InfluencerProfile', id: any, displayName: string } };

export const SellableForConversationStartersPillsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableForConversationStartersPills"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Sellable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"images"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"url"}}]}},{"kind":"Field","name":{"kind":"Name","value":"conversationStartersV2"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConversationStarters"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForFormatSellablePricing"}}]}}]} as unknown as DocumentNode;
export const BusinessUserForConversationStartersPillsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BusinessUserForConversationStartersPills"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"conversationStarters"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConversationStarters"}}]}}]}}]} as unknown as DocumentNode;
export const CategoryForConversationStartersPillsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CategoryForConversationStartersPills"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Category"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}}]}}]} as unknown as DocumentNode;
export const InfluencerForConversationStartersPillsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"InfluencerForConversationStartersPills"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Influencer"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"profile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}}]}}]}}]} as unknown as DocumentNode;