import React, { useEffect, useState } from 'react'
import { useLocation } from '@deal/router'
import { Image } from '@deal/components'
import { Button, Heading, HeadlessModal, Text, useModalTrigger } from '@deal/bluxome'
import { useProductListingLocation } from '#src/app/hooks/useProductListingLocation'
import clientOnly from '#src/app/hocs/clientOnly'
import { DepartmentForDepartmentContextFragment } from '#src/app/context/department/DepartmentForDepartmentContext.generated'
import { useDepartment } from '#src/app/context/department'
import GolfImage from './farwell-golf.jpeg'
import SnowboardingImage from './farewell-snowboarding.jpeg'
import SkiingImage from './farewell-skiing.jpeg'
import KitchenImage from './farewell-kitchen.jpeg'
import styles from './styles.css'

const IS_MODAL_DISMISSED_LOCAL_STORAGE_KEY = 'farewell-modal-dismissed'

interface FarewellModalProps {
  category?: DepartmentForDepartmentContextFragment['topLevelCategory'] | null
}

const FarewellModal: React.FC<FarewellModalProps> = ({ category }) => {
  const productListingLocation = useProductListingLocation()

  const { state, overlayProps } = useModalTrigger({
    defaultOpen: window.localStorage.getItem(IS_MODAL_DISMISSED_LOCAL_STORAGE_KEY) !== 'true',
    // The modal will only be displayed once if dismissed
    onOpenChange(isOpen) {
      if (!isOpen) {
        window.localStorage.setItem(IS_MODAL_DISMISSED_LOCAL_STORAGE_KEY, 'true')
      }
    }
  })

  const imageSrc = {
    golf: GolfImage,
    'winter-sports': SnowboardingImage,
    snowboarding: SnowboardingImage,
    skiing: SkiingImage,
    kitchen: KitchenImage,
    'coffee-espresso': KitchenImage
  }[category?.slug || '']

  return (
    <HeadlessModal overlayProps={overlayProps} width="large" state={state}>
      {({ titleProps }) => (
        <div className={styles.modal}>
          {imageSrc != null && (
            <div className={styles.image}>
              <Image src={imageSrc} size="100vw" sizeSm="400px" htmlAttributes={{ alt: '' }} />
            </div>
          )}

          <div className={styles.modalContent}>
            <div {...titleProps} className="mb-2">
              <Heading size="h3" as="h3" color="neutrals-black">
                Farewell Sale! Up to 75% Off
              </Heading>
            </div>

            <Text as="div" style="base-loose" color="neutrals-600">
              This isn't the message we wanted to give, but here it is — we're going out of
              business. That means everything must go, and we're offering up to 75% off all{' '}
              {category ? `${category.pluralDisplayName} ` : ''}gear while supplies last.
            </Text>

            <div className={styles.cta}>
              <Button
                color="shop-orange"
                link={{
                  to: productListingLocation({
                    categorySlug: category?.slug || 'products',
                    filters: ['CLEARANCE'],
                    sortBy: 'MAX_PRICE_PERCENT_SAVINGS'
                  }),
                  onClick: state.close
                }}
                fullWidth
              >
                SHOP FINAL SALE
              </Button>
            </div>
          </div>
        </div>
      )}
    </HeadlessModal>
  )
}

const FarewellModalClientOnly = clientOnly(FarewellModal, {
  fallback: () => null
})

// The modal uses `defaultOpen: true` and is only rendered on the client,
// so rendering conditions are checked in this parent component
const FarewellModalWrapper: React.FC = () => {
  const department = useDepartment()
  const { pathname } = useLocation()

  // NB: The farewell sale modal should "pop" on most pages, except for the below...
  const isModalDisabled =
    pathname.startsWith('/lp') ||
    pathname.startsWith('/sale') ||
    pathname.startsWith('/clearance') ||
    pathname.startsWith('/path') ||
    pathname.startsWith('/inbox') ||
    pathname.startsWith('/legal') ||
    pathname.startsWith('/company') ||
    pathname.startsWith('/scout')

  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (isModalDisabled) {
      return
    }
    // After determining that the modal can open, wait 3s before rendering for SEO/UX purposes
    const timer = setTimeout(() => setIsVisible(true), 3_000)
    return () => {
      clearTimeout(timer)
    }
  }, [isModalDisabled])

  if (isModalDisabled || !isVisible) {
    return null
  }

  return <FarewellModalClientOnly category={department?.topLevelCategory} />
}

export { FarewellModalWrapper as FarewellModal }
