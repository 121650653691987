import React from 'react'
import loadable from '@loadable/component'
import { Route, Switch, useLocation } from '@deal/router'
import { useIdentityContext } from '#src/app/containers/Identity'
import Page from '#src/app/components/Page'
import Footer from '#src/app/components/Footer'
import { useProductReviewsRouteQuery } from './ProductReviewsRoute.generated'
import { Loader } from './loading'
import { PostFromEmail } from './email-post'

const PageComponent = loadable(() => import(/* webpackChunkName: "product-reviews" */ './page'), {
  resolveComponent: page => page.ProductReviewsPage
})

const ProductReviewsBaseRoute: React.FC = () => {
  const { myself } = useIdentityContext()

  const { search } = useLocation()
  const urlSearchParams = new URLSearchParams(search)
  const token = urlSearchParams.get('token')
  const lineItemId = urlSearchParams.get('lineItemId')

  const query = useProductReviewsRouteQuery({
    variables: {
      input: {
        consumerId: myself?.consumer?.id,
        selectedLineItemId: lineItemId,
        emailToken: token
      }
    }
  })

  return (
    <>
      <Page
        query={query}
        pageComponent={PageComponent}
        pageKey="product-reviews"
        seoTitle="Curated | Review your purchases"
        seoIndexable={false}
        loadingComponent={Loader}
        chat={false}
        department={undefined}
        expert={undefined}
        category={undefined}
        ogImageUrl={undefined}
        seoDescription={undefined}
        sellable={undefined}
      />
      <Footer />
    </>
  )
}

export const ProductReviewsRouteSwitch = () => {
  return (
    <Switch>
      <Route path="/product-reviews/email-post" exact={true} component={PostFromEmail} />
      <Route path="/product-reviews" component={ProductReviewsBaseRoute} />
    </Switch>
  )
}
