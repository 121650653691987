// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SellableTopListForTopListCardFragment = { __typename: 'SellableTopList', id: any, title: string, friendlyId: any, slug: string, description?: string | null, cardImage?: { __typename: 'MediaFileWithCaption', alternativeText?: string | null, mediaFile: { __typename: 'MediaFile', id: any, url: string } } | null };

export type SellableTopListsForTopListCarouselQueryVariables = Types.Exact<{
  sellableTopListIds: Array<Types.Scalars['SellableTopListId']['input']> | Types.Scalars['SellableTopListId']['input'];
}>;


export type SellableTopListsForTopListCarouselQuery = { __typename: 'Query', sellableTopLists: Array<{ __typename: 'SellableTopList', id: any, title: string, friendlyId: any, slug: string, description?: string | null, cardImage?: { __typename: 'MediaFileWithCaption', alternativeText?: string | null, mediaFile: { __typename: 'MediaFile', id: any, url: string } } | null }> };

export const SellableTopListForTopListCardFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableTopListForTopListCard"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SellableTopList"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"friendlyId"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"cardImage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"alternativeText"}},{"kind":"Field","name":{"kind":"Name","value":"mediaFile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}}]}}]} as unknown as DocumentNode;
export const SellableTopListsForTopListCarouselDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SellableTopListsForTopListCarousel"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"sellableTopListIds"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SellableTopListId"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sellableTopLists"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"ids"},"value":{"kind":"Variable","name":{"kind":"Name","value":"sellableTopListIds"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableTopListForTopListCard"}}]}}]}},...SellableTopListForTopListCardFragmentDoc.definitions]} as unknown as DocumentNode;
export type SellableTopListsForTopListCarouselComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SellableTopListsForTopListCarouselQuery, SellableTopListsForTopListCarouselQueryVariables>, 'query'> & ({ variables: SellableTopListsForTopListCarouselQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SellableTopListsForTopListCarouselComponent = (props: SellableTopListsForTopListCarouselComponentProps) => (
      <ApolloReactComponents.Query<SellableTopListsForTopListCarouselQuery, SellableTopListsForTopListCarouselQueryVariables> query={SellableTopListsForTopListCarouselDocument} {...props} />
    );
    

/**
 * __useSellableTopListsForTopListCarouselQuery__
 *
 * To run a query within a React component, call `useSellableTopListsForTopListCarouselQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellableTopListsForTopListCarouselQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellableTopListsForTopListCarouselQuery({
 *   variables: {
 *      sellableTopListIds: // value for 'sellableTopListIds'
 *   },
 * });
 */
export function useSellableTopListsForTopListCarouselQuery(baseOptions: Apollo.QueryHookOptions<SellableTopListsForTopListCarouselQuery, SellableTopListsForTopListCarouselQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SellableTopListsForTopListCarouselQuery, SellableTopListsForTopListCarouselQueryVariables>(SellableTopListsForTopListCarouselDocument, options);
      }
export function useSellableTopListsForTopListCarouselLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SellableTopListsForTopListCarouselQuery, SellableTopListsForTopListCarouselQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SellableTopListsForTopListCarouselQuery, SellableTopListsForTopListCarouselQueryVariables>(SellableTopListsForTopListCarouselDocument, options);
        }
export function useSellableTopListsForTopListCarouselSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SellableTopListsForTopListCarouselQuery, SellableTopListsForTopListCarouselQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SellableTopListsForTopListCarouselQuery, SellableTopListsForTopListCarouselQueryVariables>(SellableTopListsForTopListCarouselDocument, options);
        }
export type SellableTopListsForTopListCarouselQueryHookResult = ReturnType<typeof useSellableTopListsForTopListCarouselQuery>;
export type SellableTopListsForTopListCarouselLazyQueryHookResult = ReturnType<typeof useSellableTopListsForTopListCarouselLazyQuery>;
export type SellableTopListsForTopListCarouselSuspenseQueryHookResult = ReturnType<typeof useSellableTopListsForTopListCarouselSuspenseQuery>;
export type SellableTopListsForTopListCarouselQueryResult = Apollo.QueryResult<SellableTopListsForTopListCarouselQuery, SellableTopListsForTopListCarouselQueryVariables>;