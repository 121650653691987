// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type MarkFeedNotificationsAsSeenMutationVariables = Types.Exact<{
  recipientIds: Array<Types.Scalars['AnyId']['input']> | Types.Scalars['AnyId']['input'];
}>;


export type MarkFeedNotificationsAsSeenMutation = { __typename: 'Mutation', markFeedNotificationsSeen: { __typename: 'MarkFeedNotificationsSeenPayload', success: boolean } };


export const MarkFeedNotificationsAsSeenDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"MarkFeedNotificationsAsSeen"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"recipientIds"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AnyId"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"markFeedNotificationsSeen"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"recipientIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"recipientIds"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode;
export type MarkFeedNotificationsAsSeenMutationFn = Apollo.MutationFunction<MarkFeedNotificationsAsSeenMutation, MarkFeedNotificationsAsSeenMutationVariables>;
export type MarkFeedNotificationsAsSeenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MarkFeedNotificationsAsSeenMutation, MarkFeedNotificationsAsSeenMutationVariables>, 'mutation'>;

    export const MarkFeedNotificationsAsSeenComponent = (props: MarkFeedNotificationsAsSeenComponentProps) => (
      <ApolloReactComponents.Mutation<MarkFeedNotificationsAsSeenMutation, MarkFeedNotificationsAsSeenMutationVariables> mutation={MarkFeedNotificationsAsSeenDocument} {...props} />
    );
    

/**
 * __useMarkFeedNotificationsAsSeenMutation__
 *
 * To run a mutation, you first call `useMarkFeedNotificationsAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkFeedNotificationsAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markFeedNotificationsAsSeenMutation, { data, loading, error }] = useMarkFeedNotificationsAsSeenMutation({
 *   variables: {
 *      recipientIds: // value for 'recipientIds'
 *   },
 * });
 */
export function useMarkFeedNotificationsAsSeenMutation(baseOptions?: Apollo.MutationHookOptions<MarkFeedNotificationsAsSeenMutation, MarkFeedNotificationsAsSeenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkFeedNotificationsAsSeenMutation, MarkFeedNotificationsAsSeenMutationVariables>(MarkFeedNotificationsAsSeenDocument, options);
      }
export type MarkFeedNotificationsAsSeenMutationHookResult = ReturnType<typeof useMarkFeedNotificationsAsSeenMutation>;
export type MarkFeedNotificationsAsSeenMutationResult = Apollo.MutationResult<MarkFeedNotificationsAsSeenMutation>;
export type MarkFeedNotificationsAsSeenMutationOptions = Apollo.BaseMutationOptions<MarkFeedNotificationsAsSeenMutation, MarkFeedNotificationsAsSeenMutationVariables>;