// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { CategoryForDynamicSearchAdsContentTargetFragmentDoc } from '../DynamicSearchAdsContentTarget/CategoryForDynamicSearchAdsContentTarget.generated';
import { CategoryForGetMarketableCategoryFromCategoryHierarchyFragmentDoc } from '../../services/tracking/CategoryForGetMarketableCategoryFromCategoryHierarchy.generated';
import { CategoryForEngagementChannelsFragmentDoc } from '../EngagementChannels/EngagementChannels.generated';
import { CategoryForGetClosestNavigationForCategoryFragmentDoc } from '../Header/mobile/components/MobileNavFlyout/MobileNavFlyout.generated';
import { CategoryForUseContentSelectionResultForEcFragmentDoc } from '../../hooks/useContentSelectionResultForEC/UseContentSelectionResultForEC.generated';
export type CategoryForPageFragment = { __typename: 'Category', slug: string, apparel: boolean, id: any, department?: { __typename: 'Department', id: any, slug: string, topLevelCategory: { __typename: 'Category', id: any, slug: string } } | null, parents: Array<{ __typename: 'Category', id: any, slug: string }> };

export const CategoryForPageFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CategoryForPage"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Category"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CategoryForDynamicSearchAdsContentTarget"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"CategoryForEngagementChannels"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"CategoryForGetClosestNavigationForCategory"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"CategoryForUseContentSelectionResultForEC"}}]}}]} as unknown as DocumentNode;