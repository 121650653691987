import { useContext, useEffect } from 'react'
import { ChatContext } from '@deal/chat-firebase'
import { useChatWidgetStateContext } from '#src/app/context/chat-widget-state'

export const useExpandChatOnceOnUnreadMessages = () => {
  const { unreadMessageCount } = useContext(ChatContext)
  const { previewChatWidget } = useChatWidgetStateContext()

  useEffect(() => {
    const isUnreadMessageCountChecked =
      window.sessionStorage.getItem('unread-message-count-checked') === 'true'
    if (unreadMessageCount && !isUnreadMessageCountChecked) {
      previewChatWidget('new-message')
      window.sessionStorage.setItem('unread-message-count-checked', 'true')
    }
  }, [unreadMessageCount, previewChatWidget])
}
