import React, { useEffect, useRef } from 'react'
import classnames from 'classnames'
import { usePrevious } from '@deal/dom-hooks'
import { TextField } from '@deal/components'
import styles from './styles.css'

type SixDigitCode = [string, string, string, string, string, string]

interface SixDigitCodeFieldProps {
  value: string
  onChange?: (code: string) => void
  onSubmit?: (code: string) => void
  className?: string
  disabled?: boolean
  isRebranded?: boolean
}

export const SixDigitCodeField: React.FC<React.PropsWithChildren<SixDigitCodeFieldProps>> = ({
  value,
  onChange,
  onSubmit,
  className,
  disabled,
  isRebranded
}) => {
  const inputRefs = new Array(6).fill(undefined).map(() => useRef<HTMLInputElement>(null))

  // Convert the string value into a SixDigitCode, ensuring it is a fixed-length array.
  const code = new Array(6).fill('').map((_, index) => value[index] || '')

  /**
   * Insert digit(s) into the SixDigitCode state, and move the focus to the next open
   *   input, or submit the form if the full code has been entered.
   *
   * If the inserted text is too long (i.e. it would cause the SixDigitCode to be more
   *   than 6 digits), the extra digits are ignored.
   */
  const updateCode = (insertText: string, startingAt: number) => {
    // Ensure we aren't inserting non-numeric text, or more text than fits
    insertText = insertText.replace(/[^\d]/g, '').slice(0, 6 - startingAt)

    // Update the SixDigitCode state
    const updatedCode = code.slice(0) as SixDigitCode
    updatedCode.splice(startingAt, insertText.length, ...insertText.split(''))
    if (onChange) {
      onChange(updatedCode.join(''))
    }

    // Adjust focus or submit
    const nextIndex = startingAt + insertText.length
    const nextElement = inputRefs[nextIndex] && inputRefs[nextIndex].current

    if (nextElement instanceof HTMLInputElement) {
      // Focus on the next input
      nextElement.focus()

      // Setting the caret position is only valid when the input has a value
      if (nextElement.value.length) {
        nextElement.setSelectionRange(0, 0)
      }
    } else {
      // There are no more inputs, auto-submit the code
      const lastInput = inputRefs[5].current as HTMLInputElement
      lastInput.focus()
      if (onSubmit) {
        onSubmit(updatedCode.join(''))
      }
    }

    return updatedCode
  }

  /**
   * Clear digit(s) from the SixDigitCode state.
   */
  const clearCode = (startingAt: number, count: number = 1) => {
    const updatedCode = code.slice(0) as SixDigitCode
    updatedCode.splice(startingAt, count, ...new Array(count).fill(''))
    if (onChange) {
      onChange(updatedCode.join(''))
    }

    return updatedCode
  }

  /**
   * Handle "paste" events
   */
  const handlePaste =
    (fieldIndex: number): React.ClipboardEventHandler =>
    e => {
      e.preventDefault()

      const text = e.clipboardData.getData('Text').trim()

      // A numeric string was pasted
      if (/\d+/.test(text)) {
        // Update the values of the digits starting from the focused input
        updateCode(text, fieldIndex)
      }
    }

  /**
   * Handle "change" events.
   *
   * Note: This will capture a user entering a single digit into an input, but in the
   *   case  of iOS's Secure Code autofill feature a single "change" event will be
   *   fired with all 6 digits.
   */
  const handleChange =
    (fieldIndex: number): React.ChangeEventHandler<HTMLInputElement> =>
    e => {
      e.preventDefault()

      const text = e.target.value

      // A numeric string was changed
      if (/\d+/.test(text)) {
        // Update the values of the digits starting from the focused input
        updateCode(text, fieldIndex)
      }
    }

  /**
   * Handle "keydown" events.
   *
   * This is where we handle the "backspace" behavior.
   */
  const handleKeyDown =
    (fieldIndex: number): React.KeyboardEventHandler =>
    e => {
      // Backspace was pressed
      if (e.keyCode === 8) {
        const existingDigitValue = code[fieldIndex]

        // If there was previously a value, delete it.
        if (existingDigitValue.length) {
          clearCode(fieldIndex)
        }

        // Otherwise, delete the preceding digit and the focus to the previous form element
        else if (fieldIndex > 0) {
          clearCode(fieldIndex - 1)

          const previousFormElement = inputRefs[fieldIndex - 1].current

          if (previousFormElement) {
            previousFormElement.focus()
          }
        }
      }
    }

  /**
   * The `autoFocus` prop is unreliable. Explicitly set focus on the first form element
   *   on mount.
   */
  useEffect(() => {
    inputRefs[0].current?.focus()
  }, [])

  /**
   * Similarly, reset the focus to the last form element if it was previously disabled
   *   but no longer is.
   */
  const previouslyDisabled = usePrevious(disabled)
  useEffect(() => {
    if (previouslyDisabled && !disabled) {
      inputRefs[5].current?.focus()
    }
  }, [disabled])

  return (
    <div className={classnames(styles.digits, className)}>
      {[0, 1, 2, 3, 4, 5].map(index => {
        return (
          <TextField
            ref={inputRefs[index]}
            key={`digit-${index}`}
            label={`Code ${index}`}
            autoFocus={index === 0}
            labelHidden
            placeholder="0"
            className={styles.digitField}
            innerInputContainerClassName={isRebranded ? styles.digitFieldInput : undefined}
            inputProps={{
              size: 1,
              min: 0,
              max: 9,
              className: styles.digitInput,
              onChange: handleChange(index),
              onPaste: handlePaste(index),
              onKeyDown: handleKeyDown(index),
              autoComplete: 'one-time-code'
            }}
            type="number"
            value={code[index]}
            disabled={disabled}
          />
        )
      })}
    </div>
  )
}

export default SixDigitCodeField
