import React, { useContext, useState } from 'react'
import { useHistory } from '@deal/router'

type CartVisibilityContextType = {
  isCartVisible: boolean
  setCartVisibility: React.Dispatch<React.SetStateAction<boolean>>
}

// Context
const CartVisibilityContext = React.createContext<CartVisibilityContextType | null>(null)

// Provider
const CartVisibilityProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const history = useHistory()
  // We initialize the context with isCartVisible set to true when
  // the URL contains #cart, as some experts pass this URL to users
  // for convenience
  const [isCartVisible, setCartVisibility] = useState(history.location.hash === '#cart' || false)

  return (
    <CartVisibilityContext.Provider
      value={{
        isCartVisible,
        setCartVisibility
      }}
    >
      {children}
    </CartVisibilityContext.Provider>
  )
}

const useCartVisibilityContext = (): CartVisibilityContextType => {
  const cartVisibilityContext = useContext(CartVisibilityContext)
  if (!cartVisibilityContext) {
    throw new Error('Invoked `CartVisibilityContext` outside of provider')
  }

  return cartVisibilityContext
}

export { CartVisibilityProvider, useCartVisibilityContext }
