import React, { useContext, useEffect, useState } from 'react'
import { ChatContext } from '@deal/chat-firebase'
import { Text } from '@deal/bluxome'
import { BusinessUserForChatWidgetBaseFragment } from '../ChatWidgetBase.generated'

interface ChatWidgetHeaderTextProps {
  expert: BusinessUserForChatWidgetBaseFragment | 'AI'
  isVisible: boolean
}

const ChatWidgetHeaderText: React.FC<ChatWidgetHeaderTextProps> = ({ expert, isVisible }) => {
  const { unreadMessageCount } = useContext(ChatContext)
  const unread = unreadMessageCount ?? 0 > 0

  const [alertNewMessage, setAlertNewMessage] = useState(false)

  useEffect(() => {
    const timer: NodeJS.Timer = setInterval(() => {
      if (unread && isVisible) setAlertNewMessage(prevState => !prevState)
      else if (alertNewMessage) setAlertNewMessage(false)
    }, 2000)
    return () => {
      if (timer) clearInterval(timer)
    }
  }, [unread, isVisible])

  const isAI = expert === 'AI'

  return (
    <Text style={isAI ? 'base-medium' : 'large-semibold'} color="neutrals-white" lines={1}>
      {unread && alertNewMessage ? 'New message' : isAI ? 'scout' : expert.displayName}
    </Text>
  )
}

export { ChatWidgetHeaderText }
