import React from 'react'
import { Modal, ModalProps, Text } from '@deal/bluxome'
import { useCartVisibilityContext } from '#src/app/context/cart-visibility'
import { useCart } from '#src/app/context/cart'

interface AddToCartErrorModalProps extends Pick<ModalProps, 'overlayProps' | 'state'> {
  invalidCartModalMessage?: string
}

export const AddToCartErrorModal: React.FC<AddToCartErrorModalProps> = ({
  invalidCartModalMessage,
  overlayProps,
  state
}) => {
  const { setCartVisibility } = useCartVisibilityContext()
  const { cart } = useCart('CART_VIEW')

  return (
    <Modal
      title="Can't add to cart"
      overlayProps={overlayProps}
      state={state}
      actions={{
        color: 'neutral-dark',
        primaryAction: {
          label: 'View current cart',
          onPress: () => {
            state.close()
            setCartVisibility(true)
          }
        }
      }}
      width="small"
    >
      <Text color="neutrals-600">
        {`You already have ${
          cart?.itemsCount === 1 ? '1 item' : `${cart?.itemsCount} items`
        } in your
          cart. ${invalidCartModalMessage || ''}`.trim()}
      </Text>
    </Modal>
  )
}
