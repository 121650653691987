import React from 'react'
import { ProductSpecsComparison } from './components/ProductSpecsComparison'
import { ProductCardsComparison } from './components/ProductCardsComparison'
import styles from './styles.css'

export const ProductComparisonLoader: React.FC = () => {
  return (
    <div className={styles.container}>
      <table className={styles.specsTable}>
        <thead>
          <ProductCardsComparison />
        </thead>
        <tbody>
          <ProductSpecsComparison />
        </tbody>
      </table>
    </div>
  )
}
