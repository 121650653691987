import React, { AnchorHTMLAttributes } from 'react'
import { Link } from '@deal/router'
import { getExpertProfileLink } from '#src/app/services/expert'
import { BusinessUserForExpertProfileLinkFragment } from './ExpertProfileLink.generated'

interface ExpertProfileLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  expert: BusinessUserForExpertProfileLinkFragment
}

const ExpertProfileLink: React.FC<React.PropsWithChildren<ExpertProfileLinkProps>> = ({
  expert,
  rel,
  ...rest
}) => {
  const { link, rel: combinedRel } = getExpertProfileLink(expert, rel)

  return <Link to={link} rel={combinedRel} {...rest} />
}

export default ExpertProfileLink
