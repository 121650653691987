import React, { useEffect } from 'react'
import { Button } from '@deal/components'
import clientOnly from '#src/app/hocs/clientOnly'
import { PushNotificationPromptDisplayedEvent } from '#src/app/events/PushNotificationPromptDisplayedEvent'
import { PushNotificationPromptDeclinedEvent } from '#src/app/events/PushNotificationPromptDeclinedEvent'
import { PushNotificationPromptAcceptedEvent } from '#src/app/events/PushNotificationPromptAcceptedEvent'
import styles from './styles.css'

interface PushNotificationPermissionPromptProps {
  expertFirstName?: string
  onNoThanksClicked?: () => void
  onContinueClicked?: () => void
}

const PushNotificationPermissionPrompt: React.FC<
  React.PropsWithChildren<PushNotificationPermissionPromptProps>
> = ({ expertFirstName, onNoThanksClicked, onContinueClicked }) => {
  const browserSupportsWebPush = 'PushManager' in window

  if (!browserSupportsWebPush) {
    return null
  }

  useEffect(() => {
    window.tracking?.track(new PushNotificationPromptDisplayedEvent())
  }, [])

  const handleContinueClicked = () => {
    window.tracking?.track(new PushNotificationPromptAcceptedEvent())

    if (onContinueClicked) {
      onContinueClicked()
    }
  }

  const handleNoThanksClicked = () => {
    window.tracking?.track(new PushNotificationPromptDeclinedEvent())

    if (onNoThanksClicked) {
      onNoThanksClicked()
    }
  }

  return (
    <div className={styles.prompt}>
      <div className={styles.title}>
        Want to know when {expertFirstName || 'your expert'} responds?
      </div>
      <div className={styles.subTitle}>Turn on notifications</div>
      <div className={styles.buttons}>
        <Button
          size="xsmall"
          variant="neutral-light-ghost"
          className={styles.noThanksButton}
          onClick={handleNoThanksClicked}
        >
          No thanks
        </Button>
        <Button size="xsmall" variant="secondary" onClick={handleContinueClicked}>
          Continue
        </Button>
      </div>
    </div>
  )
}

export default clientOnly(PushNotificationPermissionPrompt)
