// @ts-nocheck
import type * as Types from '../../../../../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
export type IndividualSellableProConForItemProsAndCons_IndividualSellableAttributeProCon_Fragment = { __typename: 'IndividualSellableAttributeProCon', displayName: string };

export type IndividualSellableProConForItemProsAndCons_IndividualSellableCustomProCon_Fragment = { __typename: 'IndividualSellableCustomProCon', displayName: string };

export type IndividualSellableProConForItemProsAndConsFragment = IndividualSellableProConForItemProsAndCons_IndividualSellableAttributeProCon_Fragment | IndividualSellableProConForItemProsAndCons_IndividualSellableCustomProCon_Fragment;

export const IndividualSellableProConForItemProsAndConsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"IndividualSellableProConForItemProsAndCons"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"IndividualSellableProCon"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"IndividualSellableAttributeProCon"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"displayName"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"IndividualSellableCustomProCon"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"displayName"}}]}}]}}]} as unknown as DocumentNode;