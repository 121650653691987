// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { CategoryForCategoryOrProductListingPageFragmentDoc } from '../../services/search/CategoryForCategoryOrProductListingPage.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type DepartmentsRouteQueryVariables = Types.Exact<{
  departmentSlug: Types.Scalars['String']['input'];
}>;


export type DepartmentsRouteQuery = { __typename: 'Query', departmentBySlug?: { __typename: 'Department', id: any, topLevelCategory: { __typename: 'Category', id: any, slug: string, pageLayoutType: Types.CategoryPageLayoutType } } | null };


export const DepartmentsRouteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"DepartmentsRoute"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"departmentSlug"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"departmentBySlug"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"slug"},"value":{"kind":"Variable","name":{"kind":"Name","value":"departmentSlug"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"topLevelCategory"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"CategoryForCategoryOrProductListingPage"}}]}}]}}]}},...CategoryForCategoryOrProductListingPageFragmentDoc.definitions]} as unknown as DocumentNode;
export type DepartmentsRouteComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DepartmentsRouteQuery, DepartmentsRouteQueryVariables>, 'query'> & ({ variables: DepartmentsRouteQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DepartmentsRouteComponent = (props: DepartmentsRouteComponentProps) => (
      <ApolloReactComponents.Query<DepartmentsRouteQuery, DepartmentsRouteQueryVariables> query={DepartmentsRouteDocument} {...props} />
    );
    

/**
 * __useDepartmentsRouteQuery__
 *
 * To run a query within a React component, call `useDepartmentsRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentsRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentsRouteQuery({
 *   variables: {
 *      departmentSlug: // value for 'departmentSlug'
 *   },
 * });
 */
export function useDepartmentsRouteQuery(baseOptions: Apollo.QueryHookOptions<DepartmentsRouteQuery, DepartmentsRouteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DepartmentsRouteQuery, DepartmentsRouteQueryVariables>(DepartmentsRouteDocument, options);
      }
export function useDepartmentsRouteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepartmentsRouteQuery, DepartmentsRouteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DepartmentsRouteQuery, DepartmentsRouteQueryVariables>(DepartmentsRouteDocument, options);
        }
export function useDepartmentsRouteSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DepartmentsRouteQuery, DepartmentsRouteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DepartmentsRouteQuery, DepartmentsRouteQueryVariables>(DepartmentsRouteDocument, options);
        }
export type DepartmentsRouteQueryHookResult = ReturnType<typeof useDepartmentsRouteQuery>;
export type DepartmentsRouteLazyQueryHookResult = ReturnType<typeof useDepartmentsRouteLazyQuery>;
export type DepartmentsRouteSuspenseQueryHookResult = ReturnType<typeof useDepartmentsRouteSuspenseQuery>;
export type DepartmentsRouteQueryResult = Apollo.QueryResult<DepartmentsRouteQuery, DepartmentsRouteQueryVariables>;