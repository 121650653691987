// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
export type ConversationForIsAiConversationFragment = { __typename: 'Conversation', id: any, isAiChatV2: boolean, isAiGuestChat: boolean };

export type BusinessUserForIsAiConversationFragment = { __typename: 'BusinessUser', id: any, expertAttributes: { __typename: 'BusinessUserExpertAttributes', placeholderExpert: boolean }, existingLeadForConsumer?: { __typename: 'Lead', id: any, conversation: { __typename: 'Conversation', id: any, isAiChatV2: boolean, isAiGuestChat: boolean } } | null };

export type LeadForIsAiConversationFragment = { __typename: 'Lead', id: any, expert: { __typename: 'BusinessUser', id: any, expertAttributes: { __typename: 'BusinessUserExpertAttributes', placeholderExpert: boolean } }, conversation: { __typename: 'Conversation', id: any, isAiChatV2: boolean, isAiGuestChat: boolean } };

export const ConversationForIsAiConversationFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ConversationForIsAIConversation"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Conversation"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isAiChatV2"}},{"kind":"Field","name":{"kind":"Name","value":"isAiGuestChat"}}]}}]} as unknown as DocumentNode;
export const BusinessUserForIsAiConversationFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BusinessUserForIsAiConversation"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"expertAttributes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"placeholderExpert"}}]}},{"kind":"Field","name":{"kind":"Name","value":"existingLeadForConsumer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"conversation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConversationForIsAIConversation"}}]}}]}}]}}]} as unknown as DocumentNode;
export const LeadForIsAiConversationFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LeadForIsAiConversation"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Lead"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"expert"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"expertAttributes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"placeholderExpert"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"conversation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConversationForIsAIConversation"}}]}}]}}]} as unknown as DocumentNode;