import React, { Component } from 'react'
import hoistNonReactStatic from 'hoist-non-react-statics'
import { TrackingClient } from '@deal/web-tracking/types'
import { AnalyticsConsumer } from './index'

function getDisplayName(WrappedComponent: React.ComponentType<React.PropsWithChildren<any>>) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export interface WithAnalyticsProps {
  analytics?: TrackingClient
}

function withAnalytics<P>(
  WrappedComponent: React.ComponentType<React.PropsWithChildren<P & WithAnalyticsProps>>
): React.ComponentType<React.PropsWithChildren<P & WithAnalyticsProps>> {
  class WithAnalytics extends Component<P & WithAnalyticsProps, {}> {
    public static displayName = `WithAnalytics(${getDisplayName(WrappedComponent)})`

    public render() {
      return (
        <AnalyticsConsumer>
          {analytics => <WrappedComponent analytics={analytics} {...this.props} />}
        </AnalyticsConsumer>
      )
    }
  }

  return hoistNonReactStatic(WithAnalytics, WrappedComponent)
}

export { withAnalytics }
