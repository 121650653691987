import React from 'react'
import { toast } from 'react-toastify'
import { useLocation } from '@deal/router'
import { CircleLoader, FullBlockLoader } from '@deal/components'
import { MarketingBanner } from '@deal/bluxome'
import { EngagementModalType } from '#src/generated/types'
import { useEngagementChannelBannerContext } from '#src/app/context/engagement-channel-banner'
import { Server } from '#src/app/components/Errors'
import { usePreviewPageTemplateForEngagementModalPreviewQuery } from './EngagementModalPreview.generated'
import { SMSModal } from '../SMSModalTemplate'
import PathModalTemplate from '../PathModalTemplate'
import OnSiteSMSModalTemplate from '../OnSiteSMSModalTemplate'
import EmailModalTemplate from '../EmailModalTemplate'
import { EngagementChannelTrackingContextProvider } from '../../components/EngagementChannelTracking'
import DisplayTriggerWrapper from '../../components/DisplayTriggerWrapper'
import { generateComponentValue } from '..'
import styles from './styles.css'

enum ParamName {
  DisplayTrigger = 'Display Trigger',
  TimeDelay = 'Time Delay'
}

export const EngagementModalPreview: React.FC = () => {
  const { search } = useLocation()
  const urlParams = new URLSearchParams(search)
  const pageTemplateId = urlParams.get('pageTemplateId')
  const { isBannerVisible, showBanner } = useEngagementChannelBannerContext()

  const { data, loading } = usePreviewPageTemplateForEngagementModalPreviewQuery({
    variables: {
      input: {
        previewId: pageTemplateId,
        contentList: []
      }
    },
    skip: !pageTemplateId,
    onError: () => {
      toast.error('Error: failed to render the engagement modal')
    }
  })

  if (loading) {
    return <FullBlockLoader loader={CircleLoader} />
  } else if (data?.previewPageTemplate.componentViews) {
    const componentValue = generateComponentValue(
      data?.previewPageTemplate.componentViews[0].pageComponentContent.componentValues
    )
    return (
      <div className={styles.container}>
        {isBannerVisible &&
          (data.previewPageTemplate.pageTemplate.engagementModalType === EngagementModalType.SMS ? (
            <>
              <SMSModal isOpen={true} onDismiss={() => {}} componentValue={componentValue} />
            </>
          ) : (
            <MarketingBanner
              headline={componentValue['Banner Left Text'].text!}
              subHeadline={componentValue['Banner Right Text'].text!}
              buttonLabel={componentValue['Banner CTA Button'].text!}
              buttonProps={{ color: 'shop-orange' }}
            />
          ))}
        <EngagementChannelTrackingContextProvider
          value={{
            engagementChannelViewed: () => {},
            engagementChannelDismissed: () => {},
            engagementChannelSubmitted: () => {},
            setCampaignContext: () => {}
          }}
        >
          <DisplayTriggerWrapper
            displayTrigger={componentValue[ParamName.DisplayTrigger]?.triggerEnumValue || 'default'}
            timeDelay={componentValue[ParamName.TimeDelay]?.number}
            engagementChannel="engagement-modal"
          >
            {({
              showEngagementChannel: showEngagementModal,
              closeEngagementChannel: closeEngagementModal
            }) => {
              if (
                data.previewPageTemplate.pageTemplate.engagementModalType ===
                EngagementModalType.SMS
              ) {
                return (
                  <SMSModal
                    isOpen={showEngagementModal}
                    onDismiss={() => {
                      closeEngagementModal()
                      showBanner()
                    }}
                    componentValue={componentValue}
                  />
                )
              } else if (
                data.previewPageTemplate.pageTemplate.engagementModalType ===
                EngagementModalType.EMAIL
              ) {
                return (
                  <EmailModalTemplate
                    isOpen={showEngagementModal}
                    onDismiss={() => {
                      closeEngagementModal()
                      showBanner()
                    }}
                    componentValue={componentValue}
                  />
                )
              } else if (
                data.previewPageTemplate.pageTemplate.engagementModalType ===
                EngagementModalType.ON_SITE_SMS
              ) {
                return (
                  <OnSiteSMSModalTemplate
                    categoryId=""
                    pageTemplateRevisionId={pageTemplateId ?? ''}
                    isOpen={showEngagementModal}
                    onDismiss={() => {
                      closeEngagementModal()
                      showBanner()
                    }}
                  />
                )
              } else {
                return (
                  <PathModalTemplate
                    isOpen={showEngagementModal}
                    onDismiss={() => {
                      closeEngagementModal()
                      showBanner()
                    }}
                    componentValue={componentValue}
                  />
                )
              }
            }}
          </DisplayTriggerWrapper>
        </EngagementChannelTrackingContextProvider>
      </div>
    )
  } else {
    return <Server />
  }
}

export default EngagementModalPreview
