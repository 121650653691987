// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { SellableForGetBluxomeProductTagsFromSellableFragmentDoc } from '../../services/sellable/getBluxomeProductTagsFromSellable/getBluxomeProductTagsFromSellable.generated';
import { SellableForIsSellablePurchasableFragmentDoc, SellableForGetSellableLinkRelFragmentDoc } from '../../services/sellable/Sellable.generated';
import { SellableForIsSellableAvailableForPreorderFragmentDoc } from '../../services/sellable/isSellableAvailableForPreorder/isSellableAvailableForPreorder.generated';
import { SellableForIsSellableOnClearanceFragmentDoc } from '../../services/sellable/isSellableOnClearance/isSellableOnClearance.generated';
import { SellableForGetBluxomeProductTopListAwardsFromSellableFragmentDoc } from './getBluxomeProductAwardsFromSellable/GetBluxomeProductTopListAwardsFromSellable.generated';
import { SellableTopListItemPositionForTopListAwardFragmentDoc, SellableEditorialTopListItemPositionForTopListAwardFragmentDoc } from '../TopListAward/TopListAward.generated';
import { MediaFileFragmentDoc } from '../../fragments/MediaFile.generated';
import { SellableUrlFragmentDoc } from '../../fragments/SellableUrl.generated';
export type SellableForProductTagFragment = { __typename: 'Sellable', id: any, inShortSupply: boolean, friendlyId?: string | null, slug: string, type: Types.SellableType, variantParent: boolean, active: boolean, areAllVariantsAvailableForPreOrder: boolean, areAllVariantsOutOfStock: boolean, purchasable: boolean, availableForPreOrder: boolean, metrics?: { __typename: 'SellableMetrics', totalPurchasesInLast30Days?: number | null } | null, topLists: Array<{ __typename: 'SellableTopListItemPosition', position: number, topList: { __typename: 'SellableTopList', id: any, friendlyId: any, slug: string, title: string, firstItem?: { __typename: 'SellableTopListItem', parentSellable: { __typename: 'Sellable', id: any, primaryImage?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null } } | null } }>, editorialTopLists: Array<{ __typename: 'SellableEditorialTopListItemPosition', position: number, topList: { __typename: 'SellableEditorialTopListRevision', id: any, friendlyId: any, slug: string, title: string, firstItem?: { __typename: 'SellableEditorialTopListItem', id: any, sellable: { __typename: 'Sellable', id: any, primaryImage?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null } } | null } }>, expertiseScore?: { __typename: 'SellableExpertiseScore', quality: Types.SellableExpertiseFitQuality } | null, badges: Array<{ __typename: 'SellableBadgeWithDisplayName', badge: Types.SellableBadge }> };

export const SellableForProductTagFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableForProductTag"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Sellable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForGetBluxomeProductTagsFromSellable"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForGetBluxomeProductTopListAwardsFromSellable"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableUrl"}}]}}]} as unknown as DocumentNode;