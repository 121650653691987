// @ts-nocheck
import type * as Types from '../../../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { SellableForIsSellablePurchasableFragmentDoc, SellableForGetSellableLinkRelFragmentDoc } from '../../../../../../../services/sellable/Sellable.generated';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type AddSellableWishlistItemToOrderForAddSellableMutationVariables = Types.Exact<{
  wishlistItemId: Types.Scalars['WishlistItemId']['input'];
}>;


export type AddSellableWishlistItemToOrderForAddSellableMutation = { __typename: 'Mutation', addSellableWishlistItemToOrder: { __typename: 'AddSellableWishlistItemToOrderPayload', error?: { __typename: 'AddOwnItemAddSellableWishlistItemToOrderError', errorMessage: string } | { __typename: 'AlreadyArchivedAddSellableWishlistItemToOrderError', errorMessage: string } | { __typename: 'CompletelyPurchasedAddSellableWishlistItemToOrderError', errorMessage: string } | { __typename: 'NotAvailableAddSellableWishlistItemToOrderError', errorMessage: string } | { __typename: 'NotPublicAddSellableWishlistItemToOrderError', errorMessage: string } | { __typename: 'UnexpectedDetailTypeAddSellableWishlistItemToOrderError', errorMessage: string } | null, order?: { __typename: 'Order', id: any } | null } };

export type SellableForAddSellableToWishlistCartFragment = { __typename: 'Sellable', id: any, variantParent: boolean, active: boolean, areAllVariantsAvailableForPreOrder: boolean, areAllVariantsOutOfStock: boolean, purchasable: boolean, availableForPreOrder: boolean };

export const SellableForAddSellableToWishlistCartFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableForAddSellableToWishlistCart"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Sellable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForIsSellablePurchasable"}}]}}]} as unknown as DocumentNode;
export const AddSellableWishlistItemToOrderForAddSellableDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddSellableWishlistItemToOrderForAddSellable"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"wishlistItemId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"WishlistItemId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addSellableWishlistItemToOrder"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"quantity"},"value":{"kind":"IntValue","value":"1"}},{"kind":"ObjectField","name":{"kind":"Name","value":"wishlistItemId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"wishlistItemId"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"error"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ErrorMessage"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"errorMessage"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"order"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode;
export type AddSellableWishlistItemToOrderForAddSellableMutationFn = Apollo.MutationFunction<AddSellableWishlistItemToOrderForAddSellableMutation, AddSellableWishlistItemToOrderForAddSellableMutationVariables>;
export type AddSellableWishlistItemToOrderForAddSellableComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddSellableWishlistItemToOrderForAddSellableMutation, AddSellableWishlistItemToOrderForAddSellableMutationVariables>, 'mutation'>;

    export const AddSellableWishlistItemToOrderForAddSellableComponent = (props: AddSellableWishlistItemToOrderForAddSellableComponentProps) => (
      <ApolloReactComponents.Mutation<AddSellableWishlistItemToOrderForAddSellableMutation, AddSellableWishlistItemToOrderForAddSellableMutationVariables> mutation={AddSellableWishlistItemToOrderForAddSellableDocument} {...props} />
    );
    

/**
 * __useAddSellableWishlistItemToOrderForAddSellableMutation__
 *
 * To run a mutation, you first call `useAddSellableWishlistItemToOrderForAddSellableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSellableWishlistItemToOrderForAddSellableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSellableWishlistItemToOrderForAddSellableMutation, { data, loading, error }] = useAddSellableWishlistItemToOrderForAddSellableMutation({
 *   variables: {
 *      wishlistItemId: // value for 'wishlistItemId'
 *   },
 * });
 */
export function useAddSellableWishlistItemToOrderForAddSellableMutation(baseOptions?: Apollo.MutationHookOptions<AddSellableWishlistItemToOrderForAddSellableMutation, AddSellableWishlistItemToOrderForAddSellableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSellableWishlistItemToOrderForAddSellableMutation, AddSellableWishlistItemToOrderForAddSellableMutationVariables>(AddSellableWishlistItemToOrderForAddSellableDocument, options);
      }
export type AddSellableWishlistItemToOrderForAddSellableMutationHookResult = ReturnType<typeof useAddSellableWishlistItemToOrderForAddSellableMutation>;
export type AddSellableWishlistItemToOrderForAddSellableMutationResult = Apollo.MutationResult<AddSellableWishlistItemToOrderForAddSellableMutation>;
export type AddSellableWishlistItemToOrderForAddSellableMutationOptions = Apollo.BaseMutationOptions<AddSellableWishlistItemToOrderForAddSellableMutation, AddSellableWishlistItemToOrderForAddSellableMutationVariables>;