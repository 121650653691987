import React from 'react'
import omit from 'lodash/omit'
import hoistNonReactStatic from 'hoist-non-react-statics'
import { LoginLocationOptions } from '#src/app/services/routing'
import { withIdentity } from '#src/app/containers/Identity'
import { Unauthorized } from '#src/app/components/Errors'
import { WithIdentityProps } from './withIdentity'

function getDisplayName(WrappedComponent: React.ComponentType<React.PropsWithChildren<any>>) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export default function withAuthentication<P>(
  WrappedComponent: React.ComponentType<React.PropsWithChildren<P>>,
  loginLocationOptions?: LoginLocationOptions
) {
  const WithAuthentication: React.FC<React.PropsWithChildren<WithIdentityProps & P>> = props => {
    // Only expose the props that were passed in. These are created and used only by this HOC:
    const passthroughProps = omit(props, 'myself', 'userId', 'sessionId', 'refetchIdentity')

    if (props.myself) {
      // @ts-ignore
      return <WrappedComponent {...(passthroughProps as P)} />
    } else {
      return <Unauthorized loginLocationOptions={loginLocationOptions} />
    }
  }

  WithAuthentication.displayName = `WithAuthentication(${getDisplayName(WrappedComponent)})`

  return hoistNonReactStatic(withIdentity<P>(WithAuthentication), WrappedComponent)
}
