import React, { useState } from 'react'
import classNames from 'classnames'
import { Link } from '@deal/router'
import { formatDate } from '@deal/date-utils'
import {
  BrandSymbol,
  Button,
  Heading,
  HeadlessModal,
  PhoneField,
  Text,
  TextField,
  useModalTrigger
} from '@deal/bluxome'
import clientOnly from '#src/app/hocs/clientOnly'
import { useOnSiteSMSModalTemplate } from './useOnSiteSMSModalTemplate'
import styles from './styles.css'

export interface EmailModalTemplateProps {
  isOpen: boolean
  pageTemplateRevisionId: string
  categoryId: string
  onSubmit?: () => void
  onDismiss?: () => void
}

const OnsiteSMSModalTemplate: React.FC<EmailModalTemplateProps> = ({
  isOpen,
  pageTemplateRevisionId,
  categoryId,
  onSubmit,
  onDismiss
}) => {
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [code, setCode] = useState('')
  const [selectedOption, setSelectedOption] = useState<string>()
  const { overlayProps, state } = useModalTrigger({
    isOpen: isOpen,
    onOpenChange: isOpen => {
      if (!isOpen) {
        onDismiss?.()
      }
    }
  })
  const {
    question,
    step,
    submitEmail,
    submitPhone,
    resendCode,
    verifyCode,
    finalize,
    loading,
    error,
    helperText,
    redirectURL,
    promotionPayload,
    categoryShortSingularDisplayName
  } = useOnSiteSMSModalTemplate(email, phone, code, pageTemplateRevisionId, categoryId, onSubmit)

  const loginRedirect = redirectURL ? (
    <div className={styles.centerAligned}>
      <Text>
        Looks like you already have an account,{' '}
        <Text color="learn-blue-500">
          <Link to={redirectURL} className={styles.underlined}>
            click here to log in
          </Link>
        </Text>
      </Text>
    </div>
  ) : null

  const promotion = promotionPayload?.promotion

  return (
    <HeadlessModal
      overlayProps={overlayProps}
      state={state}
      mobileLayout="fullscreen"
      width="medium"
    >
      {({ titleProps }) => (
        <div className={styles.modalContent}>
          {step === 'promotion' ? (
            <>
              <div {...titleProps} className={styles.header}>
                <BrandSymbol size="medium" color="brand-blue" />
                <Heading size="h2" color="learn-blue-500">
                  You just unlocked 10% off your next purchase!
                </Heading>
              </div>
              <div className={styles.body}>
                <div className={styles.centerAligned}>
                  {promotion ? (
                    <div className={styles.promoCode}>
                      <Text>Use the code below at checkout:</Text>
                      <div className={styles.fullWidth}>
                        <TextField
                          aria-label={'promo code'}
                          value={promotion.code}
                          onChange={() => {}}
                          size="large"
                        />
                      </div>
                      {promotion.expiresAt && (
                        <Text style="small">
                          {`Expires ${formatDate(promotion.expiresAt)}. See `}
                          <Link to="/legal/terms-conditions" target="_blank">
                            <Text style="small-underline">Terms</Text>
                          </Link>
                        </Text>
                      )}
                    </div>
                  ) : (
                    <Text>
                      {promotionPayload?.errorMessage ??
                        'There was error getting your promotion, please contact our experts to help you!'}
                    </Text>
                  )}
                </div>
                <Button color="learn-blue" style="filled" onPress={finalize}>
                  Find your perfect {categoryShortSingularDisplayName}
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className={styles.header}>
                <BrandSymbol size="medium" color="brand-blue" />
                <div className={styles.vstack}>
                  <Heading size="h1" color="learn-blue-500">
                    Unlock 10% off
                  </Heading>
                  <Text style="base" color="learn-blue-500">
                    + free shipping & returns
                  </Text>
                </div>
              </div>
              <div className={styles.body}>
                {step === 'email' ? (
                  <>
                    {question && (
                      <div className={styles.question}>
                        <Text>{question.title}</Text>
                        <div className={styles.options}>
                          {question.options.map(option => {
                            const isSelected = option === selectedOption
                            return (
                              <button
                                key={option}
                                className={classNames(styles.option, {
                                  [styles.selected]: isSelected
                                })}
                                onClick={() => setSelectedOption(option)}
                              >
                                <Text
                                  style="base-medium-tight"
                                  color={isSelected ? 'learn-blue-500' : 'neutrals-black'}
                                >
                                  {option}
                                </Text>
                              </button>
                            )
                          })}
                        </div>
                      </div>
                    )}
                    <div className={styles.input}>
                      <TextField
                        size="large"
                        value={email}
                        onChange={setEmail}
                        label="Email*"
                        type="email"
                        validation={error ? { label: error, state: 'critical' } : undefined}
                      />
                      {loginRedirect}
                      <Button
                        style="filled"
                        color="neutral-dark"
                        onPress={submitEmail}
                        isLoading={loading}
                        aria-label={'Continue'}
                        fullWidth
                      >
                        Continue
                      </Button>
                      <div className={styles.centerAligned}>
                        <Text style="extra-small-medium" color="neutrals-600">
                          By submitting, you agree to our{' '}
                          <Link to="/legal/terms-conditions" target="_blank">
                            <Text style="extra-small-medium" color="learn-blue-500">
                              Terms
                            </Text>
                          </Link>{' '}
                          and{' '}
                          <Link to="/legal/privacy-policy" target="_blank">
                            <Text style="extra-small-medium" color="learn-blue-500">
                              Privacy policy
                            </Text>
                          </Link>
                          .
                        </Text>
                      </div>
                    </div>
                  </>
                ) : step === 'phone' ? (
                  <>
                    <div className={styles.input}>
                      <PhoneField
                        size="large"
                        autoFocus
                        defaultValue={phone}
                        onChange={setPhone}
                        label="Phone number"
                        type="tel"
                        validation={error ? { label: error, state: 'critical' } : undefined}
                        helperText={helperText}
                      />
                      {loginRedirect}
                      <Button
                        style="filled"
                        color="neutral-dark"
                        onPress={submitPhone}
                        isLoading={loading}
                        isDisabled={phone.length !== 10}
                        fullWidth
                        aria-label={'Claim your 10% off'}
                      >
                        Claim your 10% off
                      </Button>
                      <div className={styles.centerAligned}>
                        <Text style="extra-small-medium" color="neutrals-600">
                          By submitting, you agree to receive text messages from Curated. Msg and
                          data rates may apply. See{' '}
                          <Link to="/legal/terms-conditions" target="_blank">
                            <Text style="extra-small-medium" color="learn-blue-500">
                              Terms
                            </Text>
                          </Link>{' '}
                          and{' '}
                          <Link to="/legal/privacy-policy" target="_blank">
                            <Text style="extra-small-medium" color="learn-blue-500">
                              Privacy policy
                            </Text>
                          </Link>
                          .
                        </Text>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className={styles.input}>
                    <div className={styles.codeInput}>
                      <TextField
                        size="large"
                        aria-label="Verification code"
                        value={code}
                        onChange={code => {
                          setCode(code.slice(0, 6))
                        }}
                        placeholder="––––––"
                        autoComplete="one-time-code"
                        inputMode="numeric"
                        validation={error ? { label: error, state: 'critical' } : undefined}
                      />
                    </div>
                    {loginRedirect ? (
                      loginRedirect
                    ) : (
                      <div className={styles.centerAligned}>
                        <Text>
                          We sent you a confirmation code. To validate your number enter the 6 digit
                          code below.
                        </Text>
                      </div>
                    )}
                    <Button
                      style="filled"
                      color="neutral-dark"
                      onPress={verifyCode}
                      isLoading={loading}
                      aria-label="Submit code"
                      fullWidth
                    >
                      Submit code
                    </Button>
                    <div className={styles.resend}>
                      <Text>Didn’t get a code?</Text>
                      <Button
                        style="text"
                        color="neutral-dark"
                        onPress={() => {
                          setCode('')
                          resendCode()
                        }}
                      >
                        Resend code
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </HeadlessModal>
  )
}

export default clientOnly(OnsiteSMSModalTemplate)
