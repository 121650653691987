import React from 'react'
import { Image } from '@deal/components'
import ServiceImage from './image.png'
import styles from './styles.css'

const ExpertServiceImage: React.FC = () => {
  return (
    <div className={styles.icon}>
      <Image size="25vw" src={ServiceImage} />
    </div>
  )
}

export default ExpertServiceImage
