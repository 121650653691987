// @ts-nocheck
import type * as Types from '../../generated/types';

import type { DocumentNode } from 'graphql';
export type BucketedDisplayFacetFragment = { __typename: 'BucketedDisplayFacet', displayName: string, name: string, alias?: string | null, buckets: Array<{ __typename: 'IndividualBucketedDisplayFacet', displayName: string, value: string, parentValue?: string | null, count?: any | null, seoShouldFollow: boolean }> };

export type BucketedRangeDisplayFacetFragment = { __typename: 'BucketedRangeDisplayFacet', displayName: string, name: string, alias?: string | null };

export type NumericRangeDisplayFacetFragment = { __typename: 'NumericRangeDisplayFacet', displayName: string, name: string, alias?: string | null, type: Types.NumericRangeDisplayFacetType, lower: any, upper: any, scale: number, upperCapped: boolean };

export type DisplayFacet_BucketedDisplayFacet_Fragment = { __typename: 'BucketedDisplayFacet', displayName: string, name: string, alias?: string | null, buckets: Array<{ __typename: 'IndividualBucketedDisplayFacet', displayName: string, value: string, parentValue?: string | null, count?: any | null, seoShouldFollow: boolean }> };

export type DisplayFacet_BucketedRangeDisplayFacet_Fragment = { __typename: 'BucketedRangeDisplayFacet', displayName: string, name: string, alias?: string | null };

export type DisplayFacet_NumericRangeDisplayFacet_Fragment = { __typename: 'NumericRangeDisplayFacet', displayName: string, name: string, alias?: string | null, type: Types.NumericRangeDisplayFacetType, lower: any, upper: any, scale: number, upperCapped: boolean };

export type DisplayFacetFragment = DisplayFacet_BucketedDisplayFacet_Fragment | DisplayFacet_BucketedRangeDisplayFacet_Fragment | DisplayFacet_NumericRangeDisplayFacet_Fragment;

export const BucketedDisplayFacetFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BucketedDisplayFacet"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BucketedDisplayFacet"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"alias"}},{"kind":"Field","name":{"kind":"Name","value":"buckets"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"parentValue"}},{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"seoShouldFollow"}}]}}]}}]} as unknown as DocumentNode;
export const BucketedRangeDisplayFacetFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BucketedRangeDisplayFacet"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BucketedRangeDisplayFacet"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"alias"}}]}}]} as unknown as DocumentNode;
export const NumericRangeDisplayFacetFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"NumericRangeDisplayFacet"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"NumericRangeDisplayFacet"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"alias"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"lower"}},{"kind":"Field","name":{"kind":"Name","value":"upper"}},{"kind":"Field","name":{"kind":"Name","value":"scale"}},{"kind":"Field","name":{"kind":"Name","value":"upperCapped"}}]}}]} as unknown as DocumentNode;
export const DisplayFacetFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"DisplayFacet"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"DisplayFacet"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BucketedDisplayFacet"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"BucketedRangeDisplayFacet"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"NumericRangeDisplayFacet"}}]}}]} as unknown as DocumentNode;