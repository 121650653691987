import React from 'react'
import { Link } from '@deal/router'
import { Button } from '@deal/components'
import { EntityNotFound } from '#src/app/components/Errors'
import styles from './styles.css'

const WishlistNotFound = () => {
  return (
    <EntityNotFound
      header={"Hmm... we can't find that wishlist!"}
      body={
        <>
          Are you sure you have the correct link?
          <div className={styles.homeButton}>
            <Link to="/">
              <Button variant="neutral-dark-ghost">Take me home</Button>
            </Link>
          </div>
        </>
      }
    />
  )
}

export default WishlistNotFound
