import React from 'react'
import numeral from 'numeral'
import { formatAmount } from '#src/app/services/money'
import {
  OrderForCreditProgressTrackerFragment,
  PromotionActionReferenceForCreditProgressTrackerFragment
} from './CreditProgressTracker.generated'
import { PromotionProgressTracker } from '../PromotionProgressTracker'

interface Tier {
  minimumSpend: number
  rewardCredit: number
}

interface CreditProgressTrackerProps {
  order: OrderForCreditProgressTrackerFragment
  termsPath?: string
}

const toValue = (a: string) => numeral(a).value() || 0

export const CreditProgressTracker: React.FC<CreditProgressTrackerProps> = ({
  order,
  termsPath
}) => {
  let subtitle = ''
  let progressPc = 0

  let currentSpend = 0
  const tiers: Tier[] = []
  let rewardCreditAction: PromotionActionReferenceForCreditProgressTrackerFragment | undefined

  if (order.appliedPromotionEntry) {
    rewardCreditAction = order.appliedPromotionEntry.promotion.actions.find(
      action => action.__typename === 'RewardCreditPromotionActionReference'
    )
    currentSpend = toValue(order.promotionEligibilityAmount.amount)
  } else if (
    order.notEligibleForPromotionReason &&
    order.notEligibleForPromotionReason.__typename === 'OrderAmountNotEligibleForPromotionReason' &&
    order.notEligibleForPromotionReason.actionAppliedWhenEligible?.type === 'REWARD_CREDIT'
  ) {
    rewardCreditAction = order.notEligibleForPromotionReason.actionAppliedWhenEligible
    currentSpend = toValue(order.notEligibleForPromotionReason.eligibleOrderAmount.amount)
  }
  if (
    rewardCreditAction &&
    rewardCreditAction.__typename === 'RewardCreditPromotionActionReference'
  )
    tiers.push(
      ...rewardCreditAction.lines.map(line => ({
        minimumSpend: toValue(line.minimumOrderAmount.amount),
        rewardCredit: toValue(line.rewardCreditAmount.amount)
      }))
    )
  if (tiers.length === 0) return null

  // sort tiers by ascending minimum spend
  tiers.sort((a, b) => a.minimumSpend - b.minimumSpend)

  // find the index of the tier the user can achieve next
  const currentTierIndex = tiers.findIndex(tier => tier.minimumSpend > currentSpend)
  const currentTier = tiers[currentTierIndex] ?? tiers.slice(-1)[0]
  const minimumSpend = formatAmount(currentTier.minimumSpend, '$0,0')
  const rewardCredit = formatAmount(currentTier.rewardCredit, '$0,0')
  const difference = formatAmount(currentTier.minimumSpend - currentSpend)
  let title = ''
  // if we have achieved 100% of all tiers
  if (currentTierIndex === -1) {
    title = `${rewardCredit} credit unlocked!`
    progressPc = 100
  } else if (currentTierIndex === 0) {
    // if we're in the first tier
    title = `Spend ${difference} more to get a ${rewardCredit} credit`
    const nextTier = tiers.at(currentTierIndex + 1)
    const nextMinimumSpend = nextTier ? formatAmount(nextTier.minimumSpend, '$0,0') : undefined
    const nextRewardCredit = nextTier ? formatAmount(nextTier.rewardCredit, '$0,0') : undefined
    subtitle = `Spend ${minimumSpend} & get ${rewardCredit}${
      nextTier ? ` or spend ${nextMinimumSpend} & get ${nextRewardCredit}` : ''
    }`
    progressPc = Math.round((currentSpend / currentTier.minimumSpend) * 100)
  } else {
    // if we're not in the first tier
    const previousTier = tiers[currentTierIndex - 1]
    const previousRewardCredit = previousTier
      ? formatAmount(previousTier.rewardCredit, '$0,0')
      : undefined
    title = `${previousRewardCredit} credit unlocked!`
    subtitle = `Spend ${difference} more to get a ${rewardCredit} credit`
    progressPc = Math.round((currentSpend / currentTier.minimumSpend) * 100)
  }
  return (
    <PromotionProgressTracker
      title={title}
      subtitle={subtitle}
      progressPc={progressPc}
      label={minimumSpend}
      termsPath={termsPath}
    />
  )
}
