import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { useInterval } from '@deal/dom-hooks'
import styles from './styles.css'

export enum NotificationType {
  SUCCESS,
  ERROR
}

type Notification = {
  message?: React.ReactNode
  type?: NotificationType
  duration?: number
}

export type GlobalNotificationType = {
  notification?: Notification
  setGlobalNotification?: (notification: Notification) => void
}

const GlobalNotificationContext = React.createContext<GlobalNotificationType>({})

const { Provider, Consumer } = GlobalNotificationContext

const GlobalNotificationProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [notification, setGlobalNotification] = useState<Notification>()
  const [countdown, setCountdown] = useState<number>(0)

  useInterval(
    () => {
      setCountdown(countdown - 1000)
      if (countdown <= 0) {
        setGlobalNotification({
          message: undefined,
          type: undefined,
          duration: undefined
        })
      }
    },
    countdown < 0 ? null : 1000
  )

  useEffect(() => {
    notification?.duration && setCountdown(notification.duration)
  }, [notification?.duration])

  return (
    <Provider value={{ notification: notification, setGlobalNotification: setGlobalNotification }}>
      {children}
    </Provider>
  )
}

const GlobalNotificationDisplay: React.FC<React.PropsWithChildren<GlobalNotificationType>> = ({ notification }) => {
  if (!notification?.message) {
    return null
  } else {
    return (
      <div
        className={classnames(styles.notification, {
          [styles.show]: notification.message,
          [styles.successBanner]: notification.type === NotificationType.SUCCESS,
          [styles.errorBanner]: notification.type === NotificationType.ERROR
        })}
      >
        {notification.message}
      </div>
    )
  }
}

export {
  GlobalNotificationProvider,
  GlobalNotificationContext,
  Consumer as GlobalNotificationConsumer,
  GlobalNotificationDisplay
}
