import React from 'react'
import { Route, RouteChildrenProps } from '@deal/router'
import { FullPageLoader } from '@deal/components'
import Redirect from '#src/app/components/Redirect'
import { NotFound } from '#src/app/components/Errors'
import { useDepartmentsRouteQuery } from './DepartmentsRoute.generated'
import { buildCategoryOrProductListingPageUrl } from '../../services/search/buildCategoryOrProductListingPageUrl'

interface DepartmentsRouteProps {
  departmentSlug: string
}

const DepartmentsRoute: React.FC<DepartmentsRouteProps> = ({ departmentSlug }) => {
  const { data, loading } = useDepartmentsRouteQuery({
    variables: {
      departmentSlug
    }
  })

  if (data?.departmentBySlug) {
    return (
      <Redirect to={buildCategoryOrProductListingPageUrl(data.departmentBySlug.topLevelCategory)} />
    )
  } else if (loading) {
    return <FullPageLoader />
  } else {
    return <NotFound />
  }
}

interface DepartmentsRouteParserMatch
  extends RouteChildrenProps<{
    departmentSlug?: string
  }> {}

const DepartmentsRouteParser: React.FC = () => (
  <Route path="/d/:departmentSlug">
    {({ match }: DepartmentsRouteParserMatch) => {
      const departmentSlug = match?.params.departmentSlug
      if (departmentSlug) {
        return <DepartmentsRoute departmentSlug={departmentSlug} />
      }
      return <NotFound />
    }}
  </Route>
)

export { DepartmentsRouteParser }
