import config from '#src/app/config'

export function relativeToAbsoluteUrl(relativeUrl: string): string {
  const { protocol, host, port } = config.get('consumer')
  const includePort = port.toString() !== '80' && port.toString() !== '443'

  return new URL(relativeUrl, `${protocol}://${host}` + (includePort ? `:${port}` : '')).toString()
}

export function absoluteToRelativeUrl(absoluteUrl: string): string {
  const url = new URL(absoluteUrl)
  return url.pathname
}

// Takes a url that is either relative or absolute and returns the relative version
// Only returns if the url is already relative or absolutely linked to the current host
export function normalizeRelativeOrAbsoluteUrl(relativeOrAbsoluteUrl: string | null = null) {
  if (!relativeOrAbsoluteUrl) {
    return
  }
  if (relativeOrAbsoluteUrl.startsWith('/')) {
    // Already a relative url
    return relativeOrAbsoluteUrl
  }
  try {
    // If url is absolute, check if it links to the current host
    const url = new URL(relativeOrAbsoluteUrl)
    if (getNormalizableHosts().includes(url.hostname)) {
      return `${url.pathname}${url.search}${url.hash}`
    }
  } catch (e) {
    return
  }
}

function getNormalizableHosts(): string[] {
  const hostname = config.get('consumer').host
  switch (hostname) {
    case 'www.curated.com':
    case 'www.curated-staging.com':
    case 'www.curated-dev.com':
      // In most cases, only absolute links to the same host can be normalized
      return [hostname]

    case 'www.local.curated-staging.com':
      // In local staging dev, both local and remote urls can be normalized
      return ['www.curated-staging.com', 'www.local.curated-staging.com']

    default:
      // This shouldn't happen
      return []
  }
}
