import React, { useRef, useState } from 'react'
import { ResizingTextArea } from '@deal/components'
import styles from './styles.css'

interface ExpertIntroComposerProps {
  loading: boolean
  onSubmit: (text: string) => void
}

const DEFAULT_PLACEHOLDER_MESSAGE = 'Send a message...'

export const ExpertIntroComposer: React.FC<ExpertIntroComposerProps> = ({ loading, onSubmit }) => {
  const [text, setText] = useState('')
  const buttonRef = useRef<HTMLButtonElement>(null)
  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Submit the form on "Enter", unless it was "Shift+Enter"
    // React fires a synthetic event for form submissions, so we click the submit button to trigger the event
    if (event.key === 'Enter' && !event.shiftKey && buttonRef.current) {
      event.preventDefault()
      buttonRef.current.click()
    }
  }
  return (
    <form
      onSubmit={event => {
        event.preventDefault()
        onSubmit(text)
      }}
      className={styles.composer}
    >
      <div className={styles.textAreaWrapper}>
        <ResizingTextArea
          value={loading ? 'Message sending...' : text}
          placeholder={DEFAULT_PLACEHOLDER_MESSAGE}
          className={styles.resizingInput}
          onChange={v => setText(v.target.value)}
          textAreaProps={{
            onKeyDown: handleKeyPress,
            disabled: loading
          }}
        />
      </div>
      <button
        ref={buttonRef}
        disabled={loading || text.length === 0}
        type="submit"
        className={styles.send}
      >
        Send
      </button>
    </form>
  )
}
