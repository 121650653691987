import React from 'react'
import { Area, Grid } from '@deal/bluxome'
import { Skeleton } from '#src/app/components/SkeletonLoader'
import styles from './styles.css'

export const BrowseSubCategoryNavigationSkeleton: React.FC = () => (
  <div className={styles.browseSubCategoryNavigationContainer}>
    <Grid>
      <Area column="span 6">
        <Skeleton height="36px" width="200px" />
      </Area>
    </Grid>
    <Grid rowGap={16}>
      {Array.from(Array(6)).map((_, idx) => (
        <Area key={idx} column="span 3" columnLg="span 3">
          <Skeleton height="80px" width="100%" />
        </Area>
      ))}
    </Grid>
  </div>
)
