import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Link } from '@deal/router'
import { Button, InlineNotification, PhoneField, Text, TextField } from '@deal/bluxome'
import { isValidPhoneNumber } from '#src/app/services/phone'
import { isEmailValid } from '#src/app/services/email'
import { AuthenticatedUserFragment } from '#src/app/fragments/AuthenticatedUser.generated'
import { useIdentityContext } from '#src/app/containers/Identity'
import { useRegisterCustomerMutation } from '#src/app/components/OAuthRegister/RegisterCustomer.generated'
import { RegistrationHandle } from '#src/app/components/AuthenticationFlow/index'
import OAuthButtons from '#src/app/components/AuthenticationFlow/components/OAuthButtons'
import AuthenticationFlowViewLayout from '#src/app/components/AuthenticationFlow/components/AuthenticationFlowViewLayout/rebranded'
import { useUpdateUserProfileForRegistrationFormMutation } from '../RegistrationForm.generated'
import { RegisterViewProps } from '../../..'
import styles from './styles.css'

export const RegistrationForm: React.FC<RegisterViewProps> = ({
  onRegister,
  onAlreadyRegistered,
  standalone,
  headline = 'Shop with an expert. Love what you buy.',
  subHeadline = 'Join Curated now - it’s free!',
  registrationHandle = RegistrationHandle.PHONE_AND_EMAIL,
  referralCodeId,
  referralRewardCreditClaimToken,
  submitDisabled,
  registrationId,
  footer
}) => {
  const { myself, incompleteUser } = useIdentityContext()
  const [registerCustomer, registerCustomerResult] = useRegisterCustomerMutation()
  const [updateUserProfile] = useUpdateUserProfileForRegistrationFormMutation()
  const [firstName, setFirstName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  const register = () => {
    if (incompleteUser && myself) {
      updateUserProfile({
        variables: {
          input: {
            firstName: firstName,
            email: email,
            phone: phone
          }
        }
      }).then(result => {
        if (result.data?.updateUserProfile.errors.length) {
          toast.error(result.data?.updateUserProfile.errors.join(', '))
          return
        }
        onRegister(
          result.data?.updateUserProfile.user ?? myself.user,
          result.data?.updateUserProfile.errors.join(', ')
        )
      })
      return
    }

    registerCustomer({
      variables: {
        input: {
          firstName: firstName,
          email: email,
          phone: phone,
          referralCodeId,
          referralRewardCreditClaimToken,
          registrationId
        }
      }
    }).then(result => {
      if (result.data?.registerCustomer.user) {
        const errorMessage = result.data.registerCustomer.claimPromotionResult?.errorMessage || ''
        onRegister(result.data.registerCustomer.user, errorMessage)
      }
      if (
        result.data?.registerCustomer.error?.__typename ===
          'AlreadyRegisteredRegisterCustomerError' &&
        result.data.registerCustomer.error.contact.id &&
        onAlreadyRegistered
      ) {
        onAlreadyRegistered(result.data.registerCustomer.error.contact.id)
      }
    })
  }

  const handleSubmit: React.FormEventHandler = e => {
    e.preventDefault()
    register()
  }

  const registrationErrors = registerCustomerResult.data?.registerCustomer.errors

  let isSubmittable = !submitDisabled && !registerCustomerResult.loading && firstName.length > 0
  switch (registrationHandle) {
    case RegistrationHandle.EMAIL:
      isSubmittable = isSubmittable && isEmailValid(email)
      break

    case RegistrationHandle.PHONE:
      isSubmittable = isSubmittable && isValidPhoneNumber(phone)
      break

    case RegistrationHandle.PHONE_AND_EMAIL:
      isSubmittable = isSubmittable && isEmailValid(email) && isValidPhoneNumber(phone)
      break
  }

  const phoneRequired =
    registrationHandle === RegistrationHandle.PHONE ||
    registrationHandle === RegistrationHandle.PHONE_AND_EMAIL

  return (
    <AuthenticationFlowViewLayout
      headline={headline}
      subHeadline={subHeadline}
      standalone={standalone}
      footer={footer}
    >
      <form onSubmit={handleSubmit}>
        {registrationErrors && (
          <InlineNotification
            type="negative"
            style="prominent"
            title={registrationErrors.join(', ')}
          />
        )}
        <div className={styles.fields}>
          <div data-testid="register-first-name">
            <TextField
              name="firstName"
              label="First name"
              value={firstName}
              onChange={setFirstName}
            />
          </div>
          <div data-testid="register-email">
            <TextField name="email" label="Email" type="email" value={email} onChange={setEmail} />
          </div>
          <div data-testid="register-phone">
            <PhoneField
              name="phone"
              label="Phone number"
              type="tel"
              helperText="We'll text order updates to this number"
              defaultValue=""
              onChange={setPhone}
            />
          </div>
          {footer || (
            <Text style="small" color="neutrals-600">
              <span className={styles.tcpa}>
                By continuing, you agree to our{' '}
                <Link to="/legal/terms-conditions">Terms of Service</Link> and{' '}
                <Link to="/legal/privacy-policy">Privacy Policy</Link>
                {phoneRequired
                  ? ', and to receive occasional texts from us. Message and data rates may apply. Text STOP to opt out.'
                  : '.'}
              </span>
            </Text>
          )}
        </div>
        <Button
          color="learn-blue"
          fullWidth
          isDisabled={!isSubmittable}
          type="submit"
          data-testid="register-submit"
          onPress={register}
        >
          Continue →
        </Button>
        <div className={styles.or}>
          <hr />
          <Text>or</Text>
          <hr />
        </div>
        <OAuthButtons
          onFailure={toast.error}
          onSuccess={(user: AuthenticatedUserFragment) => onRegister(user)}
          referralCodeId={referralCodeId}
          referralRewardCreditClaimToken={referralRewardCreditClaimToken}
          registrationId={registrationId}
          isRebranded
        />
      </form>
    </AuthenticationFlowViewLayout>
  )
}
