export function isFirstNameValid(firstName: string) {
  return firstName !== '' && firstName.length > 1
}

interface NameProps {
  firstName?: string | null
  lastName?: string | null
}

export function getFullName({ firstName, lastName }: NameProps) {
  return [firstName, lastName].join(' ').trim()
}

export function getAbbreviatedName({ firstName, lastName }: NameProps) {
  return [firstName, lastName ? `${lastName[0]}.` : ''].join(' ').trim()
}
