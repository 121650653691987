// @ts-ignore TODO: Write types
import split from 'split-string'
import { FacetSelection as GraphQLFacetSelection } from '#src/generated/types'
import { FacetSerializer } from '../types'

interface BucketedFacetSelection extends GraphQLFacetSelection {
  bucketValues: Array<string>
}

const DELIMITER = ','

const BucketedFacetSerializer: FacetSerializer<BucketedFacetSelection> = {
  SERIALIZATION_PREFIX: 'bucket',

  isSerializable(facetSelection: GraphQLFacetSelection): facetSelection is BucketedFacetSelection {
    return Array.isArray(facetSelection.bucketValues)
  },

  serialize(selection: BucketedFacetSelection) {
    return selection.bucketValues
      .map(value => value.replace(/%/g, '%25').replace(/,/g, '%2C'))
      .join(DELIMITER)
  },

  deserialize(value: string) {
    // Split the serialized value, respecting escaped delimiters
    const bucketValues: string[] = split(value, { sep: DELIMITER })

    return {
      bucketValues: bucketValues
        .filter(v => v.length)
        .map(v => v.replace(/%2C/g, ',').replace(/%25/g, '%'))
    }
  }
}

export default BucketedFacetSerializer
