import React from 'react'
import classnames from 'classnames'
import styles from './styles.css'

interface CollapsibleContainerProps extends React.PropsWithChildren {
  collapsed: boolean
  direction: 'horizontal' | 'vertical'
}

export const CollapsibleContainer: React.FC<CollapsibleContainerProps> = ({
  collapsed,
  direction,
  children
}) => (
  <div
    className={classnames(styles.collapsibleOuterContainer, {
      [styles.collapsibleHorizontalOuterContainer]: direction === 'horizontal',
      [styles.collapsibleVerticalOuterContainer]: direction === 'vertical',
      [styles.collapsed]: collapsed
    })}
  >
    <div className={styles.collapsibleInnerContainer}>{children}</div>
  </div>
)
