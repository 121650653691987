import React from 'react'
import { toast } from 'react-toastify'
import { useHistory } from '@deal/router'
import { Button } from '@deal/components'
import loggerClient from '#src/app/services/loggerClient'
import { useStartExpertConsumerConversationMutation } from '#src/app/mutations/StartExpertConsumerConversation.generated'
import useSendMessage from '#src/app/hooks/useSendMessage'
import usePreferredExpert from '#src/app/hooks/usePreferredExpert'
import { useIdentityContext } from '#src/app/containers/Identity'
import { useRecommendedExpertForConversationStartersLazyQuery } from '#src/app/components/ExpertConversationStarters/RecommendedExpertForConversationStarters.generated'
import { SellableForCartAskForAlternativeButtonFragment } from './AskForAlternativeButton.generated'

const userFacingErrorMessage =
  'Shoot, we tried messaging an expert but something went wrong. Please try sending a message yourself.'

interface AskForAlternativeButtonProps {
  sellable: SellableForCartAskForAlternativeButtonFragment
}

const AskForAlternativeButton: React.FC<React.PropsWithChildren<AskForAlternativeButtonProps>> = ({
  sellable
}) => {
  const preferredExpertVanityId = usePreferredExpert()
  const { sendMessage, isMessageSent, loading: messageSending } = useSendMessage()
  const { myself } = useIdentityContext()
  const history = useHistory()

  const [startExpertConsumerConversation, { loading: startExpertConsumerConversationLoading }] =
    useStartExpertConsumerConversationMutation({
      refetchQueries: ['GetBusinessUserForChatByCategoryId'],
      onCompleted: ({ startExpertConsumerConversation }) => {
        const conversationId = startExpertConsumerConversation.conversation?.id
        if (conversationId) {
          sendMessage(
            `Hey, ${sellable.title} seems to be out of stock. Can you help find me an alternative?`,
            conversationId,
            'cart-ask-for-alternative'
          )
          history.push(`/inbox/${conversationId}`)
        } else {
          loggerClient.captureError(
            new Error('No conversation returned from startExpertConsumerConversation')
          )
          toast.error(userFacingErrorMessage)
        }
      },
      onError: () => toast.error(userFacingErrorMessage)
    })

  const [fetchRecommendedExpert, { loading: fetchRecommendedExpertLoading }] =
    useRecommendedExpertForConversationStartersLazyQuery({
      fetchPolicy: 'network-only',
      variables: { categoryId: sellable.categories[0].id, preferredExpertVanityId },
      onCompleted: data => {
        const refetchedExpert = data.category.recommendExpertForConsumer.expert

        /**
         * If the user sends a convo starters and gets a placeholder expert from the refetch it leads to an awkward state
         * where the old expert shows up in the sidebar, but no message is sent because the authenticatedChatUserId
         * will not update.
         */
        if (!refetchedExpert || refetchedExpert.expertAttributes.placeholderExpert || !myself) {
          toast.error(userFacingErrorMessage)
          return
        }

        // TODO: Replace with dedicated api to send rich card to expert
        startExpertConsumerConversation({
          variables: {
            input: {
              consumerId: myself.id,
              expertId: refetchedExpert.id,
              sourceFlow: {
                directExpertChatFlow: { expertId: refetchedExpert.id }
              },
              sourcePage: { sellablePage: { sellableId: sellable.id } }
            }
          }
        })
      }
    })

  const loading =
    fetchRecommendedExpertLoading || startExpertConsumerConversationLoading || messageSending

  return (
    <Button
      variant="secondary-link"
      size="xsmall"
      onClick={() => {
        if (myself) {
          fetchRecommendedExpert()
        }
      }}
      disabled={loading || isMessageSent}
      testId="ask-for-alternative-button"
    >
      <span>
        {loading ? 'Sending message' : isMessageSent ? 'Message sent' : 'Ask for alternative'}
      </span>
    </Button>
  )
}

export default AskForAlternativeButton
