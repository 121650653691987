import React, { useEffect } from 'react'
import classNames from 'classnames'
import { BubbleLoader, Modal } from '@deal/components'
import { ExpertVideoRecordingType } from '#src/generated/types'
import { LeadForLoomModalFragment } from '#src/app/context/loom/LoomModal.generated'
import { useLoomContext } from '#src/app/context/loom'
import { useIdentityContext } from '#src/app/containers/Identity'
import { useCreateExpertVideoRecordingForRecordingModalMutation } from './RecordingModal.generated'
import { ModalType, SDKError } from '../..'
import LoomIcon from './loom.svg'
import CheckIcon from './CheckIcon.svg'
import styles from './styles.css'

const LOOM_BUTTON_ID = 'loom-record-sdk-button'

interface LoomModalProps extends ModalContentProps {
  onCloseRequested: () => void
  loomError: SDKError | null
  isOpen: boolean
}

export const RecordingModal: React.FC<LoomModalProps> = ({
  isOpen,
  setModal,
  onCloseRequested,
  loomError,
  ...rest
}) => {
  const { loading: loomLoading } = useLoomContext()
  /**
   * setting appElement to the root container to avoid errors on inital mount (this is needed to only render when `isOpen` is true)
   * see https://stackoverflow.com/questions/48269381/warning-react-modal-app-element-is-not-defined-please-use-modal-setappeleme#answer-52133165
   */
  let appElement
  if (typeof window !== 'undefined') {
    appElement = document.getElementById('deal') || undefined
  }
  return (
    <Modal
      appElement={appElement}
      isOpen={isOpen}
      onRequestClose={() => onCloseRequested()}
      contentLabel="Loom"
      secondaryActions={[
        {
          label: loomLoading ? (
            <BubbleLoader />
          ) : (
            <>
              <LoomIcon /> Record Loom
            </>
          ),
          id: LOOM_BUTTON_ID,
          onClick: () => onCloseRequested(),
          disabled: loomLoading || !!loomError
        }
      ]}
      tertiaryAction={{
        label: 'Cancel',
        onClick: () => {
          onCloseRequested()
        }
      }}
      hideCloseButton
    >
      <ModalContent setModal={setModal} {...rest} />
    </Modal>
  )
}

interface ModalContentProps {
  isPreview: boolean
  lead: LeadForLoomModalFragment
  setModal: (type: ModalType | null) => void
  onLoomError: (error: SDKError) => void
}

const ModalContent: React.FC<ModalContentProps> = ({ isPreview, lead, onLoomError, setModal }) => {
  const { myself } = useIdentityContext()
  const { setupLoom } = useLoomContext()

  const [createExpertRecording] = useCreateExpertVideoRecordingForRecordingModalMutation()

  useEffect(() => {
    const loomButton = document.getElementById(LOOM_BUTTON_ID)

    if (loomButton) {
      setupLoom({
        leadId: lead.id,
        button: loomButton,
        onError: error => onLoomError(error),
        onInsertClick: _data => {
          setModal(null)
        },
        onUploadComplete: (data, isVideoOn) => {
          setModal(null)

          createExpertRecording({
            variables: {
              input: {
                type: ExpertVideoRecordingType.LOOM,
                leadId: lead.id,
                expertId: myself?.businessUser?.id,
                embedUrl: data.embedUrl,
                shareUrl: data.sharedUrl,
                duration: data.duration ? { amount: data.duration, unit: 'SECONDS' } : null,
                thumbnailUrl: data.thumbnailUrl,
                videoOn: isVideoOn
              }
            }
          })
        },
        onRecordingStart: () => setModal(null),
        onCancel: () => setModal(ModalType.RECORDING),
        /**
         * This listens for 2 specific events that we want to take action on.
         *   1. If the user closes the recording window and does not have a matching videoUrl copied to their clipboard
         *      we should pop a modal that prompts them to copy the link.
         *   2. If the user closes the recording flow before starting we should re-open the recording window so they can begin again if they want.
         */
        onLifecycleUpdate: async (state, previousState, videoUrl) => {
          if (state === 'closed' && videoUrl) {
            const existingClipboardText = await navigator.clipboard.readText()
            if (existingClipboardText !== videoUrl) {
              setModal(ModalType.COPY_LINK)
            }
          } else if (state === 'closed' && previousState === 'pre-recording') {
            setModal(ModalType.RECORDING)
          }
        }
      })
    }
  }, [])

  return (
    <>
      <div
        className={classNames(styles.banner, {
          [styles.bannerAlt]: isPreview
        })}
      >
        {!isPreview && <CheckIcon />}
        <span>
          {isPreview
            ? `Viewing a preview of a curation for ${lead.consumer.firstName}`
            : `Curation sent to ${lead.consumer.firstName}`}
        </span>
      </div>
      <p className={styles.title}>Send a recording of their curation</p>
      <p className={styles.subtitle}>
        Videos are a great way to get users to engage with their curation and are proven to increase
        conversion rates!
      </p>
      <ul className={styles.list}>
        <li className={styles.listItem}>
          <span>Call out which item is your top pick for them and why</span>
        </li>
        <li className={styles.listItem}>
          <span>Keep your video to 1-5 minutes to make it informative, but digestible</span>
        </li>
        <li className={styles.listItem}>
          <span>You are not required to have your camera on for this</span>
        </li>
      </ul>
    </>
  )
}

export default RecordingModal
