import React from 'react'
import { parseISO } from 'date-fns'
import { useExperiment } from '@deal/experiment-js'
import { Text } from '@deal/bluxome'
import { ShippingEtaComputeMethod } from '#src/generated/types'
import { SellableForHolidayShippingEstimateFragment } from './HolidayShippingEstimate.generated'

interface HolidayShippingEstimateProps {
  sellable: SellableForHolidayShippingEstimateFragment
}

export const isPreholidaySeason = () => {
  const now = new Date()
  return isPreholidayDate(now)
}

const isPreholidayDate = (date: Date) => {
  const month = date.getMonth()
  const dayOfTheMonth = date.getDate()

  // December 1st till December 24th is the pre-holiday season
  return month === 11 && dayOfTheMonth < 24
}

export const HolidayShippingEstimate: React.FC<HolidayShippingEstimateProps> = ({ sellable }) => {
  const shippingMethods = sellable.holidayShippingMethods

  const consider1PPriceSourceOnly =
    useExperiment({
      experiment: 'holiday-shipping-one-p',
      defaultTreatment: 'control'
    }).result === 'one-p-only'

  if (!shippingMethods) {
    return null
  }

  const preholidayShippingMethod = shippingMethods
    .filter(method => {
      // Only consider methods that are location-based, as that identifies a warehouse shipping
      return (
        !consider1PPriceSourceOnly ||
        method.etaComputation?.method === ShippingEtaComputeMethod.LOCATION_BASED
      )
    })
    .find(method => {
      const deliveryDate = parseISO(method.deliveryTimeRange.to ?? method.deliveryTimeRange.from)
      return isPreholidayDate(deliveryDate)
    })

  if (!preholidayShippingMethod) {
    return null
  }

  return (
    <Text style="small-semibold" color="feedback-success-400">
      Holiday-ready delivery available!
    </Text>
  )
}
