import React from 'react'
import { Expert, ExpertNameTag as ExpertNameTagFromBluxome } from '@deal/bluxome'
import {
  expertForAvatar,
  expertForAvatarWithPopover
} from '#src/app/services/avatars/expertForAvatar'
import { BusinessUserForExpertNameTagFragment } from './ExpertNameTag.generated'

type ExpertNameTagFromBluxomeProps = React.ComponentPropsWithoutRef<typeof ExpertNameTagFromBluxome>

interface ExpertNameTagProps extends Omit<ExpertNameTagFromBluxomeProps, keyof Expert | 'popover'> {
  expert: BusinessUserForExpertNameTagFragment
  /**
   * default: 'base'
   */
  size?: 'small' | 'base' | 'large'
  disablePopover?: boolean
  onAskAQuestionPress?: () => void
}

export const ExpertNameTag: React.FC<ExpertNameTagProps> = ({
  expert,
  size = 'base',
  disablePopover = false,
  ...props
}) => {
  let expertProps
  if (disablePopover) {
    expertProps = expertForAvatar(expert)
  } else {
    expertProps = expertForAvatarWithPopover(expert)
  }

  return <ExpertNameTagFromBluxome {...expertProps} {...props} size={size} />
}
