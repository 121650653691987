import React from 'react'
import { Modal } from '@deal/components'
import styles from './styles.css'

interface CopyLinkModalProps {
  isOpen: boolean
  onCloseRequested: () => void
  videoUrl: string
}

const CopyLinkModal: React.FC<CopyLinkModalProps> = ({ isOpen, onCloseRequested, videoUrl }) => {
  return (
    <Modal
      isOpen={isOpen}
      primaryAction={{
        label: 'Copy video link',
        onClick: () => {
          navigator.clipboard.writeText(videoUrl!)
          onCloseRequested()
        }
      }}
      secondaryActions={[
        {
          label: 'Cancel',
          onClick: () => {
            onCloseRequested()
          }
        }
      ]}
      contentLabel="LoomCopy"
    >
      <p className={styles.title}>We've got your Loom link!</p>
      <p className={styles.subtitle}>
        We noticed you didn't get a chance to copy the video link. Click below to copy it now,{' '}
        <a href={videoUrl} target="_blank" rel="noreferrer" className={styles.link}>
          or click here to access a preview of your Loom
        </a>
        .
      </p>
    </Modal>
  )
}

export default CopyLinkModal
