import React from 'react'
import loadable from '@loadable/component'
import { useApolloClient } from '@apollo/client'
import { useLocation } from '@deal/router'
import { FeedSimpleTag, ForYouFeedConnection } from '#src/generated/types'
import { useDepartment } from '#src/app/context/department'
import Page from '#src/app/components/Page'
import { useForYouPageQuery } from './ForYouPage.generated'
import LoadingComponent from './loading'

const PageComponent = loadable(() => import(/* webpackChunkName: "for-you" */ './page/index'), {
  resolveComponent: components => components.FeedPage
})

interface FeedPageProps {}

const ForYouPage: React.FC<FeedPageProps> = () => {
  const department = useDepartment()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const sortBy = params.get('sort') === 'new' ? 'POSTED_AT' : undefined
  const after = params.has('after') ? decodeURIComponent(params.get('after')!) : undefined
  const simpleTag = (params.get('tag')?.toUpperCase() as FeedSimpleTag) || undefined
  const pinPostIds = params.getAll('pinPostIds')
  const post =
    params.get('post')?.toLowerCase() === 'products'
      ? FeedSimpleTag.PRODUCT_RECOMMENDATIONS
      : undefined

  const query = useForYouPageQuery({
    variables: {
      after: after,
      sortBy,
      query: {
        simpleTag: simpleTag || post,
        pinPostIds
      }
    }
  })

  const { cache } = useApolloClient()

  return (
    <Page
      query={query}
      pageKey="for-you-feed"
      chat={true}
      seoTitle="Curated for you"
      seoDescription="Stay informed and inspired every day with the latest updates from our Expert Community"
      seoIndexable
      category={() => undefined}
      department={() => department ?? undefined}
      expert={() => undefined}
      sellable={() => undefined}
      pageComponent={PageComponent}
      loadingComponent={LoadingComponent}
      pageProps={{
        onLoadMore: (cursor: string) => {
          query.fetchMore({ variables: { after: cursor } }).then(() => {
            cache.modify({
              fields: {
                forYouFeedConnection(existing: ForYouFeedConnection) {
                  const newEdges = existing.edges.filter((edge, index, self) => {
                    return (
                      index ===
                      self.findIndex(other => {
                        // @ts-ignore
                        return edge.node.post.__ref === other.node.post.__ref
                      })
                    )
                  })
                  const newOne = { ...existing }
                  newOne.edges = newEdges
                  return newOne
                }
              }
            })
          })
        }
      }}
      ogImageUrl={() => undefined}
      canonicalPath="/for-you"
    />
  )
}

export default ForYouPage
