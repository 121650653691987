import { CreatePathProps, useCreatePath } from '@deal/path-flow'
import { AttributeInput, PathSchemaSelectorInput } from '#src/generated/types'
import { usePathPageTrackingCodes } from '#src/app/services/path'
import { TrackingCodes } from './hooks/useCreateRequestPath'

type CreateProductRecommendationPathTuple = [
  (props: CreateProductRecommendationPathProps) => void,
  { loading: boolean }
]

export interface CreateProductRecommendationPathProps {
  pathSelector: PathSchemaSelectorInput
  trackingCodes: TrackingCodes
  initialAttributes?: AttributeInput[]
}

/**
 * A hook wrapping the path creation for when paths are created specifically for a product recommendation.
 *
 * @returns CreatePathTuple
 */
export default function useCreateProductRecommendationPath(
  createPathProps: CreatePathProps
): CreateProductRecommendationPathTuple {
  const pageTrackingCodes = usePathPageTrackingCodes()
  const [createPath, { loading }] = useCreatePath(createPathProps)

  return [
    ({ trackingCodes, pathSelector, initialAttributes }: CreateProductRecommendationPathProps) => {
      const effectiveInitialAttributes = initialAttributes
      createPath({
        timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
        productRecommendations: {
          initialAttributes: effectiveInitialAttributes,
          pathSelector
        },

        trackingCodes: {
          pageKey: pageTrackingCodes.pageKey,
          originalPageContext: pageTrackingCodes.originalPageContext,
          originalPageUrl: pageTrackingCodes.originalPageUrl,
          ctaName: trackingCodes.ctaName,
          pageVariation: trackingCodes.pageVariation,
          sourceKey: trackingCodes.sourceKey
        }
      })
    },
    { loading }
  ]
}
