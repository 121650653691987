import React, { useState } from 'react'
import classNames from 'classnames'
import { Button, Image } from '@deal/components'
import { Text } from '@deal/bluxome'
import { isSellableOnClearance } from '#src/app/services/sellable/isSellableOnClearance'
import { formatAmount, formatMonetaryAmountAsJsx } from '#src/app/services/money'
import { GroupedLineItemsType } from '#src/app/services/cart'
import { useCartVisibilityContext } from '#src/app/context/cart-visibility'
import SellableLink from '#src/app/components/SellableLink'
import PurplePill from '#src/app/components/PurplePill'
import ProductLineItemVariantAndCustomizationValues from '#src/app/components/ProductLineItemVariantAndCustomizationFeatureValues'
import ProductLineItemPrice from '#src/app/components/ProductLineItemPrice'
import ProductBundleLineItem from '#src/app/components/ProductBundleLineItem'
import PreOrderInfo from '#src/app/components/PreOrderInfo'
import OrderMessages from '#src/app/components/OrderMessages'
import MemberInsidersBubbleLegacy from '#src/app/components/MemberInsidersBubbleLegacy'
import ExpertServiceImage from '#src/app/components/ExpertServiceImage'
import ClearanceInfo from '#src/app/components/ClearanceInfo'
import defaultProduct from '#src/app/assets/images/default-product.png'
import { OrderLineItemForCartFragment } from '../../ShoppingCart.generated'
import OrderPromotionMessage from '../OrderPromotionMessage'
import ShortSupply from '../../../ShortSupply'
import PromotionIcon from './promotion-icon.svg'
import RemoveLineItemButton from './components/RemoveLineItemButton'
import LineItemQuantity from './components/LineItemQuantity'
import { HolidayShippingEstimate } from './components/HolidayShippingEstimate'
import ArrowDownIcon from './arrow-down.svg'
import styles from './styles.css'

interface Props {
  orderId: string
  group: GroupedLineItemsType<OrderLineItemForCartFragment>
  hasAppliedPromotion: boolean
}

const ProductLineItemGroup: React.FC<React.PropsWithChildren<Props>> = ({
  orderId,
  group,
  hasAppliedPromotion
}) => {
  const [showBundleLineItems, setShowBundleLineItems] = useState(false)
  const { parentLineItem, children } = group

  const getLineItemImage = (lineItem: OrderLineItemForCartFragment) => {
    if (lineItem.source.__typename === 'OrderLineItemProductBundleSource') {
      return lineItem.source.bundle.images[0]?.url || defaultProduct
    }

    // Going to leave this if check for now since this will probably behave a little
    // different with adHoc and expert item sources
    if (lineItem.source.__typename !== 'OrderLineItemSellableSource') {
      return defaultProduct
    }

    if (lineItem.source.sellable && lineItem.source.sellable.images.length > 0) {
      return lineItem.source.sellable.images[0].url
    } else {
      return defaultProduct
    }
  }

  const getLineItemSellable = (lineItem: OrderLineItemForCartFragment) => {
    if (lineItem.source.__typename === 'OrderLineItemSellableSource') {
      return lineItem.source.sellable
    }

    return undefined
  }

  const lineItemImage = getLineItemImage(parentLineItem)
  const sellable = getLineItemSellable(parentLineItem)
  const expertServiceSource =
    parentLineItem.source.__typename === 'OrderLineItemExpertServiceSource'
      ? parentLineItem.source
      : undefined

  const productPurchasePrice = children.reduce(
    (total, childLineItem) => total + Number(childLineItem.unitPrice.amount),
    0
  )
  const trialLineItem = children.find(item => item.source.type === 'TRIAL_FEE')
  const isItemGroupForTrialOrder = !!trialLineItem

  const isItemGroupForCustomizedItem =
    parentLineItem.source.__typename === 'OrderLineItemSellableSource' &&
    parentLineItem.source.selectedCustomizationFeatures.length &&
    parentLineItem.source.selectedCustomizationFeatures.length > 0

  const showPreOrderInfo = !!sellable?.availableAt

  const isProductBundleLineItem =
    parentLineItem.source.__typename === 'OrderLineItemProductBundleSource'

  const { setCartVisibility } = useCartVisibilityContext()

  return (
    <div className={styles.container}>
      <div className={styles.lineItem}>
        <div className={styles.imageContainer}>
          {expertServiceSource ? (
            <div className={styles.image}>
              <ExpertServiceImage />
            </div>
          ) : sellable ? (
            <SellableLink
              sellable={sellable}
              className={styles.link}
              onClick={() => setCartVisibility(false)}
            >
              {isItemGroupForTrialOrder && (
                <PurplePill className={styles.trialPill}>Trial</PurplePill>
              )}
              <Image
                src={lineItemImage}
                className={classNames(styles.image, {
                  [styles.imagePadding]: isItemGroupForTrialOrder
                })}
                width={80}
                imgixParams={{ fit: 'clip' }}
              />
            </SellableLink>
          ) : (
            <Image
              src={lineItemImage}
              className={styles.image}
              width={80}
              imgixParams={{ fit: 'clip' }}
            />
          )}
        </div>
        <div className={styles.details}>
          {sellable ? (
            <SellableLink
              sellable={sellable}
              className={styles.title}
              data-testid="cart-item-title"
              onClick={() => setCartVisibility(false)}
            >
              {parentLineItem.title}
            </SellableLink>
          ) : (
            <span className={styles.title}>{parentLineItem.title}</span>
          )}
          <ProductLineItemVariantAndCustomizationValues productLineItem={parentLineItem} />

          {isItemGroupForTrialOrder ? (
            <div className={styles.trialPriceDetails}>
              <div className={styles.trialPriceDetailsTopRow}>
                {/* show the trial "pay now" price */}
                <span className={styles.trialPriceToday}>
                  {formatMonetaryAmountAsJsx(trialLineItem.price)} trial fee
                </span>
              </div>
              <div className={styles.productPurchasePrice}>
                Product price {formatAmount(productPurchasePrice)}
              </div>
            </div>
          ) : // If there are no children we just render the price for parent sellable, otherwise we map over all grouped children line items
          isProductBundleLineItem || children.length === 0 ? (
            <ProductLineItemPrice lineItem={parentLineItem} />
          ) : (
            [...children]
              .sort((a, b) => {
                if (!isItemGroupForCustomizedItem) {
                  return 0
                }
                if (a.source.__typename === 'OrderLineItemBasePriceSource') {
                  return -1
                }
                if (
                  parentLineItem.source.__typename === 'OrderLineItemSellableSource' &&
                  parentLineItem.source.selectedCustomizationFeatures &&
                  parentLineItem.source.selectedCustomizationFeatures.length + 1 ===
                    group.children.length
                ) {
                  const features = parentLineItem.source.selectedCustomizationFeatures
                  const featureIndex = (item: OrderLineItemForCartFragment) =>
                    features.findIndex(feature => {
                      switch (feature.selection.__typename) {
                        case 'ProductCustomizationAddOnFeatureSelection':
                          return feature.selection.featureName === item.title

                        case 'ProductCustomizationAttributeFeatureSelection':
                          if (
                            item.source.__typename === 'OrderLineItemCustomizationAttributeSource'
                          ) {
                            return (
                              feature.selection.attribute.formatted?.internalName ===
                              item.source.attribute.formatted?.internalName
                            )
                          }
                          return false

                        case 'ProductCustomizationProductFeatureSelection':
                          return feature.selection.sellable.title === item.title
                      }
                    })
                  return featureIndex(a) - featureIndex(b)
                }
                return 0
              })
              .map(child => {
                if (
                  isItemGroupForCustomizedItem &&
                  child.source.__typename !== 'OrderLineItemBasePriceSource' &&
                  Number(child.unitPrice.amount) === 0
                ) {
                  return null
                }

                return <ProductLineItemPrice key={child.id} lineItem={child} />
              })
          )}
          {!!isItemGroupForCustomizedItem && (
            <div className={styles.customizedTotalPrice}>
              <Text style="small">Total: {formatAmount(productPurchasePrice)}</Text>
            </div>
          )}

          {sellable?.inShortSupply && <ShortSupply />}
          {sellable && <HolidayShippingEstimate sellable={sellable} />}

          {hasAppliedPromotion &&
            parentLineItem.promotionApplied &&
            !parentLineItem.notEligibleForPromotionReason && (
              <div className={styles.appliedPromotionContainer}>
                <PromotionIcon />
                <div className={styles.appliedPromotionText}>Promotion applied</div>
              </div>
            )}
          <div className={styles.lineItemInfoContainer}>
            <div className={styles.quantityContainer}>
              {isProductBundleLineItem ? (
                <Button
                  variant="secondary-link"
                  size="xsmall"
                  onClick={() => setShowBundleLineItems(!showBundleLineItems)}
                >
                  Bundle products{' '}
                  <ArrowDownIcon
                    className={classNames(styles.productBundleArrow, {
                      [styles.rotate]: showBundleLineItems
                    })}
                  />
                </Button>
              ) : (
                !isItemGroupForTrialOrder && (
                  <LineItemQuantity
                    orderId={orderId}
                    parentLineItemId={parentLineItem.id}
                    quantity={parentLineItem.quantity}
                  />
                )
              )}
              <RemoveLineItemButton
                orderId={orderId}
                parentLineItemId={parentLineItem.id}
                sellable={sellable}
              />
            </div>
            {isProductBundleLineItem && showBundleLineItems && (
              <div>
                {children.map(child => (
                  <ProductBundleLineItem key={child.id} lineItem={child} />
                ))}
              </div>
            )}
            {showPreOrderInfo && (
              <PreOrderInfo
                preOrder={sellable?.availableForPreOrder}
                availableAt={sellable?.availableAt}
              />
            )}
            {!isItemGroupForTrialOrder &&
              (children.length > 0 ? (
                <MemberInsidersBubbleLegacy
                  currentMembershipReward={children.reduce((partial, entry) => {
                    return (
                      partial +
                      (entry.currentMembershipReward
                        ? parseFloat(entry.currentMembershipReward.rewardAmount.amount)
                        : 0)
                    )
                  }, 0)}
                  isParentVariant={false}
                />
              ) : (
                parentLineItem.currentMembershipReward && (
                  <MemberInsidersBubbleLegacy
                    currentMembershipReward={parseFloat(
                      parentLineItem.currentMembershipReward.rewardAmount.amount
                    )}
                    isParentVariant={false}
                  />
                )
              ))}
            <OrderPromotionMessage
              reason={parentLineItem.notEligibleForPromotionReason}
              isLineItemReason
            />
            <OrderMessages messages={parentLineItem.orderUserMessages} />
          </div>
          {sellable && isSellableOnClearance(sellable) && (
            <ClearanceInfo className={styles.clearanceInfo} />
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductLineItemGroup
