import React from 'react'
import { AuthenticatedUserFragment } from '../../../../fragments/AuthenticatedUser.generated'
import { AuthIntent, AuthenticationFlowViewLayoutProps } from '../../index'
import { LoginForm as LoginFormLegacy } from './v1'
import { LoginForm as LoginViewRebranded } from './rebranded'

export interface LoginFormProps extends AuthenticationFlowViewLayoutProps {
  onIntentChanged: (intent: AuthIntent) => void
  onLogin: (user: AuthenticatedUserFragment) => void
  handleId?: string
  isRebranded?: boolean
}

export const LoginForm: React.FC<LoginFormProps> = ({ isRebranded = false, ...props }) => {
  if (isRebranded) {
    return <LoginViewRebranded {...props} />
  }
  return <LoginFormLegacy {...props} />
}
