import React from 'react'
import { useBreakpoint } from '#src/app/hooks/useBreakpoint'
import { Skeleton } from '#src/app/components/SkeletonLoader'
import PageContent from '#src/app/components/PageContent'
import styles from './styles.css'

const DesktopItem: React.FC = () => {
  return (
    <div className={styles.item}>
      <Skeleton width={120} height={120} />
      <div className={styles.itemDetails}>
        <Skeleton width={80} height={20} />
        <Skeleton width={400} height={30} className={styles.title} />
        <Skeleton width={200} height={15} className={styles.itemDetail} />
        <Skeleton width={200} height={15} className={styles.itemDetail} />
      </div>
    </div>
  )
}

const MobileItem: React.FC = () => {
  return (
    <div className={styles.item}>
      <Skeleton width={80} height={80} />
      <div className={styles.itemDetails}>
        <Skeleton width={60} height={20} />
        <Skeleton width={200} height={30} className={styles.title} />
        <Skeleton width={100} height={15} className={styles.itemDetail} />
        <Skeleton width={100} height={15} className={styles.itemDetail} />
      </div>
    </div>
  )
}

const ManageWishlistsRouteLoader: React.FC = () => {
  const isLargerThanSm = useBreakpoint('sm')
  const itemArr = [1, 2, 3]

  return (
    <PageContent>
      <div className={styles.header}>
        <Skeleton height={60} width={300} />
      </div>
      <Skeleton height={20} width={300} />
      <div className={styles.bar}>
        <Skeleton height={20} width={350} />
      </div>
      {isLargerThanSm
        ? itemArr.map(idx => <DesktopItem key={idx} />)
        : itemArr.map(idx => <MobileItem key={idx} />)}
    </PageContent>
  )
}

export default ManageWishlistsRouteLoader
