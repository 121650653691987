import React from 'react'
import { Location } from 'history'
import { Link } from '@deal/router'
import { Button, ButtonColor } from '@deal/bluxome'
import Logo from './logo.svg'
import styles from './styles.css'

interface ErrorProps {
  code: string
  headline: string
  redirectButtonLink: string | Location<unknown>
  redirectButtonColor: ButtonColor
}

export const Error: React.FC<ErrorProps> = ({
  code,
  headline,
  redirectButtonLink,
  redirectButtonColor
}) => (
  <section className={styles.section}>
    <header>
      <Link to="/" className={styles.logo}>
        <Logo className={styles.logo} />
      </Link>
    </header>
    <div className={styles.code}>{code}</div>
    <h1 className={styles.headline}>{headline}</h1>
    <Button link={{ to: redirectButtonLink }} color={redirectButtonColor} size="large">
      Take me home
    </Button>
  </section>
)
