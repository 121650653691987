import React from 'react'
import { Link } from '@deal/router'
import styles from './styles.css'

const TrialInfoBox: React.FC = () => {
  return (
    <div className={styles.container}>
      <h3 className={styles.heading}>How our trial works</h3>
      <ol className={styles.list} role="list">
        <li className={styles.listItemContainer}>
          <span className={styles.listItemMarker} />
          <div className={styles.listItemContent}>
            Upon checkout, a deposit will be placed for the full purchase price of all items. You'll
            see a pending charge authorized on your credit card statement, but we aren't charging
            you yet.
          </div>
        </li>
        <li className={styles.listItemContainer}>
          <span className={styles.listItemMarker} />
          <div className={styles.listItemContent}>
            Your trial period starts when your items are delivered. You have 30 days to try them
            out.
          </div>
        </li>
        <li className={styles.listItemContainer}>
          <span className={styles.listItemMarker} />
          <div className={styles.listItemContentFlex}>
            <div>
              If you love everything you tried, you can keep the items and we'll complete the charge
              when the trial ends. The trial fees are waived.
            </div>
            <span className={styles.separator}>OR</span>
            <div>
              If you'd like to return any trial items, let us know before your trial ends. You'll
              only be charged the trial fee for each item returned, plus tax.
            </div>
          </div>
        </li>
      </ol>
      <span className={styles.footer}>
        More questions on trial?{' '}
        <Link to="/trial-faq" target="_blank" className={styles.link}>
          Read our FAQs
        </Link>
      </span>
    </div>
  )
}

export default TrialInfoBox
