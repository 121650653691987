// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type UnregisterWebPushSubscriptionMutationVariables = Types.Exact<{
  subscription: Types.Scalars['String']['input'];
}>;


export type UnregisterWebPushSubscriptionMutation = { __typename: 'Mutation', unregisterWebPushSubscription: { __typename: 'UnregisterWebPushSubscriptionPayload', unregistered: boolean } };


export const UnregisterWebPushSubscriptionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UnregisterWebPushSubscription"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"subscription"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"unregisterWebPushSubscription"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"subscription"},"value":{"kind":"Variable","name":{"kind":"Name","value":"subscription"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"unregistered"}}]}}]}}]} as unknown as DocumentNode;
export type UnregisterWebPushSubscriptionMutationFn = Apollo.MutationFunction<UnregisterWebPushSubscriptionMutation, UnregisterWebPushSubscriptionMutationVariables>;
export type UnregisterWebPushSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnregisterWebPushSubscriptionMutation, UnregisterWebPushSubscriptionMutationVariables>, 'mutation'>;

    export const UnregisterWebPushSubscriptionComponent = (props: UnregisterWebPushSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<UnregisterWebPushSubscriptionMutation, UnregisterWebPushSubscriptionMutationVariables> mutation={UnregisterWebPushSubscriptionDocument} {...props} />
    );
    

/**
 * __useUnregisterWebPushSubscriptionMutation__
 *
 * To run a mutation, you first call `useUnregisterWebPushSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnregisterWebPushSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unregisterWebPushSubscriptionMutation, { data, loading, error }] = useUnregisterWebPushSubscriptionMutation({
 *   variables: {
 *      subscription: // value for 'subscription'
 *   },
 * });
 */
export function useUnregisterWebPushSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<UnregisterWebPushSubscriptionMutation, UnregisterWebPushSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnregisterWebPushSubscriptionMutation, UnregisterWebPushSubscriptionMutationVariables>(UnregisterWebPushSubscriptionDocument, options);
      }
export type UnregisterWebPushSubscriptionMutationHookResult = ReturnType<typeof useUnregisterWebPushSubscriptionMutation>;
export type UnregisterWebPushSubscriptionMutationResult = Apollo.MutationResult<UnregisterWebPushSubscriptionMutation>;
export type UnregisterWebPushSubscriptionMutationOptions = Apollo.BaseMutationOptions<UnregisterWebPushSubscriptionMutation, UnregisterWebPushSubscriptionMutationVariables>;