import { scroller } from 'react-scroll'

export const HEADER_HEIGHT = 177

export function useScrollToWithHeaderOffset() {
  return function (name: string) {
    scroller.scrollTo(name, {
      offset: HEADER_HEIGHT * -1
    })
  }
}

export { Element as ScrollElement } from 'react-scroll'
