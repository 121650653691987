import React from 'react'
import { withStatusCode } from '#src/app/containers/Router'
import { Error } from '../Error'

const NotFound = () => (
  <Error
    code="403"
    headline="This page is forbidden."
    redirectButtonLink="/"
    redirectButtonColor="neutral-dark"
  />
)

export default withStatusCode(403)(NotFound)
