import * as History from 'history'
import { ConsumerTracking } from '@deal/web-tracking'
import { matchPath } from '@deal/router'
import { CategoryPageQuery } from './types'

/**
 * Parse a URL for a category page. Category pages are a sort of "homepage" for select
 *   high level categories. The category pages contain recommended products and links
 *   PLPs for more specific categories. Additionally, cateogry pages support marketing
 *   features, such as engagement channels and Page Builder functionality.
 *
 * Some example Category Page URLs might look like:
 *
 *  /shop/skiing
 *  /shop/golf
 *  /shop/kitchen
 */
export function parseCategoryPageUrl({ pathname, search }: History.Location): CategoryPageQuery {
  const searchParams = new URLSearchParams(search)

  // Find `/shop/:categorySlug`
  const categorySlug = matchPath<{ categorySlug: string }>(pathname, {
    path: '/shop/:categorySlug'
  })?.params.categorySlug

  if (!categorySlug) {
    throw new Error(`Can not parse invalid category page path: ${pathname}`)
  }

  // Create a basic query
  const query: CategoryPageQuery = {
    categorySlug
  }

  // Add the ad context
  if (searchParams.has('ctx')) {
    query.adContext = searchParams.get('ctx')!
  }

  // Add the content selection rule ID
  if (searchParams.has('ruleId')) {
    query.ruleId = searchParams.get('ruleId')!
  }

  // Add the promotion IDs to filter for eligibility
  if (searchParams.has('promo')) {
    query.promotionIds = searchParams.get('promo')!.split(',')
  }

  // Add the entry point for tracking
  if (searchParams.has('source')) {
    query.entryPoint = searchParams.get('source')! as ConsumerTracking.SearchEntryPoint
  }

  return query
}
