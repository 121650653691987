// @ts-nocheck
import type * as Types from '../../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ConsumerProductReviewReactionSummaryForConsumerProductReviewReactionsFragment = { __typename: 'ConsumerProductReviewReactionSummary', reactionTypeAndCount: Array<{ __typename: 'ConsumerProductReviewReactionTypeAndCount', reactionType: Types.ConsumerProductReviewReactionType, count: number, consumerReaction?: { __typename: 'ConsumerProductReviewReaction', id: any, reactionType: Types.ConsumerProductReviewReactionType } | null }> };

export type ConsumerProductReviewForConsumerProductReviewReactionsFragment = { __typename: 'ConsumerProductReview', id: any, reviewReactionSummary?: { __typename: 'ConsumerProductReviewReactionSummary', reactionTypeAndCount: Array<{ __typename: 'ConsumerProductReviewReactionTypeAndCount', reactionType: Types.ConsumerProductReviewReactionType, count: number, consumerReaction?: { __typename: 'ConsumerProductReviewReaction', id: any, reactionType: Types.ConsumerProductReviewReactionType } | null }> } | null };

export type AddConsumerProductReviewReactionMutationVariables = Types.Exact<{
  input: Types.AddConsumerProductReviewReactionInput;
}>;


export type AddConsumerProductReviewReactionMutation = { __typename: 'Mutation', addConsumerProductReviewReaction: { __typename: 'AddConsumerProductReviewReactionPayload', error?: { __typename: 'NotActiveAddConsumerProductReviewReactionError', errorMessage: string } | { __typename: 'SelfReviewAddConsumerProductReviewReactionError', errorMessage: string } | null, review?: { __typename: 'ConsumerProductReview', id: any, reviewReactionSummary?: { __typename: 'ConsumerProductReviewReactionSummary', reactionTypeAndCount: Array<{ __typename: 'ConsumerProductReviewReactionTypeAndCount', reactionType: Types.ConsumerProductReviewReactionType, count: number, consumerReaction?: { __typename: 'ConsumerProductReviewReaction', id: any, reactionType: Types.ConsumerProductReviewReactionType } | null }> } | null } | null } };

export const ConsumerProductReviewReactionSummaryForConsumerProductReviewReactionsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ConsumerProductReviewReactionSummaryForConsumerProductReviewReactions"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ConsumerProductReviewReactionSummary"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"reactionTypeAndCount"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"reactionType"}},{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"consumerReaction"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"reactionType"}}]}}]}}]}}]} as unknown as DocumentNode;
export const ConsumerProductReviewForConsumerProductReviewReactionsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ConsumerProductReviewForConsumerProductReviewReactions"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ConsumerProductReview"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"reviewReactionSummary"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConsumerProductReviewReactionSummaryForConsumerProductReviewReactions"}}]}}]}}]} as unknown as DocumentNode;
export const AddConsumerProductReviewReactionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddConsumerProductReviewReaction"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddConsumerProductReviewReactionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addConsumerProductReviewReaction"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"error"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"NotActiveAddConsumerProductReviewReactionError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"errorMessage"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SelfReviewAddConsumerProductReviewReactionError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"errorMessage"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"review"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConsumerProductReviewForConsumerProductReviewReactions"}}]}}]}}]}},...ConsumerProductReviewForConsumerProductReviewReactionsFragmentDoc.definitions,...ConsumerProductReviewReactionSummaryForConsumerProductReviewReactionsFragmentDoc.definitions]} as unknown as DocumentNode;
export type AddConsumerProductReviewReactionMutationFn = Apollo.MutationFunction<AddConsumerProductReviewReactionMutation, AddConsumerProductReviewReactionMutationVariables>;
export type AddConsumerProductReviewReactionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddConsumerProductReviewReactionMutation, AddConsumerProductReviewReactionMutationVariables>, 'mutation'>;

    export const AddConsumerProductReviewReactionComponent = (props: AddConsumerProductReviewReactionComponentProps) => (
      <ApolloReactComponents.Mutation<AddConsumerProductReviewReactionMutation, AddConsumerProductReviewReactionMutationVariables> mutation={AddConsumerProductReviewReactionDocument} {...props} />
    );
    

/**
 * __useAddConsumerProductReviewReactionMutation__
 *
 * To run a mutation, you first call `useAddConsumerProductReviewReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddConsumerProductReviewReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addConsumerProductReviewReactionMutation, { data, loading, error }] = useAddConsumerProductReviewReactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddConsumerProductReviewReactionMutation(baseOptions?: Apollo.MutationHookOptions<AddConsumerProductReviewReactionMutation, AddConsumerProductReviewReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddConsumerProductReviewReactionMutation, AddConsumerProductReviewReactionMutationVariables>(AddConsumerProductReviewReactionDocument, options);
      }
export type AddConsumerProductReviewReactionMutationHookResult = ReturnType<typeof useAddConsumerProductReviewReactionMutation>;
export type AddConsumerProductReviewReactionMutationResult = Apollo.MutationResult<AddConsumerProductReviewReactionMutation>;
export type AddConsumerProductReviewReactionMutationOptions = Apollo.BaseMutationOptions<AddConsumerProductReviewReactionMutation, AddConsumerProductReviewReactionMutationVariables>;