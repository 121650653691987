// @ts-nocheck
import type * as Types from '../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { BusinessUserForExpertForAvatarFragmentDoc, BusinessUserForExpertForAvatarWithPopoverFragmentDoc } from '../../../../services/avatars/BusinessUserForExpertForAvatar.generated';
import { BusinessUserForGetExpertExpertiseFragmentDoc, BusinessUserForGetExpertProfileLinkFragmentDoc } from '../../../../services/expert/expert.generated';
import { SellableExpertiseRequestForChatWithExpertButtonFragmentDoc } from '../ChatWithExpertButton/ChatWithExpertButton.generated';
export type SellableExpertiseRuleMappingForWhyThesePicksFragment = { __typename: 'SellableExpertiseRuleMapping', id: any, explanation: string, sellableAttributeSchema: { __typename: 'AttributeSchemaRevision', id: any, name: string, displayName: string, type: Types.AttributeType, description?: string | null, enumEntries: Array<{ __typename: 'AttributeEnumEntry', name: string, displayName: string, explanation?: string | null }> }, sellableAttributeValues: Array<{ __typename: 'SellableExpertiseRuleMappingSellableAttributeDecimalRangeValue', explanation?: string | null, displayValue: string, quality?: Types.SellableExpertiseFitQuality | null } | { __typename: 'SellableExpertiseRuleMappingSellableAttributeEnumerationValue', explanation?: string | null, displayValue: string, quality?: Types.SellableExpertiseFitQuality | null } | { __typename: 'SellableExpertiseRuleMappingSellableAttributeIntegerRangeValue', explanation?: string | null, displayValue: string, quality?: Types.SellableExpertiseFitQuality | null }> };

export type SellableExpertiseRecommendationConnectionForWhyThesePicksFragment = { __typename: 'SellableExpertiseRecommendationConnection', mappings: Array<{ __typename: 'SellableExpertiseRuleMapping', id: any, explanation: string, sellableAttributeSchema: { __typename: 'AttributeSchemaRevision', id: any, name: string, displayName: string, type: Types.AttributeType, description?: string | null, enumEntries: Array<{ __typename: 'AttributeEnumEntry', name: string, displayName: string, explanation?: string | null }> }, sellableAttributeValues: Array<{ __typename: 'SellableExpertiseRuleMappingSellableAttributeDecimalRangeValue', explanation?: string | null, displayValue: string, quality?: Types.SellableExpertiseFitQuality | null } | { __typename: 'SellableExpertiseRuleMappingSellableAttributeEnumerationValue', explanation?: string | null, displayValue: string, quality?: Types.SellableExpertiseFitQuality | null } | { __typename: 'SellableExpertiseRuleMappingSellableAttributeIntegerRangeValue', explanation?: string | null, displayValue: string, quality?: Types.SellableExpertiseFitQuality | null }> }> };

export type CategoryForRecommendedWhyThesePicksFragment = { __typename: 'Category', id: any, singularDisplayName: string, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, inventory?: { __typename: 'CategoryAssetNoun', singular: string } | null } | null, expertCategories: Array<{ __typename: 'Category', id: any, singularDisplayName: string }>, experts: Array<{ __typename: 'BusinessUser', id: any, displayName: string, expertProfile?: { __typename: 'ExpertProfile', id: any, headline: string, profilePhoto?: { __typename: 'MediaFile', id: any, url: string } | null } | null, expertStats?: { __typename: 'ExpertStats', averageRating?: number | null } | null, expertAttributes: { __typename: 'BusinessUserExpertAttributes', category: { __typename: 'Category', id: any, slug: string, singularDisplayName: string, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, expert?: { __typename: 'CategoryAssetNoun', singular: string } | null } | null }, specialtyCategory?: { __typename: 'Category', id: any, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, expert?: { __typename: 'CategoryAssetNoun', singular: string } | null } | null } | null }, articleAuthorProfile?: { __typename: 'ExpertProfile', id: any, headline: string } | null }>, department?: { __typename: 'Department', id: any, activeExpertCount: number, consumerExpertReviewRating: { __typename: 'DepartmentConsumerExpertReviewRating', totalReviews: any, averageRating: number } } | null };

export type SellableExpertiseRequestForRecommendedWhyThesePicksFragment = { __typename: 'SellableExpertiseRequest', id: any, category: { __typename: 'Category', id: any, shortSingularDisplayName: string, singularDisplayName: string, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, inventory?: { __typename: 'CategoryAssetNoun', singular: string } | null } | null, expertCategories: Array<{ __typename: 'Category', id: any, singularDisplayName: string }>, experts: Array<{ __typename: 'BusinessUser', id: any, displayName: string, expertProfile?: { __typename: 'ExpertProfile', id: any, headline: string, profilePhoto?: { __typename: 'MediaFile', id: any, url: string } | null } | null, expertStats?: { __typename: 'ExpertStats', averageRating?: number | null } | null, expertAttributes: { __typename: 'BusinessUserExpertAttributes', category: { __typename: 'Category', id: any, slug: string, singularDisplayName: string, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, expert?: { __typename: 'CategoryAssetNoun', singular: string } | null } | null }, specialtyCategory?: { __typename: 'Category', id: any, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, expert?: { __typename: 'CategoryAssetNoun', singular: string } | null } | null } | null }, articleAuthorProfile?: { __typename: 'ExpertProfile', id: any, headline: string } | null }>, department?: { __typename: 'Department', id: any, activeExpertCount: number, consumerExpertReviewRating: { __typename: 'DepartmentConsumerExpertReviewRating', totalReviews: any, averageRating: number } } | null } };

export const SellableExpertiseRuleMappingForWhyThesePicksFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableExpertiseRuleMappingForWhyThesePicks"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SellableExpertiseRuleMapping"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"explanation"}},{"kind":"Field","name":{"kind":"Name","value":"sellableAttributeSchema"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"enumEntries"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"explanation"}}]}},{"kind":"Field","name":{"kind":"Name","value":"description"}}]}},{"kind":"Field","name":{"kind":"Name","value":"sellableAttributeValues"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"explanation"}},{"kind":"Field","name":{"kind":"Name","value":"displayValue"}},{"kind":"Field","name":{"kind":"Name","value":"quality"}}]}}]}}]} as unknown as DocumentNode;
export const SellableExpertiseRecommendationConnectionForWhyThesePicksFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableExpertiseRecommendationConnectionForWhyThesePicks"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SellableExpertiseRecommendationConnection"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mappings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableExpertiseRuleMappingForWhyThesePicks"}}]}}]}}]} as unknown as DocumentNode;
export const CategoryForRecommendedWhyThesePicksFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CategoryForRecommendedWhyThesePicks"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Category"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"singularDisplayName"}},{"kind":"Field","name":{"kind":"Name","value":"categoryAssetRevision"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"inventory"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"singular"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"expertCategories"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"singularDisplayName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"experts"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"limit"},"value":{"kind":"IntValue","value":"3"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForExpertForAvatar"}}]}},{"kind":"Field","name":{"kind":"Name","value":"department"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"activeExpertCount"}},{"kind":"Field","name":{"kind":"Name","value":"consumerExpertReviewRating"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"totalReviews"}},{"kind":"Field","name":{"kind":"Name","value":"averageRating"}}]}}]}}]}}]} as unknown as DocumentNode;
export const SellableExpertiseRequestForRecommendedWhyThesePicksFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableExpertiseRequestForRecommendedWhyThesePicks"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SellableExpertiseRequest"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"category"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CategoryForRecommendedWhyThesePicks"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableExpertiseRequestForChatWithExpertButton"}}]}}]} as unknown as DocumentNode;