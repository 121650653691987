import React from 'react'
import classnames from 'classnames'
import { AuthenticationFlowViewLayoutProps } from '../../..'
import styles from './styles.css'

const AuthenticationFlowViewLayout: React.FC<
  React.PropsWithChildren<AuthenticationFlowViewLayoutProps>
> = ({ children, footer, headline, subHeadline, bottomSection, standalone }) => {
  return (
    <>
      <div
        className={classnames(styles.view, {
          [styles.standalone]: standalone,
          [styles.childless]: !children
        })}
      >
        <div className={styles.content}>
          <div className={styles.top}>
            {headline && <h2 className={styles.headline}>{headline}</h2>}
            {subHeadline && <h3 className={styles.subHeadline}>{subHeadline}</h3>}
            {children}
          </div>

          {bottomSection && <div className={styles.bottom}>{bottomSection}</div>}
        </div>
      </div>
      {footer}
    </>
  )
}

export default AuthenticationFlowViewLayout
