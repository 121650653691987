import React, { useContext, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js/pure'
import { Stripe } from '@stripe/stripe-js'
import config from '#src/app/config'

type StripeContext = { promise: Promise<Stripe | null> } | null

const StripeContext = React.createContext<StripeContext>(null)

const { Consumer, Provider } = StripeContext

const StripeContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const STRIPE_API_KEY = config.get('stripe.publishable_key')
  const STRIPE_API_VERSION = config.get('stripe.api_version')
  const [stripePromise] = useState(() =>
    loadStripe(STRIPE_API_KEY, { apiVersion: STRIPE_API_VERSION })
  )

  return <Provider value={{ promise: stripePromise }}>{children}</Provider>
}

const useStripeContext = () => {
  const stripeContext = useContext(StripeContext)

  if (!stripeContext) {
    throw new Error('Invoked StripeContext outside of provider')
  }

  return stripeContext
}

export { StripeContextProvider, StripeContext, Consumer as StripeContextConsumer, useStripeContext }
