import React from 'react'
import { Link } from '@deal/router'
import { PromotionForEngagementChannelsFragment } from '../../EngagementChannels.generated'
import styles from './styles.css'

const PromotionalPageBannerForEngagementModal: React.FC<{
  promotion: PromotionForEngagementChannelsFragment
}> = ({ promotion }) => {
  return (
    <div className={styles.banner}>
      <div className={styles.content}>
        {promotion.marketingMessage || promotion.actionsDisplayValue}
      </div>
      {/* eslint-disable-next-line react/jsx-no-target-blank -- noreferrer is unnecessary and undesired for internal links */}
      <Link target="_blank" to={`/legal/promotion/${promotion.code}`} className={styles.terms}>
        Details
      </Link>
    </div>
  )
}

export default PromotionalPageBannerForEngagementModal
