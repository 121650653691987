import { ConsumerTracking, Event, ViewerContext } from '@deal/web-tracking'

export interface ConsumerVisitedFromReferralLinkEventProperties {
  entry_point: ConsumerTracking.EntryPoint
  medium: ConsumerTracking.ReferralMedium
}

export class ConsumerVisitedFromReferralLinkEvent extends Event<
  ConsumerVisitedFromReferralLinkEventProperties
> {
  static displayName = 'Consumer Visited From Referral Link'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
