import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Modal, Text, useModalTrigger } from '@deal/bluxome'
import { getFullName } from '#src/app/services/name'
import useIsMounted from '#src/app/hooks/useIsMounted'
import { useIdentityContext } from '#src/app/containers/Identity'
import { useReleaseConsumerIdentityMutation } from '../ReleaseConsumerIdentityLink/ReleaseConsumerIdentity.generated'

export const ImpersonationWarningModal: React.FC = () => {
  const isMounted = useIsMounted()
  const [isDismissed, setIsDismissed] = useState(false)
  const [isReleasingIdentity, setIsReleasingIdentity] = useState(false)

  const { myself } = useIdentityContext()
  const isImpersonating = myself?.realUser.id !== myself?.user.id

  const { state, overlayProps } = useModalTrigger({
    onOpenChange: isOpen => {
      if (!isOpen) {
        setIsDismissed(true)
      }
    },
    isOpen: isImpersonating && isMounted && !isDismissed
  })

  const [releaseConsumerIdentity] = useReleaseConsumerIdentityMutation({
    onCompleted: () => {
      /**
       * Reload the window entirely, this will suspend and re-instantiate all
       * the active subscriptions (cart, chat) that we have. We'll get a refetch
       * identity for free with the page reload.
       */
      window.location.reload()
    },
    onError: ({ message }) => {
      toast.error(message)
      setIsReleasingIdentity(false)
    }
  })

  return (
    <Modal
      state={state}
      overlayProps={overlayProps}
      title="Heads up!"
      actions={{
        color: 'neutral-dark',
        primaryAction: {
          label: 'Stop Impersonation',
          isLoading: isReleasingIdentity,
          'aria-label': 'Stop Impersonation',
          onPress: () => {
            releaseConsumerIdentity()
            setIsReleasingIdentity(true)
          }
        },
        secondaryAction: {
          label: 'Proceed',
          isDisabled: isReleasingIdentity,
          onPress: state.close
        }
      }}
      width="small"
    >
      <Text style="base-loose">
        You're impersonating <strong>{myself ? getFullName(myself.user) : 'Consumer'}</strong>.
        Tracking events and read receipts have been disabled, but all other functionality is live.
        Actions you take on the site might cause {myself?.user.firstName || 'the consumer'} to
        recieve messages from their expert or Curated, including texts and emails.
      </Text>
    </Modal>
  )
}
