import React, { useContext, useState } from 'react'
import { BusinessUserForChatFragment } from '#src/app/fragments/BusinessUserForChat.generated'

/**
 * Tracks the active expert/conversation to render in the chat UIs
 */
const CurrentExpertContext = React.createContext<{
  expert?: BusinessUserForChatFragment
  loading: boolean
  setExpert: (newExpert?: BusinessUserForChatFragment) => void
}>({
  expert: undefined,
  loading: true,
  setExpert: () => {}
})

const CurrentExpertConsumer = CurrentExpertContext.Consumer
const CurrentExpertProvider: React.FC<React.PropsWithChildren<{ value?: BusinessUserForChatFragment }>> = ({
  value,
  children
}) => {
  const [expert, setExpert] = useState(value)
  const [loading, setLoading] = useState(true)

  // The chat UIs start in a loading state during the initial render. Once
  //   an expert has been set, that loading state is never used again.
  const setExpertAndUnsetLoading = (newExpert?: BusinessUserForChatFragment) => {
    setExpert(newExpert)
    setLoading(false)
  }

  return (
    <CurrentExpertContext.Provider value={{ expert, loading, setExpert: setExpertAndUnsetLoading }}>
      {children}
    </CurrentExpertContext.Provider>
  )
}

const useCurrentExpert = () => {
  return useContext(CurrentExpertContext)
}

export { CurrentExpertContext, CurrentExpertConsumer, CurrentExpertProvider, useCurrentExpert }
