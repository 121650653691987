import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import { AttributeType } from '#src/generated/types'
import { useChatWidgetPathContext } from '#src/app/context/chatWidgetPath'
import { useScoutTransferToRealExpertMutation } from '../mutations/ScoutTransferToRealExpert.generated'
import { TrackingCodes } from '../services/path/hooks/useCreateRequestPath'
import { convertPromoIdToAttrInput, formatPathUrl, useCreateRequestPath } from '../services/path'
import { usePathCtaContext } from '../context/path-cta'

interface ScoutTransferToRealExpertArgs {
  leadId: string
}

/** To be used when scout conversation is already created */
export const useScoutTransferToRealExpert = () => {
  const [transferToExpert, { loading }] = useScoutTransferToRealExpertMutation()

  const switchToRealExpert = ({ leadId }: ScoutTransferToRealExpertArgs) => {
    transferToExpert({
      variables: {
        input: {
          leadId
        }
      }
    }).then(({ data }) => {
      if (!data) {
        toast.error('Error transferring to real expert')
        return
      }
      if (data.transferLeadToRealExpert.error) {
        toast.error(data.transferLeadToRealExpert.error)
        return
      }
    })
  }

  return { switchToRealExpert, loading }
}

/** use to request path when you dont have scout conversation yet */
export const useSwitchToRealExpert = (placement: 'widget' | 'default') => {
  const history = useHistory()
  const pathCtaContext = usePathCtaContext()
  const { setPathUrl } = useChatWidgetPathContext()
  const [createPath, { loading }] = useCreateRequestPath({
    onPathCreated: ({ pathGraphFlow, pathGraphFlowSlug, nodeSlugOrId }) => {
      if (pathGraphFlow) {
        const pathURL = formatPathUrl({
          pathGraphFlowId: pathGraphFlow.pathGraphFlowId,
          nodeSlugOrId,
          pathGraphFlowSlug,
          pathPlacement: placement
        })
        switch (placement) {
          case 'default':
            history.push(pathURL)
            break

          case 'widget':
            setPathUrl(pathURL)
            break
        }
      } else {
        throw new Error('Error creating path for switch to real expert!')
      }
    },
    onError: ({ errorMessage }) => {
      toast.error(errorMessage)
      throw new Error(errorMessage)
    }
  })

  const switchToRealExpert = (props: SwitchToRealExpertArgs) => {
    const promotionAttributes = props.promotionId
      ? convertPromoIdToAttrInput(props.promotionId)
      : []
    createPath({
      pathSelector: pathCtaContext?.pathSchemaId
        ? { pathSchemaId: pathCtaContext?.pathSchemaId }
        : { categorySlugHierarchyLookup: props.categorySlug },
      trackingCodes: { pageVariation: pathCtaContext?.pageVariation, ...props.trackingCodes },
      initialAttributes: props.leadId
        ? promotionAttributes.concat([
            {
              name: 'leadIdForExpertReassignment',
              type: AttributeType.ID,
              idValue: props.leadId
            }
          ])
        : promotionAttributes
    })
  }
  return { switchToRealExpert, loading }
}

export interface SwitchToRealExpertArgs {
  categorySlug: string
  trackingCodes: TrackingCodes
  leadId?: string
  promotionId?: string
}
