import cookies from 'js-cookie'
import { CookieContextType, DEFAULT_COOKIE_OPTIONS } from './index'

export default function (_window: Window): CookieContextType {
  return {
    getCookie: (cookieName: string) => {
      return cookies.get(cookieName)
    },

    setCookie: (cookieName, cookieValue, cookieOptions) => {
      cookies.set(cookieName, cookieValue, Object.assign({}, DEFAULT_COOKIE_OPTIONS, cookieOptions))

      return true
    },

    removeCookie: (cookieName, cookieOptions) => {
      cookies.remove(cookieName, Object.assign({}, DEFAULT_COOKIE_OPTIONS, cookieOptions))
    }
  }
}
