import React from 'react'
import { createLocation } from 'history'
import { TrackingQueryParameter } from '@deal/web-tracking/constants'
import {
  Redirect as ReactRouterRedirect,
  RedirectProps as ReactRouterRedirectProps,
  Route,
  useLocation
} from '@deal/router'

/**
 * A wrapper for the `Redirect` component from react-router, with two changes:
 *
 *  - Adds support for setting a status code with the redirect
 *  - Preserves all tracking parameters by default ("utm_*", "vt_*", "c_*", etc.)
 *
 * Note: Neither of these changes apply to client-side usage. This component is
 *   identical to react-router's Redirect component when used in the browser.
 */
interface RedirectProps extends ReactRouterRedirectProps {
  statusCode?: number
}

const Redirect: React.FC<React.PropsWithChildren<RedirectProps>> = ({ statusCode, to, ...rest }) => {
  const location = useLocation()

  // Convert the 'to' into a Location if it is provided as a string literal
  const toLocation = typeof to === 'string' ? createLocation(to) : to
  const toLocationSearchParams = new URLSearchParams(toLocation.search)
  const fromLocationSearchParams = new URLSearchParams(location.search)

  // Copy the existing ("from") acquisition query params over to the destination ("to") Location.
  //   We do this so that these parameters are not lost in a server-side redirect before we have
  //   had a chance to parse & track them on the client-side.
  fromLocationSearchParams.forEach((queryParameterValue, queryParameterName) => {
    if (
      !toLocationSearchParams.has(queryParameterName) &&
      queryParameterName in TrackingQueryParameter
    ) {
      toLocationSearchParams.set(queryParameterName, queryParameterValue)
    }
  })

  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
          staticContext.statusCode = statusCode
        }

        return (
          <ReactRouterRedirect
            to={{
              ...toLocation,
              search: toLocationSearchParams.toString()
            }}
            {...rest}
          />
        )
      }}
    />
  )
}

export default Redirect
