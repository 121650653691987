import React from 'react'
import numeral from 'numeral'
import { ExpertProfileHighlightIcon } from '@deal/components'
import { ScoutIcon, Text } from '@deal/bluxome'
import { BusinessUserForChatExpertProfilePreviewFragment } from './ConversationStarterExpert.generated'
import ExpertProfileLink from '../ExpertProfileLink'
import ExpertAvatarLegacy from '../ExpertAvatarLegacy'
import styles from './styles.css'

interface ChatExpertProfilePreviewProps {
  expert: BusinessUserForChatExpertProfilePreviewFragment
  isAI?: boolean
}

export const ChatExpertProfilePreview: React.FC<ChatExpertProfilePreviewProps> = ({
  expert,
  isAI
}) => {
  const expertName = expert.displayName
  const profile = expert.expertProfile
  const highlights =
    profile && profile.highlights && profile.highlights.length > 0 ? profile.highlights : undefined
  const expertStats = expert.expertStats ? expert.expertStats : null
  const isPlaceholderExpert = expert.expertAttributes && expert.expertAttributes.placeholderExpert

  return (
    <div className={styles.profile}>
      {isAI ? (
        <div>
          <div>
            <ScoutIcon style="wordmark" />
          </div>
          <Text style="large">
            scout is trained using collective knowledge of real Curated experts. Feel free to ask
            your questions
          </Text>
        </div>
      ) : (
        <>
          <div className={styles.identity}>
            <div className={styles.avatar}>
              {isPlaceholderExpert ? (
                <ExpertAvatarLegacy expert={expert} size={64} />
              ) : (
                <ExpertProfileLink expert={expert}>
                  <ExpertAvatarLegacy expert={expert} size={64} />
                </ExpertProfileLink>
              )}
            </div>
            <div className={styles.intro}>
              {isPlaceholderExpert ? (
                <span className={styles.name}>{expertName}</span>
              ) : (
                <ExpertProfileLink expert={expert} className={styles.name}>
                  {expertName}
                </ExpertProfileLink>
              )}
              {profile && profile.headline && (
                <div className={styles.headline}>{profile.headline}</div>
              )}
            </div>
          </div>
          <div className={styles.sections}>
            {highlights && (
              <ul className={styles.highlights}>
                {expertStats && (
                  <>
                    {expertStats.totalMessagesSent > 0 && (
                      <li className={styles.highlight}>
                        <ExpertProfileHighlightIcon glyph="CHAT" className={styles.highlightIcon} />
                        <span className={styles.highlightValue}>Total messages</span>
                        <span className={styles.highlightSecondaryValue}>
                          {numeral(expertStats.totalMessagesSent).format('0,0')}
                        </span>
                      </li>
                    )}
                    {expertStats.totalReviews > 0 && (
                      <li className={styles.highlight}>
                        <ExpertProfileHighlightIcon glyph="STAR" className={styles.highlightIcon} />
                        <span className={styles.highlightValue}>Total reviews</span>
                        <span className={styles.highlightSecondaryValue}>
                          {numeral(expertStats.totalReviews).format('0,0')}
                        </span>
                      </li>
                    )}
                  </>
                )}

                {highlights.map(highlight => (
                  <li
                    className={styles.highlight}
                    key={`${highlight.type.id}:${highlight.value}:${highlight.secondaryValue}`}
                  >
                    <ExpertProfileHighlightIcon
                      glyph={highlight.type.icon}
                      className={styles.highlightIcon}
                    />
                    <span className={styles.highlightValue}>{highlight.secondaryValue}</span>
                    <span className={styles.highlightSecondaryValue}>{highlight.value}</span>
                  </li>
                ))}
              </ul>
            )}

            {!isPlaceholderExpert && (
              <div className={styles.viewLink}>
                <ExpertProfileLink expert={expert} className={styles.expertProfileLink}>
                  View full profile
                </ExpertProfileLink>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}
