import React, { useState } from 'react'
import { useLocation } from 'react-router'
import ReactMarkdown from 'react-markdown'
import classnames from 'classnames'
import { UserRegisteredEvent } from '@deal/web-tracking'
import { Link } from '@deal/router'
import { ButtonVariant } from '@deal/components/es/components/Button'
import {
  Box,
  BubbleLoader,
  Button,
  FancyContent,
  FancyShowcase,
  Image,
  Modal,
  TextField
} from '@deal/components'
import { loginLocation } from '#src/app/services/routing'
import { ComponentValue, getTextStyles } from '#src/app/services/pageTemplate'
import { useBreakpoint } from '#src/app/hooks/useBreakpoint'
import clientOnly from '#src/app/hocs/clientOnly'
import { useIdentityContext } from '#src/app/containers/Identity'
import { useAnalyticsContext } from '#src/app/containers/Analytics'
import { useRegisterCustomerMutation } from '#src/app/components/OAuthRegister/RegisterCustomer.generated'
import { PromotionForEngagementChannelsFragment } from '../../EngagementChannels.generated'
import EngagementChannelPromotionBanner from '../../components/EngagementChannelPromotionBanner'
import styles from './styles.css'

export interface EmailModalTemplateProps {
  promotion?: PromotionForEngagementChannelsFragment | null
  isOpen: boolean
  pageTemplateRevisionId?: string
  componentValue: { [key: string]: ComponentValue }
  onSubmit?: () => void
  onDismiss?: () => void
}

enum ParamName {
  Title = 'Title',
  Description = 'Description',
  CTAButton = 'CTA Button',
  BackgroundDesktopImage = 'Background-Desktop',
  BackgroundMobileImage = 'Background-Mobile',
  FinePrint = 'Fine Print'
}

const EmailModalTemplate: React.FC<EmailModalTemplateProps> = ({
  promotion,
  isOpen,
  componentValue,
  pageTemplateRevisionId,
  onSubmit,
  onDismiss
}) => {
  const isMobile = !useBreakpoint('sm')
  const [registerCustomerError, setRegisterCustomerError] = useState<string>()
  const [registeredContactId, setRegisteredContactId] = useState<string | undefined>(undefined)
  const [email, setEmail] = useState('')
  const { refetch: refetchIdentity } = useIdentityContext()
  const analytics = useAnalyticsContext()
  const location = useLocation()

  const desktopImageUrl = componentValue[ParamName.BackgroundDesktopImage]?.imageUrl
  const mobileImageUrl = componentValue[ParamName.BackgroundMobileImage]?.imageUrl
  const desktopImageAltText = componentValue[ParamName.BackgroundDesktopImage]?.altText
  const mobileImageAltText = componentValue[ParamName.BackgroundMobileImage]?.altText

  const [registerCustomer, { loading: registerCustomerLoading }] = useRegisterCustomerMutation({
    variables: {
      input: {
        email: email,
        pageTemplateRevisionId
      }
    },
    onCompleted: data => {
      setRegisteredContactId(
        data.registerCustomer.error?.__typename === 'AlreadyRegisteredRegisterCustomerError' &&
          data.registerCustomer.error.contact.id
      )

      if (data.registerCustomer.errors?.length) {
        setRegisterCustomerError(data.registerCustomer.errors.join(' '))
      } else {
        if (refetchIdentity) {
          refetchIdentity().then(result => {
            const refetchedMyself = result.data.me
            if (refetchedMyself) {
              analytics?.track(
                new UserRegisteredEvent({
                  user_id: refetchedMyself.id,
                  context: 'guest-email-modal'
                })
              )
            }
          })
        }
      }
    }
  })

  /**
   * If the user is already registered, redirect them to the login page with
   * a custom header shown
   */
  const redirectToLoginUrl = () => {
    const redirectSearchParams = new URLSearchParams(location.search)
    redirectSearchParams.append('signinLoginReturn', 'true')

    const postLoginLocation = loginLocation(
      {
        ...location,
        search: redirectSearchParams.toString()
      },
      { header: 'ALREADY_REGISTERED', handleId: registeredContactId }
    )

    return postLoginLocation
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onDismiss}
      mobilePosition="bottom"
      width="medium"
      contentLabel={componentValue[ParamName.Title]?.text || 'Shop with an expert'}
      contentSpacing={false}
    >
      <div
        className={classnames(styles.modalContent, {
          [styles.pathHeight]: false
        })}
      >
        <>
          <FancyShowcase className={styles.imageContainer}>
            {isMobile
              ? mobileImageUrl && (
                  <Image
                    src={mobileImageUrl}
                    htmlAttributes={{ alt: mobileImageAltText ?? undefined }}
                    size="100vw"
                    imgixParams={{ fit: 'crop' }}
                    className={styles.image}
                  />
                )
              : desktopImageUrl && (
                  <Image
                    src={desktopImageUrl}
                    htmlAttributes={{ alt: desktopImageAltText ?? undefined }}
                    width={400}
                    height={350}
                    imgixParams={{ fit: 'crop' }}
                    className={styles.image}
                  />
                )}
          </FancyShowcase>
          <FancyContent className={styles.contentContainer}>
            {!!promotion && <EngagementChannelPromotionBanner promotion={promotion} />}
            <div className={styles.title} style={getTextStyles(componentValue[ParamName.Title])}>
              {componentValue[ParamName.Title]?.text}
            </div>
            <div
              className={styles.subtitle}
              style={getTextStyles(componentValue[ParamName.Description])}
            >
              {componentValue[ParamName.Description]?.text}
            </div>
            <Box spacing="extra-tight" column={isMobile}>
              <Box.Item grow={1}>
                <TextField
                  label=""
                  error={!!registerCustomerError}
                  errorText={!registeredContactId ? registerCustomerError : null}
                  placeholder="Enter your email"
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                />
              </Box.Item>
              <Box.Item grow={isMobile ? 1 : undefined}>
                <Button
                  disabled={registerCustomerLoading}
                  className={styles.submitButton}
                  size="medium"
                  onClick={() => {
                    if (email) {
                      onSubmit?.()
                      registerCustomer()
                    } else {
                      setRegisterCustomerError('Please enter a valid email address.')
                    }
                  }}
                  variant={componentValue[ParamName.CTAButton]?.buttonType as ButtonVariant}
                >
                  <div className={classnames({ [styles.loading]: registerCustomerLoading })}>
                    {componentValue[ParamName.CTAButton]?.text || 'Sign up'}
                  </div>
                  {registerCustomerLoading && (
                    <BubbleLoader className={styles['medium']} color="#767676" />
                  )}
                </Button>
              </Box.Item>
            </Box>
            {registeredContactId && (
              <div className={styles.alreadyRegistered}>
                Looks like you already have an account,{' '}
                <Link className={styles.link} to={redirectToLoginUrl()}>
                  click here to log in
                </Link>
              </div>
            )}
            {componentValue[ParamName.FinePrint]?.markdownText && (
              <ReactMarkdown
                source={componentValue[ParamName.FinePrint].markdownText}
                className={styles.finePrint}
              />
            )}
          </FancyContent>
        </>
      </div>
    </Modal>
  )
}

export default clientOnly(EmailModalTemplate)
