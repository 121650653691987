import React from 'react'
import classnames from 'classnames'
import RemoveIcon from './remove.svg'
import PinIcon from './pin.svg'
import EditIcon from './edit.svg'
import styles from './styles.css'

interface ColumnActionsProps {
  isPinned: boolean
}

export const ColumnActions: React.FC<ColumnActionsProps> = ({ isPinned }) => {
  return (
    <div className={styles.columnActionsContainer}>
      <button
        disabled
        className={styles.button}
        aria-label="Change item variant"
        title="Change item variant"
      >
        <EditIcon aria-hidden={true} />
      </button>
      <button
        disabled
        className={classnames(styles.button, { [styles.pinned]: isPinned })}
        aria-label="Pin item to first column"
        title="Pin item to first column"
      >
        <PinIcon aria-hidden={true} />
      </button>
      <button
        disabled
        className={styles.button}
        aria-label="Remove item from compare table"
        title="Remove"
      >
        <RemoveIcon aria-hidden={true} />
      </button>
    </div>
  )
}
