// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type PreparePrefillSmsConversationStarterPathForSmsModalMutationVariables = Types.Exact<{
  input: Types.PreparePrefillSmsConversationStarterPathInput;
}>;


export type PreparePrefillSmsConversationStarterPathForSmsModalMutation = { __typename: 'Mutation', preparePrefillSmsConversationStarterPath: { __typename: 'PreparePrefillSmsConversationStarterPathPayload', shortyHandle: string, smsNumber: string, userRegistrationId: any } };


export const PreparePrefillSmsConversationStarterPathForSmsModalDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"PreparePrefillSmsConversationStarterPathForSmsModal"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PreparePrefillSmsConversationStarterPathInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"preparePrefillSmsConversationStarterPath"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"shortyHandle"}},{"kind":"Field","name":{"kind":"Name","value":"smsNumber"}},{"kind":"Field","name":{"kind":"Name","value":"userRegistrationId"}}]}}]}}]} as unknown as DocumentNode;
export type PreparePrefillSmsConversationStarterPathForSmsModalMutationFn = Apollo.MutationFunction<PreparePrefillSmsConversationStarterPathForSmsModalMutation, PreparePrefillSmsConversationStarterPathForSmsModalMutationVariables>;
export type PreparePrefillSmsConversationStarterPathForSmsModalComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PreparePrefillSmsConversationStarterPathForSmsModalMutation, PreparePrefillSmsConversationStarterPathForSmsModalMutationVariables>, 'mutation'>;

    export const PreparePrefillSmsConversationStarterPathForSmsModalComponent = (props: PreparePrefillSmsConversationStarterPathForSmsModalComponentProps) => (
      <ApolloReactComponents.Mutation<PreparePrefillSmsConversationStarterPathForSmsModalMutation, PreparePrefillSmsConversationStarterPathForSmsModalMutationVariables> mutation={PreparePrefillSmsConversationStarterPathForSmsModalDocument} {...props} />
    );
    

/**
 * __usePreparePrefillSmsConversationStarterPathForSmsModalMutation__
 *
 * To run a mutation, you first call `usePreparePrefillSmsConversationStarterPathForSmsModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreparePrefillSmsConversationStarterPathForSmsModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [preparePrefillSmsConversationStarterPathForSmsModalMutation, { data, loading, error }] = usePreparePrefillSmsConversationStarterPathForSmsModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePreparePrefillSmsConversationStarterPathForSmsModalMutation(baseOptions?: Apollo.MutationHookOptions<PreparePrefillSmsConversationStarterPathForSmsModalMutation, PreparePrefillSmsConversationStarterPathForSmsModalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PreparePrefillSmsConversationStarterPathForSmsModalMutation, PreparePrefillSmsConversationStarterPathForSmsModalMutationVariables>(PreparePrefillSmsConversationStarterPathForSmsModalDocument, options);
      }
export type PreparePrefillSmsConversationStarterPathForSmsModalMutationHookResult = ReturnType<typeof usePreparePrefillSmsConversationStarterPathForSmsModalMutation>;
export type PreparePrefillSmsConversationStarterPathForSmsModalMutationResult = Apollo.MutationResult<PreparePrefillSmsConversationStarterPathForSmsModalMutation>;
export type PreparePrefillSmsConversationStarterPathForSmsModalMutationOptions = Apollo.BaseMutationOptions<PreparePrefillSmsConversationStarterPathForSmsModalMutation, PreparePrefillSmsConversationStarterPathForSmsModalMutationVariables>;