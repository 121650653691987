// @ts-nocheck
import type * as Types from '../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { MonetaryAmountFragmentDoc } from '../../../../../fragments/MonetaryAmount.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SellableForShippingEstimateFragment = { __typename: 'Sellable', id: any, fastShippingAvailable: boolean };

export type ShippingMethodCostForShippingEstimateFragment = { __typename: 'ShippingMethodCost', badges: Array<Types.ShippingMethodBadge>, shippingOptionName: string, destinationZipCode?: string | null, cost: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string }, deliveryTimeRange: { __typename: 'DateRange', from?: any | null, to?: any | null } };

export type SessionForShippingEstimateFragment = { __typename: 'Session', id: any, shippingAddress?: { __typename: 'PostalAddress', postalCode: string } | null };

export type RefreshShippingMethodCostForShippingEstimateQueryVariables = Types.Exact<{
  friendlyIdOrId: Types.Scalars['String']['input'];
}>;


export type RefreshShippingMethodCostForShippingEstimateQuery = { __typename: 'Query', findSellable?: { __typename: 'Sellable', id: any, shippingMethods: Array<{ __typename: 'ShippingMethodCost', badges: Array<Types.ShippingMethodBadge>, shippingOptionName: string, destinationZipCode?: string | null, cost: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string }, deliveryTimeRange: { __typename: 'DateRange', from?: any | null, to?: any | null } }> } | null, session: { __typename: 'Session', id: any, shippingAddress?: { __typename: 'PostalAddress', postalCode: string } | null } };

export const SellableForShippingEstimateFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableForShippingEstimate"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Sellable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"fastShippingAvailable"}}]}}]} as unknown as DocumentNode;
export const ShippingMethodCostForShippingEstimateFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ShippingMethodCostForShippingEstimate"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ShippingMethodCost"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cost"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MonetaryAmount"}}]}},{"kind":"Field","name":{"kind":"Name","value":"deliveryTimeRange"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"from"}},{"kind":"Field","name":{"kind":"Name","value":"to"}}]}},{"kind":"Field","name":{"kind":"Name","value":"badges"}},{"kind":"Field","name":{"kind":"Name","value":"shippingOptionName"}},{"kind":"Field","name":{"kind":"Name","value":"destinationZipCode"}}]}}]} as unknown as DocumentNode;
export const SessionForShippingEstimateFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SessionForShippingEstimate"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Session"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"shippingAddress"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"postalCode"}}]}}]}}]} as unknown as DocumentNode;
export const RefreshShippingMethodCostForShippingEstimateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"RefreshShippingMethodCostForShippingEstimate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"friendlyIdOrId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"findSellable"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"friendlyIdOrId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"friendlyIdOrId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"shippingMethods"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ShippingMethodCostForShippingEstimate"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"session"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SessionForShippingEstimate"}}]}}]}},...ShippingMethodCostForShippingEstimateFragmentDoc.definitions,...MonetaryAmountFragmentDoc.definitions,...SessionForShippingEstimateFragmentDoc.definitions]} as unknown as DocumentNode;
export type RefreshShippingMethodCostForShippingEstimateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RefreshShippingMethodCostForShippingEstimateQuery, RefreshShippingMethodCostForShippingEstimateQueryVariables>, 'query'> & ({ variables: RefreshShippingMethodCostForShippingEstimateQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RefreshShippingMethodCostForShippingEstimateComponent = (props: RefreshShippingMethodCostForShippingEstimateComponentProps) => (
      <ApolloReactComponents.Query<RefreshShippingMethodCostForShippingEstimateQuery, RefreshShippingMethodCostForShippingEstimateQueryVariables> query={RefreshShippingMethodCostForShippingEstimateDocument} {...props} />
    );
    

/**
 * __useRefreshShippingMethodCostForShippingEstimateQuery__
 *
 * To run a query within a React component, call `useRefreshShippingMethodCostForShippingEstimateQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefreshShippingMethodCostForShippingEstimateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefreshShippingMethodCostForShippingEstimateQuery({
 *   variables: {
 *      friendlyIdOrId: // value for 'friendlyIdOrId'
 *   },
 * });
 */
export function useRefreshShippingMethodCostForShippingEstimateQuery(baseOptions: Apollo.QueryHookOptions<RefreshShippingMethodCostForShippingEstimateQuery, RefreshShippingMethodCostForShippingEstimateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RefreshShippingMethodCostForShippingEstimateQuery, RefreshShippingMethodCostForShippingEstimateQueryVariables>(RefreshShippingMethodCostForShippingEstimateDocument, options);
      }
export function useRefreshShippingMethodCostForShippingEstimateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RefreshShippingMethodCostForShippingEstimateQuery, RefreshShippingMethodCostForShippingEstimateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RefreshShippingMethodCostForShippingEstimateQuery, RefreshShippingMethodCostForShippingEstimateQueryVariables>(RefreshShippingMethodCostForShippingEstimateDocument, options);
        }
export function useRefreshShippingMethodCostForShippingEstimateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RefreshShippingMethodCostForShippingEstimateQuery, RefreshShippingMethodCostForShippingEstimateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RefreshShippingMethodCostForShippingEstimateQuery, RefreshShippingMethodCostForShippingEstimateQueryVariables>(RefreshShippingMethodCostForShippingEstimateDocument, options);
        }
export type RefreshShippingMethodCostForShippingEstimateQueryHookResult = ReturnType<typeof useRefreshShippingMethodCostForShippingEstimateQuery>;
export type RefreshShippingMethodCostForShippingEstimateLazyQueryHookResult = ReturnType<typeof useRefreshShippingMethodCostForShippingEstimateLazyQuery>;
export type RefreshShippingMethodCostForShippingEstimateSuspenseQueryHookResult = ReturnType<typeof useRefreshShippingMethodCostForShippingEstimateSuspenseQuery>;
export type RefreshShippingMethodCostForShippingEstimateQueryResult = Apollo.QueryResult<RefreshShippingMethodCostForShippingEstimateQuery, RefreshShippingMethodCostForShippingEstimateQueryVariables>;