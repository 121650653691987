import React from 'react'
import { getMarketableCategorySlugFromCategoryHierarchy } from '#src/app/services/tracking'
import { CategoryForDynamicSearchAdsContentTargetFragment } from './CategoryForDynamicSearchAdsContentTarget.generated'
import styles from './styles.css'

interface DynamicSearchAdsContentTargetProps {
  category: CategoryForDynamicSearchAdsContentTargetFragment
}

/**
 * This component renders some invisible text describing the content of the page,
 *   off screen, for marketing purposes.
 *
 * --------
 * Context:
 * --------
 *
 * The text is used by the marketing team to configure Dynamic Search Ads ("DSA").
 *   DSA automatically generates ads for specific pages on our site based on
 *   inclusion/exclusion rules. Those rules can select pages based on a feed, the
 *   page URL, or the actual content of the page.
 *
 * The marketing team wants to be able to target all pages associated with a given
 *   category that we run marketing campaigns for, but maintaining a feed of URLs
 *   associated with those categories is painful and including category names or
 *   slugs in every URL limits our flexibility. Therefore, we have opted to include
 *   some content on the page itself. Google will discover this content when crawling
 *   our site, but we want to ensure real users do not see it (including screen
 *   readers).
 *
 * See: https://support.google.com/google-ads/answer/7334472 ("Other targeting types")
 */
const DynamicSearchAdsContentTarget: React.FC<DynamicSearchAdsContentTargetProps> = ({
  category
}) => {
  // To give the marketing team the most flexibility, we allow them to target by
  //   the exact category ("ski-poles"), the department ("winter-sports"), the
  //   top level category for which we actively run ads ("skiing"), or whether
  //   the category is apparel-related.
  const categorySlug = category.slug
  const departmentSlug = category.department?.slug
  const marketableCategorySlug = getMarketableCategorySlugFromCategoryHierarchy(category)
  const isApparel = category.apparel

  return (
    <div aria-hidden="true" className={styles.offScreen}>
      <span>{`DSA_CATEGORY:${categorySlug.toUpperCase()}`}</span>
      <span>
        {marketableCategorySlug &&
          `DSA_MARKETABLE_CATEGORY:${marketableCategorySlug.toUpperCase()}`}
      </span>
      <span>{departmentSlug && `DSA_DEPARTMENT:${departmentSlug.toUpperCase()}`}</span>
      <span>{`DSA_IS_APPAREL:${isApparel ? 'TRUE' : 'FALSE'}`}</span>
    </div>
  )
}

export default DynamicSearchAdsContentTarget
