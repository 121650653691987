import React from 'react'
import styles from './styles.css'

const CuratedBenefitsList: React.FC = () => {
  return (
    <ul className={styles.list}>
      {[
        'Send you handpicked gear recommendations',
        'Help you compare options',
        'Find you the best price'
      ].map(bullet => (
        <li className={styles.listItem} key={bullet}>
          {bullet}
        </li>
      ))}
    </ul>
  )
}

export default CuratedBenefitsList
