import React, { useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'
import FacebookLogin, {
  ReactFacebookFailureResponse,
  ReactFacebookLoginInfo
  // @ts-ignore Typings are not included for the "render prop" build
} from 'react-facebook-login/dist/facebook-login-render-props'
import classnames from 'classnames'
import { Text } from '@deal/bluxome'
import { OAuthType } from '#src/generated/types'
import { isBusinessMobileNative, isConsumerMobileNative } from '#src/app/services/mobile'
import { FacebookSigninResponse } from '#src/app/services/dealNativeBridge'
import { WebMessageEventEmitterContext } from '#src/app/containers/ReceiveMessageFromNative'
import config from '#src/app/config'
import { useSharedAuthenticateMutations } from '#src/app/components/OAuthLogin'
import { useRegisterCustomerMutation } from '../../../OAuthRegister/RegisterCustomer.generated'
import { AuthenticatedUserFragment } from '../../../../fragments/AuthenticatedUser.generated'
import { OAuthButtonComponentProps } from '../../util/oauthButtonProps'
import FacebookIconMark from './facebook-iconmark.svg'
import styles from './styles.css'

interface FacebookOAuthButtonProps extends OAuthButtonComponentProps {
  className?: string
  wrapperClassName?: string
  referralCodeId?: string | null
  referralRewardCreditClaimToken?: string | null
  registrationId?: string | null
  onSuccess: (user: AuthenticatedUserFragment) => void
  onFailure: (error: string) => void
  isRebranded?: boolean
}

const FacebookOAuthButton: React.FC<FacebookOAuthButtonProps> = ({
  className,
  wrapperClassName,
  referralCodeId,
  referralRewardCreditClaimToken,
  registrationId,
  onSuccess,
  onFailure,
  isRebranded = false,
  buttonComponent: ButtonComponent
}) => {
  const [registerCustomer] = useRegisterCustomerMutation()
  const {
    isLoading,
    handleAuthenticateByOAuthToken,
    handleUpdateIncompleteUserProfileBeforeAuthenticating,
    isIncompleteUser
  } = useSharedAuthenticateMutations()
  const eventEmitter = useContext(WebMessageEventEmitterContext)

  /**
   * After successfully completing the Facebook OAuth flow, submit the ID token to our API
   *   for authentication.
   */
  const handleSuccess = ({
    accessToken,
    first_name: firstName,
    last_name: lastName,
    email
  }: ReactFacebookLoginInfo) => {
    const options = {
      accessToken: accessToken,
      oauthType: OAuthType.FACEBOOK,
      firstName: firstName,
      lastName: lastName,
      email: email,
      onSuccess,
      onFailure
    }
    if (isBusinessMobileNative()) {
      // For expert native app, do not allow registering users.
      handleAuthenticateByOAuthToken(options)
    } else if (isIncompleteUser()) {
      // If there's a partial identity (i.e., for scout chat),
      // update the user profile before authenticating with accessToken
      handleUpdateIncompleteUserProfileBeforeAuthenticating(options)
    } else {
      registerCustomer({
        variables: {
          input: {
            firstName,
            lastName,
            email,
            externalOAuthType: OAuthType.FACEBOOK,
            externalOAuthToken: accessToken,
            referralCodeId,
            referralRewardCreditClaimToken,
            registrationId
          }
        }
      }).then(response => {
        const errors = response.errors

        // Handle failures at the GraphQL level
        if (errors) {
          onFailure('Facebook authentication failed!')
          return
        }

        const payload = response.data!.registerCustomer

        if (payload.user) {
          onSuccess(payload.user)
        } else {
          onFailure(
            (payload.errors && payload.errors.join('\n')) || 'Facebook authentication failed!'
          )
        }
      })
    }
  }

  /**
   * Handle failures from the Facebook OAuth flow itself.
   */
  const handleFailure = (response: ReactFacebookFailureResponse) => {
    switch (response.status) {
      case 'connected': // The person is logged into Facebook, and has logged into your webpage.
      case 'not_authorized': // The person is logged into Facebook, but has not logged into your webpage.
      case 'unknown': // The person is not logged into Facebook, so you don't know if they have logged into your webpage.
      default:
        onFailure('Facebook authentication failed!')
        break
    }
  }

  const scope = 'public_profile,email'
  const fields = 'first_name,last_name,email'

  const handleNativeClick = (e?: React.MouseEvent<HTMLDivElement>) => {
    e?.preventDefault()
    eventEmitter!
      .requestResponse<FacebookSigninResponse>(
        {
          type: 'facebookSigninRequest',
          messageId: uuidv4(),
          permissions: (scope && scope.split(',')) || [],
          fields: fields || ''
        },
        'facebookSigninResponse'
      )
      .then(message => {
        if (message.success) {
          handleSuccess(message.success)
        } else {
          handleFailure({
            status: message.failureCode
          })
        }
      })
  }

  if ((isConsumerMobileNative() || isBusinessMobileNative()) && eventEmitter) {
    if (ButtonComponent) {
      return <ButtonComponent onPress={() => handleNativeClick()} isLoading={isLoading} />
    }

    return (
      <div className={classnames(styles.wrapper, wrapperClassName)}>
        <div className={classnames(styles.button, className)} onClick={handleNativeClick}>
          <FacebookIconMark className={styles.logo} /> Continue with Facebook
        </div>
      </div>
    )
  } else {
    return (
      <FacebookLogin
        appId={config.get('facebook.app_id')}
        scope={scope}
        fields={fields}
        disableMobileRedirect={true}
        callback={handleSuccess}
        onFailure={handleFailure}
        render={(props: {
          onClick: () => void
          isDisabled: boolean
          isProcessing: boolean
          isSdkLoaded: boolean
        }) =>
          ButtonComponent ? (
            <ButtonComponent
              onPress={props.onClick}
              isLoading={isLoading || props.isProcessing}
              isDisabled={props.isDisabled || !props.isSdkLoaded}
            />
          ) : (
            <div className={classnames(styles.wrapper, wrapperClassName)}>
              <div
                className={classnames(styles.button, className, {
                  [styles.rebranded]: isRebranded
                })}
                onClick={props.onClick}
              >
                <FacebookIconMark className={styles.logo} />{' '}
                {isRebranded ? (
                  <Text style="base-medium">Continue with Facebook</Text>
                ) : (
                  'Continue with Facebook'
                )}
              </div>
            </div>
          )
        }
      />
    )
  }
}

export default FacebookOAuthButton
