// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SystemUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SystemUserQuery = { __typename: 'Query', systemUser: { __typename: 'SystemUser', id: any } };


export const SystemUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SystemUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"systemUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export type SystemUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SystemUserQuery, SystemUserQueryVariables>, 'query'>;

    export const SystemUserComponent = (props: SystemUserComponentProps) => (
      <ApolloReactComponents.Query<SystemUserQuery, SystemUserQueryVariables> query={SystemUserDocument} {...props} />
    );
    

/**
 * __useSystemUserQuery__
 *
 * To run a query within a React component, call `useSystemUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useSystemUserQuery(baseOptions?: Apollo.QueryHookOptions<SystemUserQuery, SystemUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SystemUserQuery, SystemUserQueryVariables>(SystemUserDocument, options);
      }
export function useSystemUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SystemUserQuery, SystemUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SystemUserQuery, SystemUserQueryVariables>(SystemUserDocument, options);
        }
export function useSystemUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SystemUserQuery, SystemUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SystemUserQuery, SystemUserQueryVariables>(SystemUserDocument, options);
        }
export type SystemUserQueryHookResult = ReturnType<typeof useSystemUserQuery>;
export type SystemUserLazyQueryHookResult = ReturnType<typeof useSystemUserLazyQuery>;
export type SystemUserSuspenseQueryHookResult = ReturnType<typeof useSystemUserSuspenseQuery>;
export type SystemUserQueryResult = Apollo.QueryResult<SystemUserQuery, SystemUserQueryVariables>;