// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SellableForSaveToCurationButtonFragment = { __typename: 'Sellable', id: any, parent?: { __typename: 'Sellable', id: any } | null, savedSellable?: { __typename: 'SavedSellable', id: any, saved: boolean, savedReason?: Types.SavedSellableReason | null } | null, recentExpertCuratedItem?: { __typename: 'ExpertCuratedItem', id: any, sellable: { __typename: 'Sellable', id: any } } | null };

export type LeadForSaveToCurationButtonFragment = { __typename: 'Lead', id: any };

export type SaveSellableForReviewMutationVariables = Types.Exact<{
  input: Types.SaveSellableForReviewInput;
}>;


export type SaveSellableForReviewMutation = { __typename: 'Mutation', saveSellableForReview: { __typename: 'SaveSellableForReviewPayload', savedSellable?: { __typename: 'SavedSellable', id: any, sellable: { __typename: 'Sellable', id: any, savedSellable?: { __typename: 'SavedSellable', id: any, saved: boolean, savedReason?: Types.SavedSellableReason | null } | null } } | null, error?: { __typename: 'DefaultErrorMessage', errorMessage: string } | null } };

export const SellableForSaveToCurationButtonFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableForSaveToCurationButton"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Sellable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"parent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"savedSellable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"saved"}},{"kind":"Field","name":{"kind":"Name","value":"savedReason"}}]}},{"kind":"Field","name":{"kind":"Name","value":"recentExpertCuratedItem"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"sellable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode;
export const LeadForSaveToCurationButtonFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LeadForSaveToCurationButton"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Lead"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]} as unknown as DocumentNode;
export const SaveSellableForReviewDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SaveSellableForReview"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SaveSellableForReviewInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"saveSellableForReview"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"savedSellable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"sellable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"savedSellable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"saved"}},{"kind":"Field","name":{"kind":"Name","value":"savedReason"}}]}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"error"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ErrorMessage"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"errorMessage"}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type SaveSellableForReviewMutationFn = Apollo.MutationFunction<SaveSellableForReviewMutation, SaveSellableForReviewMutationVariables>;
export type SaveSellableForReviewComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SaveSellableForReviewMutation, SaveSellableForReviewMutationVariables>, 'mutation'>;

    export const SaveSellableForReviewComponent = (props: SaveSellableForReviewComponentProps) => (
      <ApolloReactComponents.Mutation<SaveSellableForReviewMutation, SaveSellableForReviewMutationVariables> mutation={SaveSellableForReviewDocument} {...props} />
    );
    

/**
 * __useSaveSellableForReviewMutation__
 *
 * To run a mutation, you first call `useSaveSellableForReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSellableForReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSellableForReviewMutation, { data, loading, error }] = useSaveSellableForReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveSellableForReviewMutation(baseOptions?: Apollo.MutationHookOptions<SaveSellableForReviewMutation, SaveSellableForReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveSellableForReviewMutation, SaveSellableForReviewMutationVariables>(SaveSellableForReviewDocument, options);
      }
export type SaveSellableForReviewMutationHookResult = ReturnType<typeof useSaveSellableForReviewMutation>;
export type SaveSellableForReviewMutationResult = Apollo.MutationResult<SaveSellableForReviewMutation>;
export type SaveSellableForReviewMutationOptions = Apollo.BaseMutationOptions<SaveSellableForReviewMutation, SaveSellableForReviewMutationVariables>;