// @ts-nocheck
import type * as Types from '../../../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { LeadForCategoryPageHeroWithLeadCtaFragmentDoc } from '../HeroWithLeadCta/HeroWithLeadCta.generated';
import { BusinessUserForAnnotationWithExpertAvatarFragmentDoc } from '../../../../../../../components/AnnotationWithExpertAvatar/AnnotationWithExpertAvatar.generated';
import { BusinessUserForExpertForAvatarWithPopoverFragmentDoc, BusinessUserForExpertForAvatarFragmentDoc } from '../../../../../../../services/avatars/BusinessUserForExpertForAvatar.generated';
import { BusinessUserForGetExpertExpertiseFragmentDoc, BusinessUserForGetExpertProfileLinkFragmentDoc } from '../../../../../../../services/expert/expert.generated';
import { LeadForCategoryPageHeroWithLeadHeaderFragmentDoc, BrowseCategoryPageViewModelForCategoryPageHeroWithLeadHeaderFragmentDoc } from '../HeroWithLeadHeader/HeroWithLeadHeader.generated';
import { LeadForYourCurationFragmentDoc } from '../../YourCuration/YourCuration.generated';
export type LeadForCategoryPageHeroWithLeadWithNeedsFragment = { __typename: 'Lead', id: any, expert: { __typename: 'BusinessUser', id: any, shortName: string, displayName: string, vanityId?: string | null, state: Types.BusinessUserState, expertProfile?: { __typename: 'ExpertProfile', id: any, background: string, backgroundSummaryLines: Array<string>, headline: string, profilePhoto?: { __typename: 'MediaFile', id: any, url: string } | null } | null, expertStats?: { __typename: 'ExpertStats', totalReviews: number, totalLeadsParticipated: number, averageRating?: number | null } | null, expertAttributes: { __typename: 'BusinessUserExpertAttributes', category: { __typename: 'Category', id: any, slug: string, singularDisplayName: string, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, expert?: { __typename: 'CategoryAssetNoun', singular: string } | null } | null }, specialtyCategory?: { __typename: 'Category', id: any, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, expert?: { __typename: 'CategoryAssetNoun', singular: string } | null } | null } | null }, articleAuthorProfile?: { __typename: 'ExpertProfile', id: any, headline: string } | null }, category: { __typename: 'Category', id: any } };

export const LeadForCategoryPageHeroWithLeadWithNeedsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LeadForCategoryPageHeroWithLeadWithNeeds"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Lead"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LeadForCategoryPageHeroWithLeadCta"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"LeadForCategoryPageHeroWithLeadHeader"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"LeadForYourCuration"}}]}}]} as unknown as DocumentNode;