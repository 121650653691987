// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { AuthenticatedUserFragmentDoc } from '../../fragments/AuthenticatedUser.generated';
import { AddressFragmentDoc } from '../../fragments/Address.generated';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type AuthenticateByExternalOAuthTokenMutationVariables = Types.Exact<{
  input: Types.AuthenticateByExternalOAuthTokenInput;
}>;


export type AuthenticateByExternalOAuthTokenMutation = { __typename: 'Mutation', authenticateByExternalOAuthToken: { __typename: 'AuthenticateByExternalOAuthTokenPayload', result: Types.AuthenticationResult, message?: string | null, user?: { __typename: 'User', id: any, experimentUserId: any, firstName?: string | null, lastName?: string | null, roles: Array<string>, profilePhoto?: { __typename: 'MediaFile', id: any, url: string } | null, notificationSettings: Array<{ __typename: 'NotificationSetting', type: Types.NotificationType, emailEnabled: boolean, smsEnabled: boolean }>, messagingUser: { __typename: 'MessagingUser', id: any }, address?: { __typename: 'PostalAddress', line1?: string | null, city?: string | null, state?: string | null, postalCode: string, country?: string | null } | null, savedAddresses: Array<{ __typename: 'Address', id: any, addresseeName?: string | null, line1?: string | null, line2?: string | null, city?: string | null, state?: string | null, country?: string | null, postalCode: string, securityCode?: string | null, phoneNumber?: string | null, active: boolean, isDefault?: boolean | null, countryDetails?: { __typename: 'Country', displayCountry: string } | null }>, contacts: Array<{ __typename: 'Contact', id: any, type: Types.ContactType, value: string, verified: boolean, emailAddress?: string | null, phoneNumber?: string | null, parsedPhoneNumber?: { __typename: 'ParsedPhoneNumber', countryCode: string, regionCode: string, nationalNumber: string } | null }> } | null } };

export type UpdateUserProfileForOAuthLoginMutationVariables = Types.Exact<{
  input: Types.UpdateUserProfileInput;
}>;


export type UpdateUserProfileForOAuthLoginMutation = { __typename: 'Mutation', updateUserProfile: { __typename: 'UpdateUserProfilePayload', hasError: boolean, errorCode?: Types.UserEditErrorCode | null, errors: Array<string>, user: { __typename: 'User', id: any, experimentUserId: any, firstName?: string | null, lastName?: string | null, roles: Array<string>, profilePhoto?: { __typename: 'MediaFile', id: any, url: string } | null, notificationSettings: Array<{ __typename: 'NotificationSetting', type: Types.NotificationType, emailEnabled: boolean, smsEnabled: boolean }>, messagingUser: { __typename: 'MessagingUser', id: any }, address?: { __typename: 'PostalAddress', line1?: string | null, city?: string | null, state?: string | null, postalCode: string, country?: string | null } | null, savedAddresses: Array<{ __typename: 'Address', id: any, addresseeName?: string | null, line1?: string | null, line2?: string | null, city?: string | null, state?: string | null, country?: string | null, postalCode: string, securityCode?: string | null, phoneNumber?: string | null, active: boolean, isDefault?: boolean | null, countryDetails?: { __typename: 'Country', displayCountry: string } | null }>, contacts: Array<{ __typename: 'Contact', id: any, type: Types.ContactType, value: string, verified: boolean, emailAddress?: string | null, phoneNumber?: string | null, parsedPhoneNumber?: { __typename: 'ParsedPhoneNumber', countryCode: string, regionCode: string, nationalNumber: string } | null }> } } };


export const AuthenticateByExternalOAuthTokenDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AuthenticateByExternalOAuthToken"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AuthenticateByExternalOAuthTokenInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"authenticateByExternalOAuthToken"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"result"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AuthenticatedUser"}}]}}]}}]}},...AuthenticatedUserFragmentDoc.definitions,...AddressFragmentDoc.definitions]} as unknown as DocumentNode;
export type AuthenticateByExternalOAuthTokenMutationFn = Apollo.MutationFunction<AuthenticateByExternalOAuthTokenMutation, AuthenticateByExternalOAuthTokenMutationVariables>;
export type AuthenticateByExternalOAuthTokenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AuthenticateByExternalOAuthTokenMutation, AuthenticateByExternalOAuthTokenMutationVariables>, 'mutation'>;

    export const AuthenticateByExternalOAuthTokenComponent = (props: AuthenticateByExternalOAuthTokenComponentProps) => (
      <ApolloReactComponents.Mutation<AuthenticateByExternalOAuthTokenMutation, AuthenticateByExternalOAuthTokenMutationVariables> mutation={AuthenticateByExternalOAuthTokenDocument} {...props} />
    );
    

/**
 * __useAuthenticateByExternalOAuthTokenMutation__
 *
 * To run a mutation, you first call `useAuthenticateByExternalOAuthTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateByExternalOAuthTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateByExternalOAuthTokenMutation, { data, loading, error }] = useAuthenticateByExternalOAuthTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthenticateByExternalOAuthTokenMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateByExternalOAuthTokenMutation, AuthenticateByExternalOAuthTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticateByExternalOAuthTokenMutation, AuthenticateByExternalOAuthTokenMutationVariables>(AuthenticateByExternalOAuthTokenDocument, options);
      }
export type AuthenticateByExternalOAuthTokenMutationHookResult = ReturnType<typeof useAuthenticateByExternalOAuthTokenMutation>;
export type AuthenticateByExternalOAuthTokenMutationResult = Apollo.MutationResult<AuthenticateByExternalOAuthTokenMutation>;
export type AuthenticateByExternalOAuthTokenMutationOptions = Apollo.BaseMutationOptions<AuthenticateByExternalOAuthTokenMutation, AuthenticateByExternalOAuthTokenMutationVariables>;
export const UpdateUserProfileForOAuthLoginDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateUserProfileForOAuthLogin"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateUserProfileInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateUserProfile"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AuthenticatedUser"}}]}},{"kind":"Field","name":{"kind":"Name","value":"hasError"}},{"kind":"Field","name":{"kind":"Name","value":"errorCode"}},{"kind":"Field","name":{"kind":"Name","value":"errors"}}]}}]}},...AuthenticatedUserFragmentDoc.definitions,...AddressFragmentDoc.definitions]} as unknown as DocumentNode;
export type UpdateUserProfileForOAuthLoginMutationFn = Apollo.MutationFunction<UpdateUserProfileForOAuthLoginMutation, UpdateUserProfileForOAuthLoginMutationVariables>;
export type UpdateUserProfileForOAuthLoginComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserProfileForOAuthLoginMutation, UpdateUserProfileForOAuthLoginMutationVariables>, 'mutation'>;

    export const UpdateUserProfileForOAuthLoginComponent = (props: UpdateUserProfileForOAuthLoginComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserProfileForOAuthLoginMutation, UpdateUserProfileForOAuthLoginMutationVariables> mutation={UpdateUserProfileForOAuthLoginDocument} {...props} />
    );
    

/**
 * __useUpdateUserProfileForOAuthLoginMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileForOAuthLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileForOAuthLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileForOAuthLoginMutation, { data, loading, error }] = useUpdateUserProfileForOAuthLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileForOAuthLoginMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileForOAuthLoginMutation, UpdateUserProfileForOAuthLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileForOAuthLoginMutation, UpdateUserProfileForOAuthLoginMutationVariables>(UpdateUserProfileForOAuthLoginDocument, options);
      }
export type UpdateUserProfileForOAuthLoginMutationHookResult = ReturnType<typeof useUpdateUserProfileForOAuthLoginMutation>;
export type UpdateUserProfileForOAuthLoginMutationResult = Apollo.MutationResult<UpdateUserProfileForOAuthLoginMutation>;
export type UpdateUserProfileForOAuthLoginMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileForOAuthLoginMutation, UpdateUserProfileForOAuthLoginMutationVariables>;