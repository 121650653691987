import without from 'lodash/without'
import { SellableSearchFilter, SellableSort, SortOrder } from '#src/generated/types'
import { SearchSellablesPaginationQuery } from './types'
import { serializeFacetSelections } from './serializeFacetSelections'

// When serializing a query, the "page" is optional because it defaults to 1
export interface SerializableSearchSellablesPaginationQuery
  extends Omit<SearchSellablesPaginationQuery, 'page'> {
  page?: number
}

export function buildSeachSellableSearchParamsAndPathSegments(
  query: SerializableSearchSellablesPaginationQuery
): { searchParams: URLSearchParams; pathSegments: String[] } {
  const pathSegments = []
  const searchParams = new URLSearchParams()

  if (query.page !== undefined && query.page !== 1) {
    searchParams.append('page', query.page.toString())
  }

  if (query.selections) {
    const serializedSelections = serializeFacetSelections(query.selections)

    serializedSelections.forEach(serializedSelection => {
      const { name, alias, value, prefix, bucketCount } = serializedSelection

      if (value) {
        if (alias && bucketCount === 1) {
          pathSegments.push(`${alias}/${encodeURIComponent(value)}`)
        } else {
          searchParams.append(`${prefix}:${alias || name}`, value)
        }
      }
    })
  }
  if (query.expertiseRequestId) {
    searchParams.append('ser', query.expertiseRequestId)
  }

  if (query.filters) {
    let filters = query.filters.slice()

    const isDealsSearch = filters.includes(SellableSearchFilter.DISCOUNTED)

    if (isDealsSearch) {
      // Remove the "DISCOUNTED" as that filter is implied by the "deals" suffix
      filters = without(filters, SellableSearchFilter.DISCOUNTED)
      pathSegments.push('deals')
    }

    // Encode any remaining filters as the `filters` query param
    if (filters.length) {
      searchParams.append('filters', filters.map(filter => filter.toLowerCase()).join(','))
    }
  }

  switch (query.sortBy) {
    case SellableSort.AVG_SALE_PRICE:
      searchParams.append('sort', 'price')
      break

    case SellableSort.MAX_PRICE_PERCENT_SAVINGS:
      searchParams.append('sort', 'savings')
      break

    case SellableSort.BEST_SELLER:
      searchParams.append('sort', 'purchases')
      break

    case SellableSort.POPULARITY:
      searchParams.append('sort', 'trending')
      break

    case SellableSort.MOST_RECOMMENDED_BY_EXPERTS:
      searchParams.append('sort', 'recommended')
      break

    case SellableSort.RELEVANCE:
      searchParams.append('sort', 'relevance')
  }

  // The default sort order is descending; we only include the order in the URL if it's ascending.
  if (query.sortOrder && query.sortOrder === SortOrder.ASC) {
    searchParams.append('order', 'asc')
  }

  return { searchParams, pathSegments }
}
