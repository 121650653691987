import * as History from 'history'
import { SellableSort, SortOrder } from '#src/generated/types'
import { SearchResultsPageQuery } from './types'
import { serializeFacetSelections } from './serializeFacetSelections'

// When serializing a query, the "page" is optional because it defaults to 1
export interface SerializableSearchResultsPageQuery extends Omit<SearchResultsPageQuery, 'page'> {
  page?: number
}

/**
 * Build a URL for a search results page (SRP). SRPs are a faceted UI, and support
 *   many of the features of PLPs: sorting, pagination, and filtering. The difference
 *   is that SRPs include a keyword search in addition to filters & facets.
 *
 * Some example SRP URLs might look like:
 *
 *  /search?q=red+jackets
 *  /search/golf?q=steel+irons
 *  /search/kitchen?q=knife&nrange%3Af-blade-length=20-70
 */
export function buildSearchResultsPageUrl(
  query: SerializableSearchResultsPageQuery
): History.Location {
  const pathname = query.departmentSlug ? `/search/${query.departmentSlug}` : '/search'

  const searchParams = new URLSearchParams()

  if (query.keywords) {
    searchParams.append('q', query.keywords)
  }

  if (query.page !== undefined && query.page !== 1) {
    searchParams.append('page', query.page.toString())
  }

  if (query.selections) {
    const serializedSelections = serializeFacetSelections(query.selections)

    serializedSelections.forEach(serializedSelection => {
      const { name, alias, value, prefix } = serializedSelection

      if (value) {
        searchParams.append(`${prefix}:${alias || name}`, value)
      }
    })
  }

  if (query.filters && query.filters.length) {
    searchParams.append('filters', query.filters.map(filter => filter.toLowerCase()).join(','))
  }

  switch (query.sortBy) {
    case SellableSort.AVG_SALE_PRICE:
      searchParams.append('sort', 'price')
      break

    case SellableSort.MAX_PRICE_PERCENT_SAVINGS:
      searchParams.append('sort', 'savings')
      break

    case SellableSort.BEST_SELLER:
      searchParams.append('sort', 'purchases')
      break

    case SellableSort.POPULARITY:
      searchParams.append('sort', 'trending')
      break

    case SellableSort.MOST_RECOMMENDED_BY_EXPERTS:
      searchParams.append('sort', 'recommended')
      break
  }

  // The default sort order is descending; we only include the order in the URL if it's ascending.
  if (query.sortOrder && query.sortOrder === SortOrder.ASC) {
    searchParams.append('order', 'asc')
  }

  // Include a `source` parameter for tracking.
  if (query.entryPoint) {
    searchParams.append('source', query.entryPoint)
  }

  // Include a `previewQueryMappingId` parameter for tracking.
  if (query.previewQueryMappingId) {
    searchParams.append('previewQueryMappingId', query.previewQueryMappingId)
  }

  // Include a `consumerSearchId` parameter for tracking.
  if (query.consumerSearchId) {
    searchParams.append('cstId', query.consumerSearchId)
  }

  if (query.layout) {
    searchParams.append('layout', query.layout)
  }

  return History.createLocation({
    pathname,
    search: searchParams.toString()
  })
}
