// @ts-nocheck
import type * as Types from '../../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SellableForProductReviewItemFragment = { __typename: 'Sellable', id: any, title: string, primaryImage?: { __typename: 'MediaFile', id: any, url: string } | null };

export type OrderLineItemForProductReviewItemFragment = { __typename: 'OrderLineItem', id: any, order: { __typename: 'Order', id: any, paidAt?: any | null, sortTimestamp: any } };

export type CreateConsumerProductReviewForProductReviewItemMutationVariables = Types.Exact<{
  input: Types.CreateConsumerProductReviewInput;
}>;


export type CreateConsumerProductReviewForProductReviewItemMutation = { __typename: 'Mutation', createConsumerProductReview: { __typename: 'CreateConsumerProductReviewPayload', consumerProductReview?: { __typename: 'ConsumerProductReview', id: any } | null, error?: { __typename: 'AlreadyReviewedCreateConsumerProductReviewError', errorMessage: string } | { __typename: 'InvalidOrderLineItemTypeCreateConsumerProductReviewError', errorMessage: string } | { __typename: 'InvalidPostTokenConsumerProductReviewError', errorMessage: string } | { __typename: 'NotPurchasedCreateConsumerProductReviewError', errorMessage: string } | { __typename: 'ParentOrderLineItemCreateConsumerProductReviewError', errorMessage: string } | null } };

export type UpdateConsumerProductReviewMutationVariables = Types.Exact<{
  input: Types.UpdateConsumerProductReviewInput;
}>;


export type UpdateConsumerProductReviewMutation = { __typename: 'Mutation', updateConsumerProductReview: { __typename: 'UpdateConsumerProductReviewPayload', consumerProductReview?: { __typename: 'ConsumerProductReview', id: any } | null, error?: { __typename: 'DefaultErrorMessage', errorMessage: string } | { __typename: 'InvalidPostTokenConsumerProductReviewError', errorMessage: string } | null } };

export const SellableForProductReviewItemFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableForProductReviewItem"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Sellable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"primaryImage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}}]} as unknown as DocumentNode;
export const OrderLineItemForProductReviewItemFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"OrderLineItemForProductReviewItem"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"OrderLineItem"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"order"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"paidAt"}},{"kind":"Field","name":{"kind":"Name","value":"sortTimestamp"}}]}}]}}]} as unknown as DocumentNode;
export const CreateConsumerProductReviewForProductReviewItemDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateConsumerProductReviewForProductReviewItem"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateConsumerProductReviewInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createConsumerProductReview"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"consumerProductReview"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"error"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ErrorMessage"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"errorMessage"}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type CreateConsumerProductReviewForProductReviewItemMutationFn = Apollo.MutationFunction<CreateConsumerProductReviewForProductReviewItemMutation, CreateConsumerProductReviewForProductReviewItemMutationVariables>;
export type CreateConsumerProductReviewForProductReviewItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateConsumerProductReviewForProductReviewItemMutation, CreateConsumerProductReviewForProductReviewItemMutationVariables>, 'mutation'>;

    export const CreateConsumerProductReviewForProductReviewItemComponent = (props: CreateConsumerProductReviewForProductReviewItemComponentProps) => (
      <ApolloReactComponents.Mutation<CreateConsumerProductReviewForProductReviewItemMutation, CreateConsumerProductReviewForProductReviewItemMutationVariables> mutation={CreateConsumerProductReviewForProductReviewItemDocument} {...props} />
    );
    

/**
 * __useCreateConsumerProductReviewForProductReviewItemMutation__
 *
 * To run a mutation, you first call `useCreateConsumerProductReviewForProductReviewItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConsumerProductReviewForProductReviewItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConsumerProductReviewForProductReviewItemMutation, { data, loading, error }] = useCreateConsumerProductReviewForProductReviewItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConsumerProductReviewForProductReviewItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateConsumerProductReviewForProductReviewItemMutation, CreateConsumerProductReviewForProductReviewItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConsumerProductReviewForProductReviewItemMutation, CreateConsumerProductReviewForProductReviewItemMutationVariables>(CreateConsumerProductReviewForProductReviewItemDocument, options);
      }
export type CreateConsumerProductReviewForProductReviewItemMutationHookResult = ReturnType<typeof useCreateConsumerProductReviewForProductReviewItemMutation>;
export type CreateConsumerProductReviewForProductReviewItemMutationResult = Apollo.MutationResult<CreateConsumerProductReviewForProductReviewItemMutation>;
export type CreateConsumerProductReviewForProductReviewItemMutationOptions = Apollo.BaseMutationOptions<CreateConsumerProductReviewForProductReviewItemMutation, CreateConsumerProductReviewForProductReviewItemMutationVariables>;
export const UpdateConsumerProductReviewDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updateConsumerProductReview"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateConsumerProductReviewInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateConsumerProductReview"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"consumerProductReview"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"error"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ErrorMessage"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"errorMessage"}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type UpdateConsumerProductReviewMutationFn = Apollo.MutationFunction<UpdateConsumerProductReviewMutation, UpdateConsumerProductReviewMutationVariables>;
export type UpdateConsumerProductReviewComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateConsumerProductReviewMutation, UpdateConsumerProductReviewMutationVariables>, 'mutation'>;

    export const UpdateConsumerProductReviewComponent = (props: UpdateConsumerProductReviewComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateConsumerProductReviewMutation, UpdateConsumerProductReviewMutationVariables> mutation={UpdateConsumerProductReviewDocument} {...props} />
    );
    

/**
 * __useUpdateConsumerProductReviewMutation__
 *
 * To run a mutation, you first call `useUpdateConsumerProductReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConsumerProductReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConsumerProductReviewMutation, { data, loading, error }] = useUpdateConsumerProductReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConsumerProductReviewMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConsumerProductReviewMutation, UpdateConsumerProductReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConsumerProductReviewMutation, UpdateConsumerProductReviewMutationVariables>(UpdateConsumerProductReviewDocument, options);
      }
export type UpdateConsumerProductReviewMutationHookResult = ReturnType<typeof useUpdateConsumerProductReviewMutation>;
export type UpdateConsumerProductReviewMutationResult = Apollo.MutationResult<UpdateConsumerProductReviewMutation>;
export type UpdateConsumerProductReviewMutationOptions = Apollo.BaseMutationOptions<UpdateConsumerProductReviewMutation, UpdateConsumerProductReviewMutationVariables>;