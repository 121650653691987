import React from 'react'
import classnames from 'classnames'
import { BubbleLoader, Button, ButtonProps } from '@deal/components'
import styles from './styles.css'

interface PathSubmitButtonLegacyProps extends ButtonProps {
  loading: boolean
}

const PathSubmitButtonLegacy: React.FC<React.PropsWithChildren<PathSubmitButtonLegacyProps>> = ({
  loading,
  disabled,
  children,
  className,
  size = 'medium',
  ...buttonProps
}) => {
  return (
    <Button
      disabled={loading || disabled}
      className={classnames(styles.button, className)}
      size={size}
      {...buttonProps}
    >
      <div className={classnames({ [styles.loading]: loading })}>{children}</div>
      {loading && <BubbleLoader className={styles[size]} color="#767676" />}
    </Button>
  )
}

export default PathSubmitButtonLegacy
