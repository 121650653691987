import { LoggerClient } from '@deal/logger/types'
import config from '#src/app/config'

let loggerClient: LoggerClient

if (process.env.TARGET === 'web') {
  const { LoggerClient: BrowserLoggerClient } = require('@deal/logger/client/browser')

  loggerClient = new BrowserLoggerClient({
    accessToken: config.get('rollbar.webAccessToken'),
    environment: config.get('environment'),
    enabled: config.get('rollbar.enabled'),
    version: process.env.COMMIT_SHA
  })
} else {
  const { LoggerClient: NodeLoggerClient } = require('@deal/logger/client/server')

  loggerClient = new NodeLoggerClient({
    accessToken: config.get('rollbar.serverAccessToken'),
    environment: config.get('environment'),
    enabled: config.get('rollbar.enabled'),
    version: process.env.COMMIT_SHA
  })
}

export default loggerClient
