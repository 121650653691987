import React, { useRef, useState } from 'react'
import classnames from 'classnames'
import { useLocation } from '@deal/router'
import { useElementInteractionTracking } from '@deal/dom-hooks'
import { useChatConversation } from '@deal/chat-firebase'
import { Button, ButtonProps } from '@deal/bluxome'
import { isAIExpertConversation } from '#src/app/services/aiChat/isAIConversation'
import { useStartExpertConsumerConversationMutation } from '#src/app/mutations/StartExpertConsumerConversation.generated'
import { useBreakpoint } from '#src/app/hooks/useBreakpoint'
import { useProductComparisonContext } from '#src/app/context/product-comparison'
import { useCurrentExpert } from '#src/app/context/expert'
import { ChatWidgetPathContextProvider } from '#src/app/context/chatWidgetPath'
import { useChatWidgetStateContext } from '#src/app/context/chat-widget-state'
import { useUserAgentContext } from '#src/app/containers/UserAgent'
import { useIdentityContext } from '#src/app/containers/Identity'
import { GuestChatSource, GuestChatWidget } from './components/GuestChatWidget'
import { ChatWidgetButton } from './components/ChatWidgetButton'
import { ChatWidget } from './components/ChatWidget'
import CompareIcon from './compare.svg'
import styles from './styles.css'

const CHAT_WIDGET_DISABLED_DATE = new Date('2025-03-01T08:01:00.000Z')

export interface ActionBarProps {
  buttonText?: ButtonProps['children']
  buttonColor?: ButtonProps['color']
  buttonIcon?: ButtonProps['icon']
  buttonIconPlacement?: ButtonProps['iconPlacement']
  buttonPopoverTriggerProps?: ButtonProps['popoverTriggerProps']
  buttonOnPress?: ButtonProps['onPress']
  buttonIsDisabled?: ButtonProps['isDisabled']
  buttonIsLoading?: ButtonProps['isLoading']
  chat: boolean
  guestChat?: { expertIntroMessage: string; source: GuestChatSource }
  hideCompareButton?: boolean
}

const ActionBar: React.FC<ActionBarProps> = ({
  buttonText,
  buttonColor = 'shop-orange',
  buttonIcon,
  buttonIconPlacement,
  buttonPopoverTriggerProps,
  buttonOnPress,
  buttonIsDisabled,
  buttonIsLoading,
  chat,
  guestChat,
  hideCompareButton = false
}) => {
  const isMobile = !useBreakpoint('md', 'bluxome')
  // useElementInteractionTracking requires a ref, but it's not used for the click tracking
  const dummyRef = useRef<HTMLElement>(null)
  const { trackElementClickedEvent } = useElementInteractionTracking({
    ref: dummyRef,
    name: 'action-bar-compare-cta'
  })
  const location = useLocation()
  const { sellableIds, canCompareProduct, compareRouteLocation } = useProductComparisonContext()
  const { expert, loading: expertLoading } = useCurrentExpert()
  const { isBot } = useUserAgentContext()
  const { myself } = useIdentityContext()
  const { isChatWidgetMinimized } = useChatWidgetStateContext()

  const showCompareButton =
    !isMobile &&
    !hideCompareButton &&
    canCompareProduct &&
    sellableIds &&
    sellableIds.length > 0 &&
    ['/account', '/compare', '/inbox', '/checkout', '/scout'].every(
      route => !location.pathname.startsWith(route)
    )

  const compareButtonLabelDesktop = `Compare ${sellableIds?.length || 0} items`

  /**
   * Render the chat widget if ALL of the following cases apply:
   *  1. The chat UI is shown on this page
   *  2. Expert/conversation data is not loading
   *  3. An expert to chat with has been identified
   *  4. The user agent is not a bot - hidden to prevent bots indexing duplicate content across pages,
   *     negatively impacts SEO ranking
   */
  const renderChat = chat && myself && !expertLoading && expert && !isBot
  const renderPdpGuestChat =
    guestChat &&
    !renderChat &&
    !myself &&
    !expertLoading &&
    expert &&
    !expert.expertAttributes.placeholderExpert &&
    !isBot

  // logged-in users can initiate conversations with experts by messaging them directly.
  const [startExpertConsumerConversation, startExpertConsumerConversationData] =
    useStartExpertConsumerConversationMutation({
      variables: {
        input: {
          // `onConversationStarted` can only be triggered if the user is logged-in.
          consumerId: myself?.id,
          expertId: expert?.id,
          sourceFlow: {
            directExpertChatFlow: {
              expertId: expert?.id
            }
          }
        }
      }
    })

  // use the conversationId from the existing expert, if one exists. Otherwise, see
  //   if we've created one via `startExpertConsumerConversation`.
  const conversationId =
    expert?.existingLeadForConsumer?.conversationId ||
    startExpertConsumerConversationData.data?.startExpertConsumerConversation.conversation?.id

  const { conversation } = useChatConversation({
    conversationId,
    recipientIds: expert ? [expert.id] : undefined
  })

  const isAI = isAIExpertConversation(expert)

  return (
    <>
      <div
        className={classnames(styles.container, {
          [styles.actionBarHidden]: !buttonText && !buttonIcon && !showCompareButton,
          [styles.containerVisible]: isChatWidgetMinimized
        })}
        // This attr allows the Curated Connect widget to avoid the ActionBar component.
        data-curated-avoid="true"
      >
        {renderChat ? (
          <ChatWidgetButton expert={expert} isAI={isAI} unreadCount={conversation?.unreadCount} />
        ) : renderPdpGuestChat ? (
          <ChatWidgetButton expert={expert} guestChat />
        ) : null}
        <div className={styles.actionBar}>
          {showCompareButton && (
            <div className={styles.compareButtonContainer}>
              <Button
                size="base"
                sizeMd="large"
                color="neutral-light"
                style="outline"
                icon={CompareIcon}
                aria-label={compareButtonLabelDesktop}
                link={{
                  to: compareRouteLocation,
                  onClick: () => trackElementClickedEvent()
                }}
                fullWidth
              >
                <span className={styles.compareButtonLabelMobile}>{sellableIds.length}</span>
                <span className={styles.compareButtonLabelDesktop}>
                  {compareButtonLabelDesktop}
                </span>
              </Button>
            </div>
          )}
          {(buttonText || buttonIcon) && (
            <div className={styles.primaryButtonContainer}>
              <Button
                size="base"
                sizeMd="large"
                color={buttonColor}
                icon={buttonIcon}
                iconPlacement={buttonIconPlacement}
                onPress={buttonOnPress}
                isDisabled={buttonIsDisabled}
                isLoading={buttonIsLoading}
                popoverTriggerProps={buttonPopoverTriggerProps}
              >
                {buttonText}
              </Button>
            </div>
          )}
        </div>
      </div>
      {renderChat ? (
        <ChatWidgetPathContextProvider>
          <ChatWidget
            expert={expert}
            isAI={isAI}
            leadId={expert.existingLeadForConsumer?.id}
            conversationId={conversationId}
            onConversationStarted={startExpertConsumerConversation}
          />
        </ChatWidgetPathContextProvider>
      ) : renderPdpGuestChat ? (
        <GuestChatWidget
          expert={expert}
          expertIntroMessage={guestChat.expertIntroMessage}
          source={guestChat.source}
        />
      ) : null}
    </>
  )
}

const ActionBarWrapper: React.FC<ActionBarProps> = props => {
  const [isChatWidgetDisabled] = useState(() => Date.now() > CHAT_WIDGET_DISABLED_DATE.valueOf())

  if (isChatWidgetDisabled) {
    return null
  }

  return <ActionBar {...props} />
}

export { ActionBarWrapper as ActionBar }
