// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { FeedNotificationForFeedNotificationFragmentDoc } from './page/components/FeedNotification/FeedNotificationForFeedNotification.generated';
import { ExpertForExpertAvatarLegacyFragmentDoc } from '../../../components/ExpertAvatarLegacy/ExpertAvatarLegacy.generated';
import { MediaFileFragmentDoc } from '../../../fragments/MediaFile.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type FeedNotificationPageQueryVariables = Types.Exact<{
  recipientIds: Array<Types.Scalars['AnyId']['input']> | Types.Scalars['AnyId']['input'];
}>;


export type FeedNotificationPageQuery = { __typename: 'Query', feedNotificationConnection: { __typename: 'FeedNotificationConnection', edges: Array<{ __typename: 'FeedNotificationEdge', cursor: string, node: { __typename: 'FeedNotification', id: any, interacted: boolean, lastActivityAt: any, objectConsumerRelativeUrl: string, previewActor?: { __typename: 'FeedAuthorConsumer', id: string, name: string, profilePhoto?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null } | { __typename: 'FeedAuthorExpert', id: string, name: string, expert: { __typename: 'BusinessUser', id: any, displayName: string, expertProfile?: { __typename: 'ExpertProfile', id: any, headline: string, profilePhoto?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null } | null } } | null, textSummary: { __typename: 'FeedNotificationTextSummary', actorVerbText: string, objectText: string } } }>, pageInfo: { __typename: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } };


export const FeedNotificationPageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"FeedNotificationPage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"recipientIds"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AnyId"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"feedNotificationConnection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"query"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"recipientIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"recipientIds"}}}]}},{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"IntValue","value":"100"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FeedNotificationForFeedNotification"}}]}},{"kind":"Field","name":{"kind":"Name","value":"cursor"}}]}},{"kind":"Field","name":{"kind":"Name","value":"pageInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"endCursor"}},{"kind":"Field","name":{"kind":"Name","value":"hasNextPage"}}]}}]}}]}},...FeedNotificationForFeedNotificationFragmentDoc.definitions,...ExpertForExpertAvatarLegacyFragmentDoc.definitions,...MediaFileFragmentDoc.definitions]} as unknown as DocumentNode;
export type FeedNotificationPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FeedNotificationPageQuery, FeedNotificationPageQueryVariables>, 'query'> & ({ variables: FeedNotificationPageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FeedNotificationPageComponent = (props: FeedNotificationPageComponentProps) => (
      <ApolloReactComponents.Query<FeedNotificationPageQuery, FeedNotificationPageQueryVariables> query={FeedNotificationPageDocument} {...props} />
    );
    

/**
 * __useFeedNotificationPageQuery__
 *
 * To run a query within a React component, call `useFeedNotificationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedNotificationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedNotificationPageQuery({
 *   variables: {
 *      recipientIds: // value for 'recipientIds'
 *   },
 * });
 */
export function useFeedNotificationPageQuery(baseOptions: Apollo.QueryHookOptions<FeedNotificationPageQuery, FeedNotificationPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedNotificationPageQuery, FeedNotificationPageQueryVariables>(FeedNotificationPageDocument, options);
      }
export function useFeedNotificationPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedNotificationPageQuery, FeedNotificationPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedNotificationPageQuery, FeedNotificationPageQueryVariables>(FeedNotificationPageDocument, options);
        }
export function useFeedNotificationPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FeedNotificationPageQuery, FeedNotificationPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FeedNotificationPageQuery, FeedNotificationPageQueryVariables>(FeedNotificationPageDocument, options);
        }
export type FeedNotificationPageQueryHookResult = ReturnType<typeof useFeedNotificationPageQuery>;
export type FeedNotificationPageLazyQueryHookResult = ReturnType<typeof useFeedNotificationPageLazyQuery>;
export type FeedNotificationPageSuspenseQueryHookResult = ReturnType<typeof useFeedNotificationPageSuspenseQuery>;
export type FeedNotificationPageQueryResult = Apollo.QueryResult<FeedNotificationPageQuery, FeedNotificationPageQueryVariables>;