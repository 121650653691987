import React, { useState } from 'react'
import * as History from 'history'
import { useHistory } from '@deal/router'
import { Path, PathNavigationDetails, PathPlacement } from '@deal/path-flow'
import { EntityCreatedPathGraphFlowRequestEvent } from '#src/generated/types'
import { buildCategoryOrProductListingPageUrl } from '#src/app/services/search/buildCategoryOrProductListingPageUrl'
import { loginLocation } from '#src/app/services/routing'
import { formatPathUrl } from '#src/app/services/path'
import { isMobileBrowser } from '#src/app/services/mobile'
import { useUserAgentContext } from '#src/app/containers/UserAgent'
import { useIdentityContext } from '#src/app/containers/Identity'
import AuthenticationFlowModal from '#src/app/components/AuthenticationFlowModal'
import { AuthIntent } from '../../AuthenticationFlow'

export interface StandalonePathContainerProps {
  pathPlacement?: PathPlacement
  defaultSubmitToken?: string
  defaultPathGraphFlowId?: string
  directedActiveNodeIdOrSlug?: string
  onActiveNodeUpdated?: (pathNavigationDetails: PathNavigationDetails) => void
  onReturnToPreviousContext?: () => void
}

const StandalonePathContainer: React.FC<React.PropsWithChildren<StandalonePathContainerProps>> = ({
  defaultSubmitToken,
  defaultPathGraphFlowId,
  pathPlacement = 'default',
  onActiveNodeUpdated,
  directedActiveNodeIdOrSlug,
  onReturnToPreviousContext
}) => {
  const userAgent = useUserAgentContext()
  const history = useHistory()
  const { refetch: refetchIdentity } = useIdentityContext()
  const [authIntent, setAuthIntent] = useState(AuthIntent.Login)
  const [requestLogin, setRequestLogin] = useState<{
    urlAfterLogin: string
    loginHeaderText?: string | null
    loginSubHeaderText?: string | null
  } | null>(null)

  const onAuthenticate = () => {
    if (!requestLogin) {
      return
    }
    // This will indirectly update the directedActiveNodeIdOrSlug prop, which will force
    // the path to re-visit the path and remove the "authentication required" directive.
    history.push(requestLogin.urlAfterLogin)
    setRequestLogin(null)
  }

  const navigate = (location: History.Location | string) => {
    if (pathPlacement === 'widget') {
      if (typeof location === 'string') {
        open(location, '_top')
      } else {
        open(location.pathname + '?' + location.search, '_top')
      }
    } else {
      history.push(location)
    }
  }

  return (
    <>
      <AuthenticationFlowModal
        standalone
        intent={authIntent}
        onIntentChanged={setAuthIntent}
        isOpen={!!requestLogin}
        onRegister={onAuthenticate}
        onLogin={onAuthenticate}
        onRequestClose={() => {
          // Since authentication is forced, the only option to proceed is to login
        }}
        headline={requestLogin?.loginHeaderText}
        subHeadline={requestLogin?.loginSubHeaderText}
        hideCloseButton={true}
      />
      <Path
        isRebranded
        defaultPathGraphFlowId={defaultPathGraphFlowId}
        defaultSubmitToken={defaultSubmitToken}
        videoCaptureMethod={isMobileBrowser(userAgent) ? 'video-upload' : 'media-recorder'}
        pathPlacement={pathPlacement}
        directedActiveNodeSlugOrId={directedActiveNodeIdOrSlug}
        onRequestLogin={({ pathGraphFlow, nodeSlugOrId, pathGraphFlowSlug, forcedLogin }) => {
          if (pathGraphFlow) {
            const urlAfterLogin = formatPathUrl({
              pathGraphFlowId: pathGraphFlow.pathGraphFlowId,
              nodeSlugOrId,
              pathGraphFlowSlug
            })
            if (forcedLogin) {
              const customCopyProvided = !!(
                forcedLogin.loginHeaderText || forcedLogin.loginSubHeaderText
              )
              // If custom copy is provided, ensure we only display that custom copy and
              // hide the built-in fallback copy (`undefined` triggers fallback copy, `null` hides it)
              setRequestLogin({
                loginHeaderText: customCopyProvided
                  ? forcedLogin.loginHeaderText || null
                  : undefined,
                loginSubHeaderText: customCopyProvided
                  ? forcedLogin.loginSubHeaderText || null
                  : // Default copy is more like
                    'Please login to proceed',
                urlAfterLogin
              })
            } else {
              navigate(loginLocation(urlAfterLogin))
            }
          } else {
            throw new Error('Malformatted login location dispatched from `Path` component')
          }
        }}
        onRefetchIdentity={() => {
          if (refetchIdentity) {
            return refetchIdentity().then(response => {
              if (response.error) {
                return Promise.reject(response.error)
              }
            })
          } else {
            return Promise.reject('Error refetching user identity!')
          }
        }}
        onActiveNodeUpdated={onActiveNodeUpdated}
        onPathCompleted={pathCompleteActions => {
          if (pathCompleteActions && pathCompleteActions.redirectResult && refetchIdentity) {
            const redirectAction = () => {
              switch (pathCompleteActions.redirectResult?.__typename) {
                case 'ApplyFinishRedirectPathNodeFlowViewModelSuccess':
                  navigate(`/apply/create-profile`)
                  break
                case 'ProductRecommendationSuccessRedirectPathNodeFlowViewModelSuccess':
                  if (pathPlacement === 'widget') {
                    window.parent.postMessage(
                      {
                        type: 'pathCompleted',
                        redirectResult:
                          'ProductRecommendationSuccessRedirectPathNodeFlowViewModelSuccess'
                      },
                      '*'
                    )
                  } else {
                    navigate(`/curations/recommendations`)
                  }
                  break
                case 'InboxRedirectPathNodeFlowViewModelSuccess':
                  if (onReturnToPreviousContext) {
                    onReturnToPreviousContext()
                  } else if (
                    pathCompleteActions.redirectResult.lead?.destinationPage.__typename ===
                    'LeadDestinationDepartmentPage'
                  ) {
                    navigate(
                      `/d/${pathCompleteActions.redirectResult.lead?.destinationPage.department.slug}`
                    )
                  } else if (
                    pathCompleteActions.redirectResult.lead?.destinationPage.__typename ===
                    'LeadDestinationSellablePage'
                  ) {
                    navigate(
                      `/products/${pathCompleteActions.redirectResult.lead.destinationPage.sellableId}`
                    )
                  } else if (
                    pathCompleteActions.redirectResult.lead?.destinationPage.__typename ===
                    'LeadDestinationCategoryPage'
                  ) {
                    const category =
                      pathCompleteActions.redirectResult.lead.destinationPage.category
                    navigate(buildCategoryOrProductListingPageUrl(category))
                  } else if (
                    pathCompleteActions.redirectResult.lead?.destinationPage.__typename ===
                    'LeadDestinationTopListPage'
                  ) {
                    const topList = pathCompleteActions.redirectResult.lead.destinationPage.topList
                    navigate(`/top-lists/${topList.friendlyId}/${topList.slug}`)
                  } else if (
                    pathCompleteActions.pathNavigationDetails.pathGraphFlowSlug ===
                    'r-golf-ai-contact-collection'
                  ) {
                    // hack to prevent redirect to inbox page when the path is for ai chat contact collection
                    navigate(parent.document.location.pathname)
                  } else {
                    navigate(
                      `/inbox/${pathCompleteActions.redirectResult.lead?.conversation?.id}?expert-introduction`
                    )
                  }
                  break

                case 'ReturnToPreviousContextRedirectPathNodeFlowViewModelSuccess':
                  if (onReturnToPreviousContext) {
                    onReturnToPreviousContext()
                  } else {
                    throw new Error(
                      'Path redirect action attempted to return to previous context without handler'
                    )
                  }
                  break

                case 'UserMilestoneFinishRedirectPathNodeFlowViewModelSuccess':
                  if (onReturnToPreviousContext) {
                    onReturnToPreviousContext()
                  } else {
                    throw new Error(
                      'Path redirect action attempted to return to previous context without handler'
                    )
                  }
                  break

                case 'SurveyResponseFinishRedirectPathNodeFlowViewModelSuccess': {
                  const createdSurveyResponseEntity = pathCompleteActions.requestEvents?.find(
                    req =>
                      req.__typename === 'EntityCreatedPathGraphFlowRequestEvent' &&
                      req.createdSurveyResponse
                  ) as EntityCreatedPathGraphFlowRequestEvent | undefined

                  if (
                    createdSurveyResponseEntity?.createdSurveyResponse?.surveyName.includes(
                      'With-Promo'
                    )
                  ) {
                    navigate('/survey/complete-wp')
                  } else if (
                    createdSurveyResponseEntity?.createdSurveyResponse?.surveyName.includes(
                      'Expert Referral'
                    )
                  ) {
                    navigate('/survey/complete-referral')
                  } else {
                    navigate('/survey/complete-np')
                  }

                  break
                }

                case 'ConsumerUrlRedirectPathNodeFlowViewModelSuccess':
                  navigate(pathCompleteActions.redirectResult.relativeUrl)
                  break
                case 'AbsoluteUrlRedirectPathNodeFlowViewModelSuccess':
                  window.location.href = pathCompleteActions.redirectResult.absoluteUrl
                  break
                case 'FeedPostRedirectPathNodeFlowViewModelSuccess':
                  navigate(
                    `/for-you?requestPostId=${pathCompleteActions.redirectResult.feedPost.id}`
                  )
                  break
              }
            }

            refetchIdentity().then(redirectAction)
          } else {
            // eslint-disable-next-line no-console -- we want this warning in the console
            console.warn(
              'Path completed with no eligible action for consumer landing page implementation'
            )
          }
        }}
      />
    </>
  )
}

export default StandalonePathContainer
