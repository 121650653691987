import React from 'react'
import { Helmet } from 'react-helmet-async'
import { relativeToAbsoluteUrl } from '#src/app/services/navigation'
import config from '#src/app/config'
import type { HelmetProps } from 'react-helmet-async'

const DEFAULT_OG_DESCRIPTION =
  "Shop with unbiased experts and get personalized advice on products. Big purchases are big decisions – you shouldn't have to make them alone."

type TwitterCardType = 'summary' | 'summary_large_image' | 'app' | 'player'

interface PageMetadataProps extends HelmetProps {
  /**
   * The title to use in the browser bar and in Open Graph metadata.
   */
  title?: string

  /**
   * By default "| Curated" is appended to the title in the browser bar. Use this prop to disable that behavior.
   */
  ignoreTitleTemplate?: boolean

  /**
   * Used as the meta description and og:description.
   */
  description?: string | null

  /**
   * The canonical path of this page.
   */
  canonicalPath?: string

  /**
   * The open graph image.
   */
  imageUrl?: string

  /**
   * The type of this page. Defaults to "website" but can be set to something else for more specific use-cases, e.g. "article".
   */
  type?: string

  /**
   * How Twitter should render links to this page.
   */
  twitterCardType?: TwitterCardType

  /**
   * Should this page be indexed
   */
  indexable?: boolean
}

/**
 * @deprecated PageMetadata will be removed in favor of the Page component which has PageMetadata internally
 */
const PageMetadata: React.FC<PageMetadataProps> = ({
  title,
  ignoreTitleTemplate,
  description,
  canonicalPath,
  imageUrl,
  type,
  twitterCardType,
  indexable,
  ...helmetProps
}) => {
  const descriptionOrDefault = description || DEFAULT_OG_DESCRIPTION
  const canonicalUrl = canonicalPath && relativeToAbsoluteUrl(canonicalPath)
  const env: string = config.get('environment')
  const envTag = env === 'staging' ? '[STAGING] ' : env.startsWith('dev') ? '[DEV] ' : ''

  return (
    <Helmet
      defaultTitle={`${envTag}Curated`}
      titleTemplate={ignoreTitleTemplate ? undefined : '%s | Curated.com'}
      {...helmetProps}
    >
      <meta name="description" content={descriptionOrDefault} />
      <meta property="og:description" content={descriptionOrDefault} />
      {/* a page shouldn't be both no-index and canonical at the same time. See: https://developers.google.com/search/docs/advanced/crawling/consolidate-duplicate-urls */}
      {indexable !== false && canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {canonicalUrl && <meta property="og:url" content={canonicalUrl} />}
      {title && <title>{`${envTag}${title}`}</title>}
      {title && <meta property="og:title" content={title} />}
      {type && <meta property="og:type" content={type} />}
      {twitterCardType && <meta property="twitter:card" content={twitterCardType} />}
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      {/**
       * React Helmet directly parses the `children` prop and requires that it be a simple arrray. As such, we can
       *    not combine these into a single condition with a fragment
       */}
      {indexable === false && <meta name="robots" content="noindex, nofollow" />}
      {indexable === false && <meta name="googlebot" content="noindex, nofollow" />}
    </Helmet>
  )
}

export default PageMetadata
