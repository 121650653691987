import React, { useEffect } from 'react'
import uniq from 'lodash/uniq'
import loadable from '@loadable/component'
import { useHistory } from '@deal/router'
import { SellableSearchFilter, SellableSort, SortOrder } from '#src/generated/types'
import { parseSearchResultsPageUrl } from '#src/app/services/search/parseSearchResultsPageUrl'
import { facetSelectionsMapToGraphQLInput } from '#src/app/services/search'
import usePreferredExpert from '#src/app/hooks/usePreferredExpert'
import { ProductsSearchResultsViewedEvent } from '#src/app/events/ProductsSearchResultsViewedEvent'
import { useAnalyticsContext } from '#src/app/containers/Analytics'
import Page from '#src/app/components/Page'
import { useSearchRouteQuery } from './SearchRoute.generated'
import LoadingComponent from './loading'

const PageComponent = loadable(() => import(/* webpackChunkName: "search" */ './page'), {
  resolveComponent: page => page.SearchResultsPageComponent
})

export const SEARCH_RESULTS_PAGE_SIZE = 36

interface SearchRouteProps {}

const SearchRoute: React.FC<SearchRouteProps> = () => {
  const analytics = useAnalyticsContext()
  const history = useHistory()
  const { location } = history

  const searchQuery = parseSearchResultsPageUrl(location)

  const preferredExpertVanityId = usePreferredExpert()

  const searchSort = searchQuery.sortBy || SellableSort.RELEVANCE
  const searchOrder = searchQuery.sortOrder || SortOrder.DESC

  useEffect(() => {
    analytics?.trackExperimentMetric('consumer_product_search_result_page_views_total')
  }, [])

  const query = useSearchRouteQuery({
    variables: {
      keywords: searchQuery.keywords || '',
      previewQueryMappingId: searchQuery.previewQueryMappingId,
      query: {
        departmentSlug: searchQuery.departmentSlug,
        categorySlugs: !searchQuery.categorySlug ? undefined : [searchQuery.categorySlug],
        facets: facetSelectionsMapToGraphQLInput(searchQuery.selections || {}),
        previewQueryMappingId: searchQuery.previewQueryMappingId,
        keywords: searchQuery.keywords,
        filters: uniq([
          SellableSearchFilter.INCLUDE_IN_STOCK_OR_PRE_ORDER,
          ...(searchQuery.filters || [])
        ]),
        sellableSearchUseCase: 'CONSUMER_SEARCH',
        maxFacetTerms: 100,
        consumerSearchTrackingId: searchQuery.consumerSearchId
      },
      offset: (searchQuery.page - 1) * SEARCH_RESULTS_PAGE_SIZE,
      limit: SEARCH_RESULTS_PAGE_SIZE,
      sortBy: searchSort,
      order: searchOrder,
      departmentSlug: searchQuery.departmentSlug || '',
      skipDepartment: !searchQuery.departmentSlug,
      preferredExpertVanityId: preferredExpertVanityId
    },
    onCompleted: ({ consumerSearch }) => {
      if (consumerSearch.__typename === 'ConsumerSearchResultSellables') {
        analytics?.track(
          new ProductsSearchResultsViewedEvent({
            query: {
              keywords: searchQuery.keywords,
              category_slug: searchQuery.departmentSlug || 'products',
              facets: facetSelectionsMapToGraphQLInput(searchQuery.selections || {}),
              filters: searchQuery.filters,
              sort_by: searchSort,
              page: searchQuery.page
            },
            total_results_count: consumerSearch.sellablesPagination.count,
            visible_results_count: consumerSearch.sellablesPagination.sellables.length,
            entry_point: searchQuery.entryPoint,
            list_id: consumerSearch.sellablesPagination.trackingId
          })
        )
        analytics?.trackExperimentMetric('consumer_products_search_results_views_total')
      }
    }
  })

  return (
    <Page
      query={query}
      pageKey="search"
      chat={true}
      seoTitle={({ consumerSearch }) => {
        if (!consumerSearch || consumerSearch.__typename !== 'ConsumerSearchResultSellables') {
          return
        }

        return consumerSearch.sellablesPagination.pageMetadata?.pageTitle
      }}
      seoDescription={`Shop ${searchQuery.keywords?.toLowerCase() || 'products'} on Curated`}
      seoIndexable={false}
      expert={data =>
        data.departmentBySlug?.topLevelCategory?.recommendExpertForConsumer.expert || undefined
      }
      category={data => data.departmentBySlug?.topLevelCategory || undefined}
      department={data => data.departmentBySlug?.topLevelCategory?.department || undefined}
      sellable={undefined}
      pageComponent={PageComponent}
      loadingComponent={LoadingComponent}
      engagementChannels
      ogImageUrl={data => {
        const category = data.departmentBySlug?.topLevelCategory || data.productsCategory

        return category?.categoryAssetRevision?.openGraphImage?.image.url
      }}
    />
  )
}

export { SearchRoute }
