import React, { useContext } from 'react'
import { ChatWidgetStateChanged } from '@deal/web-tracking'
import { useSessionStorage } from '@deal/dom-hooks'

enum ChatWidgetState {
  MINIMIZED = 'minimized',
  PREVIEW = 'preview',
  EXPANDED = 'expanded'
}

const CHAT_WIDGET_STATE_SESSION_STORAGE_KEY = 'chat-widget-state-2'

type ChatWidgetStateContextType =
  | {
      isChatWidgetExpanded: boolean
      expandChatWidget: (trigger: string) => void
      isChatWidgetMinimized: boolean
      minimizeChatWidget: (trigger: string) => void
      isChatWidgetPreview: boolean
      previewChatWidget: (trigger: string) => void
    }
  | undefined

const ChatWidgetStateContext = React.createContext<ChatWidgetStateContextType>(undefined)

export const ChatWidgetStateProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [chatWidgetState, setChatWidgetState] = useSessionStorage<ChatWidgetState>(
    CHAT_WIDGET_STATE_SESSION_STORAGE_KEY,
    ChatWidgetState.MINIMIZED
  )

  const updateChatWidgetState = (newState: ChatWidgetState, trigger: string) => {
    window.tracking?.track(
      new ChatWidgetStateChanged({ previous_state: chatWidgetState, new_state: newState, trigger })
    )
    setChatWidgetState(newState)
  }

  return (
    <ChatWidgetStateContext.Provider
      value={{
        isChatWidgetExpanded: chatWidgetState === ChatWidgetState.EXPANDED,
        expandChatWidget: (trigger: string) =>
          updateChatWidgetState(ChatWidgetState.EXPANDED, trigger),
        isChatWidgetMinimized: chatWidgetState === ChatWidgetState.MINIMIZED,
        minimizeChatWidget: (trigger: string) =>
          updateChatWidgetState(ChatWidgetState.MINIMIZED, trigger),
        isChatWidgetPreview: chatWidgetState === ChatWidgetState.PREVIEW,
        previewChatWidget: (trigger: string) => {
          if (chatWidgetState !== ChatWidgetState.EXPANDED) {
            updateChatWidgetState(ChatWidgetState.PREVIEW, trigger)
          }
        }
      }}
    >
      {children}
    </ChatWidgetStateContext.Provider>
  )
}

export const useChatWidgetStateContext = () => {
  const context = useContext(ChatWidgetStateContext)

  if (!context) {
    throw new Error('Invoked ChatWidgetStateContext outside of provider')
  }

  return context
}
