import React, { AnchorHTMLAttributes } from 'react'
import { Link } from '@deal/router'
import { getSellableLinkRel, getSellableUrl } from '#src/app/services/sellable'
import { SellableForSellableLinkFragment } from './SellableLink.generated'

interface SellableLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  sellable: SellableForSellableLinkFragment
  linkRef?: string
  openNewTab?: boolean
  trackingRef?: string
}

const SellableLink: React.ForwardRefRenderFunction<
  HTMLAnchorElement,
  React.PropsWithChildren<SellableLinkProps>
> = ({ sellable, linkRef, openNewTab = false, rel, ...rest }, ref) => {
  if (openNewTab) {
    return (
      /* eslint-disable-next-line react/jsx-no-target-blank -- noreferrer is unnecessary and undesired for internal links */
      <Link
        to={getSellableUrl(sellable, { reference: linkRef })}
        target="_blank"
        rel={getSellableLinkRel(sellable) || rel}
        ref={ref}
        {...rest}
      />
    )
  }

  return (
    <Link
      to={getSellableUrl(sellable, { reference: linkRef })}
      rel={getSellableLinkRel(sellable) || rel}
      ref={ref}
      {...rest}
    />
  )
}

export default React.forwardRef(SellableLink)
