// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { MonetaryAmountFragmentDoc } from '../../fragments/MonetaryAmount.generated';
export type ExpertCuratedItemForFormatExpertCuratedItemPricingFragment = { __typename: 'ExpertCuratedItem', id: any, customizations: Array<{ __typename: 'ExpertCuratedItemCustomization', price: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } }>, priceOverride?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null, salePrice?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null, sellable: { __typename: 'Sellable', id: any, salePrice?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null, originalPrice?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null }, salePriceWithCustomizations?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null };

export type ExpertCuratedItemForIsSpecialOfferExpiredFragment = { __typename: 'ExpertCuratedItem', id: any, specialOfferValidUntil?: any | null };

export type ExpertCuratedItemForExpertCuratedItemsByCategoryHelperFragment = { __typename: 'ExpertCuratedItem', id: any, category: { __typename: 'Category', id: any } };

export type ExpertCuratedItemForGetMostRecentItemCreationDateFragment = { __typename: 'ExpertCuratedItem', id: any, createdAt: any, category: { __typename: 'Category', id: any } };

export type ExpertCuratedItemForGetExpertCuratedItemNotesFragment = { __typename: 'ExpertCuratedItem', id: any, note?: { __typename: 'ExpertCuratedItemNote', id: any, attributes: Array<{ __typename: 'Attribute', values: Array<{ __typename: 'AttributeValueDate' } | { __typename: 'AttributeValueDateRange' } | { __typename: 'AttributeValueDateTime' } | { __typename: 'AttributeValueDateTimeRange' } | { __typename: 'AttributeValueDecimal' } | { __typename: 'AttributeValueDecimalRange' } | { __typename: 'AttributeValueEnumeration' } | { __typename: 'AttributeValueId' } | { __typename: 'AttributeValueInteger' } | { __typename: 'AttributeValueIntegerRange' } | { __typename: 'AttributeValueText', text: string } | { __typename: 'AttributeValueTime' } | { __typename: 'AttributeValueTimeRange' } | { __typename: 'AttributeValueZoneId' }>, schema: { __typename: 'AttributeSchemaRevision', id: any, name: string } }> } | null };

export const ExpertCuratedItemForFormatExpertCuratedItemPricingFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ExpertCuratedItemForFormatExpertCuratedItemPricing"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ExpertCuratedItem"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"customizations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"price"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MonetaryAmount"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"priceOverride"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MonetaryAmount"}}]}},{"kind":"Field","name":{"kind":"Name","value":"salePrice"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MonetaryAmount"}}]}},{"kind":"Field","name":{"kind":"Name","value":"sellable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"salePrice"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MonetaryAmount"}}]}},{"kind":"Field","name":{"kind":"Name","value":"originalPrice"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MonetaryAmount"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"salePriceWithCustomizations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MonetaryAmount"}}]}}]}}]} as unknown as DocumentNode;
export const ExpertCuratedItemForIsSpecialOfferExpiredFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ExpertCuratedItemForIsSpecialOfferExpired"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ExpertCuratedItem"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"specialOfferValidUntil"}}]}}]} as unknown as DocumentNode;
export const ExpertCuratedItemForExpertCuratedItemsByCategoryHelperFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ExpertCuratedItemForExpertCuratedItemsByCategoryHelper"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ExpertCuratedItem"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"category"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export const ExpertCuratedItemForGetMostRecentItemCreationDateFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ExpertCuratedItemForGetMostRecentItemCreationDate"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ExpertCuratedItem"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"ExpertCuratedItemForExpertCuratedItemsByCategoryHelper"}}]}}]} as unknown as DocumentNode;
export const ExpertCuratedItemForGetExpertCuratedItemNotesFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ExpertCuratedItemForGetExpertCuratedItemNotes"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ExpertCuratedItem"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"note"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"attributes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"values"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AttributeValueText"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"text"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"schema"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]}}]} as unknown as DocumentNode;