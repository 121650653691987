// @ts-nocheck
import type * as Types from '../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { SellableForSellableLinkFragmentDoc } from '../../../../../components/SellableLink/SellableLink.generated';
import { SellableForGetSellableLinkRelFragmentDoc, SellableForIsSellablePurchasableFragmentDoc } from '../../../../../services/sellable/Sellable.generated';
import { SellableUrlFragmentDoc } from '../../../../../fragments/SellableUrl.generated';
import { SellableForFormatSellablePricingFragmentDoc } from '../../../../../fragments/SellableForFormatSellablePricing.generated';
import { MonetaryAmountFragmentDoc } from '../../../../../fragments/MonetaryAmount.generated';
import { SellableForNormalizeSellablePricingFragmentDoc } from '../../../../../fragments/SellableForNormalizeSellablePricing.generated';
export type SellableForExpertProductReviewPageSellableFragment = { __typename: 'Sellable', id: any, title: string, bestOfferVisibility: Types.SellableBestOfferVisibility, active: boolean, adHoc: boolean, friendlyId?: string | null, slug: string, type: Types.SellableType, primaryImage?: { __typename: 'MediaFile', id: any, url: string } | null, productBundle?: { __typename: 'ProductBundleRevision', id: any, salePriceRange?: { __typename: 'MonetaryAmountRange', from?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null, to?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null } | null } | null, bestOfferPrice?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null, salePrice?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null, originalPrice?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null, variationMatrix?: { __typename: 'VariationMatrix', maximumSavingsPercent?: number | null, priceRange?: { __typename: 'VariationMatrixPriceRange', min: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string }, max: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } } | null, originalPriceRange?: { __typename: 'VariationMatrixPriceRange', min: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string }, max: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } } | null, maximumSavingsAmount?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null } | null };

export const SellableForExpertProductReviewPageSellableFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableForExpertProductReviewPageSellable"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Sellable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"primaryImage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"url"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForSellableLink"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForFormatSellablePricing"}}]}}]} as unknown as DocumentNode;