import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import { Notification } from '@deal/components'
import { AuthenticationResult, ChallengeType, HandleType } from '#src/generated/types'
import { AuthenticatedUserFragment } from '#src/app/fragments/AuthenticatedUser.generated'
import { useInitiateAuthenticationMutation } from '../../../InitiateAuthentication.generated'
import { AuthenticationStateFragment } from '../../../AuthenticationState.generated'
import { useAuthenticateMutation } from '../../../Authenticate.generated'
import SixDigitCodeField from '../../SixDigitCodeField'
import handleTypeFromHandle from '../../../util/handleTypeFromHandle'
import styles from './styles.css'

interface SixDigitCodeFormProps {
  authenticationSessionState: AuthenticationStateFragment
  onLogin: (user: AuthenticatedUserFragment) => void
}

export const SixDigitCodeForm: React.FC<SixDigitCodeFormProps> = ({
  authenticationSessionState,
  onLogin
}) => {
  const formRef = useRef<HTMLFormElement>(null)
  const [authenticate, authenticateResult] = useAuthenticateMutation()
  const [oneTimePassword, setOneTimePassword] = useState('')
  const [initiateAuthentication, initiateAuthenticationResult] = useInitiateAuthenticationMutation()
  const [oneTimePasswordResentRecently, setOneTimePasswordResentRecently] = useState(false)

  const handle = authenticationSessionState.authenticationState.handle
  /**
   * Submit the one-time-password dynamically (i.e. without using the browsers built-in
   *   form submission functionality). This is called when the final digit has been
   *   entered, but the React state may not have updated yet.
   */
  const authenticateWithOneTimePassword = (oneTimePassword: string) => {
    if (!authenticationSessionState) {
      return
    }

    const handleType = handle ? handleTypeFromHandle(handle) : undefined

    authenticate({
      variables: {
        input: {
          challenge: oneTimePassword,
          handle: handle,
          handleId: authenticationSessionState.authenticationState.handleId,
          handleType: handleType
        }
      }
    }).then(result => {
      if (result.data && result.data.authenticate.result === AuthenticationResult.SUCCESS) {
        onLogin(result.data.authenticate.user!)
      }
    })
  }

  /**
   * Handle standard browser form submission (submitting the value in state and preventing
   *   the form from submitting over HTTP).
   */
  const onFormSubmit: React.FormEventHandler = e => {
    if (e) {
      e.preventDefault()
    }

    authenticateWithOneTimePassword(oneTimePassword)
  }

  /**
   * Allow the user to request that the code be sent again.
   */
  const handleResendCode = (handle: string) => {
    setOneTimePassword('')
    initiateAuthentication({
      variables: {
        input: {
          handle: handle,
          handleType: handleTypeFromHandle(handle),
          captcha: ''
        }
      }
    }).then(result => {
      if (result.data && result.data.initiateAuthentication.challengeType === ChallengeType.OTP) {
        setOneTimePasswordResentRecently(true)

        setTimeout(() => {
          setOneTimePasswordResentRecently(false)
        }, 1500)
      }
    })
  }

  // Display a useful error message if the user enters an incorrect password
  const authenticationError =
    authenticateResult.data?.authenticate.result === AuthenticationResult.FAILURE
      ? 'Code is incorrect or expired. Please double check!'
      : undefined

  return (
    <form className={styles.oneTimePassword} onSubmit={onFormSubmit} ref={formRef}>
      {authenticationError && (
        <Notification type="error" className={styles.error}>
          {authenticationError}
        </Notification>
      )}
      <SixDigitCodeField
        className={styles.sixDigitCodeField}
        value={oneTimePassword}
        onChange={(value: string) => setOneTimePassword(value)}
        onSubmit={(value: string) => authenticateWithOneTimePassword(value)}
        disabled={authenticateResult.loading}
      />

      {/* We should always have a handle, this check is just for type-safety */}
      {handle && (
        <div className={styles.resend}>
          {oneTimePasswordResentRecently ? (
            <>
              Check your {handleTypeFromHandle(handle) === HandleType.PHONE ? 'phone' : 'email'}.
              We've sent another code.
            </>
          ) : (
            <>
              Didn't receive a code?{' '}
              <a
                href="#"
                onClick={() => handleResendCode(handle)}
                className={classNames(styles.resendLink, {
                  [styles.resendLinkInProgress]: initiateAuthenticationResult.loading
                })}
              >
                Re-send
              </a>
            </>
          )}
        </div>
      )}
    </form>
  )
}
