// @ts-nocheck
import type * as Types from '../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type IssueVerificationCodeMutationVariables = Types.Exact<{
  input: Types.InitiateVerifyHandleInput;
}>;


export type IssueVerificationCodeMutation = { __typename: 'Mutation', initiateVerifyHandle: { __typename: 'InitiateVerifyHandlePayload', success: boolean } };

export type ConfirmVerificationCodeMutationVariables = Types.Exact<{
  input: Types.VerifyHandleChallengeInput;
}>;


export type ConfirmVerificationCodeMutation = { __typename: 'Mutation', verifyHandleChallenge: { __typename: 'VerifyHandleChallengePayload', message?: string | null, success: boolean, handleVerificationToken?: string | null } };

export type ClaimPromotionPayloadForUseOnSiteSmsModalTemplateFragment = { __typename: 'ClaimPromotionPayload', errorMessage?: string | null, promotion?: { __typename: 'Promotion', id: any, code: string, expiresAt?: any | null } | null };

export type ContactUsedQueryVariables = Types.Exact<{
  type: Types.ContactType;
  value: Types.Scalars['String']['input'];
}>;


export type ContactUsedQuery = { __typename: 'Query', contactUsed: boolean };

export type CategoryForUseOnSiteSmsModalTemplateQueryVariables = Types.Exact<{
  categoryId: Types.Scalars['CategoryId']['input'];
}>;


export type CategoryForUseOnSiteSmsModalTemplateQuery = { __typename: 'Query', category: { __typename: 'Category', shortSingularDisplayName: string, id: any, slug: string, department?: { __typename: 'Department', id: any, slug: string } | null } };

export type CategoryForGetQuestionForOnSiteSmsModalTemplateFragment = { __typename: 'Category', id: any, slug: string, department?: { __typename: 'Department', id: any, slug: string } | null };

export const ClaimPromotionPayloadForUseOnSiteSmsModalTemplateFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ClaimPromotionPayloadForUseOnSiteSMSModalTemplate"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ClaimPromotionPayload"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"errorMessage"}},{"kind":"Field","name":{"kind":"Name","value":"promotion"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"expiresAt"}}]}}]}}]} as unknown as DocumentNode;
export const CategoryForGetQuestionForOnSiteSmsModalTemplateFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CategoryForGetQuestionForOnSiteSMSModalTemplate"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Category"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}},{"kind":"Field","name":{"kind":"Name","value":"department"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}}]}}]}}]} as unknown as DocumentNode;
export const IssueVerificationCodeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"IssueVerificationCode"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"InitiateVerifyHandleInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"initiateVerifyHandle"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode;
export type IssueVerificationCodeMutationFn = Apollo.MutationFunction<IssueVerificationCodeMutation, IssueVerificationCodeMutationVariables>;
export type IssueVerificationCodeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<IssueVerificationCodeMutation, IssueVerificationCodeMutationVariables>, 'mutation'>;

    export const IssueVerificationCodeComponent = (props: IssueVerificationCodeComponentProps) => (
      <ApolloReactComponents.Mutation<IssueVerificationCodeMutation, IssueVerificationCodeMutationVariables> mutation={IssueVerificationCodeDocument} {...props} />
    );
    

/**
 * __useIssueVerificationCodeMutation__
 *
 * To run a mutation, you first call `useIssueVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueVerificationCodeMutation, { data, loading, error }] = useIssueVerificationCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIssueVerificationCodeMutation(baseOptions?: Apollo.MutationHookOptions<IssueVerificationCodeMutation, IssueVerificationCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssueVerificationCodeMutation, IssueVerificationCodeMutationVariables>(IssueVerificationCodeDocument, options);
      }
export type IssueVerificationCodeMutationHookResult = ReturnType<typeof useIssueVerificationCodeMutation>;
export type IssueVerificationCodeMutationResult = Apollo.MutationResult<IssueVerificationCodeMutation>;
export type IssueVerificationCodeMutationOptions = Apollo.BaseMutationOptions<IssueVerificationCodeMutation, IssueVerificationCodeMutationVariables>;
export const ConfirmVerificationCodeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ConfirmVerificationCode"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"VerifyHandleChallengeInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"verifyHandleChallenge"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"handleVerificationToken"}}]}}]}}]} as unknown as DocumentNode;
export type ConfirmVerificationCodeMutationFn = Apollo.MutationFunction<ConfirmVerificationCodeMutation, ConfirmVerificationCodeMutationVariables>;
export type ConfirmVerificationCodeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ConfirmVerificationCodeMutation, ConfirmVerificationCodeMutationVariables>, 'mutation'>;

    export const ConfirmVerificationCodeComponent = (props: ConfirmVerificationCodeComponentProps) => (
      <ApolloReactComponents.Mutation<ConfirmVerificationCodeMutation, ConfirmVerificationCodeMutationVariables> mutation={ConfirmVerificationCodeDocument} {...props} />
    );
    

/**
 * __useConfirmVerificationCodeMutation__
 *
 * To run a mutation, you first call `useConfirmVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmVerificationCodeMutation, { data, loading, error }] = useConfirmVerificationCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmVerificationCodeMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmVerificationCodeMutation, ConfirmVerificationCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmVerificationCodeMutation, ConfirmVerificationCodeMutationVariables>(ConfirmVerificationCodeDocument, options);
      }
export type ConfirmVerificationCodeMutationHookResult = ReturnType<typeof useConfirmVerificationCodeMutation>;
export type ConfirmVerificationCodeMutationResult = Apollo.MutationResult<ConfirmVerificationCodeMutation>;
export type ConfirmVerificationCodeMutationOptions = Apollo.BaseMutationOptions<ConfirmVerificationCodeMutation, ConfirmVerificationCodeMutationVariables>;
export const ContactUsedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ContactUsed"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"type"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ContactType"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"value"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"contactUsed"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"type"},"value":{"kind":"Variable","name":{"kind":"Name","value":"type"}}},{"kind":"Argument","name":{"kind":"Name","value":"value"},"value":{"kind":"Variable","name":{"kind":"Name","value":"value"}}}]}]}}]} as unknown as DocumentNode;
export type ContactUsedComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ContactUsedQuery, ContactUsedQueryVariables>, 'query'> & ({ variables: ContactUsedQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ContactUsedComponent = (props: ContactUsedComponentProps) => (
      <ApolloReactComponents.Query<ContactUsedQuery, ContactUsedQueryVariables> query={ContactUsedDocument} {...props} />
    );
    

/**
 * __useContactUsedQuery__
 *
 * To run a query within a React component, call `useContactUsedQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactUsedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactUsedQuery({
 *   variables: {
 *      type: // value for 'type'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useContactUsedQuery(baseOptions: Apollo.QueryHookOptions<ContactUsedQuery, ContactUsedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactUsedQuery, ContactUsedQueryVariables>(ContactUsedDocument, options);
      }
export function useContactUsedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactUsedQuery, ContactUsedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactUsedQuery, ContactUsedQueryVariables>(ContactUsedDocument, options);
        }
export function useContactUsedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ContactUsedQuery, ContactUsedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ContactUsedQuery, ContactUsedQueryVariables>(ContactUsedDocument, options);
        }
export type ContactUsedQueryHookResult = ReturnType<typeof useContactUsedQuery>;
export type ContactUsedLazyQueryHookResult = ReturnType<typeof useContactUsedLazyQuery>;
export type ContactUsedSuspenseQueryHookResult = ReturnType<typeof useContactUsedSuspenseQuery>;
export type ContactUsedQueryResult = Apollo.QueryResult<ContactUsedQuery, ContactUsedQueryVariables>;
export const CategoryForUseOnSiteSmsModalTemplateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CategoryForUseOnSiteSMSModalTemplate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"categoryId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CategoryId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"category"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"categoryId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"categoryId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"shortSingularDisplayName"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"CategoryForGetQuestionForOnSiteSMSModalTemplate"}}]}}]}},...CategoryForGetQuestionForOnSiteSmsModalTemplateFragmentDoc.definitions]} as unknown as DocumentNode;
export type CategoryForUseOnSiteSmsModalTemplateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CategoryForUseOnSiteSmsModalTemplateQuery, CategoryForUseOnSiteSmsModalTemplateQueryVariables>, 'query'> & ({ variables: CategoryForUseOnSiteSmsModalTemplateQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CategoryForUseOnSiteSmsModalTemplateComponent = (props: CategoryForUseOnSiteSmsModalTemplateComponentProps) => (
      <ApolloReactComponents.Query<CategoryForUseOnSiteSmsModalTemplateQuery, CategoryForUseOnSiteSmsModalTemplateQueryVariables> query={CategoryForUseOnSiteSmsModalTemplateDocument} {...props} />
    );
    

/**
 * __useCategoryForUseOnSiteSmsModalTemplateQuery__
 *
 * To run a query within a React component, call `useCategoryForUseOnSiteSmsModalTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryForUseOnSiteSmsModalTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryForUseOnSiteSmsModalTemplateQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useCategoryForUseOnSiteSmsModalTemplateQuery(baseOptions: Apollo.QueryHookOptions<CategoryForUseOnSiteSmsModalTemplateQuery, CategoryForUseOnSiteSmsModalTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryForUseOnSiteSmsModalTemplateQuery, CategoryForUseOnSiteSmsModalTemplateQueryVariables>(CategoryForUseOnSiteSmsModalTemplateDocument, options);
      }
export function useCategoryForUseOnSiteSmsModalTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryForUseOnSiteSmsModalTemplateQuery, CategoryForUseOnSiteSmsModalTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryForUseOnSiteSmsModalTemplateQuery, CategoryForUseOnSiteSmsModalTemplateQueryVariables>(CategoryForUseOnSiteSmsModalTemplateDocument, options);
        }
export function useCategoryForUseOnSiteSmsModalTemplateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CategoryForUseOnSiteSmsModalTemplateQuery, CategoryForUseOnSiteSmsModalTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CategoryForUseOnSiteSmsModalTemplateQuery, CategoryForUseOnSiteSmsModalTemplateQueryVariables>(CategoryForUseOnSiteSmsModalTemplateDocument, options);
        }
export type CategoryForUseOnSiteSmsModalTemplateQueryHookResult = ReturnType<typeof useCategoryForUseOnSiteSmsModalTemplateQuery>;
export type CategoryForUseOnSiteSmsModalTemplateLazyQueryHookResult = ReturnType<typeof useCategoryForUseOnSiteSmsModalTemplateLazyQuery>;
export type CategoryForUseOnSiteSmsModalTemplateSuspenseQueryHookResult = ReturnType<typeof useCategoryForUseOnSiteSmsModalTemplateSuspenseQuery>;
export type CategoryForUseOnSiteSmsModalTemplateQueryResult = Apollo.QueryResult<CategoryForUseOnSiteSmsModalTemplateQuery, CategoryForUseOnSiteSmsModalTemplateQueryVariables>;