import React, { useEffect, useRef, useState } from 'react'

type TClientOnly<P> = React.FC<P extends React.PropsWithChildren ? P : React.PropsWithChildren<P>>

export default function clientOnly<P = {}>(
  InnerComponent: React.ComponentType<P>,
  options: { fallback: React.ComponentType<P> } = { fallback: () => null }
): TClientOnly<P> {
  const WrapperComponent: TClientOnly<P> = props => {
    const Fallback = useRef(options.fallback).current
    const [isMounted, setIsMounted] = useState(false)
    useEffect(() => setIsMounted(true), [])
    return isMounted ? <InnerComponent {...props} /> : <Fallback {...props} />
  }
  WrapperComponent.displayName = `ClientOnly${InnerComponent.displayName || 'Component'}`
  return WrapperComponent
}
