// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { SellableForImageVectorFragmentDoc } from './ImageVectorSelector/ImageVectorSelector.generated';
import { MediaFileFragmentDoc } from '../../../fragments/MediaFile.generated';
export type VariationMatrixForButtonMatrixFragment = { __typename: 'VariationMatrix', dimensions: Array<{ __typename: 'VariationMatrixDimension', name: string, displayName?: string | null, vectors: Array<{ __typename: 'VariationMatrixVector', value: string, displayValueHtml: string, sellableIds: Array<any> }> }>, sellables: Array<{ __typename: 'Sellable', id: any, primaryImage?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null }>, outOfStockSellables: Array<{ __typename: 'Sellable', id: any, availableForPreOrder: boolean }> };

export type VariationMatrixDimensionForButtonMatrixDimensionFragment = { __typename: 'VariationMatrixDimension', name: string, displayName?: string | null, vectors: Array<{ __typename: 'VariationMatrixVector', value: string, displayValueHtml: string, sellableIds: Array<any> }> };

export type SellableForButtonMatrixFragment = { __typename: 'Sellable', id: any, brand?: { __typename: 'Brand', id: any, name: string, slug: string } | null };

export type SellableSizeGuideForButtonMatrixFragment = { __typename: 'ArticleRevision', id: any, friendlyId: string, slug: string };

export const VariationMatrixDimensionForButtonMatrixDimensionFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"VariationMatrixDimensionForButtonMatrixDimension"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"VariationMatrixDimension"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"vectors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"displayValueHtml"}},{"kind":"Field","name":{"kind":"Name","value":"sellableIds"}}]}}]}}]} as unknown as DocumentNode;
export const VariationMatrixForButtonMatrixFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"VariationMatrixForButtonMatrix"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"VariationMatrix"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dimensions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"VariationMatrixDimensionForButtonMatrixDimension"}}]}},{"kind":"Field","name":{"kind":"Name","value":"sellables"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForImageVector"}}]}},{"kind":"Field","name":{"kind":"Name","value":"outOfStockSellables"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"availableForPreOrder"}}]}}]}}]} as unknown as DocumentNode;
export const SellableForButtonMatrixFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableForButtonMatrix"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Sellable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"brand"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}}]}}]}}]} as unknown as DocumentNode;
export const SellableSizeGuideForButtonMatrixFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableSizeGuideForButtonMatrix"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ArticleRevision"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"friendlyId"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}}]}}]} as unknown as DocumentNode;