import React, { useContext, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { UserManager, UserManagerSettings } from 'oidc-client'
import classnames from 'classnames'
import { Text } from '@deal/bluxome'
import { OAuthType } from '#src/generated/types'
import { isBusinessMobileNative, isConsumerMobileNative } from '#src/app/services/mobile'
import { GoogleSigninResponse } from '#src/app/services/dealNativeBridge'
import { WebMessageEventEmitterContext } from '#src/app/containers/ReceiveMessageFromNative'
import config from '#src/app/config'
import { useSharedAuthenticateMutations } from '#src/app/components/OAuthLogin'
import { useRegisterCustomerMutation } from '../../../OAuthRegister/RegisterCustomer.generated'
import { AuthenticatedUserFragment } from '../../../../fragments/AuthenticatedUser.generated'
import { OAuthButtonComponentProps } from '../../util/oauthButtonProps'
import GoogleIconMark from './google-iconmark.svg'
import styles from './styles.css'

interface GoogleOAuthButtonProps extends OAuthButtonComponentProps {
  className?: string
  wrapperClassName?: string
  referralCodeId?: string | null
  referralRewardCreditClaimToken?: string | null
  registrationId?: string | null
  onSuccess: (user: AuthenticatedUserFragment) => void
  onFailure: (error: string) => void
  isRebranded?: boolean
}

const GoogleOAuthButton: React.FC<GoogleOAuthButtonProps> = ({
  className,
  wrapperClassName,
  referralCodeId,
  referralRewardCreditClaimToken,
  registrationId,
  onSuccess,
  onFailure,
  isRebranded = false,
  buttonComponent: ButtonComponent
}) => {
  const [registerCustomer] = useRegisterCustomerMutation()
  const {
    isLoading,
    handleAuthenticateByOAuthToken,
    handleUpdateIncompleteUserProfileBeforeAuthenticating,
    isIncompleteUser
  } = useSharedAuthenticateMutations()
  const eventEmitter = useContext(WebMessageEventEmitterContext)
  const [userManager] = useState(() => initUserManager())

  const handleSuccess = ({
    firstName,
    lastName,
    email,
    idToken
  }: {
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    idToken: string
  }) => {
    const options = {
      accessToken: idToken,
      oauthType: OAuthType.GOOGLE,
      firstName: firstName || undefined,
      lastName: lastName || undefined,
      email: email || undefined,
      onSuccess,
      onFailure
    }
    if (isBusinessMobileNative()) {
      // For expert native app, do not allow registering users
      // just try to authenticate the
      handleAuthenticateByOAuthToken(options)
    } else if (isIncompleteUser()) {
      // If there's a partial identity (i.e., for scout chat),
      // update the user profile before authenticating with accessToken
      handleUpdateIncompleteUserProfileBeforeAuthenticating(options)
    } else {
      registerCustomer({
        variables: {
          input: {
            firstName,
            lastName,
            email,
            externalOAuthType: OAuthType.GOOGLE,
            externalOAuthToken: idToken,
            referralCodeId,
            referralRewardCreditClaimToken,
            registrationId
          }
        }
      }).then(response => {
        const errors = response.errors

        // Handle failures at the GraphQL level
        if (errors) {
          onFailure('Google authentication failed!')
          return
        }

        const payload = response.data!.registerCustomer

        if (payload.user) {
          onSuccess(payload.user)
        } else {
          onFailure(
            (payload.errors && payload.errors.join('\n')) || 'Google authentication failed!'
          )
        }
      })
    }
  }

  const handleWebFailure = (failure: Error) => {
    switch (failure.message) {
      case 'Popup window closed':
        break

      default:
        onFailure('Google authentication failed!')
        break
    }
  }

  /**
   * Handle failures from the Google OAuth flow itself.
   */
  const handleFailure = (failure: { error: string }) => {
    switch (failure.error) {
      case 'popup_closed_by_user':
        break
      case 'access_denied':
      case 'immediate_failed':
      default:
        onFailure('Google authentication failed!')
    }
  }

  const handleNativeClick = (e?: React.MouseEvent<HTMLDivElement>) => {
    e?.preventDefault()
    eventEmitter!
      .requestResponse<GoogleSigninResponse>(
        {
          type: 'googleSigninRequest',
          messageId: uuidv4()
        },
        'googleSigninResponse'
      )
      .then(message => {
        if (message.success) {
          handleSuccess({
            idToken: message.success.idToken,
            firstName: message.success.user.givenName,
            lastName: message.success.user.familyName,
            email: message.success.user.email
          })
        } else {
          handleFailure({ error: message.failureCode!.toString() })
        }
      })
  }

  const handleClick = () => {
    if (userManager.signinPopup) {
      const height = 640
      const width = [800, 720, 600, 480].find(width => width <= window.outerWidth / 1.618) ?? 360
      const left = Math.max(0, Math.round(window.screenX + (window.outerWidth - width) / 2))
      const top = Math.max(0, Math.round(window.screenY + (window.outerHeight - height) / 2))
      userManager
        .signinPopup({
          popupWindowFeatures: `location=no,toolbar=no,height=${height},width=${width},top=${top},left=${left}`
        })
        .then(user => {
          handleSuccess({
            firstName: user.profile.given_name,
            lastName: user.profile.family_name,
            email: user.profile.email,
            idToken: user.id_token!
          })
        }, handleWebFailure)
    }
  }

  if ((isConsumerMobileNative() || isBusinessMobileNative()) && eventEmitter) {
    if (ButtonComponent) {
      return <ButtonComponent onPress={() => handleNativeClick()} isLoading={isLoading} />
    }

    return (
      <div className={classnames(styles.wrapper, wrapperClassName)}>
        <div
          className={classnames(styles.button, className, { [styles.rebranded]: isRebranded })}
          onClick={handleNativeClick}
        >
          <GoogleIconMark className={styles.logo} />{' '}
          {isRebranded ? (
            <Text style="base-medium">Continue with Google</Text>
          ) : (
            'Continue with Google'
          )}
        </div>
      </div>
    )
  } else {
    if (ButtonComponent) {
      return <ButtonComponent onPress={handleClick} isLoading={isLoading} />
    }

    return (
      <div className={classnames(styles.wrapper, wrapperClassName)}>
        <div
          className={classnames(styles.button, className, { [styles.rebranded]: isRebranded })}
          onClick={handleClick}
        >
          <GoogleIconMark className={styles.logo} />{' '}
          {isRebranded ? (
            <Text style="base-medium">Continue with Google</Text>
          ) : (
            'Continue with Google'
          )}
        </div>
      </div>
    )
  }
}

function initUserManager(): Partial<UserManager> {
  let redirectUri: URL | undefined
  try {
    redirectUri = new URL('/auth/oidc', window.location.href)
  } catch (e) {
    /* empty */
  }
  const settings: UserManagerSettings = {
    authority: 'https://accounts.google.com',
    client_id: config.get('google.client_id'),
    redirect_uri: redirectUri?.toString(),
    response_type: 'id_token',
    scope: 'email profile openid',
    automaticSilentRenew: false
  }
  return process.env.TARGET === 'node' ? { settings } : new UserManager(settings)
}

export default GoogleOAuthButton
