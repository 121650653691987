import React from 'react'
import { Area, Grid, Heading } from '@deal/bluxome'
import { Skeleton } from '#src/app/components/SkeletonLoader'
import styles from './styles.css'

const CurationPageSkeleton: React.FC = () => {
  const areas = `
    "toc toc toc toc toc toc"
    "i i i i i i"
    `
  const areasLg = `
    "i i i i i i i i i toc toc toc"
    `

  return (
    <>
      <div className={styles.navHeader}>
        <Grid>
          <Area column="span 6">
            <div className={styles.header}>
              <Heading size="h1">My curations</Heading>
            </div>
          </Area>
        </Grid>
      </div>
      <Grid areas={areas} areasLg={areasLg}>
        <Area name="toc">
          <Skeleton height={56} marginBottom={16} />
          <Skeleton height={56} marginBottom={24} />
        </Area>
        <Area name="i">
          <Skeleton height={31} marginBottom={8} />
          <Skeleton height={21} marginBottom={24} />
          <div className={styles.card}>
            <Skeleton className={styles.image} />
            <div className={styles.texts}>
              <Skeleton height={26} marginBottom={4} />
              <Skeleton height={18} marginBottom={4} />
              <Skeleton height={20} marginBottom={24} />
              <Skeleton height={200} marginBottom={24} />
            </div>
          </div>
          <div className={styles.card}>
            <Skeleton className={styles.image} />
            <div className={styles.texts}>
              <Skeleton height={26} marginBottom={4} />
              <Skeleton height={18} marginBottom={4} />
              <Skeleton height={20} marginBottom={24} />
              <Skeleton height={200} marginBottom={24} />
            </div>
          </div>
        </Area>
      </Grid>
    </>
  )
}

export { CurationPageSkeleton }
