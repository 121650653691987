import React from 'react'
import { AuthenticatedUserFragment } from '../../../../fragments/AuthenticatedUser.generated'
import { AuthIntent, AuthenticationFlowViewLayoutProps } from '../../index'
import LoginChallengeOneTimePasswordViewLegacy from './v1'
import { LoginChallengeOneTimePassword as LoginChallengeOneTimePasswordRebranded } from './rebranded'

interface LoginChallengeOneTimePasswordViewProps extends AuthenticationFlowViewLayoutProps {
  onLogin: (user: AuthenticatedUserFragment) => void
  onIntentChanged: (intent: AuthIntent) => void
  isRebranded?: boolean
}

const LoginChallengeOneTimePasswordView: React.FC<
  React.PropsWithChildren<LoginChallengeOneTimePasswordViewProps>
> = ({ isRebranded, ...props }) => {
  if (isRebranded) {
    return <LoginChallengeOneTimePasswordRebranded {...props} />
  }
  return <LoginChallengeOneTimePasswordViewLegacy {...props} />
}

export default LoginChallengeOneTimePasswordView
