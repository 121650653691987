import { PopoverTriggerComponentsProps } from '@deal/bluxome'
import { OrderLineItemSourceType, SellableBestOfferVisibility } from '#src/generated/types'
import { isSellablePurchasable } from '#src/app/services/sellable'
import { formatMonetaryAmountAsJsx } from '#src/app/services/money'
import { formatExpertCuratedItemPricing } from '#src/app/services/expertCuratedItem'
import {
  useAddExpertCuratedItemToCart,
  useBulkAddSellablesToCart,
  useCart
} from '#src/app/context/cart'
import { useIdentityContext } from '#src/app/containers/Identity'
import {
  ExpertCuratedItemForUseAddToCartButtonPropsFragment,
  SellableForUseAddToCartButtonPropsFragment
} from './UseAddToCartButtonProps.generated'
import CartIcon from './cart.svg'

interface AddToCartButtonProps {
  sellables: SellableForUseAddToCartButtonPropsFragment[]
  sellableLoading: boolean
  expertCuratedItem?: ExpertCuratedItemForUseAddToCartButtonPropsFragment
  onAddToCart?: () => void
  onAddToCartError?: (errorMessage: string) => void
  onParentAddToCart?: () => void
  disabled: boolean
  parentPopoverTriggerProps?: PopoverTriggerComponentsProps['triggerProps']
  addAsTrial: boolean
  isOpenBox: boolean
  source: string
}

/**
 *
 * This AddToCartButton can handle 3 potential cases:
 * 1. A single sellable being added to the cart
 * 2. Multiple sellables being added to the cart
 * 3. A specific curated item being added to the cart
 */
export const useAddToCartButtonProps = ({
  sellables,
  expertCuratedItem,
  addAsTrial = false,
  isOpenBox = false,
  onAddToCart,
  onParentAddToCart,
  onAddToCartError,
  disabled,
  sellableLoading,
  parentPopoverTriggerProps,
  source
}: AddToCartButtonProps) => {
  const { myself } = useIdentityContext()
  const { cart } = useCart('CART_VIEW')
  const [bulkAddSellablesToCart, { loading: addToCartLoading }] = useBulkAddSellablesToCart(source)
  const [addExpertCuratedItemToCart, { loading: addExpertCuratedItemToCartLoading }] =
    useAddExpertCuratedItemToCart()
  const sellablesArePurchasable = sellables.every(sellable => isSellablePurchasable(sellable))
  const multipleSellables = sellables.length > 1
  const isParent = !!sellables.at(0)?.variantParent
  const recentExpertCuratedItem = sellables.at(0)?.recentExpertCuratedItem
  const formattedCuratedItemPrice =
    recentExpertCuratedItem &&
    formatExpertCuratedItemPricing({
      curatedItem: recentExpertCuratedItem
    })?.price
  const trialPrice = sellables.at(0)?.trialPrice
  const trialPriceOverride = sellables.find(
    sellable => sellable.recentExpertCuratedItem?.trialPriceOverride
  )?.recentExpertCuratedItem?.trialPriceOverride
  const formattedTrialPrice = trialPrice
    ? formatMonetaryAmountAsJsx(trialPriceOverride ?? trialPrice)
    : undefined
  const bestOfferPrice = sellables.at(0)?.bestOfferPrice
  const formattedBestOfferPrice = bestOfferPrice
    ? formatMonetaryAmountAsJsx(bestOfferPrice)
    : undefined

  const isParentAddToCartOn = !multipleSellables && isParent && onParentAddToCart

  const { allAvailableForPreOrder, allVariantsAvaialbleForPreOrder } = sellables.reduce(
    (acc, cur) => ({
      allAvailableForPreOrder: acc.allAvailableForPreOrder && cur.availableForPreOrder,
      allVariantsAvaialbleForPreOrder:
        acc.allVariantsAvaialbleForPreOrder && cur.areAllVariantsAvailableForPreOrder
    }),
    {
      allAvailableForPreOrder: true,
      allVariantsAvaialbleForPreOrder: true
    }
  )

  const showPreOrderButtonText =
    allAvailableForPreOrder && (!isParent || allVariantsAvaialbleForPreOrder)

  const isAddToCartToSeePrice = sellables.some(
    sellable => sellable.bestOfferVisibility === SellableBestOfferVisibility.CONSUMER_ADD_TO_CART
  )

  // check if the item or sellable being added to the cart is valid, based on any
  // conflicts between trial and non-trial items.
  const isLoggedInCartEmpty = cart?.itemsCount === 0
  const areTrailItemsInLoggedInCart = cart?.lineItems.some(
    item => item.source.type === OrderLineItemSourceType.TRIAL_FEE
  )

  // if user is not logged in, it's always valid since trial items are not offered to guests
  const isItemCompatibleWithCart =
    !myself ||
    isLoggedInCartEmpty ||
    (addAsTrial && areTrailItemsInLoggedInCart) ||
    (!addAsTrial && !areTrailItemsInLoggedInCart)

  // max three trial items allowed in a single cart
  const isTrialLimitReached =
    areTrailItemsInLoggedInCart && cart?.itemsCount && cart.itemsCount >= 3

  const isCuratedItemOfferedAsTrial =
    !!recentExpertCuratedItem?.offeredAsTrial &&
    recentExpertCuratedItem.sellable.eligibleForTrial &&
    recentExpertCuratedItem.sellable.id === sellables.at(0)?.id

  /**
   * DEFINE BUTTON PROPS
   */

  const isLoading = addToCartLoading || addExpertCuratedItemToCartLoading || sellableLoading

  /**
   * Disabled logic for variant parent:
   *  - Logged In
   *     - Button is disabled, require selecting variation
   *  - Logged Out and Logged In SlideHeader
   *     - Button is active, opens variation matrix menu
   *
   * isParentAddToCartOn will be false for multiple sellables or if onParentAddToCart is undefined
   */
  const isDisabled =
    disabled || isLoading || (isParent && !isParentAddToCartOn) || !sellablesArePurchasable

  const buttonText =
    isParentAddToCartOn && isParent
      ? 'Select options'
      : multipleSellables
      ? `Add (${sellables.length < 2 ? '1' : `${sellables.length}`}) to cart`
      : // TODO: Remove `!isDisabled` once bluxome Button has a loading state
      showPreOrderButtonText && !isDisabled
      ? 'Pre-order'
      : isAddToCartToSeePrice
      ? 'Add to cart to see price'
      : isOpenBox && formattedBestOfferPrice
      ? `Buy like new open-box for ${formattedBestOfferPrice}`
      : addAsTrial && formattedTrialPrice
      ? `Try it for ${formattedTrialPrice}`
      : addAsTrial
      ? 'Try it'
      : isCuratedItemOfferedAsTrial && formattedCuratedItemPrice
      ? `Buy now for ${formattedCuratedItemPrice}`
      : 'Add to cart'

  const onPress = () => {
    if (!isItemCompatibleWithCart) {
      onAddToCartError?.(
        "You cannot mix trial items with ones you'd like to purchase. If you'd like to add this item, please empty your cart first."
      )
    } else if (isTrialLimitReached) {
      onAddToCartError?.('You may only checkout with 3 trial items at a time.')
    } else if (isParentAddToCartOn) {
      onParentAddToCart()
    } else if (expertCuratedItem) {
      addExpertCuratedItemToCart(expertCuratedItem, addAsTrial).then(() => {
        onAddToCart?.()
      })
    } else if (sellables) {
      bulkAddSellablesToCart(
        sellables.map(sellable => {
          return {
            sellable,
            addAsTrial,
            isOpenBox
          }
        })
      ).then(() => {
        onAddToCart?.()
      })
    }
  }

  // For the product comparison page, a variation matrix popover is shown for parent sellables
  const popoverTriggerProps =
    (!addAsTrial && isParentAddToCartOn && parentPopoverTriggerProps) || undefined

  const icon = addAsTrial ? undefined : CartIcon

  return {
    isDisabled,
    isLoading,
    buttonText,
    onPress,
    popoverTriggerProps,
    icon
  }
}
