// @ts-nocheck
import type * as Types from '../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { MediaFileFragmentDoc } from '../../../../fragments/MediaFile.generated';
import { SellableForSellableLinkFragmentDoc } from '../../../SellableLink/SellableLink.generated';
import { SellableForGetSellableLinkRelFragmentDoc, SellableForIsSellablePurchasableFragmentDoc } from '../../../../services/sellable/Sellable.generated';
import { SellableUrlFragmentDoc } from '../../../../fragments/SellableUrl.generated';
export type ExpertTopRecommendedSellableForTopItemsFragment = { __typename: 'ExpertTopRecommendedSellable', timesRecommended?: number | null, sellable: { __typename: 'Sellable', id: any, title: string, active: boolean, adHoc: boolean, friendlyId?: string | null, slug: string, type: Types.SellableType, images: Array<{ __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null }>, brand?: { __typename: 'Brand', id: any, displayName: string, logo?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null } | null } };

export const ExpertTopRecommendedSellableForTopItemsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ExpertTopRecommendedSellableForTopItems"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ExpertTopRecommendedSellable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sellable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"images"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaFile"}}]}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"brand"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"logo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaFile"}}]}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForSellableLink"}}]}},{"kind":"Field","name":{"kind":"Name","value":"timesRecommended"}}]}}]} as unknown as DocumentNode;