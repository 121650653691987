import React from 'react'
import { Location } from 'history'
import { useHistory, usePageKey } from '@deal/router'
import { formatDate } from '@deal/date-utils'
import { MarketingBanner as BluxomeMarketingBanner } from '@deal/bluxome'
import { EngagementModalType } from '#src/generated/types'
import {
  convertPromoIdToAttrInput,
  formatPathUrl,
  getOnboardingPathSlug,
  useCreateRequestPath
} from '#src/app/services/path'
import { useProductListingLocation } from '#src/app/hooks/useProductListingLocation'
import { useBlackFridayPromotion } from '#src/app/hooks/useBlackFridayPromotion'
import { EngagementChannelSubmittedEvent } from '#src/app/events/EngagementChannelSubmittedEvent'
import { DepartmentForDepartmentContextFragment } from '#src/app/context/department/DepartmentForDepartmentContext.generated'
import { useDepartment } from '#src/app/context/department'
import { useIdentityContext } from '#src/app/containers/Identity'
import { usePromotionsForMarketingBannerQuery } from './MarketingBanner.generated'
import { ContentSelectionResultForMarketingBannerFragment } from './ContentSelectionResultForMarketingBanner.generated'
import { generateComponentValue } from './generateComponentValue'
import styles from './styles.css'

const HOLIDAY_2024_BANNER_EXPIRATION_DATE = new Date('2025-01-01T08:01:00.000Z')

const FAREWELL_SALE_START_DATE = new Date('2025-02-10T08:01:00.000Z')

const HOLIDAY_2024_EXCLUDED_PATHNAMES = [
  '/legal',
  '/company',
  '/inbox',
  '/path',
  '/scout',
  '/products' // no banner on PDPs
]

function isHoliday2024BannerVisible(
  location: Location<any>,
  department: DepartmentForDepartmentContextFragment | null = null
) {
  if (Date.now() > HOLIDAY_2024_BANNER_EXPIRATION_DATE.valueOf()) {
    return false
  }
  if (department?.slug === 'winter-sports') {
    return !HOLIDAY_2024_EXCLUDED_PATHNAMES.some(pathname => location.pathname.startsWith(pathname))
  }
  return false
}

function isFarewellSaleBannerVisible() {
  return Date.now() > FAREWELL_SALE_START_DATE.valueOf()
}

interface MarketingBannerProps {
  ecContentSelectionResult?: ContentSelectionResultForMarketingBannerFragment
}

const MarketingBanner: React.FC<MarketingBannerProps> = ({ ecContentSelectionResult }) => {
  const department = useDepartment()
  const { myself } = useIdentityContext()
  const history = useHistory()
  const pageKey = usePageKey()
  const [createPath] = useCreateRequestPath({
    onPathCreated: ({ pathGraphFlow, pathGraphFlowSlug, nodeSlugOrId }) => {
      if (pathGraphFlow) {
        history.push(
          formatPathUrl({
            pathGraphFlowId: pathGraphFlow.pathGraphFlowId,
            nodeSlugOrId,
            pathGraphFlowSlug
          })
        )
      }
    }
  })

  /**
   * Hide the banner on the inbox since the page doesn't scroll
   * and for experts recording videos for their leads
   * and in full screen conversations
   *
   * Include this in the `skip` option of your experiment to avoid counting it
   */
  const isBannerHidden = [
    pageKey === 'inbox',
    pageKey === 'curations-products-preview-lead',
    pageKey === 'inbox-conversation',
    pageKey === 'scout',
    pageKey === 'scout-inbox'
  ].some(condition => !!condition)

  const blackFridayPromotion = useBlackFridayPromotion({ skip: isBannerHidden })

  const { data: promotionsData, loading: promotionsLoading } = usePromotionsForMarketingBannerQuery(
    {
      variables: {
        userId: myself?.user.id
      },
      skip:
        !myself ||
        isBannerHidden ||
        isFarewellSaleBannerVisible() ||
        isHoliday2024BannerVisible(history.location, department) ||
        !!blackFridayPromotion
    }
  )

  const productListingLocation = useProductListingLocation()

  if (promotionsLoading) {
    return null
  }

  if (isBannerHidden) {
    return null
  }

  if (isFarewellSaleBannerVisible()) {
    return (
      <BluxomeMarketingBanner
        headline={<>Our Final Sale — Get up to 75% off while you can!</>}
        subHeadline={
          <>
            We're saying goodbye, but you get to save big! Check out our clearance page for massive
            discounts on all products.
          </>
        }
        bgColor="shop-orange-500"
        titleColor="neutrals-white"
        subtitleColor="neutrals-white"
        buttonProps={{
          color: 'neutral-dark',
          link: {
            id: 'farewell-2025-cta',
            to: productListingLocation({
              categorySlug: department?.topLevelCategory.slug || 'winter-sports',
              filters: ['CLEARANCE'],
              sortBy: 'MAX_PRICE_PERCENT_SAVINGS'
            })
          }
        }}
        buttonLabel="SHOP FINAL SALE"
      />
    )
  }

  // Holiday 2024
  if (isHoliday2024BannerVisible(history.location, department)) {
    return (
      <BluxomeMarketingBanner
        headline={<>Gear up for the holiday with 15% off</>}
        subHeadline={
          <>Enjoy the holiday season with 15% off select winter sports gear for a limited time.</>
        }
        bgColor="shop-orange-100"
        titleColor="neutrals-black"
        subtitleColor="neutrals-black"
        buttonProps={{
          color: 'shop-orange',
          link: { to: '/shop/winter-sports', id: 'holiday-2024-cta' }
        }}
        buttonLabel="Shop special offers"
      />
    )
  }

  // 2024 Black Friday
  if (blackFridayPromotion) {
    const { code, ctaDestination, termsPath, rewardCreditPercent } = blackFridayPromotion
    const isCtaDisabled = ['/lp', ctaDestination].some(p => {
      return history.location.pathname.startsWith(p)
    })

    return (
      <BluxomeMarketingBanner
        headline={
          <>
            Black Friday starts NOW:&nbsp;
            <br className={styles.headlineBreak} />
            Up to 50% OFF.
          </>
        }
        subHeadline={
          <>
            Plus, enjoy {rewardCreditPercent}% reward credit on full-priced items with code {code}.
          </>
        }
        termsPath={termsPath}
        bgColor="shop-orange-500"
        titleColor="neutrals-white"
        subtitleColor="neutrals-white"
        buttonProps={{
          color: 'shop-orange',
          isInverted: true,
          link: { to: ctaDestination, id: 'black-friday-2024-cta' }
        }}
        buttonLabel={isCtaDisabled ? undefined : 'Shop Black Friday deals NOW'}
      />
    )
  }

  // In-progress expert application banner
  const applications = myself?.user.expertApplicationDrafts || []
  if (applications.length > 0) {
    return (
      <BluxomeMarketingBanner
        headline="Finish your application to become an Expert!"
        buttonLabel="Get Started"
        buttonProps={{
          color: 'shop-orange',
          onPress: () => {
            history.push(formatPathUrl({ pathGraphFlowId: applications[0].pathGraphFlowId }))
          }
        }}
      />
    )
  }

  // Promotions the user has earned
  const nonExpiredPromo = promotionsData?.promotionsPagination.promotions.find(
    promotion => !promotion.expiresAt || new Date(promotion.expiresAt) > new Date()
  )
  if (nonExpiredPromo?.marketingMessage) {
    return (
      <BluxomeMarketingBanner
        headline={nonExpiredPromo.marketingMessage}
        subHeadline={`Use code ${nonExpiredPromo.code} at checkout${
          nonExpiredPromo.expiresAt
            ? ` (expires ${formatDate(nonExpiredPromo.expiresAt, {
                format: 'extra-short'
              })})`
            : ''
        }`}
        termsPath={`/legal/promotion/${nonExpiredPromo.code}`}
      />
    )
  }

  // Engagement channels: path banner
  const csrTemplate = ecContentSelectionResult?.template
  const csrPromotion = ecContentSelectionResult?.promotion
  const csrRule = ecContentSelectionResult?.ruleV2
  const csrPathSchemaId = ecContentSelectionResult?.defaultPathSelection?.pathSchemaId
  const csrComponentValues =
    csrTemplate?.pageComponentViews &&
    generateComponentValue(
      csrTemplate.pageComponentViews.componentViews[0].pageComponentContent.componentValues
    )
  const ecVariation =
    ecContentSelectionResult?.treatment || csrTemplate?.pageComponentViews?.pageTemplate.name

  if (
    csrComponentValues &&
    csrTemplate?.pageComponentViews?.pageTemplate.engagementModalType === EngagementModalType.PATH
  ) {
    return (
      <BluxomeMarketingBanner
        headline={csrComponentValues['Banner Left Text'].text!}
        subHeadline={csrComponentValues['Banner Right Text'].text!}
        buttonLabel={csrComponentValues['Banner CTA Button'].text!}
        buttonProps={{
          color: 'shop-orange',
          onPress: () => {
            const sourceKey = `engagement-channel-path-banner-${pageKey}`
            const initialAttributes = convertPromoIdToAttrInput(csrPromotion?.id)

            window.tracking?.track(
              new EngagementChannelSubmittedEvent({
                // TODO: Safe to remove Category?
                // category: category?.slug,
                engagement_channel: 'path-banner',
                trait: csrRule?.title,
                variation: ecVariation || ''
              })
            )

            // TODO: Safe to remove support for starting a path for a specific sellable?
            //
            // if (!pathSchemaSlug && !pathSchemaId && !!sellable) {
            //   createPathFromSellable({
            //     sourceKey,
            //     sellable,
            //     pageVariation: variation,
            //     initialAttributes
            //   })
            // } else {
            createPath({
              pathSelector: csrPathSchemaId
                ? { pathSchemaId: csrPathSchemaId }
                : { pathSchemaSlug: getOnboardingPathSlug() },
              trackingCodes: {
                ctaName: 'path-banner-submit-button',
                sourceKey,
                pageVariation: ecVariation || ''
              },
              initialAttributes
            })
            // }
          }
        }}
      />
    )
  }

  return null
}

export default MarketingBanner
