import React from 'react'
import loadable from '@loadable/component'
import { FullPageLoader } from '@deal/components'
import { SellableSearchFilter, SellableSort } from '#src/generated/types'
import Page from '#src/app/components/Page'
import Footer from '#src/app/components/Footer'
import { useBfcmRouteQuery } from './page/BFCMRoute.generated'

const PageComponent = loadable(() => import(/* webpackChunkName: "bfcm" */ './page'), {
  resolveComponent: page => page.BFCMPage
})

const BFCMRoute: React.FC = () => {
  const query = useBfcmRouteQuery({
    variables: {
      golfQuery: {
        categorySlugs: ['golf-drivers', 'golf-irons', 'golf-woods', 'golf-complete-golf-sets'],
        filters: [
          SellableSearchFilter.DISCOUNTED,
          SellableSearchFilter.INCLUDE_IN_STOCK_OR_PRE_ORDER
        ]
      },
      snowboardQuery: {
        categorySlugs: ['snowboards', 'snowboard-bindings', 'snowboard-boots'],
        filters: [
          SellableSearchFilter.DISCOUNTED,
          SellableSearchFilter.INCLUDE_IN_STOCK_OR_PRE_ORDER
        ]
      },
      skiQuery: {
        categorySlugs: ['skis', 'ski-bindings', 'ski-boots'],
        filters: [
          SellableSearchFilter.DISCOUNTED,
          SellableSearchFilter.INCLUDE_IN_STOCK_OR_PRE_ORDER
        ]
      },
      first: 6,
      sortBy: SellableSort.MAX_PRICE_PERCENT_SAVINGS
    }
  })

  return (
    <>
      <Page
        query={query}
        pageComponent={PageComponent}
        chat={true}
        seoTitle="Curated BFCM Sale"
        seoDescription={() => undefined}
        seoIndexable
        expert={undefined}
        category={undefined}
        department={undefined}
        sellable={undefined}
        ogImageUrl={undefined}
        loadingComponent={() => <FullPageLoader />}
        pageKey="bfcm-page"
      />
      <Footer />
    </>
  )
}

export { BFCMRoute }
