import React from 'react'
import { Modal } from '@deal/components'
import { ScoutIcon } from '@deal/bluxome'
import { relativeToAbsoluteUrl } from '#src/app/services/navigation'
import clientOnly from '#src/app/hocs/clientOnly'
import { ConversationStartersV2 } from '#src/app/components/ConversationStartersV2'
import { ArticleForConversationStartersV2Fragment } from './ConversationStarterTemplate.generated'
import styles from './styles.css'

export interface ConversationStartersProps {
  article: ArticleForConversationStartersV2Fragment
  isOpen: boolean
  onDismiss?: () => void
  onSubmit?: () => void
}

const ArticleConversationStartersModalTemplate: React.FC<ConversationStartersProps> = ({
  article,
  isOpen,
  onDismiss,
  onSubmit
}) => {
  if (!article.conversationStartersV2) {
    return null
  }
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onDismiss}
      mobilePosition="bottom"
      width="medium"
      contentLabel={'Shop with an expert'}
      contentSpacing={false}
    >
      <div className={styles.standardFlexColContainer}>
        <ScoutIcon style="wordmark" />
        <ConversationStartersV2
          conversationStarters={article.conversationStartersV2}
          consumerMessageAbsoluteUrl={relativeToAbsoluteUrl(
            `/journal/${article.friendlyId}/${article.slug}`
          )}
          sourcePage={{
            articlePage: {
              articleId: article.articleId,
              trackingKey: 'ai-chat'
            }
          }}
          onSubmit={onSubmit}
        />
      </div>
    </Modal>
  )
}

export default clientOnly(ArticleConversationStartersModalTemplate)
