import './polyfills/intersectionObserver'
import './polyfills/resizeObserver'
import './polyfills/preventDefault'
import './polyfills/urlSearchParams'
import './polyfills/url'
import './polyfills/objectFit'
import './polyfills/smoothScroll'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from '#src/app/containers/App'
import { ApolloProvider } from '@apollo/client'
import { Router } from '@deal/router'
import { createBrowserHistory, createPath } from 'history'
import { HelmetProvider } from 'react-helmet-async'
import { ViewerContext as TrackingViewerContext } from '@deal/web-tracking/constants'
import StalePageRefresher from '@deal/refresh-stale-page'
import PageInteractionObserver from '@deal/page-interaction-observer'
import { AnalyticsProvider } from '#src/app/containers/Analytics'
import userTraitsFromMyself from '#src/app/containers/Analytics/userTraitsFromMyself'
import { IdentityProvider } from '#src/app/containers/Identity'
import { ConfigProvider } from '#src/app/containers/Config'
import { HostnameProvider, Hostname } from '#src/app/containers/Hostname'
import { UserAgentProvider } from '#src/app/containers/UserAgent'
import { CookieProvider } from '#src/app/context/cookies'
import {
  WebMessageEventEmitter,
  WebMessageEventEmitterProvider
} from '#src/app/containers/ReceiveMessageFromNative'
import config from '#src/app/config'
import { loadableReady } from '@loadable/component'
import schemaFragments from '#src/generated/schema.fragments'
import schemaNonNormalizableTypes from '#src/generated/schema.non-normalizable-types'
import schemaRelayStylePaginationFields from '#src/generated/schema.relay-style-pagination-fields'
import { createBrowserGraphQLClient, ViewerContext as APIViewerContext } from '@deal/api-client-js'
import UserExperimentClientProvider from '#src/app/containers/UserExperimentClientProvider'
import { createBrowserExperimentClient, BrowserExperimentClientConfig } from '@deal/experiment-js'
import { ErrorResponse } from '@apollo/client/link/error'
import SendTokensToNative from '#src/app/services/sendTokensToNative'
import { getForceTraceId } from '#src/app/services/tracing'
import persistedQueries from '#src/app/services/persistedQueries'
import { loginLocation } from '#src/app/services/routing'
import loggerClient from '#src/app/services/loggerClient'
import { MyselfDocument, MyselfQuery } from '#src/app/containers/Identity/Myself.generated'
import {
  TrackOnlineActivityDocument,
  TrackOnlineActivityMutation,
  TrackOnlineActivityMutationVariables
} from '#src/app/mutations/TrackOnlineActivity.generated'
import { PageInteractedEvent } from '@deal/web-tracking'
import { DepartmentProvider } from '#src/app/context/department'

// Callback for Apollo GraphQL errors
const handleError = (error: ErrorResponse) => {
  const { graphQLErrors, operation } = error
  if (graphQLErrors) {
    graphQLErrors.forEach(error => {
      loggerClient.captureGQLError(error, operation)
    })
  }
}

// Refresh the page/tab if it's been backgrounded for over 1 hour
StalePageRefresher.monitor()

const isBot = window.__IS_BOT__
const isSyntheticTest = window.navigator.userAgent.includes('PTST/SpeedCurve')
const isEndToEndTest = window.Cypress !== undefined

// Configure the hostname
const fullHostname = window.location.hostname.toLowerCase()
let hostname = Hostname.Deal
let isGcp = false
if (fullHostname.endsWith(config.get('gcpHostSuffix'))) {
  hostname = Hostname.Curated
  isGcp = true
} else if (fullHostname.endsWith(config.get('curatedHostSuffix'))) {
  hostname = Hostname.Curated
}

// GraphQL API connection details
let api = config.get('api')

if (isGcp) {
  api = config.get('apiGcp')
} else if (hostname === Hostname.Curated) {
  api = config.get('apiCurated')
}

// Allow override of optimizely variations via the url
const urlQueryString = window.location.search
const urlParams = new URLSearchParams(urlQueryString)
const experimentOverrides = urlParams.get('experimentOverrides') || undefined
const forceDefaultTreatments =
  urlParams.get('forceDefaultTreatments') === 'true' || isBot || isSyntheticTest || isEndToEndTest
const experimentBucketingKey = urlParams.get('experimentBucketingKey') || undefined

// History to be used by react router
const history = createBrowserHistory()

const handleNetworkError = (networkError: Error) => {
  // @ts-ignore `networkError` is incompletely typed
  //   See: https://github.com/apollographql/apollo-link/issues/300
  if (networkError && networkError.statusCode == 401) {
    // Redirect to login on 401
    window.location.href = createPath(loginLocation(history.location))
  }
}

const sendTokensToNative = new SendTokensToNative()
// Bootstrap the native client with the initial state of the tokens
window.addEventListener('load', sendTokensToNative.sendIfChanged)

// Intercept DealNativeBridge.sendToWeb calls, dispatching them through this event listener
const webMessageEventEmitter = new WebMessageEventEmitter()
webMessageEventEmitter.interceptBridgeSendToWeb()

const CURATED_TENANT_ID = 'AgAAAdQAAAAAAAAAAAAAAAAAAAAAAA'

// GraphQL client
const { apolloClient } = createBrowserGraphQLClient({
  protocol: api.protocol,
  webSocketProtocol: api.webSocketProtocol,
  host: api.host,
  port: api.port,
  cachePossibleTypes: schemaFragments.possibleTypes,
  cacheNonNormalizableTypes: schemaNonNormalizableTypes,
  cacheRelayStylePaginationFields: schemaRelayStylePaginationFields,
  initialCacheState: window.__APOLLO_STATE__,
  experimentOverrides,
  forceTrace: () => getForceTraceId(window.location.href),
  isBot: () => isBot,
  viewerContext: () => APIViewerContext.CONSUMER,
  tenantId: () => CURATED_TENANT_ID,
  onIdentityMismatch: () => {
    // If the user ID in the Apollo cache doesn't match the JWT, refresh the
    //   page to synchronize the two, but wait a second to allow the error to
    //   propagate to logger
    setTimeout(() => {
      window.location.reload()
    }, 500)
  },
  onError: handleError,
  onNetworkError: handleNetworkError,
  links: [sendTokensToNative.sendIfChangedOnResponseLink()],
  clientAwarenessName: 'consumer-app-client-' + config.get('environment'),
  clientAwarenessVersion: window.__APP_VERSION__,
  persistedQueries: persistedQueries(window.location.href)
})
delete window.__APOLLO_STATE__

// Rendering
apolloClient
  .query<MyselfQuery>({
    query: MyselfDocument
  })
  .then(({ data }) => {
    const myself = data.me
    const userId = myself?.user.id
    const consumerId = myself?.consumer?.id
    const realUserId = myself?.realUser.id

    const tracking = window.tracking

    if (userId !== realUserId) {
      tracking?.disable()
    }

    if (userId) {
      tracking?.identify({ userId, consumerId }, TrackingViewerContext.Consumer)
    }

    if (myself) {
      tracking?.addTraits(userTraitsFromMyself(myself))
    }

    // Inform the backend that this user is active on the site
    const trackOnlineActivity = () => {
      const isLoggedIn = !!userId
      const isImpersonating = userId !== realUserId

      if (isImpersonating) {
        return
      }

      // Only fire the only activity mutation for logged-in users
      if (isLoggedIn) {
        apolloClient
          .mutate<TrackOnlineActivityMutation, TrackOnlineActivityMutationVariables>({
            mutation: TrackOnlineActivityDocument,
            variables: {
              input: {
                id: userId
              }
            }
          })
          // Errors will be handled by our global error handler
          .catch(() => {})
      }

      // Track the page interaction event for all users
      tracking?.track(new PageInteractedEvent())
    }

    // Track initial user activity (loading this page)
    trackOnlineActivity()

    // Track ongoing user activity (moving mouse, scrolling, etc.)
    const interactionObserver = new PageInteractionObserver(() => {
      trackOnlineActivity()
    })
    interactionObserver.observe()

    // Update RUM page label and @deal/web-tracking if page-key changes
    const onPageKeyChanged = (pageKey?: string) => {
      tracking?.setPageKey(pageKey)

      if (pageKey) {
        if (window.LUX) {
          window.LUX.label = pageKey
        }
      } else {
        if (window.LUX) {
          delete window.LUX.label
        }
      }
    }

    // Set initial RUM page label in case `onPageKeyChanged` occurs after the LUX beacon is sent
    if (window.LUX && window.__PAGE_KEY__) {
      window.LUX.label = window.__PAGE_KEY__
    }

    // Experiment client
    const fallbackTreatments = window.__EXPERIMENT_TREATMENTS__
    const holdouts = window.__EXPERIMENT_HOLDOUTS__

    const experimentClientConfig: BrowserExperimentClientConfig = {
      graphqlConfig: {
        apolloClient,
        fetchUpdatesIntervalMillis: 60000
      },
      experimentOverrides,
      forceDefaultTreatments,
      fallback: fallbackTreatments,
      holdouts,
      trackingClient: () => window.tracking,
      luxClient: () => window.LUX,
      heapClient: () => window.heap
    }
    const experimentClient = createBrowserExperimentClient(experimentClientConfig)
    delete window.__EXPERIMENT_TREATMENTS__
    delete window.__EXPERIMENT_HOLDOUTS__

    // Rendering method
    const renderer = window.__APP_RENDERER__ || 'client'
    delete window.__APP_RENDERER__

    // Rendering function
    const render = (AppComponent: React.ComponentType) => {
      const Root = (
        <CookieProvider environment={window}>
          <UserAgentProvider userAgent={window.navigator.userAgent} isBot={isBot}>
            <ConfigProvider config={config}>
              <ApolloProvider client={apolloClient}>
                <AnalyticsProvider client={tracking}>
                  <IdentityProvider>
                    <DepartmentProvider
                      department={window.__DEPARTMENT__ || null}
                      onDepartmentChanged={department => {
                        if (department) {
                          tracking?.setDepartment(department?.slug)
                        }
                      }}
                    >
                      <UserExperimentClientProvider
                        experimentClient={experimentClient}
                        overrideId={experimentBucketingKey}
                      >
                        <HostnameProvider hostname={hostname}>
                          <WebMessageEventEmitterProvider value={webMessageEventEmitter}>
                            <Router
                              history={history}
                              pageKey={window.__PAGE_KEY__}
                              onPageKeyChanged={onPageKeyChanged}
                            >
                              <HelmetProvider>
                                <AppComponent />
                              </HelmetProvider>
                            </Router>
                          </WebMessageEventEmitterProvider>
                        </HostnameProvider>
                      </UserExperimentClientProvider>
                    </DepartmentProvider>
                  </IdentityProvider>
                </AnalyticsProvider>
              </ApolloProvider>
            </ConfigProvider>
          </UserAgentProvider>
        </CookieProvider>
      )

      if (renderer === 'server') {
        loadableReady(() => {
          ReactDOM.hydrateRoot(document.getElementById('deal')!, Root)

          // Wait for the document and all sub-resources (JS, CSS, images) to be completely loaded, and then mark
          //   the page as "loaded" for SpeedCurve. Note: LUX continuously collects data in the background, and
          //   will send a beacon with that data (including this "load" event) in any of three cases:
          //
          // 1. The page is hidden or closed (see: https://support.speedcurve.com/docs/rum-js-api#luxsendbeacononpagehidden)
          // 2. The user navigates to a different page (see: the `App` container, in the `history.listen` callback)
          // 3. The page has been open for 5 seconds after entering this "DOM Complete" ready state (see: https://www.dareboost.com/en/doc/website-speed-test/metrics/dom-complete)
          // 4. The page has been open for 60 seconds (see: https://support.speedcurve.com/docs/rum-js-api#luxmaxmeasuretime)
          //
          // The 3rd case is the most common and strikes a balance between waiting too long and potentially not
          //   firing the beacon at all (because sending the beacon when the page is closed is unreliable), and
          //   sending the beacon too early and missing some data (because the LCP or CLS may occur late in the
          //   page load). The 5 second buffer accounts for logic that may not be a direct result of the initial
          //   DOM load, such as lazy-loaded images or chat components that initialize after the React app has
          //   mounted.
          //
          // Firing a single beacon at the right time to capture page load metrics is difficult. This is a good
          //   read if you're planning on changing this logic: https://nicj.net/beaconing-in-practice/
          //
          // See: https://support.speedcurve.com/docs/rum-js-api#luxmarkloadtime
          // See: https://developer.mozilla.org/en-US/docs/Web/API/Document/readyState
          const interval = setInterval(async function () {
            if (window.LUX && document.readyState === 'complete' && 'markLoadTime' in window.LUX) {
              clearInterval(interval)

              window.LUX?.markLoadTime()

              setTimeout(() => {
                window.LUX?.send()
              }, 5000)
            }
          }, 100)
        })
      } else {
        const root = ReactDOM.createRoot(document.getElementById('deal')!)
        root.render(Root)
      }
    }

    render(App)
  })
