import React, { useContext } from 'react'

const ChatVisibilityContext = React.createContext(false)
const { Provider: ChatVisibilityProvider } = ChatVisibilityContext

const useChatVisibilityContext = () => {
  return useContext(ChatVisibilityContext)
}

export { ChatVisibilityContext, ChatVisibilityProvider, useChatVisibilityContext }
