import React, { useEffect } from 'react'
import { useHistory } from '@deal/router'
import loggerClient from '#src/app/services/loggerClient'
import { useUserAgentContext } from '#src/app/containers/UserAgent'

const RegisterServiceWorker: React.FC<React.PropsWithChildren<{}>> = () => {
  const history = useHistory()
  const { isBot } = useUserAgentContext()

  const initializeServiceWorker = () => {
    if ('serviceWorker' in navigator && !isBot) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register(`/service-worker.js`).catch(registrationError => {
          loggerClient.captureError(registrationError)
        })
      })

      // Handle messages coming from the service worker
      navigator.serviceWorker.addEventListener('message', event => {
        // Navigate to the destination URL
        history.push(event.data.url)

        // Track a Touch event, since this navigation was initiated from outside the app
        window.tracking?.touch(window.location.href, window.document.referrer)
      })
    }
  }

  useEffect(() => {
    initializeServiceWorker()
  }, [])

  return null
}

export default RegisterServiceWorker
