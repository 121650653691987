import { PathProductRecommendationInput } from '#src/generated/types'

export const getPathProductRecommendationInput = (
  topN: number = 1,
  lazyLoadCount?: number
): PathProductRecommendationInput => {
  return {
    createPath: {
      productRecommendations: {
        pathSelector: {}
      },
      timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
      trackingCodes: {
        sourceKey: 'product-details',
        pageKey: 'product-details',
        ctaName: 'inline-path'
      },
      ephemeral: true,
      layoutType: 'INLINE'
    },
    topN: topN,
    lazyLoadCount: lazyLoadCount
  }
}
