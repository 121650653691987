import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useCurrentExpert } from '#src/app/context/expert'
import { ExpertIntroChat } from '#src/app/components/ExpertIntroChatWidget/ExpertIntroChat'
import { ChatWidgetBase } from '#src/app/components/ChatWidgetBase'
import {
  BusinessUserForGuestChatWidgetFragment,
  useStartExpertGuestConversationForGuestChatWidgetMutation
} from './GuestChatWidget.generated'

export interface GuestChatSource {
  articleId?: string
  sellableId?: string
}

export interface GuestChatWidgetProps {
  expert: BusinessUserForGuestChatWidgetFragment
  expertIntroMessage: string
  source: GuestChatSource
}

export const GuestChatWidget: React.FC<GuestChatWidgetProps> = ({
  expert,
  expertIntroMessage,
  source
}) => {
  const [loading, setLoading] = useState(false)
  const { setExpert } = useCurrentExpert()

  const [startExpertGuestConversation] = useStartExpertGuestConversationForGuestChatWidgetMutation()

  return (
    <ChatWidgetBase expert={expert}>
      <ExpertIntroChat
        expert={expert}
        introMessage={expertIntroMessage}
        onSubmit={consumerMessage => {
          setLoading(true)
          startExpertGuestConversation({
            variables: {
              input: {
                expertId: expert.id,
                sourceFlow: {
                  guestChatFlow: {
                    expertMessage: expertIntroMessage,
                    consumerMessage
                  }
                },
                sourcePage: source.articleId
                  ? {
                      articlePage: {
                        articleId: source.articleId,
                        trackingKey: 'expert-guest-chat'
                      }
                    }
                  : source.sellableId
                  ? {
                      sellablePage: {
                        sellableId: source.sellableId,
                        trackingKey: 'expert-guest-chat'
                      }
                    }
                  : undefined
              }
            },
            onCompleted: ({ startExpertGuestConversation }) => {
              const expert = startExpertGuestConversation.lead?.primaryExpert
              if (expert) {
                setExpert(expert)
              }
              if (startExpertGuestConversation.error) {
                setLoading(false)
                toast.error(startExpertGuestConversation.error)
              }
            },
            onError: () => {
              setLoading(false)
              toast.error('Something went wrong. Please try again.')
            }
          })
        }}
        loading={loading}
      />
    </ChatWidgetBase>
  )
}
