import React from 'react'
import { Link } from '@deal/router'
import { Image } from '@deal/components'
import { isSellableOnClearance } from '#src/app/services/sellable/isSellableOnClearance'
import { getSellableUrl } from '#src/app/services/sellable'
import ClearanceInfo from '#src/app/components/ClearanceInfo/index'
import DefaultProductImage from '#src/app/assets/images/default-product.png'
import { OrderLineItemForProductBundleLineItemFragment } from './OrderLineItemForProductBundleLineItem.generated'
import styles from './styles.css'

interface ProductBundleLineItemProps {
  lineItem: OrderLineItemForProductBundleLineItemFragment
  className?: string
}

const ProductBundleLineItem: React.FC<React.PropsWithChildren<ProductBundleLineItemProps>> = ({
  lineItem,
  className
}) => {
  if (lineItem.source.__typename === 'OrderLineItemSellableSource') {
    const { sellable } = lineItem.source
    const productImageUrl = sellable.primaryImage?.url || DefaultProductImage
    const sellableVariants = sellable.variants

    return (
      <Link to={getSellableUrl(sellable)} className={className}>
        <div className={styles.container}>
          <div className={styles.lineItemContainer}>
            <div className={styles.sellableImageContainer}>
              <Image src={productImageUrl} size="40px" />
            </div>
            <div>
              <div className={styles.sellableTitle}>{sellable.title}</div>
              {!!sellableVariants.length &&
                sellableVariants.map(({ name, displayName, displayValueHtml }) => (
                  <div
                    key={name + displayValueHtml}
                    className={styles.variants}
                    dangerouslySetInnerHTML={{
                      __html: `${displayName}: ${displayValueHtml}`
                    }}
                  />
                ))}
            </div>
            {isSellableOnClearance(sellable) && <ClearanceInfo className={styles.clearanceInfo} />}
          </div>
        </div>
      </Link>
    )
  }
  return null
}

export default ProductBundleLineItem
