// @ts-nocheck
import type * as Types from '../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { SellableForConversationStartersPillsFragmentDoc, BusinessUserForConversationStartersPillsFragmentDoc, CategoryForConversationStartersPillsFragmentDoc, InfluencerForConversationStartersPillsFragmentDoc } from '../../../../../components/ConversationStartersPills/ConversationStartersPills.generated';
import { ConversationStartersFragmentDoc } from '../../../../../fragments/ConversationStarter.generated';
import { SellableForFormatSellablePricingFragmentDoc } from '../../../../../fragments/SellableForFormatSellablePricing.generated';
import { MonetaryAmountFragmentDoc } from '../../../../../fragments/MonetaryAmount.generated';
import { SellableForNormalizeSellablePricingFragmentDoc } from '../../../../../fragments/SellableForNormalizeSellablePricing.generated';
export type SellableForProductExpertConversationStartersFragment = { __typename: 'Sellable', id: any, title: string, bestOfferVisibility: Types.SellableBestOfferVisibility, images: Array<{ __typename: 'MediaFile', id: any, url: string }>, conversationStartersV2: { __typename: 'ConversationStarters', summary?: string | null, suggestions: Array<string> }, productBundle?: { __typename: 'ProductBundleRevision', id: any, salePriceRange?: { __typename: 'MonetaryAmountRange', from?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null, to?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null } | null } | null, bestOfferPrice?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null, salePrice?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null, originalPrice?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null, variationMatrix?: { __typename: 'VariationMatrix', maximumSavingsPercent?: number | null, priceRange?: { __typename: 'VariationMatrixPriceRange', min: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string }, max: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } } | null, originalPriceRange?: { __typename: 'VariationMatrixPriceRange', min: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string }, max: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } } | null, maximumSavingsAmount?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null } | null };

export type BusinessUserForProductExpertConversationStartersFragment = { __typename: 'BusinessUser', id: any, shortName: string, conversationStarters?: { __typename: 'ConversationStarters', summary?: string | null, suggestions: Array<string> } | null };

export type CategoryForProductExpertConversationStartersFragment = { __typename: 'Category', id: any, slug: string, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, expert?: { __typename: 'CategoryAssetNoun', singular: string } | null } | null };

export const SellableForProductExpertConversationStartersFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableForProductExpertConversationStarters"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Sellable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForConversationStartersPills"}}]}}]} as unknown as DocumentNode;
export const BusinessUserForProductExpertConversationStartersFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BusinessUserForProductExpertConversationStarters"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"shortName"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForConversationStartersPills"}}]}}]} as unknown as DocumentNode;
export const CategoryForProductExpertConversationStartersFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CategoryForProductExpertConversationStarters"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Category"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"categoryAssetRevision"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"expert"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"singular"}}]}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"CategoryForConversationStartersPills"}}]}}]} as unknown as DocumentNode;