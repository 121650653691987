// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { MediaFileFragmentDoc } from '../../fragments/MediaFile.generated';
import { SellableForSellableLinkFragmentDoc } from '../SellableLink/SellableLink.generated';
import { SellableForGetSellableLinkRelFragmentDoc, SellableForIsSellablePurchasableFragmentDoc } from '../../services/sellable/Sellable.generated';
import { SellableUrlFragmentDoc } from '../../fragments/SellableUrl.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SellablesForProductComparisonModalQueryVariables = Types.Exact<{
  sellableIds: Array<Types.Scalars['SellableId']['input']> | Types.Scalars['SellableId']['input'];
}>;


export type SellablesForProductComparisonModalQuery = { __typename: 'Query', sellables: Array<{ __typename: 'Sellable', id: any, title: string, active: boolean, adHoc: boolean, friendlyId?: string | null, slug: string, type: Types.SellableType, primaryImage?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null }> };


export const SellablesForProductComparisonModalDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SellablesForProductComparisonModal"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"sellableIds"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SellableId"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sellables"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"ids"},"value":{"kind":"Variable","name":{"kind":"Name","value":"sellableIds"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"primaryImage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaFile"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForSellableLink"}}]}}]}},...MediaFileFragmentDoc.definitions,...SellableForSellableLinkFragmentDoc.definitions,...SellableForGetSellableLinkRelFragmentDoc.definitions,...SellableUrlFragmentDoc.definitions]} as unknown as DocumentNode;
export type SellablesForProductComparisonModalComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SellablesForProductComparisonModalQuery, SellablesForProductComparisonModalQueryVariables>, 'query'> & ({ variables: SellablesForProductComparisonModalQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SellablesForProductComparisonModalComponent = (props: SellablesForProductComparisonModalComponentProps) => (
      <ApolloReactComponents.Query<SellablesForProductComparisonModalQuery, SellablesForProductComparisonModalQueryVariables> query={SellablesForProductComparisonModalDocument} {...props} />
    );
    

/**
 * __useSellablesForProductComparisonModalQuery__
 *
 * To run a query within a React component, call `useSellablesForProductComparisonModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellablesForProductComparisonModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellablesForProductComparisonModalQuery({
 *   variables: {
 *      sellableIds: // value for 'sellableIds'
 *   },
 * });
 */
export function useSellablesForProductComparisonModalQuery(baseOptions: Apollo.QueryHookOptions<SellablesForProductComparisonModalQuery, SellablesForProductComparisonModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SellablesForProductComparisonModalQuery, SellablesForProductComparisonModalQueryVariables>(SellablesForProductComparisonModalDocument, options);
      }
export function useSellablesForProductComparisonModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SellablesForProductComparisonModalQuery, SellablesForProductComparisonModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SellablesForProductComparisonModalQuery, SellablesForProductComparisonModalQueryVariables>(SellablesForProductComparisonModalDocument, options);
        }
export function useSellablesForProductComparisonModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SellablesForProductComparisonModalQuery, SellablesForProductComparisonModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SellablesForProductComparisonModalQuery, SellablesForProductComparisonModalQueryVariables>(SellablesForProductComparisonModalDocument, options);
        }
export type SellablesForProductComparisonModalQueryHookResult = ReturnType<typeof useSellablesForProductComparisonModalQuery>;
export type SellablesForProductComparisonModalLazyQueryHookResult = ReturnType<typeof useSellablesForProductComparisonModalLazyQuery>;
export type SellablesForProductComparisonModalSuspenseQueryHookResult = ReturnType<typeof useSellablesForProductComparisonModalSuspenseQuery>;
export type SellablesForProductComparisonModalQueryResult = Apollo.QueryResult<SellablesForProductComparisonModalQuery, SellablesForProductComparisonModalQueryVariables>;