import React from 'react'
import { formatMonetaryAmountAsJsx } from '#src/app/services/money'
import { OrderLineItemForProductLineItemPriceFragment } from './ProductLineItemPrice.generated'
import styles from './styles.css'

interface Props {
  lineItem: OrderLineItemForProductLineItemPriceFragment
}

const ProductLineItemPrice: React.FC<React.PropsWithChildren<Props>> = ({ lineItem }) => {
  const { unitPrice, originalUnitPrice, price } = lineItem

  if (
    (!lineItem.parentId && !lineItem.isGroup) ||
    lineItem.source.__typename === 'OrderLineItemBasePriceSource' ||
    lineItem.source.__typename === 'OrderLineItemProductBundleSource'
  ) {
    return (
      <div className={styles.price}>
        {unitPrice.amount && unitPrice.amount !== '0' ? (
          formatMonetaryAmountAsJsx(unitPrice)
        ) : (
          <span className={styles.free}>$0.00</span>
        )}
        {originalUnitPrice && originalUnitPrice.amount > unitPrice.amount && (
          <span className={styles.originalPrice}>
            {formatMonetaryAmountAsJsx(originalUnitPrice)}
          </span>
        )}
      </div>
    )
  } else {
    return (
      <div className={styles.customization}>
        {`+ ${formatMonetaryAmountAsJsx(price)} ${lineItem.title}`}
      </div>
    )
  }
}

export default ProductLineItemPrice
