// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type InitiateResetPasswordMutationVariables = Types.Exact<{
  input: Types.InitiateResetPasswordInput;
}>;


export type InitiateResetPasswordMutation = { __typename: 'Mutation', initiateResetPassword: { __typename: 'InitiateResetPasswordPayload', success: boolean, errorMessage?: string | null } };


export const InitiateResetPasswordDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"InitiateResetPassword"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"InitiateResetPasswordInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"initiateResetPassword"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"errorMessage"}}]}}]}}]} as unknown as DocumentNode;
export type InitiateResetPasswordMutationFn = Apollo.MutationFunction<InitiateResetPasswordMutation, InitiateResetPasswordMutationVariables>;
export type InitiateResetPasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InitiateResetPasswordMutation, InitiateResetPasswordMutationVariables>, 'mutation'>;

    export const InitiateResetPasswordComponent = (props: InitiateResetPasswordComponentProps) => (
      <ApolloReactComponents.Mutation<InitiateResetPasswordMutation, InitiateResetPasswordMutationVariables> mutation={InitiateResetPasswordDocument} {...props} />
    );
    

/**
 * __useInitiateResetPasswordMutation__
 *
 * To run a mutation, you first call `useInitiateResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateResetPasswordMutation, { data, loading, error }] = useInitiateResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitiateResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<InitiateResetPasswordMutation, InitiateResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitiateResetPasswordMutation, InitiateResetPasswordMutationVariables>(InitiateResetPasswordDocument, options);
      }
export type InitiateResetPasswordMutationHookResult = ReturnType<typeof useInitiateResetPasswordMutation>;
export type InitiateResetPasswordMutationResult = Apollo.MutationResult<InitiateResetPasswordMutation>;
export type InitiateResetPasswordMutationOptions = Apollo.BaseMutationOptions<InitiateResetPasswordMutation, InitiateResetPasswordMutationVariables>;