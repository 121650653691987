import React from 'react'
import { toast } from 'react-toastify'
import { Button, Divider, Rounded, Text } from '@deal/bluxome'
import { AuthenticatedUserFragment } from '#src/app/fragments/AuthenticatedUser.generated'
import { UserLoggedInEvent, UserLoggedInEventProperties } from '#src/app/events/UserLoggedInEvent'
import GoogleOAuthButtonWrapper from '../../AuthenticationFlow/components/GoogleOAuthButton'
import FacebookOAuthButtonWrapper from '../../AuthenticationFlow/components/FacebookOAuthButton'
import AppleOAuthButtonWrapper from '../../AuthenticationFlow/components/AppleOAuthButton'
import GoogleIcon from './google.svg'
import FacebookIcon from './facebook.svg'
import AppleIcon from './apple.svg'

interface ChatWidgetOAuthProps {
  userLoggedInEventContext: UserLoggedInEventProperties['context']
}

export const ChatWidgetOAuth: React.FC<ChatWidgetOAuthProps> = ({ userLoggedInEventContext }) => {
  const onSuccess = (user: AuthenticatedUserFragment) => {
    window.tracking?.track(
      new UserLoggedInEvent({ user_id: user.id, context: userLoggedInEventContext })
    )
  }

  return (
    <div className="relative flex-none self-stretch">
      <Rounded
        className="py-0.5 px-1 flex items-center justify-end"
        bgColor="neutrals-100"
        corners="all"
      >
        <Text style="small-tight">Sign in to save recommendations:</Text>
        <AppleOAuthButtonWrapper
          onSuccess={onSuccess}
          onFailure={toast.error}
          buttonComponent={buttonProps => (
            <Button
              {...buttonProps}
              color="neutral-light"
              aria-label="apple-oauth-button"
              icon={() => <AppleIcon />}
            />
          )}
        />
        <FacebookOAuthButtonWrapper
          onSuccess={onSuccess}
          onFailure={toast.error}
          buttonComponent={buttonProps => (
            <Button
              {...buttonProps}
              color="neutral-light"
              aria-label="facebook-oauth-button"
              icon={() => <FacebookIcon />}
            />
          )}
        />
        <GoogleOAuthButtonWrapper
          onSuccess={onSuccess}
          onFailure={toast.error}
          buttonComponent={buttonProps => (
            <Button
              {...buttonProps}
              color="neutral-light"
              aria-label="google-oauth-button"
              icon={() => <GoogleIcon />}
            />
          )}
        />
      </Rounded>
      <div className="absolute bottom-0 left-0 right-0">
        <Divider />
      </div>
    </div>
  )
}
