const iOSMajorVersionRegex = /^([0-9]+)\..*$/g

interface OS {
  name?: string
  version?: string
}

interface SMSLinkInput {
  os: OS
  body: string
  phone?: string
  encodePhoneNumber?: boolean
}

export default function smsLink({ os, body, phone, encodePhoneNumber }: SMSLinkInput) {
  const phoneNumber = phone ? phone : os.name === 'iOS' ? '?' : ''

  let separator
  let prefix
  switch (os.name) {
    case 'Mac OS': {
      separator = '&'
      prefix = 'imessage'
      break
    }
    case 'iOS': {
      if (!os.version) {
        // no version, assume it is >=8.0
        separator = '&'
      } else {
        const match = os.version.match(iOSMajorVersionRegex)
        if (match && match[1] && parseInt(match[1], 10) < 8) {
          // < 8.0
          separator = ';'
        } else {
          // >= 8.0
          separator = '&'
        }
      }
      prefix = 'sms'

      break
    }

    default:
      // Use '?' for Android and other phone systems
      separator = '?'
      prefix = 'sms'
      break
  }

  if (phoneNumber && phoneNumber.length > 1 && encodePhoneNumber) {
    return `${prefix}://${encodeURIComponent(phoneNumber)}${separator}body=${encodeURIComponent(
      body
    )}`
  } else {
    return `${prefix}://${phoneNumber}${separator}body=${encodeURIComponent(body)}`
  }
}
