import * as History from 'history'
import { ProductListingPageQuery } from './types'
import { buildSeachSellableSearchParamsAndPathSegments } from './buildSeachSellableSearchParamsAndPathSegments'

// When serializing a query, the "page" is optional because it defaults to 1
export interface SerializableProductListingPageQuery extends Omit<ProductListingPageQuery, 'page'> {
  page?: number
}

/**
 * Build a URL for a product listing page (PLP). PLPs are a faceted UI, and support
 *   many of the features of SRPs: sorting, pagination, and filtering. Additionally,
 *   PLPs support marketing features, such as engagement channels and Page Builder
 *   functionality.
 *
 * Some example PLP URLs might look like:
 *
 *  /c/skis
 *  /c/skis?page=2
 *  /c/skis?sort=trending
 *  /c/skis/b/armada
 *  /c/skis/b/armada?bucket%3Aattributes%3AskiBend=rocker=
 *  /c/skiing/deals - this will not lead to /shop category page as this is a product listing page even though the category
 */
export function buildProductListingPageUrl(
  query: SerializableProductListingPageQuery,
  currentSearchParam: URLSearchParams,
  isBot: boolean
): History.Location {
  const { searchParams, pathSegments } = buildSeachSellableSearchParamsAndPathSegments(query)

  if (query.promotionIds) {
    searchParams.append('promo', query.promotionIds.join(','))
  }

  // Add tracking parameters. These are not included for bots, to prevent crawlers from discovering
  //   a profileration of unique URLs.
  if (!isBot) {
    // Include a `source` parameter for tracking.
    if (query.entryPoint) {
      searchParams.append('source', query.entryPoint)
    }

    // The "k" and "campaign_id" params are used in the SRP content selection rules to target email
    //   campaigns. We must persist the value so that the page elements remain the same as the user
    //   navigates from one SRP to the next.
    const marketingKeyword = currentSearchParam.get('ctx') || currentSearchParam.get('k')
    if (marketingKeyword) {
      searchParams.set('k', marketingKeyword)
    }

    const marketingCampaignId = currentSearchParam.get('campaign_id')
    if (marketingCampaignId) {
      searchParams.set('campaign_id', marketingCampaignId)
    }
  }

  if (query.layout) {
    searchParams.append('layout', query.layout)
  }

  let pathname = '/c/' + query.categorySlug
  if (pathSegments.length) {
    pathname += '/' + pathSegments.join('/')
  }

  return History.createLocation({
    pathname,
    search: searchParams.toString()
  })
}
