import { ConsumerTracking, Event, ViewerContext } from '@deal/web-tracking'

export interface EngagementChannelDismissedEventProperties {
  engagement_channel: ConsumerTracking.EngagementChannel
  variation: string
  category?: string
  trait?: string
}

export class EngagementChannelDismissedEvent extends Event<
  EngagementChannelDismissedEventProperties
> {
  static displayName = 'Engagement Channel Dismissed'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
