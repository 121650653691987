import React from 'react'
import useMeasure, { RectReadOnly } from 'react-use-measure'
import times from 'lodash/times'
import classnames from 'classnames'
import clientOnly from '#src/app/hocs/clientOnly'
import styles from './styles.css'

/**
 * For each skeleton, we render an SVG path of the same shape inside a top-level
 *   SVG clipping path. The gradient "sheen", or "wave", animation is clipped to
 *   that path. This is done so we only have a single CSS animation for all the
 *   skeletons, to improve performance.
 */
interface SkeletonPathProps {
  bounds: RectReadOnly
  circle: boolean
}

const SkeletonPath: React.FC<SkeletonPathProps> = clientOnly(({ bounds, circle }) => {
  if (
    bounds.x + bounds.width < 0 ||
    bounds.y + bounds.height < 0 ||
    bounds.x > window.innerWidth ||
    bounds.y > window.innerHeight
  ) {
    return null
  }

  return (
    <svg
      width={bounds.width}
      height={bounds.height}
      viewBox={`0 0 ${bounds.width} ${bounds.height}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      style={{ display: 'none' }}
    >
      {circle ? (
        <circle
          cx={bounds.x + bounds.width / 2}
          cy={bounds.y + bounds.height / 2}
          r={bounds.width / 2}
        />
      ) : (
        <rect width={bounds.width} height={bounds.height} rx="4px" />
      )}
    </svg>
  )
})

interface SkeletonElementProps {
  animated?: boolean
  className?: string
  circle?: boolean
  height?: number | string
  width?: number | string
  margin?: number | string
  marginTop?: number | string
  marginRight?: number | string
  marginBottom?: number | string
  marginLeft?: number | string
}

const SkeletonElement: React.FC<SkeletonElementProps> = ({
  circle = false,
  animated = true,
  className,
  ...style
}) => {
  const [skeletonContainerRef, skeletonContainerBounds] = useMeasure({ scroll: true })

  return (
    <span aria-live="polite" aria-busy="true">
      {animated && <SkeletonPath bounds={skeletonContainerBounds} circle={circle} />}
      <span
        ref={skeletonContainerRef}
        className={classnames(styles.skeleton, className, {
          [styles.circle]: circle,
          [styles.nonAnimated]: !animated
        })}
        style={style}
      >
        &zwnj;
      </span>
      <br />
    </span>
  )
}

export interface SkeletonProps extends SkeletonElementProps {
  count?: number
}

export const Skeleton: React.FC<SkeletonProps> = ({ count = 1, ...rest }) => (
  <>
    {times(count).map(index => (
      <SkeletonElement key={index} {...rest} />
    ))}
  </>
)
