import React from 'react'
import { Image, Modal } from '@deal/components'
import { BrandLogo } from '@deal/bluxome'
import Safari from './images/safari.png'
import Firefox from './images/firefox.png'
import Edge from './images/edge.png'
import Chrome from './images/chrome.png'
import styles from './styles.css'

interface UpgradeModalProps {
  onUpgradeModalClose: () => void
  isOpen: boolean
}

interface IconComponentProps {
  name: string
  icon: string
  link: string
}

//Browser logo, name, and link to download page
const BrowserDownloadIcon: React.FC<IconComponentProps> = ({ name, icon, link }) => (
  <div className={styles.iconContainer}>
    <Image src={icon} height={72} className={styles.icon} />
    {name}
    <a className={styles.browserLink} target="_blank" href={link} rel="noreferrer">
      Install for free
    </a>
  </div>
)

const UpgradeModal: React.FC<UpgradeModalProps> = ({ onUpgradeModalClose, isOpen }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onUpgradeModalClose}
    contentLabel="Please upgrade your browser"
    contentSpacing={false}
  >
    <div className={styles.container}>
      <div className={styles.modalBodyWrapper}>
        <div className={styles.modalBody}>
          <div className={styles.logoBar}>
            <BrandLogo color="black" />
          </div>
          <div className={styles.header}>Please upgrade your browser</div>
          <div className={styles.textContainer}>
            <b>We're sorry, but your browser isn't supported. </b>
            To enjoy Curated at its best, try using a newer browser like Google Chrome, Mozilla
            Firefox, Microsoft Edge, or Safari.
          </div>
          <div className={styles.allIcons}>
            <div className={styles.iconRow}>
              <BrowserDownloadIcon
                name="Chrome"
                icon={Chrome}
                link="https://www.google.com/chrome/"
              />
              <BrowserDownloadIcon
                name="Firefox"
                icon={Firefox}
                link="https://www.mozilla.org/en-US/firefox/new/"
              />
            </div>
            <div className={styles.iconRow}>
              <BrowserDownloadIcon
                name="Edge"
                icon={Edge}
                link="https://www.microsoft.com/en-us/windows/microsoft-edge"
              />
              <BrowserDownloadIcon
                name="Safari"
                icon={Safari}
                link="https://support.apple.com/downloads/safari"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer} onClick={onUpgradeModalClose}>
        Continue with current browser anyways
      </div>
    </div>
  </Modal>
)

export default UpgradeModal
