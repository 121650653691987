import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { BubbleLoader } from '@deal/components'
import { Button } from '@deal/bluxome'
import { AiGuestChatSuggestedAction, AiGuestChatSuggestion } from '#src/generated/types'
import {
  useScoutTransferToRealExpert,
  useSwitchToRealExpert
} from '#src/app/hooks/useSwitchToRealExpert'
import useSendMessage from '#src/app/hooks/useSendMessage'
import { useAiChatContactCollection } from '#src/app/hooks/useAiChatContactCollection'
import { useIdentityContext } from '#src/app/containers/Identity'
import { useGetLatestMessageSuggestionsQuery } from './ChatSuggestions.generated'
import { useProductPathRecommendationSuggestion } from './useProductPathRecommendationSuggestion'
import styles from './styles.css'

interface ChatSuggestionsProps {
  leadId: string
  conversationId: string
  categorySlug: string
  areSuggestionsVisible: boolean | null
  setAreSuggestionsVisible: (areSuggestionsVisible: boolean | null) => void
  getMessageMetadata: () => { [key: string]: string }
}

const ChatSuggestions: React.FC<ChatSuggestionsProps> = ({
  leadId,
  conversationId,
  categorySlug,
  getMessageMetadata,
  areSuggestionsVisible: areSuggestionsVisibleFromParent,
  setAreSuggestionsVisible: setAreSuggestionsVisibleFromParent
}) => {
  // hardcoded organic 15% promotion id for prod
  const promotionId = 'AgAAAD0AKFNtsCG_QyqBUJK0fLe9Tw'
  const { myself, incompleteUser } = useIdentityContext()
  const [areSuggestionsVisible, setAreSuggestionsVisible] = useState(true)
  const { switchToRealExpert } = useSwitchToRealExpert('widget')
  const { switchToRealExpert: switchToRealExpertFromScout } = useScoutTransferToRealExpert()
  const { aiChatContactCollection } = useAiChatContactCollection('widget')
  const { switchToProductPathRecommendation } = useProductPathRecommendationSuggestion('widget')
  const { sendMessage } = useSendMessage()

  const { data, refetch, loading } = useGetLatestMessageSuggestionsQuery({
    variables: {
      leadId: leadId
    },
    fetchPolicy: 'no-cache', //we dont want to cache this data
    notifyOnNetworkStatusChange: true //this ensures loading is set to true on refetch
  })

  useEffect(() => {
    if (areSuggestionsVisibleFromParent == null || areSuggestionsVisibleFromParent) {
      refetch()
      setAreSuggestionsVisible(true)
    }
  }, [areSuggestionsVisibleFromParent])

  const select = (suggestion: AiGuestChatSuggestion) => {
    setAreSuggestionsVisible(false)
    switch (suggestion.__typename) {
      case 'AIGuestChatActionSuggestion': {
        switch (suggestion.action) {
          case AiGuestChatSuggestedAction.CONNECT_TO_EXPERT:
            switchToRealExpertFromScout({
              leadId: leadId
            })
            break
          case AiGuestChatSuggestedAction.CONNECT_TO_EXPERT_WITH_PROMOTION:
            switchToRealExpert({
              categorySlug: categorySlug,
              leadId: leadId,
              trackingCodes: {
                ctaName: 'chat-suggested-action',
                sourceKey: 'chat-suggested-action'
              },
              promotionId: promotionId
            })
            break
          case AiGuestChatSuggestedAction.SIGN_UP_FOR_PROMOTION:
            aiChatContactCollection({
              leadId: leadId,
              trackingCodes: {
                ctaName: 'chat-suggested-action',
                sourceKey: 'chat-suggested-action'
              }
            })
            break
          case AiGuestChatSuggestedAction.GET_PRODUCT_RECOMMENDATIONS:
            if (!leadId) {
              return toast.error('We cant recommend products at this time')
            }
            switchToProductPathRecommendation({
              leadId: leadId,
              trackingCodes: {
                ctaName: 'chat-suggested-action',
                sourceKey: 'chat-suggested-action'
              }
            })
        }
        break
      }
      case 'AIGuestChatMessageSuggestion': {
        sendMessage(suggestion.label, conversationId, 'ai-chat-suggestion', getMessageMetadata())
        setAreSuggestionsVisibleFromParent(false)
        break
      }
    }
  }

  const suggestions = data?.getLatestMessageSuggestions

  return (
    <>
      {loading ? (
        <BubbleLoader />
      ) : (
        areSuggestionsVisible &&
        (areSuggestionsVisibleFromParent == null || areSuggestionsVisibleFromParent) &&
        !!suggestions?.length && (
          <div className={styles.suggestions}>
            {suggestions

              .filter(suggestion => {
                // Sign up for promotion only for non-registered users
                if (
                  suggestion.__typename === 'AIGuestChatActionSuggestion' &&
                  suggestion.action === AiGuestChatSuggestedAction.SIGN_UP_FOR_PROMOTION
                ) {
                  return !myself || incompleteUser
                }
                // Get product recommendations only if leadId is present
                if (
                  suggestion.__typename === 'AIGuestChatActionSuggestion' &&
                  suggestion.action === AiGuestChatSuggestedAction.GET_PRODUCT_RECOMMENDATIONS
                ) {
                  return !!leadId
                }
                return true
              })
              .map(suggestion => {
                return (
                  <Button
                    key={suggestion.label}
                    size="small"
                    color="learn-blue"
                    style={
                      suggestion.__typename === 'AIGuestChatMessageSuggestion'
                        ? 'outline'
                        : 'filled'
                    }
                    onPress={() => select(suggestion)}
                  >
                    {suggestion.label}
                  </Button>
                )
              })}
          </div>
        )
      )}
    </>
  )
}

export { ChatSuggestions }
