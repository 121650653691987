// @ts-nocheck
import type * as Types from '../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { CategoryForBreadcrumbFragmentDoc } from '../../../../../components/Breadcrumbs/Breadcrumbs.generated';
import { CategoryForCategoryOrProductListingPageFragmentDoc } from '../../../../../services/search/CategoryForCategoryOrProductListingPage.generated';
export type CategoryForHeaderFragment = { __typename: 'Category', id: any, parents: Array<{ __typename: 'Category', id: any, pluralDisplayName: string, slug: string, pageLayoutType: Types.CategoryPageLayoutType }> };

export type CategorySubCategoryTileViewModelForHeaderFragment = { __typename: 'CategorySubCategoryTileViewModel', category: { __typename: 'Category', id: any, shortPluralDisplayName: string, slug: string, pageLayoutType: Types.CategoryPageLayoutType }, media?: { __typename: 'MediaFile', id: any, url: string } | null };

export const CategoryForHeaderFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CategoryForHeader"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Category"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"parents"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CategoryForBreadcrumb"}}]}}]}}]} as unknown as DocumentNode;
export const CategorySubCategoryTileViewModelForHeaderFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CategorySubCategoryTileViewModelForHeader"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CategorySubCategoryTileViewModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"category"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"shortPluralDisplayName"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"CategoryForCategoryOrProductListingPage"}}]}},{"kind":"Field","name":{"kind":"Name","value":"media"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}}]} as unknown as DocumentNode;