// @ts-nocheck
import type * as Types from '../../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ExpertiseRequestForSystemCurationRedirectQueryVariables = Types.Exact<{
  expertiseRequestFilter: Types.FindSellableExpertiseRequestsFilter;
}>;


export type ExpertiseRequestForSystemCurationRedirectQuery = { __typename: 'Query', findSellableExpertiseRequests: Array<{ __typename: 'SellableExpertiseRequest', id: any }> };


export const ExpertiseRequestForSystemCurationRedirectDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ExpertiseRequestForSystemCurationRedirect"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expertiseRequestFilter"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"FindSellableExpertiseRequestsFilter"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"findSellableExpertiseRequests"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertiseRequestFilter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export type ExpertiseRequestForSystemCurationRedirectComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ExpertiseRequestForSystemCurationRedirectQuery, ExpertiseRequestForSystemCurationRedirectQueryVariables>, 'query'> & ({ variables: ExpertiseRequestForSystemCurationRedirectQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ExpertiseRequestForSystemCurationRedirectComponent = (props: ExpertiseRequestForSystemCurationRedirectComponentProps) => (
      <ApolloReactComponents.Query<ExpertiseRequestForSystemCurationRedirectQuery, ExpertiseRequestForSystemCurationRedirectQueryVariables> query={ExpertiseRequestForSystemCurationRedirectDocument} {...props} />
    );
    

/**
 * __useExpertiseRequestForSystemCurationRedirectQuery__
 *
 * To run a query within a React component, call `useExpertiseRequestForSystemCurationRedirectQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpertiseRequestForSystemCurationRedirectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertiseRequestForSystemCurationRedirectQuery({
 *   variables: {
 *      expertiseRequestFilter: // value for 'expertiseRequestFilter'
 *   },
 * });
 */
export function useExpertiseRequestForSystemCurationRedirectQuery(baseOptions: Apollo.QueryHookOptions<ExpertiseRequestForSystemCurationRedirectQuery, ExpertiseRequestForSystemCurationRedirectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpertiseRequestForSystemCurationRedirectQuery, ExpertiseRequestForSystemCurationRedirectQueryVariables>(ExpertiseRequestForSystemCurationRedirectDocument, options);
      }
export function useExpertiseRequestForSystemCurationRedirectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpertiseRequestForSystemCurationRedirectQuery, ExpertiseRequestForSystemCurationRedirectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpertiseRequestForSystemCurationRedirectQuery, ExpertiseRequestForSystemCurationRedirectQueryVariables>(ExpertiseRequestForSystemCurationRedirectDocument, options);
        }
export function useExpertiseRequestForSystemCurationRedirectSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ExpertiseRequestForSystemCurationRedirectQuery, ExpertiseRequestForSystemCurationRedirectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExpertiseRequestForSystemCurationRedirectQuery, ExpertiseRequestForSystemCurationRedirectQueryVariables>(ExpertiseRequestForSystemCurationRedirectDocument, options);
        }
export type ExpertiseRequestForSystemCurationRedirectQueryHookResult = ReturnType<typeof useExpertiseRequestForSystemCurationRedirectQuery>;
export type ExpertiseRequestForSystemCurationRedirectLazyQueryHookResult = ReturnType<typeof useExpertiseRequestForSystemCurationRedirectLazyQuery>;
export type ExpertiseRequestForSystemCurationRedirectSuspenseQueryHookResult = ReturnType<typeof useExpertiseRequestForSystemCurationRedirectSuspenseQuery>;
export type ExpertiseRequestForSystemCurationRedirectQueryResult = Apollo.QueryResult<ExpertiseRequestForSystemCurationRedirectQuery, ExpertiseRequestForSystemCurationRedirectQueryVariables>;