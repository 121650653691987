import React from 'react'
import { useHistory } from 'react-router'
import loadable from '@loadable/component'
import { useIdentityContext } from '#src/app/containers/Identity'
import RouteLoader from '#src/app/components/RouteLoader'
import Redirect from '#src/app/components/Redirect'
import {
  useExpertiseRequestForCurationRedirectQuery,
  useLeadForCurationRedirectQuery,
  useSafeGetLeadForCurationRedirectQuery
} from './CurationRedirect.generated'
import { CurationPageSkeleton } from '../../components/CurationPageSkeleton'

const NoCurationsRoute = loadable(
  () => import(/* webpackChunkName: 'curations-none' */ '../NoCurations'),
  {
    resolveComponent: components => components.NoCurations,
    fallback: <RouteLoader />
  }
)

const CurationRedirect = () => {
  const history = useHistory()
  const queryParams = new URLSearchParams(history.location.search)
  const leadId = queryParams.get('leadId')
  const categoryId = queryParams.get('categoryId')
  const hash = queryParams.get('hash')
  const identity = useIdentityContext()

  const { data: firstLeadData, loading: firstLeadLoading } = useLeadForCurationRedirectQuery({
    variables: {
      leadQuery: {
        facetedSearch: false,
        consumerId: identity.myself?.user?.id
      }
    },
    skip: !identity.myself || !!leadId
  })

  const { data: leadData, loading: leadsLoading } = useSafeGetLeadForCurationRedirectQuery({
    variables: {
      leadId: leadId
    },
    skip: !leadId
  })

  const { data: expertiseRequestData, loading: expertiseRequestLoading } =
    useExpertiseRequestForCurationRedirectQuery({
      variables: {
        expertiseRequestFilter: {
          expertCategoryIds: categoryId ? [categoryId] : undefined
        }
      }
    })
  const loading = firstLeadLoading || leadsLoading || expertiseRequestLoading

  if (loading) return <CurationPageSkeleton />
  const firstLeadOrDefinedLead = leadId
    ? leadData?.safeGetLead.lead
    : firstLeadData?.leads.edges.at(0)?.node
  const firstExpertiseRequest = expertiseRequestData?.findSellableExpertiseRequests.at(0)
  if (
    firstLeadOrDefinedLead &&
    ((firstLeadOrDefinedLead.curationSummary.totalCuratedItems &&
      firstLeadOrDefinedLead.curationSummary.totalCuratedItems > 0) ||
      (firstLeadOrDefinedLead.userNeeds && firstLeadOrDefinedLead.userNeeds.length > 0))
  ) {
    return <Redirect to={`/curations/products/${firstLeadOrDefinedLead.id}#${hash}`} />
  } else if (firstExpertiseRequest) {
    return <Redirect to={`/curations/recommendations/${firstExpertiseRequest.id}`} />
  } else {
    return <NoCurationsRoute />
  }
}

export default CurationRedirect
