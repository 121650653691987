// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { CategoryForContextualPathButtonFragmentDoc } from '../ContextualPathButton/ContextualPathButton.generated';
export type CategoryForContextualPathWithImagesFragment = { __typename: 'Category', id: any, slug: string, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, inventory?: { __typename: 'CategoryAssetNoun', singular: string } | null } | null };

export type MediaFileForContextualPathWithImagesFragment = { __typename: 'MediaFile', id: any, url: string };

export const CategoryForContextualPathWithImagesFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CategoryForContextualPathWithImages"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Category"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CategoryForContextualPathButton"}}]}}]} as unknown as DocumentNode;
export const MediaFileForContextualPathWithImagesFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"MediaFileForContextualPathWithImages"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"MediaFile"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]} as unknown as DocumentNode;