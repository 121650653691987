// @ts-nocheck
import type * as Types from '../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { BusinessUserForExpertQuestionModalFragmentDoc } from '../../../../../components/ExpertQuestionModal/ExpertQuestionModal.generated';
import { ExpertProfileHighlightsFragmentDoc } from '../../../../../fragments/ExpertProfileHighlights.generated';
import { MediaFileFragmentDoc } from '../../../../../fragments/MediaFile.generated';
export type BusinessUserForShowExpertQuestionModalButtonFragment = { __typename: 'BusinessUser', id: any, active: boolean, state: Types.BusinessUserState, displayName: string, shortName: string, department: { __typename: 'Department', id: any, slug: string }, expertProfile?: { __typename: 'ExpertProfile', id: any, headline: string, gender?: Types.ExpertGender | null, location?: string | null, backgroundRichText?: string | null, background: string, highlights: Array<{ __typename: 'ProfileHighlight', value: string, secondaryValue: string, type: { __typename: 'ProfileHighlightType', id: any, icon: Types.ProfileHighlightIconType, displayName: string } }>, profilePhoto?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null } | null, expertStats?: { __typename: 'ExpertStats', averageRating?: number | null, totalReviews: number, totalMessagesSent: number, totalLeadsAsPrimaryExpert: number } | null };

export type SellableEditorialTopListRevisionForShowExpertQuestionModalButtonFragment = { __typename: 'SellableEditorialTopListRevision', id: any, sellableEditorialTopListId: any };

export const BusinessUserForShowExpertQuestionModalButtonFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BusinessUserForShowExpertQuestionModalButton"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"active"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForExpertQuestionModal"}}]}}]} as unknown as DocumentNode;
export const SellableEditorialTopListRevisionForShowExpertQuestionModalButtonFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableEditorialTopListRevisionForShowExpertQuestionModalButton"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SellableEditorialTopListRevision"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"sellableEditorialTopListId"}}]}}]} as unknown as DocumentNode;