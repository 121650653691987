// @ts-nocheck
import type * as Types from '../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { LeadForConversationListItemFragmentDoc } from '../ConversationListItem/ConversationListItem.generated';
export type SupportConversationForConversationListFragment = { __typename: 'SupportConversation', id: any, conversation: { __typename: 'Conversation', id: any, externalId: string } };

export type LeadForConversationListFragment = { __typename: 'Lead', id: any, expert: { __typename: 'BusinessUser', id: any, displayName: string, expertAttributes: { __typename: 'BusinessUserExpertAttributes', placeholderExpert: boolean } }, conversation: { __typename: 'Conversation', id: any, externalId: string }, consumerConversationMetrics: { __typename: 'ConversationMetrics', messageCount?: number | null } };

export const SupportConversationForConversationListFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SupportConversationForConversationList"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SupportConversation"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"conversation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"externalId"}}]}}]}}]} as unknown as DocumentNode;
export const LeadForConversationListFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LeadForConversationList"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Lead"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"expert"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"conversation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"externalId"}}]}},{"kind":"Field","name":{"kind":"Name","value":"consumerConversationMetrics"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"messageCount"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"LeadForConversationListItem"}}]}}]} as unknown as DocumentNode;