import React from 'react'
import classnames from 'classnames'
import { formatDate } from '@deal/date-utils'
import InfoIcon from './info.svg'
import styles from './styles.css'

interface PreorderInfoProps {
  preOrder?: boolean
  availableAt?: Date
  wrapperClassName?: string
}

const PreOrderInfo: React.FC<React.PropsWithChildren<PreorderInfoProps>> = ({
  preOrder,
  availableAt,
  wrapperClassName
}) => {
  if (!availableAt) return null
  return (
    <div className={classnames(styles.preorder, wrapperClassName)}>
      <InfoIcon height={24} width={24} className={styles.infoIcon} />
      {preOrder && (
        <>
          <span className={styles.bold}>Pre-order</span>
          {': '}
        </>
      )}
      Ships on {formatDate(availableAt, { format: 'long' })}
    </div>
  )
}

export default PreOrderInfo
