// @ts-nocheck
import type * as Types from '../../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { AuthenticatedUserFragmentDoc } from '../../../../../../fragments/AuthenticatedUser.generated';
import { AddressFragmentDoc } from '../../../../../../fragments/Address.generated';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type UpdateUserProfileForRegistrationFormMutationVariables = Types.Exact<{
  input: Types.UpdateUserProfileInput;
}>;


export type UpdateUserProfileForRegistrationFormMutation = { __typename: 'Mutation', updateUserProfile: { __typename: 'UpdateUserProfilePayload', errors: Array<string>, user: { __typename: 'User', id: any, experimentUserId: any, firstName?: string | null, lastName?: string | null, roles: Array<string>, profilePhoto?: { __typename: 'MediaFile', id: any, url: string } | null, notificationSettings: Array<{ __typename: 'NotificationSetting', type: Types.NotificationType, emailEnabled: boolean, smsEnabled: boolean }>, messagingUser: { __typename: 'MessagingUser', id: any }, address?: { __typename: 'PostalAddress', line1?: string | null, city?: string | null, state?: string | null, postalCode: string, country?: string | null } | null, savedAddresses: Array<{ __typename: 'Address', id: any, addresseeName?: string | null, line1?: string | null, line2?: string | null, city?: string | null, state?: string | null, country?: string | null, postalCode: string, securityCode?: string | null, phoneNumber?: string | null, active: boolean, isDefault?: boolean | null, countryDetails?: { __typename: 'Country', displayCountry: string } | null }>, contacts: Array<{ __typename: 'Contact', id: any, type: Types.ContactType, value: string, verified: boolean, emailAddress?: string | null, phoneNumber?: string | null, parsedPhoneNumber?: { __typename: 'ParsedPhoneNumber', countryCode: string, regionCode: string, nationalNumber: string } | null }> } } };


export const UpdateUserProfileForRegistrationFormDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateUserProfileForRegistrationForm"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateUserProfileInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateUserProfile"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AuthenticatedUser"}}]}},{"kind":"Field","name":{"kind":"Name","value":"errors"}}]}}]}},...AuthenticatedUserFragmentDoc.definitions,...AddressFragmentDoc.definitions]} as unknown as DocumentNode;
export type UpdateUserProfileForRegistrationFormMutationFn = Apollo.MutationFunction<UpdateUserProfileForRegistrationFormMutation, UpdateUserProfileForRegistrationFormMutationVariables>;
export type UpdateUserProfileForRegistrationFormComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserProfileForRegistrationFormMutation, UpdateUserProfileForRegistrationFormMutationVariables>, 'mutation'>;

    export const UpdateUserProfileForRegistrationFormComponent = (props: UpdateUserProfileForRegistrationFormComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserProfileForRegistrationFormMutation, UpdateUserProfileForRegistrationFormMutationVariables> mutation={UpdateUserProfileForRegistrationFormDocument} {...props} />
    );
    

/**
 * __useUpdateUserProfileForRegistrationFormMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileForRegistrationFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileForRegistrationFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileForRegistrationFormMutation, { data, loading, error }] = useUpdateUserProfileForRegistrationFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileForRegistrationFormMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileForRegistrationFormMutation, UpdateUserProfileForRegistrationFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileForRegistrationFormMutation, UpdateUserProfileForRegistrationFormMutationVariables>(UpdateUserProfileForRegistrationFormDocument, options);
      }
export type UpdateUserProfileForRegistrationFormMutationHookResult = ReturnType<typeof useUpdateUserProfileForRegistrationFormMutation>;
export type UpdateUserProfileForRegistrationFormMutationResult = Apollo.MutationResult<UpdateUserProfileForRegistrationFormMutation>;
export type UpdateUserProfileForRegistrationFormMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileForRegistrationFormMutation, UpdateUserProfileForRegistrationFormMutationVariables>;