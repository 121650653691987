// @ts-nocheck
import type * as Types from '../../../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { MonetaryAmountFragmentDoc } from '../../../../../../../fragments/MonetaryAmount.generated';
import { WishlistItemPurchaseForGiftDetailsFragmentDoc } from '../GiftDetails/GiftDetails.generated';
export type WishlistItemGiftCardDetailForGiftCardWishlistItemFragment = { __typename: 'WishlistItemGiftCardDetail', amountPurchased: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } };

export type WishlistItemGiftCardPurchaseForFlattenedGiftTrackerPageFragment = { __typename: 'WishlistItemPurchase', quantity: number, orderConsumerFriendlyId: string, gifterMessage?: string | null, gifterLastName?: string | null, gifterFirstName?: string | null, orderPaidAt: any, returns: Array<{ __typename: 'OrderReturnRequest', id: any, status: Types.OrderChangeStatus }>, shipmentTrackings: Array<{ __typename: 'ShipmentTracking', status: Types.ShippingStatus, deliveredAt?: any | null }> };

export const WishlistItemGiftCardDetailForGiftCardWishlistItemFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"WishlistItemGiftCardDetailForGiftCardWishlistItem"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"WishlistItemGiftCardDetail"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"amountPurchased"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MonetaryAmount"}}]}}]}}]} as unknown as DocumentNode;
export const WishlistItemGiftCardPurchaseForFlattenedGiftTrackerPageFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"WishlistItemGiftCardPurchaseForFlattenedGiftTrackerPage"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"WishlistItemPurchase"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"quantity"}},{"kind":"Field","name":{"kind":"Name","value":"orderConsumerFriendlyId"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"WishlistItemPurchaseForGiftDetails"}}]}}]} as unknown as DocumentNode;