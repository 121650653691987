// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type PromotionsForMarketingBannerQueryVariables = Types.Exact<{
  userId?: Types.InputMaybe<Types.Scalars['UserId']['input']>;
}>;


export type PromotionsForMarketingBannerQuery = { __typename: 'Query', promotionsPagination: { __typename: 'PromotionsPagination', count: any, promotions: Array<{ __typename: 'Promotion', id: any, expiresAt?: any | null, marketingMessage?: string | null, code: string }> } };


export const PromotionsForMarketingBannerDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"PromotionsForMarketingBanner"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"UserId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"promotionsPagination"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"query"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"createdForId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"autoGenerated"},"value":{"kind":"EnumValue","value":"INCLUDE"}},{"kind":"ObjectField","name":{"kind":"Name","value":"states"},"value":{"kind":"ListValue","values":[{"kind":"EnumValue","value":"ACTIVE"}]}}]}},{"kind":"Argument","name":{"kind":"Name","value":"offset"},"value":{"kind":"IntValue","value":"0"}},{"kind":"Argument","name":{"kind":"Name","value":"limit"},"value":{"kind":"IntValue","value":"1"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"promotions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"expiresAt"}},{"kind":"Field","name":{"kind":"Name","value":"marketingMessage"}},{"kind":"Field","name":{"kind":"Name","value":"code"}}]}},{"kind":"Field","name":{"kind":"Name","value":"count"}}]}}]}}]} as unknown as DocumentNode;
export type PromotionsForMarketingBannerComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PromotionsForMarketingBannerQuery, PromotionsForMarketingBannerQueryVariables>, 'query'>;

    export const PromotionsForMarketingBannerComponent = (props: PromotionsForMarketingBannerComponentProps) => (
      <ApolloReactComponents.Query<PromotionsForMarketingBannerQuery, PromotionsForMarketingBannerQueryVariables> query={PromotionsForMarketingBannerDocument} {...props} />
    );
    

/**
 * __usePromotionsForMarketingBannerQuery__
 *
 * To run a query within a React component, call `usePromotionsForMarketingBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotionsForMarketingBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionsForMarketingBannerQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function usePromotionsForMarketingBannerQuery(baseOptions?: Apollo.QueryHookOptions<PromotionsForMarketingBannerQuery, PromotionsForMarketingBannerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromotionsForMarketingBannerQuery, PromotionsForMarketingBannerQueryVariables>(PromotionsForMarketingBannerDocument, options);
      }
export function usePromotionsForMarketingBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromotionsForMarketingBannerQuery, PromotionsForMarketingBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromotionsForMarketingBannerQuery, PromotionsForMarketingBannerQueryVariables>(PromotionsForMarketingBannerDocument, options);
        }
export function usePromotionsForMarketingBannerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PromotionsForMarketingBannerQuery, PromotionsForMarketingBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PromotionsForMarketingBannerQuery, PromotionsForMarketingBannerQueryVariables>(PromotionsForMarketingBannerDocument, options);
        }
export type PromotionsForMarketingBannerQueryHookResult = ReturnType<typeof usePromotionsForMarketingBannerQuery>;
export type PromotionsForMarketingBannerLazyQueryHookResult = ReturnType<typeof usePromotionsForMarketingBannerLazyQuery>;
export type PromotionsForMarketingBannerSuspenseQueryHookResult = ReturnType<typeof usePromotionsForMarketingBannerSuspenseQuery>;
export type PromotionsForMarketingBannerQueryResult = Apollo.QueryResult<PromotionsForMarketingBannerQuery, PromotionsForMarketingBannerQueryVariables>;