import * as History from 'history'
import { SellableUrlFragment } from '#src/app/fragments/SellableUrl.generated'
import {
  SerializableSearchSellablesPaginationQuery,
  buildSeachSellableSearchParamsAndPathSegments
} from './buildSeachSellableSearchParamsAndPathSegments'

/**
 * Build a URL for a product listing page (PLP). PLPs are a faceted UI, and support
 *   many of the features of SRPs: sorting, pagination, and filtering. Additionally,
 *   PLPs support marketing features, such as engagement channels and Page Builder
 *   functionality.
 *
 * Some example PLP URLs might look like:
 *
 *  /c/skis
 *  /c/skis?page=2
 *  /c/skis?sort=trending
 *  /c/skis/b/armada
 *  /c/skis/b/armada?bucket%3Aattributes%3AskiBend=rocker=
 *  /c/skiing/deals - this will not lead to /shop category page as this is a product listing page even though the category
 */
export function buildPlaPageUrl(
  sellable: SellableUrlFragment,
  query: SerializableSearchSellablesPaginationQuery
): History.Location {
  const { searchParams, pathSegments } = buildSeachSellableSearchParamsAndPathSegments(query)

  let pathname = buildPlaPath(sellable)
  if (pathSegments.length) {
    pathname += '/' + pathSegments.join('/')
  }

  return History.createLocation({
    pathname,
    search: searchParams.toString()
  })
}

export function buildPlaPath(sellable: SellableUrlFragment) {
  return `/pla/${sellable.friendlyId ? sellable.friendlyId : sellable.id}/${sellable.slug}`
}
