import React from 'react'
import loadable from '@loadable/component'
import { useDepartment } from '#src/app/context/department'
import Page from '#src/app/components/Page'
import { useFeedDetailRouteQuery } from './FeedDetailPage.generated'
import LoadingComponent from './loading'

const PageComponent = loadable(() => import(/* webpackChunkName: "for-you" */ './page/index'), {
  resolveComponent: components => components.FeedDetail
})

interface FeedDetailPageProps {
  feedPostId: string
}

const FeedDetailPage: React.FC<React.PropsWithChildren<FeedDetailPageProps>> = ({ feedPostId }) => {
  const query = useFeedDetailRouteQuery({ variables: { id: feedPostId } })
  const department = useDepartment()
  const feedPost =
    query.data?.feedPostOrError.__typename === 'FeedPost' ? query.data?.feedPostOrError : null

  return (
    <Page
      query={query}
      pageKey="for-you-feed-detail"
      chat={true}
      seoTitle={feedPost ? `${feedPost.author.name} posted on Curated` : 'For you'}
      seoDescription={
        feedPost?.primaryContent.__typename === 'TextFeedContentBlock'
          ? feedPost.primaryContent.textContent
          : 'For you feed details'
      }
      seoIndexable
      category={() => undefined}
      department={() => department ?? undefined}
      expert={() => undefined}
      sellable={() => undefined}
      pageComponent={PageComponent}
      loadingComponent={LoadingComponent}
      ogImageUrl={() => undefined}
      canonicalPath={`/for-you/${feedPostId}`}
      pageProps={{
        onLoadMoreComments: (cursor: string) => {
          query.fetchMore({
            variables: {
              id: feedPostId,
              commentEndCursor: cursor
            }
          })
        }
      }}
    />
  )
}

export default FeedDetailPage
