import { FirebaseConversationModel } from '@deal/chat-firebase'
import {
  BusinessUserForIsAiConversationFragment,
  ConversationForIsAiConversationFragment,
  LeadForIsAiConversationFragment
} from './isAIConversation.generated'

/// Check if conversation has AI tags
/// should not be used alone without checking for placeholder expert
const isAIConversation = (conversation: ConversationForIsAiConversationFragment) => {
  return conversation.isAiGuestChat || conversation.isAiChatV2
}

/// based on the business user, check if the conversation is an AI conversation
export const isAIExpertConversation = (expert?: BusinessUserForIsAiConversationFragment) => {
  const conversation = expert?.existingLeadForConsumer?.conversation
  const isAiConvo = conversation && isAIConversation(conversation)
  return !!(expert?.expertAttributes.placeholderExpert && isAiConvo)
}

/// based on the lead, check if the conversation is an AI conversation
export const isAILead = (lead: LeadForIsAiConversationFragment) => {
  const conversation = lead.conversation
  const isAiConvo = conversation && isAIConversation(conversation)
  return lead.expert.expertAttributes.placeholderExpert && isAiConvo
}

export const isAIFirebaseConversation = (conversation?: FirebaseConversationModel) => {
  return (
    conversation?.metadata?.['aiGuestChat'] === 'true' ||
    conversation?.metadata?.['aiChatV2'] === 'true'
  )
}
