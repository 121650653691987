import React from 'react'
import capitalize from 'lodash/capitalize'
import classnames from 'classnames'
import { Link } from '@deal/router'
import { Area, BrandLogoWithTrademark, Grid, Text } from '@deal/bluxome'
import { useCategoryOrProductListingLocation } from '#src/app/hooks/useCategoryOrProductListingLocation'
import { useCategoryNavigationDecoratedGroupsForFooterQuery } from './CategoryNavigationDecoratedGroupsForFooter.generated'
import YoutubeIcon from './youtube.svg'
import LinkedInIcon from './linkedin.svg'
import InstagramIcon from './instagram.svg'
import FacebookIcon from './facebook.svg'
import styles from './styles.css'

interface FooterProps {
  className?: string
}

const Footer: React.FC<FooterProps> = ({ className }) => {
  // TODO: This component should not query for this data itself. It should be passed in as a prop.
  //  If we render the Footer in the Chrome, we can query for it there.
  const { data } = useCategoryNavigationDecoratedGroupsForFooterQuery()
  const groups = data?.formattedCategoryNavigationsByGroup

  const categoryOrProductListingLocation = useCategoryOrProductListingLocation()

  return (
    <footer className={classnames(styles.footer, className)}>
      <Grid rowGap={40}>
        <Area column="1 / -1">
          <BrandLogoWithTrademark color="white" />
        </Area>
        {groups?.map((group, index) => {
          const groupHasLinks = group.navigationRevisions.some(revision => revision.category)
          if (!groupHasLinks) {
            return null
          }

          return (
            <Area
              // We have 3 verticals with linkable categories, but we want to take up space for 4. As a temporary
              //   measure until we add Apparel pages, we double the space the 3rd vertical (Home) takes up on mobile.
              column={index === 2 ? 'span 6' : 'span 3'}
              columnLg={'span 2'}
              className={styles.section}
              key={group.slug}
            >
              <Text style="large-medium-tight">{capitalize(group.label)}</Text>

              <ul className={styles.sectionLinkList}>
                {group.navigationRevisions.map(navigation => {
                  if (!navigation.category) {
                    return null
                  }

                  return (
                    <li key={navigation.id}>
                      <Link to={categoryOrProductListingLocation(navigation.category)}>
                        <Text style="base-tight">{capitalize(navigation.displayLabel)}</Text>
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </Area>
          )
        })}
        <Area column="span 3" columnLg="span 2" className={styles.section}>
          <Text style="large-medium-tight">Curated</Text>

          <ul className={styles.sectionLinkList}>
            <li>
              <Link to="/company/about">
                <Text style="base-tight">About us</Text>
              </Link>
            </li>
            <li>
              <Link to="/company/careers">
                <Text style="base-tight">Careers</Text>
              </Link>
            </li>
            <li>
              <Link to="/partners">
                <Text style="base-tight">Partner with us</Text>
              </Link>
            </li>
            <li>
              <Link to="/group-sales">
                <Text style="base-tight">Group sales</Text>
              </Link>
            </li>
          </ul>
        </Area>
        <Area column="span 3" columnLg="span 2" className={styles.section}>
          <Text style="large-medium-tight">Our Experts</Text>

          <ul className={styles.sectionLinkList}>
            <li>
              <Link to="/meet-the-experts">
                <Text style="base-tight">Meet the experts</Text>
              </Link>
            </li>
            <li>
              <Link to="/apply">
                <Text style="base-tight">Become an expert</Text>
              </Link>
            </li>
            <li>
              <Link to="/journal">
                <Text style="base-tight">Journal</Text>
              </Link>
            </li>
            <li>
              <Link to="/top-lists">
                <Text style="base-tight">Top lists</Text>
              </Link>
            </li>
            <li>
              <Link to="/most-recommended">
                <Text style="base-tight">Most recommended</Text>
              </Link>
            </li>
          </ul>
        </Area>
        <Area column="1 / -1" className={styles.socialCopyrightAndSupportLinks}>
          <div className={styles.socialLinks}>
            <a
              href="https://www.facebook.com/curated"
              target="_blank"
              className={styles.socialIconLink}
              rel="noreferrer"
              aria-label="Curated profile on Facebook"
            >
              <FacebookIcon className={styles.socialIcon} />
            </a>
            <a
              href="https://www.instagram.com/curated"
              target="_blank"
              className={styles.socialIconLink}
              rel="noreferrer"
              aria-label="Curated profile on Instagram"
            >
              <InstagramIcon className={styles.socialIcon} />
            </a>
            <a
              href="https://www.youtube.com/@curatedcom"
              target="_blank"
              className={styles.socialIconLink}
              rel="noreferrer"
              aria-label="Curated profile on YouTube"
            >
              <YoutubeIcon className={styles.socialIcon} />
            </a>
            <a
              href="https://www.linkedin.com/company/curated-com"
              target="_blank"
              className={styles.socialIconLink}
              rel="noreferrer"
              aria-label="Curated profile on LinkedIn"
            >
              <LinkedInIcon className={styles.socialIcon} />
            </a>
          </div>
          <div className={styles.copyrightAndSupportLinks}>
            <span className={styles.copyright}>
              <Text style="small">
                Copyright © {new Date().getFullYear()} Curated Inc. All rights reserved.
              </Text>
            </span>

            <div className={styles.supportLinks}>
              <Link to="/support">
                <Text style="base-tight">Customer support</Text>
              </Link>
              <Link to="/returns">
                <Text style="base-tight">Return policy</Text>
              </Link>
              <Link to="/faq">
                <Text style="base-tight">FAQs</Text>
              </Link>
              <Link to="/legal/privacy-policy">
                <Text style="base-tight">Privacy policy</Text>
              </Link>
              <Link to="/legal/terms-conditions">
                <Text style="base-tight">Terms and conditions</Text>
              </Link>
              <Link to="/legal/dmca">
                <Text style="base-tight">DMCA</Text>
              </Link>
            </div>
          </div>
        </Area>
      </Grid>
    </footer>
  )
}

export default Footer
