import React from 'react'
import { AuthenticatedUserFragment } from '../../../../fragments/AuthenticatedUser.generated'
import { AuthIntent, AuthenticationFlowViewLayoutProps } from '../../index'
import ResetPasswordViewLegacy from './v1'
import { ResetPassword as ResetPasswordViewRebranded } from './rebranded'

interface ResetPasswordProps extends AuthenticationFlowViewLayoutProps {
  onIntentChanged: (intent: AuthIntent) => void
  onLogin: (user: AuthenticatedUserFragment) => void
  isRebranded?: boolean
}

const ResetPassword: React.FC<React.PropsWithChildren<ResetPasswordProps>> = ({
  isRebranded = false,
  ...rest
}) => {
  if (isRebranded) {
    return <ResetPasswordViewRebranded {...rest} />
  }
  return <ResetPasswordViewLegacy {...rest} />
}

export default ResetPassword
