import React, { useContext } from 'react'
import loadable from '@loadable/component'
import { useParams } from '@deal/router'
import { LeadSortOrder } from '#src/generated/types'
import { useDepartment } from '#src/app/context/department'
import { IdentityContext } from '#src/app/containers/Identity'
import RouteLoader from '#src/app/components/RouteLoader'
import Page from '#src/app/components/Page'
import Footer from '#src/app/components/Footer'
import { useLeadsForCurationsListQuery } from '../Curation/Curation.generated'
import { CurationPageSkeleton } from '../../components/CurationPageSkeleton'

const Content = loadable(() => import(/* webpackChunkName: 'curations-list' */ './page'), {
  resolveComponent: components => components.PreviewCurationPage,
  fallback: <RouteLoader />
})

export const PreviewCurationsPage: React.FC = () => {
  const { myself } = useContext(IdentityContext)
  const department = useDepartment()
  const { leadId } = useParams<{ leadId: string }>()
  const query = useLeadsForCurationsListQuery({
    returnPartialData: true,
    variables: {
      query: {
        facetedSearch: false,
        consumerId: myself?.user.id
      },
      first: 20,
      sort: LeadSortOrder.CURATION_DATE,
      leadId,
      shouldIncludeDraft: true,
      shouldAlsoIncludeNoNeedAssignedDrafts: true,
      expertPreview: true
    },
    fetchPolicy: 'cache-and-network'
  })

  if (!myself) return null

  return (
    <>
      <Page
        pageKey="curations-products-preview-lead"
        seoTitle={undefined}
        seoDescription={undefined}
        seoIndexable={true}
        expert={query.data?.currentLead.lead?.expert}
        category={undefined}
        department={department ?? undefined}
        sellable={undefined}
        chat={true}
        query={query}
        pageComponent={Content}
        loadingComponent={CurationPageSkeleton}
        ogImageUrl={undefined}
        canonicalPath={undefined}
      />
      <Footer />
    </>
  )
}
