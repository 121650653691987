import React, { useState } from 'react'
import { Grid, GridItem } from '@deal/components'
import isSupportedBrowser from '#src/app/services/unsupportedBrowsers'
import { useUserAgentContext } from '#src/app/containers/UserAgent'
import UpgradeModal from './UpgradeModal'
import Icon from './images/icon.svg'
import Close from './images/close.svg'
import styles from './styles.css'

interface BannerProps {
  text: string
  linkText: string
}

const UnsupportedBrowserBanner: React.FC<BannerProps> = ({ text, linkText }) => {
  const userAgent = useUserAgentContext()
  const [showBanner, setShowBanner] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)

  return !isSupportedBrowser(userAgent) && showBanner ? (
    <>
      <UpgradeModal onUpgradeModalClose={() => setIsModalOpen(false)} isOpen={isModalOpen} />
      <div className={styles.outsideContainer}>
        <Grid>
          <GridItem col={2}>
            <div className={styles.container}>
              <div className={styles.icon}>
                <Icon />
              </div>
              <div className={styles.textContainer}>
                <span>{text}</span>{' '}
                <span onClick={() => setIsModalOpen(true)} className={styles.linkText}>
                  {linkText}
                </span>
                <div />
              </div>
              <div className={styles.closeContainer}>
                <span className={styles.close} onClick={() => setShowBanner(false)}>
                  <Close />
                </span>
              </div>
            </div>
          </GridItem>
        </Grid>
      </div>
    </>
  ) : null
}

export default UnsupportedBrowserBanner
