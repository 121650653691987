// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
export type UserForSellableStockAlertFragment = { __typename: 'User', id: any };

export type SellableStockAlertForSellableStockAlertFragment = { __typename: 'SellableStockAlert', id: any };

export type SellableForSellableStockAlertFragment = { __typename: 'Sellable', id: any };

export const UserForSellableStockAlertFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserForSellableStockAlert"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]} as unknown as DocumentNode;
export const SellableStockAlertForSellableStockAlertFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableStockAlertForSellableStockAlert"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SellableStockAlert"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]} as unknown as DocumentNode;
export const SellableForSellableStockAlertFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableForSellableStockAlert"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Sellable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]} as unknown as DocumentNode;