import React, { useEffect, useRef, useState } from 'react'
import { mergeProps, useButton, useHover } from 'react-aria'
import classnames from 'classnames'
import { useLocation } from '@deal/router'
import { useScrollPosition } from '@deal/dom-hooks'
import { Annotation, Asterisk, ExpertAvatar, ScoutIcon, Text } from '@deal/bluxome'
import { pluralize } from '#src/app/services/pluralize'
import { useBreakpoint } from '#src/app/hooks/useBreakpoint'
import { BusinessUserForChatWidgetButtonBaseFragment } from './ChatWidgetButtonBase.generated'
import { CollapsibleContainer } from '../CollapsibleContainer'
import StarIcon from './star.svg'
import ExpertRatingStarIcon from './expert-rating-star.svg'
import ChatIcon from './chat.svg'
import styles from './styles.css'

interface AlternatingTitleProps {
  title: string
  unreadCount?: number
}

const AlternatingTitle: React.FC<AlternatingTitleProps> = ({ unreadCount, title }) => {
  const [showNewMessageCopy, setShowNewMessageCopy] = useState(false)

  useEffect(() => {
    if (unreadCount) {
      const intervalId = setInterval(() => {
        setShowNewMessageCopy(prev => !prev)
      }, 2500)

      return () => {
        clearInterval(intervalId)
      }
    } else {
      setShowNewMessageCopy(false)
    }
  }, [unreadCount, title])

  return (
    <div className={styles.titleOuterContainer}>
      {!!unreadCount && (
        <div
          className={classnames(styles.unreadCountContainer, {
            [styles.visible]: showNewMessageCopy
          })}
        >
          <Text style="large-semibold" color="neutrals-white">
            {`New ${pluralize('message', unreadCount)}`}
          </Text>
        </div>
      )}
      <div
        className={classnames(styles.titleInnerContainer, {
          [styles.visible]: !showNewMessageCopy
        })}
      >
        <Text style="large-semibold" color="neutrals-white" lines={1}>
          {title}
        </Text>
      </div>
    </div>
  )
}

interface SubtitleCustomProps {
  subtitle?: string
  averageRating?: never
  rating?: never
}

interface SubtitleRatingProps {
  rating?: number
  averageRating?: never
  subtitle?: never
}

interface SubtitleAvgRatingProps {
  averageRating?: number
  rating?: never
  subtitle?: never
}

type SubtitleProps = SubtitleCustomProps | SubtitleRatingProps | SubtitleAvgRatingProps

interface ChatWidgetButtonBaseWithoutSubtitleProps extends AlternatingTitleProps {
  expert?: BusinessUserForChatWidgetButtonBaseFragment
  onPress: () => void
  unreadCount?: number
  mobileScrollBehavior?: boolean
  defaultExpanded?: boolean
  hideChatIcon?: boolean
  isAI?: boolean
}

type ChatWidgetButtonBaseProps = ChatWidgetButtonBaseWithoutSubtitleProps & SubtitleProps

export const ChatWidgetButtonBase: React.FC<ChatWidgetButtonBaseProps> = ({
  expert,
  onPress,
  unreadCount,
  title,
  mobileScrollBehavior = true,
  defaultExpanded = true,
  subtitle,
  rating,
  averageRating,
  hideChatIcon = false,
  isAI
}) => {
  const isMobile = !useBreakpoint('md', 'bluxome')
  const [isButtonCollapsed, setIsButtonCollapsed] = useState(!defaultExpanded && isMobile)
  const location = useLocation()

  const ref = useRef<HTMLButtonElement>(null)
  const { buttonProps, isPressed } = useButton({ onPress }, ref)
  const { hoverProps, isHovered } = useHover({})

  useScrollPosition({
    effect: ({ verticalDirection }) => {
      if (location.pathname.startsWith('/products')) {
        if (verticalDirection === 'down') {
          setIsButtonCollapsed(true)
        }
      } else if (verticalDirection !== undefined) {
        setIsButtonCollapsed(verticalDirection === 'down')
      }
    },
    skip: !mobileScrollBehavior || !isMobile,
    wait: 400
  })

  const isPlaceholderExpert = expert?.expertAttributes.placeholderExpert

  return (
    <button
      ref={ref}
      {...mergeProps(buttonProps, hoverProps)}
      className={classnames(styles.button, {
        [styles.hovered]: isHovered,
        [styles.pressed]: isPressed
      })}
      // prevents touch event passing to the overlay on mobile
      onTouchEnd={e => e.preventDefault()}
    >
      <div className={styles.expertAvatarContainer}>
        {isAI ? (
          <div className={styles.expertAiAvatar}>
            <ScoutIcon style="logo" size="base" />
          </div>
        ) : !expert || isPlaceholderExpert ? (
          <div className={styles.placeholderExpertAvatar}>
            <Asterisk size="large" color="neutrals-white" />
          </div>
        ) : (
          <ExpertAvatar
            size="large"
            name={expert.displayName}
            image={expert.expertProfile?.profilePhoto?.url}
            hover={false}
          />
        )}
        {!!unreadCount && (
          <div className={styles.unreadMessagesCount}>
            <Text style="extra-small-semibold" color="neutrals-white">
              {unreadCount}
            </Text>
          </div>
        )}
      </div>
      <CollapsibleContainer collapsed={isButtonCollapsed} direction="horizontal">
        <div
          className={classnames(styles.expertDetailsWithChatPillContainer, {
            [styles.expertDetailsContainerLessRightPadding]: hideChatIcon
          })}
        >
          <div className={styles.expertDetailsContainer}>
            <AlternatingTitle title={title} unreadCount={unreadCount} />
            {subtitle ? (
              <Text style="base" color="neutrals-white">
                {subtitle}
              </Text>
            ) : rating ? (
              <div className={styles.expertRating}>
                {new Array(Math.round(rating)).fill(null).map((_, idx) => (
                  <ExpertRatingStarIcon key={idx} className={styles.starIcon} />
                ))}
                {new Array(5 - Math.round(rating)).fill(null).map((_, idx) => (
                  <ExpertRatingStarIcon key={idx} className={styles.emptyStarIcon} />
                ))}
              </div>
            ) : (
              averageRating && (
                <div className={styles.departmentExpertRating}>
                  <StarIcon className={styles.icon} />
                  <Annotation size="base" color="neutrals-white">
                    {averageRating.toFixed(1)} average rating
                  </Annotation>
                </div>
              )
            )}
          </div>
          {!hideChatIcon && (
            <div className={styles.chatLabelContainer}>
              <div className={styles.chatLabel}>
                <ChatIcon className={styles.icon} />
                <Text style="small-semibold" color="neutrals-white">
                  Chat
                </Text>
              </div>
            </div>
          )}
        </div>
      </CollapsibleContainer>
    </button>
  )
}
