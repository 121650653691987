import { usePageKey } from '@deal/router'
import { ContentSelectionChannelType } from '#src/generated/types'
import { useUserAgentContext } from '#src/app/containers/UserAgent'
import {
  CategoryForUseContentSelectionResultForEcFragment,
  useContentSelectionResultForEngagementChannelsQuery
} from './UseContentSelectionResultForEC.generated'
import useGetContentSelectionCriteriaInput from '../useGetContentSelectionCriteriaInput'

interface UseContentSelectionResultForECProps {
  category?: CategoryForUseContentSelectionResultForEcFragment
  keyword?: string
  skip?: boolean
  /// Override page key for engagement channels, defaults to `usePageKey()`
  overridePageKey?: string
}

export default function useContentSelectionResultForEC({
  category,
  keyword,
  skip,
  overridePageKey
}: UseContentSelectionResultForECProps) {
  const { isBot } = useUserAgentContext()
  const pageKey = usePageKey()
  const contentSelectionInput = useGetContentSelectionCriteriaInput({
    categorySlug: category?.slug,
    channelType: ContentSelectionChannelType.ENGAGEMENT_CHANNEL,
    keyword,
    pageKey: overridePageKey ?? pageKey ?? ''
  })

  /**
   * If a category is specified and the department isn't launched or accepting new requests then skip showing an engagement channel
   */
  const invalidDepartment = category && !category.department

  const { data: newData, previousData } = useContentSelectionResultForEngagementChannelsQuery({
    variables: {
      input: contentSelectionInput!,
      pageComponentViewsInput: {
        facets: []
      }
    },
    skip: !pageKey || invalidDepartment || isBot || skip
  })

  return (newData || previousData)?.findContentForCriteria
}
