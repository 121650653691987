import { BusinessUserState } from '#src/generated/types'
import {
  BusinessUserForGetExpertExpertiseFragment,
  BusinessUserForGetExpertPhoneDigitsOnlyFragment,
  BusinessUserForGetExpertProfileLinkFragment,
  BusinessUserForGetImageAltTextFragment,
  BusinessUserForIsPlaceholderExpertFragment
} from './expert.generated'

export const getExpertImageAltText = (expert: BusinessUserForGetImageAltTextFragment) => {
  return `${expert.expertProfile?.headline || 'Curated Expert'} ${expert.displayName}`
}

export const isPlaceholderExpert = (expert: BusinessUserForIsPlaceholderExpertFragment) => {
  return !!expert.expertAttributes.placeholderExpert
}

export const getExpertPhoneDigitsOnly = (
  expert: BusinessUserForGetExpertPhoneDigitsOnlyFragment
) => {
  return expert.assignedSmsAddress?.match(/\d+/g)?.join('')
}

export const getExpertExpertise = (expert: BusinessUserForGetExpertExpertiseFragment) => {
  const { category, specialtyCategory } = expert.expertAttributes
  const headline = expert.expertProfile?.headline || expert.articleAuthorProfile?.headline
  return (
    specialtyCategory?.categoryAssetRevision?.expert?.singular ||
    category.categoryAssetRevision?.expert?.singular ||
    (category.slug === 'curated-internal' || category.slug === 'dealtest'
      ? headline
      : `${category.singularDisplayName} Expert`) ||
    'Curated Expert'
  )
}

export const getExpertProfileLink = (
  expert: BusinessUserForGetExpertProfileLinkFragment,
  rel?: string
) => {
  const link = `/experts/${expert.vanityId || expert.id}`
  const rels = rel?.split(' ') || []

  if (expert.state !== BusinessUserState.ACTIVE) {
    rels.push('nofollow')
  }
  const relString = rels.length === 0 ? undefined : rels.join(' ')

  return { link, rel: relString }
}
