// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { FormattedCategoryNavigationRevisionForCategoryNavigationCardFragmentDoc } from '../CategoryNavigationCard/FormattedCategoryNavigationRevisionForCategoryNavigationCard.generated';
import { CategoryForGetActiveExpertCountForCategoryFragmentDoc } from '../../services/categories/CategoryForGetActiveExpertCountForCategory.generated';
export type FormattedCategoryNavigationRevisionForCategoryPathCtaModalFragment = { __typename: 'FormattedCategoryNavigationRevision', id: string, displayLabel: string, category?: { __typename: 'Category', id: any, slug: string, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, croppedInventoryImage?: { __typename: 'CategoryAssetImage', image: { __typename: 'MediaFile', id: any, url: string } } | null } | null, department?: { __typename: 'Department', id: any, expertStats: { __typename: 'DepartmentExpertStats', activeExpertCount: number } } | null } | null };

export type CategoryNavigationDecoratedGroupForCategoryPathCtaModalFragment = { __typename: 'CategoryNavigationDecoratedGroup', slug: string, label: string, navigationRevisions: Array<{ __typename: 'FormattedCategoryNavigationRevision', id: string, displayLabel: string, category?: { __typename: 'Category', id: any, slug: string, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, croppedInventoryImage?: { __typename: 'CategoryAssetImage', image: { __typename: 'MediaFile', id: any, url: string } } | null } | null, department?: { __typename: 'Department', id: any, expertStats: { __typename: 'DepartmentExpertStats', activeExpertCount: number } } | null } | null }> };

export const FormattedCategoryNavigationRevisionForCategoryPathCtaModalFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FormattedCategoryNavigationRevisionForCategoryPathCtaModal"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FormattedCategoryNavigationRevision"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"category"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"FormattedCategoryNavigationRevisionForCategoryNavigationCard"}}]}}]} as unknown as DocumentNode;
export const CategoryNavigationDecoratedGroupForCategoryPathCtaModalFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CategoryNavigationDecoratedGroupForCategoryPathCtaModal"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CategoryNavigationDecoratedGroup"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"slug"}},{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"navigationRevisions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FormattedCategoryNavigationRevisionForCategoryPathCtaModal"}}]}}]}}]} as unknown as DocumentNode;