// @ts-nocheck
import type * as Types from '../../../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type UpdateWishlistForWishlistDetailsFormMutationVariables = Types.Exact<{
  input: Types.UpdateWishlistInput;
}>;


export type UpdateWishlistForWishlistDetailsFormMutation = { __typename: 'Mutation', updateWishlist: { __typename: 'UpdateWishlistPayload', wishlist: { __typename: 'Wishlist', id: any, title: string }, error?: { __typename: 'InArchivedStateUpdateWishlistError', errorMessage: string } | null } };

export type WishlistForWishlistDetailsFormFragment = { __typename: 'Wishlist', id: any, title: string };

export const WishlistForWishlistDetailsFormFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"WishlistForWishlistDetailsForm"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Wishlist"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]} as unknown as DocumentNode;
export const UpdateWishlistForWishlistDetailsFormDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateWishlistForWishlistDetailsForm"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateWishlistInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateWishlist"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"wishlist"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"WishlistForWishlistDetailsForm"}}]}},{"kind":"Field","name":{"kind":"Name","value":"error"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"InArchivedStateUpdateWishlistError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"errorMessage"}}]}}]}}]}}]}},...WishlistForWishlistDetailsFormFragmentDoc.definitions]} as unknown as DocumentNode;
export type UpdateWishlistForWishlistDetailsFormMutationFn = Apollo.MutationFunction<UpdateWishlistForWishlistDetailsFormMutation, UpdateWishlistForWishlistDetailsFormMutationVariables>;
export type UpdateWishlistForWishlistDetailsFormComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateWishlistForWishlistDetailsFormMutation, UpdateWishlistForWishlistDetailsFormMutationVariables>, 'mutation'>;

    export const UpdateWishlistForWishlistDetailsFormComponent = (props: UpdateWishlistForWishlistDetailsFormComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateWishlistForWishlistDetailsFormMutation, UpdateWishlistForWishlistDetailsFormMutationVariables> mutation={UpdateWishlistForWishlistDetailsFormDocument} {...props} />
    );
    

/**
 * __useUpdateWishlistForWishlistDetailsFormMutation__
 *
 * To run a mutation, you first call `useUpdateWishlistForWishlistDetailsFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWishlistForWishlistDetailsFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWishlistForWishlistDetailsFormMutation, { data, loading, error }] = useUpdateWishlistForWishlistDetailsFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWishlistForWishlistDetailsFormMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWishlistForWishlistDetailsFormMutation, UpdateWishlistForWishlistDetailsFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWishlistForWishlistDetailsFormMutation, UpdateWishlistForWishlistDetailsFormMutationVariables>(UpdateWishlistForWishlistDetailsFormDocument, options);
      }
export type UpdateWishlistForWishlistDetailsFormMutationHookResult = ReturnType<typeof useUpdateWishlistForWishlistDetailsFormMutation>;
export type UpdateWishlistForWishlistDetailsFormMutationResult = Apollo.MutationResult<UpdateWishlistForWishlistDetailsFormMutation>;
export type UpdateWishlistForWishlistDetailsFormMutationOptions = Apollo.BaseMutationOptions<UpdateWishlistForWishlistDetailsFormMutation, UpdateWishlistForWishlistDetailsFormMutationVariables>;