import React, { useEffect } from 'react'
import { useIdentityContext } from '#src/app/containers/Identity'
import { useUpdateUserProfileForBrowserTimezoneMutation } from './BrowserTimezone.generated'

interface Props {}

const BrowserTimezone: React.FC<Props> = () => {
  const { myself } = useIdentityContext()

  const user = myself?.user

  /**
   * skip updating zoneId if impersonating
   */
  const isImpersonating = user?.id !== myself?.realUser.id

  const currentBrowserRegionZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone

  const userZoneId = user?.knownZoneId

  const [updateUserProfile] = useUpdateUserProfileForBrowserTimezoneMutation()

  /**
   * User zoneId is stored as either Java ZoneOffset (e.g. -08:00) or IANA ZoneRegion (e.g. America/New_York)
   * Because ZoneOffset does not account for Daylight Savings Time (DST) we might be off by +/- 1hr until the ZoneOffset is updated
   * Using ZoneRegion instead allows us to manage the DST difference before user comes back to site
   * Started collecting region based zoneId on Oct 8th, 2022
   */

  useEffect(() => {
    if (!isImpersonating && user && userZoneId !== currentBrowserRegionZoneId) {
      updateUserProfile({
        variables: {
          input: { userId: user.id, timeZoneId: currentBrowserRegionZoneId }
        }
      })
    }
  }, [user?.id])

  return null
}

export default BrowserTimezone
