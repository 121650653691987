import React, { useEffect } from 'react'
import { CartViewedEvent } from '#src/app/events/CartViewedEvent'
import { useCartVisibilityContext } from '#src/app/context/cart-visibility'
import { useCart } from '#src/app/context/cart'
import { useAnalyticsContext } from '#src/app/containers/Analytics'
import OrderLineItemsAndSummary from './components/OrderLineItemsAndSummary'
import EmptyCart from './components/EmptyCart'
import styles from './styles.css'

const LoggedInCart: React.FC = () => {
  const { cart, loading } = useCart('CART_VIEW')
  const analytics = useAnalyticsContext()
  const { isCartVisible } = useCartVisibilityContext()

  useEffect(() => {
    if (!loading && cart && isCartVisible) {
      analytics?.track(
        new CartViewedEvent({
          order_id: cart.id
        })
      )
    }
  }, [cart, loading, isCartVisible])

  return (
    <div className={styles.cart}>
      {/* Loading/syncing indicator */}
      {loading && (
        <div className={styles.loader}>
          <div className={styles.loaderLine} />
          <div className={styles.loaderInc}></div>
          <div className={styles.loaderDec}></div>
        </div>
      )}

      {cart ? <OrderLineItemsAndSummary order={cart} /> : <EmptyCart />}
    </div>
  )
}

export default LoggedInCart
