import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Notification } from '@deal/components'
import { AuthenticationResult } from '#src/generated/types'
import PasswordField from '#src/app/components/PasswordField'
import { useResetPasswordMutation } from '../../../ResetPassword.generated'
import { useInitiateResetPasswordMutation } from '../../../InitiateResetPassword.generated'
import { useAuthenticationSessionStateQuery } from '../../../AuthenticationSessionState.generated'
import { AuthenticatedUserFragment } from '../../../../../fragments/AuthenticatedUser.generated'
import handleTypeFromHandle from '../../../util/handleTypeFromHandle'
import formatHandle from '../../../util/formatHandle'
import { AuthIntent, AuthenticationFlowViewLayoutProps } from '../../../index'
import SixDigitCodeField from '../../../components/SixDigitCodeField'
import AuthenticationFlowViewLayout from '../../../components/AuthenticationFlowViewLayout/v1'
import styles from './styles.css'

interface ResetPasswordProps extends AuthenticationFlowViewLayoutProps {
  onIntentChanged: (intent: AuthIntent) => void
  onLogin: (user: AuthenticatedUserFragment) => void
}

const ResetPassword: React.FC<React.PropsWithChildren<ResetPasswordProps>> = ({
  onLogin,
  onIntentChanged,
  standalone
}) => {
  const formRef = useRef<HTMLFormElement>(null)
  const authenticationSession = useAuthenticationSessionStateQuery()
  const [initiateResetPassword] = useInitiateResetPasswordMutation()
  const [resetPassword, resetPasswordResult] = useResetPasswordMutation()
  const [resetPasswordCode, setResetPasswordCode] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordValid, setNewPasswordValid] = useState(false)
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')
  const [newPasswordConfirmationPristine, setNewPasswordConfirmationPristine] = useState(true)

  const authenticationSessionState =
    authenticationSession.data && authenticationSession.data.session.authenticationState

  const handleType = authenticationSessionState?.handle
    ? handleTypeFromHandle(authenticationSessionState.handle)
    : undefined

  /**
   * Initiate the reset password flow when the component mounts. The user
   *   will receive a code to their handle.
   */
  useEffect(() => {
    if (authenticationSessionState?.handle) {
      initiateResetPassword({
        variables: {
          input: {
            handle: authenticationSessionState.handle,
            handleType: handleType
          }
        }
      })
    }
  }, [])

  // If the authentication session has expired, send the user back to the LoginView
  if (!authenticationSessionState) {
    onIntentChanged(AuthIntent.Login)
    return null
  }

  /**
   * Submit the reset password code, along with the new password.
   */
  const handleSubmit: React.FormEventHandler = e => {
    e.preventDefault()

    resetPassword({
      variables: {
        input: {
          newPassword,
          challenge: resetPasswordCode,
          handle: authenticationSessionState.handle,
          handleType: handleType
        }
      }
    }).then(result => {
      if (result.data && result.data.resetPassword.result === AuthenticationResult.SUCCESS) {
        onLogin(result.data.resetPassword.user!)
      }
    })
  }

  const resetPasswordErrors =
    resetPasswordResult.data &&
    resetPasswordResult.data.resetPassword.result === AuthenticationResult.FAILURE &&
    resetPasswordResult.data.resetPassword.message

  const isSubmittable =
    resetPasswordCode.length === 6 && newPasswordValid && newPassword === newPasswordConfirmation

  return (
    <AuthenticationFlowViewLayout
      headline="Don't worry, it happens"
      subHeadline={
        <>
          {authenticationSessionState && authenticationSessionState.handle ? (
            <>
              We sent a code to <strong>{formatHandle(authenticationSessionState.handle)}</strong>.
            </>
          ) : (
            `We sent you a 6-digit code.`
          )}{' '}
          Enter it below, and then choose a new password.
        </>
      }
      standalone={standalone}
    >
      <form className={styles.resetPassword} onSubmit={handleSubmit} ref={formRef}>
        {resetPasswordErrors && (
          <Notification type="error" className={styles.error}>
            {resetPasswordErrors}
          </Notification>
        )}

        <SixDigitCodeField
          className={styles.sixDigitCodeField}
          value={resetPasswordCode}
          onChange={value => setResetPasswordCode(value)}
        />

        <div className={styles.newPasswordHeadline}>Create a new password</div>

        <Box spacing="normal" justify="stretch" className={styles.newPassword}>
          <Box.Item className={styles.newPasswordItem}>
            <PasswordField
              name="newPassword"
              label="Enter a new password"
              labelHidden
              type="password"
              placeholder="Password*"
              showStrength
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              onFieldValid={() => setNewPasswordValid(true)}
              onFieldInvalid={() => setNewPasswordValid(false)}
            />
          </Box.Item>
          <Box.Item className={styles.newPasswordItem}>
            <PasswordField
              name="newPasswordConfirmation"
              label="Confirm your new password"
              labelHidden
              type="password"
              placeholder="Password (Again)*"
              value={newPasswordConfirmation}
              onChange={e => setNewPasswordConfirmation(e.target.value)}
              onBlur={() => setNewPasswordConfirmationPristine(false)}
              errorText={
                !newPasswordConfirmationPristine && newPassword !== newPasswordConfirmation
                  ? `Passwords do not match`
                  : undefined
              }
            />
          </Box.Item>
        </Box>

        <div className={styles.submitArea}>
          <Button
            type="submit"
            variant="primary"
            className={styles.submitButton}
            disabled={!isSubmittable}
          >
            Continue →
          </Button>
        </div>
      </form>
    </AuthenticationFlowViewLayout>
  )
}

export default ResetPassword
