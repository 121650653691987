import React, { useContext, useState } from 'react'

type EngagementChannelBannerContext = {
  isBannerVisible: boolean
  showBanner: () => void
  removeBanner: () => void
} | null

const EngagementChannelBannerContext = React.createContext<EngagementChannelBannerContext>(null)

const { Provider } = EngagementChannelBannerContext

const EngagementChannelBannerContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children
}) => {
  const [isBannerVisible, setIsBannerVisible] = useState(false)

  return (
    <Provider
      value={{
        isBannerVisible,
        showBanner: () => setIsBannerVisible(true),
        removeBanner: () => setIsBannerVisible(false)
      }}
    >
      {children}
    </Provider>
  )
}

const useEngagementChannelBannerContext = () => {
  const engagementChannelBannerContext = useContext(EngagementChannelBannerContext)

  if (!engagementChannelBannerContext) {
    throw new Error('Invoked EngagementChannelBannerContext outside of provider')
  }

  return engagementChannelBannerContext
}

export { EngagementChannelBannerContextProvider, useEngagementChannelBannerContext }
