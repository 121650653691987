import React from 'react'
import { toast } from 'react-toastify'
import { CircleLoader } from '@deal/components'
import { getSellableEcommerceProperties } from '#src/app/services/tracking'
import { TrackableSellableFragment } from '#src/app/fragments/TrackableSellable.generated'
import { ProductRemovedEvent } from '#src/app/events/ProductRemovedEvent'
import { useAnalyticsContext } from '#src/app/containers/Analytics'
import { useRemoveItemFromOrderForRemoveLineItemButtonMutation } from './RemoveLineItemButton.generated'
import RemoveIcon from './remove.svg'
import styles from './styles.css'

interface Props {
  orderId: string
  parentLineItemId: string
  sellable?: TrackableSellableFragment
}

const RemoveLineItemButton: React.FC<React.PropsWithChildren<Props>> = ({
  orderId,
  parentLineItemId,
  sellable
}) => {
  const analytics = useAnalyticsContext()

  const [removeItemsFromOrder, { loading }] = useRemoveItemFromOrderForRemoveLineItemButtonMutation(
    {
      onCompleted: () => {
        if (sellable) {
          analytics?.track(new ProductRemovedEvent(getSellableEcommerceProperties(sellable)))
        }
      },
      onError: () => toast.error('Error removing item from cart.')
    }
  )

  return (
    <button
      className={styles.removeButton}
      onClick={() =>
        removeItemsFromOrder({
          variables: {
            input: {
              id: orderId,
              lineItemIds: [parentLineItemId]
            }
          }
        })
      }
      disabled={loading}
    >
      {loading ? <CircleLoader size="8px" /> : <RemoveIcon />}
    </button>
  )
}

export default RemoveLineItemButton
