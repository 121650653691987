interface FormatPathUrlProps {
  pathGraphFlowSlug?: string
  nodeSlugOrId?: string
  pathGraphFlowId: string
  pathPlacement?: 'default' | 'widget'
}

/**
 * We only need a `pathGraphFlowId` to correct visit a path, we use the
 * `nodeSlugeOrId` and `pathGraphFlowSlug` to identify page over page tracking.
 *
 * If these values are missing or incorrectly set, they'll update as soon as the
 * visit path mutation is called - assuming it is a valid `pathGraphFlowId`
 */
export default function formatPathUrl(pathParmeters: FormatPathUrlProps) {
  const {
    pathGraphFlowId,
    nodeSlugOrId,
    pathGraphFlowSlug,
    pathPlacement = 'default'
  } = pathParmeters

  return (
    `/path${pathGraphFlowSlug ? `/${pathGraphFlowSlug}` : ''}${
      nodeSlugOrId ? `/${nodeSlugOrId}` : ''
    }?flowId=${pathGraphFlowId}` + (pathPlacement === 'widget' ? '&pp=widget' : '')
  )
}
