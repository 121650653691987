import React from 'react'
import { Skeleton } from '#src/app/components/SkeletonLoader'
import PageContent from '#src/app/components/PageContent'
import styles from './styles.css'

const TopListSkeletonLoader: React.FC = () => {
  return (
    <PageContent>
      <div className={styles.cards}>
        <Skeleton width={240} height={315} count={7} />
      </div>
      <div className={styles.main}>
        <Skeleton height="100%" count={2} />
      </div>
    </PageContent>
  )
}

export default TopListSkeletonLoader
