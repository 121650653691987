// @ts-nocheck
import type * as Types from '../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { CategoryForCategoryOrProductListingPageFragmentDoc } from '../../../../services/search/CategoryForCategoryOrProductListingPage.generated';
export type CategoryNavigationRevisionsForCategoryTilesFragment = { __typename: 'FormattedCategoryNavigationRevision', id: string, displayLabel: string, displayLabelLink?: { __typename: 'CategoryNavigationArticleLink', id: any, relativeConsumerWebPath?: string | null, seoShouldFollow: boolean } | { __typename: 'CategoryNavigationCustomWebLink', id: any, relativeConsumerWebPath?: string | null, seoShouldFollow: boolean } | { __typename: 'CategoryNavigationDepartmentLink', id: any, relativeConsumerWebPath?: string | null, seoShouldFollow: boolean } | { __typename: 'CategoryNavigationFacetedCategoryLink', id: any, relativeConsumerWebPath?: string | null, seoShouldFollow: boolean } | { __typename: 'CategoryNavigationFacetedDealsLink', id: any, relativeConsumerWebPath?: string | null, seoShouldFollow: boolean } | null, category?: { __typename: 'Category', id: any, navigationImageUrl?: string | null, slug: string, pageLayoutType: Types.CategoryPageLayoutType, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, inventoryImage?: { __typename: 'CategoryAssetImage', image: { __typename: 'MediaFile', id: any, url: string } } | null } | null } | null };

export type CategoryNavigationDecoratedGroupForCurationFragment = { __typename: 'CategoryNavigationDecoratedGroup', label: string, navigationRevisions: Array<{ __typename: 'FormattedCategoryNavigationRevision', id: string, displayLabel: string, displayLabelLink?: { __typename: 'CategoryNavigationArticleLink', id: any, relativeConsumerWebPath?: string | null, seoShouldFollow: boolean } | { __typename: 'CategoryNavigationCustomWebLink', id: any, relativeConsumerWebPath?: string | null, seoShouldFollow: boolean } | { __typename: 'CategoryNavigationDepartmentLink', id: any, relativeConsumerWebPath?: string | null, seoShouldFollow: boolean } | { __typename: 'CategoryNavigationFacetedCategoryLink', id: any, relativeConsumerWebPath?: string | null, seoShouldFollow: boolean } | { __typename: 'CategoryNavigationFacetedDealsLink', id: any, relativeConsumerWebPath?: string | null, seoShouldFollow: boolean } | null, category?: { __typename: 'Category', id: any, navigationImageUrl?: string | null, slug: string, pageLayoutType: Types.CategoryPageLayoutType, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, inventoryImage?: { __typename: 'CategoryAssetImage', image: { __typename: 'MediaFile', id: any, url: string } } | null } | null } | null }> };

export const CategoryNavigationRevisionsForCategoryTilesFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CategoryNavigationRevisionsForCategoryTiles"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FormattedCategoryNavigationRevision"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayLabel"}},{"kind":"Field","name":{"kind":"Name","value":"displayLabelLink"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"relativeConsumerWebPath"}},{"kind":"Field","name":{"kind":"Name","value":"seoShouldFollow"}}]}},{"kind":"Field","name":{"kind":"Name","value":"category"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"navigationImageUrl"}},{"kind":"Field","name":{"kind":"Name","value":"categoryAssetRevision"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"inventoryImage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"image"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"CategoryForCategoryOrProductListingPage"}}]}}]}}]} as unknown as DocumentNode;
export const CategoryNavigationDecoratedGroupForCurationFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CategoryNavigationDecoratedGroupForCuration"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CategoryNavigationDecoratedGroup"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"navigationRevisions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CategoryNavigationRevisionsForCategoryTiles"}}]}}]}}]} as unknown as DocumentNode;