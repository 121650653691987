import React from 'react'
import { AuthenticatedUserFragment } from '../../../../fragments/AuthenticatedUser.generated'
import { AuthenticationFlowViewLayoutProps, RegistrationHandle } from '../../index'
import { RegistrationForm } from './views/RegistrationForm/v1'
import { RegistrationForm as RegistrationFormRebranded } from './views/RegistrationForm/rebranded'
import { RegisterViaPath } from './views/RegisterViaPath'

export interface RegisterViewProps extends AuthenticationFlowViewLayoutProps {
  referralCodeId?: string | null
  referralRewardCreditClaimToken?: string | null
  submitDisabled?: boolean
  onRegister: (user: AuthenticatedUserFragment, error?: string) => void
  onAlreadyRegistered?: (id: string) => void
  registerViaPath?: boolean
  registrationHandle?: RegistrationHandle
  registrationId?: string
  isRebranded?: boolean
}

const RegisterView: React.FC<React.PropsWithChildren<RegisterViewProps>> = ({
  registerViaPath,
  isRebranded = false,
  ...props
}) => {
  if (registerViaPath) {
    return <RegisterViaPath {...props} />
  } else if (isRebranded) {
    return <RegistrationFormRebranded {...props} />
  } else {
    return <RegistrationForm {...props} />
  }
}

export default RegisterView
