// @ts-nocheck
import type * as Types from '../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type LeadForCurationRedirectQueryVariables = Types.Exact<{
  leadQuery: Types.LeadSearchQuery;
}>;


export type LeadForCurationRedirectQuery = { __typename: 'Query', leads: { __typename: 'LeadSearchConnection', edges: Array<{ __typename: 'LeadSearchEdge', node: { __typename: 'Lead', id: any, userNeeds: Array<{ __typename: 'UserNeed', id: any }>, curationSummary: { __typename: 'CurationSummary', totalCuratedItems?: number | null } } }> } };

export type SafeGetLeadForCurationRedirectQueryVariables = Types.Exact<{
  leadId: Types.Scalars['LeadId']['input'];
}>;


export type SafeGetLeadForCurationRedirectQuery = { __typename: 'Query', safeGetLead: { __typename: 'LeadPayload', lead?: { __typename: 'Lead', id: any, userNeeds: Array<{ __typename: 'UserNeed', id: any }>, curationSummary: { __typename: 'CurationSummary', totalCuratedItems?: number | null } } | null } };

export type ExpertiseRequestForCurationRedirectQueryVariables = Types.Exact<{
  expertiseRequestFilter: Types.FindSellableExpertiseRequestsFilter;
}>;


export type ExpertiseRequestForCurationRedirectQuery = { __typename: 'Query', findSellableExpertiseRequests: Array<{ __typename: 'SellableExpertiseRequest', id: any }> };


export const LeadForCurationRedirectDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"LeadForCurationRedirect"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"leadQuery"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LeadSearchQuery"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"leads"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"query"},"value":{"kind":"Variable","name":{"kind":"Name","value":"leadQuery"}}},{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"IntValue","value":"1"}},{"kind":"Argument","name":{"kind":"Name","value":"sort"},"value":{"kind":"EnumValue","value":"CURATION_DATE"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"userNeeds"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"curationSummary"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"totalCuratedItems"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type LeadForCurationRedirectComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LeadForCurationRedirectQuery, LeadForCurationRedirectQueryVariables>, 'query'> & ({ variables: LeadForCurationRedirectQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const LeadForCurationRedirectComponent = (props: LeadForCurationRedirectComponentProps) => (
      <ApolloReactComponents.Query<LeadForCurationRedirectQuery, LeadForCurationRedirectQueryVariables> query={LeadForCurationRedirectDocument} {...props} />
    );
    

/**
 * __useLeadForCurationRedirectQuery__
 *
 * To run a query within a React component, call `useLeadForCurationRedirectQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeadForCurationRedirectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeadForCurationRedirectQuery({
 *   variables: {
 *      leadQuery: // value for 'leadQuery'
 *   },
 * });
 */
export function useLeadForCurationRedirectQuery(baseOptions: Apollo.QueryHookOptions<LeadForCurationRedirectQuery, LeadForCurationRedirectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LeadForCurationRedirectQuery, LeadForCurationRedirectQueryVariables>(LeadForCurationRedirectDocument, options);
      }
export function useLeadForCurationRedirectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeadForCurationRedirectQuery, LeadForCurationRedirectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LeadForCurationRedirectQuery, LeadForCurationRedirectQueryVariables>(LeadForCurationRedirectDocument, options);
        }
export function useLeadForCurationRedirectSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LeadForCurationRedirectQuery, LeadForCurationRedirectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LeadForCurationRedirectQuery, LeadForCurationRedirectQueryVariables>(LeadForCurationRedirectDocument, options);
        }
export type LeadForCurationRedirectQueryHookResult = ReturnType<typeof useLeadForCurationRedirectQuery>;
export type LeadForCurationRedirectLazyQueryHookResult = ReturnType<typeof useLeadForCurationRedirectLazyQuery>;
export type LeadForCurationRedirectSuspenseQueryHookResult = ReturnType<typeof useLeadForCurationRedirectSuspenseQuery>;
export type LeadForCurationRedirectQueryResult = Apollo.QueryResult<LeadForCurationRedirectQuery, LeadForCurationRedirectQueryVariables>;
export const SafeGetLeadForCurationRedirectDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SafeGetLeadForCurationRedirect"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"leadId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LeadId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"safeGetLead"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"leadId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"lead"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"userNeeds"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"curationSummary"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"totalCuratedItems"}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type SafeGetLeadForCurationRedirectComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SafeGetLeadForCurationRedirectQuery, SafeGetLeadForCurationRedirectQueryVariables>, 'query'> & ({ variables: SafeGetLeadForCurationRedirectQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SafeGetLeadForCurationRedirectComponent = (props: SafeGetLeadForCurationRedirectComponentProps) => (
      <ApolloReactComponents.Query<SafeGetLeadForCurationRedirectQuery, SafeGetLeadForCurationRedirectQueryVariables> query={SafeGetLeadForCurationRedirectDocument} {...props} />
    );
    

/**
 * __useSafeGetLeadForCurationRedirectQuery__
 *
 * To run a query within a React component, call `useSafeGetLeadForCurationRedirectQuery` and pass it any options that fit your needs.
 * When your component renders, `useSafeGetLeadForCurationRedirectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSafeGetLeadForCurationRedirectQuery({
 *   variables: {
 *      leadId: // value for 'leadId'
 *   },
 * });
 */
export function useSafeGetLeadForCurationRedirectQuery(baseOptions: Apollo.QueryHookOptions<SafeGetLeadForCurationRedirectQuery, SafeGetLeadForCurationRedirectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SafeGetLeadForCurationRedirectQuery, SafeGetLeadForCurationRedirectQueryVariables>(SafeGetLeadForCurationRedirectDocument, options);
      }
export function useSafeGetLeadForCurationRedirectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SafeGetLeadForCurationRedirectQuery, SafeGetLeadForCurationRedirectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SafeGetLeadForCurationRedirectQuery, SafeGetLeadForCurationRedirectQueryVariables>(SafeGetLeadForCurationRedirectDocument, options);
        }
export function useSafeGetLeadForCurationRedirectSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SafeGetLeadForCurationRedirectQuery, SafeGetLeadForCurationRedirectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SafeGetLeadForCurationRedirectQuery, SafeGetLeadForCurationRedirectQueryVariables>(SafeGetLeadForCurationRedirectDocument, options);
        }
export type SafeGetLeadForCurationRedirectQueryHookResult = ReturnType<typeof useSafeGetLeadForCurationRedirectQuery>;
export type SafeGetLeadForCurationRedirectLazyQueryHookResult = ReturnType<typeof useSafeGetLeadForCurationRedirectLazyQuery>;
export type SafeGetLeadForCurationRedirectSuspenseQueryHookResult = ReturnType<typeof useSafeGetLeadForCurationRedirectSuspenseQuery>;
export type SafeGetLeadForCurationRedirectQueryResult = Apollo.QueryResult<SafeGetLeadForCurationRedirectQuery, SafeGetLeadForCurationRedirectQueryVariables>;
export const ExpertiseRequestForCurationRedirectDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ExpertiseRequestForCurationRedirect"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expertiseRequestFilter"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"FindSellableExpertiseRequestsFilter"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"findSellableExpertiseRequests"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertiseRequestFilter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export type ExpertiseRequestForCurationRedirectComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ExpertiseRequestForCurationRedirectQuery, ExpertiseRequestForCurationRedirectQueryVariables>, 'query'> & ({ variables: ExpertiseRequestForCurationRedirectQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ExpertiseRequestForCurationRedirectComponent = (props: ExpertiseRequestForCurationRedirectComponentProps) => (
      <ApolloReactComponents.Query<ExpertiseRequestForCurationRedirectQuery, ExpertiseRequestForCurationRedirectQueryVariables> query={ExpertiseRequestForCurationRedirectDocument} {...props} />
    );
    

/**
 * __useExpertiseRequestForCurationRedirectQuery__
 *
 * To run a query within a React component, call `useExpertiseRequestForCurationRedirectQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpertiseRequestForCurationRedirectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertiseRequestForCurationRedirectQuery({
 *   variables: {
 *      expertiseRequestFilter: // value for 'expertiseRequestFilter'
 *   },
 * });
 */
export function useExpertiseRequestForCurationRedirectQuery(baseOptions: Apollo.QueryHookOptions<ExpertiseRequestForCurationRedirectQuery, ExpertiseRequestForCurationRedirectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpertiseRequestForCurationRedirectQuery, ExpertiseRequestForCurationRedirectQueryVariables>(ExpertiseRequestForCurationRedirectDocument, options);
      }
export function useExpertiseRequestForCurationRedirectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpertiseRequestForCurationRedirectQuery, ExpertiseRequestForCurationRedirectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpertiseRequestForCurationRedirectQuery, ExpertiseRequestForCurationRedirectQueryVariables>(ExpertiseRequestForCurationRedirectDocument, options);
        }
export function useExpertiseRequestForCurationRedirectSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ExpertiseRequestForCurationRedirectQuery, ExpertiseRequestForCurationRedirectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExpertiseRequestForCurationRedirectQuery, ExpertiseRequestForCurationRedirectQueryVariables>(ExpertiseRequestForCurationRedirectDocument, options);
        }
export type ExpertiseRequestForCurationRedirectQueryHookResult = ReturnType<typeof useExpertiseRequestForCurationRedirectQuery>;
export type ExpertiseRequestForCurationRedirectLazyQueryHookResult = ReturnType<typeof useExpertiseRequestForCurationRedirectLazyQuery>;
export type ExpertiseRequestForCurationRedirectSuspenseQueryHookResult = ReturnType<typeof useExpertiseRequestForCurationRedirectSuspenseQuery>;
export type ExpertiseRequestForCurationRedirectQueryResult = Apollo.QueryResult<ExpertiseRequestForCurationRedirectQuery, ExpertiseRequestForCurationRedirectQueryVariables>;