import { LoginLocationOptions } from '#src/app/services/routing'
import withAuthentication from './withAuthentication'

/**
 * Require the viewer to be authenticated to render a component
 */
export default function protect<T = any>(
  ComponentConstructor: React.ComponentType<React.PropsWithChildren<T>>,
  loginLocationOptions?: LoginLocationOptions
) {
  return withAuthentication(ComponentConstructor, loginLocationOptions)
}
