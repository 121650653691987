import React from 'react'
import { Skeleton } from '#src/app/components/SkeletonLoader'
import { ProductCarouselSkeleton } from '#src/app/components/ProductCarousel/ProductCarouselSkeleton'
import { HeroSkeleton } from './components/HeroSkeleton'
import { BrowseSubCategoryNavigationSkeleton } from './components/BrowseSubCategoryNavigationSkeleton'
import styles from './styles.css'

export const CategoryRouteLoader: React.FC = () => {
  return (
    <>
      <Skeleton height="48px" />
      <div className={styles.categoryPage}>
        <div className={styles.sectionContainer}>
          <HeroSkeleton />
          <BrowseSubCategoryNavigationSkeleton />
          <ProductCarouselSkeleton />
          <ProductCarouselSkeleton />
        </div>
      </div>
    </>
  )
}
