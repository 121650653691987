import React, { useContext, useState } from 'react'
import { CreatePathProps } from '@deal/path-flow'
import { useModalTrigger } from '@deal/bluxome'
import { CreatePathGraphFlowInput } from '#src/generated/types'
import { isAIExpertConversation } from '#src/app/services/aiChat/isAIConversation'
import { useCurrentExpert } from '../expert'
import { RequestPathInterstitialModal } from './RequestPathInterstitialModal'

/**
 * A higher-order function that wraps the provided `createPath` function.
 * It opens a confirmation dialog before creating a request if the consumer already
 * has an existing lead.
 *
 * @returns {function} A new function that takes a `CreatePathGraphFlowInput` and handles the interstitial logic.
 */
interface CreatePathWithInterstitialContextType {
  (...args: CreatePathWithInterstitialArguments): (
    createPathInput: CreatePathGraphFlowInput
  ) => void
}

type CreatePathWithInterstitialArguments = [
  createPath: (createPathInput: CreatePathGraphFlowInput) => void,
  createPathProps: CreatePathProps
]

const CreatePathWithInterstitialContext =
  React.createContext<CreatePathWithInterstitialContextType>(() => () => {
    throw new Error('Attempted to access `CreatePathWithInterstitialContext` outside of a provider')
  })

export const CreatePathWithInterstitialContextProvider: React.FC<React.PropsWithChildren> = ({
  children
}) => {
  const { expert = null } = useCurrentExpert()

  const [createPathProps, setCreatePathProps] = useState<CreatePathProps | null>(null)
  const [createPathInput, setCreatePathInput] = useState<CreatePathGraphFlowInput | null>(null)

  const { state, overlayProps } = useModalTrigger({
    onOpenChange: isOpen => {
      if (!isOpen) {
        setTimeout(() => {
          setCreatePathProps(null)
          setCreatePathInput(null)
        }, 200)
      }
    }
  })

  const isExistingLeadActiveAndWithRealExpert = () => {
    const isAi = (expert && isAIExpertConversation(expert)) ?? false
    return !!expert?.existingLeadForConsumer?.conversation?.active && !isAi
  }

  return (
    <CreatePathWithInterstitialContext.Provider
      value={(createPath, createPathProps) => {
        return input => {
          if (input.request && isExistingLeadActiveAndWithRealExpert()) {
            setCreatePathProps(createPathProps)
            setCreatePathInput(input)
            state.open()
          } else {
            createPath(input)
            state.close()
          }
        }
      }}
    >
      {expert && createPathProps && createPathInput && (
        <RequestPathInterstitialModal
          state={state}
          overlayProps={overlayProps}
          expert={expert}
          createPathProps={{
            ...createPathProps,
            onPathCreated: (...args) => {
              createPathProps.onPathCreated(...args)
              state.close()
            },
            onError: (...args) => {
              createPathProps.onError?.(...args)
              state.close()
            }
          }}
          createPathInput={createPathInput}
        />
      )}
      {children}
    </CreatePathWithInterstitialContext.Provider>
  )
}

export function useCreatePathWithInterstitial(...args: CreatePathWithInterstitialArguments) {
  const withInterstitial = useContext(CreatePathWithInterstitialContext)
  return withInterstitial(...args)
}
