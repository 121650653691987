import React from 'react'
import classnames from 'classnames'
import { Heading, Text } from '@deal/bluxome'
import { AuthenticationFlowViewLayoutProps } from '../../..'
import styles from './styles.css'

const AuthenticationFlowViewLayout: React.FC<
  React.PropsWithChildren<Omit<AuthenticationFlowViewLayoutProps, 'bottomSection'>>
> = ({ children, footer, headline, subHeadline, standalone }) => {
  return (
    <>
      <div
        className={classnames(styles.view, {
          [styles.standalone]: standalone
        })}
      >
        <div className={styles.content}>
          <div className={styles.top}>
            {headline && <Heading size="h4">{headline}</Heading>}
            {subHeadline && <Text color="neutrals-600">{subHeadline}</Text>}
          </div>
          {children}
        </div>
      </div>
      {footer}
    </>
  )
}

export default AuthenticationFlowViewLayout
