import { ConsumerTracking, Event, ViewerContext } from '@deal/web-tracking'

export interface EngagementChannelSubmittedEventProperties {
  engagement_channel: ConsumerTracking.EngagementChannel
  variation: string
  category?: string
  trait?: string
}

export class EngagementChannelSubmittedEvent extends Event<
  EngagementChannelSubmittedEventProperties
> {
  static displayName = 'Engagement Channel Submitted'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
