import { useIdentityContext } from '#src/app/containers/Identity'
import { BusinessUserForUseFeedAuthorFragment } from './useFeedAuthor.generated'

export type FeedAuthor = {
  type: 'expert' | 'consumer'
  displayName?: string
  profilePhotoUrl?: string
  authorId: string
  impersonatingExpert?: boolean
}

export default function useFeedAuthor(): FeedAuthor | null {
  const { myself } = useIdentityContext()
  if (!myself) {
    return null
  }

  const priorityExpertAuthors = [
    // Always prefer the currently logged in expert account
    myself.businessUser?.department.slug === 'golf' ? makeExpertAuthor(myself.businessUser) : null,
    // If not logged in, for MVP (targeting golf) lets prefer the expert's golf expert account
    ...myself.user.assignedBusinessUsers
      .filter(bu => bu.department.slug === 'golf')
      .map(makeExpertAuthor),
    // All else fails just pick the first expert account we can find
    ...myself.user.assignedBusinessUsers.map(makeExpertAuthor)
  ].filter(author => author !== null)

  if (priorityExpertAuthors.length > 0) {
    const impersonatingExpert =
      !!myself.businessUser &&
      !myself.realUser.assignedBusinessUsers.some(bu => bu.id === myself.businessUser?.id)
    return {
      ...priorityExpertAuthors[0]!,
      impersonatingExpert
    }
  }

  if (myself.consumer) {
    return {
      type: 'consumer',
      displayName: myself.user.firstName || undefined,
      authorId: myself.consumer.id,
      profilePhotoUrl: myself.user.profilePhoto?.url
    }
  }
  return null
}

/**
 * @returns all possibile identities the current user can post as
 */
export function useFeedIdentities(): string[] {
  const { myself } = useIdentityContext()
  if (!myself) {
    return []
  }

  const ids = [
    myself.consumer?.id,
    extractExpertId(myself.businessUser),
    ...myself.user.assignedBusinessUsers.map(extractExpertId)
  ].filter(id => !!id)

  return [...new Set(ids)]
}

function makeExpertAuthor(expert?: BusinessUserForUseFeedAuthorFragment | null): FeedAuthor | null {
  if (!expert?.active) {
    return null
  }
  return {
    type: 'expert',
    displayName: expert.displayName,
    profilePhotoUrl: expert.expertProfile?.profilePhoto?.url,
    authorId: expert.id
  }
}

function extractExpertId(expert?: BusinessUserForUseFeedAuthorFragment | null): string | null {
  if (!expert?.active) {
    return null
  }
  return expert.id
}
