import React, { useRef, useState } from 'react'
import { useDebounce } from 'use-debounce'
import useMeasure from 'react-use-measure'
import { Button, Text, TextField } from '@deal/bluxome'
import { AuthenticationResult, ChallengeType, HandleType } from '#src/generated/types'
import { PasswordField } from '#src/app/components/PasswordField/rebranded'
import handleTypeFromHandle from '#src/app/components/AuthenticationFlow/util/handleTypeFromHandle'
import formatHandle from '#src/app/components/AuthenticationFlow/util/formatHandle'
import { AuthIntent } from '#src/app/components/AuthenticationFlow'
import { useInitiateAuthenticationMutation } from '../../../InitiateAuthentication.generated'
import { useAuthenticationSessionStateQuery } from '../../../AuthenticationSessionState.generated'
import { useAuthenticateMutation } from '../../../Authenticate.generated'
import { AuthenticatedUserFragment } from '../../../../../fragments/AuthenticatedUser.generated'
import styles from './styles.css'

interface EmailOrPhoneLoginFieldProps {
  onIntentChanged?: (intent: AuthIntent) => void
  onLogin?: (user: AuthenticatedUserFragment) => void
  onGoogleAuthEnforced?: (googleAuthEnforced: boolean) => void
  defaultHandle?: string
  loading?: boolean
}

const EmailOrPhoneLoginField: React.FC<EmailOrPhoneLoginFieldProps> = ({
  onIntentChanged,
  onLogin,
  onGoogleAuthEnforced,
  defaultHandle = '',
  loading
}) => {
  // Current authentication state
  const authenticationSession = useAuthenticationSessionStateQuery()
  const authenticationSessionState =
    authenticationSession.data && authenticationSession.data.session.authenticationState

  // State and mutation for submitting the "handle" (email or phone number)
  const [emailOrPhone, setEmailOrPhone] = useState(defaultHandle)
  const [emailOrPhoneSubmitted, setEmailOrPhoneSubmitted] = useState(false)
  const [initiateAuthentication, initiateAuthenticationResult] = useInitiateAuthenticationMutation()

  // State and mutation for submitting a password
  const [password, setPassword] = useState('')
  const [authenticate, authenticateResult] = useAuthenticateMutation()

  // Is it an email address or a phone number?
  const handleType = handleTypeFromHandle(emailOrPhone)

  // Only show the loader in the email/phone field if it's submitting (or loading) slowly (< 500ms).
  const [showEmailOrPhoneLoader] = useDebounce(initiateAuthenticationResult.loading || loading, 500)

  // Show the password field?
  const showPasswordField =
    emailOrPhoneSubmitted &&
    initiateAuthenticationResult &&
    initiateAuthenticationResult.data &&
    initiateAuthenticationResult.data.initiateAuthentication.isValidHandle

  const passwordInputRef = useRef<HTMLInputElement>(null)
  const [passwordFieldRef, { height: passwordFieldHeight }] = useMeasure({
    debounce: 10,
    scroll: false
  })

  const passwordFieldWrapperStyle = showPasswordField
    ? {
        height: passwordFieldHeight,
        opacity: 1,
        top: 0
      }
    : {
        height: 0,
        opacity: 0,
        top: -10
      }

  /**
   * On submit, initiate authentication by email or phone.
   *
   * If it's successful, the user must complete a challenge (entering their password or
   *   a six digit code).
   */
  const handleSubmitEmailOrPhone = () => {
    setEmailOrPhoneSubmitted(true)

    initiateAuthentication({
      variables: {
        input: {
          handle: emailOrPhone,
          handleType: handleType,
          // TODO: Add support for ReCAPTCHA
          captcha: ''
        }
      }
    }).then(result => {
      if (result && result.data && result.data.initiateAuthentication.isValidHandle) {
        // If the handle is valid, there will always be a challenge.
        const challengeType = result.data.initiateAuthentication.challengeType!

        // If the challenge is a one-time-password, the user is taken to a new screen.
        //   Otherwise, the challenge type is a reusable password and a password field
        //    is shown inline.
        if (challengeType === ChallengeType.OTP) {
          onIntentChanged && onIntentChanged(AuthIntent.LoginChallengeOneTimePassword)
          onGoogleAuthEnforced && onGoogleAuthEnforced(false)
        } else if (challengeType === ChallengeType.PASSWORD) {
          if (passwordInputRef.current) {
            passwordInputRef.current.focus()
          }
          onGoogleAuthEnforced && onGoogleAuthEnforced(false)
        } else if (challengeType === ChallengeType.GOOGLE_OAUTH) {
          onGoogleAuthEnforced && onGoogleAuthEnforced(true)
        }
      }
    })
  }

  /**
   * If the user has a password (i.e. they've complete the `initiateAuthentication` step and
   *   it returned a ChallengeType of "PASSWORD"), a second step is to enter the password
   *   and submit it.
   */
  const handleSubmitPassword = () => {
    authenticate({
      variables: {
        input: {
          challenge: password,
          handle: emailOrPhone,
          handleType: handleType
        }
      }
    }).then(result => {
      if (result.data && result.data.authenticate.result === AuthenticationResult.SUCCESS) {
        onLogin && onLogin(result.data.authenticate.user!)
      }
    })
  }

  const handleSubmit = () => {
    if (showPasswordField) {
      handleSubmitPassword()
    } else {
      handleSubmitEmailOrPhone()
    }
  }

  // Was the handle not found in our database?
  const handleUnrecognized =
    initiateAuthenticationResult.data &&
    initiateAuthenticationResult.data.initiateAuthentication.isValidHandle === false

  // If so, and the user hasn't changed the input since submitting, show an error message
  let emailOrPhoneErrorMessage
  if (emailOrPhoneSubmitted && handleUnrecognized) {
    emailOrPhoneErrorMessage = `We can't find an account with that ${
      handleType === HandleType.EMAIL ? 'email address' : 'phone number'
    }. Did you sign up with your ${
      handleType === HandleType.EMAIL ? 'phone number' : 'email address'
    }?`
  }

  const passwordRejected =
    authenticateResult.data?.authenticate.result === AuthenticationResult.FAILURE
  // Display a useful error message if the user enters an incorrect password
  let passwordErrorMessage
  if (passwordRejected) {
    passwordErrorMessage = (
      <>
        Incorrect password
        {authenticationSessionState && authenticationSessionState.handle ? (
          <> for {formatHandle(authenticationSessionState.handle)}</>
        ) : (
          ''
        )}
        . Please try again, or{' '}
        <a
          onClick={() => onIntentChanged && onIntentChanged(AuthIntent.ResetPassword)}
          className={styles.errorResetPasswordLink}
        >
          reset your password
        </a>
        .
      </>
    )
  }

  const googleAuthOnly =
    initiateAuthenticationResult.data?.initiateAuthentication.challengeType ==
    ChallengeType.GOOGLE_OAUTH
  return (
    <form
      className={styles.emailOrPhoneLoginForm}
      onSubmit={handleSubmit}
      data-testid="auth-login-form"
    >
      <div data-testid="auth-email-or-phone-field">
        <TextField
          name="emailOrPhone"
          value={emailOrPhone}
          label="Email or phone number"
          onChange={value => {
            setEmailOrPhoneSubmitted(false)
            setEmailOrPhone(value)
            setPassword('')
          }}
          autoFocus
          validation={
            emailOrPhoneErrorMessage
              ? { state: 'critical', label: emailOrPhoneErrorMessage }
              : undefined
          }
          isDisabled={loading || showEmailOrPhoneLoader}
          autoComplete="tel-national"
        />
      </div>

      {!googleAuthOnly && (
        <div style={passwordFieldWrapperStyle} className={styles.passwordFieldWrapper}>
          <div ref={passwordFieldRef} data-testid="auth-password-field">
            <PasswordField
              name="password"
              label="Password"
              value={password}
              onChange={setPassword}
              validation={
                passwordErrorMessage
                  ? { state: 'critical', label: passwordErrorMessage }
                  : undefined
              }
              ref={passwordInputRef}
              autoComplete="current-password"
            />
            <a
              className={styles.inlineResetPasswordLink}
              onClick={() => onIntentChanged && onIntentChanged(AuthIntent.ResetPassword)}
            >
              <Text style="base-medium" color="learn-blue-600">
                Forgot?
              </Text>
            </a>
          </div>
        </div>
      )}
      <div data-testid="auth-submit-login">
        {!googleAuthOnly && (
          <Button
            color="learn-blue"
            fullWidth
            onPress={handleSubmit}
            isLoading={loading || showEmailOrPhoneLoader}
            isDisabled={
              emailOrPhone.length === 0 ||
              initiateAuthenticationResult.loading ||
              authenticateResult.loading
            }
          >
            Continue →
          </Button>
        )}
      </div>
    </form>
  )
}

export default EmailOrPhoneLoginField
