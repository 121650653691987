import React from 'react'
import { Grid, GridItem } from '@deal/components'
import RouteWithStatusCode from '#src/app/components/RouteWithStatusCode'
import NoIndex from '#src/app/components/NoIndex'
import Link from './link.svg'
import styles from './styles.css'

interface EntityNotFoundProps {
  header: React.ReactNode
  body?: React.ReactNode
  margins?: boolean
}

const EntityNotFound: React.FC<React.PropsWithChildren<EntityNotFoundProps>> = ({
  children,
  header,
  body,
  margins
}) => {
  return (
    <RouteWithStatusCode statusCode={404}>
      <NoIndex />
      <Grid margins={margins}>
        <GridItem col={2}>
          <div className={styles.wrapper}>
            <div className={styles.copy}>
              <div className={styles.header}>{header}</div>
              {body && <div className={styles.body}>{body}</div>}
            </div>
            <div className={styles.icon}>
              <Link className={styles.linkIcon} />
            </div>
          </div>
        </GridItem>
      </Grid>
      {children}
    </RouteWithStatusCode>
  )
}

export default EntityNotFound
