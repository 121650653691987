import mapValues from 'lodash/mapValues'
import keyBy from 'lodash/keyBy'
import { ComponentValue } from '#src/app/services/pageTemplate'
import { PageTemplateComponentValueForEngagementChannelBannerFragment } from './ContentSelectionResultForMarketingBanner.generated'

const getParameterValue = (
  componentValue: PageTemplateComponentValueForEngagementChannelBannerFragment
): ComponentValue => {
  switch (componentValue.parameterValue?.__typename) {
    case 'PageTemplateComponentFieldValueText':
      return {
        text: componentValue.parameterValue.text,
        textColor: componentValue.parameterValue.color?.value || undefined,
        fontStyles: componentValue.parameterValue.fontStyles
      }
    case 'PageTemplateComponentFieldValueImage':
      return {
        imageUrl: componentValue.parameterValue.media?.url,
        altText: componentValue.parameterValue?.altText
      }
    case 'PageTemplateComponentFieldValueButton':
      return {
        text: componentValue.parameterValue.text,
        buttonType: componentValue.parameterValue.styleDisplayName || undefined
      }
    default:
      return {}
  }
}

export const generateComponentValue = (
  componentValues: PageTemplateComponentValueForEngagementChannelBannerFragment[]
) => {
  return mapValues(keyBy(componentValues, 'parameterName'), componentValue => ({
    ...getParameterValue(componentValue)
  }))
}
