// @ts-nocheck
import type * as Types from '../../generated/types';

import type { DocumentNode } from 'graphql';
import { SellableForNormalizeSellablePricingFragmentDoc } from './SellableForNormalizeSellablePricing.generated';
import { MonetaryAmountFragmentDoc } from './MonetaryAmount.generated';
import { MediaFileFragmentDoc } from './MediaFile.generated';
import { CategoryForGetMarketableCategoryFromCategoryHierarchyFragmentDoc } from '../services/tracking/CategoryForGetMarketableCategoryFromCategoryHierarchy.generated';
export type TrackableSellableFragment = { __typename: 'Sellable', id: any, slug: string, googleId: string, title: string, feedId: string, brand?: { __typename: 'Brand', id: any, displayName: string } | null, primaryImage?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null, categories: Array<{ __typename: 'Category', id: any, slug: string, parents: Array<{ __typename: 'Category', id: any, slug: string }> }>, salePrice?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null, originalPrice?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null, variationMatrix?: { __typename: 'VariationMatrix', maximumSavingsPercent?: number | null, priceRange?: { __typename: 'VariationMatrixPriceRange', min: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string }, max: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } } | null, originalPriceRange?: { __typename: 'VariationMatrixPriceRange', min: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string }, max: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } } | null, maximumSavingsAmount?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null } | null };

export const TrackableSellableFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"TrackableSellable"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Sellable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForNormalizeSellablePricing"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}},{"kind":"Field","name":{"kind":"Name","value":"googleId"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"feedId"}},{"kind":"Field","name":{"kind":"Name","value":"brand"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"primaryImage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaFile"}}]}},{"kind":"Field","name":{"kind":"Name","value":"categories"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"CategoryForGetMarketableCategoryFromCategoryHierarchy"}}]}}]}}]} as unknown as DocumentNode;