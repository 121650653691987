import React, { createContext, useContext, useState } from 'react'

interface ChatWidgetPathContextProps {
  pathUrl: string | undefined
  isInPath: boolean
  setPathUrl: React.Dispatch<React.SetStateAction<string | undefined>>
}

const ChatWidgetPathContext = createContext<ChatWidgetPathContextProps>({
  pathUrl: undefined,
  isInPath: false,
  setPathUrl: () => {}
})

interface ChatWidgetPathContextProviderProps {}

const ChatWidgetPathContextProvider: React.FC<
  React.PropsWithChildren<ChatWidgetPathContextProviderProps>
> = ({ children }) => {
  const [pathUrl, setPathUrl] = useState<string>()

  return (
    <ChatWidgetPathContext.Provider value={{ pathUrl, isInPath: !!pathUrl, setPathUrl }}>
      {children}
    </ChatWidgetPathContext.Provider>
  )
}

const useChatWidgetPathContext = () => {
  const context = useContext(ChatWidgetPathContext)

  if (!context) {
    throw new Error('Invoked PathUrlContext outside of provider')
  }

  return context
}

export { ChatWidgetPathContextProvider, useChatWidgetPathContext }
