// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CreateProductReviewFromEmailMutationVariables = Types.Exact<{
  input: Types.CreateConsumerProductReviewInput;
}>;


export type CreateProductReviewFromEmailMutation = { __typename: 'Mutation', createConsumerProductReview: { __typename: 'CreateConsumerProductReviewPayload', consumerProductReview?: { __typename: 'ConsumerProductReview', id: any } | null, error?: { __typename: 'AlreadyReviewedCreateConsumerProductReviewError', errorMessage: string, existingConsumerProductReview: { __typename: 'ConsumerProductReview', id: any } } | { __typename: 'InvalidOrderLineItemTypeCreateConsumerProductReviewError', errorMessage: string } | { __typename: 'InvalidPostTokenConsumerProductReviewError', errorMessage: string } | { __typename: 'NotPurchasedCreateConsumerProductReviewError', errorMessage: string } | { __typename: 'ParentOrderLineItemCreateConsumerProductReviewError', errorMessage: string } | null } };


export const CreateProductReviewFromEmailDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateProductReviewFromEmail"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateConsumerProductReviewInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createConsumerProductReview"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"consumerProductReview"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"error"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ErrorMessage"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"errorMessage"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AlreadyReviewedCreateConsumerProductReviewError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"existingConsumerProductReview"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type CreateProductReviewFromEmailMutationFn = Apollo.MutationFunction<CreateProductReviewFromEmailMutation, CreateProductReviewFromEmailMutationVariables>;
export type CreateProductReviewFromEmailComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateProductReviewFromEmailMutation, CreateProductReviewFromEmailMutationVariables>, 'mutation'>;

    export const CreateProductReviewFromEmailComponent = (props: CreateProductReviewFromEmailComponentProps) => (
      <ApolloReactComponents.Mutation<CreateProductReviewFromEmailMutation, CreateProductReviewFromEmailMutationVariables> mutation={CreateProductReviewFromEmailDocument} {...props} />
    );
    

/**
 * __useCreateProductReviewFromEmailMutation__
 *
 * To run a mutation, you first call `useCreateProductReviewFromEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductReviewFromEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductReviewFromEmailMutation, { data, loading, error }] = useCreateProductReviewFromEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductReviewFromEmailMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductReviewFromEmailMutation, CreateProductReviewFromEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductReviewFromEmailMutation, CreateProductReviewFromEmailMutationVariables>(CreateProductReviewFromEmailDocument, options);
      }
export type CreateProductReviewFromEmailMutationHookResult = ReturnType<typeof useCreateProductReviewFromEmailMutation>;
export type CreateProductReviewFromEmailMutationResult = Apollo.MutationResult<CreateProductReviewFromEmailMutation>;
export type CreateProductReviewFromEmailMutationOptions = Apollo.BaseMutationOptions<CreateProductReviewFromEmailMutation, CreateProductReviewFromEmailMutationVariables>;