import React from 'react'
import classNames from 'classnames'
import styles from './styles.css'

interface PurplePillProps extends React.PropsWithChildren {
  className?: string
}

const PurplePill: React.FC<PurplePillProps> = ({ className, children }) => {
  return <div className={classNames(styles.pill, className)}>{children}</div>
}

export default PurplePill
