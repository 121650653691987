// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type RewardCreditPercentPromotionActionReferenceForBlackFridayPromotionFragment = { __typename: 'RewardCreditPercentPromotionActionReference', id: any, type: Types.PromotionActionType, percent: number };

export type PromotionForBlackFridayPromotionFragment = { __typename: 'Promotion', id: any, code: string, active: boolean, expiresAt?: any | null, marketingMessage?: string | null, actions: Array<{ __typename: 'AmountOffOrderPromotionActionReference', id: any } | { __typename: 'PercentOffLineItemsPromotionActionReference', id: any } | { __typename: 'RewardCreditPercentPromotionActionReference', id: any, type: Types.PromotionActionType, percent: number } | { __typename: 'RewardCreditPromotionActionReference', id: any }> };

export type ActivePromotionByCodeForBlackFridayPromotionQueryVariables = Types.Exact<{
  code: Types.Scalars['String']['input'];
}>;


export type ActivePromotionByCodeForBlackFridayPromotionQuery = { __typename: 'Query', activePromotionByCode?: { __typename: 'Promotion', id: any, code: string, active: boolean, expiresAt?: any | null, marketingMessage?: string | null, actions: Array<{ __typename: 'AmountOffOrderPromotionActionReference', id: any } | { __typename: 'PercentOffLineItemsPromotionActionReference', id: any } | { __typename: 'RewardCreditPercentPromotionActionReference', id: any, type: Types.PromotionActionType, percent: number } | { __typename: 'RewardCreditPromotionActionReference', id: any }> } | null };

export const RewardCreditPercentPromotionActionReferenceForBlackFridayPromotionFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"RewardCreditPercentPromotionActionReferenceForBlackFridayPromotion"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"RewardCreditPercentPromotionActionReference"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"percent"}}]}}]} as unknown as DocumentNode;
export const PromotionForBlackFridayPromotionFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PromotionForBlackFridayPromotion"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Promotion"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"active"}},{"kind":"Field","name":{"kind":"Name","value":"expiresAt"}},{"kind":"Field","name":{"kind":"Name","value":"marketingMessage"}},{"kind":"Field","name":{"kind":"Name","value":"actions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"RewardCreditPercentPromotionActionReferenceForBlackFridayPromotion"}}]}}]}}]} as unknown as DocumentNode;
export const ActivePromotionByCodeForBlackFridayPromotionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ActivePromotionByCodeForBlackFridayPromotion"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"code"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"activePromotionByCode"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"code"},"value":{"kind":"Variable","name":{"kind":"Name","value":"code"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PromotionForBlackFridayPromotion"}}]}}]}},...PromotionForBlackFridayPromotionFragmentDoc.definitions,...RewardCreditPercentPromotionActionReferenceForBlackFridayPromotionFragmentDoc.definitions]} as unknown as DocumentNode;
export type ActivePromotionByCodeForBlackFridayPromotionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ActivePromotionByCodeForBlackFridayPromotionQuery, ActivePromotionByCodeForBlackFridayPromotionQueryVariables>, 'query'> & ({ variables: ActivePromotionByCodeForBlackFridayPromotionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ActivePromotionByCodeForBlackFridayPromotionComponent = (props: ActivePromotionByCodeForBlackFridayPromotionComponentProps) => (
      <ApolloReactComponents.Query<ActivePromotionByCodeForBlackFridayPromotionQuery, ActivePromotionByCodeForBlackFridayPromotionQueryVariables> query={ActivePromotionByCodeForBlackFridayPromotionDocument} {...props} />
    );
    

/**
 * __useActivePromotionByCodeForBlackFridayPromotionQuery__
 *
 * To run a query within a React component, call `useActivePromotionByCodeForBlackFridayPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivePromotionByCodeForBlackFridayPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivePromotionByCodeForBlackFridayPromotionQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useActivePromotionByCodeForBlackFridayPromotionQuery(baseOptions: Apollo.QueryHookOptions<ActivePromotionByCodeForBlackFridayPromotionQuery, ActivePromotionByCodeForBlackFridayPromotionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivePromotionByCodeForBlackFridayPromotionQuery, ActivePromotionByCodeForBlackFridayPromotionQueryVariables>(ActivePromotionByCodeForBlackFridayPromotionDocument, options);
      }
export function useActivePromotionByCodeForBlackFridayPromotionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivePromotionByCodeForBlackFridayPromotionQuery, ActivePromotionByCodeForBlackFridayPromotionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivePromotionByCodeForBlackFridayPromotionQuery, ActivePromotionByCodeForBlackFridayPromotionQueryVariables>(ActivePromotionByCodeForBlackFridayPromotionDocument, options);
        }
export function useActivePromotionByCodeForBlackFridayPromotionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ActivePromotionByCodeForBlackFridayPromotionQuery, ActivePromotionByCodeForBlackFridayPromotionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ActivePromotionByCodeForBlackFridayPromotionQuery, ActivePromotionByCodeForBlackFridayPromotionQueryVariables>(ActivePromotionByCodeForBlackFridayPromotionDocument, options);
        }
export type ActivePromotionByCodeForBlackFridayPromotionQueryHookResult = ReturnType<typeof useActivePromotionByCodeForBlackFridayPromotionQuery>;
export type ActivePromotionByCodeForBlackFridayPromotionLazyQueryHookResult = ReturnType<typeof useActivePromotionByCodeForBlackFridayPromotionLazyQuery>;
export type ActivePromotionByCodeForBlackFridayPromotionSuspenseQueryHookResult = ReturnType<typeof useActivePromotionByCodeForBlackFridayPromotionSuspenseQuery>;
export type ActivePromotionByCodeForBlackFridayPromotionQueryResult = Apollo.QueryResult<ActivePromotionByCodeForBlackFridayPromotionQuery, ActivePromotionByCodeForBlackFridayPromotionQueryVariables>;