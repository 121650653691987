import React, { ReactNode } from 'react'
import classNames from 'classnames'
import { CircleLoader } from '@deal/components'
import { HeadlessModal, Text, useModalTrigger } from '@deal/bluxome'
import StandalonePathContainer, { StandalonePathContainerProps } from '../StandalonePath'
import styles from './styles.css'

export interface ModalPathProps extends Omit<StandalonePathContainerProps, 'pathPlacement'> {
  isOpen: boolean
  loadingPath?: boolean
  onClose?: () => void
  fallback?: ReactNode
  fixedMinHeight?: boolean
}

const ModalPath: React.FC<ModalPathProps> = ({
  isOpen,
  loadingPath = false,
  onClose,
  defaultPathGraphFlowId,
  defaultSubmitToken,
  fallback,
  fixedMinHeight = false,
  ...props
}) => {
  const { state, overlayProps } = useModalTrigger({
    isOpen: isOpen,
    onOpenChange: isOpen => {
      if (!isOpen) {
        onClose?.()
      }
    }
  })

  return (
    <HeadlessModal overlayProps={overlayProps} state={state} width="medium">
      {() => (
        <div className={classNames(styles.container, { [styles.fixedMinHeight]: fixedMinHeight })}>
          {loadingPath ? (
            <div className={styles.loader}>
              <CircleLoader />
            </div>
          ) : defaultSubmitToken || defaultPathGraphFlowId ? (
            <div className={styles.pathContainer}>
              <StandalonePathContainer
                {...props}
                pathPlacement="modal"
                defaultPathGraphFlowId={defaultPathGraphFlowId}
                defaultSubmitToken={defaultSubmitToken}
              />
            </div>
          ) : fallback ? (
            <>{fallback}</>
          ) : (
            <Text>There's been a problem, please try again.</Text>
          )}
        </div>
      )}
    </HeadlessModal>
  )
}

export { ModalPath }
