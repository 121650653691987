import React, { Component } from 'react'
import PropTypes from 'prop-types'
import hoistNonReactStatic from 'hoist-non-react-statics'
import { Config } from '#src/app/config'

function getDisplayName(WrappedComponent: React.ComponentType<React.PropsWithChildren<any>>) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export interface IWithConfigProps {
  config: Config
}

export default function withConfig<P>(
  WrappedComponent: React.ComponentType<P & IWithConfigProps>
): React.ComponentType<P> {
  class WithConfig extends Component<P, {}> {
    public context: any

    public static contextTypes = {
      config: PropTypes.object.isRequired
    }

    public static displayName = `WithConfig(${getDisplayName(WrappedComponent)})`

    public render() {
      const { config } = this.context

      return <WrappedComponent config={config} {...this.props} />
    }
  }

  return hoistNonReactStatic(WithConfig, WrappedComponent)
}
