import { ConsumerTracking, Event, ViewerContext } from '@deal/web-tracking'

export interface EngagementChannelViewedEventProperties {
  engagement_channel: ConsumerTracking.EngagementChannel
  variation: string
  category?: string
  trait?: string
}

export class EngagementChannelViewedEvent extends Event<EngagementChannelViewedEventProperties> {
  static displayName = 'Engagement Channel Viewed'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
