import React from 'react'
import { Area, Grid } from '@deal/bluxome'
import { generateGrid } from '#src/app/services/grid'
import { Skeleton } from '#src/app/components/SkeletonLoader'
import styles from './styles.css'

interface ProductsLoaderProps {
  areBreadcrumbsHidden?: boolean
}

export const ProductsLoader: React.FC<ProductsLoaderProps> = ({ areBreadcrumbsHidden = false }) => {
  const areas = generateGrid([
    {
      'image-carousel': 6
    },
    {
      'shopping-details': 6
    },
    {
      main: 6
    }
  ])

  const areasLg = generateGrid([
    {
      'image-carousel': 7,
      'shopping-details': 5
    },
    {
      main: 7,
      'shopping-details': 5
    },
    {
      main: 7,
      'shopping-details': 5
    }
  ])

  return (
    <div className={styles.page}>
      {!areBreadcrumbsHidden && (
        <Grid>
          <Area column="1 / -1">
            <div className={styles.breadcrumbs}>
              <Skeleton width={100} />
              <Skeleton width={45} />
              <Skeleton width={35} />
            </div>
          </Area>
        </Grid>
      )}

      <Grid areas={areas} areasLg={areasLg}>
        <Area name="image-carousel" className={styles.imageCarouselContainer} bleedBelow="lg">
          <Skeleton className={styles.imageCarouselSkeleton} />
        </Area>

        <Area name="shopping-details" className={styles.shoppingDetailsContainer}>
          <div className={styles.brandNameProductTitleAndId}>
            <Skeleton height={20} width={88} />
            <Skeleton className={styles.productTitleSkeleton} />
            <Skeleton height={20} width={105} />
          </div>

          <div className={styles.pricing}>
            <Skeleton className={styles.productPriceSkeleton} />
            <Skeleton height={20} width={250} />
          </div>

          <Skeleton height={1} width="100%" />

          <div className={styles.addToCart}>
            <Skeleton height={56} width="100%" />
          </div>
        </Area>

        <Area name="main" className={styles.mainContainer}>
          <Skeleton className={styles.accordionTitleSkeleton} />

          <Skeleton height={1} width="100%" />

          <Skeleton className={styles.accordionTitleSkeletonShort} />

          <Skeleton height={1} width="100%" />

          <Skeleton className={styles.accordionTitleSkeleton} />
        </Area>
      </Grid>
    </div>
  )
}

const Loader: React.FC = () => {
  return <ProductsLoader />
}

export { Loader }
