import { FacetSelectionsMap } from './types'
import { FACET_SERIALIZERS } from './serializers'

interface SerializedFacetSelection {
  name: string
  alias?: string
  value: string
  prefix: string
  bucketCount?: number
}

export function serializeFacetSelections(
  selections: FacetSelectionsMap
): SerializedFacetSelection[] {
  // Sort the facet selections by the length of facet name, then lexigraphically
  const sortedSelections = Object.values(selections).sort((a, b) => {
    const keyA = a.alias || a.name
    const keyB = b.alias || b.name

    if (keyA.length !== keyB.length) {
      return keyA.length - keyB.length
    } else {
      return keyA.localeCompare(keyB)
    }
  })

  const serializedSelections: SerializedFacetSelection[] = []

  for (const facetSelection of sortedSelections) {
    // For each selection, find the appropriate serializer for that facet selection type,
    //   based on the `isSerializable` type guard implementations.
    const serializer = FACET_SERIALIZERS.find(serializer => {
      return serializer.isSerializable(facetSelection)
    })

    if (!serializer) {
      throw new Error(
        `Could not determine the facet selection type for the "${facetSelection.name}" facet`
      )
    }

    const name = facetSelection.name
    const alias = facetSelection.alias || undefined
    const value = serializer.serialize(facetSelection)
    const prefix = serializer.SERIALIZATION_PREFIX
    const bucketCount = facetSelection.bucketValues?.length

    serializedSelections.push({
      name,
      alias,
      value,
      prefix,
      bucketCount
    })
  }

  return serializedSelections
}
