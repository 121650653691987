import React from 'react'
import { Skeleton } from '#src/app/components/SkeletonLoader'
import styles from './styles.css'

const SkeletonListItem: React.FC = () => {
  return (
    <li className={styles.row}>
      <div className={styles.preview}>
        <Skeleton width={40} height={40} circle={true} className={styles.avatarLoader} />

        <div className={styles.summary}>
          <div className={styles.metadata}>
            <div className={styles.participants}>
              <Skeleton width={100} height={12} />
            </div>
            <div className={styles.timestamp}>
              <Skeleton width={25} height={12} />
            </div>
          </div>
          <div className={styles.lastMessage}>
            <Skeleton width={150} height={12} />
          </div>
        </div>
      </div>
    </li>
  )
}

export default SkeletonListItem
