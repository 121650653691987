import React, { useContext, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import classnames from 'classnames'
import { SkipNavContent } from '@reach/skip-nav'
import { UserActionEvent } from '@deal/web-tracking'
import { usePageKey } from '@deal/router'
import { SavedProductsProvider } from '#src/app/context/savedProducts'
import {
  GlobalNotificationContext,
  GlobalNotificationDisplay
} from '#src/app/context/global-notification'
import { ChatVisibilityProvider } from '#src/app/context/chat-visibility'
import { useIdentityContext } from '#src/app/containers/Identity'
import { ContentSelectionResultForMarketingBannerFragment } from '../MarketingBanner/ContentSelectionResultForMarketingBanner.generated'
import { CategoryForGetClosestNavigationForCategoryFragment } from '../Header/mobile/components/MobileNavFlyout/MobileNavFlyout.generated'
import MarketingBanner from '../MarketingBanner'
import Header from '../Header'
import GridOverlay from '../GridOverlay'
import { FarewellModal } from '../FarewellModal'
import Cart from '../Cart'
import { BlackFridayCyberMondayModal } from '../BlackFridayCyberMondayModal'
import { ActionBar, ActionBarProps } from '../ActionBar'
import styles from './styles.css'

export interface ChromeProps {
  /**
   * If `true`, the Chrome will act as the scroll container, preventing mobile browser's
   * address bar and other browser toolbars from collapsing. This is useful to prevent
   * issues with "dead zones" on browsers like mobile Safari.
   *
   * See: https://www.eventbrite.com/engineering/mobile-safari-why/
   */
  fixed?: boolean
  contentClassName?: string
  headerClassName?: string
  hideHeader?: boolean

  /**
   * Should the chat widget be displayed when logged in?
   */
  chat?: boolean

  category?: CategoryForGetClosestNavigationForCategoryFragment

  ecContentSelectionResult?: ContentSelectionResultForMarketingBannerFragment

  actionBarProps?: Omit<ActionBarProps, 'chat'>
}

const Chrome: React.FC<React.PropsWithChildren<ChromeProps>> = ({
  fixed,
  contentClassName,
  headerClassName,
  children,
  hideHeader,
  chat = false,
  category,
  ecContentSelectionResult,
  actionBarProps
}) => {
  const { isLoggedIn } = useIdentityContext()
  const { notification } = useContext(GlobalNotificationContext)
  const showChat = chat && isLoggedIn

  const [headerHeight, setHeaderHeight] = useState(145)

  return (
    <SavedProductsProvider isEnabled={false}>
      <ChatVisibilityProvider value={showChat}>
        <GridOverlay />
        {notification && <GlobalNotificationDisplay notification={notification} />}
        {fixed && (
          <Helmet>
            <body className={styles.fixedBody} />
          </Helmet>
        )}
        {!hideHeader && (
          <Header
            className={headerClassName}
            category={category}
            onHeaderHeightChanged={setHeaderHeight}
          />
        )}
        <Cart />
        <SkipNavContent />
        <main
          className={classnames(styles.content, contentClassName)}
          id="content"
          role="main"
          style={{ '--header-height': `${headerHeight}px` } as React.CSSProperties}
        >
          <div className={styles.globalBanner} id="global-banner">
            <MarketingBanner ecContentSelectionResult={ecContentSelectionResult} />
          </div>
          {children}
        </main>
        <ActionBar chat={chat} {...actionBarProps} />
        <BlackFridayCyberMondayModal />
        <FarewellModal />
      </ChatVisibilityProvider>
    </SavedProductsProvider>
  )
}

/** Wraps Chrome with onPointerDownCapture for <a> and <button> elements that tracks
 * user actions in generic way, in case we need to check back in time roughly
 * how many times the element is being interacted. This should not replace custom tracking
 * events for specific needs but only serves as backup solution before implementing custom events.
 *
 * Tracks pageKey as `page` and href / aria-label / textContent as `element` in User Action event
 *
 * We discussed if this may be too much extra load on the tracking DB and came to conclusion
 * this should not cause any issues and the potential for having some access to events is worth it */
const ChromeWithUserActionTracking: React.FC<React.PropsWithChildren<ChromeProps>> = props => {
  const pageKey = usePageKey()
  return (
    <div
      className={styles.contentWrapper}
      onPointerDownCapture={e => {
        if (e.target instanceof Element) {
          const closestActionElement = e.target.closest('button') ?? e.target.closest('a')
          if (closestActionElement) {
            const element =
              // Preffer href for links instead of their content
              closestActionElement?.getAttribute('href') ??
              // Second best is aria-label
              closestActionElement.getAttribute('aria-label') ??
              //Bluxome button contains 4 instances of children,
              // which means textContent contains 4x the same text
              // inner text returns correct value for bluxome buttons
              closestActionElement.innerText ??
              // Fallback to have some text if nothing else is available
              closestActionElement.textContent

            const trimmedElement = element?.trim()
            if (trimmedElement?.length) {
              window.tracking?.track(
                new UserActionEvent({ page: pageKey, element: trimmedElement })
              )
            }
          }
        }
      }}
    >
      <Chrome {...props} />
    </div>
  )
}

export default ChromeWithUserActionTracking
