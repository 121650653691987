import { Event, ViewerContext } from '@deal/web-tracking'

export interface LoomInstantiatedProperties {
  lead_id?: string
}

export class LoomInstantiated extends Event<LoomInstantiatedProperties> {
  static displayName = 'Loom Instantiated'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
