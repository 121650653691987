import React, { useRef } from 'react'
import { Overlay, useOverlay } from 'react-aria'
import { Flyout, FlyoutHeader } from '@deal/components'
import clientOnly from '#src/app/hocs/clientOnly'
import { useCartVisibilityContext } from '#src/app/context/cart-visibility'
import { LoggedInCart } from '#src/app/components/ShoppingCart'
import styles from './styles.css'

const Cart: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { isCartVisible, setCartVisibility } = useCartVisibilityContext()
  const ref = useRef<HTMLDivElement>(null)

  const { overlayProps } = useOverlay({ isOpen: isCartVisible }, ref)

  return (
    <Overlay {...overlayProps}>
      <Flyout
        from="right"
        isOpen={isCartVisible}
        onClose={() => setCartVisibility(false)}
        data-testid="cart-overlay"
        width="small"
        role="region"
        aria-label="Cart"
      >
        <div ref={ref} className={'h-full flex flex-col'}>
          <FlyoutHeader
            title="Your cart"
            onClose={() => setCartVisibility(false)}
            testId="cart-header"
          />
          <div className={styles.cartContainer}>
            <LoggedInCart />
          </div>
        </div>
      </Flyout>
    </Overlay>
  )
}
export default clientOnly(Cart)
