import React, { useEffect } from 'react'
import { Button, Notification } from '@deal/components'
import { useGlobalLoaderContext } from '#src/app/context/global-loader'
import { useDepartment } from '#src/app/context/department'
import PageContent from '../PageContent'
import ChromeWithCategorySidebar from '../ChromeWithCategorySidebar'
import { ChromeProps } from '../Chrome'
import styles from './styles.css'

interface RouteLoaderProps {
  chat?: ChromeProps['chat']
  isLoading?: boolean
  pastDelay?: boolean
  timedOut?: boolean
  error?: any
  retry?: () => void
}

const RouteLoader: React.FC<React.PropsWithChildren<RouteLoaderProps>> = ({
  chat,
  isLoading = true,
  pastDelay = false,
  error,
  retry
}) => {
  const department = useDepartment()
  const { setLoading } = useGlobalLoaderContext()

  useEffect(() => {
    setLoading(isLoading && pastDelay)

    return () => {
      setLoading(false)
    }
  }, [isLoading, pastDelay])

  return (
    <ChromeWithCategorySidebar
      // Render the sidebar for the active department while loading, if navigating to a department-specific page.
      categoryId={department?.topLevelCategory.id}
      chat={chat}
    >
      {error && (
        <PageContent gridClassName={styles.page}>
          <Notification type="error">
            Something went wrong loading this page. Please check your internet connection and try
            again.
          </Notification>

          <Button onClick={retry} className={styles.retryButton}>
            Reload
          </Button>
        </PageContent>
      )}
    </ChromeWithCategorySidebar>
  )
}

export default RouteLoader
