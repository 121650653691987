import React from 'react'
import { Area, Grid } from '@deal/bluxome'
import { useBreakpoint } from '#src/app/hooks/useBreakpoint'
import { Skeleton } from '#src/app/components/SkeletonLoader'
import styles from './styles.css'

export const ProductCarouselSkeleton: React.FC = () => {
  const isLargerThanLg = useBreakpoint('lg', 'bluxome')

  return (
    <div className={styles.productCarouselSkeletonContainer}>
      <Grid>
        <Area column="span 6">
          <Skeleton height="36px" width="200px" />
        </Area>
      </Grid>
      <Grid rowGap={16}>
        {Array.from(Array(isLargerThanLg ? 4 : 2)).map((_, idx) => (
          <Area key={idx} column="span 3" columnLg="span 3">
            <Skeleton height={isLargerThanLg ? '400px' : '313px'} width="100%" />
          </Area>
        ))}
      </Grid>
    </div>
  )
}
