// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type FindContactQueryVariables = Types.Exact<{
  contactId: Types.Scalars['ContactId']['input'];
}>;


export type FindContactQuery = { __typename: 'Query', findContact?: { __typename: 'Contact', id: any, type: Types.ContactType, value: string, verified: boolean } | null };


export const FindContactDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"FindContact"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"contactId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ContactId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"findContact"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"contactId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"contactId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"verified"}}]}}]}}]} as unknown as DocumentNode;
export type FindContactComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FindContactQuery, FindContactQueryVariables>, 'query'> & ({ variables: FindContactQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FindContactComponent = (props: FindContactComponentProps) => (
      <ApolloReactComponents.Query<FindContactQuery, FindContactQueryVariables> query={FindContactDocument} {...props} />
    );
    

/**
 * __useFindContactQuery__
 *
 * To run a query within a React component, call `useFindContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindContactQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useFindContactQuery(baseOptions: Apollo.QueryHookOptions<FindContactQuery, FindContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindContactQuery, FindContactQueryVariables>(FindContactDocument, options);
      }
export function useFindContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindContactQuery, FindContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindContactQuery, FindContactQueryVariables>(FindContactDocument, options);
        }
export function useFindContactSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindContactQuery, FindContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindContactQuery, FindContactQueryVariables>(FindContactDocument, options);
        }
export type FindContactQueryHookResult = ReturnType<typeof useFindContactQuery>;
export type FindContactLazyQueryHookResult = ReturnType<typeof useFindContactLazyQuery>;
export type FindContactSuspenseQueryHookResult = ReturnType<typeof useFindContactSuspenseQuery>;
export type FindContactQueryResult = Apollo.QueryResult<FindContactQuery, FindContactQueryVariables>;