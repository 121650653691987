import React from 'react'
import { Route, RouteChildrenProps, Switch } from '@deal/router'
import Redirect from '#src/app/components/Redirect'
import { NotFound } from '#src/app/components/Errors'
import ViewWishlistsRoute from './view'
import ManageWishlistsRoute from './manage'

interface WishlistsRouteParserMatch
  extends RouteChildrenProps<{
    id?: string
  }> {}

const WishlistsRouteParser: React.FC = () => {
  return (
    <Switch>
      <Route path="/wl">
        {({ location }: WishlistsRouteParserMatch) => {
          return (
            <Redirect
              to={{
                ...location,
                pathname: location.pathname.replace(/^\/wl\//, '/wishlist/')
              }}
            />
          )
        }}
      </Route>
      <Route path="/wishlist/:id/manage">
        {({ match }: WishlistsRouteParserMatch) => {
          const id = match?.params.id
          return <ManageWishlistsRoute id={id!} />
        }}
      </Route>
      <Route path="/wishlist/:id">
        {({ match }: WishlistsRouteParserMatch) => {
          const id = match?.params.id
          return <ViewWishlistsRoute id={id!} />
        }}
      </Route>
      {/* 404 */}
      <Route component={NotFound} />
    </Switch>
  )
}

export { WishlistsRouteParser }
