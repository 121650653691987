import { CreatePathProps, useCreatePath } from '@deal/path-flow'
import { AttributeInput, AttributeType } from '#src/generated/types'
import { getOnboardingPathSlug , usePathPageTrackingCodes } from '#src/app/services/path'
import { ExpertForCreatePathForExpertFragment } from './ExpertForCreatePathFromExpert.generated'

type CreatePathTuple = [(props: CreatePathFromExpertProps) => void, { loading: boolean }]

interface CreatePathFromExpertProps {
  sourceKey: string
  expert: ExpertForCreatePathForExpertFragment
  // If true, the user will be connected with the provided expert. If false,
  //   the user will be matched with the provided *may* be connected with
  //   the provided expert, but only if they are not already matched with
  //   another expert.
  guaranteeExpertMatch?: boolean
  referrerId?: string
}

/**
 * A hook wrapping the path creation for when paths are created specifically from the expert.
 * This uses the expert's top category as a starting point, the expert ID as `expertId`, and
 * potentially referrer ID or refered by expert ID as `referredByExpertId`, so we can inject
 * the expert's attributes within the path editor
 *
 * @returns CreatePathTuple
 */
export default function useCreateRequestPathFromExpert(
  createPathProps: CreatePathProps
): CreatePathTuple {
  const [createPath, { loading }] = useCreatePath(createPathProps)
  const pageTrackingCodes = usePathPageTrackingCodes()

  return [
    ({ sourceKey, expert, guaranteeExpertMatch = true, referrerId }: CreatePathFromExpertProps) => {
      const expertCategory = expert.expertAttributes ? expert.expertAttributes.categories[0] : null
      const initialAttributes: AttributeInput[] = [
        {
          name: guaranteeExpertMatch ? 'expertId' : 'presentedExpertId',
          type: AttributeType.ID,
          idValue: expert.id
        }
      ]

      if (referrerId) {
        initialAttributes.push({
          name: 'referredByExpertId',
          type: AttributeType.ID,
          idValue: referrerId
        })
      }

      createPath({
        timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
        request: {
          pathSelector: expertCategory?.slug
            ? {
                categorySlugHierarchyLookup: expertCategory?.slug
              }
            : {
                pathSchemaSlug: getOnboardingPathSlug()
              },
          initialAttributes: initialAttributes
        },
        trackingCodes: {
          ctaName: sourceKey,
          pageKey: pageTrackingCodes.pageKey,
          originalPageContext: pageTrackingCodes.originalPageContext,
          originalPageUrl: pageTrackingCodes.originalPageUrl,
          sourceKey
        }
      })
    },
    { loading }
  ]
}
