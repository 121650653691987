import React, { useContext } from 'react'
import { useLocalStorage } from '@deal/dom-hooks'
import { SellableForProductCardFragment } from '#src/app/components/ProductCard/ProductCard.generated'
import {
  SellableForAddRecentlyViewedProductFragment,
  useRecentlyViewedProductsQuery
} from './RecentlyViewedProducts.generated'

export const RECENTLY_VIEWED_MIN_LENGTH = 2
export const RECENTLY_VIEWED_MAX_LENGTH = 10

type RecentlyViewedProductsContextType =
  | {
      recentlyViewedProducts: Array<SellableForProductCardFragment>
      addRecentlyViewedProduct: (sellable: SellableForAddRecentlyViewedProductFragment) => void
    }
  | undefined

const RecentlyViewedProductsContext =
  React.createContext<RecentlyViewedProductsContextType>(undefined)

const RecentlyViewedProductsContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [recentlyViewedProducts, setRecentlyViewedProducts] = useLocalStorage<{
    sellableIds: string[]
    updatedAt?: number
  }>('activity.recentlyViewedProducts', { sellableIds: [] })

  const addRecentlyViewedProduct = (sellable: SellableForAddRecentlyViewedProductFragment) => {
    const sellableId = sellable.parent ? sellable.parent.id : sellable.id
    const updatedRecentlyViewedProductsSellableIds = recentlyViewedProducts.sellableIds
      .slice(0, RECENTLY_VIEWED_MAX_LENGTH)
      .filter(id => id !== sellableId)
    updatedRecentlyViewedProductsSellableIds.unshift(sellableId)
    setRecentlyViewedProducts({
      sellableIds: updatedRecentlyViewedProductsSellableIds,
      updatedAt: Date.now()
    })
  }

  const {
    data: newData,
    previousData,
    loading
  } = useRecentlyViewedProductsQuery({
    variables: {
      sellableIds: recentlyViewedProducts.sellableIds
    },
    skip: recentlyViewedProducts.sellableIds.length == 0
  })
  const data = newData || previousData

  return (
    <RecentlyViewedProductsContext.Provider
      value={{
        addRecentlyViewedProduct: addRecentlyViewedProduct,
        recentlyViewedProducts: loading || !data ? [] : data?.sellables
      }}
    >
      {children}
    </RecentlyViewedProductsContext.Provider>
  )
}

const useRecentlyViewedProductsContext = () => {
  const recentlyViewedContext = useContext(RecentlyViewedProductsContext)

  if (!recentlyViewedContext) {
    throw new Error('Invoked RecentlyViewedContext outside of provider')
  }

  return recentlyViewedContext
}

export { RecentlyViewedProductsContextProvider, useRecentlyViewedProductsContext }
