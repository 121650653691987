// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type UpdateUserProfileForBrowserTimezoneMutationVariables = Types.Exact<{
  input: Types.UpdateUserProfileInput;
}>;


export type UpdateUserProfileForBrowserTimezoneMutation = { __typename: 'Mutation', updateUserProfile: { __typename: 'UpdateUserProfilePayload', errors: Array<string>, user: { __typename: 'User', id: any, knownZoneId?: string | null } } };


export const UpdateUserProfileForBrowserTimezoneDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateUserProfileForBrowserTimezone"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateUserProfileInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateUserProfile"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"knownZoneId"}}]}},{"kind":"Field","name":{"kind":"Name","value":"errors"}}]}}]}}]} as unknown as DocumentNode;
export type UpdateUserProfileForBrowserTimezoneMutationFn = Apollo.MutationFunction<UpdateUserProfileForBrowserTimezoneMutation, UpdateUserProfileForBrowserTimezoneMutationVariables>;
export type UpdateUserProfileForBrowserTimezoneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserProfileForBrowserTimezoneMutation, UpdateUserProfileForBrowserTimezoneMutationVariables>, 'mutation'>;

    export const UpdateUserProfileForBrowserTimezoneComponent = (props: UpdateUserProfileForBrowserTimezoneComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserProfileForBrowserTimezoneMutation, UpdateUserProfileForBrowserTimezoneMutationVariables> mutation={UpdateUserProfileForBrowserTimezoneDocument} {...props} />
    );
    

/**
 * __useUpdateUserProfileForBrowserTimezoneMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileForBrowserTimezoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileForBrowserTimezoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileForBrowserTimezoneMutation, { data, loading, error }] = useUpdateUserProfileForBrowserTimezoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileForBrowserTimezoneMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileForBrowserTimezoneMutation, UpdateUserProfileForBrowserTimezoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileForBrowserTimezoneMutation, UpdateUserProfileForBrowserTimezoneMutationVariables>(UpdateUserProfileForBrowserTimezoneDocument, options);
      }
export type UpdateUserProfileForBrowserTimezoneMutationHookResult = ReturnType<typeof useUpdateUserProfileForBrowserTimezoneMutation>;
export type UpdateUserProfileForBrowserTimezoneMutationResult = Apollo.MutationResult<UpdateUserProfileForBrowserTimezoneMutation>;
export type UpdateUserProfileForBrowserTimezoneMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileForBrowserTimezoneMutation, UpdateUserProfileForBrowserTimezoneMutationVariables>;