import React from 'react'
import classNames from 'classnames'
import Clearance from './clearance.svg'
import styles from './styles.css'

interface ClearanceInfoProps {
  className?: string
}

const ClearanceInfo: React.FC<ClearanceInfoProps> = ({ className }) => {
  return (
    <div className={classNames(styles.container, className)}>
      <Clearance />
      <div>
        <span className={styles.title}>Clearance Sale! </span>
        Because of deep discounts, clearance sale items are not eligible to be returned or
        exchanged.
      </div>
    </div>
  )
}

export default ClearanceInfo
