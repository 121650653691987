import store from 'store2'

const analyticsStore = store.namespace('analytics')
const checkoutStore = store.namespace('checkout')
const engagementChannelStore = store.namespace('engagementChannels')
const guestCartStore = store.namespace('guestCart')
const shopperApprovedStore = store.namespace('shopperApproved')
const postPurchaseSurveyStore = store.namespace('postPurchaseSurvey')
const walkthroughStore = store.namespace('walkthrough')
const webPushPromptStore = store.namespace('webPush')

export {
  analyticsStore,
  checkoutStore,
  engagementChannelStore,
  walkthroughStore,
  guestCartStore,
  shopperApprovedStore,
  postPurchaseSurveyStore,
  webPushPromptStore
}
