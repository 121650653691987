// @ts-nocheck
import type * as Types from '../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { MonetaryAmountFragmentDoc } from '../../../../../fragments/MonetaryAmount.generated';
export type PromotionActionReference_AmountOffOrderPromotionActionReference_Fragment = { __typename: 'AmountOffOrderPromotionActionReference', id: any, type: Types.PromotionActionType, amountOff: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } };

export type PromotionActionReference_PercentOffLineItemsPromotionActionReference_Fragment = { __typename: 'PercentOffLineItemsPromotionActionReference', id: any, percentOff: number, type: Types.PromotionActionType, maximumAmountOff?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null };

export type PromotionActionReference_RewardCreditPercentPromotionActionReference_Fragment = { __typename: 'RewardCreditPercentPromotionActionReference', id: any, type: Types.PromotionActionType };

export type PromotionActionReference_RewardCreditPromotionActionReference_Fragment = { __typename: 'RewardCreditPromotionActionReference', id: any, type: Types.PromotionActionType };

export type PromotionActionReferenceFragment = PromotionActionReference_AmountOffOrderPromotionActionReference_Fragment | PromotionActionReference_PercentOffLineItemsPromotionActionReference_Fragment | PromotionActionReference_RewardCreditPercentPromotionActionReference_Fragment | PromotionActionReference_RewardCreditPromotionActionReference_Fragment;

export type OrderNotEligibleForPromotionReason_InternalUserOnlyNotEligibleForPromotionReason_Fragment = { __typename: 'InternalUserOnlyNotEligibleForPromotionReason', message: string };

export type OrderNotEligibleForPromotionReason_OrderAmountNotEligibleForPromotionReason_Fragment = { __typename: 'OrderAmountNotEligibleForPromotionReason', message: string, percentageOfEligibleOrderAmount?: number | null, actionAppliedWhenEligible?: { __typename: 'AmountOffOrderPromotionActionReference', id: any, type: Types.PromotionActionType, amountOff: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } } | { __typename: 'PercentOffLineItemsPromotionActionReference', id: any, percentOff: number, type: Types.PromotionActionType, maximumAmountOff?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null } | { __typename: 'RewardCreditPercentPromotionActionReference', id: any, type: Types.PromotionActionType } | { __typename: 'RewardCreditPromotionActionReference', id: any, type: Types.PromotionActionType } | null };

export type OrderNotEligibleForPromotionReason_PurchasedOrderCountNotEligibleForPromotionReason_Fragment = { __typename: 'PurchasedOrderCountNotEligibleForPromotionReason', message: string };

export type OrderNotEligibleForPromotionReason_UserUsageLimitNotEligibleForPromotionReason_Fragment = { __typename: 'UserUsageLimitNotEligibleForPromotionReason', message: string };

export type OrderNotEligibleForPromotionReasonFragment = OrderNotEligibleForPromotionReason_InternalUserOnlyNotEligibleForPromotionReason_Fragment | OrderNotEligibleForPromotionReason_OrderAmountNotEligibleForPromotionReason_Fragment | OrderNotEligibleForPromotionReason_PurchasedOrderCountNotEligibleForPromotionReason_Fragment | OrderNotEligibleForPromotionReason_UserUsageLimitNotEligibleForPromotionReason_Fragment;

export type OrderLineItemNotEligibleForPromotionReason_CustomSourceItemNotEligibleForPromotionReason_Fragment = { __typename: 'CustomSourceItemNotEligibleForPromotionReason', message: string };

export type OrderLineItemNotEligibleForPromotionReason_ItemCountNotEligibleForPromotionReason_Fragment = { __typename: 'ItemCountNotEligibleForPromotionReason', message: string };

export type OrderLineItemNotEligibleForPromotionReason_ParentSourceTypeNotEligibleForPromotionReason_Fragment = { __typename: 'ParentSourceTypeNotEligibleForPromotionReason', message: string };

export type OrderLineItemNotEligibleForPromotionReason_SellableAttributeFilterNotEligibleForPromotionReason_Fragment = { __typename: 'SellableAttributeFilterNotEligibleForPromotionReason', message: string };

export type OrderLineItemNotEligibleForPromotionReason_SellableBadgeNotEligibleForPromotionReason_Fragment = { __typename: 'SellableBadgeNotEligibleForPromotionReason', message: string };

export type OrderLineItemNotEligibleForPromotionReason_SellableBrandFilterNotEligibleForPromotionReason_Fragment = { __typename: 'SellableBrandFilterNotEligibleForPromotionReason', message: string };

export type OrderLineItemNotEligibleForPromotionReason_SellableCategoryFilterNotEligibleForPromotionReason_Fragment = { __typename: 'SellableCategoryFilterNotEligibleForPromotionReason', message: string };

export type OrderLineItemNotEligibleForPromotionReason_SellableFilterNotEligibleForPromotionReason_Fragment = { __typename: 'SellableFilterNotEligibleForPromotionReason', message: string };

export type OrderLineItemNotEligibleForPromotionReason_SellableFulfillmentChannelNotEligibleForPromotionReason_Fragment = { __typename: 'SellableFulfillmentChannelNotEligibleForPromotionReason', message: string };

export type OrderLineItemNotEligibleForPromotionReason_SellableFullPriceNotEligibleForPromotionReason_Fragment = { __typename: 'SellableFullPriceNotEligibleForPromotionReason', message: string };

export type OrderLineItemNotEligibleForPromotionReason_SourceTypeNotEligibleForPromotionReason_Fragment = { __typename: 'SourceTypeNotEligibleForPromotionReason', message: string };

export type OrderLineItemNotEligibleForPromotionReasonFragment = OrderLineItemNotEligibleForPromotionReason_CustomSourceItemNotEligibleForPromotionReason_Fragment | OrderLineItemNotEligibleForPromotionReason_ItemCountNotEligibleForPromotionReason_Fragment | OrderLineItemNotEligibleForPromotionReason_ParentSourceTypeNotEligibleForPromotionReason_Fragment | OrderLineItemNotEligibleForPromotionReason_SellableAttributeFilterNotEligibleForPromotionReason_Fragment | OrderLineItemNotEligibleForPromotionReason_SellableBadgeNotEligibleForPromotionReason_Fragment | OrderLineItemNotEligibleForPromotionReason_SellableBrandFilterNotEligibleForPromotionReason_Fragment | OrderLineItemNotEligibleForPromotionReason_SellableCategoryFilterNotEligibleForPromotionReason_Fragment | OrderLineItemNotEligibleForPromotionReason_SellableFilterNotEligibleForPromotionReason_Fragment | OrderLineItemNotEligibleForPromotionReason_SellableFulfillmentChannelNotEligibleForPromotionReason_Fragment | OrderLineItemNotEligibleForPromotionReason_SellableFullPriceNotEligibleForPromotionReason_Fragment | OrderLineItemNotEligibleForPromotionReason_SourceTypeNotEligibleForPromotionReason_Fragment;

export const PromotionActionReferenceFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PromotionActionReference"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PromotionActionReference"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AmountOffOrderPromotionActionReference"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"amountOff"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MonetaryAmount"}}]}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PercentOffLineItemsPromotionActionReference"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"percentOff"}},{"kind":"Field","name":{"kind":"Name","value":"maximumAmountOff"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MonetaryAmount"}}]}}]}}]}}]} as unknown as DocumentNode;
export const OrderNotEligibleForPromotionReasonFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"OrderNotEligibleForPromotionReason"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"OrderNotEligibleForPromotionReason"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"OrderAmountNotEligibleForPromotionReason"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"percentageOfEligibleOrderAmount"}},{"kind":"Field","name":{"kind":"Name","value":"actionAppliedWhenEligible"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PromotionActionReference"}}]}}]}}]}}]} as unknown as DocumentNode;
export const OrderLineItemNotEligibleForPromotionReasonFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"OrderLineItemNotEligibleForPromotionReason"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"OrderLineItemNotEligibleForPromotionReason"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]} as unknown as DocumentNode;