import { Event, ViewerContext } from '@deal/web-tracking'

export interface SessionAssociatedEventProperties {
  context_id: string
  reason: string | null
}

export class SessionAssociatedEvent extends Event<SessionAssociatedEventProperties> {
  static displayName = 'Session Associated'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
