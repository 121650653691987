import React, { useEffect } from 'react'
import { useChatWidgetPathContext } from '#src/app/context/chatWidgetPath'
import { ChatWidgetBase } from '#src/app/components/ChatWidgetBase'
import { BusinessUserForChatWidgetFragment } from './ChatWidget.generated'
import { Chat, ChatProps } from '../Chat'

export interface ChatWidgetProps extends ChatProps {
  expert: BusinessUserForChatWidgetFragment
  isAI: boolean
}

export const ChatWidget: React.FC<ChatWidgetProps> = ({ expert, isAI, leadId, ...chatProps }) => {
  const { pathUrl, setPathUrl } = useChatWidgetPathContext()

  useEffect(() => {
    const handleMessage = (event: MessageEvent<any>) => {
      if (
        event.origin !== 'https://www.curated.com' &&
        event.origin !== 'https://www.curated-staging.com' &&
        event.origin !== 'https://www.local.curated-staging.com'
      ) {
        return
      }

      if (event.data['type'] === 'pathCompleted' && event.data['redirectResult']) {
        switch (event.data['redirectResult']) {
          case 'ProductRecommendationSuccessRedirectPathNodeFlowViewModelSuccess':
            setPathUrl(undefined)
            break
        }
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [setPathUrl])

  return (
    <ChatWidgetBase expert={expert} isAI={isAI}>
      {pathUrl ? (
        <iframe style={{ height: '100%', width: '100%' }} src={pathUrl} />
      ) : (
        <Chat expert={expert} isAI={isAI} leadId={leadId} {...chatProps} />
      )}
    </ChatWidgetBase>
  )
}
