// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ReleaseConsumerIdentityMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type ReleaseConsumerIdentityMutation = { __typename: 'Mutation', releaseConsumerIdentity: { __typename: 'ReleaseConsumerIdentityPayload', user?: { __typename: 'User', id: any, firstName?: string | null, lastName?: string | null } | null } };


export const ReleaseConsumerIdentityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ReleaseConsumerIdentity"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"releaseConsumerIdentity"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}}]}}]}}]}}]} as unknown as DocumentNode;
export type ReleaseConsumerIdentityMutationFn = Apollo.MutationFunction<ReleaseConsumerIdentityMutation, ReleaseConsumerIdentityMutationVariables>;
export type ReleaseConsumerIdentityComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReleaseConsumerIdentityMutation, ReleaseConsumerIdentityMutationVariables>, 'mutation'>;

    export const ReleaseConsumerIdentityComponent = (props: ReleaseConsumerIdentityComponentProps) => (
      <ApolloReactComponents.Mutation<ReleaseConsumerIdentityMutation, ReleaseConsumerIdentityMutationVariables> mutation={ReleaseConsumerIdentityDocument} {...props} />
    );
    

/**
 * __useReleaseConsumerIdentityMutation__
 *
 * To run a mutation, you first call `useReleaseConsumerIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseConsumerIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseConsumerIdentityMutation, { data, loading, error }] = useReleaseConsumerIdentityMutation({
 *   variables: {
 *   },
 * });
 */
export function useReleaseConsumerIdentityMutation(baseOptions?: Apollo.MutationHookOptions<ReleaseConsumerIdentityMutation, ReleaseConsumerIdentityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReleaseConsumerIdentityMutation, ReleaseConsumerIdentityMutationVariables>(ReleaseConsumerIdentityDocument, options);
      }
export type ReleaseConsumerIdentityMutationHookResult = ReturnType<typeof useReleaseConsumerIdentityMutation>;
export type ReleaseConsumerIdentityMutationResult = Apollo.MutationResult<ReleaseConsumerIdentityMutation>;
export type ReleaseConsumerIdentityMutationOptions = Apollo.BaseMutationOptions<ReleaseConsumerIdentityMutation, ReleaseConsumerIdentityMutationVariables>;