import config from '#src/app/config'

export async function getPushSubscription() {
  const registration = await navigator.serviceWorker.getRegistration()
  return await registration?.pushManager?.getSubscription()
}

export async function createPushSubscription() {
  const registration = await navigator.serviceWorker.getRegistration()
  const applicationServerPublicKey = config.get('webPush.applicationServerPublicKey')
  const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey)

  return await registration?.pushManager?.subscribe({
    userVisibleOnly: true,
    applicationServerKey: applicationServerKey
  })
}

function urlB64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}
