// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SellableForSaveToCurationButtonLegacyFragment = { __typename: 'Sellable', id: any, variantParent: boolean, savedSellable?: { __typename: 'SavedSellable', id: any, saved: boolean, savedReason?: Types.SavedSellableReason | null } | null, parent?: { __typename: 'Sellable', id: any } | null };

export type SaveSellableForReviewLegacyMutationVariables = Types.Exact<{
  input: Types.SaveSellableForReviewInput;
}>;


export type SaveSellableForReviewLegacyMutation = { __typename: 'Mutation', saveSellableForReview: { __typename: 'SaveSellableForReviewPayload', savedSellable?: { __typename: 'SavedSellable', id: any, sellable: { __typename: 'Sellable', id: any, savedSellable?: { __typename: 'SavedSellable', id: any, saved: boolean, savedReason?: Types.SavedSellableReason | null } | null } } | null, error?: { __typename: 'DefaultErrorMessage', errorMessage: string } | null } };

export const SellableForSaveToCurationButtonLegacyFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableForSaveToCurationButtonLegacy"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Sellable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"savedSellable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"saved"}},{"kind":"Field","name":{"kind":"Name","value":"savedReason"}}]}},{"kind":"Field","name":{"kind":"Name","value":"variantParent"}},{"kind":"Field","name":{"kind":"Name","value":"parent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export const SaveSellableForReviewLegacyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SaveSellableForReviewLegacy"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SaveSellableForReviewInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"saveSellableForReview"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"savedSellable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"sellable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"savedSellable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"saved"}},{"kind":"Field","name":{"kind":"Name","value":"savedReason"}}]}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"error"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ErrorMessage"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"errorMessage"}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type SaveSellableForReviewLegacyMutationFn = Apollo.MutationFunction<SaveSellableForReviewLegacyMutation, SaveSellableForReviewLegacyMutationVariables>;
export type SaveSellableForReviewLegacyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SaveSellableForReviewLegacyMutation, SaveSellableForReviewLegacyMutationVariables>, 'mutation'>;

    export const SaveSellableForReviewLegacyComponent = (props: SaveSellableForReviewLegacyComponentProps) => (
      <ApolloReactComponents.Mutation<SaveSellableForReviewLegacyMutation, SaveSellableForReviewLegacyMutationVariables> mutation={SaveSellableForReviewLegacyDocument} {...props} />
    );
    

/**
 * __useSaveSellableForReviewLegacyMutation__
 *
 * To run a mutation, you first call `useSaveSellableForReviewLegacyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSellableForReviewLegacyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSellableForReviewLegacyMutation, { data, loading, error }] = useSaveSellableForReviewLegacyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveSellableForReviewLegacyMutation(baseOptions?: Apollo.MutationHookOptions<SaveSellableForReviewLegacyMutation, SaveSellableForReviewLegacyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveSellableForReviewLegacyMutation, SaveSellableForReviewLegacyMutationVariables>(SaveSellableForReviewLegacyDocument, options);
      }
export type SaveSellableForReviewLegacyMutationHookResult = ReturnType<typeof useSaveSellableForReviewLegacyMutation>;
export type SaveSellableForReviewLegacyMutationResult = Apollo.MutationResult<SaveSellableForReviewLegacyMutation>;
export type SaveSellableForReviewLegacyMutationOptions = Apollo.BaseMutationOptions<SaveSellableForReviewLegacyMutation, SaveSellableForReviewLegacyMutationVariables>;