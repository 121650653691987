import * as History from 'history'
import { CategoryPageQuery } from './types'

export interface SerializableCategoryPageQuery extends CategoryPageQuery {}

/**
 * Build a URL for a category page. Category pages are a sort of "homepage" for select
 *   high level categories. The category pages contain recommended products and links
 *   PLPs for more specific categories. Additionally, cateogry pages support marketing
 *   features, such as engagement channels and Page Builder functionality.
 *
 * Some example Category Page URLs might look like:
 *
 *  /shop/skiing
 *  /shop/golf
 *  /shop/kitchen
 */
export function buildCategoryPageUrl(
  query: SerializableCategoryPageQuery,
  currentSearchParam: URLSearchParams,
  isBot: boolean
): History.Location {
  const pathname = `/shop/${query.categorySlug}`
  const searchParams = new URLSearchParams()

  // Add tracking parameters. These are not included for bots, to prevent crawlers from discovering
  //   a profileration of unique URLs.
  if (!isBot) {
    // Include a `source` parameter for tracking.
    if (query.entryPoint) {
      searchParams.append('source', query.entryPoint)
    }

    // The "k" and "campaign_id" params are used in the SRP content selection rules to target email
    //   campaigns. We must persist the value so that the page elements remain the same as the user
    //   navigates from one SRP to the next.
    const marketingKeyword = currentSearchParam.get('ctx') || currentSearchParam.get('k')
    if (marketingKeyword) {
      searchParams.set('k', marketingKeyword)
    }

    const marketingCampaignId = currentSearchParam.get('campaign_id')
    if (marketingCampaignId) {
      searchParams.set('campaign_id', marketingCampaignId)
    }
  }

  // TODO: Handle marketing and tracking params (source, adContext, ruleId, promoptionIds, etc.)
  return History.createLocation({
    pathname,
    search: searchParams.toString()
  })
}
