import React, { useContext, useState } from 'react'
import { UAParser } from 'ua-parser-js'
import { toast } from 'react-toastify'
import { useHistory, usePageKey } from '@deal/router'
import { AttributeType, StartExpertConsumerConversationSourcePageInput } from '#src/generated/types'
import smsLink from '#src/app/services/smsLink'
import { formatPathUrl, useCreateRequestPath } from '#src/app/services/path'
import loggerClient from '#src/app/services/loggerClient'
import { useStartExpertConsumerConversationMutation } from '#src/app/mutations/StartExpertConsumerConversation.generated'
import usePreferredExpert from '#src/app/hooks/usePreferredExpert'
import { SessionAssociatedEvent } from '#src/app/events/SessionAssociatedEvent'
import { useAnalyticsContext } from '#src/app/containers/Analytics'
import MessageSentToast from '#src/app/components/MessageSentToast'
import { useRecommendedExpertForConversationStartersLazyQuery } from '../../components/ExpertConversationStarters/RecommendedExpertForConversationStarters.generated'
import { usePreparePrefillSmsConversationStarterPathForSmsModalMutation } from './PreparePrefillSmsConversationStarterPath.generated'
import { useCurrentExpert } from '../expert'

type CreateConversationInput = {
  userId?: string
  expertId?: string
  conversationStarterSuggestion: string
  sourcePage: StartExpertConsumerConversationSourcePageInput
  categoryId: string
  categorySlug: string
  deliveryMethod: 'direct' | 'path' | 'prefill-sms-path'
}

type ExpertConversationStartersContext = {
  createConversation: (input: CreateConversationInput) => Promise<void>
}

const ExpertConversationStartersContext = React.createContext<ExpertConversationStartersContext>({
  createConversation: () => Promise.reject()
})

const { Consumer, Provider } = ExpertConversationStartersContext

const userFacingErrorMessage =
  'Shoot, we tried messaging an expert but something went wrong. Try sending a message yourself!'

const ExpertConversationStartersContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children
}) => {
  const history = useHistory()
  const analytics = useAnalyticsContext()
  const { setExpert } = useCurrentExpert()
  const preferredExpertVanityId = usePreferredExpert()
  const pageKey = usePageKey()
  const [showConfirmationToast, setShowConfirmationToast] = useState(false)

  const [startExpertConsumerConversation] = useStartExpertConsumerConversationMutation({
    refetchQueries: ['GetBusinessUserForChatByCategoryId'],
    onCompleted: data => {
      if (!data || !data.startExpertConsumerConversation.conversation?.id) {
        loggerClient.captureError(
          new Error('No conversation returned from startExpertConsumerConversation')
        )

        toast.error(userFacingErrorMessage)
        return
      }

      const expert = data.startExpertConsumerConversation.conversation.lead?.expert
      if (expert) {
        setExpert(expert)
      }

      setShowConfirmationToast(true)
    },
    onError: () => {
      toast.error(userFacingErrorMessage)
    }
  })

  const [createPath] = useCreateRequestPath({
    onPathCreated: ({ pathGraphFlow, pathGraphFlowSlug, nodeSlugOrId }) => {
      if (pathGraphFlow) {
        history.push(
          formatPathUrl({
            pathGraphFlowId: pathGraphFlow.pathGraphFlowId,
            nodeSlugOrId,
            pathGraphFlowSlug
          })
        )
      } else {
        throw new Error(
          'Error creating path for `ExpertConversationStartersContextProvider` component!'
        )
      }
    },
    onError: ({ errorMessage }) => {
      toast.error(errorMessage)
      throw new Error(errorMessage)
    }
  })

  const [preparePrefillSmsConversationStarterPath] =
    usePreparePrefillSmsConversationStarterPathForSmsModalMutation()

  const [fetchRecommendedExpert] = useRecommendedExpertForConversationStartersLazyQuery({
    // network-only policy so that this query and onComplete callback can be run multiple-times
    fetchPolicy: 'network-only'
  })

  // Create a conversation with the recommended expert (or launch a path that will do so).
  const createConversation = async ({
    userId,
    expertId,
    deliveryMethod,
    categoryId,
    categorySlug,
    sourcePage,
    conversationStarterSuggestion
  }: CreateConversationInput) => {
    const expertIdValue =
      expertId ||
      (await fetchRecommendedExpert({ variables: { categoryId, preferredExpertVanityId } }).then(
        ({ data }) => data?.category.recommendExpertForConsumer.expert?.id || null
      ))

    if (!expertIdValue) {
      toast.error('no real experts available')
      return
    }

    if (deliveryMethod === 'direct' && !userId) {
      loggerClient.logWarning(
        'attempted to start a direct conversation without a userId, switching to path'
      )
      deliveryMethod = 'path'
    }

    switch (deliveryMethod) {
      case 'direct': {
        const sourceFlow = {
          conversationStarterFlow: {
            title: conversationStarterSuggestion,
            message: conversationStarterSuggestion
          }
        }
        startExpertConsumerConversation({
          variables: {
            input: {
              consumerId: userId,
              expertId: expertIdValue,
              sourceFlow,
              sourcePage
            }
          }
        })
        break
      }

      case 'path': {
        const initialAttributes = [
          {
            name: 'presentedExpertId',
            type: AttributeType.ID,
            idValue: expertIdValue
          },
          {
            name: 'conversationStarterQuestion',
            type: AttributeType.TEXT,
            text: conversationStarterSuggestion
          }
        ]
        if (sourcePage.sellablePage?.sellableId) {
          initialAttributes.push({
            name: 'conversationStarterSellableId',
            type: AttributeType.ID,
            idValue: sourcePage.sellablePage?.sellableId
          })
        }

        createPath({
          initialAttributes,
          pathSelector: { categorySlugHierarchyLookup: categorySlug },
          trackingCodes: { ctaName: 'conversation-starters', sourceKey: 'conversation-starters' }
        })
        break
      }

      case 'prefill-sms-path': {
        // This doesn't appear to be used anywhere in the codebase, but I'm keeping it in for future reference.
        // — TH, 2024-07-25
        const { data: prefillData } = await preparePrefillSmsConversationStarterPath({
          variables: {
            input: {
              conversationStarterQuestion: conversationStarterSuggestion,
              expertId: expertIdValue,
              categorySlug,
              pageKey
            }
          }
        })
        const os = new UAParser().getOS()
        if (!prefillData) {
          toast.error(userFacingErrorMessage)
          return
        }
        analytics?.track(
          new SessionAssociatedEvent({
            context_id: prefillData.preparePrefillSmsConversationStarterPath.userRegistrationId,
            reason: 'SMS Client Opened'
          })
        )
        window.location.replace(
          smsLink({
            os,
            phone: prefillData.preparePrefillSmsConversationStarterPath.smsNumber,
            body: `[ID: ${prefillData.preparePrefillSmsConversationStarterPath.shortyHandle}] ${conversationStarterSuggestion}`
          })
        )
        break
      }

      default: {
        loggerClient.logError(new Error('No delivery method specified.'))
        break
      }
    }
  }

  return (
    <>
      {showConfirmationToast && (
        <MessageSentToast
          headerText="Question sent to your expert"
          subheaderText="Your expert is currently online. It may take a moment for your question to appear in the chat."
          duration={3500}
          onTimeout={() => setShowConfirmationToast(false)}
        />
      )}
      <Provider value={{ createConversation }}>{children}</Provider>
    </>
  )
}

const useExpertConversationStartersContext = () => {
  const conversationStarterContext = useContext(ExpertConversationStartersContext)

  if (!ExpertConversationStartersContext) {
    throw new Error('Invoked ExpertConversationStartersContext outside of provider')
  }

  return conversationStarterContext
}

export {
  ExpertConversationStartersContextProvider,
  ExpertConversationStartersContext,
  Consumer as ExpertConversationStartersContextConsumer,
  useExpertConversationStartersContext
}
