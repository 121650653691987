import { toast } from 'react-toastify'
import { CreatePathProps, useCreatePath } from '@deal/path-flow'
import { AttributeInput, AttributeType } from '#src/generated/types'
import { usePathPageTrackingCodes } from '#src/app/services/path'
import loggerClient from '#src/app/services/loggerClient'
import { SellableForCreatePathFromSellableFragment } from './SellableForCreatePathFromSellable.generated'

type CreatePathTuple = [(props: CreatePathFromSellableProps) => void, { loading: boolean }]

export interface CreatePathFromSellableProps {
  sourceKey: string
  sellable: SellableForCreatePathFromSellableFragment
  pageVariation?: string
  initialAttributes?: AttributeInput[]
}

/**
 * A hook wrapping the path creation for when paths are created specifically from a sellable. This
 * uses the sellable's top category as a starting point and the sellable ID as `viewedSellableIds`
 * so we can inject the sellable's attributes within the path editor
 *
 * @returns CreatePathTuple
 */
export default function useCreateRequestPathFromSellable(
  createPathProps: CreatePathProps
): CreatePathTuple {
  const pageTrackingCodes = usePathPageTrackingCodes()
  const [createPath, { loading }] = useCreatePath(createPathProps)

  return [
    ({
      sourceKey,
      sellable,
      pageVariation,
      initialAttributes = []
    }: CreatePathFromSellableProps) => {
      const sellableCategory = sellable.categories[0].slug
      if (!sellableCategory) {
        loggerClient.captureError(
          new Error('Attempted to create path from sellable without a category'),
          {
            sellableId: sellable.id
          }
        )

        toast.error('Error starting a new quiz for this product!')
        return
      }

      createPath({
        timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
        request: {
          pathSelector: {
            categorySlugHierarchyLookup: sellable.categories[0].slug
          },
          initialAttributes: [
            {
              name: 'viewedSellableIds',
              type: AttributeType.ID,
              idValue: sellable.id
            },
            ...initialAttributes
          ]
        },
        trackingCodes: {
          ctaName: sourceKey,
          pageKey: pageTrackingCodes.pageKey,
          originalPageContext: pageTrackingCodes.originalPageContext,
          originalPageUrl: pageTrackingCodes.originalPageUrl,
          sourceKey,
          pageVariation
        }
      })
    },
    { loading }
  ]
}
