import React from 'react'
import { Area, Divider, Grid } from '@deal/bluxome'
import { Skeleton } from '#src/app/components/SkeletonLoader'
import styles from './styles.css'

interface SearchRouteLoaderProps {}

const SearchRouteLoader: React.FC<React.PropsWithChildren<SearchRouteLoaderProps>> = () => {
  const areas = `
    "b b b b b b"
  `

  const areasLg = `
    "a a a b b b b b b b b b"
  `

  return (
    <>
      <div className={styles.keyboardInput} />
      <Divider />
      <div className={styles.grid}>
        <Grid areas={areas} areasLg={areasLg}>
          <Area name="a" />

          <Area name="b">
            <div className={styles.realGrid}>
              {new Array(12).fill(null).map((_, index) => (
                <div className={styles.result} key={index}>
                  <Skeleton className={styles.imageSkeleton} />
                </div>
              ))}
            </div>
          </Area>
        </Grid>
      </div>
    </>
  )
}

export default SearchRouteLoader
