// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { AuthenticationStateFragmentDoc } from './AuthenticationState.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type AuthenticationSessionStateQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AuthenticationSessionStateQuery = { __typename: 'Query', session: { __typename: 'Session', id: any, authenticationMethod?: Types.AuthenticationMethod | null, authenticationState: { __typename: 'AuthenticationSessionState', handle?: string | null, handleId?: any | null, loginChallengeType?: Types.ChallengeType | null, resetPasswordChallengeVerified: boolean } } };


export const AuthenticationSessionStateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AuthenticationSessionState"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"session"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AuthenticationState"}}]}}]}},...AuthenticationStateFragmentDoc.definitions]} as unknown as DocumentNode;
export type AuthenticationSessionStateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AuthenticationSessionStateQuery, AuthenticationSessionStateQueryVariables>, 'query'>;

    export const AuthenticationSessionStateComponent = (props: AuthenticationSessionStateComponentProps) => (
      <ApolloReactComponents.Query<AuthenticationSessionStateQuery, AuthenticationSessionStateQueryVariables> query={AuthenticationSessionStateDocument} {...props} />
    );
    

/**
 * __useAuthenticationSessionStateQuery__
 *
 * To run a query within a React component, call `useAuthenticationSessionStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthenticationSessionStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthenticationSessionStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthenticationSessionStateQuery(baseOptions?: Apollo.QueryHookOptions<AuthenticationSessionStateQuery, AuthenticationSessionStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthenticationSessionStateQuery, AuthenticationSessionStateQueryVariables>(AuthenticationSessionStateDocument, options);
      }
export function useAuthenticationSessionStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthenticationSessionStateQuery, AuthenticationSessionStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthenticationSessionStateQuery, AuthenticationSessionStateQueryVariables>(AuthenticationSessionStateDocument, options);
        }
export function useAuthenticationSessionStateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AuthenticationSessionStateQuery, AuthenticationSessionStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AuthenticationSessionStateQuery, AuthenticationSessionStateQueryVariables>(AuthenticationSessionStateDocument, options);
        }
export type AuthenticationSessionStateQueryHookResult = ReturnType<typeof useAuthenticationSessionStateQuery>;
export type AuthenticationSessionStateLazyQueryHookResult = ReturnType<typeof useAuthenticationSessionStateLazyQuery>;
export type AuthenticationSessionStateSuspenseQueryHookResult = ReturnType<typeof useAuthenticationSessionStateSuspenseQuery>;
export type AuthenticationSessionStateQueryResult = Apollo.QueryResult<AuthenticationSessionStateQuery, AuthenticationSessionStateQueryVariables>;