import React from 'react'
import { PageKeyPriority, Route, Switch } from '@deal/router'
import { protect } from '#src/app/containers/Identity'
import InboxConversationRoute from './inbox-conversation'
import InboxRoute from './inbox'

const InboxRouteParser: React.FC<React.PropsWithChildren<{}>> = () => {
  return (
    <Switch>
      <Route
        path="/inbox/:conversationId"
        pageKey="inbox-conversation"
        priority={PageKeyPriority.PAGE}
        render={({ match }) => {
          const conversationId = match.params.conversationId!
          return <InboxConversationRoute conversationId={conversationId} />
        }}
      />
      <Route path="/inbox" pageKey="inbox" priority={PageKeyPriority.PAGE} component={InboxRoute} />
    </Switch>
  )
}

export default protect(InboxRouteParser)
