// @ts-nocheck
import type * as Types from '../../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { WishlistForSetWishlistVisibilityFieldFragmentDoc } from '../SetWishlistVisibilityField/WishlistForSetWishlistVisibilityField.generated';
import { WishlistForCopyRegistryLinkFragmentDoc } from '../../../../components/CopyRegistryLink/CopyRegistryLink.generated';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type WishlistForSetWishlistVisibilityPopoverFragment = { __typename: 'Wishlist', id: any, visibility: Types.WishlistVisibility, state: Types.WishlistState, friendlyId?: string | null, title: string, shippingAddress?: { __typename: 'Address', id: any } | null };

export type SetWishlistVisibilityForSetWishlistVisibilityPopoverMutationVariables = Types.Exact<{
  wishlistId: Types.Scalars['WishlistId']['input'];
  visibility: Types.WishlistVisibility;
}>;


export type SetWishlistVisibilityForSetWishlistVisibilityPopoverMutation = { __typename: 'Mutation', setWishlistVisibility: { __typename: 'SetWishlistVisibilityPayload', wishlist: { __typename: 'Wishlist', id: any, visibility: Types.WishlistVisibility } } };

export const WishlistForSetWishlistVisibilityPopoverFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"WishlistForSetWishlistVisibilityPopover"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Wishlist"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"visibility"}},{"kind":"Field","name":{"kind":"Name","value":"state"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"WishlistForSetWishlistVisibilityField"}}]}}]} as unknown as DocumentNode;
export const SetWishlistVisibilityForSetWishlistVisibilityPopoverDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetWishlistVisibilityForSetWishlistVisibilityPopover"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"wishlistId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"WishlistId"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"visibility"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"WishlistVisibility"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setWishlistVisibility"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"wishlistId"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"visibility"},"value":{"kind":"Variable","name":{"kind":"Name","value":"visibility"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"wishlist"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"visibility"}}]}}]}}]}}]} as unknown as DocumentNode;
export type SetWishlistVisibilityForSetWishlistVisibilityPopoverMutationFn = Apollo.MutationFunction<SetWishlistVisibilityForSetWishlistVisibilityPopoverMutation, SetWishlistVisibilityForSetWishlistVisibilityPopoverMutationVariables>;
export type SetWishlistVisibilityForSetWishlistVisibilityPopoverComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetWishlistVisibilityForSetWishlistVisibilityPopoverMutation, SetWishlistVisibilityForSetWishlistVisibilityPopoverMutationVariables>, 'mutation'>;

    export const SetWishlistVisibilityForSetWishlistVisibilityPopoverComponent = (props: SetWishlistVisibilityForSetWishlistVisibilityPopoverComponentProps) => (
      <ApolloReactComponents.Mutation<SetWishlistVisibilityForSetWishlistVisibilityPopoverMutation, SetWishlistVisibilityForSetWishlistVisibilityPopoverMutationVariables> mutation={SetWishlistVisibilityForSetWishlistVisibilityPopoverDocument} {...props} />
    );
    

/**
 * __useSetWishlistVisibilityForSetWishlistVisibilityPopoverMutation__
 *
 * To run a mutation, you first call `useSetWishlistVisibilityForSetWishlistVisibilityPopoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWishlistVisibilityForSetWishlistVisibilityPopoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWishlistVisibilityForSetWishlistVisibilityPopoverMutation, { data, loading, error }] = useSetWishlistVisibilityForSetWishlistVisibilityPopoverMutation({
 *   variables: {
 *      wishlistId: // value for 'wishlistId'
 *      visibility: // value for 'visibility'
 *   },
 * });
 */
export function useSetWishlistVisibilityForSetWishlistVisibilityPopoverMutation(baseOptions?: Apollo.MutationHookOptions<SetWishlistVisibilityForSetWishlistVisibilityPopoverMutation, SetWishlistVisibilityForSetWishlistVisibilityPopoverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetWishlistVisibilityForSetWishlistVisibilityPopoverMutation, SetWishlistVisibilityForSetWishlistVisibilityPopoverMutationVariables>(SetWishlistVisibilityForSetWishlistVisibilityPopoverDocument, options);
      }
export type SetWishlistVisibilityForSetWishlistVisibilityPopoverMutationHookResult = ReturnType<typeof useSetWishlistVisibilityForSetWishlistVisibilityPopoverMutation>;
export type SetWishlistVisibilityForSetWishlistVisibilityPopoverMutationResult = Apollo.MutationResult<SetWishlistVisibilityForSetWishlistVisibilityPopoverMutation>;
export type SetWishlistVisibilityForSetWishlistVisibilityPopoverMutationOptions = Apollo.BaseMutationOptions<SetWishlistVisibilityForSetWishlistVisibilityPopoverMutation, SetWishlistVisibilityForSetWishlistVisibilityPopoverMutationVariables>;