import { ConsumerTracking, Event, ViewerContext } from '@deal/web-tracking'

export interface ProductViewEndedEventProperties extends ConsumerTracking.SellableProperties {
  activity_id: string
}

export class ProductViewEndedEvent extends Event<ProductViewEndedEventProperties> {
  static displayName = 'Product View Ended'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
