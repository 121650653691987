import React, { useState } from 'react'
import { TextField } from '@deal/bluxome'
import {
  UseConversationStartersV2Props,
  useConversationStartersV2
} from '../useConversationStartersV2'
import SendIcon from './send.svg'
import styles from './styles.css'

export interface ConversationStartersV2TextfieldProps extends UseConversationStartersV2Props {
  infoButton?: (props: {
    loading: boolean
    sendAction: (message: string, onCompleted?: () => void) => void
  }) => React.ReactNode
  onSubmit?: () => void
}

const ConversationStartersV2Textfield: React.FC<ConversationStartersV2TextfieldProps> = ({
  infoButton,
  ...rest
}) => {
  const [text, setText] = useState('')

  const { sendAction, loading } = useConversationStartersV2(rest)
  const send = (message: string) => {
    sendAction(message)
    setText('')
  }

  return (
    <div className={styles.textfield}>
      <TextField
        label="Ask scout anything"
        size="base"
        value={text}
        isDisabled={loading}
        onChange={setText}
        border="rounded"
        onKeyDown={e => {
          if (e.key === 'Enter' && text.length) {
            e.preventDefault()
            send(text)
            e.currentTarget.blur()
          }
        }}
      >
        <button
          aria-label="Send"
          className={styles.sendButton}
          onClick={() => send(text.length ? text : 'Tell me about this product')}
          disabled={loading}
        >
          <SendIcon />
        </button>
      </TextField>
      {infoButton && infoButton({ loading, sendAction })}
    </div>
  )
}

export { ConversationStartersV2Textfield }
