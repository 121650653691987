// @ts-nocheck
import type * as Types from '../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { SellableForProductReviewItemFragmentDoc, OrderLineItemForProductReviewItemFragmentDoc } from './ProductReviewItem/ProductReviewItem.generated';
export type ConsumerProductReviewTargetForProductReviewItemsListFragment = { __typename: 'ConsumerProductReviewTarget', purchasedSellable: { __typename: 'Sellable', id: any, title: string, primaryImage?: { __typename: 'MediaFile', id: any, url: string } | null }, orderLineItem: { __typename: 'OrderLineItem', id: any, order: { __typename: 'Order', id: any, paidAt?: any | null, sortTimestamp: any } } };

export type ConsumerProductReviewForProductReviewItemsListFragment = { __typename: 'ConsumerProductReview', id: any, rating: number, title?: string | null, comments?: string | null, recommended?: boolean | null, sellable: { __typename: 'Sellable', id: any, title: string, primaryImage?: { __typename: 'MediaFile', id: any, url: string } | null }, source: { __typename: 'ConsumerProductReviewSourceCurated', purchasedAt: any } | { __typename: 'ConsumerProductReviewSourceImported' } };

export const ConsumerProductReviewTargetForProductReviewItemsListFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ConsumerProductReviewTargetForProductReviewItemsList"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ConsumerProductReviewTarget"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"purchasedSellable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForProductReviewItem"}}]}},{"kind":"Field","name":{"kind":"Name","value":"orderLineItem"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OrderLineItemForProductReviewItem"}}]}}]}}]} as unknown as DocumentNode;
export const ConsumerProductReviewForProductReviewItemsListFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ConsumerProductReviewForProductReviewItemsList"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ConsumerProductReview"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"rating"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"comments"}},{"kind":"Field","name":{"kind":"Name","value":"recommended"}},{"kind":"Field","name":{"kind":"Name","value":"sellable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForProductReviewItem"}}]}},{"kind":"Field","name":{"kind":"Name","value":"source"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ConsumerProductReviewSourceCurated"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"purchasedAt"}}]}}]}}]}}]} as unknown as DocumentNode;