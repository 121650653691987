import React from 'react'
import numeral from 'numeral'
import classNames from 'classnames'
import { isUserInsider } from '#src/app/services/insider'
import { useIdentityContext } from '#src/app/containers/Identity'
import InsidersIcon from './insiders.svg'
import styles from './styles.css'

interface MemberInsidersBubbleLegacyProps {
  className?: string
  isParentVariant: boolean
  currentMembershipReward: number
}

/**
 * @deprecated use `MemberInsiderReward` instead
 */
const MemberInsidersBubbleLegacy: React.FC<
  React.PropsWithChildren<MemberInsidersBubbleLegacyProps>
> = ({ className, isParentVariant, currentMembershipReward }) => {
  const { myself } = useIdentityContext()

  if (isUserInsider(myself?.user) && currentMembershipReward > 0) {
    return (
      <div className={classNames(styles.bubble, className)}>
        <InsidersIcon className={styles.icon} />
        <span className={styles.copy}>
          Earn {isParentVariant && 'up to'}{' '}
          <strong>{numeral(currentMembershipReward).format('$0.00')}</strong> credit on this item
        </span>
      </div>
    )
  }

  return null
}

export default MemberInsidersBubbleLegacy
