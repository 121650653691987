import React from 'react'
import { CircleLoader } from '@deal/components'
import { useUpdateQuantityForLineItemQuantityMutation } from './LineItemQuantity.generated'
import PlusIcon from './plus.svg'
import MinusIcon from './minus.svg'
import styles from './styles.css'

interface Props {
  orderId: string
  parentLineItemId: string
  quantity: number
}

const LineItemQuantity: React.FC<React.PropsWithChildren<Props>> = ({
  orderId,
  parentLineItemId,
  quantity
}) => {
  const [updateQuantityForOrder, { loading }] = useUpdateQuantityForLineItemQuantityMutation()

  const handleUpdateQuantity = (quantity: number) =>
    updateQuantityForOrder({
      variables: {
        input: {
          id: orderId,
          lineItems: [{ id: parentLineItemId, quantity }]
        }
      }
    })

  return (
    <div className={styles.quantityContainer}>
      <button
        className={styles.quantityButton}
        disabled={loading || quantity < 2}
        onClick={() => handleUpdateQuantity(quantity - 1)}
      >
        <MinusIcon />
      </button>
      <div className={styles.quantityNumber}>
        {loading ? <CircleLoader size="10px" className={styles.loader} /> : quantity}
      </div>
      <button
        className={styles.quantityButton}
        onClick={() => handleUpdateQuantity(quantity + 1)}
        disabled={loading}
      >
        <PlusIcon />
      </button>
    </div>
  )
}

export default LineItemQuantity
