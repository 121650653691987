import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from '@deal/router'
import { Box, Grid, GridItem } from '@deal/components'
import { useCreateProductReviewFromEmailMutation } from './ProductReviewPostFromEmail.generated'
import styles from './styles.css'

export const PostFromEmail: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const token = urlParams.get('token')
  const lineItemId = urlParams.get('lineItemId')
  const rating = urlParams.get('rating')
  const title = urlParams.get('title')
  const comments = urlParams.get('comments')
  const recommended = urlParams.get('recommended')
  const [error, setError] = useState<string | null>(null)

  const [createProductReview] = useCreateProductReviewFromEmailMutation({
    onCompleted: data => {
      if (data.createConsumerProductReview.consumerProductReview) {
        history.push({
          ...location,
          pathname: '/product-reviews',
          search: `token=${token}&reviewId=${data.createConsumerProductReview.consumerProductReview.id}`
        })
      } else if (data.createConsumerProductReview.error) {
        if (
          data.createConsumerProductReview.error.__typename ===
          'AlreadyReviewedCreateConsumerProductReviewError'
        ) {
          history.push({
            ...location,
            pathname: '/product-reviews',
            search: `token=${token}&reviewId=${data.createConsumerProductReview.error.existingConsumerProductReview.id}`
          })
        } else {
          setError(data.createConsumerProductReview.error.errorMessage)
        }
      }
    },
    onError: error =>
      setError(
        'Error posting the product review. Please contact support. Error message: ' +
          error.toString()
      )
  })

  // on initial render, check for the required params, then either redirect to the product reviews page
  // or post the review
  useEffect(() => {
    // If any of the required params are missing, redirect to the product reviews page,
    // and pass along the params that were provided.
    if (!token || !lineItemId || !rating) {
      const searchParamStrings: string[] = []

      if (token) {
        searchParamStrings.push(`token=${token}`)
      }
      if (lineItemId) {
        searchParamStrings.push(`lineItemId=${lineItemId}`)
      }
      if (rating) {
        searchParamStrings.push(`rating=${rating}`)
      }
      if (title) {
        searchParamStrings.push(`title=${title}`)
      }
      if (comments) {
        searchParamStrings.push(`comments=${comments}`)
      }
      if (recommended) {
        searchParamStrings.push(`recommended=${recommended}`)
      }

      history.push({
        ...location,
        pathname: '/product-reviews',
        search: searchParamStrings.join('&')
      })
    } else {
      createProductReview({
        variables: {
          input: {
            orderLineItemId: lineItemId,
            rating: parseInt(rating),
            medias: [],
            title: title && title.trim().length > 0 ? title : undefined,
            comments: comments && comments.trim().length > 0 ? comments : undefined,
            recommended: recommended ? recommended === 'true' : undefined,
            emailReviewToken: token
          }
        }
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps -- only run on initial render, regardless of dependencies

  return (
    <Grid className={styles.page}>
      <GridItem col={2}>
        <Box column justify="start" items="stretch" className={styles.content}>
          <Box.Item>
            {error ? (
              <div className={styles.heading}>{error}</div>
            ) : (
              <div className={styles.heading}>Please wait while we process your product review</div>
            )}
          </Box.Item>
        </Box>
      </GridItem>
    </Grid>
  )
}
