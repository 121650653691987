import React, { Children } from 'react'
import PropTypes from 'prop-types'
import { Config } from '#src/app/config'

interface Props {
  config: Config
}

export default class ConfigProvider extends React.Component<React.PropsWithChildren<Props>> {
  public static childContextTypes = {
    config: PropTypes.object.isRequired
  }

  public getChildContext() {
    const { config } = this.props

    return { config }
  }

  public render() {
    return Children.only(this.props.children)
  }
}
