import React from 'react'
import { AuthenticatedUserFragment } from '../../../../fragments/AuthenticatedUser.generated'
import GoogleOAuthButton from '../GoogleOAuthButton'
import FacebookOAuthButton from '../FacebookOAuthButton/'
import AppleOAuthButton from '../AppleOAuthButton'
import styles from './styles.css'

interface OAuthButtonsProps {
  referralCodeId?: string | null
  referralRewardCreditClaimToken?: string | null
  registrationId?: string | null
  googleAuthOnly?: boolean
  onSuccess: (user: AuthenticatedUserFragment) => void
  onFailure: (error: string) => void
  isRebranded?: boolean
}

const OAuthButtons: React.FC<React.PropsWithChildren<OAuthButtonsProps>> = ({
  googleAuthOnly,
  isRebranded = false,
  ...props
}) => {
  const className = styles[`${isRebranded ? 'normal' : 'rebranded'}OAuth`]
  const wrapperClassName = styles[`${isRebranded ? 'normal' : 'rebranded'}OAuthButton`]

  return (
    <div className={className}>
      {!googleAuthOnly && (
        <AppleOAuthButton
          {...props}
          wrapperClassName={wrapperClassName}
          isRebranded={isRebranded}
        />
      )}
      <GoogleOAuthButton {...props} wrapperClassName={wrapperClassName} isRebranded={isRebranded} />
      {!googleAuthOnly && (
        <FacebookOAuthButton
          {...props}
          wrapperClassName={wrapperClassName}
          isRebranded={isRebranded}
        />
      )}
    </div>
  )
}

export default OAuthButtons
