import { CategoryForGetQuestionForOnSiteSmsModalTemplateFragment } from './OnSiteSMSModalTemplate.generated'

export const getQuestionForOnSiteSMSModalTemplate = (
  category?: CategoryForGetQuestionForOnSiteSmsModalTemplateFragment
) => {
  const slug = category?.department?.slug ?? category?.slug

  const options = ['Brand new', 'Beginner', 'Intermediate', 'Advanced', 'Expert']

  switch (slug) {
    case undefined:
      return undefined
    case 'winter-sports':
      if (category?.slug.includes('ski')) {
        return {
          title: 'What’s your skiing experience?',
          options: options
        }
      }
      return {
        title: 'What’s your snowboarding experience?',
        options: options
      }
    case 'golf':
      return {
        title: 'What’s your typical score for 18 holes?',
        options: ['75 or lower', '76-85', '86-95', '96-105', '106-115', '116+']
      }
    case 'kitchen':
      return {
        title: 'Which of the following best describes your approach to cooking?',
        options: ['A passion', 'A hobby', 'A means to an end', "I'm shopping for a gift"]
      }
    case 'coffee-espresso':
      return {
        title: 'What’s your espresso-making experience?',
        options: ["I'm new to espresso-making", 'Some experience', 'A lot of experience']
      }
    default:
      return {
        title: 'What’s your experience?',
        options: options
      }
  }
}
