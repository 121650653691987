import { useHistory, useLocation } from '@deal/router'
import { ContentSelectionChannelType, ContentSelectionDeviceType } from '#src/generated/types'
import { acquisitionAttributesFromUrl } from '#src/app/services/tracking'
import { relativeToAbsoluteUrl } from '#src/app/services/navigation'
import { isMobileBrowser } from '#src/app/services/mobile'
import { useUserAgentContext } from '#src/app/containers/UserAgent'

interface EngagementChannelInputProps {
  channelType: 'ENGAGEMENT_CHANNEL'
  categorySlug?: string
  keyword?: string
  pageKey: string
}

interface OtherInputProps {
  channelType: 'ARTICLE' | 'CATEGORY_PAGE' | 'EXPERT_HIRING' | 'LANDING_PAGE' | 'PRODUCT_DETAIL'
  categorySlug?: string
  keyword?: string
}

type UseGetContentSelectionCriteriaInputProps = EngagementChannelInputProps | OtherInputProps

export default function useGetContentSelectionCriteriaInput(
  props: UseGetContentSelectionCriteriaInputProps
) {
  const { categorySlug, channelType, keyword } = props
  const pageKey =
    channelType === ContentSelectionChannelType.ENGAGEMENT_CHANNEL ? props.pageKey : undefined
  const userAgent = useUserAgentContext()
  const location = useLocation()
  const history = useHistory()
  const urlString = relativeToAbsoluteUrl(
    history.createHref({
      ...location,
      hash: undefined
    })
  )
  const userAcquisitionAttributes = acquisitionAttributesFromUrl(urlString)
  const params = new URLSearchParams(location.search)

  return {
    pageKey,
    categorySlug,
    deviceType: isMobileBrowser(userAgent)
      ? ContentSelectionDeviceType.MOBILE_WEB
      : ContentSelectionDeviceType.DESKTOP,
    channelType,
    source: userAcquisitionAttributes.source,
    campaignId: userAcquisitionAttributes.campaignId,
    adGroupId: userAcquisitionAttributes.adgroupId,
    adId: userAcquisitionAttributes.adId,
    keyword: keyword || params.get('ctx') || params.get('k') || undefined,
    previewRuleId: params.get('previewRuleId') || undefined,
    traceTag: params.get('traceTag') || undefined,
    pageUrl: urlString
  }
}
