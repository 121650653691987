import React, { useEffect, useState } from 'react'
import loadable from '@loadable/component'
import { Route, useLocation, useParams } from '@deal/router'
import { useExperiment } from '@deal/experiment-js'
import { usePrevious } from '@deal/dom-hooks'
import { useModalTrigger } from '@deal/bluxome'
import { ContentSelectionChannelType, ProductQuickReviewSort } from '#src/generated/types'
import { getSellableUrl } from '#src/app/services/sellable'
import { useScrollToWithHeaderOffset } from '#src/app/hooks/useScrollToWithHeaderOffset'
import usePreferredExpert from '#src/app/hooks/usePreferredExpert'
import useGetContentSelectionCriteriaInput from '#src/app/hooks/useGetContentSelectionCriteriaInput'
import { useAddToCartButtonProps } from '#src/app/hooks/useAddToCartButtonProps'
import { RecentlyViewedProductsContextProvider } from '#src/app/context/recently-viewed-products'
import { PathCtaContextProvider } from '#src/app/context/path-cta'
import { useIdentityContext } from '#src/app/containers/Identity'
import Redirect from '#src/app/components/Redirect'
import Page from '#src/app/components/Page'
import Footer from '#src/app/components/Footer'
import { AddToCartErrorModal } from '#src/app/components/AddToCartErrorModal'
import { useProductsRouteQuery } from './ProductsRoute.generated'
import { getPathProductRecommendationInput } from './page/getPathProductRecommendationInput'
import { MissingSelectionProvider } from './page/context/missing-selection-error'
import { useShowPdpActionBarCtaAtom } from './page/atoms/pdp-action-bar-cta'
import { ExtraProductPageProps } from './page'
import { Loader } from './loading'
import { VariantSelectorProvider } from './context/variantSelectorContext'

const PageComponent = loadable(() => import(/* webpackChunkName: "products" */ './page'), {
  resolveComponent: page => page.ProductPage
})

const ProductsRoute: React.FC = () => {
  const { isLoggedIn, incompleteUser } = useIdentityContext()
  const [invalidCartModalMessage, setInvalidCartModalMessage] = useState('')
  const [showPdpActionBarCta, setShowPdpActionBarCta] = useShowPdpActionBarCtaAtom()
  // Evaluate the PLA experiment for exposure tracking only. The actual experimentation is done
  //   server-side to ensure the redirect happens as quickly as possible (see server/index.tsx),
  //   but we must evaluate the experiment in the browser as well to track the exposure.
  const { search } = useLocation()
  const urlQueryParams = new URLSearchParams(search)
  const isGoogleShoppingTraffic =
    urlQueryParams.get('utm_source') === 'google' && urlQueryParams.get('ad_type') === 'pla'
  const isAffiliateTraffic = urlQueryParams.get('utm_source') === 'impact'
  const pageReference = urlQueryParams.get('ref') ?? undefined

  useExperiment({
    experiment: 'pla-route',
    defaultTreatment: 'control',
    options: {
      skip: !isGoogleShoppingTraffic
    }
  })

  useExperiment({
    experiment: 'pla-route-affiliate',
    defaultTreatment: 'control',
    options: {
      skip: !isAffiliateTraffic
    }
  })

  const isShowingSellableFamilyRelationship =
    useExperiment({
      experiment: 'sellable-family-relationship',
      defaultTreatment: 'control'
    }).result === 'on'

  const { friendlyIdOrId } = useParams<{
    friendlyIdOrId: string
  }>()

  const location = useLocation()
  const condition = new URLSearchParams(location.search).get('condition')
  const scrollTo = useScrollToWithHeaderOffset()
  const [shouldShowMissingSelectionError, setShouldShowMissingSelectionError] = useState(false)

  const preferredExpertVanityId = usePreferredExpert()

  const contentSelectionInput = useGetContentSelectionCriteriaInput({
    channelType: ContentSelectionChannelType.PRODUCT_DETAIL
  })

  const query = useProductsRouteQuery({
    variables: {
      friendlyIdOrId,
      isLoggedOut: !isLoggedIn || incompleteUser,
      productQuickReviewSortBy: ProductQuickReviewSort.CONTENT_QUALITY,
      contentSelectionCriteriaInput: contentSelectionInput,
      preferredExpertVanityId,
      isShowingSellableFamilyRelationship,
      pathProductRecommendationInput: getPathProductRecommendationInput()
    }
  })
  const data = query.data || query.previousData
  const sellable = data?.findSellable?.defaultVariantOrSelf

  const prevParentId: string | undefined = usePrevious(sellable?.parent?.id)

  // reset variation matrix error if sellable changes
  useEffect(() => {
    setShouldShowMissingSelectionError(false)
    const sellableParentId = sellable?.parent?.id
    if (!sellableParentId || prevParentId !== sellableParentId) {
      setShowPdpActionBarCta(false)
    }
  }, [sellable?.id, prevParentId, sellable?.parent?.id, setShowPdpActionBarCta])

  const showMissingSelectionError = () => {
    setShouldShowMissingSelectionError(true)
    // TODO: update this to use hash link once we've updated to the new VariantSelector
    scrollTo('variation-matrix')
  }

  const csrResult = sellable?.contentForCriteria
  const promotion = csrResult?.promotion
  const pathSchemaId = csrResult?.defaultPathSelection?.pathSchemaId
  const csrKeyword = csrResult?.rule?.keyword || csrResult?.ruleV2?.title || undefined
  const pageVariation = csrResult?.template?.contentfulTemplate?.variation

  // Check the URL and make sure it is using the correct friendly ID and slug. If it's incorrect,
  //   redirect to the canonical URL. There are two cases to consider:
  //
  // 1) Using the non-friendly ID in the URL. This shouldn't happen so long as we are formatting URLs
  //    correctly everywhere in our codebase and sitemap.
  //
  //    For example: curated.com/products/AgAAADAAC3Bh8YnZQN2LdXoBavLUxg/any-slug
  //
  // 2) Using the an incorrect slug in the URL. This can happen if the slug changes after generating
  //    the sitemap, or by otherwise accessing an outdated link (e.g. via email or SMS).
  //
  //    For example: curated.com/products/123456789/some-outdated-slug
  //
  // In both cases, we need to make sure we are comparing the URL to the correct sellable. The
  //   `sellable` from the query could be a different sellable entirely if we have just navigated to
  //   a related sellable from a PDP. The URL will update before the Apollo cache updates; we need
  //   to ensure we're comparing apples to apples before redirecting.
  const urlMatchesSellableId =
    friendlyIdOrId === data?.findSellable?.id || friendlyIdOrId === data?.findSellable?.friendlyId
  const canonicalUrl = data?.findSellable ? getSellableUrl(data?.findSellable) : undefined

  const { state: modalState, overlayProps: modalOverlayProps } = useModalTrigger({
    onOpenChange: isOpen => {
      if (!isOpen) {
        setInvalidCartModalMessage('')
      }
    }
  })

  const addToCartButtonProps = useAddToCartButtonProps({
    sellables: sellable ? [sellable] : [],
    disabled: !sellable,
    sellableLoading: query.loading,
    addAsTrial: false,
    isOpenBox: false,
    onAddToCartError: errorMessage => {
      setInvalidCartModalMessage(errorMessage)
      modalState.open()
    },
    onParentAddToCart: showMissingSelectionError,
    source: 'pdp-action-bar'
  })

  if (urlMatchesSellableId && canonicalUrl && canonicalUrl !== location.pathname) {
    return <Redirect to={canonicalUrl} statusCode={301} />
  }

  // If the sellable is inactive, redirect to the default variant (assuming it is active)
  if (
    sellable &&
    !sellable.active &&
    sellable.variant &&
    sellable.variationMatrix?.defaultVariant.active
  ) {
    return (
      <Redirect
        to={getSellableUrl(sellable.variationMatrix.defaultVariant, { reference: pageReference })}
      />
    )
  }

  return (
    <>
      <PathCtaContextProvider
        value={{ promotionId: promotion?.id, pathSchemaId, csrKeyword, pageVariation }}
      >
        <RecentlyViewedProductsContextProvider>
          <MissingSelectionProvider
            value={{ shouldShowMissingSelectionError, showMissingSelectionError }}
          >
            <VariantSelectorProvider>
              <Page
                query={query}
                chat={true}
                department={data => data.findSellable?.defaultVariantOrSelf.department || undefined}
                expert={data =>
                  data.findSellable?.defaultVariantOrSelf.categories.at(0)
                    ?.recommendExpertForConsumer.expert || undefined
                }
                category={data =>
                  data.findSellable?.defaultVariantOrSelf.categories.at(0) || undefined
                }
                loadingComponent={Loader}
                ogImageUrl={sellable?.primaryImage?.url}
                engagementChannels
                pageComponent={PageComponent}
                pageKey="product-details"
                canonicalPath={
                  sellable?.parent
                    ? getSellableUrl(sellable.parent)
                    : sellable && getSellableUrl(sellable)
                }
                seoTitle={data =>
                  data.findSellable?.defaultVariantOrSelf.parent?.title ||
                  data.findSellable?.defaultVariantOrSelf.title
                }
                seoDescription={data =>
                  data.findSellable?.defaultVariantOrSelf.purchasable
                    ? `Shop ${
                        data.findSellable?.defaultVariantOrSelf.parent?.title ||
                        data.findSellable?.defaultVariantOrSelf.title
                      }. In stock and guaranteed lowest price. Get expert advice on whether this product is right for you. `
                    : `Get expert advice on alternatives of ${
                        data.findSellable?.defaultVariantOrSelf.parent?.title ||
                        data.findSellable?.defaultVariantOrSelf.title
                      }.`
                }
                seoIndexable={data =>
                  data.findSellable?.defaultVariantOrSelf.indexable &&
                  !!data.findSellable.defaultVariantOrSelf.department
                    ? true
                    : false
                }
                sellable={data => data.findSellable?.defaultVariantOrSelf || undefined}
                pageProps={
                  {
                    friendlyIdOrIdFromParams: friendlyIdOrId,
                    csrResult,
                    condition
                  } as ExtraProductPageProps
                }
                actionBarProps={() => {
                  return {
                    ...(showPdpActionBarCta
                      ? {
                          buttonColor: 'shop-orange',
                          buttonText: addToCartButtonProps.buttonText,
                          buttonPopoverTriggerProps: addToCartButtonProps.popoverTriggerProps,
                          buttonIsDisabled: addToCartButtonProps.isDisabled,
                          buttonOnPress: addToCartButtonProps.onPress,
                          buttonIsLoading: addToCartButtonProps.isLoading
                        }
                      : {})
                  }
                }}
              />
            </VariantSelectorProvider>
          </MissingSelectionProvider>
        </RecentlyViewedProductsContextProvider>
      </PathCtaContextProvider>
      <Footer />
      <AddToCartErrorModal
        state={modalState}
        overlayProps={modalOverlayProps}
        invalidCartModalMessage={invalidCartModalMessage}
      />
    </>
  )
}

const ProductsRouteParser = () => {
  return <Route path="/products/:friendlyIdOrId" component={ProductsRoute} />
}

export { ProductsRouteParser }
