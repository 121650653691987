import React from 'react'
// import { useLocation } from 'react-router'
// import { Image } from '@deal/components'
// import { Text } from '@deal/bluxome'
// import { useGetSellableForCurrentlyViewingQuery } from './CurrentlyViewing.generated'
import styles from './styles.css'

interface CurrentlyViewingProps extends React.PropsWithChildren {}

const CurrentlyViewing: React.FC<CurrentlyViewingProps> = ({ children }) => {
  // Temporarily hide currently viewing section as it does not have much benefits yet to consumer
  // const pathname = useLocation().pathname
  // const pathNameSplit = pathname.split('/')
  // const sellableFriendlyId =
  //   pathNameSplit[1] === 'products' && pathNameSplit[2] ? pathNameSplit[2] : null

  // const { data } = useGetSellableForCurrentlyViewingQuery({
  //   variables: { friendlyId: sellableFriendlyId! },
  //   skip: !sellableFriendlyId
  // })

  // const sellable = data?.findSellable
  // if (!sellable) {
  //   return null
  // }

  return (
    <div className={styles.content}>
      {/* <div className={styles.currentlyViewing}>
        <div className={styles.info}>
          {sellable.primaryImage?.url && (
            <Image
              src={sellable.primaryImage?.url}
              width={40}
              height={32}
              className={styles.image}
            />
          )}
          <div className={styles.infoText}>
            <Text style="extra-small" color="neutrals-600">
              Currently viewing
            </Text>
            <Text style="extra-small-semibold">{sellable.parent?.title ?? sellable.title}</Text>
          </div>
        </div>
      </div> */}
      {children}
    </div>
  )
}

export { CurrentlyViewing }
