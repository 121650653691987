import React from 'react'
import { Modal } from '@deal/components'
import AuthenticationFlow, { AuthenticationFlowProps } from '../AuthenticationFlow'
import styles from './styles.css'

export interface AuthenticationFlowModalProps extends AuthenticationFlowProps {
  isOpen: boolean
  hideCloseButton?: boolean
  onRequestClose: () => void
}

const AuthenticationFlowModal: React.FC<AuthenticationFlowModalProps> = ({
  isOpen,
  onRequestClose,
  hideCloseButton,
  ...authenticationFlowProps
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      modalContentClassName={styles.authenticationFlowModalContent}
      mobilePosition="bottom"
      contentLabel={authenticationFlowProps.intent}
      contentSpacing={false}
      width="xsmall"
      hideCloseButton={hideCloseButton}
    >
      <AuthenticationFlow {...authenticationFlowProps} />
    </Modal>
  )
}

export default AuthenticationFlowModal
