import React, { Component } from 'react'
import hoistNonReactStatic from 'hoist-non-react-statics'
import { RouteComponentProps, withRouter } from '@deal/router'

interface IWithStatusCodeProps {
  staticContext?: {
    statusCode: number
  }
}

export default function withStatusCode<P>(statusCode: number) {
  return (WrappedComponent: React.ComponentType<React.PropsWithChildren<P>>) => {
    class WithStatusCode extends Component<IWithStatusCodeProps & P & RouteComponentProps> {
      public render() {
        const { staticContext, ...rest } = this.props

        if (staticContext) {
          staticContext.statusCode = statusCode
        }

        // @ts-ignore: This nested dec doesn't think this.props is referencing correctly
        return <WrappedComponent {...rest} />
      }
    }

    // @ts-ignore: @ktsosno
    return hoistNonReactStatic(withRouter(WithStatusCode), WrappedComponent)
  };
}
