import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { ApolloError } from '@apollo/client'
import { ProductAddedEvent } from '@deal/web-tracking'
import {
  AddItemsToOrderExpertCuratedItemInput,
  AddItemsToOrderSellableInput,
  CartSyncUseCase,
  ProductCustomizationSelectedOptionInput
} from '#src/generated/types'
import { getSellableEcommerceProperties } from '#src/app/services/tracking'
import {
  ExpertCuratedItemForAddExpertCuratedItemToCartFragment,
  OrderForCartFragment,
  SellableForAddSellableToCartFragment,
  useAddItemsToOrderForCartMutation,
  useGetCartQuery,
  useSyncOrderForCartMutation,
  useUserCartChangedForCartSubscription
} from './Cart.generated'
import { useCartVisibilityContext } from '../cart-visibility'

type CartContextType = {
  cart?: OrderForCartFragment
  sync?: (useCase: CartSyncUseCase) => void
  loading: boolean
  error?: ApolloError
}

const CartContext = React.createContext<CartContextType>({
  loading: false
})

/**
 * Tracks the user's cart contents and exposes a method for syncing the cart contents
 *   with the backend.
 */
export const CartProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const lastSyncUseCase = useRef<CartSyncUseCase | null>(null)

  const [syncOrder, { data: _syncData, loading: syncLoading, error: syncError }] =
    useSyncOrderForCartMutation()

  const {
    data: subscriptionData,
    loading: subscriptionLoading,
    error: subscriptionError
  } = useUserCartChangedForCartSubscription({})

  const {
    data: cartData,
    loading: cartLoading,
    error: cartError
  } = useGetCartQuery({
    variables: {
      orderId: subscriptionData?.userCartChanged.cart?.id
    },
    skip: subscriptionLoading || !!subscriptionError || !subscriptionData?.userCartChanged.cart?.id
  })

  const data = cartData
  const loading = syncLoading || subscriptionLoading || cartLoading
  const error = syncError || subscriptionError || cartError

  // Components reading the cart contents via `useCart` will trigger a sync.
  //   This logic ensures that multiple components accessing the cart at the
  //   same time (or sequentially) does not result in unnecessary network
  //   requests. The sync mutation is only called if it hasn't been called
  //   previously, or if the use case has changed.
  const sync = useCallback(
    (useCase: CartSyncUseCase) => {
      if (lastSyncUseCase.current == useCase) {
        return
      }

      const orderId = data?.safeGetOrder.cart?.id

      // If we are syncing to view the cart, only do so if the user already
      //   has an order. We do not want to inadverently create a new order
      //   for a guest by invoking a cart sync before they have added products
      //   to their cart.
      if (!orderId) {
        return
      }

      syncOrder({
        variables: {
          orderId,
          useCase
        }
      })

      lastSyncUseCase.current = useCase
    },
    [syncOrder, lastSyncUseCase, data?.safeGetOrder.cart?.id]
  )

  return (
    <CartContext.Provider
      value={{ cart: data?.safeGetOrder.cart || undefined, sync, loading, error }}
    >
      {children}
    </CartContext.Provider>
  )
}

/**
 * Returns the user's current order, including the details necessary to display
 *   each of the line items in a cart UI. This hook abstracts the logic necessary
 *   to keep the cart contents up to date, whether it has been modified in the
 *   "background" by an expert or items have been added or removed by the consumer.
 */
export const useCart = (useCase: CartSyncUseCase) => {
  const { sync, cart, loading, error } = useContext(CartContext)

  useEffect(() => {
    sync?.(useCase)
  }, [sync, useCase])

  return { cart, loading, error }
}

export interface UseBulkAddSellablesToCartInput
  extends Array<{
    sellable: SellableForAddSellableToCartFragment
    addAsTrial?: boolean
    isOpenBox?: boolean
    quantity?: number
    selectedCustomizations?: ProductCustomizationSelectedOptionInput[]
  }> {}

/**
 * Adds sellables to the user's cart. If an expert has curated any of the sellables
 *   for the user, the curated item is added instead (with any applicable discounts
 *   and customizations).
 */
export const useBulkAddSellablesToCart = (source?: string) => {
  const [addItemsToOrder, result] = useAddItemsToOrderForCartMutation()
  const { setCartVisibility } = useCartVisibilityContext()

  return [
    (items: UseBulkAddSellablesToCartInput) => {
      const sellables: AddItemsToOrderSellableInput[] = items
        .filter(
          item =>
            !item.sellable.recentExpertCuratedItem ||
            item.sellable.id !== item.sellable.recentExpertCuratedItem.sellable.id
        )
        .map(item => {
          return {
            sellableId: item.sellable.id,
            quantity: item.quantity,
            bestOffer: item.isOpenBox || undefined,
            selectedCustomizations: item.selectedCustomizations
          }
        })

      // only swap out a sellable for its recentExpertCuratedItem if they are the same parent or variant
      const expertCuratedItems: AddItemsToOrderExpertCuratedItemInput[] = items
        .filter(
          item =>
            item.sellable.recentExpertCuratedItem &&
            item.sellable.id === item.sellable.recentExpertCuratedItem.sellable.id
        )
        .map(item => {
          const expertCuratedItem = item.sellable.recentExpertCuratedItem!

          return {
            expertCuratedItemId: expertCuratedItem.id,
            addAsTrial: item.addAsTrial
          }
        })

      return addItemsToOrder({
        variables: {
          input: {
            sellables,
            expertCuratedItems
          }
        },
        onCompleted: () => {
          items.forEach(item => {
            window.tracking?.track(
              new ProductAddedEvent({
                ...getSellableEcommerceProperties(item.sellable),
                quantity: 1,
                source: source
              })
            )
          })

          setCartVisibility(true)
        }
      })
    },
    result
  ] as const
}

/**
 * Adds a sellable to the user's cart. If an expert has curated the sellable for
 *   the user, the curated item is added instead (with any applicable discounts
 *   and customizations).
 */
export const useAddSellableToCart = (source?: string) => {
  const [bulkAddSellablesToCart, result] = useBulkAddSellablesToCart(source)

  return [
    (
      sellable: SellableForAddSellableToCartFragment,
      quantity: number = 1,
      selectedCustomizations?: ProductCustomizationSelectedOptionInput[]
    ) => {
      return bulkAddSellablesToCart([{ sellable, quantity, selectedCustomizations }])
    },
    result
  ] as const
}

interface UseBulkAddExpertCuratedItemsToCartInput
  extends Array<{
    curatedItem: ExpertCuratedItemForAddExpertCuratedItemToCartFragment
    addAsTrial: boolean
  }> {}

/**
 * Adds curated items to the user's cart, including any customizations and discounts.
 */
export const useBulkAddExpertCuratedItemsToCart = () => {
  const [addItemsToOrder, result] = useAddItemsToOrderForCartMutation()
  const { setCartVisibility } = useCartVisibilityContext()

  return [
    (items: UseBulkAddExpertCuratedItemsToCartInput) => {
      return addItemsToOrder({
        variables: {
          input: {
            expertCuratedItems: items.map(item => {
              return {
                expertCuratedItemId: item.curatedItem.id,
                addAsTrial: item.addAsTrial
              }
            })
          }
        },
        onCompleted: () => {
          items.forEach(item => {
            window.tracking?.track(
              new ProductAddedEvent({
                ...getSellableEcommerceProperties(item.curatedItem.sellable),
                quantity: 1,
                expert_curated_item_id: item.curatedItem.id
              })
            )
          })

          setCartVisibility(true)
        }
      })
    },
    result
  ] as const
}

/**
 * Adds a curated items to the user's cart, including any customizations and discounts
 */
export const useAddExpertCuratedItemToCart = () => {
  const [bulkAddExpertCuratedItemsToCart, result] = useBulkAddExpertCuratedItemsToCart()

  return [
    (
      curatedItem: ExpertCuratedItemForAddExpertCuratedItemToCartFragment,
      addAsTrial: boolean = false
    ) => {
      return bulkAddExpertCuratedItemsToCart([{ curatedItem, addAsTrial }])
    },
    result
  ] as const
}
