import React from 'react'
import { PageKeyPriority, Route, Switch } from '@deal/router'
import Redirect from '#src/app/components/Redirect'
import NoIndex from '#src/app/components/NoIndex'
import { NotFound } from '#src/app/components/Errors'
import { SystemCurationRedirect } from './pages/SystemCuration/components/SystemCurationRedirect'
import { SystemCurationPage } from './pages/SystemCuration'
import { PreviewCurationsPage } from './pages/Preview'
import CurationRedirect from './pages/CurationRedirect'
import { CurationsPage } from './pages/Curation'

const Curations: React.FC = () => {
  return (
    <>
      <NoIndex />
      <Switch>
        <Route
          path="/curations"
          pageKey="curations-products-lead"
          priority={PageKeyPriority.PAGE}
          component={CurationRedirect}
          exact
        />
        {/* Route for expert to preview a lead's curation */}
        <Route
          path="/curations/products/preview/:leadId"
          pageKey="curations-products-preview-lead"
          priority={PageKeyPriority.PAGE}
          component={PreviewCurationsPage}
          exact
        />
        <Route
          path="/curations/products/:leadId"
          pageKey="curations-products-lead"
          priority={PageKeyPriority.PAGE}
          component={CurationsPage}
          exact
        />
        <Route
          path="/curations/recommendations"
          pageKey="curations-recommendations"
          priority={PageKeyPriority.PAGE}
          component={SystemCurationRedirect}
          exact
        />
        <Route
          path="/curations/recommendations/:requestId"
          pageKey="curations-recommendations"
          priority={PageKeyPriority.PAGE}
          component={SystemCurationPage}
          exact
        />
        <Route
          path="/curations/:leadId"
          exact
          render={({ match }) => {
            if (match.params.leadId === 'products') {
              return <NotFound />
            } else {
              return <Redirect to={`/curations/products/${match.params.leadId}`} />
            }
          }}
        />
        <Route component={NotFound} />
      </Switch>
    </>
  )
}

export { Curations }
