import React from 'react'
import { useLocation } from 'react-router'
import loadable from '@loadable/component'
import { useIdentityContext } from '#src/app/containers/Identity'
import RouteLoader from '#src/app/components/RouteLoader'
import Redirect from '#src/app/components/Redirect'
import { Unauthorized } from '#src/app/components/Errors'
import { useExpertiseRequestForSystemCurationRedirectQuery } from './SystemCurationRedirect.generated'
import { SystemCurationPageSkeleton } from '../SystemCurationPageSkeleton'

const NoCurationsRoute = loadable(
  () => import(/* webpackChunkName: 'curations-none' */ '../../../NoCurations'),
  {
    resolveComponent: components => components.NoCurations,
    fallback: <RouteLoader />
  }
)

export const SystemCurationRedirect = () => {
  const identity = useIdentityContext()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const previewConsumerId = params.get('previewConsumerId')
  const loginRequired = params.get('loginRequired') === 'true'
  const ownerId = previewConsumerId || identity.myself?.consumer?.id

  const { data, loading } = useExpertiseRequestForSystemCurationRedirectQuery({
    variables: {
      expertiseRequestFilter: {}
    }
  })

  if (loginRequired && !ownerId) {
    return <Unauthorized />
  }

  if (loading) return <SystemCurationPageSkeleton />
  const firstExpertiseRequest = data?.findSellableExpertiseRequests.at(0)
  if (firstExpertiseRequest) {
    return <Redirect to={`/curations/recommendations/${firstExpertiseRequest.id}`} />
  } else {
    return <NoCurationsRoute />
  }
}
