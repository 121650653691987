import React from 'react'
import compact from 'lodash/compact'
import loadable from '@loadable/component'
import { Route, RouteComponentProps, Switch } from '@deal/router'
import { buildProductComparisonPageUrl } from '#src/app/services/search/buildProductComparisonPageUrl'
import { useProductComparisonContext } from '#src/app/context/product-comparison'
import Redirect from '#src/app/components/Redirect'
import Page from '#src/app/components/Page'
import Footer from '#src/app/components/Footer'
import { CompareRouteQuery, useCompareRouteQuery } from './CompareRoute.generated'
import { ProductComparisonLoader } from './page/loading'

const PageComponent = loadable(() => import(/* webpackChunkName: "compare-table" */ './page'), {
  resolveComponent: page => page.CompareTablePage
})

// v2 requires a reference to the first sellable being compared in the ssr-accessible url pathname.
// This is used to derive the open graph meta & create rich LinkPreviews in chat.
const CompareRouteV2: React.FC<RouteComponentProps<{ friendlyIdOrId: string }>> = ({
  location,
  match
}) => {
  const { syncSellableIdsFromApolloCacheToLocalStorage } = useProductComparisonContext()

  const firstSellableFriendlyIdOrId = match.params.friendlyIdOrId
  const otherSellableFriendlyIdsOrIds = new URLSearchParams(location.search)
    .getAll('vs')
    .slice(0, 4)

  const query = useCompareRouteQuery({
    variables: { firstSellableFriendlyIdOrId, otherSellableFriendlyIdsOrIds },
    onCompleted: ({ firstSellable, otherSellables }) => {
      syncSellableIdsFromApolloCacheToLocalStorage(
        compact([firstSellable?.id, ...otherSellables.map(sellable => sellable.id)])
      )
    }
  })

  const { firstSellable = null } = query.data || {}

  if (firstSellable?.friendlyId && firstSellable.friendlyId !== firstSellableFriendlyIdOrId) {
    return <Redirect to={{ ...location, pathname: `/compare/${firstSellable.friendlyId}` }} />
  }

  return (
    <>
      <Page
        query={query}
        pageKey="compare-table"
        chat={true}
        seoTitle={data => {
          const title = getFirstSellableSEOTitle(data)
          return `Product Comparison${title ? ` | ${title}` : ''}`
        }}
        seoDescription={data => {
          return `Compare ${getFirstSellableSEOTitle(data) || 'products'} on Curated`
        }}
        seoIndexable={false}
        expert={undefined}
        category={undefined}
        department={undefined}
        sellable={undefined}
        pageComponent={PageComponent}
        loadingComponent={ProductComparisonLoader}
        ogImageUrl={({ firstSellable }) => firstSellable?.primaryImage?.url}
        canonicalPath={undefined}
      />
      <Footer />
    </>
  )
}

function getFirstSellableSEOTitle({ firstSellable }: CompareRouteQuery) {
  return (
    firstSellable?.defaultVariantOrSelf.parent?.title || firstSellable?.defaultVariantOrSelf.title
  )
}

// v1 relies on references to the sellables being compared in on ssr-inaccessible state (localStorage & url search params)
const CompareRouteV1: React.FC<RouteComponentProps> = ({ location }) => {
  const urlParams = new URLSearchParams(location.search)
  const sellableIdsFromUrl = urlParams.get('ids')?.split(',').slice(0, 4)
  const sellableIdsFromContext = useProductComparisonContext().sellableIds
  const sellableIds = sellableIdsFromUrl || sellableIdsFromContext

  const emptyQuery = useCompareRouteQuery({ skip: true })

  if (sellableIds?.at(0)) {
    // If viewing "/compare" (no :friendlyIdOrId param), but at least one
    // sellableId was revived from localStorage or the v1 search param,
    // then a v2-compatible route can be built.
    const comparisonRouteV2Location = buildProductComparisonPageUrl(location, sellableIds).to
    return <Redirect to={comparisonRouteV2Location} />
  }

  return (
    <>
      <Page
        query={emptyQuery}
        pageKey="compare-table"
        chat={true}
        seoTitle={undefined}
        seoDescription={undefined}
        seoIndexable={false}
        expert={undefined}
        category={undefined}
        department={undefined}
        sellable={undefined}
        pageComponent={PageComponent}
        loadingComponent={ProductComparisonLoader}
        ogImageUrl={undefined}
        canonicalPath={undefined}
      />
      <Footer />
    </>
  )
}

export const CompareRoute: React.FC = () => {
  return (
    <Switch>
      <Route path="/compare/:friendlyIdOrId" component={CompareRouteV2} />
      <Route path="/compare" component={CompareRouteV1} />
    </Switch>
  )
}
