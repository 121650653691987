import React, { useEffect, useState } from 'react'
import { useChatWidgetStateContext } from '#src/app/context/chat-widget-state'
import { ChatWidgetButtonBase } from '#src/app/components/ChatWidgetButtonBase'
import { BusinessUserForChatWidgetButtonFragment } from './ChatWidgetButton.generated'

interface ChatWidgetButtonProps {
  expert: BusinessUserForChatWidgetButtonFragment
  isAI?: boolean
  unreadCount?: number
  guestChat?: boolean
}

export const ChatWidgetButton: React.FC<ChatWidgetButtonProps> = ({
  expert,
  isAI,
  unreadCount,
  guestChat
}) => {
  const [guestChatUnreadCount, setGuestChatUnreadCount] = useState(1)
  const { expandChatWidget } = useChatWidgetStateContext()
  const expertAvgRating = expert.expertStats?.averageRating
  const departmentExpertAvgRating = expert.department.consumerExpertReviewRating.averageRating
  const isPlaceholderExpert = expert.expertAttributes.placeholderExpert
  const expertRatingProp = isAI
    ? undefined
    : expertAvgRating
    ? { rating: expertAvgRating }
    : !isPlaceholderExpert
    ? {
        subtitle:
          expert.expertAttributes.category.categoryAssetRevision?.expert?.singular ||
          expert.expertProfile?.headline ||
          `${expert.expertAttributes.category.singularDisplayName} Expert`
      }
    : departmentExpertAvgRating
    ? { averageRating: departmentExpertAvgRating }
    : undefined

  useEffect(() => {
    if (guestChat && guestChatUnreadCount > 0) {
      const timeout = setTimeout(() => {
        expandChatWidget('guest-chat-auto-expand')
        setGuestChatUnreadCount(0)
      }, 3000)
      return () => clearTimeout(timeout)
    }
  }, [guestChat, guestChatUnreadCount, expandChatWidget])

  return (
    <ChatWidgetButtonBase
      expert={expert}
      onPress={() => {
        if (guestChat) {
          setGuestChatUnreadCount(0)
          expandChatWidget('user-interacted-guest-chat-widget-button')
        } else {
          expandChatWidget('user-interacted-chat-widget-button')
        }
      }}
      unreadCount={guestChat ? guestChatUnreadCount : unreadCount}
      title={isAI ? 'scout' : expert.displayName}
      isAI={isAI}
      {...expertRatingProp}
    />
  )
}
