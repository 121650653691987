import React from 'react'
import { toast } from 'react-toastify'
import classNames from 'classnames'
import { Button } from '@deal/components'
import { useDismissOrderUserMessagesMutation } from '#src/app/mutations/DismissOrderUserMessages.generated'
import { OrderUserMessageForOrderMessagesFragment } from './OrderMessages.generated'
import Close from './close.svg'
import styles from './styles.css'

interface Props {
  messages: OrderUserMessageForOrderMessagesFragment[]
  className?: string
}

const OrderMessages: React.FC<React.PropsWithChildren<Props>> = ({ messages, className }) => {
  const [dismissMessage, { loading }] = useDismissOrderUserMessagesMutation({
    onCompleted: ({ dismissOrderUserMessages }) => {
      if (dismissOrderUserMessages.error) {
        toast.error(dismissOrderUserMessages.error.errorMessage)
      }
    }
  })

  if (messages.length > 0) {
    return (
      <div className={classNames(styles.container, className)}>
        {messages.map(message => (
          <div key={message.id} className={styles.message}>
            <div>{message.message}</div>
            <Button
              onClick={() => dismissMessage({ variables: { orderUserMessageIds: [message.id] } })}
              disabled={loading}
              variant="secondary-link"
              size="xsmall"
              className={styles.dismissButton}
            >
              <Close />
            </Button>
          </div>
        ))}
        {messages.length > 1 && (
          <Button
            onClick={() =>
              dismissMessage({ variables: { orderUserMessageIds: messages.map(({ id }) => id) } })
            }
            disabled={loading}
            variant="secondary-link"
            size="xsmall"
            className={styles.dismissAllButton}
          >
            Dismiss all
          </Button>
        )}
      </div>
    )
  }
  return null
}

export default OrderMessages
