// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { SellableForSaveToCurationButtonLegacyFragmentDoc } from '../../SaveToCurationButtonLegacy/SaveToCurationButtonLegacy.generated';
import { BusinessUserForExpertAvatarFragmentDoc } from '../../ExpertAvatar/ExpertAvatar.generated';
import { BusinessUserForExpertForAvatarWithPopoverFragmentDoc, BusinessUserForExpertForAvatarFragmentDoc } from '../../../services/avatars/BusinessUserForExpertForAvatar.generated';
import { BusinessUserForGetExpertExpertiseFragmentDoc, BusinessUserForGetExpertProfileLinkFragmentDoc } from '../../../services/expert/expert.generated';
import { LeadForSaveToCurationButtonFragmentDoc } from '../../SaveToCurationButton/SaveToCurationButton.generated';
export type SellableForAskForFeedbackFragment = { __typename: 'Sellable', id: any, variantParent: boolean, recentExpertCuratedItem?: { __typename: 'ExpertCuratedItem', id: any, sellable: { __typename: 'Sellable', id: any } } | null, savedSellable?: { __typename: 'SavedSellable', id: any, saved: boolean, savedReason?: Types.SavedSellableReason | null } | null, parent?: { __typename: 'Sellable', id: any } | null };

export type LeadForAskForFeedbackFragment = { __typename: 'Lead', id: any, expert: { __typename: 'BusinessUser', id: any, displayName: string, vanityId?: string | null, state: Types.BusinessUserState, expertProfile?: { __typename: 'ExpertProfile', id: any, background: string, backgroundSummaryLines: Array<string>, headline: string, profilePhoto?: { __typename: 'MediaFile', id: any, url: string } | null } | null, expertStats?: { __typename: 'ExpertStats', totalReviews: number, totalLeadsParticipated: number, averageRating?: number | null } | null, expertAttributes: { __typename: 'BusinessUserExpertAttributes', category: { __typename: 'Category', id: any, slug: string, singularDisplayName: string, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, expert?: { __typename: 'CategoryAssetNoun', singular: string } | null } | null }, specialtyCategory?: { __typename: 'Category', id: any, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, expert?: { __typename: 'CategoryAssetNoun', singular: string } | null } | null } | null }, articleAuthorProfile?: { __typename: 'ExpertProfile', id: any, headline: string } | null } };

export const SellableForAskForFeedbackFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableForAskForFeedback"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Sellable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"recentExpertCuratedItem"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"sellable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForSaveToCurationButtonLegacy"}}]}}]} as unknown as DocumentNode;
export const LeadForAskForFeedbackFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LeadForAskForFeedback"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Lead"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"expert"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForExpertAvatar"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"LeadForSaveToCurationButton"}}]}}]} as unknown as DocumentNode;