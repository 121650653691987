import { DealNativeBridge } from '#src/app/services/dealNativeBridge'
import { UserAgent } from '#src/app/containers/UserAgent'

/**
 * Helper method for determining if we're inside of a the consumer native mobile wrapper app.
 *
 * Note that this only works client side; during server side render this will always return false
 */
export function isConsumerMobileNative(userAgent?: UserAgent) {
  const dealNativeBridge =
    process.env.TARGET === 'web'
      ? window && ((window as any).dealNativeBridge as DealNativeBridge | undefined)
      : undefined
  return (
    (!!dealNativeBridge && dealNativeBridge.appBundleId === 'com.deal.Consumer') ||
    (!!userAgent && userAgent.raw.includes('DealWebView_com.deal.Consumer'))
  )
}

export function isBusinessMobileNative(userAgent?: UserAgent) {
  const dealNativeBridge =
    process.env.TARGET === 'web'
      ? window && ((window as any).dealNativeBridge as DealNativeBridge | undefined)
      : undefined
  return (
    (!!dealNativeBridge && dealNativeBridge.appBundleId === 'com.deal.Business') ||
    (!!userAgent && userAgent.raw.includes('DealWebView_com.deal.Business'))
  )
}

/**
 * Helper method for determining if we're inside iPadOS
 *
 * With the release of iPadOS 13, Apple changed the user agent of Safari to be the same as MacOS.
 *  Currently (2020) the difference between iPad and the other platforms is that iPad is touch enabled.
 */
export function isIpadOS(navigator: Navigator) {
  return navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform)
}

/**
 * Helper method for determining if we're inside a mobile browser, but not in a native wrapper
 */
export function isMobileBrowser(userAgent: UserAgent) {
  if (!userAgent) return false

  const navigator = process.env.TARGET === 'web' ? window.navigator : undefined

  return (
    !isConsumerMobileNative() &&
    (userAgent.device.type === 'mobile' ||
      userAgent.device.type === 'tablet' ||
      (navigator && isIpadOS(navigator)))
  )
}

/**
 * Helper method for determining if we're in mobile and in mobile iOS
 */
export function isMobileiOS(userAgent: UserAgent) {
  if (!userAgent) return false

  if (!isMobileBrowser(userAgent)) {
    return false
  }

  const navigator = process.env.TARGET === 'web' ? window.navigator : undefined

  return userAgent.os.name === 'iOS' || (navigator && isIpadOS(navigator))
}
