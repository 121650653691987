import React, { useContext, useRef } from 'react'
import classNames from 'classnames'
import { GetBreakpointOverridingContainer, useIsHydrated, useMergedRefs } from '@deal/dom-hooks'
import styles from './styles.css'

/**
 * The `ResponsiveBreakpointOverrideContainer` is designed to wrap components whose style
 * should respond to the width of their container, not the width of the viewport.
 *
 * Calls to `useBreakpoint` in a child component of a `ResponsiveBreakpointOverrideContainer` instance
 * will use this component's width instead of the viewport's.
 *
 * The component is defined as a CSS container, enabling container queries in its descendants.
 */
interface ResponsiveBreakpointOverrideContainerProps
  extends React.PropsWithoutRef<React.HTMLAttributes<HTMLDivElement>> {
  // Used to render a skeleton loader (or similar) until app has been hydrated
  // Might prevent layout trashing because static markup from SSR can only estimate the container's final size.
  fallback?: React.ReactNode
}

const ResponsiveBreakpointOverrideContainer = React.forwardRef<
  HTMLDivElement,
  ResponsiveBreakpointOverrideContainerProps
>(({ children, fallback = null, className, ...props }, forwardedRef) => {
  const innerRef = useRef<HTMLDivElement>(null)
  const mergedRef = useMergedRefs(forwardedRef, innerRef)
  const isSuspended = !useIsHydrated() && !!fallback

  return (
    <div
      {...props}
      ref={mergedRef}
      className={classNames(
        styles.responsiveContainer,
        { [styles.suspended]: isSuspended },
        className
      )}
    >
      <ResponsiveBreakpointOverrideContainerContext.Provider value={() => innerRef.current}>
        {children}
        <div className={styles.fallback}>{fallback}</div>
      </ResponsiveBreakpointOverrideContainerContext.Provider>
    </div>
  )
})

ResponsiveBreakpointOverrideContainer.displayName = 'ResponsiveBreakpointOverrideContainer'

const ResponsiveBreakpointOverrideContainerContext =
  React.createContext<GetBreakpointOverridingContainer | null>(null)

function useGetResponsiveBreakpointOverrideContainer() {
  return useContext(ResponsiveBreakpointOverrideContainerContext)
}

export { ResponsiveBreakpointOverrideContainer, useGetResponsiveBreakpointOverrideContainer }
