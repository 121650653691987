import React, { useEffect } from 'react'
import { CircleLoader } from '@deal/components'
import { useLogoutMutation } from '../../../Logout.generated'
import { AuthenticationFlowViewLayoutProps } from '../../../index'
import AuthenticationFlowViewLayout from '../../../components/AuthenticationFlowViewLayout/v1'
import styles from './styles.css'

interface LogoutViewProps extends AuthenticationFlowViewLayoutProps {
  onLogout: () => void
}

const LogoutView: React.FC<LogoutViewProps> = ({ onLogout, standalone }) => {
  const [logout, { client }] = useLogoutMutation()

  useEffect(() => {
    logout().then(() => {
      client.resetStore().then(() => {
        setTimeout(onLogout, 2000)
      })
    })
  }, [])

  return (
    <AuthenticationFlowViewLayout
      headline="Just a moment..."
      subHeadline="We're logging you out. See you soon!"
      standalone={standalone}
    >
      <div className={styles.loader}>
        <CircleLoader size="40px" thickness="2px" />
      </div>
    </AuthenticationFlowViewLayout>
  )
}

export default LogoutView
