import React from 'react'
import { toast } from 'react-toastify'
import { Link } from '@deal/router'
import { Image } from '@deal/components'
import { getSellableUrl } from '#src/app/services/sellable'
import loggerClient from '#src/app/services/loggerClient'
import { useDismissOrderUserMessagesMutation } from '#src/app/mutations/DismissOrderUserMessages.generated'
import { useCartVisibilityContext } from '#src/app/context/cart-visibility'
import SellableStockAlertButton from '#src/app/components/SellableStockAlertButtonLegacy'
import DefaultProductImage from '#src/app/assets/images/default-product.png'
import { OrderUserMessageForCartOutOfStockFragment } from './OutOfStock.generated'
import AskForAlternativeButton from './components/AskForAlternativeButton'
import CloseIcon from './close.svg'
import styles from './styles.css'

interface OutOfStockProps {
  messages: OrderUserMessageForCartOutOfStockFragment[]
  testId: string
}

const OutOfStock: React.FC<OutOfStockProps> = ({ messages, testId }) => {
  const { setCartVisibility } = useCartVisibilityContext()

  const [dismissMessage, { loading }] = useDismissOrderUserMessagesMutation({
    onCompleted: ({ dismissOrderUserMessages }) => {
      if (dismissOrderUserMessages.error) {
        toast.error(dismissOrderUserMessages.error.errorMessage)
      }
    }
  })

  if (messages.length > 0) {
    return (
      <div data-testid={testId}>
        <div className={styles.title}>Out of stock</div>
        <div className={styles.subtitle}>
          These items are no longer in stock and have been removed from the cart
        </div>
        <div className={styles.messages}>
          {messages.map(message => {
            if (
              message.__typename === 'AdHocSellableNotAvailableOrderUserMessage' ||
              message.__typename === 'SellableNotAvailableOrderUserMessage'
            ) {
              const { sellable } = message
              return (
                <div key={message.id} className={styles.messageContainer}>
                  <Link to={getSellableUrl(sellable)} onClick={() => setCartVisibility(false)}>
                    <div className={styles.imageContainer}>
                      <Image
                        src={sellable.primaryImage?.url || DefaultProductImage}
                        size="40px"
                        htmlAttributes={{ alt: `Image of ${sellable.title}` }}
                      />
                    </div>
                  </Link>
                  <div className={styles.sellableDetails}>
                    <Link to={getSellableUrl(sellable)} onClick={() => setCartVisibility(false)}>
                      <div className={styles.sellableTitle}>{sellable.title}</div>
                    </Link>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sellable.variants
                          .map(({ displayValueHtml }) => displayValueHtml)
                          .join(', ')
                      }}
                      className={styles.sellableVariants}
                    />
                    <div className={styles.buttonsContainer}>
                      <AskForAlternativeButton sellable={sellable} />
                      {message.__typename === 'SellableNotAvailableOrderUserMessage' && (
                        <SellableStockAlertButton
                          sellable={sellable}
                          isCartPlacement
                          size="xsmall"
                        />
                      )}
                    </div>
                  </div>
                  <CloseIcon
                    className={styles.dismissIcon}
                    onClick={() =>
                      !loading &&
                      dismissMessage({ variables: { orderUserMessageIds: [message.id] } })
                    }
                  />
                </div>
              )
            } else {
              loggerClient.captureError(
                new Error(
                  'Attempted to display order user message without a Sellable in OutOfStock cart module'
                )
              )
              return null
            }
          })}
        </div>
      </div>
    )
  }
  return null
}

export default OutOfStock
