// Note: At this point these types are manually copied & pasted between this and the mobile native
// app. If we add a lot more message types, we can re-factor and move these types to a shared
// library.
export type OnTokenChangeMessage = {
  type: 'onTokenChange'
  sn?: string
  at?: string
  rt?: string
}

export type NavigateToMessage = {
  type: 'navigateTo'
  // Add additional pages as union types
  page:
    | {
        name: 'conversation'
        conversationId: string
      }
    | {
        name: 'orderConfirmation'
        orderId: string
        paymentSourceId: string
      }
}

export type GetNotificationSettingsAuthorizationStatusRequestMessage = {
  type: 'getNotificationSettingsAuthorizationStatusRequest'
  messageId: string
}

export enum NotificationSettingsAuthorizationStatus {
  notDetermined = 'notDetermined',
  denied = 'denied',
  authorized = 'authorized',
  unknown = 'unknown'
}

export type GetNotificationSettingsAuthorizationStatusResponseMessage = {
  type: 'getNotificationSettingsAuthorizationStatusResponse'
  messageId: string
  status: NotificationSettingsAuthorizationStatus
}

export type RequestPushNotificationPermissionRequestMessage = {
  type: 'requestPushNotificationPermissionRequest'
  messageId: string
}

export type RequestPushNotificationPermissionResponseMessage = {
  type: 'requestPushNotificationPermissionResponse'
  messageId: string
  success: boolean
}

export type GetFcmTokenRequestMessage = {
  type: 'getFcmTokenRequest'
  messageId: string
}

export type GetFcmTokenResponseMessage = {
  type: 'getFcmTokenResponse'
  messageId: string
  fcmToken: string
}

export type RequestAuthenticatedSessionRequestMessage = {
  type: 'requestAuthenticatedSessionRequest'
  messageId: string
  url: string
}

export type RequestAuthenticatedSessionResponseMessage = {
  type: 'requestAuthenticatedSessionResponse'
  messageId: string
  webUrl?: string
  canceled: boolean
}

export type navtiveScreenNames = 'addCreditCard' | 'onboarding'

export type NavigateToNativeScreenMessage = {
  type: 'navigateToNativeScreen'
  screen: {
    name: navtiveScreenNames
    orderId?: string
    // Defaults to false
    saveCard?: boolean
    // Defaults to false
    disableSaveCard?: boolean
    amount?: {
      currency: string
      amountInSmallestDenomination: string | number
    }
  }
}

export type LinkingOpenUrlMessage = {
  type: 'linkingOpenUrl'
  url: string
}

export type AnalyticsMessage = {
  type: 'analyticsMessage'
  payload:
    | {
        type: 'identify'
        userId: string
        traits?: any
      }
    | {
        type: 'track'
        event: string
        properties?: any
      }
    | {
        type: 'screen'
        name: string
        properties?: any
      }
    | {
        type: 'reset'
      }
}

export type GoogleSigninRequest = {
  type: 'googleSigninRequest'
  messageId: string
}

export type GoogleSigninResponse = {
  type: 'googleSigninResponse'
  messageId: string
  success?: {
    user: {
      id: string | null
      name: string | null
      email: string | null
      photo: string | null
      familyName: string | null
      givenName: string | null
    }
    scopes?: string[]
    idToken: string
    accessToken: string | null
  }
  failureCode?:
    | 'SIGN_IN_CANCELLED'
    | 'IN_PROGRESS'
    | 'PLAY_SERVICES_NOT_AVAILABLE'
    | 'SIGN_IN_REQUIRED'
    | 'UNKNOWN'
}

export type FacebookSigninRequest = {
  type: 'facebookSigninRequest'
  messageId: string
  permissions: string[]
  fields: string
}

export type FacebookSigninResponse = {
  type: 'facebookSigninResponse'
  messageId: string
  success?: {
    id: string
    accessToken: string
    [fieldKey: string]: string | undefined
  }
  failureCode?: 'LOGIN_ERROR' | 'CANCELLED' | 'NO_ACCESS_TOKEN' | 'GRAPH_REQUEST_ERROR' | 'UNKNOWN'
  failureDesc?: string
}

export type AppleSigninRequest = {
  type: 'appleSigninRequest'
  messageId: string
}

export type AppleSigninResponse = {
  type: 'appleSigninResponse'
  messageId: string
  success?: {
    user: {
      email: string | null
      lastName: string | null
      firstName: string | null
    }
    idToken: string
  }
  failure?: 'CANCELED' | 'FAILED' | 'INVALID_RESPONSE' | 'NOT_HANDLED' | 'UNKNOWN'
}

export type StartAffirmCheckoutMessage = {
  type: 'startAffirmCheckoutMessage'
  messageId: string
  checkoutData: any
}

export type AffirmCheckoutEventMessage = {
  type: 'affirmCheckoutEventMessage'
  messageId: string
  event:
    | {
        type: 'CheckoutReadyToPresent'
      }
    | {
        type: 'CheckoutCompleted'
        checkoutToken: string
      }
    | {
        type: 'CheckoutCanceled'
      }
    | {
        type: 'CheckoutFailed'
        error: any
      }
}

export type InitializeAudioRecordingRequest = {
  type: 'initializeAudioRecordingRequest'
  messageId: string
}

export type InitializeAudioRecordingResponse = {
  type: 'initializeAudioRecordingResponse'
  messageId: string
  success: boolean
}

export type StartAudioRecordingRequest = {
  type: 'startAudioRecordingRequest'
  messageId: string
}

export type StartAudioRecordingResponse = {
  type: 'startAudioRecordingResponse'
  messageId: string
  success: boolean
}

export type StopAudioRecordingRequest = {
  type: 'stopAudioRecordingRequest'
  messageId: string
}

export type StopAudioRecordingResponse = {
  type: 'stopAudioRecordingResponse'
  messageId: string
  success: boolean
  file?: {
    id: string
    url: string
    mimeType: string
    originalFileName?: string | null
  }
}

export type CloseAudioRecordingMessage = {
  type: 'closeAudioRecordingMessage'
}

export type WebMessage =
  | NavigateToMessage
  | GetNotificationSettingsAuthorizationStatusResponseMessage
  | RequestPushNotificationPermissionResponseMessage
  | GetFcmTokenResponseMessage
  | RequestAuthenticatedSessionResponseMessage
  | GoogleSigninResponse
  | FacebookSigninResponse
  | AppleSigninResponse
  | AffirmCheckoutEventMessage
  | InitializeAudioRecordingResponse
  | StartAudioRecordingResponse
  | StopAudioRecordingResponse
export type NativeMessage =
  | OnTokenChangeMessage
  | GetNotificationSettingsAuthorizationStatusRequestMessage
  | RequestPushNotificationPermissionRequestMessage
  | GetFcmTokenRequestMessage
  | RequestAuthenticatedSessionRequestMessage
  | NavigateToNativeScreenMessage
  | LinkingOpenUrlMessage
  | AnalyticsMessage
  | GoogleSigninRequest
  | FacebookSigninRequest
  | AppleSigninRequest
  | StartAffirmCheckoutMessage
  | InitializeAudioRecordingRequest
  | StartAudioRecordingRequest
  | StopAudioRecordingRequest
  | CloseAudioRecordingMessage

export type DealNativeBridge = {
  sendToNative(message: NativeMessage): void
  sendToWeb(message: WebMessage): void
  _sendToWebInitBuffer: WebMessage[]
  appBundleId: string
  appBuildNumber: string
  deviceId: string
  deviceUniqueId?: string
}

/**
 * Invoke `callback` if the a bridge between this web app and a hosting native app has been supplied.
 *
 * Note that this code base does not set the `dealNativeBridge` property; the object is injected
 * into the window object by our React Native apps. The injection is done after the document is
 * creted, but before any other content is loaded. See
 * https://developer.apple.com/documentation/webkit/wkuserscriptinjectiontime/wkuserscriptinjectiontimeatdocumentstart?language=objc
 * for documentation on when this is injected.
 *
 * @param callback callback that's invoked only if the bridge has been injected.
 */
export function ifBridgeDefined<T>(callback: (bridge: DealNativeBridge) => T): T | undefined {
  const bridge: DealNativeBridge = window.dealNativeBridge

  if (bridge) {
    return callback(bridge)
  }
  return undefined
}
