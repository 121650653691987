import React from 'react'
import { CircleLoader } from '@deal/components'

interface ForYouLoaderProps {}

const ForYouLoader: React.FC<React.PropsWithChildren<ForYouLoaderProps>> = () => {
  return <CircleLoader />
}

export default ForYouLoader
