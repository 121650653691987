import React, { ReactElement, useEffect } from 'react'
import { LoadableComponent } from '@loadable/component'
import { ApolloError, ApolloQueryResult, QueryResult } from '@apollo/client'
import { PageKeyPriority, PageKeySetter, RouteProps } from '@deal/router'
import useContentSelectionResultForEC from '#src/app/hooks/useContentSelectionResultForEC'
import { useCurrentExpert } from '#src/app/context/expert'
import { DepartmentSetter } from '#src/app/context/department'
import { SellableForPageFragment } from './SellableForPage.generated'
import { DepartmentForPageFragment } from './DepartmentForPage.generated'
import { CategoryForPageFragment } from './CategoryForPage.generated'
import { BusinessUserForPageFragment } from './BusinessUserForPage.generated'
import PageMetadata from '../PageMetadata'
import { Server as ServerError } from '../Errors'
import EngagementChannels from '../EngagementChannels'
import DynamicSearchAdsContentTarget from '../DynamicSearchAdsContentTarget'
import Chrome, { ChromeProps } from '../Chrome'

/**
 * Represents most types except functions.
 */
type ResolveableValue = bigint | boolean | null | number | string | symbol | undefined | object

/**
 * Represents a static value, or a function to derive a value from the completed
 * page query data.
 */
type ResolvableFromData<TResolve extends ResolveableValue, TData extends any | undefined> =
  | TResolve
  | ((data: TData) => TResolve)

/** Each underlying page component must accept these props */
export interface PageComponentProps<TData extends any> {
  data: TData
  refetch: () => Promise<ApolloQueryResult<TData>>
  loading: boolean
  error?: ApolloError
}

/**
 * Page components can accept extra props (via the `pageProps` prop), but they must
 * never overlap the built-in props (`data`, `refetch`, `loading`, `error`).
 */
type NeverPageComponentProps = {
  [K in keyof PageComponentProps<any>]: never
}

type ExtraPageProps = undefined | (any & NeverPageComponentProps)

/**
 * The Page component accepts all of the props of Chrome, but allows them to be derived from
 *   the page query data
 */
type PageChromeProps<TData extends any> = {
  [Property in keyof ChromeProps]: ResolvableFromData<ChromeProps[Property], TData>
}

/** Page component props */
interface PageProps<TData extends any, TExtraPageProps extends ExtraPageProps>
  extends PageChromeProps<TData> {
  query: QueryResult<TData, any>

  seoTitle: ResolvableFromData<string | undefined, TData>
  seoDescription: ResolvableFromData<string | undefined, TData>
  seoIndexable: ResolvableFromData<boolean, TData>

  /** Should the chat widget/sidebar be visible on this page?
   *
   * Note: It is best to set this to a constant (either a boolean or one of the pre-defined
   * strings allowed by this prop). If a function is provided to derive this from query
   * data, the chat will show while the page is loading by default. That will result in
   * layout jank if the eventual state returns "false".
   * */
  chat: ResolvableFromData<ChromeProps['chat'], TData>
  hideHeader?: ResolvableFromData<ChromeProps['hideHeader'], TData>

  /** Which expert should be shown in the chat UIs on this page? */
  expert: ResolvableFromData<BusinessUserForPageFragment | undefined, TData>

  /** Which category is most relevant to the content of this page?
   *
   * This is used for dynamic ad targeting purposes.
   */
  category: ResolvableFromData<CategoryForPageFragment | undefined, TData>

  /** Which department is most relevant to the content of this page?
   *
   * This is used to set the department context, which is used for a variety of
   * features around the site (such as keyword search), as well as being
   * included on tracking events.
   */
  department: ResolvableFromData<DepartmentForPageFragment | undefined, TData>

  /** Which sellable is most relevant to the content of this page, if any?
   *
   * This is used for certain engagement channels that feature a specific product
   */
  sellable: ResolvableFromData<SellableForPageFragment | undefined, TData>

  pageComponent: LoadableComponent<PageComponentProps<TData> & TExtraPageProps>
  pageProps?: TExtraPageProps
  /** The component to be shown while the query is in a loading state */
  loadingComponent: React.ComponentType<React.PropsWithChildren<unknown>>
  /** The component to be shown in the case of the query returning an error state */
  errorComponent?: React.ComponentType<React.PropsWithChildren<unknown>>
  /**
   * Should we render engagement channels on this page?
   */
  engagementChannels?: boolean
  /** Override engagement channes page key
   * for example on PLA page we need to use PDP engagement channels
   *  */
  overridePageKeyForEngagementChannels?: string
  canonicalPath?: ResolvableFromData<string | undefined, TData>
  /**
   * Open Graph image url
   */
  ogImageUrl: ResolvableFromData<string | undefined, TData>
  /**
   * Open graph page type
   */
  ogType?: ResolvableFromData<string | undefined, TData>
}

/** Helper to unwrap the value of a field once the data is available */
function resolveFromData<TResolve extends ResolveableValue, TData>(
  resolvable: ResolvableFromData<TResolve, TData>,
  data: TData
): TResolve {
  if (typeof resolvable === 'function') {
    return resolvable(data)
  } else {
    return resolvable
  }
}

/**
 * Renders a page while adhering to best practices:
 *
 *   - Loads data in parallel to fetching the CSS and JS
 *   - Encourages a single query for all data needs of the page
 *   - Encourages a single well-designed loading state for the page
 *   - Requires a title, description, and indexable state for SEO
 *   - Preserves the global state of the application (the header and chat UIs
 *       while loading)
 */
function Page<TData, TExtraPageProps extends ExtraPageProps = {}>({
  query,
  seoTitle,
  seoDescription,
  seoIndexable,
  hideHeader,
  chat,
  expert,
  category,
  department,
  sellable,
  pageComponent: PageComponent,
  pageProps,
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent = ServerError,
  engagementChannels,
  overridePageKeyForEngagementChannels,
  canonicalPath,
  ogImageUrl,
  ogType,
  actionBarProps,
  fixed
}: PageProps<TData, TExtraPageProps>): ReactElement | null {
  const { data: newData, previousData, loading, error, refetch, called } = query
  const data = newData || previousData

  const { setExpert } = useCurrentExpert()
  const resolvedExpert = data && resolveFromData(expert, data)
  // On mount, start loading the component.
  useEffect(() => {
    PageComponent.load()
  }, [])

  // Set the expert for the chat UI's once data is ready.
  useEffect(() => {
    // do not show chat bar for editorial expert
    if (resolvedExpert && !resolvedExpert.isEditorialExpert) {
      setExpert(resolvedExpert)
    }
  }, [resolvedExpert?.id])

  const categoryValue = data ? resolveFromData(category, data) : undefined

  const contentSelectionResult = useContentSelectionResultForEC({
    category: categoryValue,
    // remove the category check if Page component is used on a page that is category agnostic
    //   and needs engagement channel
    skip: !engagementChannels || !categoryValue,
    overridePageKey: overridePageKeyForEngagementChannels
  })

  if (data) {
    const departmentValue = resolveFromData(department, data)

    return (
      <DepartmentSetter department={departmentValue || null}>
        <PageMetadata
          title={resolveFromData(seoTitle, data)}
          canonicalPath={resolveFromData(canonicalPath, data)}
          description={resolveFromData(seoDescription, data)}
          imageUrl={resolveFromData(ogImageUrl, data)}
          type={ogType ? resolveFromData(ogType, data) : undefined}
          indexable={resolveFromData(seoIndexable, data) !== false}
        />

        {categoryValue && <DynamicSearchAdsContentTarget category={categoryValue} />}
        <Chrome
          fixed={resolveFromData(fixed, data)}
          chat={resolveFromData(chat, data)}
          category={categoryValue}
          ecContentSelectionResult={contentSelectionResult || undefined}
          actionBarProps={resolveFromData(actionBarProps, data)}
          hideHeader={resolveFromData(hideHeader, data)}
        >
          {contentSelectionResult && (
            <EngagementChannels
              contentSelectionResult={contentSelectionResult}
              category={categoryValue}
              sellable={resolveFromData(sellable, data)}
            />
          )}
          <PageComponent
            refetch={refetch}
            data={data}
            loading={loading}
            error={error}
            fallback={<LoadingComponent />}
            // @ts-ignore
            {...pageProps}
          />
        </Chrome>
      </DepartmentSetter>
    )
  }

  if (loading || !called) {
    return (
      <Chrome
        chat={typeof chat === 'boolean' ? chat : true}
        hideHeader={typeof hideHeader === 'boolean' ? hideHeader : false}
      >
        <LoadingComponent />
      </Chrome>
    )
  }

  if (error) {
    return (
      <Chrome
        chat={typeof chat === 'boolean' ? chat : true}
        hideHeader={typeof hideHeader === 'boolean' ? hideHeader : false}
      >
        <ErrorComponent />
      </Chrome>
    )
  }

  return null
}

/**
 * Renders a page while adhering to best practices:
 *
 *   - Loads data in parallel to fetching the CSS and JS
 *   - Encourages a single query for all data needs of the page
 *   - Encourages a single well-designed loading state for the page
 *   - Requires a title, description, and indexable state for SEO
 *   - Preserves the global state of the application (the header and chat UIs
 *       while loading)
 */
export default function PageWrapper<TData, TExtraPageProps extends ExtraPageProps>({
  pageKey,
  ...rest
}: PageProps<TData, TExtraPageProps> & { pageKey: RouteProps['pageKey'] }): ReactElement | null {
  return (
    <PageKeySetter pageKey={pageKey} priority={PageKeyPriority.TOP_LEVEL}>
      <Page {...rest} />
    </PageKeySetter>
  )
}
