import { Event, ViewerContext } from '@deal/web-tracking'

export interface CartViewedEventProperties {
  order_id?: string
}

export class CartViewedEvent extends Event<CartViewedEventProperties> {
  static displayName = 'Cart Viewed'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
