import React, { useContext } from 'react'

type MissingSelectionContextType =
  | { shouldShowMissingSelectionError: boolean; showMissingSelectionError: () => void }
  | undefined

const MissingSelectionContext = React.createContext<MissingSelectionContextType>(undefined)

const MissingSelectionProvider = MissingSelectionContext.Provider

const useMissingSelectionContext = () => {
  const context = useContext(MissingSelectionContext)

  if (!context) {
    throw new Error('Invoked MissingSelectionContext outside of provider')
  }

  return context
}

export { MissingSelectionProvider, useMissingSelectionContext }
