import numeral from 'numeral'
import { ProductAddedEvent, ProductViewedEvent } from '@deal/web-tracking'
import { DepartmentType, SellableType } from '#src/generated/types'
import { getSellableEcommerceProperties } from '#src/app/services/tracking'
import { doesDeptSupportFreeShipping } from '#src/app/services/categories'
import { TrackableSellableFragment } from '#src/app/fragments/TrackableSellable.generated'
import { SellableUrlFragment } from '#src/app/fragments/SellableUrl.generated'
import { SellableForNormalizeSellablePricingFragment } from '#src/app/fragments/SellableForNormalizeSellablePricing.generated'
import { SellableForFormatSellablePricingFragment } from '#src/app/fragments/SellableForFormatSellablePricing.generated'
import { MonetaryAmountFragment } from '#src/app/fragments/MonetaryAmount.generated'
import { ProductViewEndedEvent } from '#src/app/events/ProductViewEndedEvent'
import { ProductAddClickedEvent } from '#src/app/events/ProductAddClickedEvent'
import {
  SellableForGetSellableLinkRelFragment,
  SellableForIsSellablePurchasableFragment
} from './Sellable.generated'
import { formatProductBundlePricing } from '../productBundle'
import { MonetaryAmountFormattingOptions, formatMonetaryAmountAsJsx } from '../money'
import loggerClient from '../loggerClient'

interface Sellable {
  type: string
  title?: string
  brand: {
    name: string
    displayName: string
  } | null
  serviceLocation: {
    displayTitle: string | null
  } | null
}

export function getSellableUrl(
  sellableUrlData: SellableUrlFragment,
  params: { reference?: string; attributionToken?: string; attributionTag?: string } = {}
) {
  if (
    sellableUrlData.type !== SellableType.PRODUCT &&
    sellableUrlData.type !== SellableType.SERVICE
  ) {
    throw new Error(
      `Attempted to generate link for unhandled sellable type [${sellableUrlData.type}]`
    )
  }

  let sellableUrl = '/'

  // First determine if this is a product or experience
  sellableUrl += `${sellableUrlData.type === SellableType.PRODUCT ? 'products' : 'experiences'}`

  // If a friendly ID is available, use that, otherwise use the default sellable ID
  sellableUrl += `/${sellableUrlData.friendlyId ? sellableUrlData.friendlyId : sellableUrlData.id}`

  // Slug should always be available, append this to the end in any case
  sellableUrl += `/${sellableUrlData.slug}`

  const searchParams = new URLSearchParams()

  if (params.reference) {
    searchParams.set('ref', params.reference)
  }

  if (params.attributionToken) {
    searchParams.set('at', params.attributionToken)
  }

  if (params.attributionTag) {
    searchParams.set('atag', params.attributionTag)
  }
  if (searchParams.toString()) {
    sellableUrl += `?${searchParams.toString()}`
  }

  return sellableUrl
}

export function getSellableLinkRel(sellable: SellableForGetSellableLinkRelFragment) {
  return !sellable.active || sellable.adHoc ? 'nofollow' : undefined
}

/**
 * Returns a title and subtitle of a Sellable based on their type.
 *
 * This function is useful to have consistency data across the website.
 *
 * @param sellable
 */
export function getTitleAndSubtitle(sellable: Sellable): {
  title?: string
  subtitle?: string | null
} {
  if (sellable.type === 'SERVICE') {
    return {
      title: sellable.title,
      subtitle: sellable.serviceLocation ? sellable.serviceLocation.displayTitle : undefined
    }
  }

  return {
    title: sellable.brand ? sellable.brand.displayName : undefined,
    subtitle: sellable.title
  }
}

/**
 * Format a Sellable's pricing information
 *
 * `variantsPricesDiffer` is used to display certain copy for a parent sellable.
 * For a select variant, we show the pricing normally with price and show msrp and savings if available.
 * For a parent sellable and `variantsPricesDiffer` === true, we want to show the price and savings as 'From $199 and 'Up to 10% off'. We do not show the msrp. Things list golf sets can have varying msrp.
 * For a parent sellable and `variantsPricesDiffer` === false, pricing is shown as '$199' and savings is shown as '10% off' and we display the msrp if available.
 */
export interface FormattedSellablePricing {
  price: string | JSX.Element
  rawPrice?: MonetaryAmountFragment
  msrp?: string | JSX.Element
  rawMsrp?: MonetaryAmountFragment
  savings?: string | JSX.Element
  savingsPercentage?: number
  variantsPricesDiffer?: boolean
  variantsMsrpDiffer?: boolean
  msrpRange?: { base?: MonetaryAmountFragment; additional?: MonetaryAmountFragment }
}

export function formatSellablePricing(
  sellable: SellableForFormatSellablePricingFragment,
  formattingOptions?: MonetaryAmountFormattingOptions & {
    percentSavingsDisplayThreshold?: number
  },
  normalizationOptions?: NormalizationOptions
): FormattedSellablePricing | null {
  if (sellable.productBundle && !normalizationOptions?.salePriceOverrideIsBundlePrice) {
    const bundlePrice = formatProductBundlePricing(sellable.productBundle)
    // bundle msrp and savings can only be calculated when selections within the bundle are made
    if (bundlePrice.salePriceRangeFrom) {
      return {
        price: bundlePrice.salePriceRangeFrom,
        variantsPricesDiffer: bundlePrice.salePriceDiffers
      }
    } else {
      return null
    }
  }

  const pricing = normalizeSellablePricing(sellable, normalizationOptions)

  if (!pricing) {
    return null
  }

  const {
    price,
    msrp,
    msrpRange,
    savings,
    savingsPercentage,
    variantsPricesDiffer,
    variantsMsrpDiffer
  } = pricing

  // The savings display threshold is passed in as a whole number
  const displaySavings =
    formattingOptions?.percentSavingsDisplayThreshold &&
    savingsPercentage &&
    savingsPercentage * 100 >= formattingOptions.percentSavingsDisplayThreshold

  // Format the strike-through price (a.k.a. MSRP), if the savings are above the threshold
  let formattedMsrp: string | JSX.Element | undefined
  let displayMsrpRange:
    | { base?: MonetaryAmountFragment; additional?: MonetaryAmountFragment }
    | undefined
  if (displaySavings) {
    // If we're showing pricing for whole the sellable group and prices differ across variants,
    //   show a range of prices. Otherwise, show the single strikethrough price.
    if (normalizationOptions?.pricingBasedOnAllVariants && msrpRange) {
      if (variantsMsrpDiffer && price.amount !== msrpRange.min.amount) {
        formattedMsrp = `${formatMonetaryAmountAsJsx(
          msrpRange.min,
          formattingOptions
        )} to ${formatMonetaryAmountAsJsx(msrpRange.max, formattingOptions)}`
        displayMsrpRange = { base: msrpRange.min, additional: msrpRange.max }
      } else {
        formattedMsrp = formatMonetaryAmountAsJsx(msrpRange.max, formattingOptions)
        displayMsrpRange = { base: msrpRange.max }
      }
    } else {
      formattedMsrp = msrp && formatMonetaryAmountAsJsx(msrp, formattingOptions)
      displayMsrpRange = { base: msrp }
    }
  }

  return {
    price: formatMonetaryAmountAsJsx(price, formattingOptions),
    rawPrice: price,
    msrp: formattedMsrp,
    rawMsrp: msrp,
    savings:
      savings && displaySavings ? formatMonetaryAmountAsJsx(savings, formattingOptions) : undefined,
    savingsPercentage:
      savingsPercentage && displaySavings ? Math.round(savingsPercentage * 100) : undefined,
    variantsPricesDiffer,
    variantsMsrpDiffer,
    msrpRange: displayMsrpRange
  }
}

/**
 * Normalize a Sellable's pricing information
 *
 * This function assumes that the salePrice and originalPrice have the same currency
 */
interface MSRPRange {
  min: MonetaryAmountFragment
  max: MonetaryAmountFragment
}

export interface SellablePricing {
  price: MonetaryAmountFragment
  msrp?: MonetaryAmountFragment
  msrpRange?: MSRPRange
  savings?: MonetaryAmountFragment
  savingsPercentage?: number
  variantsPricesDiffer?: boolean
  variantsMsrpDiffer?: boolean
}

export interface NormalizationOptions {
  pricingBasedOnAllVariants?: boolean
  salePriceOverride?: MonetaryAmountFragment
  salePriceOverrideIsBundlePrice?: boolean
}

export function normalizeSellablePricing(
  sellable: SellableForNormalizeSellablePricingFragment,
  options: NormalizationOptions = {}
): SellablePricing | null {
  const originalPrice = sellable.originalPrice
  let salePrice = options.salePriceOverride || sellable.salePrice
  let variantsPricesDiffer: boolean | undefined,
    variantsMsrpDiffer: boolean | undefined,
    savings: MonetaryAmountFragment | undefined,
    savingsPercentage: number | undefined,
    msrpRange: MSRPRange | undefined

  const pricingBasedOnAllVariants = options.pricingBasedOnAllVariants

  /**
   * Override salePrice and originalPrice to reflect all of the variants when displaying parent sellables
   */
  if (pricingBasedOnAllVariants && sellable.variationMatrix) {
    const priceRange = sellable.variationMatrix.priceRange

    variantsPricesDiffer = priceRange ? priceRange.max.amount !== priceRange.min.amount : false
    salePrice = priceRange?.min
    msrpRange = sellable.variationMatrix.originalPriceRange || undefined
    variantsMsrpDiffer = msrpRange ? msrpRange.max.amount !== msrpRange.min.amount : false
  } else if (pricingBasedOnAllVariants && !('variationMatrix' in sellable)) {
    loggerClient.captureError(
      new Error('Attempted to normalize pricing based on all variants without a variation matrix')
    )
  }

  // If there is no pricing information, return null
  if (!salePrice) {
    return null
  }

  /**
   * Calculate savings and savingsPercentage
   */
  if (pricingBasedOnAllVariants && 'variationMatrix' in sellable && sellable.variationMatrix) {
    savings = sellable.variationMatrix.maximumSavingsAmount || undefined
    savingsPercentage = sellable.variationMatrix.maximumSavingsPercent || undefined
  } else if (originalPrice) {
    const savingsAmount = parseFloat(originalPrice.amount) - parseFloat(salePrice.amount)
    savings = {
      __typename: 'MonetaryAmount',
      amount: numeral(savingsAmount).format('0,0.00'),
      amountInSmallestDenomination: `${savingsAmount * 100}`,
      currency: salePrice.currency
    }
    savingsPercentage = savingsAmount / parseFloat(originalPrice.amount)
  }

  /**
   * If there is a savings percentage show msrp and savings info
   * For `pricingBasedOnAllVariants`, it's possible that a parent sellable has varying msrp, so we don't
   * show msrp if `variantsPricesDiffer` === true, but still want to show `savingsPercentage`
   */
  if (savingsPercentage) {
    return {
      price: salePrice,
      msrp: originalPrice || undefined,
      msrpRange,
      savings,
      savingsPercentage,
      variantsPricesDiffer,
      variantsMsrpDiffer
    }
  }

  // Otherwise, return just the sale price
  return {
    price: salePrice,
    variantsPricesDiffer
  }
}

export function validateFreeShipping(
  departmentType: DepartmentType,
  salePrice: MonetaryAmountFragment
): boolean {
  return doesDeptSupportFreeShipping(departmentType) && parseInt(salePrice.amount, 10) >= 50
}

export function isSellablePurchasable(sellable: SellableForIsSellablePurchasableFragment) {
  // Pre-orders appear as OOS
  // Parent sellables are "purchasable" if at least 1 of the underlying sellable is purchasable
  //   or all underlying sellables are preorders.
  const isParentPurchasable =
    sellable.variantParent &&
    sellable.active &&
    (!sellable.areAllVariantsOutOfStock || sellable.areAllVariantsAvailableForPreOrder)
  // if the sellable isn't a variant parent, we check to see if it's purchaseable or available
  //   for pre-order
  const isSellablePurchasable = sellable.purchasable || sellable.availableForPreOrder
  return isParentPurchasable || isSellablePurchasable
}

export const trackProductViewed = (
  sellable: TrackableSellableFragment,
  activityId: string,
  ref?: string,
  attributionToken?: string
) => {
  window.tracking?.track(
    new ProductViewedEvent({
      ...getSellableEcommerceProperties(sellable),
      activity_id: activityId,
      ref: ref,
      attribution_token: attributionToken
    })
  )

  window.tracking?.trackExperimentMetric('consumer_product_details_page_views_total')

  const isOnSale =
    sellable.originalPrice &&
    sellable.salePrice &&
    parseFloat(sellable.originalPrice.amount) - parseFloat(sellable.salePrice.amount) > 0
  if (isOnSale) {
    window.tracking?.trackExperimentMetric('consumer_on_sale_product_details_page_views_total')
  }
}

export const trackProductViewEnded = (sellable: TrackableSellableFragment, activityId: string) => {
  window.tracking?.track(
    new ProductViewEndedEvent({
      ...getSellableEcommerceProperties(sellable),
      activity_id: activityId
    })
  )
}

export const trackSellableAddClicked = (sellable: TrackableSellableFragment) => {
  window.tracking?.track(
    new ProductAddClickedEvent({
      ...getSellableEcommerceProperties(sellable)
    })
  )
}

export const trackSellableAddedToCart = (
  sellable: TrackableSellableFragment,
  quantity: number,
  expertCuratedItemId?: string
) => {
  window.tracking?.track(
    new ProductAddedEvent({
      ...getSellableEcommerceProperties(sellable),
      quantity: quantity,
      expert_curated_item_id: expertCuratedItemId
    })
  )
}
