import React from 'react'
import { Button } from '@deal/bluxome'
import { ConversationStartersForConversationStartersV2Fragment } from '../ConversationStartersV2.generated'
import {
  UseConversationStartersV2Props,
  useConversationStartersV2
} from '../useConversationStartersV2'

interface ConversationStartersV2SuggestionsProps extends UseConversationStartersV2Props {
  conversationStarters: ConversationStartersForConversationStartersV2Fragment
  onCompleted?: () => void
}

const ConversationStartersV2Suggestions: React.FC<ConversationStartersV2SuggestionsProps> = ({
  conversationStarters,
  onCompleted,
  ...rest
}) => {
  const { sendAction, loading } = useConversationStartersV2(rest)

  return (
    <>
      {conversationStarters.suggestions.map((suggestion, index) => (
        <Button
          key={index}
          size="small"
          color="learn-blue"
          isLoading={loading}
          onPress={() => sendAction(suggestion, onCompleted)}
        >
          {suggestion}
        </Button>
      ))}
    </>
  )
}

export { ConversationStartersV2Suggestions }
