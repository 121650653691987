import React, { useContext } from 'react'
import { ApolloQueryResult } from '@apollo/client'
import { ViewerContext } from '@deal/web-tracking/constants'
import { CookieGetter, useCookieContext } from '#src/app/context/cookies'
import { useAnalyticsContext } from '#src/app/containers/Analytics'
import { MyselfFragment, MyselfQuery, SessionFragment, useMyselfQuery } from './Myself.generated'
import userTraitsFromMyself from '../Analytics/userTraitsFromMyself'
import { WithIdentityProps as WithIdentityPropsType, withIdentity } from './withIdentity'
import withAuthentication from './withAuthentication'
import protect from './protect'

export type WithIdentityProps = WithIdentityPropsType

function getSessionId(session: SessionFragment, getCookie: CookieGetter): string {
  if (process.env.TARGET === 'web') {
    const sessionCookie = getCookie('deal_sn')
    if (sessionCookie) {
      return atob(sessionCookie.split('.')[1])
    }
  }

  return session.id
}

function getExperimentUserId(session: SessionFragment, myself?: MyselfFragment | null): string {
  return myself ? myself.user.experimentUserId : session.experimentUserId
}

function getUserExperimentAttributes(session: SessionFragment, myself?: MyselfFragment | null) {
  const userAttributes: { [key: string]: string } = {}
  myself?.user.experimentAttributes.forEach(entry => {
    userAttributes[entry.name] = entry.value
  })
  userAttributes['session.tenantId'] = session.tenantId
  return userAttributes
}

// Context
type IdentityContextType = {
  myself: MyselfFragment | null
  session: SessionFragment | null
  refetch?: () => Promise<ApolloQueryResult<MyselfQuery>>
  sessionId?: string
  userId?: string
  experimentUserId?: string
  userExperimentAttributes?: { [key: string]: string }
  isLoggedIn: boolean
  incompleteUser: boolean
}

const IdentityContext = React.createContext<IdentityContextType>({
  myself: null,
  session: null,
  isLoggedIn: false,
  incompleteUser: false
})

// Consumer
const IdentityConsumer = IdentityContext.Consumer

// Providers
// This provider is a small wrapper around the context provider, in order to handle the loading state
const IdentityProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const analytics = useAnalyticsContext()
  const cookies = useCookieContext()
  const {
    data,
    loading,
    refetch: refetchMyself
  } = useMyselfQuery({
    partialRefetch: true
  })

  // Data exposed via context
  const myself = data?.me || null
  const incompleteUser = myself?.user.incompleteUser ?? false
  const isLoggedIn = !!myself
  const session = data?.session || null
  const sessionId = data && getSessionId(data.session, cookies.getCookie)
  const userId = data?.me?.user.id || undefined
  const experimentUserId = data && getExperimentUserId(data.session, data.me)
  const userExperimentAttributes =
    (data && getUserExperimentAttributes(data.session, data?.me)) || {}

  const refetch = () => {
    return refetchMyself().then(response => {
      /**
       * We often track events after refetching the user's identity,
       *   so we need to make sure the analytics client is up to date
       *   with the latest user state (ID and traits)
       */
      const myself = response.data.me
      if (myself) {
        analytics?.identify({ userId: myself.id }, ViewerContext.Consumer)
        analytics?.addTraits(userTraitsFromMyself(myself))
      }

      return response
    })
  }

  // The context object itself
  const context: IdentityContextType = {
    myself,
    session,
    sessionId,
    userId,
    experimentUserId,
    userExperimentAttributes,
    refetch,
    isLoggedIn,
    incompleteUser
  }

  /**
   * During SSR, the Myself query will be in a loading state initially.
   *   We don't want to redirect the user to the login page, and we can't
   *   continue rendering the application without knowing the user's identity,
   *   (unless we required every consumer of the IdentityContext to handle
   *   this loading state).
   *
   * Instead, we initially short-circuit the rendering here, and wait for the
   *   second render pass to complete (`renderToStringWithData`). After that
   *   point the identity data will always be available.
   */
  if (loading) {
    return null
  }

  return <IdentityContext.Provider value={context}>{children}</IdentityContext.Provider>
}

const useIdentityContext = () => {
  const identityContext = useContext(IdentityContext)

  if (!identityContext) {
    throw new Error('Invoked `IdentityContext` outside of provider')
  }

  return identityContext
}

export {
  IdentityContext,
  IdentityContextType,
  IdentityProvider,
  IdentityConsumer,
  useIdentityContext,
  withIdentity,
  withAuthentication,
  protect
}
