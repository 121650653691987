import React, { useEffect, useState } from 'react'
import { ConsumerTracking } from '@deal/web-tracking/types'
import { useScrollPosition } from '@deal/dom-hooks'
import { usePreviousLocationAtom } from '#src/app/atoms/previousLocationAtom'
import { useEngagementChannelTrackingContext } from '../EngagementChannelTracking'

export interface DisplayTriggerSharedProps {
  displayTrigger: 'default' | 'time' | 'scroll' | 'scroll-up' | 'second-page'
  timeDelay?: number
}

export interface DisplayTriggerContext {
  showEngagementChannel: boolean
  closeEngagementChannel: () => void
}

interface DisplayTriggerProps extends DisplayTriggerSharedProps {
  children: ({ showEngagementChannel, closeEngagementChannel }: DisplayTriggerContext) => void
  engagementChannel: ConsumerTracking.EngagementChannel
}

const DisplayTriggerWrapper: React.FC<DisplayTriggerProps> = ({
  children,
  displayTrigger,
  timeDelay,
  engagementChannel
}) => {
  const [showEngagementChannel, setShowEngagementChannel] = useState(false)
  const [pageScrolled, setPageScrolled] = useState(false)
  const ecTracking = useEngagementChannelTrackingContext()
  const [previousLocation] = usePreviousLocationAtom()

  useScrollPosition({
    effect: ({ currPos, verticalDirection }) => {
      if (
        (displayTrigger === 'scroll' && (verticalDirection === 'down' || currPos.y !== 0)) ||
        (displayTrigger === 'scroll-up' && verticalDirection === 'up')
      ) {
        setPageScrolled(true)
        setShowEngagementChannel(true)
      }
    },
    wait: 200,
    skip: (displayTrigger !== 'scroll' && displayTrigger !== 'scroll-up') || pageScrolled
  })

  useEffect(() => {
    switch (displayTrigger) {
      case 'time':
        setTimeout(() => setShowEngagementChannel(true), timeDelay && timeDelay * 1000)
        break
      case 'default':
        setShowEngagementChannel(true)
        break
      case 'second-page':
        if (previousLocation) {
          setShowEngagementChannel(true)
        }
        break
      case 'scroll':
      case 'scroll-up':
        // handeled in the useScrollPosition above
        break
    }
  }, [])

  useEffect(() => {
    if (showEngagementChannel) {
      ecTracking?.engagementChannelViewed(engagementChannel)
      ecTracking?.setCampaignContext()
    }
  }, [showEngagementChannel])

  return (
    <>
      {children({
        showEngagementChannel,
        closeEngagementChannel: () => setShowEngagementChannel(false)
      })}
    </>
  )
}

export default DisplayTriggerWrapper
