import { ButtonColor } from '@deal/bluxome'
import { PageTemplateFontStyle } from '#src/generated/types'

export interface CompositeValue {
  [key: string]: {
    text?: string
    imageUrl?: string
    altText?: string
    textColor?: string
    fontStyles?: PageTemplateFontStyle[]
  }
}

export interface ComponentValue {
  text?: string
  imageUrl?: string
  altText?: string
  videoUrl?: string
  images?: { altText?: string; imageUrl?: string; mediaId?: string }[]
  entryItems?: { key: string; value: string }[]
  number?: number
  buttonType?: string
  buttonColor?: ButtonColor
  textColor?: string
  href?: string
  composites?: CompositeValue[]
  enumValue?: string
  fontStyles?: PageTemplateFontStyle[]
  anchors?: { text?: string; selectComponentName?: string }[]
  triggerEnumValue?: 'default' | 'time' | 'scroll' | 'scroll-up'
  markdownText?: string
  booleanValue?: boolean
}

export const getTextStyles = (value?: ComponentValue) => {
  return {
    color: value?.textColor,
    fontWeight: value?.fontStyles?.includes(PageTemplateFontStyle.BOLD) ? 'bold' : undefined,
    fontStyle: value?.fontStyles?.includes(PageTemplateFontStyle.ITALIC) ? 'italic' : undefined,
    textDecoration: value?.fontStyles?.includes(PageTemplateFontStyle.UNDERLINE)
      ? 'underline'
      : undefined
  }
}

export const convertToBluxomeButtonColor = (
  buutonType: undefined | string
): ButtonColor | undefined => {
  switch (buutonType) {
    case 'neutral-dark':
      return 'neutral-dark'
    case 'neutral-light-ghost':
      return 'neutral-light'
    case 'primary':
      return 'shop-orange'
    default:
      return undefined
  }
}
