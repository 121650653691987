// @ts-nocheck
import type * as Types from '../../../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { BusinessUserForShowExpertQuestionModalButtonFragmentDoc, SellableEditorialTopListRevisionForShowExpertQuestionModalButtonFragmentDoc } from '../../../ShowExpertQuestionModalButton/ShowExpertQuestionModalButton.generated';
import { BusinessUserForExpertQuestionModalFragmentDoc } from '../../../../../../../components/ExpertQuestionModal/ExpertQuestionModal.generated';
import { ExpertProfileHighlightsFragmentDoc } from '../../../../../../../fragments/ExpertProfileHighlights.generated';
import { MediaFileFragmentDoc } from '../../../../../../../fragments/MediaFile.generated';
import { ExpertForExpertsFacepileFragmentDoc } from '../../../../../../../components/ExpertsFacepile/ExpertsFacepile.generated';
import { BusinessUserForExpertProfileLinkFragmentDoc } from '../../../../../../../components/ExpertProfileLink/ExpertProfileLink.generated';
import { ExpertForExpertAvatarLegacyFragmentDoc } from '../../../../../../../components/ExpertAvatarLegacy/ExpertAvatarLegacy.generated';
import { ExpertForProductQuestionWrapperFragmentDoc } from '../../../../../../../components/ProductQuestionWrapper/ProductQuestionWrapper.generated';
export type SellableEditorialTopListQuestionForQuestionAndAnswersFragment = { __typename: 'SellableEditorialTopListQuestion', id: any, questionText: string, answers: Array<{ __typename: 'SellableEditorialTopListAnswer', answerText: string, author: { __typename: 'BusinessUser', id: any, displayName: string, active: boolean, vanityId?: string | null, state: Types.BusinessUserState, shortName: string, expertStats?: { __typename: 'ExpertStats', totalLeadsAsPrimaryExpert: number, averageRating?: number | null, totalReviews: number, totalMessagesSent: number } | null, expertProfile?: { __typename: 'ExpertProfile', id: any, headline: string, gender?: Types.ExpertGender | null, location?: string | null, backgroundRichText?: string | null, background: string, profilePhoto?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null, highlights: Array<{ __typename: 'ProfileHighlight', value: string, secondaryValue: string, type: { __typename: 'ProfileHighlightType', id: any, icon: Types.ProfileHighlightIconType, displayName: string } }> } | null, department: { __typename: 'Department', id: any, slug: string } } }> };

export type SellableEditorialTopListRevisionForQuestionAndAnswersFragment = { __typename: 'SellableEditorialTopListRevision', id: any, sellableEditorialTopListId: any, categories: Array<{ __typename: 'Category', id: any, recommendExpertForConsumer: { __typename: 'RecommendExpertPayload', expert?: { __typename: 'BusinessUser', id: any } | null } }> };

export const SellableEditorialTopListQuestionForQuestionAndAnswersFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableEditorialTopListQuestionForQuestionAndAnswers"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SellableEditorialTopListQuestion"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"questionText"}},{"kind":"Field","name":{"kind":"Name","value":"answers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"answerText"}},{"kind":"Field","name":{"kind":"Name","value":"author"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"expertStats"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"totalLeadsAsPrimaryExpert"}}]}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForShowExpertQuestionModalButton"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"ExpertForExpertsFacepile"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForExpertProfileLink"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"ExpertForExpertAvatarLegacy"}}]}}]}}]}}]} as unknown as DocumentNode;
export const SellableEditorialTopListRevisionForQuestionAndAnswersFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableEditorialTopListRevisionForQuestionAndAnswers"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SellableEditorialTopListRevision"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"sellableEditorialTopListId"}},{"kind":"Field","name":{"kind":"Name","value":"categories"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"recommendExpertForConsumer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"preferredExpertVanityId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"preferredExpertVanityId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"expert"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ExpertForProductQuestionWrapper"}}]}}]}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableEditorialTopListRevisionForShowExpertQuestionModalButton"}}]}}]} as unknown as DocumentNode;