import React, { useEffect, useState } from 'react'
import { Link, useLocation } from '@deal/router'
import { useExperiment } from '@deal/experiment-js'
import { Button, Heading, HeadlessModal, Text, useModalTrigger } from '@deal/bluxome'
import copyTextToClipboardWithToast from '#src/app/services/copyTextToClipboardWithToast'
import {
  BlackFridayPromotion,
  useBlackFridayPromotion
} from '#src/app/hooks/useBlackFridayPromotion'
import clientOnly from '#src/app/hocs/clientOnly'
import styles from './styles.css'

const IS_MODAL_DISMISSED_LOCAL_STORAGE_KEY = 'bfcm-2024-modal-dismissed'

const BlackFridayCyberMondayModal: React.FC<BlackFridayPromotion> = ({
  code,
  termsPath,
  rewardCreditPercent,
  ctaDestination
}) => {
  const { state, overlayProps } = useModalTrigger({
    defaultOpen: window.localStorage.getItem(IS_MODAL_DISMISSED_LOCAL_STORAGE_KEY) !== 'true',
    // The modal will only be displayed once if dismissed
    onOpenChange(isOpen) {
      if (!isOpen) {
        window.localStorage.setItem(IS_MODAL_DISMISSED_LOCAL_STORAGE_KEY, 'true')
      }
    }
  })

  return (
    <HeadlessModal overlayProps={overlayProps} width="small" state={state}>
      {({ titleProps }) => (
        <div className={styles.modal}>
          <div className={styles.pill}>
            <Text style="small-semibold" color="neutrals-white">
              Black Friday · Up To 50% Off
            </Text>
          </div>

          <div {...titleProps} className={styles.headline}>
            <Heading size="h1" color="neutrals-black">
              Shop Black Friday deals
            </Heading>
          </div>

          <div className={styles.subheadline}>
            <Text as="div" style="large-loose" color="neutrals-black">
              Up to{' '}
              <Text as="strong" style="large-semibold-loose">
                50% OFF
              </Text>{' '}
              select items. Plus, enjoy {rewardCreditPercent}% reward credit on full-priced items
              with code{' '}
              <Text
                as="strong"
                style="large-semibold-loose"
                onClick={() => {
                  copyTextToClipboardWithToast(code, 'Copied promo code')
                }}
              >
                {code}
              </Text>
              .
            </Text>
            <br />
            <Link to={termsPath} target="_blank" className="contents" rel="noreferrer">
              <Text as="div" style="small-underline" color="neutrals-black">
                See terms
              </Text>
            </Link>
          </div>

          <div className={styles.cta}>
            <Button color="shop-orange" link={{ to: ctaDestination, onClick: state.close }}>
              Shop deals
            </Button>
          </div>
        </div>
      )}
    </HeadlessModal>
  )
}

const BlackFridayCyberMondayModalClientOnly = clientOnly(BlackFridayCyberMondayModal, {
  fallback: () => null
})

// The modal uses `defaultOpen: true` and is only rendered on the client,
// so rendering conditions are checked in this parent component
const BlackFridayCyberMondayModalWrapper: React.FC = () => {
  const { pathname } = useLocation()

  // NB: The BFCM modal should "pop" on most pages, except for the below...
  /*
   const isModalDisabled =
    pathname.startsWith('/lp') ||
    pathname.startsWith('/sale') ||
    pathname.startsWith('/clearance') ||
    pathname.startsWith('/path') ||
    pathname.startsWith('/inbox') ||
    pathname.startsWith('/legal') ||
    pathname.startsWith('/company') ||
    pathname.startsWith('/scout')
  */

  // ... however, as of 2024-12-02 (Cyber Monday) we only want to show it on the journal page:
  const isModalDisabled = !pathname.startsWith('/journal')

  const promotion = useBlackFridayPromotion({ skip: isModalDisabled })

  const isModalEnabled =
    useExperiment({
      experiment: 'black-friday-2024-modal',
      defaultTreatment: 'control',
      options: { skip: !promotion }
    }).result === 'enabled'

  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (!isModalEnabled) {
      return
    }
    // After determining that the modal can open, wait 3s before rendering for SEO/UX purposes
    const timer = setTimeout(() => setIsVisible(true), 3_000)
    return () => {
      clearTimeout(timer)
    }
  }, [isModalEnabled])

  if (!promotion || !isModalEnabled || !isVisible) {
    return null
  }

  return <BlackFridayCyberMondayModalClientOnly {...promotion} />
}

export { BlackFridayCyberMondayModalWrapper as BlackFridayCyberMondayModal }
