import React from 'react'
import classnames from 'classnames'
import { Button, ButtonProps } from '@deal/components'
import { isMobileBrowser } from '#src/app/services/mobile'
import { useSellableStockAlertContext } from '#src/app/context/sellable-stock-alert'
import { useUserAgentContext } from '#src/app/containers/UserAgent'
import { SellableForSellableStockAlertButtonBaseFragment } from './SellableStockAlertButtonBase.generated'
import UnsubscribeIcon from './unsubscribe.svg'
import SubscribedIcon from './subscribed.svg'
import styles from './styles.css'

interface SellableStockAlertButtonBaseProps {
  sellable: SellableForSellableStockAlertButtonBaseFragment
  onSetAlert: () => void
  isCartPlacement?: boolean
  disabled?: boolean
  className?: string
  size?: ButtonProps['size']
  forceButtons?: boolean
}

const SellableStockAlertButtonBase: React.FC<SellableStockAlertButtonBaseProps> = ({
  sellable,
  onSetAlert,
  isCartPlacement,
  disabled,
  className,
  size = 'small',
  forceButtons
}) => {
  const userAgent = useUserAgentContext()
  const isMobile = isMobileBrowser(userAgent)

  const { cancelAlert, loading: cancelAlertLoading } = useSellableStockAlertContext()
  const sellableStockAlert = sellable.sellableStockAlert

  // hide for ad hoc since it may never come back and bundles because the underlying sellable has an
  //   infinite number of variations, it doesn't make sense to have alerts for them.
  if (sellable.adHoc || sellable.bundle) {
    return null
  }

  if (sellableStockAlert) {
    return isMobile && !forceButtons ? (
      <>
        <div className={styles.mobileSubscribedContainer}>
          <div className={isCartPlacement ? styles.mobileSubscribedCart : styles.mobileSubscribed}>
            <SubscribedIcon />
            <span>Subscribed</span>
          </div>
          {!isCartPlacement && (
            <Button
              variant="secondary-link"
              onClick={() => cancelAlert(sellableStockAlert)}
              disabled={cancelAlertLoading || disabled}
              size="small"
              className={styles.mobileUnsubscribeButton}
            >
              <span>Unsubscribe from alert</span>
            </Button>
          )}
        </div>
        {isCartPlacement && (
          <Button
            variant="secondary-link"
            onClick={() => cancelAlert(sellableStockAlert)}
            disabled={cancelAlertLoading || disabled}
            size="xsmall"
            className={styles.mobileUnsubscribeButtonCart}
          >
            <span>Unsubscribe from alert</span>
          </Button>
        )}
      </>
    ) : (
      <Button
        variant={isCartPlacement ? 'success-link' : 'success-ghost'}
        onClick={() => cancelAlert(sellableStockAlert)}
        disabled={cancelAlertLoading || disabled}
        size={size}
        className={classnames(
          className,
          isCartPlacement ? styles.subscribedButtonCart : styles.subscribedButton
        )}
      >
        <div
          className={classnames({
            [styles.subscribedButtonNormalContent]: !isMobile,
            [styles.subscribedButtonMobileContent]: isMobile
          })}
        >
          <SubscribedIcon />
          <span>Subscribed</span>
        </div>
        {!isMobile && (
          <div className={styles.subscribedButtonHoverContent}>
            <UnsubscribeIcon className={styles.unsubscribeIcon} />
            <span>Unsubscribe</span>
          </div>
        )}
      </Button>
    )
  } else {
    return (
      <Button
        variant={isCartPlacement ? 'secondary-link' : 'neutral-light-ghost'}
        onClick={onSetAlert}
        disabled={disabled}
        size={size}
        className={className}
      >
        <span>Notify me</span>
      </Button>
    )
  }
}

export default SellableStockAlertButtonBase
