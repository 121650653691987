// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { MediaFileFragmentDoc } from '../../../fragments/MediaFile.generated';
import { BusinessUserForChatExpertProfilePreviewFragmentDoc } from '../../ChatExpertProfilePreview/ConversationStarterExpert.generated';
import { BusinessUserForExpertProfileLinkFragmentDoc } from '../../ExpertProfileLink/ExpertProfileLink.generated';
import { ExpertForExpertAvatarLegacyFragmentDoc } from '../../ExpertAvatarLegacy/ExpertAvatarLegacy.generated';
export type BusinessUserForExpertIntroChatFragment = { __typename: 'BusinessUser', id: any, displayName: string, shortName: string, vanityId?: string | null, state: Types.BusinessUserState, expertProfile?: { __typename: 'ExpertProfile', id: any, headline: string, profilePhoto?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null, highlights: Array<{ __typename: 'ProfileHighlight', value: string, secondaryValue: string, type: { __typename: 'ProfileHighlightType', id: any, icon: Types.ProfileHighlightIconType } }> } | null, expertAttributes: { __typename: 'BusinessUserExpertAttributes', placeholderExpert: boolean }, expertStats?: { __typename: 'ExpertStats', totalMessagesSent: number, totalReviews: number } | null };

export const BusinessUserForExpertIntroChatFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BusinessUserForExpertIntroChat"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"shortName"}},{"kind":"Field","name":{"kind":"Name","value":"expertProfile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"profilePhoto"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaFile"}}]}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForChatExpertProfilePreview"}}]}}]} as unknown as DocumentNode;