import React, { useEffect } from 'react'
import uniq from 'lodash/uniq'
import loadable from '@loadable/component'
import { useLocation } from '@deal/router'
import {
  ContentSelectionChannelType,
  SellableSearchFeature,
  SellableSearchFilter,
  SellableSort,
  SortOrder
} from '#src/generated/types'
import { parseProductListingPageUrl } from '#src/app/services/search/parseProductListingPageUrl'
import { buildSearchResultsPageUrl } from '#src/app/services/search/buildSearchResultsPageUrl'
import { facetSelectionsMapToGraphQLInput } from '#src/app/services/search'
import { usePathPageTrackingCodes } from '#src/app/services/path'
import usePreferredExpert from '#src/app/hooks/usePreferredExpert'
import useGetContentSelectionCriteriaInput from '#src/app/hooks/useGetContentSelectionCriteriaInput'
import { ProductsSearchResultsViewedEvent } from '#src/app/events/ProductsSearchResultsViewedEvent'
import { useAnalyticsContext } from '#src/app/containers/Analytics'
import Redirect from '#src/app/components/Redirect'
import Page from '#src/app/components/Page'
import { useProductListingRouteQuery } from './ProductListingRoute.generated'
import LoadingComponent from './loading'

const PageComponent = loadable(() => import(/* webpackChunkName: "categories" */ './page'), {
  resolveComponent: page => page.ProductListingPageRedirect
})

export const SEARCH_RESULTS_PAGE_SIZE = 36

interface ProductListingRouteProps {}

const ProductListingRoute: React.FC<ProductListingRouteProps> = () => {
  const location = useLocation()

  const pageTrackingCodes = usePathPageTrackingCodes()

  const analytics = useAnalyticsContext()

  const searchQuery = parseProductListingPageUrl(location)
  const searchSort = searchQuery.sortBy || SellableSort.RELEVANCE
  const searchOrder = searchQuery.sortOrder || SortOrder.DESC

  // Prefer the expert from the `preferred_expert` cookie, if available
  const preferredExpertVanityId = usePreferredExpert()

  const contentSelectionInput = useGetContentSelectionCriteriaInput({
    channelType: ContentSelectionChannelType.CATEGORY_PAGE,
    categorySlug: searchQuery.categorySlug
  })

  const brandSlug =
    searchQuery.selections?.['b']?.bucketValues?.length === 1
      ? searchQuery.selections['b'].bucketValues[0]
      : ''

  useEffect(() => {
    analytics?.trackExperimentMetric('consumer_product_listing_page_views_total')
  }, [])

  const query = useProductListingRouteQuery({
    variables: {
      sellableSearchQuery: {
        facets: facetSelectionsMapToGraphQLInput(searchQuery.selections || {}),
        filters: uniq([
          SellableSearchFilter.INCLUDE_IN_STOCK_OR_PRE_ORDER,
          ...(searchQuery.filters || [])
        ]),
        eligibleForPromotionIds: searchQuery.promotionIds,
        sellableSearchUseCase: 'CONSUMER_SEARCH',
        maxFacetTerms: 100,
        searchFeatures: [SellableSearchFeature.HIERARCHICAL_CATEGORY_FACET],
        sellableExpertiseRequestId: searchQuery.expertiseRequestId
      },
      expertiseRecommendationCountsInput: {
        facets: facetSelectionsMapToGraphQLInput(searchQuery.selections || {})
      },
      categorySlug: searchQuery.categorySlug,
      // always force FacetedCategoryPageViewModel when accessing /c page
      forceSearch: true,
      offset: (searchQuery.page - 1) * SEARCH_RESULTS_PAGE_SIZE,
      limit: SEARCH_RESULTS_PAGE_SIZE,
      sortBy: searchSort,
      order: searchOrder,
      preferredExpertVanityId: preferredExpertVanityId,
      contentSelectionInput,
      createPathGraphFlowInput: {
        request: {
          pathSelector: {}
        },
        layoutType: 'INLINE',
        trackingCodes: {
          pageKey: pageTrackingCodes.pageKey,
          originalPageContext: pageTrackingCodes.originalPageContext,
          originalPageUrl: pageTrackingCodes.originalPageUrl,
          ctaName: 'category-page-placement',
          sourceKey: 'category-page-placement'
        },
        ephemeral: true
      },
      brandSlug,
      skipBrandFetch: !brandSlug
    },
    onCompleted: ({ categoryViewBySlug }) => {
      if (categoryViewBySlug?.__typename === 'FacetedCategoryPageViewModel') {
        analytics?.track(
          new ProductsSearchResultsViewedEvent({
            query: {
              category_slug: searchQuery.categorySlug,
              facets: facetSelectionsMapToGraphQLInput(searchQuery.selections || {}),
              filters: searchQuery.filters,
              sort_by: searchSort,
              page: searchQuery.page
            },
            total_results_count: categoryViewBySlug.sellablesPagination.count,
            visible_results_count: categoryViewBySlug.sellablesPagination.sellables.length,
            entry_point: searchQuery.entryPoint,
            list_id: categoryViewBySlug.sellablesPagination.trackingId
          })
        )
        analytics?.trackExperimentMetric('consumer_products_search_results_views_total')
      }
    }
  })

  return (
    <Page
      query={query}
      pageKey="product-listing"
      chat={!searchQuery.filters?.includes(SellableSearchFilter.CLEARANCE)}
      seoTitle={({ categoryViewBySlug }) => {
        if (!categoryViewBySlug) {
          return
        }

        if (categoryViewBySlug.__typename === 'FacetedCategoryPageViewModel') {
          return (
            categoryViewBySlug.sellablesPagination.pageMetadata?.pageTitle ||
            categoryViewBySlug.category?.pluralDisplayName
          )
        }
      }}
      seoDescription={({ categoryViewBySlug }) =>
        categoryViewBySlug?.category?.categoryAssetRevision?.openGraphDescription?.plainText
      }
      seoIndexable={({ categoryViewBySlug }) => {
        if (!categoryViewBySlug) {
          return false
        }

        if (categoryViewBySlug.__typename === 'FacetedCategoryPageViewModel') {
          return (
            categoryViewBySlug.sellablesPagination.indexable &&
            !!categoryViewBySlug.sellablesPagination.canonicalPath
          )
        }
        return false
      }}
      expert={({ categoryViewBySlug }) =>
        categoryViewBySlug?.category?.recommendExpertForConsumer.expert || undefined
      }
      category={({ categoryViewBySlug }) => categoryViewBySlug?.category || undefined}
      department={({ categoryViewBySlug }) => categoryViewBySlug?.category?.department || undefined}
      sellable={() => undefined}
      pageComponent={PageComponent}
      loadingComponent={LoadingComponent}
      engagementChannels
      ogImageUrl={({ categoryViewBySlug }) =>
        categoryViewBySlug?.category?.categoryAssetRevision?.openGraphImage?.image.url
      }
      canonicalPath={({ categoryViewBySlug }) => {
        if (!categoryViewBySlug) {
          return undefined
        }

        if (categoryViewBySlug.__typename === 'FacetedCategoryPageViewModel') {
          return categoryViewBySlug.sellablesPagination.canonicalPath || undefined
        }
      }}
    />
  )
}

interface ProductListingRouteProps {}

const ProductListingRouteWithSearchResultsPageRedirect: React.FC<
  React.PropsWithChildren<ProductListingRouteProps>
> = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  if (params.has('q')) {
    return (
      <Redirect
        to={buildSearchResultsPageUrl({
          keywords: params.get('q') || undefined
        })}
      />
    )
  }

  return <ProductListingRoute />
}

export { ProductListingRouteWithSearchResultsPageRedirect as ProductListingRoute }
