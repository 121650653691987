/**
 * Global style imports
 *
 * These must be imported first. Webpack will maintain source order, and we want to be able to override
 *   styles from 3rd party libraries with application styles.
 */
import '@deal/standard-css/curated.min.css'
import '@deal/dom-hooks/bodyScrollLock.min.css'
import '@deal/components/es/index.css'
import '@deal/path-flow/es/index.css'
import '@deal/bluxome/es/index.css'
import 'slick-carousel/slick/slick.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-dates/lib/css/_datepicker.css'
import './fonts.css'
import React, { useCallback, useEffect, useRef } from 'react'
import { ToastContainer } from 'react-toastify'
import { Helmet } from 'react-helmet-async'
import once from 'lodash/once'
import { Location } from 'history'
import { ConsumerTracking } from '@deal/web-tracking/types'
import { ViewerContext } from '@deal/web-tracking/constants'
import { useHistory, useLocation } from '@deal/router'
import { PathFlowProvider } from '@deal/path-flow'
import { useExperiment } from '@deal/experiment-js'
import { DeviceType, useTimeout } from '@deal/dom-hooks'
import { ComponentsProvider } from '@deal/components'
import { BluxomeProvider } from '@deal/bluxome'
import { AtomicStateProvider } from '@deal/atomic-state'
import loggerClient from '#src/app/services/loggerClient'
import Router from '#src/app/routes'
import { EngagedSessionQualifiedEvent } from '#src/app/events/EngagedSessionQualified'
import { ConsumerVisitedFromReferralLinkEvent } from '#src/app/events/ConsumerVisitedFromReferralLinkEvent'
import { PushNotificationsProvider } from '#src/app/context/push-notifications'
import { ProductComparisonContextProvider } from '#src/app/context/product-comparison'
import { CreatePathWithInterstitialContextProvider } from '#src/app/context/path-create-with-interstitial'
import { LoomProvider } from '#src/app/context/loom'
import { GlobalNotificationProvider } from '#src/app/context/global-notification'
import { GlobalLoaderContextProvider } from '#src/app/context/global-loader'
import { CurrentExpertProvider } from '#src/app/context/expert'
import { EngagementChannelBannerContextProvider } from '#src/app/context/engagement-channel-banner'
import { useCookieContext } from '#src/app/context/cookies'
import { ExpertConversationStartersContextProvider } from '#src/app/context/conversation-starters'
import { ChatWidgetStateProvider } from '#src/app/context/chat-widget-state'
import { CartVisibilityProvider } from '#src/app/context/cart-visibility'
import { CartProvider } from '#src/app/context/cart'
import { useUserAgentContext } from '#src/app/containers/UserAgent'
import useLogoutCallback from '#src/app/containers/Identity/useLogoutCallback'
import useLoginCallback from '#src/app/containers/Identity/useLoginCallback'
import AuthChangeObserver from '#src/app/containers/Identity/AuthChangeObserver'
import { useIdentityContext } from '#src/app/containers/Identity'
import ChatProvider from '#src/app/containers/Chat'
import userTraitsFromMyself from '#src/app/containers/Analytics/userTraitsFromMyself'
import { useAnalyticsContext } from '#src/app/containers/Analytics'
import config from '#src/app/config'
import UnsupportedBrowserBanner from '#src/app/components/UnsupportedBrowserBanner'
import { ScrollToTop } from '#src/app/components/ScrollToTop'
import RegisterServiceWorker from '#src/app/components/RegisterServiceWorker'
import { ProductComparisonModal } from '#src/app/components/ProductComparisonModal'
import PageMetadata from '#src/app/components/PageMetadata'
import { ImpersonationWarningModal } from '#src/app/components/ImpersonationWarningModal'
import Heartbeat from '#src/app/components/Heartbeat'
import ExpertProfileDraftFullPageTakeover from '#src/app/components/ExpertProfileDraftFullPageTakeover'
import CCPAConsentBanner from '#src/app/components/CCPAConsentBanner'
import BrowserTimezone from '#src/app/components/BrowserTimezone'
import OpenGraphImage from './favicons/og-image.png'
import Favicon32 from './favicons/icon-32.png'
import Favicon16 from './favicons/icon-16.png'
import AppleTouchIcon from './favicons/apple-touch-icon.png'
import styles from './styles.css'

// Favicons
const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { myself, sessionId } = useIdentityContext()
  const userAgent = useUserAgentContext()
  const analytics = useAnalyticsContext()
  const cookies = useCookieContext()
  const history = useHistory()
  const location = useLocation()
  const prevLocation = useRef(location)

  /**
   * Track an "Engaged Session Qualified" event if the user stays on the app for 10s+ or the URL changes.
   *
   * This method is called on every page change, and after a timeout (see below), but is wrapped in `lodash/once`
   *   to ensure only a single event is tracked.
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps -- once() is a higher-order fn returning an inline fn
  const trackEngagedSession = useCallback(
    once(() => {
      analytics?.track(new EngagedSessionQualifiedEvent())
      analytics?.trackExperimentMetric('consumer_engaged_sessions_total')
    }),
    []
  )

  const trackContentMetrics: (location: Location) => void = location => {
    analytics?.trackExperimentMetric('content_engagement_actions_total')

    if (
      location.pathname.startsWith('/shop/') ||
      location.pathname.startsWith('/c/') ||
      location.pathname.startsWith('/products/') ||
      location.pathname.startsWith('/pla/') ||
      location.pathname.startsWith('/compare')
    ) {
      analytics?.trackExperimentMetric('content_shopping_actions_total')
    }
  }

  /**
   * On mount, subscribe to any route changes and track a `PageViewed` event.
   */
  useEffect(() => {
    const unregisterHistoryListener = history.listen((location, action) => {
      // Send a beacon to SpeedCurve with the metrics collected up to this point. Metrics are collected continuously,
      //   and a beacon is automatically sent whenever the page is hidden or closed, but we also want to ensure we
      //   aren't collecting metrics across page transitions. Doing so would pollute metrics like CLS since a page
      //   transition is otherwise indistinguishable from a very slowly loading page.
      //
      // For now, we only send beacons for the first page load. If we decide to instrument page transitions as well,
      //   we would call `window.LUX.init()` immediately after `window.LUX.send()`. This would dramatically increase
      //   the number of page views we are billed for by SpeedCurve, and it may be difficult to distinguish metrics
      //   between the two types of page loads within the SpeedCurve dashboards.
      //
      // Note: this callback occurs after the location has updated, meaning the pathname sent to SpeedCurve will be
      //   incorrect. However, the page label ("page key" in our terminology) will not have been updated yet and
      //   that is what is used primarily by SpeedCurve.
      //
      // See: https://support.speedcurve.com/docs/single-page-applications
      // See: https://support.speedcurve.com/docs/rum-js-api#luxsend
      window.LUX?.send()

      // Track a PageViewed event
      const ref = new URLSearchParams(location.search).get('ref') || undefined

      analytics?.page({
        navigation_type: `client_${action.toLowerCase() as Lowercase<typeof action>}`,
        ref
      })

      // track navigation away from content pages
      if (
        prevLocation.current.pathname.startsWith('/journal/') ||
        prevLocation.current.pathname.startsWith('/most-recommended/') ||
        prevLocation.current.pathname.startsWith('/top-lists/')
      ) {
        trackContentMetrics(location)
      }

      // Track an "Engaged Session Qualified" event if the user navigvates to another internal page
      trackEngagedSession()
      prevLocation.current = location
    })

    return () => {
      if (unregisterHistoryListener) {
        unregisterHistoryListener()
      }
    }
  }, [])

  /**
   * Track an "Engaged Session Qualified" event if the user stays on the app for 10s+
   */
  useTimeout(() => {
    trackEngagedSession()
  }, 10_000)

  /**
   * On login, identify the user in our the logs
   */
  useLoginCallback(newMyself => {
    loggerClient.identifyUser({
      id: newMyself.user.id
    })
  })

  /**
   * Set the initial `deal_ust` cookie value once the tracking client has been initialized:
   * (1) when `e` parameter is present, set it to last touch (if it exists), meaning attribute the user to the latest expert;
   * (2) otherwise, set it to first touch (if it exists).
   */
  useEffect(() => {
    window.tracking?.ready(() => {
      const queryParams = new URLSearchParams(history.location.search)
      const preferredExpertSlug = queryParams.get('e')
      if (preferredExpertSlug && window.tracking?.lastTouch) {
        cookies.setCookie('deal_ust', JSON.stringify(window.tracking.lastTouch.attributes), {
          expires: 60
        })
      } else if (window.tracking?.firstTouch) {
        cookies.setCookie('deal_ust', JSON.stringify(window.tracking.firstTouch.attributes), {
          expires: 60
        })
      }

      // for initial page load, if the reference is journal, track it
      const ref = new URLSearchParams(location.search).get('ref') || undefined
      if ((['journal', 'most-recommended', 'top-lists'] as (string | undefined)[]).includes(ref)) {
        trackContentMetrics(location)
      }
    })
  }, [])

  /**
   * On login, enable or disable user tracking, identify the user, and clear the "deal_ust" cookie
   */
  useLoginCallback(newMyself => {
    if (newMyself.realUser.id !== newMyself.user.id) {
      // The user is being impersonated. Disable all tracking.
      window.tracking?.disable()
    } else if (userAgent && !userAgent.isBot) {
      // The user is no longer (or was never) being impersonated. Re-enable tracking unless it's a bot
      window.tracking?.enable()
    }

    window.tracking?.identify({ userId: newMyself.id }, ViewerContext.Consumer)
    window.tracking?.addTraits(userTraitsFromMyself(newMyself))

    // Remove the `deal_ust` cookie to reduce the size of our API requests, since this cookie
    //   is only relevant for registration mutations.
    cookies.removeCookie('deal_ust')
  })

  /**
   * On logout, reset the tracking client's state
   */
  useLogoutCallback(() => {
    if (sessionId) {
      window.tracking?.reset(sessionId)
    }
  })

  /**
   * On mount, set the preferred expert as a cookie if a certain query param is present
   */
  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search)
    const preferredExpertSlug = queryParams.get('e')

    if (preferredExpertSlug) {
      cookies.setCookie('preferred_expert', preferredExpertSlug, {
        // Only send this cookie to `www.` so we don't bloat API requests
        domain: config.get('consumer.host'),
        // Expire the cookie after 30 days
        expires: 30
      })
    }
  }, [])

  /**
   * On mount, track a `ConsumerVisitedFromReferralLink` event if certain query params
   *   are present.
   */
  useEffect(() => {
    // Initial pageview event & tracking event for if user is coming from referral link
    const urlParams = new URLSearchParams(window.location.search)
    const entryPoint = urlParams.get('entry_point')
    const medium = urlParams.get('utm_medium')
    if (entryPoint && medium && analytics) {
      analytics.track(
        new ConsumerVisitedFromReferralLinkEvent({
          entry_point: entryPoint as ConsumerTracking.EntryPoint,
          medium: medium as ConsumerTracking.ReferralMedium
        })
      )
    }
  }, [])

  const isImpersonating = myself?.realUser.id !== myself?.user.id
  const deviceType = userAgent.device.type as DeviceType

  const removeRef: (location: Location) => string = location => {
    const urlParams = new URLSearchParams(location.search)

    if (urlParams.has('ref')) {
      urlParams.delete('ref')
    }

    const search = urlParams.toString()

    return history.createHref({
      pathname: location.pathname,
      search,
      hash: location.hash,
      key: location.key
    })
  }

  const { result: ccpaResult } = useExperiment({
    experiment: 'consumer-ccpa-banner',
    defaultTreatment: 'control'
  })

  return (
    <AtomicStateProvider>
      <AuthChangeObserver />
      <CartProvider>
        <CartVisibilityProvider>
          <CreatePathWithInterstitialContextProvider>
            <ChatProvider>
              <CurrentExpertProvider>
                <ExpertConversationStartersContextProvider>
                  <GlobalNotificationProvider>
                    <PushNotificationsProvider>
                      <GlobalLoaderContextProvider>
                        <EngagementChannelBannerContextProvider>
                          <BluxomeProvider value={{ deviceType: deviceType || 'desktop' }}>
                            <ComponentsProvider value={{ deviceType }}>
                              <PathFlowProvider value={{ deviceType }}>
                                <LoomProvider>
                                  <ProductComparisonContextProvider>
                                    <ChatWidgetStateProvider>
                                      {/* Default metadata. These can be overridden by more deeply nested instances of PageMetadata. */}
                                      <PageMetadata
                                        type="website"
                                        twitterCardType="summary_large_image"
                                        canonicalPath={removeRef(location)}
                                      />
                                      <Helmet>
                                        <meta name="keywords" content="deal deals" />
                                        <meta name="msapplication-TileColor" content="#ffffff" />
                                        <meta name="theme-color" content="#ffffff" />
                                        <meta property="og:image" content={OpenGraphImage} />
                                        <link
                                          rel="apple-touch-icon"
                                          sizes="180x180"
                                          href={AppleTouchIcon}
                                        />
                                        <link
                                          rel="icon"
                                          type="image/png"
                                          sizes="32x32"
                                          href={Favicon32}
                                        />
                                        <link
                                          rel="icon"
                                          type="image/png"
                                          sizes="16x16"
                                          href={Favicon16}
                                        />
                                      </Helmet>
                                      <Heartbeat />
                                      <BrowserTimezone />
                                      <RegisterServiceWorker />
                                      <ExpertProfileDraftFullPageTakeover />
                                      {isImpersonating && (
                                        <>
                                          <div className={styles.impersonationOverlay} />
                                          <ImpersonationWarningModal />
                                        </>
                                      )}
                                      <ToastContainer
                                        position="bottom-right"
                                        autoClose={5000}
                                        hideProgressBar={true}
                                        newestOnTop={false}
                                        closeOnClick={true}
                                        pauseOnHover={false}
                                        className={styles.toast}
                                        enableMultiContainer
                                      />
                                      <UnsupportedBrowserBanner
                                        text="Unsupported browser, some things might not work."
                                        linkText="View compatible browsers"
                                      />
                                      {ccpaResult === 'on' && <CCPAConsentBanner />}
                                      <ScrollToTop>
                                        <Router />
                                      </ScrollToTop>
                                    </ChatWidgetStateProvider>
                                    <ProductComparisonModal />
                                  </ProductComparisonContextProvider>
                                </LoomProvider>
                              </PathFlowProvider>
                            </ComponentsProvider>
                          </BluxomeProvider>
                        </EngagementChannelBannerContextProvider>
                      </GlobalLoaderContextProvider>
                    </PushNotificationsProvider>
                  </GlobalNotificationProvider>
                </ExpertConversationStartersContextProvider>
              </CurrentExpertProvider>
            </ChatProvider>
          </CreatePathWithInterstitialContextProvider>
        </CartVisibilityProvider>
      </CartProvider>
    </AtomicStateProvider>
  )
}

export default App
