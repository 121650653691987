import { Event, ViewerContext } from '@deal/web-tracking'

export interface LoomRecordingStartedProperties {
  lead_id?: string
}

export class LoomRecordingStarted extends Event<LoomRecordingStartedProperties> {
  static displayName = 'Loom Recording Started'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
