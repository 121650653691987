import * as History from 'history'
import { ConsumerTracking } from '@deal/web-tracking'
import { matchPath } from '@deal/router'
import { SellableSearchFilter, SellableSort, SortOrder } from '#src/generated/types'
import loggerClient from '../loggerClient'
import { SearchResultsPageQuery } from './types'
import { FACET_SERIALIZERS } from './serializers'

/**
 * Parse a URL for a search results page (SRP). SRPs are a faceted UI, and support
 *   many of the features of PLPs: sorting, pagination, and filtering. The difference
 *   is that SRPs include a keyword search in addition to filters & facets.
 *
 * Some example SRP URLs might look like:
 *
 *  /search?q=red+jackets
 *  /search/golf?q=steel+irons
 *  /search/kitchen?q=knife&nrange%3Af-blade-length=20-70
 */
export function parseSearchResultsPageUrl({
  pathname,
  search
}: History.Location): SearchResultsPageQuery {
  const searchParams = new URLSearchParams(search)

  // Find `/search/:departmentSlug`
  const departmentSlug = matchPath<{ departmentSlug?: string }>(pathname, {
    path: '/search/:departmentSlug?'
  })?.params.departmentSlug

  // Find `/search/:categorySlug`
  const categorySlug = matchPath<{ categorySlug?: string }>(pathname, {
    path: '/search/:categorySlug?'
  })?.params.categorySlug

  // Create a basic query
  const query: SearchResultsPageQuery = {
    departmentSlug,
    categorySlug,
    selections: {},
    filters: [],
    page: 1
  }

  // Add the query keywords
  if (searchParams.get('q')) {
    query.keywords = searchParams.get('q') || undefined
  }

  // Add the facets from the query parameters (`/search?q=red+jacket&bucket:facetName=facetValue1,facetValue2`)
  for (const [key, value] of searchParams.entries()) {
    const serializer = FACET_SERIALIZERS.find(serializer =>
      key.startsWith(serializer.SERIALIZATION_PREFIX + ':')
    )

    if (serializer) {
      const facetName = key.slice(serializer.SERIALIZATION_PREFIX.length + 1)

      query.selections![facetName] = {
        name: facetName,
        alias: undefined,
        ...serializer.deserialize(value)
      }
    }
  }

  // Add the page nuber
  if (searchParams.has('page')) {
    query.page = parseInt(searchParams.get('page')!, 10)
  }

  // Add the sorting method
  switch (searchParams.get('sort')!) {
    case 'price':
      query.sortBy = SellableSort.AVG_SALE_PRICE
      break

    case 'savings':
      query.sortBy = SellableSort.MAX_PRICE_PERCENT_SAVINGS
      break

    case 'purchases':
      query.sortBy = SellableSort.BEST_SELLER
      break

    case 'trending':
      query.sortBy = SellableSort.POPULARITY
      break

    case 'recommended':
      query.sortBy = SellableSort.MOST_RECOMMENDED_BY_EXPERTS
      break

    default:
      query.sortBy = SellableSort.RELEVANCE
  }

  // Add the sorting order (descending by default)
  query.sortOrder = searchParams.get('order') === 'asc' ? SortOrder.ASC : SortOrder.DESC

  // Add additional filters
  if (searchParams.has('filters')) {
    searchParams
      .get('filters')!
      .split(',')
      .forEach((filterValue: string) => {
        const filterEnumValue = filterValue.toUpperCase()

        if (filterEnumValue in SellableSearchFilter) {
          query.filters!.push(SellableSearchFilter[filterEnumValue as SellableSearchFilter])
        } else {
          loggerClient.captureError(new Error(`Unknown filter in search URL: ${filterEnumValue}`))
        }
      })
  }

  // Add the entry point for tracking
  if (searchParams.has('source')) {
    query.entryPoint = searchParams.get('source')! as ConsumerTracking.SearchEntryPoint
  }

  // Add the preview query mapping ID
  if (searchParams.has('previewQueryMappingId')) {
    query.previewQueryMappingId = searchParams.get('previewQueryMappingId')!
  }

  // Add the consumer search ID
  if (searchParams.has('cstId')) {
    query.consumerSearchId = searchParams.get('cstId')!
  }

  if (searchParams.has('layout')) {
    query.layout = searchParams.get('layout') as 'grid' | 'list'
  }

  return query
}
