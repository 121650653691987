import { toast } from 'react-toastify'
import { CreatePathProps, useCreatePath } from '@deal/path-flow'
import { AttributeType } from '#src/generated/types'
import { usePathPageTrackingCodes } from '#src/app/services/path'
import loggerClient from '#src/app/services/loggerClient'
import { ArticleForCreatePathFromArticleFragment } from './ArticleForCreatePathFromArticle.generated'

type CreatePathTuple = [(props: CreatePathFromArticleProps) => void, { loading: boolean }]

interface CreatePathFromArticleProps {
  sourceKey: string
  article: ArticleForCreatePathFromArticleFragment
}

/**
 * A hook wrapping the path creation for when paths are created specifically from a article. This
 * uses the articles's top category as a starting point and the article ID as `viewedArticleIds`
 * so we can inject the articles's attributes within the path editor
 *
 * @returns CreatePathTuple
 */
export default function useCreateRequestPathFromArticle(
  createPathProps: CreatePathProps
): CreatePathTuple {
  const [createPath, { loading }] = useCreatePath(createPathProps)
  const pageTrackingCodes = usePathPageTrackingCodes()

  return [
    ({ sourceKey, article }: CreatePathFromArticleProps) => {
      const articleCategory = article.categories[0].slug
      if (!articleCategory) {
        loggerClient.captureError(
          new Error('Attempted to create path from article wihout a category'),
          {
            articleId: article.id
          }
        )

        toast.error('Error starting a new quiz for this article!')
        return
      }

      createPath({
        timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
        request: {
          pathSelector: {
            categorySlugHierarchyLookup: article.categories[0].slug
          },
          initialAttributes: [
            {
              name: 'viewedArticleId',
              type: AttributeType.ID,
              idValue: article.id
            }
          ]
        },
        trackingCodes: {
          ctaName: sourceKey,
          pageKey: pageTrackingCodes.pageKey,
          originalPageContext: pageTrackingCodes.originalPageContext,
          originalPageUrl: pageTrackingCodes.originalPageUrl,
          sourceKey
        }
      })
    },
    { loading }
  ]
}
