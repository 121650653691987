// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { BusinessUserForGetExpertExpertiseFragmentDoc, BusinessUserForGetExpertProfileLinkFragmentDoc } from '../expert/expert.generated';
export type BusinessUserForExpertForAvatarFragment = { __typename: 'BusinessUser', id: any, displayName: string, expertProfile?: { __typename: 'ExpertProfile', id: any, headline: string, profilePhoto?: { __typename: 'MediaFile', id: any, url: string } | null } | null, expertStats?: { __typename: 'ExpertStats', averageRating?: number | null } | null, expertAttributes: { __typename: 'BusinessUserExpertAttributes', category: { __typename: 'Category', id: any, slug: string, singularDisplayName: string, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, expert?: { __typename: 'CategoryAssetNoun', singular: string } | null } | null }, specialtyCategory?: { __typename: 'Category', id: any, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, expert?: { __typename: 'CategoryAssetNoun', singular: string } | null } | null } | null }, articleAuthorProfile?: { __typename: 'ExpertProfile', id: any, headline: string } | null };

export type BusinessUserForExpertForAvatarWithPopoverFragment = { __typename: 'BusinessUser', id: any, displayName: string, vanityId?: string | null, state: Types.BusinessUserState, expertProfile?: { __typename: 'ExpertProfile', id: any, background: string, backgroundSummaryLines: Array<string>, headline: string, profilePhoto?: { __typename: 'MediaFile', id: any, url: string } | null } | null, expertStats?: { __typename: 'ExpertStats', totalReviews: number, totalLeadsParticipated: number, averageRating?: number | null } | null, expertAttributes: { __typename: 'BusinessUserExpertAttributes', category: { __typename: 'Category', id: any, slug: string, singularDisplayName: string, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, expert?: { __typename: 'CategoryAssetNoun', singular: string } | null } | null }, specialtyCategory?: { __typename: 'Category', id: any, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, expert?: { __typename: 'CategoryAssetNoun', singular: string } | null } | null } | null }, articleAuthorProfile?: { __typename: 'ExpertProfile', id: any, headline: string } | null };

export const BusinessUserForExpertForAvatarFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BusinessUserForExpertForAvatar"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"expertProfile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"profilePhoto"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"expertStats"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"averageRating"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForGetExpertExpertise"}}]}}]} as unknown as DocumentNode;
export const BusinessUserForExpertForAvatarWithPopoverFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BusinessUserForExpertForAvatarWithPopover"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"expertProfile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"background"}},{"kind":"Field","name":{"kind":"Name","value":"backgroundSummaryLines"}}]}},{"kind":"Field","name":{"kind":"Name","value":"expertStats"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"totalReviews"}},{"kind":"Field","name":{"kind":"Name","value":"totalLeadsParticipated"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForExpertForAvatar"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForGetExpertProfileLink"}}]}}]} as unknown as DocumentNode;