import React, { useState } from 'react'
import loadable from '@loadable/component'
import { useDepartment } from '#src/app/context/department'
import Page from '#src/app/components/Page'
import { useWishlistsRouteQuery } from './WishlistsRoute.generated'
import WishlistLoader from '../components/SkeletonLoader'
import WishlistNotFound from './page/components/WishlistNotFound'

const PageComponent = loadable(() => import(/* webpackChunkName: "wishlist" */ './page'), {
  resolveComponent: components => components.ViewWishlistPage
})

interface ViewWishlistsRouteProps {
  id: string
}

const ViewWishlistsRoute: React.FC<ViewWishlistsRouteProps> = ({ id }) => {
  const [stillNeeded, setStillNeeded] = useState<boolean | null>(null)

  const query = useWishlistsRouteQuery({
    variables: {
      wishlistIdOrFriendlyId: id,
      stillNeeded
    },
    nextFetchPolicy: 'cache-and-network'
  })

  const department = useDepartment()

  return (
    <Page
      query={query}
      pageKey="wishlist"
      chat={false}
      seoTitle={data => data.findWishlistByIdOrFriendlyId?.title}
      seoDescription={undefined}
      seoIndexable={true}
      expert={undefined}
      category={undefined}
      department={department || undefined}
      sellable={() => undefined}
      pageComponent={PageComponent}
      loadingComponent={WishlistLoader}
      errorComponent={WishlistNotFound}
      pageProps={{
        onUpdateItemFilters: (updatedStillNeeded: boolean | null) =>
          setStillNeeded(updatedStillNeeded)
      }}
      canonicalPath={undefined}
      ogImageUrl={undefined}
    />
  )
}

export default ViewWishlistsRoute
