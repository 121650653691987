import React, { useContext, useRef, useState } from 'react'
import { SDKButtonInterface, SDKState } from '@loomhq/record-sdk'
import { oembed } from '@loomhq/loom-embed'
import { LoomRecordingStarted } from '#src/app/events/LoomRecordingStarted'
import { LoomInstantiated } from '#src/app/events/LoomInstantiatedEvent'
import { LoomError } from '#src/app/events/LoomErrorEvent'
import { useIdentityContext } from '#src/app/containers/Identity'
import { SDKError, SDK_ERROR_VALUES } from '#src/app/components/RecordingManagementModal'
import { useLoomJwtLazyQuery } from './LoomModal.generated'

interface LoomVideo {
  id: string
  title: string
  height: number
  width: number
  sharedUrl: string
  embedUrl: string
  thumbnailHeight?: number
  thumbnailWidth?: number
  thumbnailUrl?: string
  duration?: number
  providerUrl: string
}

interface LoomOptions {
  leadId: string
  button: HTMLElement
  onError?: (error: SDKError) => void
  onInsertClick?: (video: LoomVideo) => void
  onRecordingComplete?: (video: LoomVideo) => void
  onUploadComplete?: (video: LoomVideo, isVideoOn: boolean) => void
  onCancel?: () => void
  onRecordingStart?: () => void
  onLifecycleUpdate?: (
    state: SDKState,
    previousState: SDKState | null,
    videoUrl: string | null
  ) => void
}

type LoomContextType = {
  setupLoom: (optional: LoomOptions) => void
  error?: SDKError
  sdkButton: SDKButtonInterface | null
  loading: boolean
  status: SDKState | null
  videoUrl: string | null
}

// Context
const LoomContext = React.createContext<LoomContextType | null>(null)

// Provider
/**
 * Designs and copy in this component is currently highly specific to
 * recoding curation previews
 * 
 * To initiate Loom in a component, call
 *   useEffect(() => {
      if (
        // conditions, likely if lead exists
      ) {
        startLoom(lead, isPreview)
      }
    }, [])
 */
const LoomProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [loomSdkLoading, setLoomSdkLoading] = useState(true)
  const { myself } = useIdentityContext()

  const sdkButtonRef = useRef<SDKButtonInterface | null>(null)

  const [videoUrl, setVideoUrl] = useState<string | null>(null)
  const videoRef = useRef<string | null>(null)

  const isVideoOnRef = useRef<boolean>(false)

  // We keep a ref because we need access to the current state inside the lifecycle update but we also need to trigger an
  // update for the context so we keep in state as well. Ref updates do not cause re-renders.
  const [status, setStatus] = useState<SDKState | null>(null)
  const statusRef = useRef<SDKState | null>(null)

  // Only user's with an expert account can generate a JWT token
  // These tokens expire, so always fetch a new one
  const [fetchToken, { loading }] = useLoomJwtLazyQuery()

  async function setupLoom({
    leadId,
    button,
    onInsertClick,
    onRecordingComplete,
    onCancel,
    onRecordingStart,
    onError,
    onLifecycleUpdate,
    onUploadComplete
  }: LoomOptions) {
    setLoomSdkLoading(true)

    const processError = (error: SDKError) => {
      setLoomSdkLoading(false)
      onError?.(error)
      window.tracking?.track(
        new LoomError({
          lead_id: leadId,
          reason: error
        })
      )
    }

    // Must have expert account to record with loom
    if (!myself?.businessUser) {
      processError(SDK_ERROR_VALUES.Unauthorized)
      return
    }

    const { data } = await fetchToken()

    if (!data?.loomJwt) {
      processError(SDK_ERROR_VALUES.Unauthorized)
      return
    }

    const { isSupported, setup } = await import(
      /* webpackChunkName: 'loom-sdk' */ '@loomhq/record-sdk'
    )

    const { supported, error } = await isSupported()
    if (!supported || error) {
      processError(error || 'unknown')
      return
    }

    if (!(button instanceof HTMLButtonElement)) {
      processError(SDK_ERROR_VALUES.ImplementationError)
      return
    }

    const { configureButton, status } = await setup({
      jws: data?.loomJwt,
      config: {
        insertButtonText: 'Copy Link & Continue'
      }
    })

    const { success } = status()

    if (!success) {
      processError(SDK_ERROR_VALUES.LoomFailure)
      return
    }

    window.tracking?.track(
      new LoomInstantiated({
        lead_id: leadId
      })
    )

    sdkButtonRef.current = configureButton({ element: button })

    // When user requests to copy video link post video recording
    sdkButtonRef.current.on('insert-click', async (video: LoomVideo) => {
      navigator.clipboard.writeText(video.sharedUrl)
      onInsertClick?.(video)
    })

    sdkButtonRef.current.on('recording-complete', async (video: LoomVideo) => {
      videoRef.current = video.sharedUrl
      setVideoUrl(video.sharedUrl)
      onRecordingComplete?.(video)
    })

    // When recording is cancelled from the trash icon in loom recording actions
    sdkButtonRef.current.on('cancel', async () => {
      onCancel?.()
    })

    // When recording is started (countdown timer hits 0)
    sdkButtonRef.current.on('recording-start', async () => {
      window.tracking?.track(
        new LoomRecordingStarted({
          lead_id: leadId
        })
      )

      const isVideoOn = !!document
        .getElementById('loom-sdk-record-overlay-shadow-root-id')
        ?.shadowRoot?.querySelector('[aria-label="Camera bubble settings"]')

      isVideoOnRef.current = isVideoOn

      onRecordingStart?.()
    })

    sdkButtonRef.current.on('lifecycle-update', async (state: SDKState) => {
      onLifecycleUpdate?.(state, statusRef.current, videoRef.current)
      statusRef.current = state
      setStatus(statusRef.current)
    })

    sdkButtonRef.current.on('upload-complete', async (video: LoomVideo) => {
      /**
       * Video duration does not exist on the video object returned by Loom API.
       * Instead, when video is done uploading we use their oembed API to re-fetch the video metadata.
       * Override the video duration with the duration from this object.
       */
      const oembedData = await oembed(video.sharedUrl)
      video.duration = Math.round(oembedData.duration)
      video.thumbnailUrl = oembedData.thumbnail_url
      onUploadComplete?.(video, isVideoOnRef.current)
    })

    setLoomSdkLoading(false)
  }

  return (
    <LoomContext.Provider
      value={{
        setupLoom,
        sdkButton: sdkButtonRef.current,
        loading: loading || loomSdkLoading,
        status,
        videoUrl
      }}
    >
      {children}
    </LoomContext.Provider>
  )
}

const useLoomContext = (): LoomContextType => {
  const loomContext = useContext(LoomContext)
  if (!loomContext) {
    throw new Error('Invoked `LoomContext` outside of provider')
  }

  return loomContext
}

export { LoomProvider, useLoomContext }
