// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { AuthenticatedUserFragmentDoc } from '../../fragments/AuthenticatedUser.generated';
import { AddressFragmentDoc } from '../../fragments/Address.generated';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type AuthenticateMutationVariables = Types.Exact<{
  input: Types.AuthenticationInput;
}>;


export type AuthenticateMutation = { __typename: 'Mutation', authenticate: { __typename: 'AuthenticationResultPayload', result: Types.AuthenticationResult, message?: string | null, user?: { __typename: 'User', id: any, experimentUserId: any, firstName?: string | null, lastName?: string | null, roles: Array<string>, profilePhoto?: { __typename: 'MediaFile', id: any, url: string } | null, notificationSettings: Array<{ __typename: 'NotificationSetting', type: Types.NotificationType, emailEnabled: boolean, smsEnabled: boolean }>, messagingUser: { __typename: 'MessagingUser', id: any }, address?: { __typename: 'PostalAddress', line1?: string | null, city?: string | null, state?: string | null, postalCode: string, country?: string | null } | null, savedAddresses: Array<{ __typename: 'Address', id: any, addresseeName?: string | null, line1?: string | null, line2?: string | null, city?: string | null, state?: string | null, country?: string | null, postalCode: string, securityCode?: string | null, phoneNumber?: string | null, active: boolean, isDefault?: boolean | null, countryDetails?: { __typename: 'Country', displayCountry: string } | null }>, contacts: Array<{ __typename: 'Contact', id: any, type: Types.ContactType, value: string, verified: boolean, emailAddress?: string | null, phoneNumber?: string | null, parsedPhoneNumber?: { __typename: 'ParsedPhoneNumber', countryCode: string, regionCode: string, nationalNumber: string } | null }> } | null } };


export const AuthenticateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Authenticate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AuthenticationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"authenticate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"result"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AuthenticatedUser"}}]}}]}}]}},...AuthenticatedUserFragmentDoc.definitions,...AddressFragmentDoc.definitions]} as unknown as DocumentNode;
export type AuthenticateMutationFn = Apollo.MutationFunction<AuthenticateMutation, AuthenticateMutationVariables>;
export type AuthenticateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AuthenticateMutation, AuthenticateMutationVariables>, 'mutation'>;

    export const AuthenticateComponent = (props: AuthenticateComponentProps) => (
      <ApolloReactComponents.Mutation<AuthenticateMutation, AuthenticateMutationVariables> mutation={AuthenticateDocument} {...props} />
    );
    

/**
 * __useAuthenticateMutation__
 *
 * To run a mutation, you first call `useAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateMutation, { data, loading, error }] = useAuthenticateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthenticateMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateMutation, AuthenticateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticateMutation, AuthenticateMutationVariables>(AuthenticateDocument, options);
      }
export type AuthenticateMutationHookResult = ReturnType<typeof useAuthenticateMutation>;
export type AuthenticateMutationResult = Apollo.MutationResult<AuthenticateMutation>;
export type AuthenticateMutationOptions = Apollo.BaseMutationOptions<AuthenticateMutation, AuthenticateMutationVariables>;