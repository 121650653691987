// @ts-nocheck
import type * as Types from '../../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { DisplayFacetFragmentDoc, BucketedDisplayFacetFragmentDoc, BucketedRangeDisplayFacetFragmentDoc, NumericRangeDisplayFacetFragmentDoc } from '../../../../../../fragments/Facets.generated';
export type SellableForConsumerProductReviewSummaryFragment = { __typename: 'Sellable', id: any, consumerProductReviewLMSummary?: { __typename: 'ConsumerProductReviewLMSummary', summary: string, themes: Array<{ __typename: 'ConsumerProductReviewLMSummaryThemeEntry', explanation: string, sentiment: string, theme: string }> } | null, facetConnection: { __typename: 'ConsumerProductReviewConnection', facets: Array<{ __typename: 'BucketedDisplayFacet', displayName: string, name: string, alias?: string | null, buckets: Array<{ __typename: 'IndividualBucketedDisplayFacet', displayName: string, value: string, parentValue?: string | null, count?: any | null, seoShouldFollow: boolean }> } | { __typename: 'BucketedRangeDisplayFacet', displayName: string, name: string, alias?: string | null } | { __typename: 'NumericRangeDisplayFacet', displayName: string, name: string, alias?: string | null, type: Types.NumericRangeDisplayFacetType, lower: any, upper: any, scale: number, upperCapped: boolean }> } };

export type ConsumerProductReviewLmSummaryForConsumerProductReviewSummaryFragment = { __typename: 'ConsumerProductReviewLMSummary', summary: string, themes: Array<{ __typename: 'ConsumerProductReviewLMSummaryThemeEntry', explanation: string, sentiment: string, theme: string }> };

export type ThemeForConsumerProductReviewSummaryFragment = { __typename: 'ConsumerProductReviewLMSummaryThemeEntry', explanation: string, sentiment: string, theme: string };

export const ThemeForConsumerProductReviewSummaryFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ThemeForConsumerProductReviewSummary"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ConsumerProductReviewLMSummaryThemeEntry"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"explanation"}},{"kind":"Field","name":{"kind":"Name","value":"sentiment"}},{"kind":"Field","name":{"kind":"Name","value":"theme"}}]}}]} as unknown as DocumentNode;
export const ConsumerProductReviewLmSummaryForConsumerProductReviewSummaryFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ConsumerProductReviewLMSummaryForConsumerProductReviewSummary"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ConsumerProductReviewLMSummary"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"summary"}},{"kind":"Field","name":{"kind":"Name","value":"themes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ThemeForConsumerProductReviewSummary"}}]}}]}}]} as unknown as DocumentNode;
export const SellableForConsumerProductReviewSummaryFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableForConsumerProductReviewSummary"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Sellable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"consumerProductReviewLMSummary"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConsumerProductReviewLMSummaryForConsumerProductReviewSummary"}}]}},{"kind":"Field","alias":{"kind":"Name","value":"facetConnection"},"name":{"kind":"Name","value":"consumerProductReviewConnection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"query"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"sourceTypes"},"value":{"kind":"ListValue","values":[]}},{"kind":"ObjectField","name":{"kind":"Name","value":"ratings"},"value":{"kind":"ListValue","values":[]}}]}},{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"IntValue","value":"0"}},{"kind":"Argument","name":{"kind":"Name","value":"sortBy"},"value":{"kind":"EnumValue","value":"REVIEWED_AT"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"facets"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"DisplayFacet"}}]}}]}}]}}]} as unknown as DocumentNode;