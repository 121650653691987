import { StartAiGuestConversationVersion , StartExpertConsumerConversationSourcePageInput } from '#src/generated/types'
import { useCurrentExpert } from '#src/app/context/expert'
import { useChatWidgetStateContext } from '#src/app/context/chat-widget-state'
import { useIdentityContext } from '#src/app/containers/Identity'
import { useStartAiChatForConversationStartersV2Mutation } from './ConversationStartersV2.generated'

export interface UseConversationStartersV2Props {
  consumerMessageAbsoluteUrl: string
  sourcePage: StartExpertConsumerConversationSourcePageInput
  onSubmit?: () => void
}

export const useConversationStartersV2 = ({
  consumerMessageAbsoluteUrl,
  sourcePage,
  onSubmit
}: UseConversationStartersV2Props) => {
  const { refetch } = useIdentityContext()
  const { setExpert } = useCurrentExpert()
  const { isChatWidgetMinimized, expandChatWidget } = useChatWidgetStateContext()
  const [startAiChat, { loading }] = useStartAiChatForConversationStartersV2Mutation({
    onCompleted: data => {
      if (!data?.startAiGuestConversation.error && data?.startAiGuestConversation.myself) {
        refetch?.()
        if (data?.startAiGuestConversation?.lead?.expert) {
          setExpert(data?.startAiGuestConversation?.lead?.expert)
        }
        if (isChatWidgetMinimized) {
          expandChatWidget('ai-guest-chat-start')
        }
      }
    }
  })

  const sendAction = (message: string, onCompleted?: () => void) => {
    onSubmit?.()
    startAiChat({
      variables: {
        input: {
          sourceFlow: {
            aiGuestChatFlow: {
              consumerMessage: consumerMessageAbsoluteUrl + '\n' + message
            }
          },
          sourcePage: sourcePage,
          version: StartAiGuestConversationVersion.EXPERT_AI_V2
        }
      },
      onCompleted: () => {
        onCompleted?.()
      }
    })
  }
  return { sendAction, loading }
}
