import React, { ReactElement } from 'react'
import { Modal } from '@deal/components'
import { SDK_ERROR_VALUES } from '../..'
import styles from './styles.css'

interface ErrorModalProps {
  error: typeof SDK_ERROR_VALUES[keyof typeof SDK_ERROR_VALUES]
  isOpen: boolean
  onCloseRequested: () => void
}

const ErrorModal: React.FC<ErrorModalProps> = ({ error, isOpen, onCloseRequested }) => {
  let content: ReactElement | string = 'An unknown error occured'
  let subcontent =
    'Please refresh the page to try again. ' +
    'If you are still unable to resolve your issue, ' +
    "reach out to tech support, and we'll help you get rolling in no time!"
  const FAQLink = (
    <a
      className={styles.url}
      href="https://support.loom.com/hc/en-us/articles/360020667617-loomSDK-FAQ"
      target="_blank"
      rel="noreferrer"
    >
      FAQ
    </a>
  )
  switch (error) {
    case SDK_ERROR_VALUES.IncompatibleBrowser:
      content = (
        <>Your browser is incompatible. Checkout the {FAQLink} for a list of compatible browsers.</>
      )
      break

    case SDK_ERROR_VALUES.NoMediaStreamsSupport:
      content = (
        <>No media streams support. Checkout the {FAQLink} for a list of compatible browsers.</>
      )
      break

    case SDK_ERROR_VALUES.ThirdPartyCookiesDisabled:
      content =
        'Third party cookies are disabled. ' +
        'Please adjust your browser settings and disable any ad-block or pop-up blockers you may have installed.'
      break

    case SDK_ERROR_VALUES.Unauthorized:
      content = 'You are not authorized to perform this action.'
      subcontent = "Please reach out to tech support, and we'll help you get rolling in no time!"
      break

    case SDK_ERROR_VALUES.LoomFailure:
      content = 'Failed to connect to Loom.'
      break

    case SDK_ERROR_VALUES.ImplementationError:
      content = 'Failed to link button to recorder functionality.'
      subcontent = 'This is an implementation error. Please reach out to tech support.'
      break
  }
  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Video Recorder"
      primaryAction={{
        label: 'Done',
        onClick: () => onCloseRequested()
      }}
      hideCloseButton
    >
      <p className={styles.errorTitle}>
        Looks like we're having trouble loading the video recorders
      </p>
      <p className={styles.errorSubtitle}>{content}</p>
      <p className={styles.errorSubtitle}>{subcontent}</p>
    </Modal>
  )
}

export default ErrorModal
