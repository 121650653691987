import React from 'react'
import { TextField } from '@deal/components'

interface EmailOrPhoneTextFieldProps {
  name: string
  value: string | undefined
  errorText?: string
  loading?: boolean
  disabled?: boolean
  onChange: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  testId?: string
  className?: string
  placeholder?: string
}

export const EmailOrPhoneTextField: React.FC<EmailOrPhoneTextFieldProps> = ({
  name,
  value,
  errorText,
  loading,
  disabled,
  onChange,
  onBlur,
  testId,
  className,
  placeholder
}) => {
  return (
    <TextField
      name={name}
      label=""
      labelHidden
      placeholder={placeholder}
      className={className}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      errorText={errorText}
      loading={loading}
      disabled={disabled || loading}
      inputProps={{
        autoComplete: 'tel-national'
      }}
      testId={testId}
    />
  )
}
