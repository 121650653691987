import React from 'react'
import { Image } from '@deal/components'
import { useAuthenticationSessionStateQuery } from '../../../AuthenticationSessionState.generated'
import { AuthenticatedUserFragment } from '../../../../../fragments/AuthenticatedUser.generated'
import formatHandle from '../../../util/formatHandle'
import { AuthIntent, AuthenticationFlowViewLayoutProps } from '../../../index'
import { SixDigitCodeForm } from '../../../components/SixDigitCodeForm/rebranded'
import AuthenticationFlowViewLayout from '../../../components/AuthenticationFlowViewLayout/rebranded'
import InboundCode from './inbound-code.png'

interface LoginChallengeOneTimePasswordProps extends AuthenticationFlowViewLayoutProps {
  onLogin: (user: AuthenticatedUserFragment) => void
  onIntentChanged: (intent: AuthIntent) => void
}

const LoginChallengeOneTimePassword: React.FC<
  React.PropsWithChildren<LoginChallengeOneTimePasswordProps>
> = ({ onLogin, onIntentChanged, standalone }) => {
  const authenticationSession = useAuthenticationSessionStateQuery()

  const authenticationSessionState =
    authenticationSession.data && authenticationSession.data.session

  // If the authentication session has expired, send the user back to the LoginView
  if (!authenticationSessionState?.authenticationState) {
    onIntentChanged(AuthIntent.Login)
    return null
  }

  return (
    <AuthenticationFlowViewLayout
      headline="Enter your code"
      subHeadline={
        authenticationSessionState.authenticationState.handle ? (
          <>
            We sent a 6-digit code to{' '}
            <strong>{formatHandle(authenticationSessionState.authenticationState.handle)}</strong>
          </>
        ) : (
          'We sent you a 6-digit code.'
        )
      }
      footer={<Image src={InboundCode} sizes="600px" />}
      standalone={standalone}
    >
      <SixDigitCodeForm authenticationSessionState={authenticationSessionState} onLogin={onLogin} />
    </AuthenticationFlowViewLayout>
  )
}

export { LoginChallengeOneTimePassword }
