// @ts-nocheck
import type * as Types from '../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { SellableEditorialTopListQuestionForQuestionAndAnswersFragmentDoc, SellableEditorialTopListRevisionForQuestionAndAnswersFragmentDoc } from './components/QuestionAndAnswers/QuestionAndAnswers.generated';
import { BusinessUserForShowExpertQuestionModalButtonFragmentDoc, SellableEditorialTopListRevisionForShowExpertQuestionModalButtonFragmentDoc } from '../ShowExpertQuestionModalButton/ShowExpertQuestionModalButton.generated';
import { BusinessUserForExpertQuestionModalFragmentDoc } from '../../../../../components/ExpertQuestionModal/ExpertQuestionModal.generated';
import { ExpertProfileHighlightsFragmentDoc } from '../../../../../fragments/ExpertProfileHighlights.generated';
import { MediaFileFragmentDoc } from '../../../../../fragments/MediaFile.generated';
import { ExpertForExpertsFacepileFragmentDoc } from '../../../../../components/ExpertsFacepile/ExpertsFacepile.generated';
import { BusinessUserForExpertProfileLinkFragmentDoc } from '../../../../../components/ExpertProfileLink/ExpertProfileLink.generated';
import { ExpertForExpertAvatarLegacyFragmentDoc } from '../../../../../components/ExpertAvatarLegacy/ExpertAvatarLegacy.generated';
import { ExpertForProductQuestionWrapperFragmentDoc } from '../../../../../components/ProductQuestionWrapper/ProductQuestionWrapper.generated';
export type SellableEditorialTopListRevisionForQuestionsToAskFragment = { __typename: 'SellableEditorialTopListRevision', id: any, questionsHeader: string, sellableEditorialTopListId: any, questions: Array<{ __typename: 'SellableEditorialTopListQuestion', id: any, questionText: string, answers: Array<{ __typename: 'SellableEditorialTopListAnswer', answerText: string, author: { __typename: 'BusinessUser', id: any, displayName: string, active: boolean, vanityId?: string | null, state: Types.BusinessUserState, shortName: string, expertStats?: { __typename: 'ExpertStats', totalLeadsAsPrimaryExpert: number, averageRating?: number | null, totalReviews: number, totalMessagesSent: number } | null, expertProfile?: { __typename: 'ExpertProfile', id: any, headline: string, gender?: Types.ExpertGender | null, location?: string | null, backgroundRichText?: string | null, background: string, profilePhoto?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null, highlights: Array<{ __typename: 'ProfileHighlight', value: string, secondaryValue: string, type: { __typename: 'ProfileHighlightType', id: any, icon: Types.ProfileHighlightIconType, displayName: string } }> } | null, department: { __typename: 'Department', id: any, slug: string } } }> }>, categories: Array<{ __typename: 'Category', id: any, recommendExpertForConsumer: { __typename: 'RecommendExpertPayload', expert?: { __typename: 'BusinessUser', id: any } | null } }> };

export const SellableEditorialTopListRevisionForQuestionsToAskFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableEditorialTopListRevisionForQuestionsToAsk"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SellableEditorialTopListRevision"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"questionsHeader"}},{"kind":"Field","name":{"kind":"Name","value":"questions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableEditorialTopListQuestionForQuestionAndAnswers"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableEditorialTopListRevisionForQuestionAndAnswers"}}]}}]} as unknown as DocumentNode;