import React from 'react'
import { Grid, GridItem } from '@deal/components'

interface PageContentProps {
  gridClassName?: string
  gridWrapperClassName?: string
  margins?: boolean
  className?: string
  onChildrenClick?: () => void
}

/**
 * @deprecated Use <Grid> and <Area> from Bluxome instead
 */
const PageContent: React.FC<React.PropsWithChildren<PageContentProps>> = ({
  gridClassName,
  gridWrapperClassName,
  margins = true,
  className,
  children,
  onChildrenClick
}) => {
  return (
    <Grid className={gridClassName} wrapperClassName={gridWrapperClassName} margins={margins}>
      <GridItem col={2} className={className} onClick={onChildrenClick}>
        {children}
      </GridItem>
    </Grid>
  )
}

export default PageContent
