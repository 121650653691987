import React, { useContext } from 'react'
import { Request, Response } from 'express'
import config from '#src/app/config'
import createServerContext from './createServerContext'
import createBrowserContext from './createBrowserContext'

/**
 * The CookieProvider exposes browser cookies (for client-side renders) and request cookies (for SSR) via a unified API.
 */
export type CookieGetter = (cookieName: string) => string | undefined

export type CookieSetter = (
  cookieName: string,
  cookieValue: string | object,
  cookieOptions?: CookieOptions
) => boolean

export type CookieRemover = (cookieName: string, cookieOptions?: CookieOptions) => void

export type CookieContextType = {
  getCookie: CookieGetter
  setCookie: CookieSetter
  removeCookie: CookieRemover
}

export type CookieOptions = {
  expires?: number | Date
  path?: string
  domain?: string
  secure?: boolean
  sameSite?: 'strict' | 'lax' | 'none'
  httpOnly?: boolean
}

export type CookieEnvironment = Window | { req: Request; res: Response }

export const DEFAULT_COOKIE_OPTIONS: Partial<CookieOptions> = {
  domain: config.get('consumer.clientSideCookieDomain'),
  sameSite: 'lax',
  secure: true
}

// Create the underlying provider and consumer
export const CookieContext = React.createContext<CookieContextType>({
  getCookie: () => {
    throw new Error('Can not get a cookie before cookie provider is initialized')
  },
  setCookie: () => {
    throw new Error('Can not set a cookie before cookie provider is initialized')
  },
  removeCookie: () => {
    throw new Error('Can not remove a cookie before cookie provider is initialized')
  }
})

const { Consumer, Provider } = CookieContext

// A wrapper provider acts as the state container and manager
interface CookieProviderProps {
  environment: CookieEnvironment
}

interface CookieProviderState {
  context: CookieContextType
}

class CookieProvider extends React.Component<
  React.PropsWithChildren<CookieProviderProps>,
  CookieProviderState
> {
  public constructor(props: CookieProviderProps) {
    super(props)

    this.state = {
      context: this.createContext(this.props.environment)
    }
  }

  public componentDidUpdate(prevProps: CookieProviderProps) {
    if (prevProps.environment !== this.props.environment) {
      this.context = this.createContext(this.props.environment)
    }
  }

  public createContext(environment: CookieEnvironment): CookieContextType {
    if (environment && 'req' in environment) {
      return createServerContext(environment)
    } else {
      return createBrowserContext(environment)
    }
  }

  public render() {
    return <Provider value={this.state.context}>{this.props.children}</Provider>
  }
}

const useCookieContext = () => {
  const cookieContext = useContext(CookieContext)

  if (!cookieContext) {
    throw new Error('Invoked CookieContext outside of provider')
  }

  return cookieContext
}

// Export with clean names
export { CookieProvider, Consumer as CookieConsumer, useCookieContext }
