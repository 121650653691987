// @ts-nocheck
import type * as Types from '../../../../../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { SellableForSellableLinkFragmentDoc } from '../../../../../../../../../components/SellableLink/SellableLink.generated';
import { SellableForGetSellableLinkRelFragmentDoc, SellableForIsSellablePurchasableFragmentDoc } from '../../../../../../../../../services/sellable/Sellable.generated';
import { SellableUrlFragmentDoc } from '../../../../../../../../../fragments/SellableUrl.generated';
export type SellableForItemSellableLinkFragment = { __typename: 'Sellable', id: any, active: boolean, adHoc: boolean, friendlyId?: string | null, slug: string, type: Types.SellableType };

export type SellableEditorialTopListRevisionForItemSellableLinkFragment = { __typename: 'SellableEditorialTopListRevision', id: any };

export const SellableForItemSellableLinkFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableForItemSellableLink"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Sellable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForSellableLink"}}]}}]} as unknown as DocumentNode;
export const SellableEditorialTopListRevisionForItemSellableLinkFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableEditorialTopListRevisionForItemSellableLink"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SellableEditorialTopListRevision"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]} as unknown as DocumentNode;