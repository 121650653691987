import { useExperiment } from '@deal/experiment-js'
import { useIdentityContext } from '#src/app/containers/Identity'
import { CategoryForUseFeedHomePageEligibilityCheckFragment } from './useFeedHomePageEligibilityCheck.generated'

export default function useFeedHomePageEligibilityCheck(
  category?: CategoryForUseFeedHomePageEligibilityCheckFragment
) {
  const { myself } = useIdentityContext()
  const mostRecentRequestPost = myself?.user.mostRecentFeedRequestPost
  const { result } = useExperiment({
    experiment: 'consumer-feed-pcp',
    defaultTreatment: 'control',
    options: {
      skip:
        !mostRecentRequestPost ||
        !!myself.user.leads.length ||
        (category && category.slug !== 'golf')
    }
  })
  return result === 'on'
}
