import React, { useContext } from 'react'
import { ExperimentClient, ExperimentClientProvider } from '@deal/experiment-js'
import { useUserAgentContext } from '#src/app/containers/UserAgent'
import { IdentityContext } from '#src/app/containers/Identity'
import { useSystemUserQuery } from './SystemUser.generated'

export interface UserExperimentClientProviderProps {
  experimentClient: ExperimentClient
  overrideId?: string
  forceExperimentDefaults?: boolean
}

const UserExperimentClientProvider: React.FC<
  React.PropsWithChildren<UserExperimentClientProviderProps>
> = ({ overrideId, experimentClient, children }) => {
  const { myself, experimentUserId, userExperimentAttributes } = useContext(IdentityContext)
  const { isBot } = useUserAgentContext()
  const { data } = useSystemUserQuery({
    skip: !isBot
  })
  const isImpersonating = myself && myself.user.id !== myself.realUser.id

  if (isBot && !data) {
    return null
  }

  return (
    <ExperimentClientProvider
      experimentClient={experimentClient}
      id={overrideId || (isBot ? data!.systemUser.id : experimentUserId) || ''}
      attributes={userExperimentAttributes}
      options={{
        // Do not track experiment impressions when impersonating
        trackImpression: !isImpersonating
      }}
    >
      {children}
    </ExperimentClientProvider>
  )
}

export default UserExperimentClientProvider
