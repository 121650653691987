import React from 'react'
import loadable from '@loadable/component'
import Page from '#src/app/components/Page'
import { useManageWishlistsRouteQuery } from './ManageWishlistsRoute.generated'
import WishlistLoader from '../components/SkeletonLoader'

const PageComponent = loadable(() => import(/* webpackChunkName: "manage-wishlist" */ './page'), {
  resolveComponent: components => components.ManageWishlistsRouteParser
})

interface ManageWishlistsRouteProps {
  id: string
}

const ManageWishlistsRoute: React.FC<ManageWishlistsRouteProps> = ({ id }) => {
  const query = useManageWishlistsRouteQuery({
    variables: {
      wishlistId: id
    },
    fetchPolicy: 'cache-and-network'
  })

  return (
    <Page
      query={query}
      pageKey="manage-wishlist"
      chat={true}
      seoTitle={undefined}
      seoDescription={undefined}
      seoIndexable={true}
      expert={data => {
        const babyLead = data.me?.user.leads.find(
          lead => lead.department.topLevelCategory.slug === 'baby-toddler'
        )

        return babyLead?.expert
      }}
      category={undefined}
      department={data => {
        const babyLead = data.me?.user.leads.find(
          lead => lead.department.topLevelCategory.slug === 'baby-toddler'
        )

        return babyLead?.department
      }}
      sellable={() => undefined}
      pageComponent={PageComponent}
      loadingComponent={WishlistLoader}
      ogImageUrl={undefined}
      canonicalPath={undefined}
    />
  )
}

export default ManageWishlistsRoute
