import React, { useContext } from 'react'

type PathCtaContextType = {
  promotionId?: string
  pathSchemaId?: string
  csrKeyword?: string
  pageVariation?: string
}

const PathCtaContext = React.createContext<PathCtaContextType | undefined>(undefined)
const PathCtaContextProvider = PathCtaContext.Provider

const usePathCtaContext = () => useContext(PathCtaContext)

export { PathCtaContextProvider, usePathCtaContext }
