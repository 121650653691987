import { useLocation } from '@deal/router'
import { CategoryForCategoryOrProductListingPageFragment } from '../services/search/CategoryForCategoryOrProductListingPage.generated'
import {
  SerializableProductListingPageQuery,
  buildProductListingPageUrl
} from '../services/search/buildProductListingPageUrl'
import { buildCategoryPageUrl } from '../services/search/buildCategoryPageUrl'
import { useUserAgentContext } from '../containers/UserAgent'
import { CategoryPageLayoutType } from '../../generated/types'

/**
 * Use this if you have category only
 * This will return Category /shop or PLP /c based on the category.pageLayoutType
 */
const useCategoryOrProductListingLocation = () => {
  // If we can't determine whether the user is a bot, assume they are. This flag is used to add
  //   tracking parameters to the destination URL, which causes duplicate URLs for crawlers. It's
  //   better to be conservative and miss some tracking info than to create crawl issues.
  const { isBot = true } = useUserAgentContext()
  const { search } = useLocation()

  return (
    category: CategoryForCategoryOrProductListingPageFragment,
    entryPoint?: string,
    query?: SerializableProductListingPageQuery
  ) => {
    if (category.pageLayoutType === CategoryPageLayoutType.FACETED_CATEGORY_LAYOUT) {
      return buildProductListingPageUrl(
        { ...query, categorySlug: category.slug, entryPoint },
        new URLSearchParams(search),
        isBot
      )
    }

    return buildCategoryPageUrl(
      { categorySlug: category.slug, entryPoint },
      new URLSearchParams(search),
      isBot
    )
  }
}

export { useCategoryOrProductListingLocation }
