// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { ConsumerProductReviewTargetForProductReviewsPageFragmentDoc } from './page/ProductReviewsPage.generated';
import { ConsumerProductReviewTargetForProductReviewItemsListFragmentDoc, ConsumerProductReviewForProductReviewItemsListFragmentDoc } from './page/components/ProductReviewItemsList/ProductReviewItemsList.generated';
import { SellableForProductReviewItemFragmentDoc, OrderLineItemForProductReviewItemFragmentDoc } from './page/components/ProductReviewItemsList/ProductReviewItem/ProductReviewItem.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ProductReviewsRouteQueryVariables = Types.Exact<{
  input: Types.ConsumerProductReviewTargetsInput;
}>;


export type ProductReviewsRouteQuery = { __typename: 'Query', consumerProductReviewTargets: { __typename: 'ConsumerProductReviewTargetsPayload', targets: Array<{ __typename: 'ConsumerProductReviewTarget', suggestedAuthorName: string, suggestedAuthorLocation: string, purchasedSellable: { __typename: 'Sellable', id: any, title: string, primaryImage?: { __typename: 'MediaFile', id: any, url: string } | null }, orderLineItem: { __typename: 'OrderLineItem', id: any, order: { __typename: 'Order', id: any, paidAt?: any | null, sortTimestamp: any } } }>, selectedTarget?: { __typename: 'ConsumerProductReviewTarget', suggestedAuthorName: string, suggestedAuthorLocation: string, purchasedSellable: { __typename: 'Sellable', id: any, title: string, primaryImage?: { __typename: 'MediaFile', id: any, url: string } | null }, orderLineItem: { __typename: 'OrderLineItem', id: any, order: { __typename: 'Order', id: any, paidAt?: any | null, sortTimestamp: any } } } | null } };


export const ProductReviewsRouteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ProductReviewsRoute"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ConsumerProductReviewTargetsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"consumerProductReviewTargets"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"targets"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConsumerProductReviewTargetForProductReviewsPage"}}]}},{"kind":"Field","name":{"kind":"Name","value":"selectedTarget"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConsumerProductReviewTargetForProductReviewsPage"}}]}}]}}]}},...ConsumerProductReviewTargetForProductReviewsPageFragmentDoc.definitions,...ConsumerProductReviewTargetForProductReviewItemsListFragmentDoc.definitions,...SellableForProductReviewItemFragmentDoc.definitions,...OrderLineItemForProductReviewItemFragmentDoc.definitions]} as unknown as DocumentNode;
export type ProductReviewsRouteComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProductReviewsRouteQuery, ProductReviewsRouteQueryVariables>, 'query'> & ({ variables: ProductReviewsRouteQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProductReviewsRouteComponent = (props: ProductReviewsRouteComponentProps) => (
      <ApolloReactComponents.Query<ProductReviewsRouteQuery, ProductReviewsRouteQueryVariables> query={ProductReviewsRouteDocument} {...props} />
    );
    

/**
 * __useProductReviewsRouteQuery__
 *
 * To run a query within a React component, call `useProductReviewsRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductReviewsRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductReviewsRouteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductReviewsRouteQuery(baseOptions: Apollo.QueryHookOptions<ProductReviewsRouteQuery, ProductReviewsRouteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductReviewsRouteQuery, ProductReviewsRouteQueryVariables>(ProductReviewsRouteDocument, options);
      }
export function useProductReviewsRouteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductReviewsRouteQuery, ProductReviewsRouteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductReviewsRouteQuery, ProductReviewsRouteQueryVariables>(ProductReviewsRouteDocument, options);
        }
export function useProductReviewsRouteSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProductReviewsRouteQuery, ProductReviewsRouteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductReviewsRouteQuery, ProductReviewsRouteQueryVariables>(ProductReviewsRouteDocument, options);
        }
export type ProductReviewsRouteQueryHookResult = ReturnType<typeof useProductReviewsRouteQuery>;
export type ProductReviewsRouteLazyQueryHookResult = ReturnType<typeof useProductReviewsRouteLazyQuery>;
export type ProductReviewsRouteSuspenseQueryHookResult = ReturnType<typeof useProductReviewsRouteSuspenseQuery>;
export type ProductReviewsRouteQueryResult = Apollo.QueryResult<ProductReviewsRouteQuery, ProductReviewsRouteQueryVariables>;