import React, { useEffect, useState } from 'react'
import { useLocation } from '@deal/router'
import { LeadForLoomModalFragment } from '#src/app/context/loom/LoomModal.generated'
import { useLoomContext } from '#src/app/context/loom'
import { useIdentityContext } from '#src/app/containers/Identity'
import RecordingModal from './components/RecordingModal'
import ErrorModal from './components/ErrorModal'
import CopyLinkModal from './components/CopyLinkModal'

interface LoomModalProps {
  lead: LeadForLoomModalFragment
  isPreview: boolean
}

export enum ModalType {
  RECORDING,
  COPY_LINK,
  ERROR
}

export const SDK_ERROR_VALUES = {
  IncompatibleBrowser: 'incompatible-browser',
  ThirdPartyCookiesDisabled: 'third-party-cookies-disabled',
  NoMediaStreamsSupport: 'no-media-streams-support',
  Unauthorized: 'unauthorized',
  LoomFailure: 'loom-failure',
  ImplementationError: 'implementation-error',
  Unknown: 'unknown',
  SDKNotAvailable: 'sdk-not-available'
} as const

export type SDKError = typeof SDK_ERROR_VALUES[keyof typeof SDK_ERROR_VALUES]

const RecordingManagementModal: React.FC<LoomModalProps> = ({ lead, isPreview }) => {
  const { search } = useLocation()
  const { myself } = useIdentityContext()
  const urlParams = new URLSearchParams(search)
  const shouldRecord = urlParams.get('record')

  const [modal, setModal] = useState<ModalType | null>(null)
  const [loomError, setLoomError] = useState<SDKError | null>(null)

  const { videoUrl, status } = useLoomContext()

  /**
   * On mount, open the recording modal if:
   *  - The expert is not actively recording and navigating back to the page
   */

  useEffect(() => {
    if (myself?.businessUser && shouldRecord && status !== 'active-recording') {
      setModal(ModalType.RECORDING)
    }
  }, [])

  useEffect(() => {
    const shouldShowErrorModal = !!loomError
    if (shouldShowErrorModal) {
      setModal(ModalType.ERROR)
    }
  }, [loomError])

  const errorToShow = loomError

  return (
    <>
      {videoUrl && (
        <CopyLinkModal
          isOpen={modal === ModalType.COPY_LINK}
          onCloseRequested={() => setModal(null)}
          videoUrl={videoUrl}
        />
      )}

      {errorToShow && (
        <ErrorModal
          isOpen={modal === ModalType.ERROR}
          error={errorToShow}
          onCloseRequested={() => setModal(null)}
        />
      )}

      <RecordingModal
        isOpen={modal === ModalType.RECORDING}
        isPreview={isPreview}
        lead={lead}
        onCloseRequested={() => setModal(null)}
        setModal={modal => setModal(modal)}
        loomError={loomError}
        onLoomError={error => setLoomError(error)}
      />
    </>
  )
}

export default RecordingManagementModal
