// @ts-nocheck
import type * as Types from '../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { MediaFileFragmentDoc } from '../../../../../fragments/MediaFile.generated';
import { SellableFormattedVariantFragmentDoc } from '../../../../../fragments/SellableFormatterVariant.generated';
import { SellableForCartAskForAlternativeButtonFragmentDoc } from './components/AskForAlternativeButton/AskForAlternativeButton.generated';
import { SellableForSellableStockAlertButtonLegacyFragmentDoc } from '../../../../SellableStockAlertButtonLegacy/SellableStockAlertButton.generated';
import { SellableForSellableStockAlertButtonBaseFragmentDoc } from '../../../../SellableStockAlertButtonBaseLegacy/SellableStockAlertButtonBase.generated';
import { SellableStockAlertForSellableStockAlertFragmentDoc } from '../../../../../context/sellable-stock-alert/SellableStockAlert.generated';
import { SellableUrlFragmentDoc } from '../../../../../fragments/SellableUrl.generated';
export type SellableForCartOutOfStockFragment = { __typename: 'Sellable', id: any, title: string, friendlyId?: string | null, slug: string, type: Types.SellableType, adHoc: boolean, bundle: boolean, primaryImage?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null, variants: Array<{ __typename: 'SellableFormattedVariant', name: string, displayName?: string | null, value: string, displayValueHtml: string }>, categories: Array<{ __typename: 'Category', id: any }>, sellableStockAlert?: { __typename: 'SellableStockAlert', id: any } | null };

export type OrderUserMessageForCartOutOfStock_AdHocSellableNotAvailableOrderUserMessage_Fragment = { __typename: 'AdHocSellableNotAvailableOrderUserMessage', id: any, message: string, sellable: { __typename: 'Sellable', id: any, title: string, friendlyId?: string | null, slug: string, type: Types.SellableType, adHoc: boolean, bundle: boolean, primaryImage?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null, variants: Array<{ __typename: 'SellableFormattedVariant', name: string, displayName?: string | null, value: string, displayValueHtml: string }>, categories: Array<{ __typename: 'Category', id: any }>, sellableStockAlert?: { __typename: 'SellableStockAlert', id: any } | null } };

export type OrderUserMessageForCartOutOfStock_AddonSellableQuantityOrderUserMessage_Fragment = { __typename: 'AddonSellableQuantityOrderUserMessage', id: any, message: string };

export type OrderUserMessageForCartOutOfStock_DefaultOrderUserMessage_Fragment = { __typename: 'DefaultOrderUserMessage', id: any, message: string };

export type OrderUserMessageForCartOutOfStock_SellableCustomizationNotAvailableOrderUserMessage_Fragment = { __typename: 'SellableCustomizationNotAvailableOrderUserMessage', id: any, message: string };

export type OrderUserMessageForCartOutOfStock_SellableLimitedQuantityOrderUserMessage_Fragment = { __typename: 'SellableLimitedQuantityOrderUserMessage', id: any, message: string };

export type OrderUserMessageForCartOutOfStock_SellableNotAvailableOrderUserMessage_Fragment = { __typename: 'SellableNotAvailableOrderUserMessage', id: any, message: string, sellable: { __typename: 'Sellable', id: any, title: string, friendlyId?: string | null, slug: string, type: Types.SellableType, adHoc: boolean, bundle: boolean, primaryImage?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null, variants: Array<{ __typename: 'SellableFormattedVariant', name: string, displayName?: string | null, value: string, displayValueHtml: string }>, categories: Array<{ __typename: 'Category', id: any }>, sellableStockAlert?: { __typename: 'SellableStockAlert', id: any } | null } };

export type OrderUserMessageForCartOutOfStock_WishlistArchivedGiftCardOrderUserMessage_Fragment = { __typename: 'WishlistArchivedGiftCardOrderUserMessage', id: any, message: string };

export type OrderUserMessageForCartOutOfStock_WishlistArchivedSellableOrderUserMessage_Fragment = { __typename: 'WishlistArchivedSellableOrderUserMessage', id: any, message: string };

export type OrderUserMessageForCartOutOfStock_WishlistItemArchivedGiftCardOrderUserMessage_Fragment = { __typename: 'WishlistItemArchivedGiftCardOrderUserMessage', id: any, message: string };

export type OrderUserMessageForCartOutOfStock_WishlistItemArchivedSellableOrderUserMessage_Fragment = { __typename: 'WishlistItemArchivedSellableOrderUserMessage', id: any, message: string };

export type OrderUserMessageForCartOutOfStock_WishlistItemDeletedDuePurchasedSellableOrderUserMessage_Fragment = { __typename: 'WishlistItemDeletedDuePurchasedSellableOrderUserMessage', id: any, message: string };

export type OrderUserMessageForCartOutOfStock_WishlistItemQuantityChangedDuePurchasedSellableOrderUserMessage_Fragment = { __typename: 'WishlistItemQuantityChangedDuePurchasedSellableOrderUserMessage', id: any, message: string };

export type OrderUserMessageForCartOutOfStock_WishlistPrivateGiftCardOrderUserMessage_Fragment = { __typename: 'WishlistPrivateGiftCardOrderUserMessage', id: any, message: string };

export type OrderUserMessageForCartOutOfStock_WishlistPrivateSellableOrderUserMessage_Fragment = { __typename: 'WishlistPrivateSellableOrderUserMessage', id: any, message: string };

export type OrderUserMessageForCartOutOfStockFragment = OrderUserMessageForCartOutOfStock_AdHocSellableNotAvailableOrderUserMessage_Fragment | OrderUserMessageForCartOutOfStock_AddonSellableQuantityOrderUserMessage_Fragment | OrderUserMessageForCartOutOfStock_DefaultOrderUserMessage_Fragment | OrderUserMessageForCartOutOfStock_SellableCustomizationNotAvailableOrderUserMessage_Fragment | OrderUserMessageForCartOutOfStock_SellableLimitedQuantityOrderUserMessage_Fragment | OrderUserMessageForCartOutOfStock_SellableNotAvailableOrderUserMessage_Fragment | OrderUserMessageForCartOutOfStock_WishlistArchivedGiftCardOrderUserMessage_Fragment | OrderUserMessageForCartOutOfStock_WishlistArchivedSellableOrderUserMessage_Fragment | OrderUserMessageForCartOutOfStock_WishlistItemArchivedGiftCardOrderUserMessage_Fragment | OrderUserMessageForCartOutOfStock_WishlistItemArchivedSellableOrderUserMessage_Fragment | OrderUserMessageForCartOutOfStock_WishlistItemDeletedDuePurchasedSellableOrderUserMessage_Fragment | OrderUserMessageForCartOutOfStock_WishlistItemQuantityChangedDuePurchasedSellableOrderUserMessage_Fragment | OrderUserMessageForCartOutOfStock_WishlistPrivateGiftCardOrderUserMessage_Fragment | OrderUserMessageForCartOutOfStock_WishlistPrivateSellableOrderUserMessage_Fragment;

export const SellableForCartOutOfStockFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableForCartOutOfStock"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Sellable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"primaryImage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaFile"}}]}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"variants"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableFormattedVariant"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForCartAskForAlternativeButton"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForSellableStockAlertButtonLegacy"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableUrl"}}]}}]} as unknown as DocumentNode;
export const OrderUserMessageForCartOutOfStockFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"OrderUserMessageForCartOutOfStock"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"OrderUserMessage"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AdHocSellableNotAvailableOrderUserMessage"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"sellable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForCartOutOfStock"}}]}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SellableNotAvailableOrderUserMessage"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"sellable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForCartOutOfStock"}}]}}]}}]}}]} as unknown as DocumentNode;