import React, { useEffect, useRef } from 'react'
import { createPath } from 'history'
import { PageKeyPriority, Redirect, Route, Switch, useLocation } from '@deal/router'
import { WishlistsRouteParser } from '#src/app/routes/wishlists'
import { TopListsRouteParser } from '#src/app/routes/top-lists'
import { CategoryRoute } from '#src/app/routes/shop'
import { SearchRoute } from '#src/app/routes/search'
import { SaleRoute } from '#src/app/routes/sale'
import { ProductsRouteParser } from '#src/app/routes/products'
import InboxRoute from '#src/app/routes/inbox'
import { ExpertProfileContainer } from '#src/app/routes/experts'
import { ExpertProductReviewsPageContainer } from '#src/app/routes/expert-product-reviews'
import { DepartmentsRouteParser } from '#src/app/routes/departments'
import { ProductListingRoute } from '#src/app/routes/c'
import { StripeContextProvider } from '#src/app/context/stripe'
import { NotFound } from '#src/app/components/Errors'
import EngagementModalPreview from '#src/app/components/EngagementChannels/EngagementModal/EngagementModalPreview'
import {
  usePreviousLocationAtom,
  useUpdatePreviousLocationAtom
} from '../atoms/previousLocationAtom'
import { UnavailableCategoryRoute } from './unavailable-category'
import * as Routes from './routes'
import { ProductReviewsRouteSwitch } from './product-reviews'
import { PLARouteParser } from './pla'
import { ForYouRoute } from './for-you'
import { Curations } from './curations'
import { CompareRoute } from './compare'
import { ClearanceRoute } from './clearance'
import { BFCMRoute } from './bfcm'

const Router: React.FC<React.PropsWithChildren<unknown>> = () => {
  useUpdatePreviousLocationAtom()

  const location = useLocation()
  const previousLocationRef = useRef<string>()
  const [, setPreviousLocation] = usePreviousLocationAtom()
  useEffect(() => {
    setPreviousLocation(previousLocationRef.current)
    previousLocationRef.current = createPath(location)
  }, [location])

  return (
    <Switch>
      {/* Redirects */}
      <Route path="/r" component={Routes.Redirects} />
      {/**
       * The authentication route requires special treament:
       *
       *   1) We don't want to initiate subscriptions that require authenticated state while
       *      logging out, or we'll hit race conditions between clearing authentication cookies
       *      and re-setting the subscription to no longer use the authenticated state (e.g.
       *      the cart subscription)
       *
       *   2) The route should always be accessible, and not subject to the Deal.com => Curated.com
       *      redirect and splash screen.
       */}
      <Route
        path="/auth"
        pageKey="auth"
        priority={PageKeyPriority.TOP_LEVEL}
        component={Routes.Auth}
      />
      <Route
        render={() => (
          <Switch>
            {/* Home page */}
            <Route
              path="/"
              pageKey="home"
              priority={PageKeyPriority.TOP_LEVEL}
              component={Routes.Home}
              exact
            />
            <Route
              path={['/lp', '/landing', '/lp2', '/lp3']}
              pageKey="landing"
              priority={PageKeyPriority.TOP_LEVEL}
              component={Routes.Landing}
            />
            {/**
             * This is only for redirect purpose. we used to have /orders route for both checkout and order list/details.
             * The checkout is now at /checkout and order list/details is at /account/orders.
             * Keep this route for redirect purpose only.
             */}
            <Route
              path="/orders"
              pageKey="orders"
              priority={PageKeyPriority.TOP_LEVEL}
              component={Routes.Orders}
            />
            <Route
              path="/gift-cards"
              pageKey="gift-cards"
              priority={PageKeyPriority.TOP_LEVEL}
              component={Routes.GiftCards}
            />
            <Route
              path="/claim-promotion"
              pageKey="claim-promotion"
              priority={PageKeyPriority.TOP_LEVEL}
              component={Routes.ClaimPromotion}
            />
            <Route path="/unavailable-category/:slug" component={UnavailableCategoryRoute} />
            <Route
              path="/group-sales"
              pageKey="group-sales"
              priority={PageKeyPriority.TOP_LEVEL}
              component={Routes.GroupSales}
            />
            <Route
              path="/ea-landing"
              pageKey="ea-landing"
              priority={PageKeyPriority.TOP_LEVEL}
              component={Routes.ExpertAffiliateLanding}
            />
            <Route
              path="/path"
              pageKey="path"
              priority={PageKeyPriority.TOP_LEVEL}
              component={Routes.Path}
            />
            <Route
              path="/survey"
              pageKey="survey"
              priority={PageKeyPriority.TOP_LEVEL}
              component={Routes.Survey}
            />
            <Route
              path="/requests"
              pageKey="requests"
              priority={PageKeyPriority.TOP_LEVEL}
              component={Routes.Requests}
            />
            <Route
              path="/sms-promo"
              pageKey="sms-promo"
              priority={PageKeyPriority.TOP_LEVEL}
              component={Routes.SmsPromo}
            />
            <Route
              path="/unsubscribe"
              pageKey="unsubscribe"
              priority={PageKeyPriority.TOP_LEVEL}
              component={Routes.Unsubscribe}
            />
            <Route
              path="/winter-packing-list"
              pageKey="winter-packing-list"
              priority={PageKeyPriority.TOP_LEVEL}
              component={Routes.WinterPackingList}
            />
            <Route
              path="/partners"
              pageKey="partners"
              priority={PageKeyPriority.TOP_LEVEL}
              component={Routes.Partners}
            />
            <Route
              path="/for-you"
              pageKey="for-you"
              priority={PageKeyPriority.TOP_LEVEL}
              component={ForYouRoute}
            />
            <Route
              render={() => (
                // Load Stripe snippets here to avoid unnecessary loading on pages that don't need it.
                <StripeContextProvider>
                  <Switch>
                    <Route
                      path="/checkout"
                      pageKey="checkout"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.Checkout}
                    />
                    <Route
                      path="/clearance"
                      pageKey="clearance"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={ClearanceRoute}
                    />
                    <Route
                      path="/sale"
                      pageKey="sale"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={SaleRoute}
                    />
                    <Route
                      path="/referral"
                      pageKey="referral"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.Referral}
                    />
                    <Route
                      path="/referral-reward-credit"
                      pageKey="referral-reward-credit"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.ReferralRewardCredit}
                    />
                    <Route
                      path="/insiders"
                      pageKey="insiders"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.Insiders}
                    />
                    <Route
                      path="/about-us"
                      pageKey="about-us"
                      priority={PageKeyPriority.TOP_LEVEL}
                      render={() => <Redirect to="/company/about" />}
                    />
                    <Route
                      path="/account"
                      pageKey="account"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.Account}
                    />
                    <Route
                      path="/apply"
                      pageKey="apply"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.Apply}
                    />
                    <Route
                      path="/careers"
                      pageKey="careers"
                      priority={PageKeyPriority.TOP_LEVEL}
                      render={() => <Redirect to="/company/careers" />}
                    />
                    <Route path="/c" component={ProductListingRoute} />
                    <Route
                      path="/company"
                      pageKey="company"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.Company}
                    />
                    <Route
                      path="/compare"
                      pageKey="compare-table"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={CompareRoute}
                    />
                    <Route
                      path="/reviews"
                      pageKey="reviews"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.Reviews}
                    />
                    <Route
                      path="/curations"
                      pageKey="curations"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Curations}
                    />
                    <Route path="/d" component={DepartmentsRouteParser} />
                    <Route path="/experts" component={ExpertProfileContainer} />
                    <Route
                      path="/expert-product-reviews"
                      component={ExpertProductReviewsPageContainer}
                    />
                    <Route
                      path="/e"
                      pageKey="expert-short-link-redirect"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.ExpertShortLinkRedirect}
                    />
                    <Route
                      path="/feedback"
                      pageKey="feedback"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.Feedback}
                    />
                    <Route
                      path="/faq"
                      pageKey="faq"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.FAQ}
                    />
                    <Route
                      path="/gift-card-promo"
                      pageKey="gift-card-promo"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.GiftCardPromo}
                    />
                    <Route
                      path="/how-it-works"
                      pageKey="how-it-works"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.HowItWorks}
                    />
                    <Route
                      path="/in-action"
                      pageKey="in-action"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.InAction}
                    />
                    <Route path="/inbox" component={InboxRoute} />
                    <Route
                      path="/journal"
                      pageKey="journal"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.Journal}
                    />
                    <Route
                      path="/legal"
                      pageKey="legal"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.Legal}
                    />
                    <Route
                      path="/giveaways"
                      pageKey="giveaways"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.Giveaways}
                    />
                    <Route
                      path="/meet-the-experts"
                      pageKey="meet-the-experts"
                      priority={PageKeyPriority.TOP_LEVEL}
                      render={() => <Redirect to="/company/experts" />}
                    />
                    <Route
                      path="/most-recommended"
                      pageKey="most-recommended"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.MostRecommended}
                    />
                    <Route
                      path="/mylists"
                      pageKey="influencer"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.Influencer}
                    />
                    <Route
                      path="/curated-lists"
                      pageKey="influencer"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.Influencer}
                    />
                    <Route
                      path="/organic-referral-signup"
                      pageKey="organic-referral-signup"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.OrganicReferralSignup}
                    />
                    <Route path="/pla" component={PLARouteParser} />
                    <Route path="/product-reviews" component={ProductReviewsRouteSwitch} />
                    <Route path="/products" component={ProductsRouteParser} />
                    <Route
                      path="/refer-a-friend"
                      pageKey="refer-a-friend"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.ReferAFriend}
                    />
                    <Route
                      path="/returns"
                      pageKey="returns"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.Returns}
                    />
                    <Route path="/search" component={SearchRoute} />
                    <Route
                      path="/sellable"
                      pageKey="sellable"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.Sellable}
                    />
                    <Route path="/shop" component={CategoryRoute} />
                    <Route
                      path="/support"
                      pageKey="support"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.Support}
                    />
                    <Route
                      path="/trial-faq"
                      pageKey="trial-faq"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.TrialFAQ}
                    />
                    <Route path="/top-lists" component={TopListsRouteParser} />
                    <Route path="/wishlist" component={WishlistsRouteParser} />
                    <Route path="/wl" component={WishlistsRouteParser} />
                    <Route
                      path="/preview-engagement-modal"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={EngagementModalPreview}
                    />
                    <Route
                      path="/scout"
                      pageKey="scout"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={Routes.Scout}
                    />
                    <Route
                      path="/bfcm"
                      pageKey="bfcm"
                      priority={PageKeyPriority.TOP_LEVEL}
                      component={BFCMRoute}
                    />
                    {/* 404 */}
                    <Route component={NotFound} />
                  </Switch>
                </StripeContextProvider>
              )}
            />
          </Switch>
        )}
      />
    </Switch>
  )
}

export default Router
