// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { SupportConversationForConversationListFragmentDoc, LeadForConversationListFragmentDoc } from '../components/ConversationList/ConversationList.generated';
import { LeadForConversationListItemFragmentDoc } from '../components/ConversationListItem/ConversationListItem.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type InboxRouteQueryVariables = Types.Exact<{
  consumerId: Types.Scalars['UserId']['input'];
}>;


export type InboxRouteQuery = { __typename: 'Query', supportConversation?: { __typename: 'SupportConversation', id: any, conversation: { __typename: 'Conversation', id: any, externalId: string } } | null, me?: { __typename: 'Myself', id: any, user: { __typename: 'User', id: any, leads: Array<{ __typename: 'Lead', id: any, expert: { __typename: 'BusinessUser', id: any, displayName: string, expertAttributes: { __typename: 'BusinessUserExpertAttributes', placeholderExpert: boolean } }, conversation: { __typename: 'Conversation', id: any, externalId: string }, consumerConversationMetrics: { __typename: 'ConversationMetrics', messageCount?: number | null } }> } } | null };


export const InboxRouteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"InboxRoute"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"consumerId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UserId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"supportConversation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"consumerId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"consumerId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SupportConversationForConversationList"}}]}},{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"leads"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"limit"},"value":{"kind":"IntValue","value":"10"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LeadForConversationList"}}]}}]}}]}}]}},...SupportConversationForConversationListFragmentDoc.definitions,...LeadForConversationListFragmentDoc.definitions,...LeadForConversationListItemFragmentDoc.definitions]} as unknown as DocumentNode;
export type InboxRouteComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<InboxRouteQuery, InboxRouteQueryVariables>, 'query'> & ({ variables: InboxRouteQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const InboxRouteComponent = (props: InboxRouteComponentProps) => (
      <ApolloReactComponents.Query<InboxRouteQuery, InboxRouteQueryVariables> query={InboxRouteDocument} {...props} />
    );
    

/**
 * __useInboxRouteQuery__
 *
 * To run a query within a React component, call `useInboxRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxRouteQuery({
 *   variables: {
 *      consumerId: // value for 'consumerId'
 *   },
 * });
 */
export function useInboxRouteQuery(baseOptions: Apollo.QueryHookOptions<InboxRouteQuery, InboxRouteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InboxRouteQuery, InboxRouteQueryVariables>(InboxRouteDocument, options);
      }
export function useInboxRouteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InboxRouteQuery, InboxRouteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InboxRouteQuery, InboxRouteQueryVariables>(InboxRouteDocument, options);
        }
export function useInboxRouteSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InboxRouteQuery, InboxRouteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InboxRouteQuery, InboxRouteQueryVariables>(InboxRouteDocument, options);
        }
export type InboxRouteQueryHookResult = ReturnType<typeof useInboxRouteQuery>;
export type InboxRouteLazyQueryHookResult = ReturnType<typeof useInboxRouteLazyQuery>;
export type InboxRouteSuspenseQueryHookResult = ReturnType<typeof useInboxRouteSuspenseQuery>;
export type InboxRouteQueryResult = Apollo.QueryResult<InboxRouteQuery, InboxRouteQueryVariables>;