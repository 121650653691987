import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import { useSetSellableStockAlertMutation } from '#src/app/mutations/SetSellableStockAlert.generated'
import { useCancelSellableStockAlertMutation } from '#src/app/mutations/CancelSellableStockAlert.generated'
import {
  SellableForSellableStockAlertFragment,
  SellableStockAlertForSellableStockAlertFragment,
  UserForSellableStockAlertFragment
} from './SellableStockAlert.generated'

export type SellableStockAlertContextType = {
  setAlert: (user: UserForSellableStockAlertFragment) => void
  cancelAlert: (sellableStockAlert: SellableStockAlertForSellableStockAlertFragment) => void
  loading: boolean
}

const SellableStockAlertContext = React.createContext<SellableStockAlertContextType>({
  setAlert: () => {},
  cancelAlert: () => {},
  loading: false
})

const Provider = SellableStockAlertContext.Provider

interface SellableStockAlertContextProviderProps {
  sellable: SellableForSellableStockAlertFragment
}

const SellableStockAlertContextProvider: React.FC<
  React.PropsWithChildren<SellableStockAlertContextProviderProps>
> = ({ sellable, children }) => {
  const [setSellableStockAlert, { loading: setAlertLoading }] = useSetSellableStockAlertMutation({
    onCompleted: ({ setSellableStockAlert: { error } }) => {
      if (error) {
        toast.error(error.errorMessage)
      }
    },
    onError: () => toast.error('Uh oh. Something went wrong. Please try again.')
  })

  const [cancelSellableStockAlert, { loading: cancelAlertLoading }] =
    useCancelSellableStockAlertMutation({
      onCompleted: ({ cancelSellableStockAlert }) => {
        if (cancelSellableStockAlert.error) {
          toast.error(cancelSellableStockAlert.error.errorMessage)
        }
      },
      onError: () => toast.error('Uh oh. Something went wrong. Please try again.')
    })

  return (
    <Provider
      value={{
        setAlert: user =>
          setSellableStockAlert({ variables: { sellableId: sellable.id, subscriberId: user.id } }),
        cancelAlert: sellableStockAlert =>
          cancelSellableStockAlert({ variables: { sellableStockAlertId: sellableStockAlert.id } }),
        loading: setAlertLoading || cancelAlertLoading
      }}
    >
      {children}
    </Provider>
  )
}

const useSellableStockAlertContext = () => {
  const context = useContext(SellableStockAlertContext)

  if (!SellableStockAlertContext) {
    throw new Error('Invoked SellableStockAlertContext outside of provider')
  }

  return context
}

export { SellableStockAlertContextProvider, useSellableStockAlertContext }
