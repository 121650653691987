// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { BusinessUserForExpertQuestionModalFragmentDoc } from '../ExpertQuestionModal/ExpertQuestionModal.generated';
import { ExpertProfileHighlightsFragmentDoc } from '../../fragments/ExpertProfileHighlights.generated';
import { MediaFileFragmentDoc } from '../../fragments/MediaFile.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type RecommendedExpertForConversationStartersFragment = { __typename: 'BusinessUser', id: any, displayName: string, shortName: string, state: Types.BusinessUserState, expertAttributes: { __typename: 'BusinessUserExpertAttributes', placeholderExpert: boolean }, department: { __typename: 'Department', id: any, slug: string }, expertProfile?: { __typename: 'ExpertProfile', id: any, headline: string, gender?: Types.ExpertGender | null, location?: string | null, backgroundRichText?: string | null, background: string, highlights: Array<{ __typename: 'ProfileHighlight', value: string, secondaryValue: string, type: { __typename: 'ProfileHighlightType', id: any, icon: Types.ProfileHighlightIconType, displayName: string } }>, profilePhoto?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null } | null, expertStats?: { __typename: 'ExpertStats', averageRating?: number | null, totalReviews: number, totalMessagesSent: number, totalLeadsAsPrimaryExpert: number } | null };

export type RecommendedExpertForConversationStartersQueryVariables = Types.Exact<{
  categoryId: Types.Scalars['CategoryId']['input'];
  preferredExpertVanityId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type RecommendedExpertForConversationStartersQuery = { __typename: 'Query', category: { __typename: 'Category', id: any, recommendExpertForConsumer: { __typename: 'RecommendExpertPayload', expert?: { __typename: 'BusinessUser', id: any, displayName: string, shortName: string, state: Types.BusinessUserState, expertAttributes: { __typename: 'BusinessUserExpertAttributes', placeholderExpert: boolean }, department: { __typename: 'Department', id: any, slug: string }, expertProfile?: { __typename: 'ExpertProfile', id: any, headline: string, gender?: Types.ExpertGender | null, location?: string | null, backgroundRichText?: string | null, background: string, highlights: Array<{ __typename: 'ProfileHighlight', value: string, secondaryValue: string, type: { __typename: 'ProfileHighlightType', id: any, icon: Types.ProfileHighlightIconType, displayName: string } }>, profilePhoto?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null } | null, expertStats?: { __typename: 'ExpertStats', averageRating?: number | null, totalReviews: number, totalMessagesSent: number, totalLeadsAsPrimaryExpert: number } | null } | null } } };

export const RecommendedExpertForConversationStartersFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"RecommendedExpertForConversationStarters"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"shortName"}},{"kind":"Field","name":{"kind":"Name","value":"expertAttributes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"placeholderExpert"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForExpertQuestionModal"}}]}}]} as unknown as DocumentNode;
export const RecommendedExpertForConversationStartersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"RecommendedExpertForConversationStarters"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"categoryId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CategoryId"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"preferredExpertVanityId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"category"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"categoryId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"categoryId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"recommendExpertForConsumer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"preferredExpertVanityId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"preferredExpertVanityId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"expert"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RecommendedExpertForConversationStarters"}}]}}]}}]}}]}},...RecommendedExpertForConversationStartersFragmentDoc.definitions,...BusinessUserForExpertQuestionModalFragmentDoc.definitions,...ExpertProfileHighlightsFragmentDoc.definitions,...MediaFileFragmentDoc.definitions]} as unknown as DocumentNode;
export type RecommendedExpertForConversationStartersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RecommendedExpertForConversationStartersQuery, RecommendedExpertForConversationStartersQueryVariables>, 'query'> & ({ variables: RecommendedExpertForConversationStartersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RecommendedExpertForConversationStartersComponent = (props: RecommendedExpertForConversationStartersComponentProps) => (
      <ApolloReactComponents.Query<RecommendedExpertForConversationStartersQuery, RecommendedExpertForConversationStartersQueryVariables> query={RecommendedExpertForConversationStartersDocument} {...props} />
    );
    

/**
 * __useRecommendedExpertForConversationStartersQuery__
 *
 * To run a query within a React component, call `useRecommendedExpertForConversationStartersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendedExpertForConversationStartersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendedExpertForConversationStartersQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      preferredExpertVanityId: // value for 'preferredExpertVanityId'
 *   },
 * });
 */
export function useRecommendedExpertForConversationStartersQuery(baseOptions: Apollo.QueryHookOptions<RecommendedExpertForConversationStartersQuery, RecommendedExpertForConversationStartersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecommendedExpertForConversationStartersQuery, RecommendedExpertForConversationStartersQueryVariables>(RecommendedExpertForConversationStartersDocument, options);
      }
export function useRecommendedExpertForConversationStartersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecommendedExpertForConversationStartersQuery, RecommendedExpertForConversationStartersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecommendedExpertForConversationStartersQuery, RecommendedExpertForConversationStartersQueryVariables>(RecommendedExpertForConversationStartersDocument, options);
        }
export function useRecommendedExpertForConversationStartersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RecommendedExpertForConversationStartersQuery, RecommendedExpertForConversationStartersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RecommendedExpertForConversationStartersQuery, RecommendedExpertForConversationStartersQueryVariables>(RecommendedExpertForConversationStartersDocument, options);
        }
export type RecommendedExpertForConversationStartersQueryHookResult = ReturnType<typeof useRecommendedExpertForConversationStartersQuery>;
export type RecommendedExpertForConversationStartersLazyQueryHookResult = ReturnType<typeof useRecommendedExpertForConversationStartersLazyQuery>;
export type RecommendedExpertForConversationStartersSuspenseQueryHookResult = ReturnType<typeof useRecommendedExpertForConversationStartersSuspenseQuery>;
export type RecommendedExpertForConversationStartersQueryResult = Apollo.QueryResult<RecommendedExpertForConversationStartersQuery, RecommendedExpertForConversationStartersQueryVariables>;