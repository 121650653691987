import trimStart from 'lodash/trimStart'
import * as History from 'history'
import { LocationDescriptor } from 'history'
import { TrackingQueryParameter } from '@deal/web-tracking/constants'
import { OrderForCheckoutLocationFragment } from './OrderForCheckoutLocation.generated'

export interface LoginLocationOptions {
  loginToken?: string
  handleId?: string
  header?: string
  registerViaPath?: boolean
  promotionClaimed?: boolean
}

export function loginLocation(
  returnLocation: History.Location | string,
  options: LoginLocationOptions = {}
): History.Location {
  // Normalize the input to a Location. String URLs are allowed for convenience.
  returnLocation =
    typeof returnLocation === 'string' ? History.createLocation(returnLocation) : returnLocation

  // Parse the query string of the destination location
  const returnQueryParams = new URLSearchParams(returnLocation.search)

  // Extract a login token and/or a handle ID from the destination location as a fallback, if
  //   none were explicitly provided. This allows us to add login tokens and/or handle IDs to
  //   any URL and have them preserved when we redirect the user to the login flow.
  const loginToken =
    options.loginToken || returnQueryParams.get('lt') || returnQueryParams.get('tlt') || undefined
  const handleId = options.handleId || returnQueryParams.get('hid') || undefined

  // The login route accepts 4 query params
  //   1) `then`..... The URL or pathname to send the user to after they successfully authenticate
  //   2) `lt` or `tlt`....... A login token (essentially a single use, private password that expires)
  //   3) `hid`...... A handle identifier (a "handle" is the user's phone or email). Used to display
  //                  a masked version of their handle, such as "c*******@curated.com".
  //   4) `rvp`...... A flag that, if true, sends user through path while registering.
  //   5) `header`... An pseudo-enum value that controls the copy shown on the authentication pages.
  //                  This will be deprecated soon.
  const loginQueryParams = new URLSearchParams({
    then: `${returnLocation.pathname}?${trimStart(returnLocation.search, '?')}`
  })

  // Preserve all tracking parameters from the destination URL. This is especially important when
  //   redirecting to the login page in the case of a 401, so the initial Touch and other tracking
  //   events on that page include the proper attributes.
  Object.keys(TrackingQueryParameter).forEach(paramName => {
    if (returnQueryParams.has(paramName)) {
      loginQueryParams.append(paramName, returnQueryParams.get(paramName)!)
    }
  })

  if (loginToken) {
    loginQueryParams.set('tlt', loginToken)
  }

  if (handleId) {
    loginQueryParams.set('hid', handleId)
  }

  if (options.header) {
    loginQueryParams.set('header', options.header)
  }

  if (options.registerViaPath) {
    loginQueryParams.set('rvp', 'true')
  }

  if (options.promotionClaimed) {
    loginQueryParams.set('promo', 'true')
  }

  return {
    ...returnLocation,
    pathname: '/auth/login',
    search: loginQueryParams.toString()
  }
}

export function logoutLocation(returnLocation: LocationDescriptor | string): LocationDescriptor {
  // Normalize the input to a Location. String URLs are allowed for convenience.
  returnLocation =
    typeof returnLocation === 'string' ? History.createLocation(returnLocation) : returnLocation

  const returnPathname = returnLocation.pathname ? returnLocation.pathname : ''
  const returnQueryParams = returnLocation.search ? encodeURIComponent(returnLocation.search) : ''
  const logoutLocation = {
    pathname: '/auth/logout',
    search: returnPathname ? `?then=${returnPathname}${returnQueryParams}` : returnQueryParams
  }

  return logoutLocation
}

export function checkoutLocation(order: OrderForCheckoutLocationFragment) {
  if (order.requiresShippingAddress) {
    return `/checkout/${order.id}/shipping`
  } else {
    return `/checkout/${order.id}/payment`
  }
}
