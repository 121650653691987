import React from 'react'
import { useBreakpoint } from '#src/app/hooks/useBreakpoint'
import { Skeleton } from '#src/app/components/SkeletonLoader'
import PageContent from '#src/app/components/PageContent'
import styles from './styles.css'

const ExpertSkeletonLoader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isLargerThanSm = useBreakpoint('sm')

  return (
    <PageContent>
      <div className={styles.wrapper}>
        <Skeleton width={375} height={500} />
        <div className={styles.details}>
          <Skeleton width={isLargerThanSm ? 400 : 200} height={75} />
          <Skeleton
            width={isLargerThanSm ? 450 : 250}
            height={isLargerThanSm ? 100 : 50}
            className={styles.body}
          />
        </div>
      </div>
    </PageContent>
  )
}

export default ExpertSkeletonLoader
