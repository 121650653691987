// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
export type AttributeForAttributesToAttributeInputsFragment = { __typename: 'Attribute', schema: { __typename: 'AttributeSchemaRevision', id: any, name: string }, values: Array<{ __typename: 'AttributeValueDate' } | { __typename: 'AttributeValueDateRange' } | { __typename: 'AttributeValueDateTime' } | { __typename: 'AttributeValueDateTimeRange' } | { __typename: 'AttributeValueDecimal' } | { __typename: 'AttributeValueDecimalRange' } | { __typename: 'AttributeValueEnumeration', enumerationValue: string } | { __typename: 'AttributeValueId' } | { __typename: 'AttributeValueInteger', integer: any } | { __typename: 'AttributeValueIntegerRange', minInteger?: any | null, maxInteger?: any | null } | { __typename: 'AttributeValueText', text: string } | { __typename: 'AttributeValueTime' } | { __typename: 'AttributeValueTimeRange' } | { __typename: 'AttributeValueZoneId' }> };

export type AttributeSchemaRevisionForIsAttributeNameFragment = { __typename: 'AttributeSchemaRevision', id: any, name: string };

export type AttributeValueForIsEnumerationValueList_AttributeValueDate_Fragment = { __typename: 'AttributeValueDate' };

export type AttributeValueForIsEnumerationValueList_AttributeValueDateRange_Fragment = { __typename: 'AttributeValueDateRange' };

export type AttributeValueForIsEnumerationValueList_AttributeValueDateTime_Fragment = { __typename: 'AttributeValueDateTime' };

export type AttributeValueForIsEnumerationValueList_AttributeValueDateTimeRange_Fragment = { __typename: 'AttributeValueDateTimeRange' };

export type AttributeValueForIsEnumerationValueList_AttributeValueDecimal_Fragment = { __typename: 'AttributeValueDecimal' };

export type AttributeValueForIsEnumerationValueList_AttributeValueDecimalRange_Fragment = { __typename: 'AttributeValueDecimalRange' };

export type AttributeValueForIsEnumerationValueList_AttributeValueEnumeration_Fragment = { __typename: 'AttributeValueEnumeration', enumerationValue: string };

export type AttributeValueForIsEnumerationValueList_AttributeValueId_Fragment = { __typename: 'AttributeValueId' };

export type AttributeValueForIsEnumerationValueList_AttributeValueInteger_Fragment = { __typename: 'AttributeValueInteger' };

export type AttributeValueForIsEnumerationValueList_AttributeValueIntegerRange_Fragment = { __typename: 'AttributeValueIntegerRange' };

export type AttributeValueForIsEnumerationValueList_AttributeValueText_Fragment = { __typename: 'AttributeValueText' };

export type AttributeValueForIsEnumerationValueList_AttributeValueTime_Fragment = { __typename: 'AttributeValueTime' };

export type AttributeValueForIsEnumerationValueList_AttributeValueTimeRange_Fragment = { __typename: 'AttributeValueTimeRange' };

export type AttributeValueForIsEnumerationValueList_AttributeValueZoneId_Fragment = { __typename: 'AttributeValueZoneId' };

export type AttributeValueForIsEnumerationValueListFragment = AttributeValueForIsEnumerationValueList_AttributeValueDate_Fragment | AttributeValueForIsEnumerationValueList_AttributeValueDateRange_Fragment | AttributeValueForIsEnumerationValueList_AttributeValueDateTime_Fragment | AttributeValueForIsEnumerationValueList_AttributeValueDateTimeRange_Fragment | AttributeValueForIsEnumerationValueList_AttributeValueDecimal_Fragment | AttributeValueForIsEnumerationValueList_AttributeValueDecimalRange_Fragment | AttributeValueForIsEnumerationValueList_AttributeValueEnumeration_Fragment | AttributeValueForIsEnumerationValueList_AttributeValueId_Fragment | AttributeValueForIsEnumerationValueList_AttributeValueInteger_Fragment | AttributeValueForIsEnumerationValueList_AttributeValueIntegerRange_Fragment | AttributeValueForIsEnumerationValueList_AttributeValueText_Fragment | AttributeValueForIsEnumerationValueList_AttributeValueTime_Fragment | AttributeValueForIsEnumerationValueList_AttributeValueTimeRange_Fragment | AttributeValueForIsEnumerationValueList_AttributeValueZoneId_Fragment;

export type AttributeValueForIsTextValueList_AttributeValueDate_Fragment = { __typename: 'AttributeValueDate' };

export type AttributeValueForIsTextValueList_AttributeValueDateRange_Fragment = { __typename: 'AttributeValueDateRange' };

export type AttributeValueForIsTextValueList_AttributeValueDateTime_Fragment = { __typename: 'AttributeValueDateTime' };

export type AttributeValueForIsTextValueList_AttributeValueDateTimeRange_Fragment = { __typename: 'AttributeValueDateTimeRange' };

export type AttributeValueForIsTextValueList_AttributeValueDecimal_Fragment = { __typename: 'AttributeValueDecimal' };

export type AttributeValueForIsTextValueList_AttributeValueDecimalRange_Fragment = { __typename: 'AttributeValueDecimalRange' };

export type AttributeValueForIsTextValueList_AttributeValueEnumeration_Fragment = { __typename: 'AttributeValueEnumeration' };

export type AttributeValueForIsTextValueList_AttributeValueId_Fragment = { __typename: 'AttributeValueId' };

export type AttributeValueForIsTextValueList_AttributeValueInteger_Fragment = { __typename: 'AttributeValueInteger' };

export type AttributeValueForIsTextValueList_AttributeValueIntegerRange_Fragment = { __typename: 'AttributeValueIntegerRange' };

export type AttributeValueForIsTextValueList_AttributeValueText_Fragment = { __typename: 'AttributeValueText', text: string };

export type AttributeValueForIsTextValueList_AttributeValueTime_Fragment = { __typename: 'AttributeValueTime' };

export type AttributeValueForIsTextValueList_AttributeValueTimeRange_Fragment = { __typename: 'AttributeValueTimeRange' };

export type AttributeValueForIsTextValueList_AttributeValueZoneId_Fragment = { __typename: 'AttributeValueZoneId' };

export type AttributeValueForIsTextValueListFragment = AttributeValueForIsTextValueList_AttributeValueDate_Fragment | AttributeValueForIsTextValueList_AttributeValueDateRange_Fragment | AttributeValueForIsTextValueList_AttributeValueDateTime_Fragment | AttributeValueForIsTextValueList_AttributeValueDateTimeRange_Fragment | AttributeValueForIsTextValueList_AttributeValueDecimal_Fragment | AttributeValueForIsTextValueList_AttributeValueDecimalRange_Fragment | AttributeValueForIsTextValueList_AttributeValueEnumeration_Fragment | AttributeValueForIsTextValueList_AttributeValueId_Fragment | AttributeValueForIsTextValueList_AttributeValueInteger_Fragment | AttributeValueForIsTextValueList_AttributeValueIntegerRange_Fragment | AttributeValueForIsTextValueList_AttributeValueText_Fragment | AttributeValueForIsTextValueList_AttributeValueTime_Fragment | AttributeValueForIsTextValueList_AttributeValueTimeRange_Fragment | AttributeValueForIsTextValueList_AttributeValueZoneId_Fragment;

export type AttributeValueForIsIntegerRangeValueList_AttributeValueDate_Fragment = { __typename: 'AttributeValueDate' };

export type AttributeValueForIsIntegerRangeValueList_AttributeValueDateRange_Fragment = { __typename: 'AttributeValueDateRange' };

export type AttributeValueForIsIntegerRangeValueList_AttributeValueDateTime_Fragment = { __typename: 'AttributeValueDateTime' };

export type AttributeValueForIsIntegerRangeValueList_AttributeValueDateTimeRange_Fragment = { __typename: 'AttributeValueDateTimeRange' };

export type AttributeValueForIsIntegerRangeValueList_AttributeValueDecimal_Fragment = { __typename: 'AttributeValueDecimal' };

export type AttributeValueForIsIntegerRangeValueList_AttributeValueDecimalRange_Fragment = { __typename: 'AttributeValueDecimalRange' };

export type AttributeValueForIsIntegerRangeValueList_AttributeValueEnumeration_Fragment = { __typename: 'AttributeValueEnumeration' };

export type AttributeValueForIsIntegerRangeValueList_AttributeValueId_Fragment = { __typename: 'AttributeValueId' };

export type AttributeValueForIsIntegerRangeValueList_AttributeValueInteger_Fragment = { __typename: 'AttributeValueInteger' };

export type AttributeValueForIsIntegerRangeValueList_AttributeValueIntegerRange_Fragment = { __typename: 'AttributeValueIntegerRange', minInteger?: any | null, maxInteger?: any | null };

export type AttributeValueForIsIntegerRangeValueList_AttributeValueText_Fragment = { __typename: 'AttributeValueText' };

export type AttributeValueForIsIntegerRangeValueList_AttributeValueTime_Fragment = { __typename: 'AttributeValueTime' };

export type AttributeValueForIsIntegerRangeValueList_AttributeValueTimeRange_Fragment = { __typename: 'AttributeValueTimeRange' };

export type AttributeValueForIsIntegerRangeValueList_AttributeValueZoneId_Fragment = { __typename: 'AttributeValueZoneId' };

export type AttributeValueForIsIntegerRangeValueListFragment = AttributeValueForIsIntegerRangeValueList_AttributeValueDate_Fragment | AttributeValueForIsIntegerRangeValueList_AttributeValueDateRange_Fragment | AttributeValueForIsIntegerRangeValueList_AttributeValueDateTime_Fragment | AttributeValueForIsIntegerRangeValueList_AttributeValueDateTimeRange_Fragment | AttributeValueForIsIntegerRangeValueList_AttributeValueDecimal_Fragment | AttributeValueForIsIntegerRangeValueList_AttributeValueDecimalRange_Fragment | AttributeValueForIsIntegerRangeValueList_AttributeValueEnumeration_Fragment | AttributeValueForIsIntegerRangeValueList_AttributeValueId_Fragment | AttributeValueForIsIntegerRangeValueList_AttributeValueInteger_Fragment | AttributeValueForIsIntegerRangeValueList_AttributeValueIntegerRange_Fragment | AttributeValueForIsIntegerRangeValueList_AttributeValueText_Fragment | AttributeValueForIsIntegerRangeValueList_AttributeValueTime_Fragment | AttributeValueForIsIntegerRangeValueList_AttributeValueTimeRange_Fragment | AttributeValueForIsIntegerRangeValueList_AttributeValueZoneId_Fragment;

export type AttributeValueForIsIntegerValueList_AttributeValueDate_Fragment = { __typename: 'AttributeValueDate' };

export type AttributeValueForIsIntegerValueList_AttributeValueDateRange_Fragment = { __typename: 'AttributeValueDateRange' };

export type AttributeValueForIsIntegerValueList_AttributeValueDateTime_Fragment = { __typename: 'AttributeValueDateTime' };

export type AttributeValueForIsIntegerValueList_AttributeValueDateTimeRange_Fragment = { __typename: 'AttributeValueDateTimeRange' };

export type AttributeValueForIsIntegerValueList_AttributeValueDecimal_Fragment = { __typename: 'AttributeValueDecimal' };

export type AttributeValueForIsIntegerValueList_AttributeValueDecimalRange_Fragment = { __typename: 'AttributeValueDecimalRange' };

export type AttributeValueForIsIntegerValueList_AttributeValueEnumeration_Fragment = { __typename: 'AttributeValueEnumeration' };

export type AttributeValueForIsIntegerValueList_AttributeValueId_Fragment = { __typename: 'AttributeValueId' };

export type AttributeValueForIsIntegerValueList_AttributeValueInteger_Fragment = { __typename: 'AttributeValueInteger', integer: any };

export type AttributeValueForIsIntegerValueList_AttributeValueIntegerRange_Fragment = { __typename: 'AttributeValueIntegerRange' };

export type AttributeValueForIsIntegerValueList_AttributeValueText_Fragment = { __typename: 'AttributeValueText' };

export type AttributeValueForIsIntegerValueList_AttributeValueTime_Fragment = { __typename: 'AttributeValueTime' };

export type AttributeValueForIsIntegerValueList_AttributeValueTimeRange_Fragment = { __typename: 'AttributeValueTimeRange' };

export type AttributeValueForIsIntegerValueList_AttributeValueZoneId_Fragment = { __typename: 'AttributeValueZoneId' };

export type AttributeValueForIsIntegerValueListFragment = AttributeValueForIsIntegerValueList_AttributeValueDate_Fragment | AttributeValueForIsIntegerValueList_AttributeValueDateRange_Fragment | AttributeValueForIsIntegerValueList_AttributeValueDateTime_Fragment | AttributeValueForIsIntegerValueList_AttributeValueDateTimeRange_Fragment | AttributeValueForIsIntegerValueList_AttributeValueDecimal_Fragment | AttributeValueForIsIntegerValueList_AttributeValueDecimalRange_Fragment | AttributeValueForIsIntegerValueList_AttributeValueEnumeration_Fragment | AttributeValueForIsIntegerValueList_AttributeValueId_Fragment | AttributeValueForIsIntegerValueList_AttributeValueInteger_Fragment | AttributeValueForIsIntegerValueList_AttributeValueIntegerRange_Fragment | AttributeValueForIsIntegerValueList_AttributeValueText_Fragment | AttributeValueForIsIntegerValueList_AttributeValueTime_Fragment | AttributeValueForIsIntegerValueList_AttributeValueTimeRange_Fragment | AttributeValueForIsIntegerValueList_AttributeValueZoneId_Fragment;

export const AttributeValueForIsEnumerationValueListFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AttributeValueForIsEnumerationValueList"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AttributeValue"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AttributeValueEnumeration"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"enumerationValue"}}]}}]}}]} as unknown as DocumentNode;
export const AttributeValueForIsTextValueListFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AttributeValueForIsTextValueList"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AttributeValue"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AttributeValueText"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"text"}}]}}]}}]} as unknown as DocumentNode;
export const AttributeValueForIsIntegerValueListFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AttributeValueForIsIntegerValueList"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AttributeValue"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AttributeValueInteger"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"integer"}}]}}]}}]} as unknown as DocumentNode;
export const AttributeValueForIsIntegerRangeValueListFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AttributeValueForIsIntegerRangeValueList"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AttributeValue"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AttributeValueIntegerRange"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"minInteger"}},{"kind":"Field","name":{"kind":"Name","value":"maxInteger"}}]}}]}}]} as unknown as DocumentNode;
export const AttributeForAttributesToAttributeInputsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AttributeForAttributesToAttributeInputs"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Attribute"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"schema"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"values"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AttributeValueForIsEnumerationValueList"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"AttributeValueForIsTextValueList"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"AttributeValueForIsIntegerValueList"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"AttributeValueForIsIntegerRangeValueList"}}]}}]}}]} as unknown as DocumentNode;
export const AttributeSchemaRevisionForIsAttributeNameFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AttributeSchemaRevisionForIsAttributeName"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AttributeSchemaRevision"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]} as unknown as DocumentNode;