import { useLocation } from '@deal/router'
import {
  SerializableProductListingPageQuery,
  buildProductListingPageUrl
} from '../services/search/buildProductListingPageUrl'
import { useUserAgentContext } from '../containers/UserAgent'
/**
 * Only use this if you have facets or filters to navigate user to PLP /c page
 */
const useProductListingLocation = () => {
  // If we can't determine whether the user is a bot, assume they are. This flag is used to add
  //   tracking parameters to the destination URL, which causes duplicate URLs for crawlers. It's
  //   better to be conservative and miss some tracking info than to create crawl issues.
  const { isBot = true } = useUserAgentContext()
  const { search } = useLocation()

  return (query: SerializableProductListingPageQuery) => {
    return buildProductListingPageUrl(query, new URLSearchParams(search), isBot)
  }
}

export { useProductListingLocation }
