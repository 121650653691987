import React, { useState } from 'react'
import { useLocation } from '@deal/router'
import { Chat as ChatFromChatFirebase } from '@deal/chat-firebase'
import { BusinessUserState } from '#src/generated/types'
import { isMobileBrowser } from '#src/app/services/mobile'
import { usePushNotificationsContext } from '#src/app/context/push-notifications'
import { useChatWidgetStateContext } from '#src/app/context/chat-widget-state'
import { useUserAgentContext } from '#src/app/containers/UserAgent'
import { useIdentityContext } from '#src/app/containers/Identity'
import DeactivatedExpertChatBanner from '#src/app/components/DeactivatedExpertChatBanner'
import { ChatExpertProfilePreview } from '#src/app/components/ChatExpertProfilePreview'
import { BusinessUserForChatWidgetChatFragment } from './Chat.generated'
import { CurrentlyViewing } from '../CurrentlyViewing'
import { ChatSuggestions } from '../ChatSuggestions'

export interface ChatProps {
  expert: BusinessUserForChatWidgetChatFragment
  conversationId?: string
  isAI?: boolean
  onConversationStarted: () => void
  leadId?: string
  categorySlug?: string
}

const Chat: React.FC<ChatProps> = ({
  expert,
  conversationId,
  isAI,
  onConversationStarted,
  leadId,
  categorySlug
}) => {
  const { myself } = useIdentityContext()
  const { requestPushNotificationPermission } = usePushNotificationsContext()
  // it is important that we set the initial value to null since the ChatSuggestion components is dependent on the value of the state.
  // changing from null to true will trigger the ChatSuggestion component to fetch the suggestions. We have seen in prod the suggestion didn't show up
  // because the value quickly changed from true->false->true, probably all within one react render cycle. that caused the ChatSuggestion component to not fetch the suggestions
  // we need to rework this to use subscription as some point as there are too many race conditions between the firebase callback, react render cycle and etc
  const [areSuggestionsVisible, setAreSuggestionsVisible] = useState<boolean | null>(null)
  const {
    isChatWidgetExpanded,
    isChatWidgetMinimized,
    isChatWidgetPreview,
    expandChatWidget,
    previewChatWidget
  } = useChatWidgetStateContext()
  const userAgent = useUserAgentContext()
  const isMobile = isMobileBrowser(userAgent)
  const location = useLocation()
  const getMessageMetadata = () => {
    const { pathname, search } = location
    const searchParams = new URLSearchParams(search)
    const pathNameSplit = pathname.split('/')
    const messageMetadata: {
      consumerPageType: string
      consumerPagePath: string
      consumerPageSellableId?: string
      consumerPageArticleId?: string
      consumerPageCategorySlug?: string
      consumerPageSearchQuery?: string
    } = {
      consumerPageType: 'HOME_PAGE',
      consumerPagePath: pathname
    }
    switch (pathNameSplit[1]) {
      case 'products': {
        if (pathNameSplit[2]) {
          messageMetadata.consumerPageSellableId = pathNameSplit[2]
          messageMetadata.consumerPageType = 'PDP'
        }
        break
      }
      case 'journal': {
        if (pathNameSplit[2]) {
          if (/^[a-zA-Z_-]+/.test(pathNameSplit[2])) {
            messageMetadata.consumerPageCategorySlug = pathNameSplit[2]
            messageMetadata.consumerPageType = 'JOURNAL_HUB'
          } else {
            messageMetadata.consumerPageArticleId = pathNameSplit[2]
            messageMetadata.consumerPageType = 'JOURNAL_ARTICLE'
          }
        } else {
          messageMetadata.consumerPageType = 'JOURNAL_HUB'
        }
        break
      }
      case 'shop':
      case 'c': {
        messageMetadata.consumerPageCategorySlug = pathNameSplit[2]
        messageMetadata.consumerPageType = 'CATEGORY'
        break
      }
      case 'search': {
        messageMetadata.consumerPageSearchQuery = searchParams.get('q') || undefined
        messageMetadata.consumerPageType = 'SEARCH'
      }
    }
    return messageMetadata
  }

  const areSuggestionsShown = isAI && expert.expertAttributes.placeholderExpert

  return (
    <ChatFromChatFirebase
      conversationId={conversationId}
      recipientIds={[expert.id]}
      enableReadReceipts={isChatWidgetExpanded}
      updateReadUntilTimestamp={isChatWidgetMinimized}
      bottomBanner={() => {
        if (areSuggestionsShown && leadId && conversationId && categorySlug) {
          return (
            <CurrentlyViewing>
              <ChatSuggestions
                leadId={leadId}
                conversationId={conversationId}
                categorySlug={categorySlug}
                areSuggestionsVisible={areSuggestionsVisible}
                setAreSuggestionsVisible={setAreSuggestionsVisible}
                getMessageMetadata={getMessageMetadata}
              />
            </CurrentlyViewing>
          )
        } else if (myself && expert.state === BusinessUserState.DEACTIVATED && conversationId) {
          return (
            <DeactivatedExpertChatBanner
              deactivatedExpert={expert}
              conversationId={conversationId}
              placeholderExpert={expert.department?.placeholderExpert}
            />
          )
        }
      }}
      source="chathead"
      onConversationStarted={onConversationStarted}
      onSendMessage={() => {
        requestPushNotificationPermission()
        setAreSuggestionsVisible(false)
      }}
      onNewMessage={isMyself => {
        if (isChatWidgetMinimized) {
          previewChatWidget('new-message')
        }
        setAreSuggestionsVisible(!isMyself)
      }}
      onScrollEnd={
        isChatWidgetPreview ? () => expandChatWidget('user-interaction-chat-scroll') : undefined
      }
      isMobile={isMobile}
      messageMetadata={getMessageMetadata()}
    >
      <ChatExpertProfilePreview expert={expert} isAI={isAI} />
    </ChatFromChatFirebase>
  )
}

const MemoChat = React.memo(Chat)
export { MemoChat as Chat }
