// @ts-nocheck
import type * as Types from '../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { BusinessUserForExpertNameTagFragmentDoc } from '../../../../components/ExpertNameTag/ExpertNameTag.generated';
import { BusinessUserForExpertForAvatarWithPopoverFragmentDoc, BusinessUserForExpertForAvatarFragmentDoc } from '../../../../services/avatars/BusinessUserForExpertForAvatar.generated';
import { BusinessUserForGetExpertExpertiseFragmentDoc, BusinessUserForGetExpertProfileLinkFragmentDoc } from '../../../../services/expert/expert.generated';
import { BusinessUserForExpertQuestionModalFragmentDoc } from '../../../../components/ExpertQuestionModal/ExpertQuestionModal.generated';
import { ExpertProfileHighlightsFragmentDoc } from '../../../../fragments/ExpertProfileHighlights.generated';
import { MediaFileFragmentDoc } from '../../../../fragments/MediaFile.generated';
export type BusinessUserForYourExpertFragment = { __typename: 'BusinessUser', id: any, state: Types.BusinessUserState, displayName: string, shortName: string, vanityId?: string | null, expertProfile?: { __typename: 'ExpertProfile', id: any, headline: string, gender?: Types.ExpertGender | null, location?: string | null, backgroundRichText?: string | null, background: string, backgroundSummaryLines: Array<string>, highlights: Array<{ __typename: 'ProfileHighlight', value: string, secondaryValue: string, type: { __typename: 'ProfileHighlightType', id: any, icon: Types.ProfileHighlightIconType, displayName: string } }>, profilePhoto?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null } | null, expertStats?: { __typename: 'ExpertStats', totalLeadsParticipated: number, totalReviews: number, averageRating?: number | null, totalMessagesSent: number, totalLeadsAsPrimaryExpert: number } | null, department: { __typename: 'Department', id: any, slug: string }, expertAttributes: { __typename: 'BusinessUserExpertAttributes', category: { __typename: 'Category', id: any, slug: string, singularDisplayName: string, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, expert?: { __typename: 'CategoryAssetNoun', singular: string } | null } | null }, specialtyCategory?: { __typename: 'Category', id: any, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, expert?: { __typename: 'CategoryAssetNoun', singular: string } | null } | null } | null }, articleAuthorProfile?: { __typename: 'ExpertProfile', id: any, headline: string } | null };

export const BusinessUserForYourExpertFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BusinessUserForYourExpert"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"expertProfile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"headline"}}]}},{"kind":"Field","name":{"kind":"Name","value":"expertStats"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"totalLeadsParticipated"}},{"kind":"Field","name":{"kind":"Name","value":"totalReviews"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForExpertNameTag"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForExpertQuestionModal"}}]}}]} as unknown as DocumentNode;