// @ts-nocheck
import type * as Types from '../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { WishlistForWishlistGiftCardOptionFragmentDoc } from './components/WishlistGiftCardOption/WishlistGiftCardOption.generated';
import { WishlistForWishlistDetailsFormFragmentDoc } from './components/WishlistDetailsForm/WishlistDetailsForm.generated';
import { WishlistForSetWishlistVisibilityFieldFragmentDoc } from '../components/SetWishlistVisibilityField/WishlistForSetWishlistVisibilityField.generated';
import { WishlistForCopyRegistryLinkFragmentDoc } from '../../../components/CopyRegistryLink/CopyRegistryLink.generated';
import { WishlistForWishlistShippingFormFragmentDoc, ShippingAddressForWishlistShippingFormFragmentDoc } from './components/WishlistShippingForm/WishlistShippingForm.generated';
import { WishlistForManageWishlistHeaderFragmentDoc } from '../components/ManageWishlistHeader/ManageWishlistHeader.generated';
import { WishlistForSetWishlistVisibilityPopoverFragmentDoc } from '../components/SetWishlistVisibilityPopover/SetWishlistVisibilityPopover.generated';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ArchiveWishlistForManageWishlistDetailsPageMutationVariables = Types.Exact<{
  wishlistId: Types.Scalars['WishlistId']['input'];
}>;


export type ArchiveWishlistForManageWishlistDetailsPageMutation = { __typename: 'Mutation', archiveWishlist: { __typename: 'ArchiveWishlistPayload', error?: { __typename: 'DefaultErrorMessage', errorMessage: string } | null, wishlist: { __typename: 'Wishlist', id: any, state: Types.WishlistState } } };

export type SetWishlistVisibilityForManageWishlistDetailsPageMutationVariables = Types.Exact<{
  wishlistId: Types.Scalars['WishlistId']['input'];
  visibility: Types.WishlistVisibility;
}>;


export type SetWishlistVisibilityForManageWishlistDetailsPageMutation = { __typename: 'Mutation', setWishlistVisibility: { __typename: 'SetWishlistVisibilityPayload', wishlist: { __typename: 'Wishlist', id: any, visibility: Types.WishlistVisibility } } };

export type WishlistForManageWishlistDetailsPageFragment = { __typename: 'Wishlist', id: any, visibility: Types.WishlistVisibility, title: string, friendlyId?: string | null, state: Types.WishlistState, giftCardItem?: { __typename: 'WishlistItem', id: any } | null, shippingAddress?: { __typename: 'Address', id: any, addresseeName?: string | null, line1?: string | null, line2?: string | null, city?: string | null, state?: string | null, postalCode: string, country?: string | null, phoneNumber?: string | null } | null };

export const WishlistForManageWishlistDetailsPageFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"WishlistForManageWishlistDetailsPage"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Wishlist"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"visibility"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"WishlistForWishlistGiftCardOption"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"WishlistForWishlistDetailsForm"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"WishlistForSetWishlistVisibilityField"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"WishlistForWishlistShippingForm"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"WishlistForManageWishlistHeader"}}]}}]} as unknown as DocumentNode;
export const ArchiveWishlistForManageWishlistDetailsPageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ArchiveWishlistForManageWishlistDetailsPage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"wishlistId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"WishlistId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"archiveWishlist"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"wishlistId"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"error"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ErrorMessage"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"errorMessage"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"wishlist"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"state"}}]}}]}}]}}]} as unknown as DocumentNode;
export type ArchiveWishlistForManageWishlistDetailsPageMutationFn = Apollo.MutationFunction<ArchiveWishlistForManageWishlistDetailsPageMutation, ArchiveWishlistForManageWishlistDetailsPageMutationVariables>;
export type ArchiveWishlistForManageWishlistDetailsPageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ArchiveWishlistForManageWishlistDetailsPageMutation, ArchiveWishlistForManageWishlistDetailsPageMutationVariables>, 'mutation'>;

    export const ArchiveWishlistForManageWishlistDetailsPageComponent = (props: ArchiveWishlistForManageWishlistDetailsPageComponentProps) => (
      <ApolloReactComponents.Mutation<ArchiveWishlistForManageWishlistDetailsPageMutation, ArchiveWishlistForManageWishlistDetailsPageMutationVariables> mutation={ArchiveWishlistForManageWishlistDetailsPageDocument} {...props} />
    );
    

/**
 * __useArchiveWishlistForManageWishlistDetailsPageMutation__
 *
 * To run a mutation, you first call `useArchiveWishlistForManageWishlistDetailsPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveWishlistForManageWishlistDetailsPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveWishlistForManageWishlistDetailsPageMutation, { data, loading, error }] = useArchiveWishlistForManageWishlistDetailsPageMutation({
 *   variables: {
 *      wishlistId: // value for 'wishlistId'
 *   },
 * });
 */
export function useArchiveWishlistForManageWishlistDetailsPageMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveWishlistForManageWishlistDetailsPageMutation, ArchiveWishlistForManageWishlistDetailsPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveWishlistForManageWishlistDetailsPageMutation, ArchiveWishlistForManageWishlistDetailsPageMutationVariables>(ArchiveWishlistForManageWishlistDetailsPageDocument, options);
      }
export type ArchiveWishlistForManageWishlistDetailsPageMutationHookResult = ReturnType<typeof useArchiveWishlistForManageWishlistDetailsPageMutation>;
export type ArchiveWishlistForManageWishlistDetailsPageMutationResult = Apollo.MutationResult<ArchiveWishlistForManageWishlistDetailsPageMutation>;
export type ArchiveWishlistForManageWishlistDetailsPageMutationOptions = Apollo.BaseMutationOptions<ArchiveWishlistForManageWishlistDetailsPageMutation, ArchiveWishlistForManageWishlistDetailsPageMutationVariables>;
export const SetWishlistVisibilityForManageWishlistDetailsPageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetWishlistVisibilityForManageWishlistDetailsPage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"wishlistId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"WishlistId"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"visibility"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"WishlistVisibility"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setWishlistVisibility"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"wishlistId"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"visibility"},"value":{"kind":"Variable","name":{"kind":"Name","value":"visibility"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"wishlist"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"visibility"}}]}}]}}]}}]} as unknown as DocumentNode;
export type SetWishlistVisibilityForManageWishlistDetailsPageMutationFn = Apollo.MutationFunction<SetWishlistVisibilityForManageWishlistDetailsPageMutation, SetWishlistVisibilityForManageWishlistDetailsPageMutationVariables>;
export type SetWishlistVisibilityForManageWishlistDetailsPageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetWishlistVisibilityForManageWishlistDetailsPageMutation, SetWishlistVisibilityForManageWishlistDetailsPageMutationVariables>, 'mutation'>;

    export const SetWishlistVisibilityForManageWishlistDetailsPageComponent = (props: SetWishlistVisibilityForManageWishlistDetailsPageComponentProps) => (
      <ApolloReactComponents.Mutation<SetWishlistVisibilityForManageWishlistDetailsPageMutation, SetWishlistVisibilityForManageWishlistDetailsPageMutationVariables> mutation={SetWishlistVisibilityForManageWishlistDetailsPageDocument} {...props} />
    );
    

/**
 * __useSetWishlistVisibilityForManageWishlistDetailsPageMutation__
 *
 * To run a mutation, you first call `useSetWishlistVisibilityForManageWishlistDetailsPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWishlistVisibilityForManageWishlistDetailsPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWishlistVisibilityForManageWishlistDetailsPageMutation, { data, loading, error }] = useSetWishlistVisibilityForManageWishlistDetailsPageMutation({
 *   variables: {
 *      wishlistId: // value for 'wishlistId'
 *      visibility: // value for 'visibility'
 *   },
 * });
 */
export function useSetWishlistVisibilityForManageWishlistDetailsPageMutation(baseOptions?: Apollo.MutationHookOptions<SetWishlistVisibilityForManageWishlistDetailsPageMutation, SetWishlistVisibilityForManageWishlistDetailsPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetWishlistVisibilityForManageWishlistDetailsPageMutation, SetWishlistVisibilityForManageWishlistDetailsPageMutationVariables>(SetWishlistVisibilityForManageWishlistDetailsPageDocument, options);
      }
export type SetWishlistVisibilityForManageWishlistDetailsPageMutationHookResult = ReturnType<typeof useSetWishlistVisibilityForManageWishlistDetailsPageMutation>;
export type SetWishlistVisibilityForManageWishlistDetailsPageMutationResult = Apollo.MutationResult<SetWishlistVisibilityForManageWishlistDetailsPageMutation>;
export type SetWishlistVisibilityForManageWishlistDetailsPageMutationOptions = Apollo.BaseMutationOptions<SetWishlistVisibilityForManageWishlistDetailsPageMutation, SetWishlistVisibilityForManageWishlistDetailsPageMutationVariables>;