import React from 'react'
import { useRouteMatch } from 'react-router'
import loadable from '@loadable/component'
import RouteLoader from '#src/app/components/RouteLoader'
import Page from '#src/app/components/Page'
import { NotFound } from '#src/app/components/Errors'
import { useUnavailableCategoryRouteQuery } from './UnavailableCategoryRoute.generated'

const PageComponent = loadable(
  () => import(/* webpackChunkName: "unavailable-category" */ './page'),
  {
    resolveComponent: page => page.UnavailableCategoryPage
  }
)

export const UnavailableCategoryRoute: React.FC = () => {
  const match = useRouteMatch<{ slug: string }>('/unavailable-category/:slug')

  const query = useUnavailableCategoryRouteQuery({
    variables: {
      categorySlug: match!.params.slug
    },
    skip: !match
  })

  if (!match) {
    return <NotFound />
  }

  return (
    <Page
      query={query}
      pageKey="unavailable-category"
      chat={true}
      seoTitle={({ categoryBySlug }) => categoryBySlug?.department?.displayName}
      seoIndexable={true}
      seoDescription={undefined}
      ogImageUrl={undefined}
      expert={undefined}
      category={({ categoryBySlug }) => categoryBySlug || undefined}
      department={({ categoryBySlug }) => categoryBySlug?.department || undefined}
      sellable={() => undefined}
      pageComponent={PageComponent}
      loadingComponent={RouteLoader}
    />
  )
}
