// @ts-nocheck
import type * as Types from '../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ScoutTransferToRealExpertMutationVariables = Types.Exact<{
  input: Types.TransferLeadToRealExpertInput;
}>;


export type ScoutTransferToRealExpertMutation = { __typename: 'Mutation', transferLeadToRealExpert: { __typename: 'UpdateLeadPayload', error?: string | null, lead?: { __typename: 'Lead', id: any, conversation: { __typename: 'Conversation', id: any } } | null } };


export const ScoutTransferToRealExpertDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ScoutTransferToRealExpert"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TransferLeadToRealExpertInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"transferLeadToRealExpert"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"lead"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"conversation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"error"}}]}}]}}]} as unknown as DocumentNode;
export type ScoutTransferToRealExpertMutationFn = Apollo.MutationFunction<ScoutTransferToRealExpertMutation, ScoutTransferToRealExpertMutationVariables>;
export type ScoutTransferToRealExpertComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ScoutTransferToRealExpertMutation, ScoutTransferToRealExpertMutationVariables>, 'mutation'>;

    export const ScoutTransferToRealExpertComponent = (props: ScoutTransferToRealExpertComponentProps) => (
      <ApolloReactComponents.Mutation<ScoutTransferToRealExpertMutation, ScoutTransferToRealExpertMutationVariables> mutation={ScoutTransferToRealExpertDocument} {...props} />
    );
    

/**
 * __useScoutTransferToRealExpertMutation__
 *
 * To run a mutation, you first call `useScoutTransferToRealExpertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScoutTransferToRealExpertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scoutTransferToRealExpertMutation, { data, loading, error }] = useScoutTransferToRealExpertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScoutTransferToRealExpertMutation(baseOptions?: Apollo.MutationHookOptions<ScoutTransferToRealExpertMutation, ScoutTransferToRealExpertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScoutTransferToRealExpertMutation, ScoutTransferToRealExpertMutationVariables>(ScoutTransferToRealExpertDocument, options);
      }
export type ScoutTransferToRealExpertMutationHookResult = ReturnType<typeof useScoutTransferToRealExpertMutation>;
export type ScoutTransferToRealExpertMutationResult = Apollo.MutationResult<ScoutTransferToRealExpertMutation>;
export type ScoutTransferToRealExpertMutationOptions = Apollo.BaseMutationOptions<ScoutTransferToRealExpertMutation, ScoutTransferToRealExpertMutationVariables>;