import { UserTraits } from '@deal/web-tracking/types'
import { MyselfFragment } from '../Identity/Myself.generated'

export default function userTraitsFromMyself(myself: MyselfFragment): UserTraits {
  if (!myself.user) {
    return {}
  }

  const { firstName, lastName, contacts, address } = myself.user

  const email = contacts.find(contact => contact.type.toUpperCase() === 'EMAIL')
  const phone = contacts.find(contact => contact.type.toUpperCase() === 'PHONE')
  const facebook = contacts.find(contact => contact.type.toUpperCase() === 'FACEBOOK')

  return {
    firstName: firstName || undefined,
    lastName: lastName || undefined,
    email: email ? email.value : undefined,
    phone: phone ? phone.value : undefined,
    facebook: facebook ? facebook.value : undefined,
    address: address
      ? {
          street: address.line1 || undefined,
          city: address.city || undefined,
          state: address.state || undefined,
          postalCode: address.postalCode || undefined
        }
      : undefined
  }
}
