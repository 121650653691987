import React from 'react'
import { toast } from 'react-toastify'
import { useHistory, usePageKey } from '@deal/router'
import {
  CreatePathFromSellableProps,
  CreateRequestPathProps,
  convertPromoIdToAttrInput,
  formatPathUrl,
  getOnboardingPathSlug,
  useCreateRequestPath,
  useCreateRequestPathFromSellable
} from '#src/app/services/path'
import { ArticleForConversationStartersV2Fragment } from '../EngagementModal/ArticleConversationStarterTemplate/ConversationStarterTemplate.generated'
import {
  CategoryForEngagementChannelsFragment,
  PageTemplateResolveResultForEngagementChannelsFragment,
  PromotionForEngagementChannelsFragment,
  SellableForEngagementChannelsFragment
} from '../EngagementChannels.generated'
import EngagementModal from '../EngagementModal'

export interface EngagementChannelsPathSubmit {
  onSubmit: () => void
  pathLoading: boolean
}

interface EngagementChannelsSharedProps {
  sellable?: SellableForEngagementChannelsFragment
  category?: CategoryForEngagementChannelsFragment
  pathSchemaSlug?: string
}

interface EngagementChannelsHandlerProps extends EngagementChannelsSharedProps {
  variation?: string
  pathSchemaId?: string
  promotion?: PromotionForEngagementChannelsFragment | null
  pageTemplateData?: PageTemplateResolveResultForEngagementChannelsFragment | null
  articleConversationStarters?: ArticleForConversationStartersV2Fragment
}

const useCreatePath = (value: {
  category?: CategoryForEngagementChannelsFragment
  sellable?: SellableForEngagementChannelsFragment
  promotionId?: string
  variation?: string
  pathSchemaId?: string
  pathSchemaSlug?: string
}) => {
  const { category, sellable, promotionId, variation, pathSchemaId, pathSchemaSlug } = value

  const history = useHistory()
  const pageKey = usePageKey()

  const [createPathFromSellable, { loading: pathFromSellableLoading }] =
    useCreateRequestPathFromSellable({
      onPathCreated: ({ pathGraphFlow, pathGraphFlowSlug, nodeSlugOrId }) => {
        if (pathGraphFlow) {
          history.push(
            formatPathUrl({
              pathGraphFlowId: pathGraphFlow?.pathGraphFlowId,
              nodeSlugOrId,
              pathGraphFlowSlug
            })
          )
        } else {
          throw new Error('Error creating path for `EngagementChannelsHandler` component!')
        }
      },
      onError: ({ errorMessage }) => toast.error(errorMessage)
    })

  const [createPath, { loading }] = useCreateRequestPath({
    onPathCreated: ({ pathGraphFlow, pathGraphFlowSlug, nodeSlugOrId }) => {
      if (pathGraphFlow) {
        history.push(
          formatPathUrl({
            pathGraphFlowId: pathGraphFlow.pathGraphFlowId,
            nodeSlugOrId,
            pathGraphFlowSlug
          })
        )
      } else {
        throw new Error('Error creating path for `EngagementChannelsHandler` component!')
      }
    },
    onError: ({ errorMessage }) => toast.error(errorMessage)
  })

  const pathLoading = pathFromSellableLoading || loading

  const initialAttributes = convertPromoIdToAttrInput(promotionId)

  const getCreatePathFromSellableProps = (
    sellable: SellableForEngagementChannelsFragment,
    sourceKey: string
  ): CreatePathFromSellableProps => ({
    sourceKey,
    sellable,
    pageVariation: variation,
    initialAttributes
  })

  const getCreatePathProps = (
    engagementChannel: string,
    sourceKey: string
  ): CreateRequestPathProps => ({
    pathSelector: pathSchemaSlug
      ? { pathSchemaSlug }
      : pathSchemaId
      ? { pathSchemaId }
      : category
      ? { categorySlugHierarchyLookup: category.slug }
      : { pathSchemaSlug: getOnboardingPathSlug() },
    trackingCodes: {
      ctaName: `${engagementChannel}-submit-button`,
      pageVariation: variation,
      sourceKey
    },
    initialAttributes
  })

  const onSubmit = (engagementChannel: string) => {
    const sourceKey = `engagement-channel-${engagementChannel}-${pageKey}`
    if (!pathSchemaSlug && !pathSchemaId && !!sellable) {
      createPathFromSellable(getCreatePathFromSellableProps(sellable, sourceKey))
    } else {
      createPath(getCreatePathProps(engagementChannel, sourceKey))
    }
  }

  return { onSubmit, pathLoading, getCreatePathFromSellableProps, getCreatePathProps }
}

const EngagementChannelsHandler: React.FC<EngagementChannelsHandlerProps> = ({
  category,
  sellable,
  variation,
  pathSchemaSlug,
  promotion,
  pathSchemaId,
  pageTemplateData,
  articleConversationStarters
}) => {
  const pageKey = usePageKey()

  const { getCreatePathFromSellableProps, getCreatePathProps } = useCreatePath({
    variation,
    pathSchemaId,
    pathSchemaSlug,
    category,
    sellable,
    promotionId: promotion?.id
  })

  if (pageTemplateData?.componentViews) {
    const engagementModalSourceKey = `engagement-channel-path-modal-${pageKey}`

    return (
      <EngagementModal
        articleConversationStarters={articleConversationStarters}
        pageTemplateRevisionId={pageTemplateData.pageTemplate.id}
        engagementModalType={pageTemplateData.pageTemplate.engagementModalType}
        categoryId={category?.id}
        promotion={promotion}
        componentView={pageTemplateData.componentViews[0]}
        createPathFromSellableProps={
          !pathSchemaSlug && !pathSchemaId && !!sellable
            ? sellable && getCreatePathFromSellableProps(sellable, engagementModalSourceKey)
            : undefined
        }
        createPathProps={getCreatePathProps('path-modal', engagementModalSourceKey)}
      />
    )
  } else {
    return null
  }
}

export default EngagementChannelsHandler
