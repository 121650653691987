import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router'
import mapValues from 'lodash/mapValues'
import keyBy from 'lodash/keyBy'
import { useExperiment } from '@deal/experiment-js'
import { EngagementModalType } from '#src/generated/types'
import {
  CreatePathFromSellableProps,
  CreateRequestPathProps,
  useCreateRequestPath,
  useCreateRequestPathFromSellable
} from '#src/app/services/path'
import { ComponentValue } from '#src/app/services/pageTemplate'
import loggerClient from '#src/app/services/loggerClient'
import clientOnly from '#src/app/hocs/clientOnly'
import { useEngagementChannelBannerContext } from '#src/app/context/engagement-channel-banner'
import { useCookieContext } from '#src/app/context/cookies'
import DisplayTriggerWrapper from '#src/app/components/EngagementChannels/components/DisplayTriggerWrapper'
import { usePdpEngagementModalDismissedAtom } from '#src/app/atoms/usePdpEngagementModalDismissedAtom'
import { useArticleEngagementModalDismissedAtom } from '#src/app/atoms/useArticleEngagementModalDismissedAtom'
import {
  PageTemplateComponentValueForEngagementChannelsFragment,
  PageTemplateComponentViewForEngagementChannelsFragment,
  PromotionForEngagementChannelsFragment
} from '../EngagementChannels.generated'
import { ArticleForConversationStartersV2Fragment } from './ArticleConversationStarterTemplate/ConversationStarterTemplate.generated'
import { useEngagementChannelTrackingContext } from '../components/EngagementChannelTracking'
import SMSModalTemplate from './SMSModalTemplate'
import PathModalTemplate from './PathModalTemplate'
import OnSiteSMSModalTemplate from './OnSiteSMSModalTemplate'
import EmailModalTemplate from './EmailModalTemplate'
import ArticleConversationStarterTemplate from './ArticleConversationStarterTemplate'

const getParameterValue = (
  componentValue: PageTemplateComponentValueForEngagementChannelsFragment
): ComponentValue | {} => {
  switch (componentValue.parameterValue?.__typename) {
    case 'PageTemplateComponentFieldValueText':
      return {
        text: componentValue.parameterValue.text,
        textColor: componentValue.parameterValue.color?.value || undefined,
        fontStyles: componentValue.parameterValue.fontStyles
      }
    case 'PageTemplateComponentFieldValueImage':
      return {
        imageUrl: componentValue.parameterValue.media?.url,
        altText: componentValue.parameterValue?.altText
      }
    case 'PageTemplateComponentFieldValueButton':
      return {
        text: componentValue.parameterValue.text,
        buttonType: componentValue.parameterValue.styleDisplayName || undefined
      }
    case 'PageTemplateComponentFieldValueNumber':
      return {
        number: componentValue.parameterValue.number || undefined
      }
    case 'PageTemplateComponentFieldValueEnum':
      return { triggerEnumValue: componentValue.parameterValue.value || 'default' }
    case 'PageTemplateComponentFieldValueMarkdown':
      return { markdownText: componentValue.parameterValue.value }
    default:
      return {}
  }
}

export const generateComponentValue = (
  componentValues: PageTemplateComponentValueForEngagementChannelsFragment[]
) => {
  return mapValues(keyBy(componentValues, 'parameterName'), componentValue => ({
    ...getParameterValue(componentValue)
  }))
}
export interface EngagementModalProps {
  promotion?: PromotionForEngagementChannelsFragment | null
  componentView: PageTemplateComponentViewForEngagementChannelsFragment
  createPathFromSellableProps?: CreatePathFromSellableProps
  createPathProps: CreateRequestPathProps
  engagementModalType?: EngagementModalType | null
  categoryId?: string
  pageTemplateRevisionId: string
  articleConversationStarters?: ArticleForConversationStartersV2Fragment
}

enum ParamName {
  DisplayTrigger = 'Display Trigger',
  TimeDelay = 'Time Delay'
}

const PATH_MODAL_COOKIE = 'path_modal_viewed'
const SMS_MODAL_COOKIE = 'sms_modal_viewed'
const ON_SITE_SMS_MODAL_COOKIE = 'on_site_sms_modal_viewed'
const EMAIL_MODAL_COOKIE = 'email_modal_viewed'
const CONVO_STARTER_MODAL_COOKIE = 'convo_starter_modal_viewed'

const EngagementModal: React.FC<EngagementModalProps> = ({
  createPathProps,
  createPathFromSellableProps,
  promotion,
  componentView,
  engagementModalType,
  categoryId,
  pageTemplateRevisionId,
  articleConversationStarters
}) => {
  const { getCookie, setCookie } = useCookieContext()
  const ecTracking = useEngagementChannelTrackingContext()
  const hasPathModalBeenViewed = getCookie(PATH_MODAL_COOKIE)
  const hasSMSModalBeenViewed = getCookie(SMS_MODAL_COOKIE)
  const hasOnSiteSMSModalBeenViewed = getCookie(ON_SITE_SMS_MODAL_COOKIE)
  const hasEmailModalBeenViewed = getCookie(EMAIL_MODAL_COOKIE)
  const hasConvoStarterModalBeenViewed = getCookie(CONVO_STARTER_MODAL_COOKIE)
  const location = useLocation()

  const { showBanner } = useEngagementChannelBannerContext()
  const [defaultSubmitToken, setDefaultSubmitToken] = useState<string>()

  const setPathModalViewedCookie = () =>
    setCookie(PATH_MODAL_COOKIE, 'true', {
      expires: 1
    })

  const setSMSModalViewedCookie = () => {
    setCookie(SMS_MODAL_COOKIE, 'true', {
      expires: 1
    })
  }
  const setOnSiteSMSModalViewedCookie = () => {
    setCookie(ON_SITE_SMS_MODAL_COOKIE, 'true', {
      expires: 1
    })
  }
  const setEmailModalViewedCookie = () => {
    setCookie(EMAIL_MODAL_COOKIE, 'true', {
      expires: 1
    })
  }

  const setConvoStarterlModalViewedCookie = () => {
    setCookie(CONVO_STARTER_MODAL_COOKIE, 'true', {
      expires: 1
    })
  }

  const [createPathFromSellable, { loading: pathFromSellableLoading }] =
    useCreateRequestPathFromSellable({
      onPathCreated: ({ pathGraphFlow }) => setDefaultSubmitToken(pathGraphFlow?.submitToken),
      onError: ({ errorMessage }) => toast.error(errorMessage)
    })

  const [createPath, { loading }] = useCreateRequestPath({
    onPathCreated: ({ pathGraphFlow }) => setDefaultSubmitToken(pathGraphFlow?.submitToken),
    onError: ({ errorMessage }) => toast.error(errorMessage)
  })

  const pathLoading = pathFromSellableLoading || loading

  const componentValue = generateComponentValue(componentView.pageComponentContent.componentValues)

  const [, setPdpEngagementModalDismissed] = usePdpEngagementModalDismissedAtom()
  const [, setArticleEngagementModalDismissed] = useArticleEngagementModalDismissedAtom()

  const setEngagementModalDismissedAtom = () => {
    // Only track pdp engagement modal dismiss
    if (location.pathname.includes('/products/')) {
      setPdpEngagementModalDismissed(true)
    }
    if (location.pathname.includes('/journal/')) {
      setArticleEngagementModalDismissed(true)
    }
  }

  const isOnSiteSMSModalEnabled =
    useExperiment({
      experiment: 'on-site-sms-modal',
      defaultTreatment: 'control',
      options: { skip: hasOnSiteSMSModalBeenViewed === 'true' }
    }).result === 'enabled'

  const OnSiteSMSModal = (
    <DisplayTriggerWrapper
      displayTrigger={'second-page'}
      timeDelay={componentValue[ParamName.TimeDelay]?.number}
      engagementChannel="on-site-sms-modal"
    >
      {({
        showEngagementChannel: showEngagementModal,
        closeEngagementChannel: closeEngagementModal
      }) => {
        const onDismissSMSModal = () => {
          ecTracking?.engagementChannelDismissed('on-site-sms-modal')
          setOnSiteSMSModalViewedCookie()
          closeEngagementModal()
          showBanner()
        }

        const onSubmitSMSModal = () => {
          ecTracking?.engagementChannelSubmitted('on-site-sms-modal')
          setOnSiteSMSModalViewedCookie()
          closeEngagementModal()
        }

        if (categoryId) {
          return (
            <OnSiteSMSModalTemplate
              isOpen={showEngagementModal}
              onDismiss={onDismissSMSModal}
              onSubmit={onSubmitSMSModal}
              categoryId={categoryId}
              pageTemplateRevisionId={pageTemplateRevisionId}
            />
          )
        } else {
          loggerClient.captureError(
            new Error('Attempted to render on site sms modal without a category')
          )
          return null
        }
      }}
    </DisplayTriggerWrapper>
  )

  if (articleConversationStarters) {
    if (hasConvoStarterModalBeenViewed) {
      setEngagementModalDismissedAtom()
      return null
    }
    return (
      <DisplayTriggerWrapper
        displayTrigger={componentValue[ParamName.DisplayTrigger]?.triggerEnumValue || 'default'}
        timeDelay={componentValue[ParamName.TimeDelay]?.number}
        engagementChannel="conversation-starter-modal"
      >
        {({
          showEngagementChannel: showEngagementModal,
          closeEngagementChannel: closeEngagementModal
        }) => {
          const onDismissModal = () => {
            setEngagementModalDismissedAtom()
            ecTracking?.engagementChannelDismissed('conversation-starter-modal')
            setConvoStarterlModalViewedCookie()
            closeEngagementModal()
            showBanner()
          }

          const onSubmitModal = () => {
            setEngagementModalDismissedAtom()
            ecTracking?.engagementChannelSubmitted('conversation-starter-modal')
            setEmailModalViewedCookie()
          }

          return (
            <ArticleConversationStarterTemplate
              onDismiss={onDismissModal}
              onSubmit={onSubmitModal}
              isOpen={showEngagementModal}
              article={articleConversationStarters}
            />
          )
        }}
      </DisplayTriggerWrapper>
    )
  } else if (engagementModalType === EngagementModalType.SMS) {
    if (hasSMSModalBeenViewed) {
      setEngagementModalDismissedAtom()
      return null
    }
    return (
      <DisplayTriggerWrapper
        displayTrigger={componentValue[ParamName.DisplayTrigger]?.triggerEnumValue || 'default'}
        timeDelay={componentValue[ParamName.TimeDelay]?.number}
        engagementChannel="prefill-sms-modal"
      >
        {({
          showEngagementChannel: showEngagementModal,
          closeEngagementChannel: closeEngagementModal
        }) => {
          const onDismissSMSModal = () => {
            setEngagementModalDismissedAtom()
            ecTracking?.engagementChannelDismissed('prefill-sms-modal')
            setSMSModalViewedCookie()
            closeEngagementModal()
            showBanner()
          }

          const onSubmitSMSModal = () => {
            setEngagementModalDismissedAtom()
            ecTracking?.engagementChannelSubmitted('prefill-sms-modal')
            setSMSModalViewedCookie()
          }

          if (categoryId) {
            return (
              <SMSModalTemplate
                isOpen={showEngagementModal}
                onDismiss={onDismissSMSModal}
                onSubmit={onSubmitSMSModal}
                componentValue={componentValue}
                categoryId={categoryId}
                pageTemplateRevisionId={pageTemplateRevisionId}
              />
            )
          } else {
            loggerClient.captureError(new Error('Attempted to render sms modal without a category'))
            return null
          }
        }}
      </DisplayTriggerWrapper>
    )
  } else if (engagementModalType === EngagementModalType.ON_SITE_SMS) {
    if (hasOnSiteSMSModalBeenViewed) {
      return null
    }
    return OnSiteSMSModal
  } else if (engagementModalType === EngagementModalType.PATH) {
    if (hasPathModalBeenViewed) {
      setEngagementModalDismissedAtom()
      if (isOnSiteSMSModalEnabled) {
        return OnSiteSMSModal
      }
      return null
    }
    return (
      <DisplayTriggerWrapper
        displayTrigger={componentValue[ParamName.DisplayTrigger]?.triggerEnumValue || 'default'}
        timeDelay={componentValue[ParamName.TimeDelay]?.number}
        engagementChannel="path-modal"
      >
        {({
          showEngagementChannel: showEngagementModal,
          closeEngagementChannel: closeEngagementModal
        }) => {
          const onDismissPathModal = () => {
            setEngagementModalDismissedAtom()
            ecTracking?.engagementChannelDismissed('path-modal')
            showBanner()
            setPathModalViewedCookie()
            closeEngagementModal()
            setDefaultSubmitToken(undefined)
          }

          const onSubmitPathModal = () => {
            setEngagementModalDismissedAtom()
            ecTracking?.engagementChannelSubmitted('path-modal')
            if (createPathFromSellableProps) {
              createPathFromSellable(createPathFromSellableProps)
            } else {
              createPath(createPathProps)
            }
          }

          const onReturnToPreviousContext = () => {
            setPathModalViewedCookie()
            closeEngagementModal()
            setDefaultSubmitToken(undefined)
          }
          return (
            <PathModalTemplate
              isOpen={showEngagementModal}
              componentValue={componentValue}
              onDismiss={onDismissPathModal}
              onSubmit={onSubmitPathModal}
              promotion={promotion}
              pathLoading={pathLoading}
              defaultSubmitToken={defaultSubmitToken}
              onReturnToPreviousContext={onReturnToPreviousContext}
            />
          )
        }}
      </DisplayTriggerWrapper>
    )
  } else if (engagementModalType === EngagementModalType.EMAIL) {
    if (hasEmailModalBeenViewed) {
      setEngagementModalDismissedAtom()
      return null
    }
    return (
      <DisplayTriggerWrapper
        displayTrigger={componentValue[ParamName.DisplayTrigger]?.triggerEnumValue || 'default'}
        timeDelay={componentValue[ParamName.TimeDelay]?.number}
        engagementChannel="guest-email-modal"
      >
        {({
          showEngagementChannel: showEngagementModal,
          closeEngagementChannel: closeEngagementModal
        }) => {
          const onDismissEmailModal = () => {
            setEngagementModalDismissedAtom()
            ecTracking?.engagementChannelDismissed('guest-email-modal')
            setEmailModalViewedCookie()
            closeEngagementModal()
            showBanner()
          }

          const onSubmitEmailModal = () => {
            setEngagementModalDismissedAtom()
            ecTracking?.engagementChannelSubmitted('guest-email-modal')
            setEmailModalViewedCookie()
          }

          if (categoryId) {
            return (
              <EmailModalTemplate
                onDismiss={onDismissEmailModal}
                onSubmit={onSubmitEmailModal}
                isOpen={showEngagementModal}
                pageTemplateRevisionId={pageTemplateRevisionId}
                componentValue={componentValue}
                promotion={promotion}
              />
            )
          } else {
            loggerClient.captureError(
              new Error('Attempted to render guest email modal without a category')
            )
            return null
          }
        }}
      </DisplayTriggerWrapper>
    )
  } else {
    return null
  }
}

export default clientOnly(EngagementModal)
