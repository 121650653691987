import React, { useEffect } from 'react'
import { useLocation } from '@deal/router'
import { usePrevious } from '@deal/dom-hooks'

export const ScrollToTop: React.FC<React.PropsWithChildren> = ({ children }) => {
  const location = useLocation()
  const prevLocation = usePrevious(location)

  useEffect(() => {
    const shouldNotResetScroll = location.state && !(location.state as any).resetScroll
    const containsHash = !!location.hash

    // Do not reset scroll if the new location state explicitly sets "resetScroll" to false or has a hash
    if (shouldNotResetScroll || containsHash) {
      return
    }

    if (prevLocation?.pathname !== location.pathname) {
      document.body.scrollTo(0, 0)
    }
  }, [location.state, location.hash, location.pathname, prevLocation?.pathname])

  return <>{children}</>
}
