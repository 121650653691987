import React, { memo } from 'react'
import loadable from '@loadable/component'
import { useBreakpoint } from '#src/app/hooks/useBreakpoint'
import { CategoryForGetClosestNavigationForCategoryFragment } from './mobile/components/MobileNavFlyout/MobileNavFlyout.generated'

const HeaderDesktop = loadable(() => import(/* webpackChunkName: 'header-desktop' */ './desktop'), {
  resolveComponent: page => page.HeaderDesktop
})
const HeaderMobile = loadable(() => import(/* webpackChunkName: 'header-mobile' */ './mobile'), {
  resolveComponent: page => page.HeaderMobile
})

export interface HeaderProps {
  className?: string
  category?: CategoryForGetClosestNavigationForCategoryFragment
  onHeaderHeightChanged: (height: number) => void
}

const Header: React.FC<HeaderProps> = props => {
  const isLargerThanMd = useBreakpoint('md')

  if (isLargerThanMd) {
    return <HeaderDesktop {...props} />
  }

  return <HeaderMobile {...props} />
}

const MemoHeader = memo(Header)

export default MemoHeader
