import React, { useEffect } from 'react'
import usePreferredExpert from '#src/app/hooks/usePreferredExpert'
import { useCurrentExpert } from '#src/app/context/expert'
import { useGetBusinessUserForChatByCategoryIdQuery } from './GetBusinessUserForChatByCategoryId.generated'
import Chrome, { ChromeProps } from '../Chrome'

interface ChromeWithCategorySidebarProps extends Omit<ChromeProps, 'conversation'> {
  categoryId: string
}

const ChromeWithCategorySidebar: React.FC<React.PropsWithChildren<ChromeWithCategorySidebarProps>> = ({
  categoryId,
  chat = true,
  ...props
}) => {
  const { setExpert } = useCurrentExpert()
  const preferredExpertVanityId = usePreferredExpert()
  const { data } = useGetBusinessUserForChatByCategoryIdQuery({
    variables: {
      categoryId,
      preferredExpertVanityId
    }
  })

  // Set the expert for the chat UI's once data is ready.
  useEffect(() => {
    if (data) {
      setExpert(data.category?.recommendExpertForConsumer.expert || undefined)
    }
  }, [data])

  return <Chrome chat={chat} {...props} />
}

/**
 * The public API of this component takes an optional category ID, purely for ergonomics. If a category
 *   ID isn't provided, the chat UIs are not rendered. This is done so in cases where a category ID
 *   isn't guaranteed we don't need to duplicate this fallback logic.
 */
interface ChromeWithOptionalCategorySidebarProps
  extends Omit<ChromeWithCategorySidebarProps, 'categoryId'> {
  categoryId?: string
}

const ChromeWithOptionalCategorySidebar: React.FC<React.PropsWithChildren<ChromeWithOptionalCategorySidebarProps>> = ({
  categoryId,
  ...props
}) => {
  if (!categoryId) {
    return <Chrome chat={false} {...props} />
  }

  return <ChromeWithCategorySidebar categoryId={categoryId} {...props} />
}

export default ChromeWithOptionalCategorySidebar
