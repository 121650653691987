import React from 'react'
import { Button, ButtonProps } from '@deal/bluxome'
import { useScoutTransferToRealExpert } from '#src/app/hooks/useSwitchToRealExpert'
import {
  BusinessUserForSwitchToRealExpertButtonFragment,
  ConversationForSwitchToRealExpertButtonFragment,
  LeadForSwitchToRealExpertButtonFragment,
  useConsumerLeadChangedForSwitchToRealExpertButtonSubscription
} from './SwitchToRealExpertButton.generated'

// One of either `lead`, `expert`, or `conversation` is required to determine the
// lead ID and whether an AI placeholder expert is currently attached to the conversation
type SwitchToRealExpertButtonVariableProps = DiscriminatedUnion<
  { lead: LeadForSwitchToRealExpertButtonFragment },
  { expert: BusinessUserForSwitchToRealExpertButtonFragment },
  { conversation: ConversationForSwitchToRealExpertButtonFragment }
>

type SwitchToRealExpertButtonProps = SwitchToRealExpertButtonVariableProps &
  Partial<Pick<ButtonProps, 'size' | 'style' | 'color' | 'isInverted'>>

export const SwitchToRealExpertButton: React.FC<SwitchToRealExpertButtonProps> = ({
  style = 'text',
  color = 'neutral-light',
  size = 'small',
  isInverted,
  ...props
}) => {
  const { switchToRealExpert, loading: transferLoading } = useScoutTransferToRealExpert()
  const leadId = getLeadIdFromProps(props)
  const { data, loading: subscriptionLoading } =
    useConsumerLeadChangedForSwitchToRealExpertButtonSubscription({
      variables: { leadId },
      skip: !leadId || !isAiChatWithPlaceholder(props.lead || props.expert || props.conversation)
    })

  if (
    isAiChatWithPlaceholder(
      data?.consumerLeadChanged.lead || props.lead || props.expert || props.conversation
    )
  ) {
    return (
      <Button
        color={color}
        style={style}
        size={size}
        isInverted={isInverted}
        isLoading={transferLoading || subscriptionLoading}
        isDisabled={!leadId}
        onPress={() => {
          if (leadId) switchToRealExpert({ leadId })
        }}
      >
        Switch to Real Expert
      </Button>
    )
  }
  return null
}

function getLeadIdFromProps({ lead, expert, conversation }: SwitchToRealExpertButtonVariableProps) {
  return lead?.id || expert?.existingLeadForConsumer?.id || conversation?.lead?.id
}

function isAiChatWithPlaceholder(
  entity:
    | LeadForSwitchToRealExpertButtonFragment
    | BusinessUserForSwitchToRealExpertButtonFragment
    | ConversationForSwitchToRealExpertButtonFragment
    | null = null
) {
  let isAiChatV2: boolean | undefined,
    isAiGuestChat: boolean | undefined,
    placeholderExpert: boolean | undefined

  switch (entity?.__typename) {
    case 'Lead':
      isAiChatV2 = entity.conversation.isAiChatV2
      isAiGuestChat = entity.conversation.isAiGuestChat
      placeholderExpert = entity.expert.expertAttributes.placeholderExpert
      break

    case 'BusinessUser':
      isAiChatV2 = entity.existingLeadForConsumer?.conversation.isAiChatV2
      isAiGuestChat = entity.existingLeadForConsumer?.conversation.isAiGuestChat
      placeholderExpert = entity.expertAttributes.placeholderExpert
      break

    case 'Conversation':
      isAiChatV2 = entity.isAiChatV2
      isAiGuestChat = entity.isAiGuestChat
      placeholderExpert = entity.lead?.expert.expertAttributes.placeholderExpert
      break
  }

  return (isAiChatV2 || isAiGuestChat) && placeholderExpert
}
