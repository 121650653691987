// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { VariationMatrixForButtonMatrixFragmentDoc, VariationMatrixDimensionForButtonMatrixDimensionFragmentDoc, SellableSizeGuideForButtonMatrixFragmentDoc, SellableForButtonMatrixFragmentDoc } from './ButtonMatrix/ButtonMatrix.generated';
import { SellableForImageVectorFragmentDoc } from './ButtonMatrix/ImageVectorSelector/ImageVectorSelector.generated';
import { MediaFileFragmentDoc } from '../../fragments/MediaFile.generated';
import { SellableForFitDescriptorFragmentDoc } from './ButtonMatrix/FitDescriptor/FitDescriptor.generated';
import { SellableUrlFragmentDoc } from '../../fragments/SellableUrl.generated';
export type SellableForVariationMatrixFragment = { __typename: 'Sellable', id: any, active: boolean, purchasable: boolean, variantParent: boolean, variant: boolean, parent?: { __typename: 'Sellable', id: any } | null, variationMatrix?: { __typename: 'VariationMatrix', dimensions: Array<{ __typename: 'VariationMatrixDimension', name: string, displayName?: string | null, vectors: Array<{ __typename: 'VariationMatrixVector', sellableIds: Array<any>, value: string, displayValueHtml: string }> }>, outOfStockSellables: Array<{ __typename: 'Sellable', id: any, availableForPreOrder: boolean }>, sellables: Array<{ __typename: 'Sellable', id: any, friendlyId?: string | null, slug: string, type: Types.SellableType, variant: boolean, primaryImage?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null, brand?: { __typename: 'Brand', id: any, name: string, slug: string } | null }> } | null, sizeGuides: Array<{ __typename: 'ArticleRevision', id: any, friendlyId: string, slug: string }>, categories: Array<{ __typename: 'Category', id: any, slug: string }>, brand?: { __typename: 'Brand', id: any, name: string, slug: string } | null };

export type SellableForVariationMatrixSelectedSellableFragment = { __typename: 'Sellable', id: any, variant: boolean, brand?: { __typename: 'Brand', id: any, name: string, slug: string } | null };

export type SellableForBuildSelectionTableFragment = { __typename: 'Sellable', id: any, variant: boolean };

export type VariationMatrixForBuildSelectionTableFragment = { __typename: 'VariationMatrix', dimensions: Array<{ __typename: 'VariationMatrixDimension', name: string, vectors: Array<{ __typename: 'VariationMatrixVector', sellableIds: Array<any>, value: string }> }>, outOfStockSellables: Array<{ __typename: 'Sellable', id: any, availableForPreOrder: boolean }>, sellables: Array<{ __typename: 'Sellable', id: any, friendlyId?: string | null, slug: string, type: Types.SellableType, variant: boolean, brand?: { __typename: 'Brand', id: any, name: string, slug: string } | null }> };

export const SellableForBuildSelectionTableFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableForBuildSelectionTable"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Sellable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"variant"}}]}}]} as unknown as DocumentNode;
export const SellableForVariationMatrixSelectedSellableFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableForVariationMatrixSelectedSellable"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Sellable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForBuildSelectionTable"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForButtonMatrix"}}]}}]} as unknown as DocumentNode;
export const VariationMatrixForBuildSelectionTableFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"VariationMatrixForBuildSelectionTable"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"VariationMatrix"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dimensions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"vectors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sellableIds"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"outOfStockSellables"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"availableForPreOrder"}}]}},{"kind":"Field","name":{"kind":"Name","value":"sellables"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableUrl"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForVariationMatrixSelectedSellable"}}]}}]}}]} as unknown as DocumentNode;
export const SellableForVariationMatrixFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SellableForVariationMatrix"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Sellable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"active"}},{"kind":"Field","name":{"kind":"Name","value":"purchasable"}},{"kind":"Field","name":{"kind":"Name","value":"variantParent"}},{"kind":"Field","name":{"kind":"Name","value":"parent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForVariationMatrixSelectedSellable"}},{"kind":"Field","name":{"kind":"Name","value":"variationMatrix"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"VariationMatrixForBuildSelectionTable"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"VariationMatrixForButtonMatrix"}}]}},{"kind":"Field","name":{"kind":"Name","value":"sizeGuides"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"limit"},"value":{"kind":"IntValue","value":"1"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableSizeGuideForButtonMatrix"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForFitDescriptor"}}]}}]} as unknown as DocumentNode;