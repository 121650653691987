import React, { ForwardRefRenderFunction } from 'react'
import classnames from 'classnames'
import styles from './styles.css'

interface CompareTableRowData {
  sellableId: string
  cell: React.ReactNode
}

export interface CompareTableRowProps {
  header: React.ReactNode
  stickyHeader?: 'left' | 'center' | 'none'
  data: CompareTableRowData[]
  className?: string
  firstDataColumnDropshadow?: boolean
}

const CompareTableRowRenderFunction: ForwardRefRenderFunction<
  HTMLTableRowElement,
  CompareTableRowProps
> = (
  { header, data, className, stickyHeader = 'none', firstDataColumnDropshadow = true },
  tableRowRef
) => {
  return (
    <tr
      ref={tableRowRef}
      className={classnames(styles.compareTableRow, className, {
        [styles.emptyDataCells]: data.every(({ cell }) => !cell)
      })}
    >
      <th
        scope="row"
        className={classnames(styles.compareTableHeader, {
          [styles.stickyLeft]: stickyHeader === 'left',
          [styles.stickyCenter]: stickyHeader === 'center'
        })}
      >
        {header}
      </th>
      {data.map(({ sellableId, cell }, idx) => (
        <td
          key={`${sellableId}-${idx}`}
          className={classnames(styles.compareTableData, {
            [styles.firstDataColumnDropshadow]: firstDataColumnDropshadow
          })}
        >
          {cell}
        </td>
      ))}
    </tr>
  )
}

export const CompareTableRow = React.forwardRef(CompareTableRowRenderFunction)
