// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { CategoryForCategoryOrProductListingPageFragmentDoc } from '../../services/search/CategoryForCategoryOrProductListingPage.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CategoryNavigationDecoratedGroupsForFooterQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CategoryNavigationDecoratedGroupsForFooterQuery = { __typename: 'Query', formattedCategoryNavigationsByGroup: Array<{ __typename: 'CategoryNavigationDecoratedGroup', slug: string, label: string, navigationRevisions: Array<{ __typename: 'FormattedCategoryNavigationRevision', id: string, displayLabel: string, category?: { __typename: 'Category', id: any, slug: string, pageLayoutType: Types.CategoryPageLayoutType } | null }> }> };


export const CategoryNavigationDecoratedGroupsForFooterDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CategoryNavigationDecoratedGroupsForFooter"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"formattedCategoryNavigationsByGroup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"clientProfiles"},"value":{"kind":"ListValue","values":[{"kind":"EnumValue","value":"DESKTOP"}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"slug"}},{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"navigationRevisions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayLabel"}},{"kind":"Field","name":{"kind":"Name","value":"category"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CategoryForCategoryOrProductListingPage"}}]}}]}}]}}]}},...CategoryForCategoryOrProductListingPageFragmentDoc.definitions]} as unknown as DocumentNode;
export type CategoryNavigationDecoratedGroupsForFooterComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CategoryNavigationDecoratedGroupsForFooterQuery, CategoryNavigationDecoratedGroupsForFooterQueryVariables>, 'query'>;

    export const CategoryNavigationDecoratedGroupsForFooterComponent = (props: CategoryNavigationDecoratedGroupsForFooterComponentProps) => (
      <ApolloReactComponents.Query<CategoryNavigationDecoratedGroupsForFooterQuery, CategoryNavigationDecoratedGroupsForFooterQueryVariables> query={CategoryNavigationDecoratedGroupsForFooterDocument} {...props} />
    );
    

/**
 * __useCategoryNavigationDecoratedGroupsForFooterQuery__
 *
 * To run a query within a React component, call `useCategoryNavigationDecoratedGroupsForFooterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryNavigationDecoratedGroupsForFooterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryNavigationDecoratedGroupsForFooterQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoryNavigationDecoratedGroupsForFooterQuery(baseOptions?: Apollo.QueryHookOptions<CategoryNavigationDecoratedGroupsForFooterQuery, CategoryNavigationDecoratedGroupsForFooterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryNavigationDecoratedGroupsForFooterQuery, CategoryNavigationDecoratedGroupsForFooterQueryVariables>(CategoryNavigationDecoratedGroupsForFooterDocument, options);
      }
export function useCategoryNavigationDecoratedGroupsForFooterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryNavigationDecoratedGroupsForFooterQuery, CategoryNavigationDecoratedGroupsForFooterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryNavigationDecoratedGroupsForFooterQuery, CategoryNavigationDecoratedGroupsForFooterQueryVariables>(CategoryNavigationDecoratedGroupsForFooterDocument, options);
        }
export function useCategoryNavigationDecoratedGroupsForFooterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CategoryNavigationDecoratedGroupsForFooterQuery, CategoryNavigationDecoratedGroupsForFooterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CategoryNavigationDecoratedGroupsForFooterQuery, CategoryNavigationDecoratedGroupsForFooterQueryVariables>(CategoryNavigationDecoratedGroupsForFooterDocument, options);
        }
export type CategoryNavigationDecoratedGroupsForFooterQueryHookResult = ReturnType<typeof useCategoryNavigationDecoratedGroupsForFooterQuery>;
export type CategoryNavigationDecoratedGroupsForFooterLazyQueryHookResult = ReturnType<typeof useCategoryNavigationDecoratedGroupsForFooterLazyQuery>;
export type CategoryNavigationDecoratedGroupsForFooterSuspenseQueryHookResult = ReturnType<typeof useCategoryNavigationDecoratedGroupsForFooterSuspenseQuery>;
export type CategoryNavigationDecoratedGroupsForFooterQueryResult = Apollo.QueryResult<CategoryNavigationDecoratedGroupsForFooterQuery, CategoryNavigationDecoratedGroupsForFooterQueryVariables>;