// @ts-nocheck
import type * as Types from '../../../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ShippingAddressForWishlistShippingFormFragment = { __typename: 'Address', id: any, addresseeName?: string | null, line1?: string | null, line2?: string | null, city?: string | null, state?: string | null, postalCode: string, country?: string | null, phoneNumber?: string | null };

export type WishlistForWishlistShippingFormFragment = { __typename: 'Wishlist', id: any, title: string, shippingAddress?: { __typename: 'Address', id: any, addresseeName?: string | null, line1?: string | null, line2?: string | null, city?: string | null, state?: string | null, postalCode: string, country?: string | null, phoneNumber?: string | null } | null };

export type AddAddressWithValidationForWishlistShippingFormMutationVariables = Types.Exact<{
  input: Types.AddAddressWithValidationInput;
}>;


export type AddAddressWithValidationForWishlistShippingFormMutation = { __typename: 'Mutation', addAddressWithValidation: { __typename: 'AddAddressWithValidationPayload', address?: { __typename: 'Address', id: any } | null, error?: { __typename: 'InvalidAddressAddAddressWithValidationError' } | null } };

export type UpdateWishlistShippingAddressForWishlistShippingFormMutationVariables = Types.Exact<{
  input: Types.UpdateWishlistShippingAddressInput;
}>;


export type UpdateWishlistShippingAddressForWishlistShippingFormMutation = { __typename: 'Mutation', updateWishlistShippingAddress: { __typename: 'UpdateWishlistShippingAddressPayload', wishlist: { __typename: 'Wishlist', id: any, shippingAddress?: { __typename: 'Address', id: any, addresseeName?: string | null, line1?: string | null, line2?: string | null, city?: string | null, state?: string | null, postalCode: string, country?: string | null, phoneNumber?: string | null } | null }, error?: { __typename: 'InArchivedStateUpdateWishlistShippingAddressError' } | null } };

export type DeleteAddressForWishlistShippingFormMutationVariables = Types.Exact<{
  input: Types.DeleteAddressInput;
}>;


export type DeleteAddressForWishlistShippingFormMutation = { __typename: 'Mutation', deleteAddress: { __typename: 'DeleteAddressPayload', success?: boolean | null } };

export const ShippingAddressForWishlistShippingFormFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ShippingAddressForWishlistShippingForm"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Address"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"addresseeName"}},{"kind":"Field","name":{"kind":"Name","value":"line1"}},{"kind":"Field","name":{"kind":"Name","value":"line2"}},{"kind":"Field","name":{"kind":"Name","value":"city"}},{"kind":"Field","name":{"kind":"Name","value":"state"}},{"kind":"Field","name":{"kind":"Name","value":"postalCode"}},{"kind":"Field","name":{"kind":"Name","value":"country"}},{"kind":"Field","name":{"kind":"Name","value":"phoneNumber"}}]}}]} as unknown as DocumentNode;
export const WishlistForWishlistShippingFormFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"WishlistForWishlistShippingForm"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Wishlist"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"shippingAddress"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ShippingAddressForWishlistShippingForm"}}]}}]}}]} as unknown as DocumentNode;
export const AddAddressWithValidationForWishlistShippingFormDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddAddressWithValidationForWishlistShippingForm"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddAddressWithValidationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addAddressWithValidation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"address"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"error"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}}]}}]}}]}}]} as unknown as DocumentNode;
export type AddAddressWithValidationForWishlistShippingFormMutationFn = Apollo.MutationFunction<AddAddressWithValidationForWishlistShippingFormMutation, AddAddressWithValidationForWishlistShippingFormMutationVariables>;
export type AddAddressWithValidationForWishlistShippingFormComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddAddressWithValidationForWishlistShippingFormMutation, AddAddressWithValidationForWishlistShippingFormMutationVariables>, 'mutation'>;

    export const AddAddressWithValidationForWishlistShippingFormComponent = (props: AddAddressWithValidationForWishlistShippingFormComponentProps) => (
      <ApolloReactComponents.Mutation<AddAddressWithValidationForWishlistShippingFormMutation, AddAddressWithValidationForWishlistShippingFormMutationVariables> mutation={AddAddressWithValidationForWishlistShippingFormDocument} {...props} />
    );
    

/**
 * __useAddAddressWithValidationForWishlistShippingFormMutation__
 *
 * To run a mutation, you first call `useAddAddressWithValidationForWishlistShippingFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAddressWithValidationForWishlistShippingFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAddressWithValidationForWishlistShippingFormMutation, { data, loading, error }] = useAddAddressWithValidationForWishlistShippingFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAddressWithValidationForWishlistShippingFormMutation(baseOptions?: Apollo.MutationHookOptions<AddAddressWithValidationForWishlistShippingFormMutation, AddAddressWithValidationForWishlistShippingFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAddressWithValidationForWishlistShippingFormMutation, AddAddressWithValidationForWishlistShippingFormMutationVariables>(AddAddressWithValidationForWishlistShippingFormDocument, options);
      }
export type AddAddressWithValidationForWishlistShippingFormMutationHookResult = ReturnType<typeof useAddAddressWithValidationForWishlistShippingFormMutation>;
export type AddAddressWithValidationForWishlistShippingFormMutationResult = Apollo.MutationResult<AddAddressWithValidationForWishlistShippingFormMutation>;
export type AddAddressWithValidationForWishlistShippingFormMutationOptions = Apollo.BaseMutationOptions<AddAddressWithValidationForWishlistShippingFormMutation, AddAddressWithValidationForWishlistShippingFormMutationVariables>;
export const UpdateWishlistShippingAddressForWishlistShippingFormDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateWishlistShippingAddressForWishlistShippingForm"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateWishlistShippingAddressInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateWishlistShippingAddress"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"wishlist"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"shippingAddress"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ShippingAddressForWishlistShippingForm"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"error"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}}]}}]}}]}},...ShippingAddressForWishlistShippingFormFragmentDoc.definitions]} as unknown as DocumentNode;
export type UpdateWishlistShippingAddressForWishlistShippingFormMutationFn = Apollo.MutationFunction<UpdateWishlistShippingAddressForWishlistShippingFormMutation, UpdateWishlistShippingAddressForWishlistShippingFormMutationVariables>;
export type UpdateWishlistShippingAddressForWishlistShippingFormComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateWishlistShippingAddressForWishlistShippingFormMutation, UpdateWishlistShippingAddressForWishlistShippingFormMutationVariables>, 'mutation'>;

    export const UpdateWishlistShippingAddressForWishlistShippingFormComponent = (props: UpdateWishlistShippingAddressForWishlistShippingFormComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateWishlistShippingAddressForWishlistShippingFormMutation, UpdateWishlistShippingAddressForWishlistShippingFormMutationVariables> mutation={UpdateWishlistShippingAddressForWishlistShippingFormDocument} {...props} />
    );
    

/**
 * __useUpdateWishlistShippingAddressForWishlistShippingFormMutation__
 *
 * To run a mutation, you first call `useUpdateWishlistShippingAddressForWishlistShippingFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWishlistShippingAddressForWishlistShippingFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWishlistShippingAddressForWishlistShippingFormMutation, { data, loading, error }] = useUpdateWishlistShippingAddressForWishlistShippingFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWishlistShippingAddressForWishlistShippingFormMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWishlistShippingAddressForWishlistShippingFormMutation, UpdateWishlistShippingAddressForWishlistShippingFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWishlistShippingAddressForWishlistShippingFormMutation, UpdateWishlistShippingAddressForWishlistShippingFormMutationVariables>(UpdateWishlistShippingAddressForWishlistShippingFormDocument, options);
      }
export type UpdateWishlistShippingAddressForWishlistShippingFormMutationHookResult = ReturnType<typeof useUpdateWishlistShippingAddressForWishlistShippingFormMutation>;
export type UpdateWishlistShippingAddressForWishlistShippingFormMutationResult = Apollo.MutationResult<UpdateWishlistShippingAddressForWishlistShippingFormMutation>;
export type UpdateWishlistShippingAddressForWishlistShippingFormMutationOptions = Apollo.BaseMutationOptions<UpdateWishlistShippingAddressForWishlistShippingFormMutation, UpdateWishlistShippingAddressForWishlistShippingFormMutationVariables>;
export const DeleteAddressForWishlistShippingFormDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteAddressForWishlistShippingForm"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeleteAddressInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteAddress"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode;
export type DeleteAddressForWishlistShippingFormMutationFn = Apollo.MutationFunction<DeleteAddressForWishlistShippingFormMutation, DeleteAddressForWishlistShippingFormMutationVariables>;
export type DeleteAddressForWishlistShippingFormComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteAddressForWishlistShippingFormMutation, DeleteAddressForWishlistShippingFormMutationVariables>, 'mutation'>;

    export const DeleteAddressForWishlistShippingFormComponent = (props: DeleteAddressForWishlistShippingFormComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteAddressForWishlistShippingFormMutation, DeleteAddressForWishlistShippingFormMutationVariables> mutation={DeleteAddressForWishlistShippingFormDocument} {...props} />
    );
    

/**
 * __useDeleteAddressForWishlistShippingFormMutation__
 *
 * To run a mutation, you first call `useDeleteAddressForWishlistShippingFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAddressForWishlistShippingFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAddressForWishlistShippingFormMutation, { data, loading, error }] = useDeleteAddressForWishlistShippingFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAddressForWishlistShippingFormMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAddressForWishlistShippingFormMutation, DeleteAddressForWishlistShippingFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAddressForWishlistShippingFormMutation, DeleteAddressForWishlistShippingFormMutationVariables>(DeleteAddressForWishlistShippingFormDocument, options);
      }
export type DeleteAddressForWishlistShippingFormMutationHookResult = ReturnType<typeof useDeleteAddressForWishlistShippingFormMutation>;
export type DeleteAddressForWishlistShippingFormMutationResult = Apollo.MutationResult<DeleteAddressForWishlistShippingFormMutation>;
export type DeleteAddressForWishlistShippingFormMutationOptions = Apollo.BaseMutationOptions<DeleteAddressForWishlistShippingFormMutation, DeleteAddressForWishlistShippingFormMutationVariables>;