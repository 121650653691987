import isEqual from 'lodash/isEqual'
import { History, Location } from 'history'

interface ProductComparisonPageUrl {
  to: string
  action: Extract<keyof History, 'push' | 'replace'>
}

export function buildProductComparisonPageUrl(
  prevLocation: Location,
  sellableIds: string[]
): ProductComparisonPageUrl {
  const [firstSellableId, ...otherSellableIds] = sellableIds
  const nextUrlParams = new URLSearchParams(prevLocation.search)
  nextUrlParams.delete('vs')
  nextUrlParams.delete('ids')
  otherSellableIds.forEach(id => nextUrlParams.append('vs', id))

  const nextPathname = firstSellableId ? `/compare/${firstSellableId}` : '/compare'
  const nextSearch = nextUrlParams.toString() && `?${nextUrlParams.toString()}`

  return {
    to: nextPathname + nextSearch,
    action: isEqual(nextPathname, prevLocation.pathname) ? 'replace' : 'push'
  }
}
