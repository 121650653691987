import { useDebounce } from 'use-debounce'
import { OAuthType } from '#src/generated/types'
import { AuthenticatedUserFragment } from '#src/app/fragments/AuthenticatedUser.generated'
import { useIdentityContext } from '#src/app/containers/Identity'
import { useLogoutMutation } from '../AuthenticationFlow/Logout.generated'
import {
  useAuthenticateByExternalOAuthTokenMutation,
  useUpdateUserProfileForOAuthLoginMutation
} from './OAuthLogin.generated'

const UNIQUE_EMAIL_VIOLATION_ERROR_MESSAGE = 'Email address is already registered'

interface SharedAuthenticateByExternalOAuthTokenOptions {
  accessToken: string
  oauthType: OAuthType
  firstName?: string
  lastName?: string
  email?: string
  onSuccess: (user: AuthenticatedUserFragment) => void
  onFailure: (errorMessage: string) => void
}

export const useSharedAuthenticateMutations = () => {
  const {
    myself,
    incompleteUser,
    refetch: refetchIdentity = () => Promise.resolve()
  } = useIdentityContext()
  const isIncompleteUser = () => !!myself && incompleteUser

  const [authenticate, { loading: authenticateLoading }] =
    useAuthenticateByExternalOAuthTokenMutation()
  const [updateUserProfile, { loading: updateLoading }] =
    useUpdateUserProfileForOAuthLoginMutation()
  const [logout, { loading: logoutLoading, client }] = useLogoutMutation()

  const [isLoading] = useDebounce(authenticateLoading || updateLoading || logoutLoading, 500)

  const getGenericErrorMessage = (options: SharedAuthenticateByExternalOAuthTokenOptions) => {
    const oauthTypeDisplay = {
      [OAuthType.APPLE]: 'Apple',
      [OAuthType.FACEBOOK]: 'Facebook',
      [OAuthType.GOOGLE]: 'Google'
    }[options.oauthType]
    return `${oauthTypeDisplay} authentication failed!`
  }

  const handleAuthenticateByOAuthToken = async (
    options: SharedAuthenticateByExternalOAuthTokenOptions
  ) => {
    const { data, errors: gqlErrors } = await authenticate({
      variables: { input: { token: options.accessToken, type: options.oauthType } }
    })

    if (gqlErrors) {
      options.onFailure(getGenericErrorMessage(options))
      return
    }

    const { user, message } = data?.authenticateByExternalOAuthToken || {}

    if (user) {
      options.onSuccess(user)
    } else {
      options.onFailure(message || 'Unable to authenticate, maybe you do not have an account?')
    }
  }

  // If `incompleteUser`, then signing in via OAuth requires two steps:
  // 1) Update the `incompleteUser` profile with name/email from the auth provider
  // 2) Authenticate with the auth provider, which can only succeed if the profile is updated or already exists
  const handleUpdateIncompleteUserProfileBeforeAuthenticating = async (
    options: SharedAuthenticateByExternalOAuthTokenOptions
  ) => {
    if (!isIncompleteUser()) {
      return
    }

    const { data, errors: gqlErrors } = await updateUserProfile({
      variables: {
        input: { firstName: options.firstName, lastName: options.lastName, email: options.email }
      }
    })

    if (gqlErrors) {
      options.onFailure(getGenericErrorMessage(options))
      return
    }

    const { user, hasError, errorCode, errors } = data?.updateUserProfile || {}

    if (user && !hasError) {
      // Incomplete user has been successfully updated with oauth provider data,
      // call authenticate to associate updated user with oauth token
      await refetchIdentity().then(() => handleAuthenticateByOAuthToken(options))
    } else if (
      errorCode === 'INVALID_CONTACT' &&
      errors?.includes(UNIQUE_EMAIL_VIOLATION_ERROR_MESSAGE)
    ) {
      // Incomplete user update failed because email is already associated with an existing user,
      // logout incomplete user before authenticating as existing user
      await logout()
        .then(() => client.clearStore()) // `clearStore` instead of `resetStore` because don't want to refetch active queries
        .then(() => handleAuthenticateByOAuthToken(options))
    } else {
      // Incomplete user update failed for another reason, don't try to authenticate
      options.onFailure(errors?.join('\n') || getGenericErrorMessage(options))
    }
  }

  return {
    isLoading,
    handleAuthenticateByOAuthToken,
    handleUpdateIncompleteUserProfileBeforeAuthenticating,
    isIncompleteUser
  }
}
