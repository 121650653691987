import React, { useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'
import makeAsyncScriptLoader from 'react-async-script'
import classnames from 'classnames'
import { Text } from '@deal/bluxome'
import { OAuthType } from '#src/generated/types'
import { isBusinessMobileNative, isConsumerMobileNative } from '#src/app/services/mobile'
import { AppleSigninResponse } from '#src/app/services/dealNativeBridge'
import { WebMessageEventEmitterContext } from '#src/app/containers/ReceiveMessageFromNative'
import config from '#src/app/config'
import { useSharedAuthenticateMutations } from '#src/app/components/OAuthLogin'
import { useRegisterCustomerMutation } from '../../../OAuthRegister/RegisterCustomer.generated'
import { AuthenticatedUserFragment } from '../../../../fragments/AuthenticatedUser.generated'
import { OAuthButtonComponentProps } from '../../util/oauthButtonProps'
import AppleIconMark from './apple-iconmark.svg'
import styles from './styles.css'

interface AppleOAuthButtonProps extends OAuthButtonComponentProps {
  AppleID?: AppleIDI
  className?: string
  wrapperClassName?: string
  referralCodeId?: string | null
  referralRewardCreditClaimToken?: string | null
  registrationId?: string | null
  onSuccess: (user: AuthenticatedUserFragment) => void
  onFailure: (error: string) => void
  isRebranded?: boolean
}

interface AppleIDI {
  auth: AuthI
}

interface AuthI {
  init: (config: ClientConfigI) => void
  signIn: (config?: ClientConfigI) => Promise<SignInResponseI | SignInErrorI> | void
  renderButton: () => void
}

interface ClientConfigI {}

interface AuthorizationI {
  code: string
  id_token: string
  state?: string
}

interface NameI {
  firstName?: string
  lastName?: string
}

interface UserI {
  email?: string
  name?: NameI
}

interface SignInResponseI {
  user?: UserI
  authorization: AuthorizationI
}

interface SignInErrorI {
  error: string
}

const AppleOAuthButton: React.FC<AppleOAuthButtonProps> = ({
  AppleID: appleid,
  className,
  wrapperClassName,
  referralCodeId,
  referralRewardCreditClaimToken,
  registrationId,
  onSuccess,
  onFailure,
  isRebranded = false,
  buttonComponent: ButtonComponent
}) => {
  const [registerCustomer] = useRegisterCustomerMutation()
  const {
    isLoading,
    handleAuthenticateByOAuthToken,
    handleUpdateIncompleteUserProfileBeforeAuthenticating,
    isIncompleteUser
  } = useSharedAuthenticateMutations()
  const eventEmitter = useContext(WebMessageEventEmitterContext)

  /**
   * After successfully completing the Apple OAuth flow, submit the ID token to our API
   *   for authentication.
   */
  const handleWebSuccess = (response: SignInResponseI) =>
    handleSuccess({
      firstName: response.user?.name?.firstName,
      lastName: response.user?.name?.lastName,
      email: response.user?.email,
      idToken: response.authorization.id_token
    })

  const handleSuccess = ({
    firstName,
    lastName,
    email,
    idToken
  }: {
    firstName?: string
    lastName?: string
    email?: string
    idToken: string
  }) => {
    const options = {
      accessToken: idToken,
      oauthType: OAuthType.APPLE,
      firstName: firstName,
      lastName: lastName,
      email: email,
      onSuccess,
      onFailure
    }
    if (isBusinessMobileNative()) {
      // For expert native app, do not allow registering users.
      handleAuthenticateByOAuthToken(options)
    } else if (isIncompleteUser()) {
      // If there's a partial identity (i.e., for scout chat),
      // update the user profile before authenticating with idToken
      handleUpdateIncompleteUserProfileBeforeAuthenticating(options)
      return
    } else {
      registerCustomer({
        variables: {
          input: {
            firstName,
            lastName,
            email,
            externalOAuthType: OAuthType.APPLE,
            externalOAuthToken: idToken,
            referralCodeId,
            referralRewardCreditClaimToken,
            registrationId
          }
        }
      }).then(response => {
        const errors = response.errors

        // Handle failures at the GraphQL level
        if (errors) {
          onFailure('Apple authentication failed!')
          return
        }

        const payload = response.data!.registerCustomer

        if (payload.user) {
          onSuccess(payload.user)
        } else {
          onFailure((payload.errors && payload.errors.join('\n')) || 'Apple authentication failed!')
        }
      })
    }
  }

  /**
   * Handle failures from the Apple OAuth flow itself.
   */
  const handleFailure = (failure: { error: string }) => {
    switch (failure.error) {
      case 'popup_closed_by_user':
        break

      case 'NOT_HANDLED':
        onFailure('Sign-in with Apple is not supported.')
        break

      default:
        onFailure('Apple authentication failed!')
        break
    }
  }

  const handleNativeClick = (e?: React.MouseEvent<HTMLDivElement>) => {
    e?.preventDefault()
    eventEmitter!
      .requestResponse<AppleSigninResponse>(
        {
          type: 'appleSigninRequest',
          messageId: uuidv4()
        },
        'appleSigninResponse'
      )
      .then(message => {
        if (message.success) {
          handleSuccess({
            firstName: message.success.user.firstName || undefined,
            lastName: message.success.user.lastName || undefined,
            email: message.success.user.email || undefined,
            idToken: message.success.idToken
          })
        } else {
          handleFailure({
            error: message.failure!.toString()
          })
        }
      })
  }

  const handleClick = () => {
    appleid!.auth.init({
      clientId: config.get('apple.client_id'),
      redirectURI: new URL('/auth/apple', window.location.href).toString(),
      scope: 'name email',
      usePopup: true
    })
    const result = appleid!.auth.signIn()
    if (result) {
      result.then(response => {
        if ('error' in response) {
          handleFailure(response)
        } else {
          handleWebSuccess(response)
        }
      }, handleFailure)
    }
  }

  if ((isConsumerMobileNative() || isBusinessMobileNative()) && eventEmitter) {
    if (ButtonComponent) {
      return <ButtonComponent onPress={() => handleNativeClick()} isLoading={isLoading} />
    }

    return (
      <div className={classnames(styles.wrapper, wrapperClassName)}>
        <div
          className={classnames(styles.button, className, { [styles.rebranded]: isRebranded })}
          onClick={handleNativeClick}
        >
          <AppleIconMark className={styles.logo} />{' '}
          {isRebranded ? (
            <Text style="base-medium">Continue with Apple</Text>
          ) : (
            'Continue with Apple'
          )}
        </div>
      </div>
    )
  } else {
    if (ButtonComponent) {
      return <ButtonComponent onPress={handleClick} isLoading={isLoading} />
    }

    return (
      <div className={classnames(styles.wrapper, wrapperClassName)}>
        <div className={classnames(styles.wrapper, wrapperClassName)}>
          <div
            className={classnames(styles.button, className, { [styles.rebranded]: isRebranded })}
            onClick={handleClick}
          >
            <AppleIconMark className={styles.logo} />{' '}
            {isRebranded ? (
              <Text style="base-medium">Continue with Apple</Text>
            ) : (
              'Continue with Apple'
            )}
          </div>
        </div>
      </div>
    )
  }
}

const AppleOAuthButtonWrapper = makeAsyncScriptLoader(
  AppleOAuthButton,
  'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
  { globalName: 'AppleID' }
)

export default AppleOAuthButtonWrapper
