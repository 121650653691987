import React, { useCallback, useContext, useEffect, useState } from 'react'
import { OverlayTriggerState } from 'react-stately'
import isEqual from 'lodash/isEqual'
import { DOMProps } from '@react-types/shared'
import { useHistory, useLocation } from '@deal/router'
import { useLocalStorage } from '@deal/dom-hooks'
import { useModalTrigger } from '@deal/bluxome'
import { buildProductComparisonPageUrl } from '#src/app/services/search/buildProductComparisonPageUrl'
import loggerClient from '#src/app/services/loggerClient'

type ProductComparisonContextType =
  | {
      sellableIds?: string[]
      removeAllSellableIds: () => void
      removeSellableId: (sellableId: string) => void
      replaceSellableId: (oldSellableId: string, newSellableId: string) => void
      pinSellableId: (sellableId: string) => void
      isSellableSelected: (sellableId: string) => boolean
      onCompareSelected: (sellableId: string, isSelected: boolean) => void
      canCompareProduct: boolean
      compareRouteLocation: string
      exceedsFourSellables: boolean
      overlayProps: DOMProps
      compareModalState: OverlayTriggerState
      syncSellableIdsFromApolloCacheToLocalStorage: (nextSellableIds: string[]) => void
    }
  | undefined

const ProductComparisonContext = React.createContext<ProductComparisonContextType>(undefined)

const ProductComparisonContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const history = useHistory()
  const location = useLocation()
  const [extraSellableId, setExtraSellableId] = useState<string>()
  const [isMounted, setIsMounted] = useState(false)
  const { state: compareModalState, overlayProps } = useModalTrigger({
    onOpenChange: isOpen => {
      if (!isOpen) {
        setExtraSellableId(undefined)
      }
    }
  })

  useEffect(() => {
    setIsMounted(true)
  }, [])

  const [{ sellableIds }, setLocalStorage] = useLocalStorage<{ sellableIds?: string[] }>(
    'activity.productComparison',
    { sellableIds: undefined }
  )

  const getCompareRouteLocation = useCallback(
    (nextSellableIds = sellableIds || []) => {
      return buildProductComparisonPageUrl(location, nextSellableIds)
    },
    [sellableIds, location]
  )

  const updateLocalStorage = (
    nextSellableIds: string[],
    options = { skipUrlUpdate: !location.pathname.startsWith('/compare') }
  ) => {
    if (!isEqual(sellableIds, nextSellableIds)) {
      if (!options.skipUrlUpdate) {
        const { to, action } = getCompareRouteLocation(nextSellableIds)
        history[action].call(history, to)
      }
      setLocalStorage({ sellableIds: nextSellableIds })
    }
  }

  const addSellableId = (sellableId: string) => {
    if (!sellableIds) {
      updateLocalStorage([sellableId])
    } else if (sellableIds.length < 4) {
      const updatedSellableIds = [...sellableIds.filter(id => id !== sellableId), sellableId]
      updateLocalStorage(updatedSellableIds)
    } else {
      setExtraSellableId(sellableId)
    }
    compareModalState.open()
  }

  const pinSellableId = (sellableId: string) => {
    if (sellableIds) {
      const updatedSellableIds = [sellableId, ...sellableIds.filter(id => id !== sellableId)]
      updateLocalStorage(updatedSellableIds)
    } else {
      loggerClient.captureError(new Error(`Attempted to mutate non existent sellableIds array`), {
        sellableId
      })
    }
  }

  const removeAllSellableIds = () => {
    updateLocalStorage([])
    setExtraSellableId(undefined)
  }

  const removeSellableId = (sellableId: string) => {
    if (sellableIds) {
      const updatedSellableIds = sellableIds.filter(id => id !== sellableId)
      if (extraSellableId) {
        updatedSellableIds.push(extraSellableId)
        setExtraSellableId(undefined)
      }
      updateLocalStorage(updatedSellableIds)
    } else {
      loggerClient.captureError(new Error(`Attempted to mutate non existent sellableIds array`), {
        sellableId
      })
    }
  }

  const replaceSellableId = (oldSellableId: string, newSellableId: string) => {
    if (sellableIds) {
      const updatedSellableIds = [...sellableIds]
      const oldSellableIdIdx = updatedSellableIds.indexOf(oldSellableId)
      if (oldSellableIdIdx !== -1) {
        updatedSellableIds[oldSellableIdIdx] = newSellableId
      } else {
        loggerClient.captureError(
          new Error(`Attempted to replace non-existent sellable in compare table`),
          { oldSellableId, newSellableId }
        )
      }
      updateLocalStorage(updatedSellableIds)
    } else {
      loggerClient.captureError(new Error(`Attempted to mutate non existent sellableIds array`), {
        oldSellableId,
        newSellableId
      })
    }
  }

  const isSellableSelected = (sellableId: string) =>
    sellableIds ? sellableIds.includes(sellableId) : false

  const onCompareSelected = (sellableId: string, isSelected: boolean) =>
    isSelected ? addSellableId(sellableId) : removeSellableId(sellableId)

  return (
    <ProductComparisonContext.Provider
      value={{
        removeAllSellableIds,
        pinSellableId,
        removeSellableId,
        sellableIds,
        isSellableSelected,
        onCompareSelected,
        // The product comparison sellable ids are stored in local storage. As a result, the ids can't
        //   be accessed on server, which causes a hydration mismatch when the page loads and then
        //   gets the sellable ids. To get around this, load the compare checkboxes after the page has
        //   mounted.
        canCompareProduct: isMounted,
        compareRouteLocation: getCompareRouteLocation().to,
        exceedsFourSellables: !!extraSellableId,
        replaceSellableId,
        overlayProps,
        compareModalState,
        syncSellableIdsFromApolloCacheToLocalStorage: nextSellableIds => {
          if (typeof window !== 'undefined') {
            updateLocalStorage(nextSellableIds, { skipUrlUpdate: true })
          }
        }
      }}
    >
      {children}
    </ProductComparisonContext.Provider>
  )
}

const useProductComparisonContext = () => {
  const context = useContext(ProductComparisonContext)

  if (!context) {
    throw new Error('Invoked ProductComparisonContext outside of provider')
  }

  return context
}

export { ProductComparisonContextProvider, useProductComparisonContext }
