// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { MediaFileFragmentDoc } from '../../../fragments/MediaFile.generated';
import { SellableForSellableLinkFragmentDoc } from '../../SellableLink/SellableLink.generated';
import { SellableForGetSellableLinkRelFragmentDoc, SellableForIsSellablePurchasableFragmentDoc } from '../../../services/sellable/Sellable.generated';
import { SellableUrlFragmentDoc } from '../../../fragments/SellableUrl.generated';
export type BusinessUserForMyCurrentGearFragment = { __typename: 'BusinessUser', id: any, shortName: string, expertProfile?: { __typename: 'ExpertProfile', id: any, currentGears: Array<{ __typename: 'Sellable', id: any, title: string, active: boolean, adHoc: boolean, friendlyId?: string | null, slug: string, type: Types.SellableType, brand?: { __typename: 'Brand', id: any, displayName: string } | null, images: Array<{ __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null }> }> } | null };

export const BusinessUserForMyCurrentGearFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BusinessUserForMyCurrentGear"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"shortName"}},{"kind":"Field","name":{"kind":"Name","value":"expertProfile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"currentGears"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"brand"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"images"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaFile"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SellableForSellableLink"}}]}}]}}]}}]} as unknown as DocumentNode;