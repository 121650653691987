import config from '#src/app/config'

export function getForceTraceId(urlString: string): string | null {
  const url = new URL(urlString)
  const pathname = url.pathname
  const queryParams = new URLSearchParams(url.search)
  const forceTraceParam = queryParams.get('forceTrace')

  // The explicit `forceTrace` query parameter always takes precedence
  if (forceTraceParam) {
    return forceTraceParam
  }

  // Otherwise, force tracing only for order checkout pages:
  const isPaymentOrderQuery = pathname.startsWith('/checkout')
  if (isPaymentOrderQuery) {
    return config.get('environment') + '_order_' + pathname.split('/')[2]
  }

  return null
}
