// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type LoomJwtQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LoomJwtQuery = { __typename: 'Query', loomJwt: string };

export type LeadForLoomModalFragment = { __typename: 'Lead', id: any, consumer: { __typename: 'User', id: any, firstName?: string | null } };

export const LeadForLoomModalFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LeadForLoomModal"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Lead"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"consumer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}}]}}]}}]} as unknown as DocumentNode;
export const LoomJwtDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"LoomJwt"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"loomJwt"}}]}}]} as unknown as DocumentNode;
export type LoomJwtComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LoomJwtQuery, LoomJwtQueryVariables>, 'query'>;

    export const LoomJwtComponent = (props: LoomJwtComponentProps) => (
      <ApolloReactComponents.Query<LoomJwtQuery, LoomJwtQueryVariables> query={LoomJwtDocument} {...props} />
    );
    

/**
 * __useLoomJwtQuery__
 *
 * To run a query within a React component, call `useLoomJwtQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoomJwtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoomJwtQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoomJwtQuery(baseOptions?: Apollo.QueryHookOptions<LoomJwtQuery, LoomJwtQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoomJwtQuery, LoomJwtQueryVariables>(LoomJwtDocument, options);
      }
export function useLoomJwtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoomJwtQuery, LoomJwtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoomJwtQuery, LoomJwtQueryVariables>(LoomJwtDocument, options);
        }
export function useLoomJwtSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LoomJwtQuery, LoomJwtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LoomJwtQuery, LoomJwtQueryVariables>(LoomJwtDocument, options);
        }
export type LoomJwtQueryHookResult = ReturnType<typeof useLoomJwtQuery>;
export type LoomJwtLazyQueryHookResult = ReturnType<typeof useLoomJwtLazyQuery>;
export type LoomJwtSuspenseQueryHookResult = ReturnType<typeof useLoomJwtSuspenseQuery>;
export type LoomJwtQueryResult = Apollo.QueryResult<LoomJwtQuery, LoomJwtQueryVariables>;