import React, { useContext, useState } from 'react'
import { webPushPromptStore } from '#src/app/stores'
import { createPushSubscription, getPushSubscription } from '#src/app/services/push'
import { BrowserNotificationPermissionRequestedEvent } from '#src/app/events/BrowserNotificationPermissionRequestedEvent'
import { BrowserNotificationPermissionGrantedEvent } from '#src/app/events/BrowserNotificationPermissionGrantedEvent'
import { BrowserNotificationPermissionDeniedEvent } from '#src/app/events/BrowserNotificationPermissionDeniedEvent'
import useLogoutCallback from '#src/app/containers/Identity/useLogoutCallback'
import useLoginCallback from '#src/app/containers/Identity/useLoginCallback'
import { IdentityContext } from '#src/app/containers/Identity'
import NotificationPermissionPrompt from '#src/app/components/PushNotificationPermissionPrompt'
import { useUnregisterWebPushSubscriptionMutation } from './UnregisterWebPushSubscription.generated'
import { useRegisterWebPushSubscriptionMutation } from './RegisterWebPushSubscription.generated'

type WebPushNotificationsContextType = {
  requestPushNotificationPermission(): void
}

const PushNotificationsContext = React.createContext<WebPushNotificationsContextType>({
  requestPushNotificationPermission: () => {}
})

const PushNotificationsProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { myself } = useContext(IdentityContext)
  const localStoragePermissionKeyForUser = `${myself?.user.id}.permission`

  const [showNotificationPermissionPrompt, setShowNotificationPermissionPrompt] = useState(false)

  const [registerWebPushSubscription] = useRegisterWebPushSubscriptionMutation()
  const [unregisterWebPushSubscription] = useUnregisterWebPushSubscriptionMutation()

  // When the user logs in, ensure we send the most up-to-date token to our backend.
  //
  // There are some edge-cases here: we only want to register the subscription if:
  //
  //   1. We have requested permission to show desktop notifications.
  //   2. We have shown our own push notification prompt to the user and they have
  //      selected "continue"
  //
  // These two conditions are not the same thing, nor necessarily in sync. The 1st
  //   setting is unique to a browser, whereas we also want to confirm with each
  //   individual user that they are okay with push notifications. It's possible
  //   the same browser may be used by multiple users.
  useLoginCallback(async newMyself => {
    const hasNotificationPermission = window.Notification?.permission === 'granted'
    const hasPushPermission = webPushPromptStore.get(localStoragePermissionKeyForUser) === 'granted'

    if (hasNotificationPermission && hasPushPermission) {
      // We have permission to show notifications, and permission to push to this user, so
      //   we resubscribe to ensure the most up-to-date token is used by the backend.
      const subscription = await createPushSubscription()

      if (!subscription) {
        return
      }

      registerWebPushSubscription({
        variables: {
          messagingUserId: newMyself.user.messagingUser.id,
          subscription: JSON.stringify(subscription)
        }
      })
    }
  })

  // When the user logs out, unregister the subscription on our backend and unsubscribe the
  //   subscription from the PushManager so they do not continue to receive push messages.
  useLogoutCallback(async () => {
    const subscription = await getPushSubscription()

    if (!subscription) {
      return
    }

    unregisterWebPushSubscription({
      variables: {
        subscription: JSON.stringify(subscription)
      }
    })

    subscription.unsubscribe()
  })

  // Allow child components to use this context to explicitly show the prompt
  const requestPushNotificationPermission = () => {
    setShowNotificationPermissionPrompt(true)
  }

  // Dismiss the prompt if the user selects "No thanks"
  const handleNoThanksClicked = () => {
    webPushPromptStore.set(localStoragePermissionKeyForUser, 'dismissed')
    setShowNotificationPermissionPrompt(false)
  }

  // Dismiss the prompt after the user selects "Continue"
  const handleContinueClicked = async () => {
    webPushPromptStore.set(localStoragePermissionKeyForUser, 'granted')
    setShowNotificationPermissionPrompt(false)

    const notificationPermission = await new Promise<NotificationPermission>(resolve => {
      window.tracking?.track(new BrowserNotificationPermissionRequestedEvent())

      Notification.requestPermission(permission => {
        if (permission === 'granted') {
          window.tracking?.track(new BrowserNotificationPermissionGrantedEvent())
        }

        if (permission === 'denied') {
          window.tracking?.track(new BrowserNotificationPermissionDeniedEvent())
        }

        resolve(permission)
      })
    })

    if (notificationPermission !== 'granted') {
      return
    }

    const subscription = await createPushSubscription()

    if (!subscription) {
      return
    }

    const messagingUserId = myself?.user.messagingUser.id
    const subscriptionJSON = JSON.stringify(subscription?.toJSON())

    registerWebPushSubscription({
      variables: {
        messagingUserId,
        subscription: subscriptionJSON
      }
    })
  }

  // If the user has previously answered the notification prompt on this device, never
  //   show it again.
  const hasPreviouslyAnsweredNotificationPermissionPrompt = webPushPromptStore.has(
    localStoragePermissionKeyForUser
  )

  return (
    <PushNotificationsContext.Provider
      value={{
        requestPushNotificationPermission
      }}
    >
      <>
        {showNotificationPermissionPrompt &&
          !hasPreviouslyAnsweredNotificationPermissionPrompt &&
          myself &&
          myself.realUser.id === myself.user.id && (
            <NotificationPermissionPrompt
              onNoThanksClicked={() => handleNoThanksClicked()}
              onContinueClicked={() => handleContinueClicked()}
            />
          )}
        {children}
      </>
    </PushNotificationsContext.Provider>
  )
}

const usePushNotificationsContext = () => {
  const context = useContext(PushNotificationsContext)

  if (!context) {
    throw new Error('Invoked PushNotificationsContext outside of provider')
  }

  return context
}

export { usePushNotificationsContext, PushNotificationsProvider }
