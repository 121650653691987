import React, { useEffect } from 'react'
import { PageNotFoundEvent } from '@deal/web-tracking'
import { Link } from '@deal/router'
import { Button } from '@deal/bluxome'
import { withStatusCode } from '#src/app/containers/Router'
import { useAnalyticsContext } from '#src/app/containers/Analytics'
import { useCurrentPreviousLocationAtom } from '#src/app/atoms/previousLocationAtom'
import NotFoundImage from './not-found.png'
import Logo from './logo.svg'
import styles from './styles.css'

function getAbsoluteUrl(url: string) {
  const a = document.createElement('a')
  a.href = url
  return a.href
}

const NotFound = () => {
  const [currentPreviousLocation] = useCurrentPreviousLocationAtom()
  const analytics = useAnalyticsContext()

  useEffect(() => {
    if (currentPreviousLocation) {
      analytics?.track(
        new PageNotFoundEvent({
          url: getAbsoluteUrl(currentPreviousLocation.current),
          referrer_url:
            (currentPreviousLocation.previous &&
              getAbsoluteUrl(currentPreviousLocation.previous)) ||
            document.referrer
        })
      )
    }
  }, [analytics, currentPreviousLocation])

  return (
    <section>
      <header className={styles.header}>
        <Link to="/">
          <Logo className={styles.logo} />
        </Link>
      </header>
      <div className={styles.image}>
        <img src={NotFoundImage} />
      </div>
      <h1 className={styles.headline}>Oops. The page you were looking for doesn&apos;t exist.</h1>
      <p className={styles.body}>You may have mistyped the address or the page has moved.</p>
      <div className={styles.action}>
        <Button link={{ to: '/' }} color="learn-blue" size="large">
          Take me home
        </Button>
      </div>
    </section>
  )
}

export default withStatusCode(404)(NotFound)
