import React, { useContext } from 'react'
import isEqual from 'lodash/isEqual'
import { useLocalStorage } from '@deal/dom-hooks'

export interface SellableForUseSavedProducts {
  id: string
  primaryImage?: {
    url: string
  } | null
  categories: {
    id: string
    slug: string
    pluralDisplayName: string
  }[]
}

type SavedProductsContextType = {
  isEnabled: boolean
  savedProducts: SellableForUseSavedProducts[]
  setSavedProducts: (sellables: SellableForUseSavedProducts[]) => void
}

const SavedProductsContext = React.createContext<SavedProductsContextType | null>(null)

interface SavedProductsProviderProps {
  isEnabled: boolean
}

// this implementation should be replaced by backend storage instead of local storage
const SavedProductsProvider: React.FC<React.PropsWithChildren<SavedProductsProviderProps>> = ({
  isEnabled,
  children
}) => {
  const [{ sellables }, setLocalStorage] = useLocalStorage<{
    sellables: SellableForUseSavedProducts[]
  }>('activity.savedProducts', { sellables: [] })

  const updateLocalStorage = (nextSellables: SellableForUseSavedProducts[]) => {
    if (!isEqual(sellables, nextSellables)) {
      setLocalStorage({ sellables: nextSellables })
    }
  }

  return (
    <SavedProductsContext.Provider
      value={{
        isEnabled: isEnabled,
        savedProducts: sellables,
        setSavedProducts: updateLocalStorage
      }}
    >
      {children}
    </SavedProductsContext.Provider>
  )
}

const useSavedProductsContext = (): SavedProductsContextType => {
  const context = useContext(SavedProductsContext)
  if (!context) {
    throw new Error('Invoked `SavedProductsContext` outside of provider')
  }
  return context
}

export { SavedProductsContext, SavedProductsProvider, useSavedProductsContext }
