// @ts-nocheck
import type * as Types from '../../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { MonetaryAmountFragmentDoc } from '../../../../../../fragments/MonetaryAmount.generated';
import { WishlistItemSellableDetailForEditSellableWishlistItemFragmentDoc } from './EditSellableWishlistItem/EditSellableWishlistItem.generated';
import { SellableForVariationMatrixFragmentDoc, SellableForVariationMatrixSelectedSellableFragmentDoc, SellableForBuildSelectionTableFragmentDoc, VariationMatrixForBuildSelectionTableFragmentDoc } from '../../../../../../components/VariationMatrix/VariationMatrix.generated';
import { SellableForButtonMatrixFragmentDoc, VariationMatrixForButtonMatrixFragmentDoc, VariationMatrixDimensionForButtonMatrixDimensionFragmentDoc, SellableSizeGuideForButtonMatrixFragmentDoc } from '../../../../../../components/VariationMatrix/ButtonMatrix/ButtonMatrix.generated';
import { SellableUrlFragmentDoc } from '../../../../../../fragments/SellableUrl.generated';
import { SellableForImageVectorFragmentDoc } from '../../../../../../components/VariationMatrix/ButtonMatrix/ImageVectorSelector/ImageVectorSelector.generated';
import { MediaFileFragmentDoc } from '../../../../../../fragments/MediaFile.generated';
import { SellableForFitDescriptorFragmentDoc } from '../../../../../../components/VariationMatrix/ButtonMatrix/FitDescriptor/FitDescriptor.generated';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ArchiveWishlistItemForSellableWishlistItemManageCtaButtonsMutationVariables = Types.Exact<{
  itemId: Types.Scalars['WishlistItemId']['input'];
}>;


export type ArchiveWishlistItemForSellableWishlistItemManageCtaButtonsMutation = { __typename: 'Mutation', archiveWishlistItem: { __typename: 'ArchiveWishlistItemPayload', wishlist?: { __typename: 'Wishlist', id: any, items: { __typename: 'WishlistItemPagination', count: any, wishlistItems: Array<{ __typename: 'WishlistItem', id: any }> } } | null } };

export type WishlistItemSellableDetailForSellableWishlistItemManageCtaButtonsFragment = { __typename: 'WishlistItemSellableDetail', quantityRequested: number, quantityPurchased: number, sellable: { __typename: 'Sellable', id: any, title: string, active: boolean, purchasable: boolean, variantParent: boolean, variant: boolean, salePrice?: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } | null, parent?: { __typename: 'Sellable', id: any } | null, variationMatrix?: { __typename: 'VariationMatrix', dimensions: Array<{ __typename: 'VariationMatrixDimension', name: string, displayName?: string | null, vectors: Array<{ __typename: 'VariationMatrixVector', sellableIds: Array<any>, value: string, displayValueHtml: string }> }>, outOfStockSellables: Array<{ __typename: 'Sellable', id: any, availableForPreOrder: boolean }>, sellables: Array<{ __typename: 'Sellable', id: any, friendlyId?: string | null, slug: string, type: Types.SellableType, variant: boolean, primaryImage?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null, brand?: { __typename: 'Brand', id: any, name: string, slug: string } | null }> } | null, sizeGuides: Array<{ __typename: 'ArticleRevision', id: any, friendlyId: string, slug: string }>, categories: Array<{ __typename: 'Category', id: any, slug: string }>, brand?: { __typename: 'Brand', id: any, name: string, slug: string } | null } };

export const WishlistItemSellableDetailForSellableWishlistItemManageCtaButtonsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"WishlistItemSellableDetailForSellableWishlistItemManageCtaButtons"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"WishlistItemSellableDetail"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"quantityRequested"}},{"kind":"Field","name":{"kind":"Name","value":"sellable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"salePrice"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MonetaryAmount"}}]}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"WishlistItemSellableDetailForEditSellableWishlistItem"}}]}}]} as unknown as DocumentNode;
export const ArchiveWishlistItemForSellableWishlistItemManageCtaButtonsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ArchiveWishlistItemForSellableWishlistItemManageCtaButtons"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"itemId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"WishlistItemId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"archiveWishlistItem"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"itemId"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"wishlist"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"wishlistItems"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type ArchiveWishlistItemForSellableWishlistItemManageCtaButtonsMutationFn = Apollo.MutationFunction<ArchiveWishlistItemForSellableWishlistItemManageCtaButtonsMutation, ArchiveWishlistItemForSellableWishlistItemManageCtaButtonsMutationVariables>;
export type ArchiveWishlistItemForSellableWishlistItemManageCtaButtonsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ArchiveWishlistItemForSellableWishlistItemManageCtaButtonsMutation, ArchiveWishlistItemForSellableWishlistItemManageCtaButtonsMutationVariables>, 'mutation'>;

    export const ArchiveWishlistItemForSellableWishlistItemManageCtaButtonsComponent = (props: ArchiveWishlistItemForSellableWishlistItemManageCtaButtonsComponentProps) => (
      <ApolloReactComponents.Mutation<ArchiveWishlistItemForSellableWishlistItemManageCtaButtonsMutation, ArchiveWishlistItemForSellableWishlistItemManageCtaButtonsMutationVariables> mutation={ArchiveWishlistItemForSellableWishlistItemManageCtaButtonsDocument} {...props} />
    );
    

/**
 * __useArchiveWishlistItemForSellableWishlistItemManageCtaButtonsMutation__
 *
 * To run a mutation, you first call `useArchiveWishlistItemForSellableWishlistItemManageCtaButtonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveWishlistItemForSellableWishlistItemManageCtaButtonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveWishlistItemForSellableWishlistItemManageCtaButtonsMutation, { data, loading, error }] = useArchiveWishlistItemForSellableWishlistItemManageCtaButtonsMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useArchiveWishlistItemForSellableWishlistItemManageCtaButtonsMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveWishlistItemForSellableWishlistItemManageCtaButtonsMutation, ArchiveWishlistItemForSellableWishlistItemManageCtaButtonsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveWishlistItemForSellableWishlistItemManageCtaButtonsMutation, ArchiveWishlistItemForSellableWishlistItemManageCtaButtonsMutationVariables>(ArchiveWishlistItemForSellableWishlistItemManageCtaButtonsDocument, options);
      }
export type ArchiveWishlistItemForSellableWishlistItemManageCtaButtonsMutationHookResult = ReturnType<typeof useArchiveWishlistItemForSellableWishlistItemManageCtaButtonsMutation>;
export type ArchiveWishlistItemForSellableWishlistItemManageCtaButtonsMutationResult = Apollo.MutationResult<ArchiveWishlistItemForSellableWishlistItemManageCtaButtonsMutation>;
export type ArchiveWishlistItemForSellableWishlistItemManageCtaButtonsMutationOptions = Apollo.BaseMutationOptions<ArchiveWishlistItemForSellableWishlistItemManageCtaButtonsMutation, ArchiveWishlistItemForSellableWishlistItemManageCtaButtonsMutationVariables>;