import { BreakpointName, DeviceType, useBreakpoint as useBreakpointHook } from '@deal/dom-hooks'
import { useUserAgentContext } from '#src/app/containers/UserAgent'
import { useGetResponsiveBreakpointOverrideContainer } from '#src/app/containers/ResponsiveBreakpointOverrideContainer'

export const useBreakpoint = (
  breakpoint: BreakpointName | number,
  designSystem?: 'legacy' | 'bluxome'
) => {
  const userAgentContext = useUserAgentContext()
  const getContainer = useGetResponsiveBreakpointOverrideContainer()

  return useBreakpointHook(
    breakpoint,
    userAgentContext.device.type as DeviceType,
    designSystem,
    getContainer
  )
}
