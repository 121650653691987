import { Event, ViewerContext } from '@deal/web-tracking'

export interface LoomErrorProperties {
  lead_id?: string
  reason: string
}

export class LoomError extends Event<LoomErrorProperties> {
  static displayName = 'Loom Error'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
