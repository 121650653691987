import React from 'react'
import loadable from '@loadable/component'
import { Route, Switch, useParams } from '@deal/router'
import { useIdentityContext } from '#src/app/containers/Identity'
import Page from '#src/app/components/Page'
import { NotFound } from '#src/app/components/Errors'
import { useExpertProfileQuery } from './ExpertProfile.generated'
import LoadingComponent from './loading'

const ExpertProfilePage = loadable(
  () => import(/* webpackChunkName: "expert-profile " */ './page'),
  {
    resolveComponent: components => components.ExpertProfilePage
  }
)

const ExpertPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { myself } = useIdentityContext()
  const { vanityIdOrId } = useParams<{ vanityIdOrId: string }>()
  const expertQuery = useExpertProfileQuery({
    variables: {
      vanityIdOrId
    }
  })

  return (
    <Page
      query={expertQuery}
      loadingComponent={LoadingComponent}
      pageKey="expert-profile"
      pageComponent={ExpertProfilePage}
      chat={
        myself
          ? // For logged-in users, we don't know whether to show the chat until we
            //   fetch the expert profile
            data => data.findExpert?.state === 'ACTIVE' || data.findExpert?.state === 'PENDING'
          : // For logged-out users, we never show the chat
            false
      }
      expert={data => data?.findExpert || undefined}
      category={data => data.findExpert?.department.topLevelCategory}
      department={data => data?.findExpert?.department}
      sellable={() => undefined}
      seoIndexable={({ findExpert }) =>
        !!findExpert && !findExpert.expertAttributes.placeholderExpert
      }
      seoTitle={data =>
        data.findExpert ? `${data.findExpert.displayName}'s Profile | Curated.com` : undefined
      }
      seoDescription={data => {
        /**
         * Open Graph description
         *
         * If user has more than 10 reviews, we show the last sentence.
         *
         * Example: “Shop with golf expert Tommy Summers and get personalized recommendations
         * to fit your needs. Tommy has helped 200 customers and has 100 reviews averaging
         * 4.7 stars."
         */
        if (data.findExpert) {
          const expert = data.findExpert
          return `Shop with ${expert.department.displayName} expert ${
            expert.displayName
          } and get personalized recommendations to fit your needs.${
            expert.expertStats &&
            expert.expertStats.totalReviews >= 10 &&
            expert.expertStats.averageRating
              ? ` ${expert.shortName} has helped ${
                  expert.expertStats.totalLeadsParticipated
                } customers and has ${
                  expert.expertStats.totalReviews
                } reviews averaging ${expert.expertStats.averageRating.toFixed(1)} stars.`
              : ''
          }`
        }

        return undefined
      }}
      canonicalPath={({ findExpert }) => {
        const expertIdentifier = findExpert?.vanityId || findExpert?.id
        return expertIdentifier ? `/experts/${expertIdentifier}` : undefined
      }}
      ogImageUrl={({ findExpert }) => findExpert?.expertProfile?.profilePhoto?.url}
    />
  )
}

const ExpertProfileContainer: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Switch>
      <Route path="/experts/:vanityIdOrId" component={ExpertPage} />
      <Route component={NotFound} />
    </Switch>
  )
}

export { ExpertProfileContainer }
