import { CreatePathProps, useCreatePath } from '@deal/path-flow'
import { AttributeInput, PathSchemaSelectorInput } from '#src/generated/types'
import { usePathPageTrackingCodes } from '#src/app/services/path'
import { useCreatePathWithInterstitial } from '#src/app/context/path-create-with-interstitial'

type CreateRequestPathTuple = [(props: CreateRequestPathProps) => void, { loading: boolean }]

export interface TrackingCodes {
  ctaName: string
  sourceKey: string
  pageVariation?: string
}

export interface CreateRequestPathProps {
  pathSelector: PathSchemaSelectorInput
  trackingCodes: TrackingCodes
  initialAttributes?: AttributeInput[]
}

/**
 * A hook wrapping the path creation for when paths are created specifically for a request.
 *
 * @returns CreatePathTuple
 */
export default function useCreateRequestPath(
  createPathProps: CreatePathProps
): CreateRequestPathTuple {
  const pageTrackingCodes = usePathPageTrackingCodes()
  const [createPath, { loading }] = useCreatePath(createPathProps)
  const createPathWithInterstitial = useCreatePathWithInterstitial(createPath, createPathProps)

  return [
    ({ trackingCodes, pathSelector, initialAttributes }: CreateRequestPathProps) => {
      const effectiveInitialAttributes = initialAttributes
      createPathWithInterstitial({
        timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
        request: {
          initialAttributes: effectiveInitialAttributes,
          pathSelector
        },
        trackingCodes: {
          pageKey: pageTrackingCodes.pageKey,
          originalPageContext: pageTrackingCodes.originalPageContext,
          originalPageUrl: pageTrackingCodes.originalPageUrl,
          ctaName: trackingCodes.ctaName,
          pageVariation: trackingCodes.pageVariation,
          sourceKey: trackingCodes.sourceKey
        }
      })
    },
    { loading }
  ]
}
