import { useState } from 'react'
import { useLocation } from 'react-router'
import * as History from 'history'
import { UserRegisteredEvent } from '@deal/web-tracking'
import { HandleType } from '#src/generated/types'
import { loginLocation } from '#src/app/services/routing'
import { useIdentityContext } from '#src/app/containers/Identity'
import { useAnalyticsContext } from '#src/app/containers/Analytics'
import { useRegisterCustomerMutation } from '#src/app/components/OAuthRegister/RegisterCustomer.generated'
import {
  ClaimPromotionPayloadForUseOnSiteSmsModalTemplateFragment,
  useCategoryForUseOnSiteSmsModalTemplateQuery,
  useConfirmVerificationCodeMutation,
  useContactUsedLazyQuery,
  useIssueVerificationCodeMutation
} from './OnSiteSMSModalTemplate.generated'
import { getQuestionForOnSiteSMSModalTemplate } from './getQuestionForOnSiteSMSModalTemplate'

export const useOnSiteSMSModalTemplate = (
  email: string,
  phone: string,
  code: string,
  pageTemplateRevisionId: string,
  categoryId: string,
  onSubmit?: () => void
) => {
  const { refetch: refetchIdentity } = useIdentityContext()
  const analytics = useAnalyticsContext()
  const location = useLocation()

  const [error, setError] = useState<string>()
  const [helperText, setHelperText] = useState<string>()
  const [redirectURL, setRedirectUrl] = useState<History.Location>()
  const [promotionPayload, setPromotionPayload] =
    useState<ClaimPromotionPayloadForUseOnSiteSmsModalTemplateFragment>()
  const [step, setStep] = useState<'email' | 'phone' | 'code' | 'promotion'>('email')

  /** ---- QUERIES and MUTATIONS ---- */

  const [contactUsedQuery, { loading: contactUsedLoading }] = useContactUsedLazyQuery()

  const { data: categoryQuery } = useCategoryForUseOnSiteSmsModalTemplateQuery({
    variables: { categoryId }
  })

  const [registerCustomerMutation, { loading: registerCustomerLoading }] =
    useRegisterCustomerMutation({
      variables: {
        input: {
          email: email,
          phone: phone,
          pageTemplateRevisionId: pageTemplateRevisionId
        }
      },
      onCompleted: data => {
        if (data.registerCustomer.error?.__typename === 'AlreadyRegisteredRegisterCustomerError') {
          setRedirectUrl(redirectToLoginUrl(data.registerCustomer.error.contact.id))
          setError('Already registered')
        } else if (data.registerCustomer.errors?.length) {
          setError(data.registerCustomer.errors.join(' '))
        } else {
          setPromotionPayload(data.registerCustomer.claimPromotionResult ?? undefined)
          setStep('promotion')
          if (refetchIdentity) {
            refetchIdentity().then(result => {
              const refetchedMyself = result.data.me
              if (refetchedMyself) {
                analytics?.track(
                  new UserRegisteredEvent({
                    user_id: refetchedMyself.id,
                    context: 'guest-email-modal'
                  })
                )
              }
            })
          }
        }
      },
      onError: error => {
        setError(error.name + ': ' + error.message)
      }
    })

  const [issueVerificationCode, { loading: issueVerificationCodeLoading }] =
    useIssueVerificationCodeMutation({
      variables: {
        input: {
          handle: phone,
          handleType: HandleType.PHONE
        }
      },
      onCompleted: data => {
        if (data.initiateVerifyHandle.success) {
          setStep('code')
        } else {
          setError('Cound not issue verification code, please try again')
        }
      },
      onError: error => {
        setError(error.name + ': ' + error.message)
      }
    })

  const [confirmVerificationCode, { loading: confirmVerificationCodeLoading }] =
    useConfirmVerificationCodeMutation({
      variables: {
        input: {
          handle: phone,
          handleType: HandleType.PHONE,
          challenge: code
        }
      },
      onCompleted: data => {
        if (data.verifyHandleChallenge.success) {
          registerCustomer()
        } else {
          setError(data.verifyHandleChallenge.message ?? 'Could not verify phone')
        }
      },
      onError: error => {
        setError(error.name + ': ' + error.message)
      }
    })

  /** ---- LOGIC ---- */

  const question = getQuestionForOnSiteSMSModalTemplate(categoryQuery?.category)

  const finalize = () => {
    onSubmit?.()
  }

  const registerCustomer = () => {
    if (email.length && phone.length) {
      setError(undefined)
      registerCustomerMutation()
    } else {
      setError('Please enter a valid email address and phone number.')
    }
  }

  const submitEmail = () => {
    if (email.includes('@') && email.includes('.')) {
      setError('')
      setRedirectUrl(undefined)
      contactUsedQuery({ variables: { type: 'EMAIL', value: email } }).then(result => {
        if (!result.data) {
          setError(result.error?.message ?? 'Could not verify email, please try again.')
          return
        }
        if (result.data?.contactUsed) {
          setError('Invalid email')
          setRedirectUrl(redirectToLoginUrl())
        } else {
          setStep('phone')
        }
      })
    } else {
      setError('Please provide valid email')
    }
  }

  const submitPhone = () => {
    setError('')
    setHelperText(undefined)
    setRedirectUrl(undefined)
    contactUsedQuery({ variables: { type: 'PHONE', value: phone } }).then(result => {
      if (!result.data) {
        setError(result.error?.message ?? 'Could not verify phone, please try again.')
        return
      }
      if (result.data?.contactUsed) {
        setError('Email or phone number is already used.')
        setRedirectUrl(redirectToLoginUrl())
      } else {
        issueVerificationCode()
      }
    })
  }

  const resendCode = () => {
    setError('')
    setHelperText('Please confirm your number')
    setStep('phone')
  }

  const verifyCode = () => {
    setError('')
    confirmVerificationCode()
  }

  /**
   * If the user is already registered, redirect them to the login page with
   * a custom header shown
   */
  const redirectToLoginUrl = (handleId?: string) => {
    const redirectSearchParams = new URLSearchParams(location.search)
    redirectSearchParams.append('signinLoginReturn', 'true')

    const postLoginLocation = loginLocation(
      {
        ...location,
        search: redirectSearchParams.toString()
      },
      { header: 'ALREADY_REGISTERED', handleId: handleId }
    )

    return postLoginLocation
  }

  return {
    question,
    step,
    submitEmail,
    submitPhone,
    resendCode,
    verifyCode,
    finalize,
    loading:
      registerCustomerLoading ||
      issueVerificationCodeLoading ||
      confirmVerificationCodeLoading ||
      contactUsedLoading,
    error,
    helperText,
    redirectURL,
    promotionPayload,
    categoryShortSingularDisplayName: categoryQuery?.category.shortSingularDisplayName.toLowerCase()
  }
}
