import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useFindContactQuery } from '../../../FindContact.generated'
import { AuthenticatedUserFragment } from '../../../../../fragments/AuthenticatedUser.generated'
import { AuthIntent, AuthenticationFlowViewLayoutProps } from '../../../index'
import OAuthButtons from '../../../components/OAuthButtons'
import EmailOrPhoneLoginField from '../../../components/EmailOrPhoneLoginField/v1'
import AuthenticationFlowViewLayout from '../../../components/AuthenticationFlowViewLayout/v1'
import styles from './styles.css'

export interface LoginFormProps extends AuthenticationFlowViewLayoutProps {
  onIntentChanged: (intent: AuthIntent) => void
  onLogin: (user: AuthenticatedUserFragment) => void
  handleId?: string
}

export const LoginForm: React.FC<LoginFormProps> = ({
  onLogin,
  onIntentChanged,
  standalone,
  handleId,
  headline = 'Welcome Back',
  subHeadline = 'Login to access this page',
  ...props
}) => {
  const [googleAuthEnforced, setGoogleAuthEnforced] = useState(false)
  const { data, loading } = useFindContactQuery({
    variables: {
      contactId: handleId!
    },
    skip: !handleId
  })

  return (
    <AuthenticationFlowViewLayout
      headline={headline}
      subHeadline={subHeadline}
      standalone={standalone}
      bottomSection={
        <>
          {!googleAuthEnforced && (
            <div className={styles.or}>
              <span>OR</span>
            </div>
          )}

          <OAuthButtons
            onFailure={toast.error}
            googleAuthOnly={googleAuthEnforced}
            onSuccess={(user: AuthenticatedUserFragment) => onLogin(user)}
          />
        </>
      }
      {...props}
    >
      <EmailOrPhoneLoginField
        key={data?.findContact?.value}
        onIntentChanged={onIntentChanged}
        onLogin={onLogin}
        loading={loading}
        defaultHandle={data?.findContact?.value}
        onGoogleAuthEnforced={enforced => setGoogleAuthEnforced(enforced)}
      />
    </AuthenticationFlowViewLayout>
  )
}
