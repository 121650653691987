import React, { useEffect, useRef } from 'react'
import { Grid, GridItem } from '@deal/components'
import styles from './styles.css'

interface MessageSentToastProps {
  headerText: string
  subheaderText: string
  duration: number
  onTimeout: () => void
}

const MessageSentToast: React.FC<React.PropsWithChildren<MessageSentToastProps>> = ({
  headerText,
  subheaderText,
  duration,
  onTimeout
}) => {
  const timeout = useRef<NodeJS.Timeout>()

  useEffect(() => {
    timeout.current = setTimeout(() => {
      onTimeout()
    }, duration)

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [])

  return (
    <Grid wrapperClassName={styles.messageConfirmationToastGridWrapper}>
      <GridItem className={styles.messageConfirmationToastGridItem} col={2}>
        <div className={styles.messageConfirmationToast}>
          {headerText}
          <div className={styles.messageConfirmationToastExpertPresence}>{subheaderText}</div>
          <div className={styles.messageConfirmationToastArrow} />
        </div>
      </GridItem>
    </Grid>
  )
}

export default MessageSentToast
