import React, { useEffect, useState } from 'react'
import { BroadcastChannel } from 'broadcast-channel'
import { relativeToAbsoluteUrl } from '#src/app/services/navigation'
import useLogoutCallback from './useLogoutCallback'
import useLoginCallback from './useLoginCallback'
import { useIdentityContext } from '.'

// Triggers the observer registration without forcing the component
// which includes it to re-render
const AuthChangeObserver: React.FC = () => {
  const { myself } = useIdentityContext()
  const [authChannel, setAuthChannel] = useState<any>(null)

  /**
   * On mount, reigster a new broadcast channel in state. We store this in state so the
   * useEffect listeners can recognize when the auth channel is ready.
   */
  useEffect(() => {
    setAuthChannel(new BroadcastChannel('deal-consumer-auth'))
  }, [])

  /**
   * Register the `onmessage` listener for auth broadcasts. This will respond to any auth change
   * and update the tab, this allows us to log out (or in) when other tabs update the identity
   */
  useEffect(() => {
    if (authChannel) {
      authChannel.onmessage = (message: any) => {
        const currentUserId = myself ? myself.user.id : null
        const newUserId = message.userId

        if (currentUserId !== newUserId) {
          // Redirect the user to the homepage / department page if logging out / logging in.
          window.location.href = relativeToAbsoluteUrl('/')
        }
      }
    }
  }, [myself?.user.id, authChannel])

  /**
   * If at any point the user's identity changes, broadcast a message to update the other tabs of the
   * new identity or lack thereof. Since the redirect only happens when the identity changes, there
   * is no harm in 'over broadcasting'.
   */
  useEffect(() => {
    if (authChannel) {
      authChannel.postMessage({ userId: myself ? myself.user.id : null })
    }
  }, [myself?.user.id, authChannel])

  /**
   * Any time the user logs in or out, broadcast an authentication change
   */
  useLoginCallback(newMyself => {
    if (authChannel) {
      authChannel.postMessage({ userId: newMyself?.user.id })
    }
  })

  useLogoutCallback(() => {
    if (authChannel) {
      authChannel.postMessage({ userId: null })
    }
  })

  return null
}

export default AuthChangeObserver
