import numeral from 'numeral'
import repeat from 'lodash/repeat'
import omit from 'lodash/omit'
import cloneDeep from 'lodash/cloneDeep'
import { FacetSelection as GraphQLFacetSelection } from '#src/generated/types'
import { UnnamedFacetSelection } from '#src/app/components/Facet'
import {
  DisplayFacetFragment,
  NumericRangeDisplayFacetFragment
} from '../../fragments/Facets.generated'
import { FacetSelectionsMap } from './types'

/**
 * Transforms display facets into a FacetSelectionsMap
 *
 * This lets us return the decorated DisplayFacet from the API and map it into
 *   a `FacetSelectionsMap`. The facet selections can then be fed directly into
 *   one of the URL builder utilities to generate a category page, PLP, or SRP
 *   URL.
 */
export const displayFacetsToFacetSelectionsMap = (
  displayFacets: DisplayFacetFragment[]
): FacetSelectionsMap => {
  if (displayFacets.length === 0) {
    return {}
  }

  const facetMap: { [key: string]: any } = {}

  displayFacets.forEach(decoratedFacet => {
    const facetName = decoratedFacet.alias || decoratedFacet.name

    if (decoratedFacet.__typename === 'BucketedDisplayFacet') {
      facetMap[facetName] = {
        name: decoratedFacet.alias || decoratedFacet.name,
        alias: decoratedFacet.alias,
        bucketValues: decoratedFacet.buckets.map(bucket => bucket.value)
      }
    } else if (decoratedFacet.__typename === 'NumericRangeDisplayFacet') {
      facetMap[facetName] = {
        name: decoratedFacet.alias || decoratedFacet.name,
        alias: decoratedFacet.alias,
        lower: decoratedFacet.lower,
        upper: decoratedFacet.upper
      }
    }
  })

  return facetMap
}

/**
 * Safely convert a map of facet selections into an arrayy of FacetSelectionInputs, for
 *   passing into various GraphQL APIs
 */
export function facetSelectionsMapToGraphQLInput(
  selections: FacetSelectionsMap
): GraphQLFacetSelection[] {
  return Object.values(cloneDeep(selections)).map(selection => {
    return omit(selection, 'alias', 'displayName')
  })
}

/**
 * Format a single selection value for a NumericRangeDisplayFacet in a format suitable
 *   for use as a label (i.e. abbreviated)
 */
export function formatNumericFacetValueAsLabel(
  value: number,
  facet: NumericRangeDisplayFacetFragment
) {
  const scale = facet.scale
  const upper = parseInt(facet.upper, 10)
  const isAboveUpperCap = value >= upper && facet.upperCapped
  const suffix = isAboveUpperCap ? '+' : ''

  switch (facet.type) {
    case 'USD_CURRENCY':
      // Use abbreviations for values of more than $10,000
      return numeral(value / 100).format(value / 100 >= 10000 ? '($0a)' : '$0,0') + suffix

    default: {
      // Scale the stored to it's actual representation, and include that many decimals
      const format = scale > 0 ? `0,0.${repeat('0', scale)}` : '0,0'
      return numeral(value * Math.pow(10, -scale)).format(format) + suffix
    }
  }
}

/**
 * Format a FacetSelection using `formatNumericFacetValueAsLabel`
 */
export function formatNumericFacetSelectionAsLabel(
  selection: UnnamedFacetSelection,
  facet: NumericRangeDisplayFacetFragment
) {
  const lowerLabel = selection.lower && formatNumericFacetValueAsLabel(selection.lower, facet)
  const upperLabel = selection.upper && formatNumericFacetValueAsLabel(selection.upper, facet)

  if (lowerLabel && upperLabel) {
    return `${lowerLabel} - ${upperLabel}`
  } else if (lowerLabel) {
    return `${lowerLabel}+`
  } else if (upperLabel) {
    return `Up to ${upperLabel}`
  } else {
    return 'Any'
  }
}

export const getSellableSearchResultsCountLabel = (totalCount: number, maximumExceeded: boolean) =>
  maximumExceeded
    ? '10,000+ results'
    : `${numeral(totalCount).format('0,0')} result${totalCount > 1 ? 's' : ''}`
