import React from 'react'
import { CreatePathProps, useCreatePath } from '@deal/path-flow'
import { Modal, ModalProps, Text } from '@deal/bluxome'
import { CreatePathGraphFlowInput } from '#src/generated/types'
import { BusinessUserForChatFragment } from '#src/app/fragments/BusinessUserForChat.generated'
import { ExpertNameTag } from '#src/app/components/ExpertNameTag'

export interface RequestPathInterstitialModalProps
  extends Pick<ModalProps, 'overlayProps' | 'state'> {
  expert: BusinessUserForChatFragment
  createPathProps: CreatePathProps
  createPathInput: CreatePathGraphFlowInput
}

export const RequestPathInterstitialModal: React.FC<RequestPathInterstitialModalProps> = ({
  state,
  overlayProps,
  expert,
  createPathProps,
  createPathInput
}) => {
  const [createPath, { loading }] = useCreatePath(createPathProps)

  return (
    <Modal
      state={state}
      overlayProps={overlayProps}
      title="Before we proceed"
      width="small"
      actions={{
        color: 'learn-blue',
        size: 'small',
        primaryAction: {
          label: `I'll stick with ${expert.shortName}`,
          ...(expert.existingLeadForConsumer
            ? {
                link: {
                  to: `/inbox/${expert.existingLeadForConsumer.conversationId}`,
                  onClick: state.close
                }
              }
            : { onPress: state.close }),
          isDisabled: loading
        },
        tertiaryAction: {
          label: 'Connect with a new expert',
          onPress: () => createPath(createPathInput),
          isLoading: loading
        }
      }}
    >
      <Text color="neutrals-600" style="small">
        You have an active conversation with {expert.displayName}.
      </Text>
      <div className="mt-1 mb-2">
        <ExpertNameTag expert={expert} />
      </div>
      <Text style="base-loose">
        Do you want to continue your conversation with {expert.shortName}, or get connected with a
        new expert? Connecting with a new expert will start a new conversation.
      </Text>
    </Modal>
  )
}
