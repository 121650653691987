import React from 'react'
import loadable from '@loadable/component'
import { useIdentityContext } from '#src/app/containers/Identity'
import Page from '#src/app/components/Page'
import { useInboxRouteQuery } from './InboxRoute.generated'
import LoadingComponent from '../loading'

const PageComponent = loadable(() => import(/* webpackChunkName: "inbox" */ './page'), {
  resolveComponent: components => components.Inbox
})

const Inbox: React.FC = () => {
  const { myself } = useIdentityContext()
  const query = useInboxRouteQuery({
    variables: {
      consumerId: myself!.user.id
    }
  })

  return (
    <Page
      fixed
      query={query}
      pageKey="inbox"
      chat={false}
      seoTitle={undefined}
      seoDescription={undefined}
      seoIndexable={false}
      expert={undefined}
      category={undefined}
      department={undefined}
      sellable={undefined}
      pageComponent={PageComponent}
      loadingComponent={LoadingComponent}
      ogImageUrl={undefined}
      canonicalPath={undefined}
    />
  )
}

export default Inbox
