import { toast } from 'react-toastify'
import keys from 'lodash/keys'
import { guestCartStore } from '#src/app/stores'
import { OrderLineItemForGroupLineItemsFragment } from './OrderLineItemForGroupLineItems.generated'

export function getFilteredLineItems<T extends OrderLineItemForGroupLineItemsFragment>(
  lineItems: T[]
) {
  return lineItems.filter(
    lineItem =>
      !['OrderLineItemShipmentSource', 'OrderLineItemExpertTipSource'].includes(
        lineItem.source.__typename
      )
  )
}

export interface GroupedLineItemsType<T extends OrderLineItemForGroupLineItemsFragment> {
  parentLineItem: T
  children: T[]
}

export function groupLineItems<T extends OrderLineItemForGroupLineItemsFragment>({
  lineItems,
  includeTip = false,
  includeShipping = false
}: {
  lineItems: T[]
  includeTip?: boolean
  includeShipping?: boolean
}) {
  const orderLineItemTree: {
    [key: string]: GroupedLineItemsType<T>
  } = {}

  lineItems.forEach(orderLineItem => {
    if (!includeTip && orderLineItem.source.__typename === 'OrderLineItemExpertTipSource') return
    if (!includeShipping && orderLineItem.source.__typename === 'OrderLineItemShipmentSource')
      return

    if (orderLineItem.isGroup) {
      // If it's a group, it gets it's own ID, overwrite?
      orderLineItemTree[orderLineItem.id] = {
        parentLineItem: orderLineItem,
        children: []
      }
    } else {
      if (orderLineItem.parentId && orderLineItemTree[orderLineItem.parentId]) {
        orderLineItemTree[orderLineItem.parentId].children.push(orderLineItem)
      } else {
        orderLineItemTree[orderLineItem.id] = { parentLineItem: orderLineItem, children: [] }
      }
    }
  })

  return keys(orderLineItemTree).map(lineItemTree => orderLineItemTree[lineItemTree])
}

export type GuestCartSellable = {
  id: string
  quantity: number
}

export type GuestOrder = {
  sellables: GuestCartSellable[]
}

export function getGuestCartContents(): GuestOrder {
  const guestOrder: GuestOrder = guestCartStore.get('cart')

  const sellables: GuestCartSellable[] = []

  /**
   * Since we're transitioning the shape of the guest cart, we need to support
   * how existing carts data structures are shaped and move sellables from the
   * top level into a `sellables` array. Once transitioned, the guest order
   * will no longer be an array.
   */
  if (Array.isArray(guestOrder)) {
    guestOrder.forEach((item: GuestCartSellable) => {
      if (item.id) {
        sellables.push(item)
      }
    })

    return { sellables }
  } else if (guestOrder) {
    return {
      sellables: guestOrder.sellables || []
    }
  } else {
    return {
      sellables: []
    }
  }
}

export function addGuestCartSellable(sellableId: string) {
  const guestCartContents = getGuestCartContents()

  // Check if this already exists so we don't add duplicates
  const mutableSellables = guestCartContents.sellables
  const existingSellableIndex = mutableSellables.findIndex(
    guestSellable => guestSellable.id === sellableId
  )

  // If it exists, just increment the quantity instead of adding a new entry
  if (existingSellableIndex !== -1) {
    mutableSellables[existingSellableIndex].quantity =
      mutableSellables[existingSellableIndex].quantity + 1
  } else {
    mutableSellables.push({
      id: sellableId,
      quantity: 1
    })
  }

  guestCartStore.set('cart', {
    sellables: mutableSellables
  })
}

export function removeGuestCartSellable(sellableId: string) {
  const guestCartContents = getGuestCartContents()

  const updatedGuestCartSellables = guestCartContents.sellables.filter(
    guestSellable => guestSellable.id !== sellableId
  )

  if (guestCartContents.sellables.length === updatedGuestCartSellables.length) {
    toast.error('Error removing that product!')
  }

  guestCartStore.set('cart', {
    sellables: updatedGuestCartSellables
  })
}

export function updateGuestCartSellableQuantity(sellableId: string, quantity: number) {
  const guestCartContents = getGuestCartContents()

  const mutableSellables = guestCartContents.sellables
  const sellableIndexToUpdate = mutableSellables.findIndex(
    guestSellable => guestSellable.id === sellableId
  )

  if (!mutableSellables[sellableIndexToUpdate]) {
    return
  }

  mutableSellables[sellableIndexToUpdate].quantity = quantity

  guestCartStore.set('cart', {
    sellables: mutableSellables
  })
}

export function resetGuestCart() {
  guestCartStore.remove('cart')
}
