import React from 'react'
import { withStatusCode } from '#src/app/containers/Router'
import { Error } from '../Error'

const InternalError = () => (
  <Error
    code="500"
    headline="Internal server error."
    redirectButtonLink="/"
    redirectButtonColor="neutral-dark"
  />
)

export default withStatusCode(500)(InternalError)
