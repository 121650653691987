import React, { ForwardRefRenderFunction, useState } from 'react'
import { TextField, TextFieldProps } from '@deal/bluxome'
import type { default as zxcvbn } from 'zxcvbn'

interface PasswordFieldProps extends TextFieldProps {
  showStrength?: boolean
  onFieldValid?: () => void
  onFieldInvalid?: () => void
}

let zxcvbnPromise: Promise<typeof zxcvbn> | undefined

const PasswordField: ForwardRefRenderFunction<HTMLInputElement, PasswordFieldProps> = (
  { showStrength = false, onChange, helperText, onFieldValid, onFieldInvalid, ...props },
  ref
) => {
  const [strength, setStrength] = useState<number>()

  // Lazy-load the `zxcvbn` library on focus. It is very large.
  const loadZxcvbn = () => {
    if (!zxcvbnPromise) {
      zxcvbnPromise = import(/* webpackChunkName: 'zxcvbn' */ 'zxcvbn').then(value => {
        return value.default
      })
    }
  }

  const handleChange = (value: string) => {
    const isValid = value.length >= 6
    if (isValid) {
      onFieldValid?.()
    } else {
      onFieldInvalid?.()
    }

    if (showStrength && zxcvbnPromise) {
      zxcvbnPromise.then(zxcvbn => {
        const zxcvbnResult = zxcvbn(value)

        setStrength(zxcvbnResult.score)
      })
    }

    onChange?.(value)
  }

  let status: string | undefined
  switch (strength) {
    case 0:
      status = 'Strength: too weak 😞'
      break
    case 1:
      status = 'Strength: not great 😕'
      break
    case 2:
      status = 'Strength: okay 🙂'
      break
    case 3:
      status = 'Strength: nice! 😊'
      break
    case 4:
      status = 'Strength: well done! 😎'
      break
  }

  return (
    <TextField
      type="password"
      placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
      onChange={handleChange}
      onFocus={loadZxcvbn}
      helperText={status || helperText}
      ref={ref}
      {...props}
    />
  )
}

const ForwardedPasswordField = React.forwardRef(PasswordField)

export { ForwardedPasswordField as PasswordField }
