// @ts-nocheck
import type * as Types from '../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type StartSmsPromotionRegistrationForSmsModalTemplateMutationVariables = Types.Exact<{
  input: Types.StartSmsPromotionRegistrationInput;
}>;


export type StartSmsPromotionRegistrationForSmsModalTemplateMutation = { __typename: 'Mutation', startSmsPromotionRegistration: { __typename: 'StartSmsPromotionRegistrationPayload', smsText: string, smsNumber: string, registrationId: string } };

export type StartSmsPromotionRegistrationPayloadForSmsModalTemplateFragment = { __typename: 'StartSmsPromotionRegistrationPayload', smsText: string, smsNumber: string, registrationId: string };

export const StartSmsPromotionRegistrationPayloadForSmsModalTemplateFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"StartSmsPromotionRegistrationPayloadForSMSModalTemplate"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"StartSmsPromotionRegistrationPayload"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"smsText"}},{"kind":"Field","name":{"kind":"Name","value":"smsNumber"}},{"kind":"Field","name":{"kind":"Name","value":"registrationId"}}]}}]} as unknown as DocumentNode;
export const StartSmsPromotionRegistrationForSmsModalTemplateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"StartSmsPromotionRegistrationForSMSModalTemplate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"StartSmsPromotionRegistrationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"startSmsPromotionRegistration"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"StartSmsPromotionRegistrationPayloadForSMSModalTemplate"}}]}}]}},...StartSmsPromotionRegistrationPayloadForSmsModalTemplateFragmentDoc.definitions]} as unknown as DocumentNode;
export type StartSmsPromotionRegistrationForSmsModalTemplateMutationFn = Apollo.MutationFunction<StartSmsPromotionRegistrationForSmsModalTemplateMutation, StartSmsPromotionRegistrationForSmsModalTemplateMutationVariables>;
export type StartSmsPromotionRegistrationForSmsModalTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<StartSmsPromotionRegistrationForSmsModalTemplateMutation, StartSmsPromotionRegistrationForSmsModalTemplateMutationVariables>, 'mutation'>;

    export const StartSmsPromotionRegistrationForSmsModalTemplateComponent = (props: StartSmsPromotionRegistrationForSmsModalTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<StartSmsPromotionRegistrationForSmsModalTemplateMutation, StartSmsPromotionRegistrationForSmsModalTemplateMutationVariables> mutation={StartSmsPromotionRegistrationForSmsModalTemplateDocument} {...props} />
    );
    

/**
 * __useStartSmsPromotionRegistrationForSmsModalTemplateMutation__
 *
 * To run a mutation, you first call `useStartSmsPromotionRegistrationForSmsModalTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSmsPromotionRegistrationForSmsModalTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSmsPromotionRegistrationForSmsModalTemplateMutation, { data, loading, error }] = useStartSmsPromotionRegistrationForSmsModalTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartSmsPromotionRegistrationForSmsModalTemplateMutation(baseOptions?: Apollo.MutationHookOptions<StartSmsPromotionRegistrationForSmsModalTemplateMutation, StartSmsPromotionRegistrationForSmsModalTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartSmsPromotionRegistrationForSmsModalTemplateMutation, StartSmsPromotionRegistrationForSmsModalTemplateMutationVariables>(StartSmsPromotionRegistrationForSmsModalTemplateDocument, options);
      }
export type StartSmsPromotionRegistrationForSmsModalTemplateMutationHookResult = ReturnType<typeof useStartSmsPromotionRegistrationForSmsModalTemplateMutation>;
export type StartSmsPromotionRegistrationForSmsModalTemplateMutationResult = Apollo.MutationResult<StartSmsPromotionRegistrationForSmsModalTemplateMutation>;
export type StartSmsPromotionRegistrationForSmsModalTemplateMutationOptions = Apollo.BaseMutationOptions<StartSmsPromotionRegistrationForSmsModalTemplateMutation, StartSmsPromotionRegistrationForSmsModalTemplateMutationVariables>;