import React from 'react'
// @ts-ignore TODO: write typings for react-hot-keys
import HotKeys from 'react-hot-keys'
import { Grid, GridItem } from '@deal/components'
import styles from './styles.css'

interface GridOverlayProps {}
interface GridOverlayState {
  visible: boolean
}

// Persist the visibility state outside the module so it survives unmounting/remounting
let isVisible = false

export default class GridOverlay extends React.Component<GridOverlayProps, GridOverlayState> {
  public constructor(props: GridOverlayProps) {
    super(props)

    this.state = {
      visible: false
    }

    this.handleHotKeyDown = this.handleHotKeyDown.bind(this)
  }

  public componentDidMount() {
    if (isVisible) {
      this.setState({
        visible: true
      })
    }
  }

  public handleHotKeyDown(_keyName: string, _e: React.KeyboardEvent) {
    this.setState(
      prevState => ({
        visible: !prevState.visible
      }),
      () => {
        isVisible = this.state.visible
      }
    )

    return false
  }

  public render() {
    if (process.env.NODE_ENV !== 'development') {
      return null
    }

    return (
      <HotKeys keyName="command+option+g,ctrl+alt+g" onKeyDown={this.handleHotKeyDown.bind(this)}>
        {this.state.visible && (
          <Grid wrapperClassName={styles.grid}>
            <GridItem className={styles.column}>1</GridItem>
            <GridItem className={styles.column}>2</GridItem>
            <GridItem className={styles.column}>3</GridItem>
            <GridItem className={styles.column}>4</GridItem>
            <GridItem className={styles.column}>5</GridItem>
            <GridItem className={styles.column}>6</GridItem>
            <GridItem className={styles.column}>7</GridItem>
            <GridItem className={styles.column}>8</GridItem>
            <GridItem className={styles.column}>9</GridItem>
            <GridItem className={styles.column}>10</GridItem>
            <GridItem className={styles.column}>11</GridItem>
            <GridItem className={styles.column}>12</GridItem>
          </Grid>
        )}
      </HotKeys>
    )
  }
}
