import React, { useEffect } from 'react'
import { Link } from '@deal/router'
import { ButtonVariant } from '@deal/components/es/components/Button'
import { BackgroundImage, Button, Modal } from '@deal/components'
import smsLink from '#src/app/services/smsLink'
import { ComponentValue, getTextStyles } from '#src/app/services/pageTemplate'
import clientOnly from '#src/app/hocs/clientOnly'
import { SessionAssociatedEvent } from '#src/app/events/SessionAssociatedEvent'
import { useUserAgentContext } from '#src/app/containers/UserAgent'
import { useAnalyticsContext } from '#src/app/containers/Analytics'
import {
  StartSmsPromotionRegistrationPayloadForSmsModalTemplateFragment,
  useStartSmsPromotionRegistrationForSmsModalTemplateMutation
} from './SMSModalTemplate.generated'
import styles from './styles.css'

interface SMSModalTemplateProps {
  isOpen: boolean
  onDismiss: () => void
  onSubmit?: () => void
  componentValue: { [key: string]: ComponentValue }
  categoryId: string
  pageTemplateRevisionId: String
}

interface SMSModalProps {
  isOpen: boolean
  onDismiss: () => void
  onSubmit?: () => void
  componentValue: { [key: string]: ComponentValue }
  data?: StartSmsPromotionRegistrationPayloadForSmsModalTemplateFragment
  os?: UAParser.IOS
}

enum ParamName {
  Title = 'Title',
  Description = 'Description',
  CTAButton = 'CTA Button',
  BackgroundImage = 'Background'
}

export const SMSModal: React.FC<SMSModalProps> = ({
  isOpen,
  onDismiss,
  onSubmit,
  componentValue,
  data,
  os
}) => {
  const backgroundImageUrl = componentValue[ParamName.BackgroundImage]?.imageUrl

  const content = (
    <div className={styles.content}>
      <div className={styles.title} style={getTextStyles(componentValue[ParamName.Title])}>
        {componentValue[ParamName.Title]?.text}
      </div>
      <div
        className={styles.description}
        style={getTextStyles(componentValue[ParamName.Description])}
      >
        {componentValue[ParamName.Description]?.text}
      </div>
      <div>
        {data && os ? (
          <a
            href={smsLink({
              os,
              phone: data.smsNumber,
              body: data.smsText,
              encodePhoneNumber: false
            })}
          >
            <Button
              size="large"
              className={styles.mainButton}
              onClick={onSubmit}
              variant={componentValue[ParamName.CTAButton]?.buttonType as ButtonVariant}
            >
              {componentValue[ParamName.CTAButton]?.text}
            </Button>
          </a>
        ) : (
          <Button
            size="large"
            className={styles.mainButton}
            onClick={onSubmit}
            variant={componentValue[ParamName.CTAButton]?.buttonType as ButtonVariant}
          >
            {componentValue[ParamName.CTAButton]?.text}
          </Button>
        )}
      </div>
      <div className={styles.details}>
        Msg and data rates may apply. Msg frequency varies. Reply HELP for help and STOP to cancel.
        See &nbsp;
        <Link target="_blank" to="/legal/terms-conditions" className={styles.link}>
          Terms
        </Link>
        &nbsp;and &nbsp;
        <Link to="/legal/privacy-policy" target="_blank" className={styles.link}>
          Privacy
        </Link>
        .
      </div>
    </div>
  )

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onDismiss}
      width="xsmall"
      contentSpacing={false}
      contentLabel={componentValue[ParamName.Title]?.text || ''}
    >
      {backgroundImageUrl ? (
        <BackgroundImage
          contentClassName={styles.backgroundContent}
          containerClassName={styles.backgroundContainer}
          size="100%"
          src={backgroundImageUrl}
          htmlAttributes={{ alt: componentValue[ParamName.BackgroundImage]?.altText }}
          imgixParams={{
            fit: 'crop'
          }}
        >
          <div></div>
          {content}
        </BackgroundImage>
      ) : (
        <div>{content}</div>
      )}
    </Modal>
  )
}

const SMSModalTemplate: React.FC<SMSModalTemplateProps> = ({
  isOpen,
  onSubmit,
  onDismiss,
  componentValue,
  categoryId,
  pageTemplateRevisionId
}) => {
  const userAgent = useUserAgentContext()
  const analytics = useAnalyticsContext()
  const os = userAgent?.os

  const [startSmsPromotionRegistrationMutation, { data }] =
    useStartSmsPromotionRegistrationForSmsModalTemplateMutation({
      onCompleted: data => {
        if (data.startSmsPromotionRegistration.registrationId) {
          analytics?.track(
            new SessionAssociatedEvent({
              context_id: data.startSmsPromotionRegistration.registrationId,
              reason: 'Start prefilled sms registration'
            })
          )
        }
      }
    })

  useEffect(() => {
    startSmsPromotionRegistrationMutation({
      variables: {
        input: {
          startPage: window.location.href,
          pageTemplateRevisionId: pageTemplateRevisionId,
          categoryId
        }
      }
    })
  }, [])

  if (data && os) {
    return (
      <SMSModal
        os={os}
        isOpen={isOpen}
        onDismiss={onDismiss}
        onSubmit={onSubmit}
        componentValue={componentValue}
        data={data.startSmsPromotionRegistration}
      />
    )
  } else {
    return null
  }
}

export default clientOnly(SMSModalTemplate)
