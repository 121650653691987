import { TrackingQueryParameter } from '@deal/web-tracking/constants'
import type { ConsumerTracking, TouchPointAttributes } from '@deal/web-tracking'
import { ContactType } from '#src/generated/types'
import { NormalizationOptions, normalizeSellablePricing } from '#src/app/services/sellable'
import { TrackableOrderFragment } from '#src/app/routes/checkout/fragments/TrackableOrder.generated'
import { TrackableSellableFragment } from '#src/app/fragments/TrackableSellable.generated'
import { MyselfFragment } from '#src/app/containers/Identity/Myself.generated'
import { CategoryForGetMarketableCategoryFromCategoryHierarchyFragment } from './CategoryForGetMarketableCategoryFromCategoryHierarchy.generated'

// These categories have active paid media campaigns. We use this list to
//   determine which pixel/conversion event to fire based on an event that
//   has some associated category data. For example, if a user adds a ski
//   pole to their cart, we will fire a conversion associated with a campaign
//   for the "skiing" category, because that is the most granular level at
//   which we advertise.
const MARKETABLE_CATEGORIES = [
  'camping',
  'cycling',
  'conventional-fishing',
  'fly-fishing',
  'golf',
  'skiing',
  'snowboarding',
  'tennis',
  'coffee-espresso',
  'baby-toddler',
  'grills-outdoor-kitchens'
]

export function getMarketableCategorySlugFromCategoryHierarchy(
  category: CategoryForGetMarketableCategoryFromCategoryHierarchyFragment
) {
  const categoryHierarchy = [...category.parents, category].reverse()

  return categoryHierarchy.find(category => MARKETABLE_CATEGORIES.includes(category.slug))?.slug
}

export function getSellableEcommerceProperties(
  sellable: TrackableSellableFragment,
  additionalData?: {},
  pricingNormalizationOptions: NormalizationOptions = {}
): ConsumerTracking.SellableProperties {
  const sellableCategory = sellable.categories[0]
  const normalizedPricing = normalizeSellablePricing(sellable, pricingNormalizationOptions)
  const marketableCategorySlug = getMarketableCategorySlugFromCategoryHierarchy(sellableCategory)

  return {
    brand: sellable.brand?.displayName,
    brand_id: sellable.brand?.id,
    category: sellableCategory.slug,
    category_id: sellableCategory.id,
    marketable_category_slug: marketableCategorySlug,
    currency: 'USD',
    image_url: sellable.primaryImage?.url,
    name: sellable.title,
    product_id: sellable.feedId,
    price: normalizedPricing && normalizedPricing.price.amount,
    sellable_id: sellable.id,
    sku: sellable.googleId,
    url: `/sellable/${sellable.id}`,
    ...additionalData
  }
}

export function getOrderEcommerceProperties(
  order: TrackableOrderFragment,
  additionalData?: {}
): ConsumerTracking.OrderProperties {
  const products: ConsumerTracking.OrderProperties['products'] = []
  const promotionName = order.appliedPromotionEntry
    ? order.appliedPromotionEntry.promotion.code
    : undefined

  const isExpertAssisted = order.recommendTipPercentages.length > 0

  let mostExpensiveProduct: ConsumerTracking.SellableProperties | undefined
  let department: string | undefined
  order.lineItems.forEach(item => {
    if (item.source.__typename !== 'OrderLineItemSellableSource') {
      return null
    }

    const product = getSellableEcommerceProperties(item.source.sellable)

    // Build array of product tracking information
    // TODO: Track AdHoc sellables
    products.push({
      quantity: item.quantity,
      ...product
    })

    // Determine the department of the most expensive product in the order, and use that
    //   as the `department` property of the order itself.
    const productPrice = product.price ? parseFloat(product.price) : 0
    const mostExpensiveProductPrice = mostExpensiveProduct?.price
      ? parseFloat(mostExpensiveProduct.price)
      : 0

    if (!mostExpensiveProduct || productPrice > mostExpensiveProductPrice) {
      mostExpensiveProduct = product
      department = item.source.sellable.department?.slug
    }
  })

  let assumedMargin: number = 0
  order.lineItems.forEach(lineItem => {
    assumedMargin += lineItem.assumedMargin ? parseFloat(lineItem.assumedMargin.amount) : 0
  })

  let targetMargin: number = 0
  order.lineItems.forEach(lineItem => {
    targetMargin += lineItem.targetMargin ? parseFloat(lineItem.targetMargin.amount) : 0
  })

  const estimatedCM1 = Math.max(
    0,
    assumedMargin -
      parseFloat(order.estimatedExpertCommission.amount) -
      parseFloat(order.estimatedPaymentProcessingFee.amount)
  )

  return {
    coupon: promotionName,
    currency: 'USD',
    order_id: order.id,
    products,
    shipping: order.totalShippingCost ? order.totalShippingCost.amount : null,
    subtotal: order.subtotal.amount,
    tax: order.tax ? order.tax.amount : null,
    tip: order.tip ? order.tip.amount : null,
    total: order.totalCost.amount,
    value: order.totalCost.amount,
    assumed_margin: assumedMargin.toString(),
    target_margin: targetMargin.toString(),
    estimated_cm1: estimatedCM1.toString(),
    department: department!,
    expert_assisted: isExpertAssisted,
    ...additionalData
  }
}

export type UserRequestAttributes = {
  city?: string
  email?: string
  first_name?: string
  last_name?: string
  phone?: string
  state?: string
  zip?: string
}

export function getUserRequestAttributesFromMyself(
  myself?: MyselfFragment | null
): UserRequestAttributes {
  if (!myself) {
    return {}
  }

  const userPhone = myself.user.contacts.find(contact => contact.type === ContactType.PHONE)
  const userEmail = myself.user.contacts.find(contact => contact.type === ContactType.EMAIL)
  const userAddress = myself.user.address || null

  return {
    city: userAddress?.city || undefined,
    email: userEmail?.value,
    first_name: myself.realUser.firstName || undefined,
    last_name: myself.realUser.lastName || undefined,
    phone: userPhone?.value,
    state: userAddress?.state || undefined,
    zip: userAddress?.postalCode
  }
}

/**
 * This logic is copied from @deal/web-tracking/client/util/acquisitionAttributesFromUrl.
 *
 * That library is not compiled for Node/CommonJS so we can't import it directly.
 */
export function acquisitionAttributesFromUrl(urlString: string): TouchPointAttributes {
  const url = new URL(urlString)
  const queryString = url.search
  const parameters = new URLSearchParams(queryString)
  const attributes: TouchPointAttributes = {}

  // Sort the query parameters by increasing priority (see comment below).
  const prioritizedParameters: Array<[string, string]> = []
  parameters.forEach((queryParamValue, queryParamKey) => {
    prioritizedParameters.push([queryParamKey, queryParamValue])
  })
  prioritizedParameters.sort(([queryParamKeyA], [queryParamKeyB]) => {
    const priorityA = priorityFromQueryParameter(queryParamKeyA)
    const priorityB = priorityFromQueryParameter(queryParamKeyB)

    return priorityA - priorityB
  })

  // Iterate over the [key, value] tuples, setting the acquisition attributes.
  prioritizedParameters.forEach(([queryParamKey, queryParamValue]) => {
    if (!(queryParamKey in TrackingQueryParameter)) {
      return
    }

    let attributeKey =
      QUERY_PARAMETERS_TO_ACQUISITION_ATTRIBUTES[queryParamKey as TrackingQueryParameter]

    if (Array.isArray(attributeKey)) {
      attributeKey = attributeKey[0]
    }

    if (attributeKey) {
      attributes[attributeKey] = queryParamValue
    }
  })

  // If an `e` parameter exists in the URL, or if the URL matches `/e/:expert-vanity`, set
  //   the source to "expert_affiliate" and include the referring expert's vanity ID
  let referringExpertVanityId = parameters.get('e')
  const expertAffiliateUrlMatch = url.pathname.match(/^\/e\/([^/]+)\/?$/)
  if (expertAffiliateUrlMatch) {
    referringExpertVanityId = expertAffiliateUrlMatch[1]
  }

  if (referringExpertVanityId) {
    attributes.source = 'expert_affiliate'
    attributes.expertVanityId = referringExpertVanityId
  }

  // Unless specified otherwise, attribution is considered organic.
  if (!attributes.source) {
    attributes.source = 'organic'
  }

  return attributes
}

/**
 * A mapping of query paremeters to their corresponding touch point attributes.
 *
 * Multiple query parameters may map to the same attribute, so a priority may be specified
 *   by setting the value to a tuple of [attribute, priority]. Higher priorities take
 *   precedence during parsing. Attributes without a specified priority are considered to
 *   have a priority of 0.
 *
 * For example: "utm_source" (priority: 10) takes precedence over "c_source" (priority: 0)
 */
export const QUERY_PARAMETERS_TO_ACQUISITION_ATTRIBUTES: {
  [queryParam in TrackingQueryParameter]:
    | keyof TouchPointAttributes
    | [keyof TouchPointAttributes, number]
} = {
  ad_id: 'adId',
  ad_type: 'adType',
  ad_search_query: 'searchQuery',
  adgroup_id: 'adgroupId',
  expert_id: 'expertId',
  campaign_id: 'campaignId',
  department: 'department',
  gclid: 'googleClickId',
  fbclid: 'facebookClickId',
  fb_placement: ['placement', 10],
  fb_site_source_name: 'siteSourceName',
  product_id: 'productId',
  referrer_id: 'referrerId',
  target_id: 'targetId',
  c_adgroup: ['adgroup', 0],
  c_medium: ['medium', 0],
  c_campaign: ['campaign', 0],
  c_term: ['term', 0],
  c_content: ['content', 0],
  c_source: ['source', 0],
  c_id: ['campaignId', 0],
  utm_adgroup: ['adgroup', 10],
  utm_medium: ['medium', 10],
  utm_campaign: ['campaign', 10],
  utm_term: ['term', 10],
  utm_content: ['content', 10],
  utm_source: ['source', 10],
  utm_id: ['campaignId', 10],
  vt_match_type: 'matchType',
  vt_device: 'device',
  vt_device_model: 'deviceModel',
  vt_geo_location: 'geoLocation',
  vt_interest_location: 'interestLocation',
  vt_placement: ['placement', 0],
  vt_target_id: 'targetId',
  vt_ad_position: 'adPosition',
  vt_network: 'network'
}

/**
 * Given a query parameter, return the priority from the mapping above.
 */
function priorityFromQueryParameter(queryParam: string) {
  if (!(queryParam in TrackingQueryParameter)) {
    return 0
  }

  const attribute = QUERY_PARAMETERS_TO_ACQUISITION_ATTRIBUTES[queryParam as TrackingQueryParameter]

  if (Array.isArray(attribute)) {
    return attribute[1]
  }

  return 0
}

export const ACQUISITION_QUERY_PARAMETERS = Object.keys(QUERY_PARAMETERS_TO_ACQUISITION_ATTRIBUTES)
