import { useContext, useEffect } from 'react'
import { usePrevious } from '@deal/dom-hooks'
import { IdentityContext } from './index'

/**
 * Invoke a callback when a user logs out.
 */
export default function useLogoutCallback(callbackFn: () => void) {
  const { myself } = useContext(IdentityContext)
  const previousMyself = usePrevious(myself)

  useEffect(() => {
    if (!myself && previousMyself?.id) {
      callbackFn()
    }
  }, [myself?.id, previousMyself?.id])
}
