import React from 'react'
import { Link } from '@deal/router'
import { Button } from '@deal/components'
import { ContactType } from '#src/generated/types'
import { MaskedUserHandleFragment } from '#src/app/fragments/MaksedUserHandle.generated'
import { useIdentityContext } from '#src/app/containers/Identity'
import RouteWithStatusCode from '#src/app/components/RouteWithStatusCode'
import PageContent from '#src/app/components/PageContent'
import NoIndex from '#src/app/components/NoIndex'
import LinkIcon from './link.svg'
import styles from './styles.css'

interface EntityNotAllowedProps {
  entityName: string
  maskedUserHandlers: MaskedUserHandleFragment[]
  margins?: boolean
}

const EntityNotAllowed: React.FC<React.PropsWithChildren<EntityNotAllowedProps>> = ({
  children,
  entityName,
  maskedUserHandlers,
  margins
}) => {
  const { myself } = useIdentityContext()
  const contacts = myself?.user.contacts.filter(
    contact => contact.type === ContactType.EMAIL || contact.type === ContactType.PHONE
  )

  return (
    <RouteWithStatusCode statusCode={404}>
      <NoIndex />
      <PageContent margins={margins} className={styles.wrapper}>
        <div className={styles.copy}>
          <div className={styles.header}>You are not allowed to view this {entityName}</div>
          <div className={styles.body}>
            {maskedUserHandlers.length > 0 ? (
              <>
                {contacts && contacts.length > 0 && (
                  <div>
                    You are currently logged in to the account associated with{' '}
                    {contacts.map(contact => contact.value).join(' and ')}.
                  </div>
                )}
                <div>
                  This {entityName} belongs to the account associated with{' '}
                  {maskedUserHandlers.map(handler => handler.maskedHandle).join(' and ')}.
                </div>
                <div>
                  If you are the owner of that account, please log out and then log in with the
                  correct credential.
                </div>
                <Link to="/auth/logout">
                  <Button variant="neutral-dark-ghost">Log out</Button>
                </Link>
              </>
            ) : (
              <>
                <div>
                  You don't have permission to view this {entityName}. It belongs to a different
                  user account.
                </div>
                <Link to="/">
                  <Button variant="neutral-dark-ghost">Take me home</Button>
                </Link>
              </>
            )}
          </div>
        </div>
        <div className={styles.icon}>
          <LinkIcon className={styles.linkIcon} />
        </div>
      </PageContent>
      {children}
    </RouteWithStatusCode>
  )
}

export default EntityNotAllowed
