import React, { useEffect } from 'react'
import { Overlay } from 'react-aria'
import classnames from 'classnames'
import { useHistory } from '@deal/router'
import { useExperiment } from '@deal/experiment-js'
import { useBodyScrollLock } from '@deal/dom-hooks'
import { Button, Divider } from '@deal/bluxome'
import { useBreakpoint } from '#src/app/hooks/useBreakpoint'
import { useChatWidgetPathContext } from '#src/app/context/chatWidgetPath'
import { useChatWidgetStateContext } from '#src/app/context/chat-widget-state'
import { useIdentityContext } from '#src/app/containers/Identity'
import { BusinessUserForChatWidgetBaseFragment } from './ChatWidgetBase.generated'
import { SwitchToRealExpertButton } from '../SwitchToRealExpertButton'
import { useExpandChatOnceOnUnreadMessages } from './useExpandChatOnceOnUnreadMessages'
import { ChatWidgetHeaderText } from './Header'
import CloseIcon from './close.svg'
import { ChatWidgetOAuth } from './ChatWidgetOAuth'
import styles from './styles.css'

export interface ChatWidgetBaseProps {
  expert: BusinessUserForChatWidgetBaseFragment
  isAI?: boolean
}

export const ChatWidgetBase: React.FC<React.PropsWithChildren<ChatWidgetBaseProps>> = ({
  expert,
  isAI = false,
  children
}) => {
  const history = useHistory()
  const isLargerThanMd = useBreakpoint('md', 'bluxome')
  const { myself, incompleteUser } = useIdentityContext()
  const { isChatWidgetExpanded, isChatWidgetPreview, minimizeChatWidget, expandChatWidget } =
    useChatWidgetStateContext()
  useExpandChatOnceOnUnreadMessages()

  const { isInPath, setPathUrl } = useChatWidgetPathContext()

  const isVisible = isChatWidgetExpanded || isChatWidgetPreview

  // lock the body scroll when expanded on mobile
  useBodyScrollLock({ skip: isLargerThanMd || !isVisible })

  // minimize the widget if the user navigates on mobile
  useEffect(() => {
    if (!isLargerThanMd) {
      const unlisten = history.listen(() => minimizeChatWidget('navigation'))
      return unlisten
    }
  }, [history, isLargerThanMd, minimizeChatWidget])

  const isExpertAiAuthEnabled =
    useExperiment({
      experiment: 'consumer-expert-ai-chat-oauth',
      defaultTreatment: 'control',
      options: { skip: !isAI || !(myself || incompleteUser) }
    }).result === 'on'

  return (
    <Overlay>
      <div className={styles.container}>
        <div
          className={classnames(styles.overlay, {
            [styles.overlayVisible]: isVisible
          })}
          onClick={() => minimizeChatWidget('user-interaction-overlay')}
        />
        <div
          className={classnames(styles.chatWidgetContainer, {
            [styles.chatWidgetExpanded]: isChatWidgetExpanded,
            [styles.chatWidgetPreview]: isChatWidgetPreview
          })}
        >
          <div
            className={styles.chatHeader}
            onClick={() => minimizeChatWidget('user-interaction-header')}
          >
            <ChatWidgetHeaderText expert={isAI ? 'AI' : expert} isVisible={isVisible} />
            <div className={styles.chatHeaderActions}>
              {isInPath ? (
                <Button
                  style="text"
                  size="small"
                  color="neutral-light"
                  isInverted
                  onPress={() => {
                    setPathUrl(undefined)
                  }}
                >
                  Exit questionnaire
                </Button>
              ) : isAI ? (
                <SwitchToRealExpertButton
                  style="text"
                  size="small"
                  color="neutral-light"
                  isInverted
                  expert={expert}
                />
              ) : null}
              <Button
                size="small"
                color="neutral-light"
                style="outline"
                isInverted
                icon={CloseIcon}
                onPress={() => minimizeChatWidget('user-interaction-close-button')}
                aria-label="Close chat"
              />
            </div>
          </div>
          <Divider />
          <div
            onClick={
              isChatWidgetPreview
                ? () => expandChatWidget('user-interaction-chat-click')
                : undefined
            }
            className={styles.chatContainer}
          >
            {isAI && myself && incompleteUser && isExpertAiAuthEnabled && (
              <ChatWidgetOAuth userLoggedInEventContext="expert-ai-chat-login" />
            )}
            {children}
          </div>
        </div>
      </div>
    </Overlay>
  )
}
