import React from 'react'
import { CircleLoader } from '@deal/components'
import { ExpertAvatar } from '@deal/bluxome'
import { ChatExpertProfilePreview } from '#src/app/components/ChatExpertProfilePreview'
import { BusinessUserForExpertIntroChatFragment } from './ExpertIntroChat.generated'
import { ExpertIntroComposer } from './ExpertIntroComposer'
import styles from './styles.css'

interface ExpertIntroChatProps {
  expert: BusinessUserForExpertIntroChatFragment
  introMessage?: string
  onSubmit: (text: string) => void
  loading: boolean
}

export const ExpertIntroChat: React.FC<ExpertIntroChatProps> = ({
  expert,
  introMessage,
  onSubmit,
  loading
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.body}>
        {loading && (
          <div className={styles.loader}>
            <CircleLoader />
          </div>
        )}
        <div className={styles.conversation}>
          <div className={styles.avatar}>
            <ExpertAvatar
              size="small"
              name={expert.shortName}
              image={expert.expertProfile?.profilePhoto?.url}
            />
          </div>
          <div className={styles.messages}>
            <span className={styles.sender}>{expert.displayName}</span>
            <div className={styles.textMessageBubble}>{introMessage}</div>
          </div>
        </div>
        <div className={styles.label}>
          <hr className={styles.divider} />
          <time
            className={styles.date}
            dateTime={new Date().toISOString()}
            title={new Date().toISOString()}
          >
            Today
          </time>
          <hr className={styles.divider} />
        </div>
        <ChatExpertProfilePreview expert={expert} />
      </div>
      <ExpertIntroComposer loading={loading} onSubmit={onSubmit} />
    </div>
  )
}
