// @ts-nocheck
import type * as Types from '../../../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type WishlistForWishlistGiftCardOptionFragment = { __typename: 'Wishlist', id: any, giftCardItem?: { __typename: 'WishlistItem', id: any } | null };

export type AddGiftCardToWishlistForWishlistGiftCardOptionMutationVariables = Types.Exact<{
  input: Types.AddGiftCardToWishlistInput;
}>;


export type AddGiftCardToWishlistForWishlistGiftCardOptionMutation = { __typename: 'Mutation', addGiftCardToWishlist: { __typename: 'AddGiftCardToWishlistPayload', wishlist?: { __typename: 'Wishlist', id: any, giftCardItem?: { __typename: 'WishlistItem', id: any } | null } | null, error?: { __typename: 'AlreadyHasGiftCardAddGiftCardToWishlistError', errorMessage: string } | { __typename: 'InArchivedStateAddGiftCardToWishlistError' } | null } };

export type RemoveGiftCardFromWishlistForWishlistGiftCardOptionMutationVariables = Types.Exact<{
  input: Types.ArchiveWishlistItemInput;
}>;


export type RemoveGiftCardFromWishlistForWishlistGiftCardOptionMutation = { __typename: 'Mutation', archiveWishlistItem: { __typename: 'ArchiveWishlistItemPayload', wishlist?: { __typename: 'Wishlist', id: any, giftCardItem?: { __typename: 'WishlistItem', id: any } | null } | null } };

export const WishlistForWishlistGiftCardOptionFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"WishlistForWishlistGiftCardOption"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Wishlist"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"giftCardItem"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export const AddGiftCardToWishlistForWishlistGiftCardOptionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddGiftCardToWishlistForWishlistGiftCardOption"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddGiftCardToWishlistInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addGiftCardToWishlist"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"wishlist"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"WishlistForWishlistGiftCardOption"}}]}},{"kind":"Field","name":{"kind":"Name","value":"error"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AlreadyHasGiftCardAddGiftCardToWishlistError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"errorMessage"}}]}}]}}]}}]}},...WishlistForWishlistGiftCardOptionFragmentDoc.definitions]} as unknown as DocumentNode;
export type AddGiftCardToWishlistForWishlistGiftCardOptionMutationFn = Apollo.MutationFunction<AddGiftCardToWishlistForWishlistGiftCardOptionMutation, AddGiftCardToWishlistForWishlistGiftCardOptionMutationVariables>;
export type AddGiftCardToWishlistForWishlistGiftCardOptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddGiftCardToWishlistForWishlistGiftCardOptionMutation, AddGiftCardToWishlistForWishlistGiftCardOptionMutationVariables>, 'mutation'>;

    export const AddGiftCardToWishlistForWishlistGiftCardOptionComponent = (props: AddGiftCardToWishlistForWishlistGiftCardOptionComponentProps) => (
      <ApolloReactComponents.Mutation<AddGiftCardToWishlistForWishlistGiftCardOptionMutation, AddGiftCardToWishlistForWishlistGiftCardOptionMutationVariables> mutation={AddGiftCardToWishlistForWishlistGiftCardOptionDocument} {...props} />
    );
    

/**
 * __useAddGiftCardToWishlistForWishlistGiftCardOptionMutation__
 *
 * To run a mutation, you first call `useAddGiftCardToWishlistForWishlistGiftCardOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGiftCardToWishlistForWishlistGiftCardOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGiftCardToWishlistForWishlistGiftCardOptionMutation, { data, loading, error }] = useAddGiftCardToWishlistForWishlistGiftCardOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGiftCardToWishlistForWishlistGiftCardOptionMutation(baseOptions?: Apollo.MutationHookOptions<AddGiftCardToWishlistForWishlistGiftCardOptionMutation, AddGiftCardToWishlistForWishlistGiftCardOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddGiftCardToWishlistForWishlistGiftCardOptionMutation, AddGiftCardToWishlistForWishlistGiftCardOptionMutationVariables>(AddGiftCardToWishlistForWishlistGiftCardOptionDocument, options);
      }
export type AddGiftCardToWishlistForWishlistGiftCardOptionMutationHookResult = ReturnType<typeof useAddGiftCardToWishlistForWishlistGiftCardOptionMutation>;
export type AddGiftCardToWishlistForWishlistGiftCardOptionMutationResult = Apollo.MutationResult<AddGiftCardToWishlistForWishlistGiftCardOptionMutation>;
export type AddGiftCardToWishlistForWishlistGiftCardOptionMutationOptions = Apollo.BaseMutationOptions<AddGiftCardToWishlistForWishlistGiftCardOptionMutation, AddGiftCardToWishlistForWishlistGiftCardOptionMutationVariables>;
export const RemoveGiftCardFromWishlistForWishlistGiftCardOptionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemoveGiftCardFromWishlistForWishlistGiftCardOption"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ArchiveWishlistItemInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"archiveWishlistItem"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"wishlist"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"WishlistForWishlistGiftCardOption"}}]}}]}}]}},...WishlistForWishlistGiftCardOptionFragmentDoc.definitions]} as unknown as DocumentNode;
export type RemoveGiftCardFromWishlistForWishlistGiftCardOptionMutationFn = Apollo.MutationFunction<RemoveGiftCardFromWishlistForWishlistGiftCardOptionMutation, RemoveGiftCardFromWishlistForWishlistGiftCardOptionMutationVariables>;
export type RemoveGiftCardFromWishlistForWishlistGiftCardOptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveGiftCardFromWishlistForWishlistGiftCardOptionMutation, RemoveGiftCardFromWishlistForWishlistGiftCardOptionMutationVariables>, 'mutation'>;

    export const RemoveGiftCardFromWishlistForWishlistGiftCardOptionComponent = (props: RemoveGiftCardFromWishlistForWishlistGiftCardOptionComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveGiftCardFromWishlistForWishlistGiftCardOptionMutation, RemoveGiftCardFromWishlistForWishlistGiftCardOptionMutationVariables> mutation={RemoveGiftCardFromWishlistForWishlistGiftCardOptionDocument} {...props} />
    );
    

/**
 * __useRemoveGiftCardFromWishlistForWishlistGiftCardOptionMutation__
 *
 * To run a mutation, you first call `useRemoveGiftCardFromWishlistForWishlistGiftCardOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGiftCardFromWishlistForWishlistGiftCardOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGiftCardFromWishlistForWishlistGiftCardOptionMutation, { data, loading, error }] = useRemoveGiftCardFromWishlistForWishlistGiftCardOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveGiftCardFromWishlistForWishlistGiftCardOptionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveGiftCardFromWishlistForWishlistGiftCardOptionMutation, RemoveGiftCardFromWishlistForWishlistGiftCardOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveGiftCardFromWishlistForWishlistGiftCardOptionMutation, RemoveGiftCardFromWishlistForWishlistGiftCardOptionMutationVariables>(RemoveGiftCardFromWishlistForWishlistGiftCardOptionDocument, options);
      }
export type RemoveGiftCardFromWishlistForWishlistGiftCardOptionMutationHookResult = ReturnType<typeof useRemoveGiftCardFromWishlistForWishlistGiftCardOptionMutation>;
export type RemoveGiftCardFromWishlistForWishlistGiftCardOptionMutationResult = Apollo.MutationResult<RemoveGiftCardFromWishlistForWishlistGiftCardOptionMutation>;
export type RemoveGiftCardFromWishlistForWishlistGiftCardOptionMutationOptions = Apollo.BaseMutationOptions<RemoveGiftCardFromWishlistForWishlistGiftCardOptionMutation, RemoveGiftCardFromWishlistForWishlistGiftCardOptionMutationVariables>;