import React, { MutableRefObject, useContext, useEffect, useRef, useState } from 'react'
import { useIdentityContext } from '#src/app/containers/Identity'
import { DepartmentForDepartmentContextFragment } from './DepartmentForDepartmentContext.generated'

/**
 * The DepartmentProvider exposes the current department, as well as a setter.
 */
export type DepartmentValue = DepartmentForDepartmentContextFragment | null
interface DepartmentContextType {
  /**
   * The current department
   *
   * This should not be read directly, as it is not accurate during SSR.
   */
  department?: DepartmentValue

  /**
   * A mutable ref representing the same state (the current department)
   *
   * This should not be read directly, use `useDepartment` instead.
   */
  departmentRef?: MutableRefObject<DepartmentValue>

  /**
   * Update the active department.
   *
   * This should not be read directly, use `<DepartmentSetter>` instead.
   */
  setDepartment: (department: DepartmentValue) => void
}

const DepartmentContext = React.createContext<DepartmentContextType>({
  setDepartment: () => {}
})

const DepartmentConsumer = DepartmentContext.Consumer

export interface DepartmentProviderProps {
  department: DepartmentValue
  onDepartmentChanged: (department?: DepartmentValue) => void
}

/**
 * Exposes the current department.
 *
 * An important note on how this provider works: unlike most context providers, this
 *   provider stores its state in a mutable ref instead of React state. This is done
 *   so that the app can track the department during SSR where a typical `setState`
 *   would not work. Whenever a `<DepartmentSetter>` is rendered during SSR, it
 *   synchronously updates the ref. Any child components using `useDepartment()` will
 *   have access to that department even in the same render pass. In the browser, a
 *   more standard `setState` approach is used (see the `<DepartmentSetter>` component
 *   later in this file for that logic).
 */
const DepartmentProvider: React.FC<React.PropsWithChildren<DepartmentProviderProps>> = ({
  department: initialDepartment,
  onDepartmentChanged,
  children
}) => {
  const { myself } = useIdentityContext()

  // The canonical department state is kept as a ref so it can be mutated during SSR, affecting
  //   later parts of the render (deeper children) without re-rendering from the start.
  const departmentRef = useRef<DepartmentValue>(
    initialDepartment || myself?.user.leads[0]?.department || null
  )

  // HACK: The default department is the department of the user's latest lead. On the server
  //   that data is not available when we kick off rendering the DepartmentProvider so it is
  //   not passed in as the initial value. This callback ensures the default value is passed
  //   back to the server entrypoint.
  if (process.env.TARGET === 'node' && !initialDepartment) {
    onDepartmentChanged(myself?.user.leads[0]?.department)
  }

  // Track the same department as above, but in React state. This state will not be kept up to
  //   date during SSR (because `setState` will not get called during SSR). This state is
  //   *only* used to force a re-render on the client when the active department changes (see
  //   the `department` value passed to the provider below).
  const [department, setDepartment] = useState(initialDepartment)

  // This function is called whenever the <DepartmentSetter> is rendered with a new department
  const handleDepartmentChanged = (newDepartment: DepartmentValue) => {
    // Update the current department in the ref
    departmentRef.current = newDepartment

    // If the department has actually changed, invoke the `onDepartmentChanged` callback
    //   and update the local React state to trigger a re-render.
    if (newDepartment?.id !== department?.id) {
      onDepartmentChanged(newDepartment)
      setDepartment(newDepartment)
    }
  }

  return (
    <DepartmentContext.Provider
      value={{
        // This exists solely to force a re-render on the browser when the department changes
        department: department,
        // The ref tracking the current department
        departmentRef: departmentRef,
        setDepartment: handleDepartmentChanged
      }}
    >
      {children}
    </DepartmentContext.Provider>
  )
}

/**
 * Return the current active department.
 */
export function useDepartment() {
  return useContext(DepartmentContext).departmentRef?.current || null
}

/**
 * Update the active department (on render on the server, and on mount in the browser).
 */
interface DepartmentSetterProps {
  department: DepartmentValue
}

const DepartmentSetter: React.FC<React.PropsWithChildren<DepartmentSetterProps>> = ({
  department,
  children
}) => {
  const { setDepartment } = useContext(DepartmentContext)

  // This use of a hook inside a conditional is safe because it's not a real
  //   conditional, the if/else will be compiled away for each environment.
  if (process.env.TARGET === 'web') {
    // When this setter mounts, update the department in the context. Note:
    //   this state is "sticky", i.e. we do not clean it up if this setter is
    //   unmounted. The canonical department state will remain until another
    //   <DepartmentSetter> is rendered.
    useEffect(() => {
      setDepartment(department)
    }, [department])
  } else {
    if (department) {
      setDepartment(department)
    }
  }

  return <>{children}</>
}

export { DepartmentProvider, DepartmentConsumer, DepartmentContext, DepartmentSetter }
