import { useExperiment } from '@deal/experiment-js'
import {
  PromotionForBlackFridayPromotionFragment,
  RewardCreditPercentPromotionActionReferenceForBlackFridayPromotionFragment,
  useActivePromotionByCodeForBlackFridayPromotionQuery
} from './BlackFridayPromotion.generated'

const BFCM_PROMO_CODE = 'BFCM-24'

const HOLIDAY_PROMO_CODE = 'XMAS15'

const NEW_YEAR_PROMO_CODE = 'NEWYEAR15'

export interface BlackFridayPromotion extends PromotionForBlackFridayPromotionFragment {
  termsPath: string
  ctaDestination: string
  rewardCreditPercent: number
}

export function useBlackFridayPromotion(opts = { skip: false }): BlackFridayPromotion | null {
  const { result } = useExperiment({
    experiment: 'black-friday-2024',
    defaultTreatment: 'control',
    options: { skip: opts.skip }
  })
  const { result: linkDestinationResult } = useExperiment({
    experiment: 'black-friday-2024-link-destination',
    defaultTreatment: 'control',
    options: { skip: opts.skip || result !== 'on' }
  })
  const { data } = useActivePromotionByCodeForBlackFridayPromotionQuery({
    variables: { code: BFCM_PROMO_CODE },
    skip: opts.skip || result !== 'on',
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only'
  })
  const promotion = data?.activePromotionByCode
  if (
    result === 'on' &&
    promotion?.active &&
    new Date(promotion.expiresAt || 0).valueOf() > Date.now()
  ) {
    return {
      ...promotion,
      termsPath: '/legal/black-friday-2024-curated-credit-terms',
      ctaDestination: linkDestinationResult === 'control' ? '/sale' : `/${linkDestinationResult}`,
      rewardCreditPercent: getRewardCreditPercentFromPromotion(promotion) ?? 10
    }
  }
  return null
}

export function useHolidayPromotion(opts = { skip: false }) {
  const { data } = useActivePromotionByCodeForBlackFridayPromotionQuery({
    variables: { code: HOLIDAY_PROMO_CODE },
    skip: opts.skip,
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only'
  })
  return data?.activePromotionByCode
}

export function useNewYearPromotion(opts = { skip: false }) {
  const { data } = useActivePromotionByCodeForBlackFridayPromotionQuery({
    variables: { code: NEW_YEAR_PROMO_CODE },
    skip: opts.skip,
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only'
  })
  return data?.activePromotionByCode
}

export function getRewardCreditPercentFromPromotion<
  P extends PromotionForBlackFridayPromotionFragment
>(promotion: P) {
  const rewardCreditAction = promotion.actions.find(
    (a): a is RewardCreditPercentPromotionActionReferenceForBlackFridayPromotionFragment => {
      return a.__typename === 'RewardCreditPercentPromotionActionReference'
    }
  )
  return rewardCreditAction?.percent
}
