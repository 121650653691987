import React from 'react'
import { useLocation } from '@deal/router'
import { LoginLocationOptions, loginLocation } from '#src/app/services/routing'
import { withStatusCode } from '#src/app/containers/Router'
import Redirect from '#src/app/components/Redirect'
import { Error } from '../Error'

interface Props {
  redirectToLogin?: boolean
  loginLocationOptions?: LoginLocationOptions
}

const Unauthorized: React.FC<Props> = ({ redirectToLogin = true, loginLocationOptions }) => {
  const location = useLocation()

  if (redirectToLogin) {
    return <Redirect to={loginLocation(location, loginLocationOptions)} />
  }

  return (
    <Error
      code="401"
      headline="You do not have access to this page."
      redirectButtonLink={loginLocation(location)}
      redirectButtonColor="neutral-dark"
    />
  )
}

export default withStatusCode<Props>(401)(Unauthorized)
