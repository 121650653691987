// @ts-nocheck
import type * as Types from '../../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { MonetaryAmountFragmentDoc } from '../../../../../../fragments/MonetaryAmount.generated';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ArchiveWishlistItemForGiftCardCtaButtonsMutationVariables = Types.Exact<{
  itemId: Types.Scalars['WishlistItemId']['input'];
}>;


export type ArchiveWishlistItemForGiftCardCtaButtonsMutation = { __typename: 'Mutation', archiveWishlistItem: { __typename: 'ArchiveWishlistItemPayload', wishlist?: { __typename: 'Wishlist', id: any, items: { __typename: 'WishlistItemPagination', count: any, wishlistItems: Array<{ __typename: 'WishlistItem', id: any }> } } | null } };

export type WishlistItemSellableDetailForGiftCardCtaButtonsFragment = { __typename: 'WishlistItemGiftCardDetail', amountPurchased: { __typename: 'MonetaryAmount', currency: any, amount: string, amountInSmallestDenomination: string } };

export const WishlistItemSellableDetailForGiftCardCtaButtonsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"WishlistItemSellableDetailForGiftCardCtaButtons"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"WishlistItemGiftCardDetail"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"amountPurchased"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MonetaryAmount"}}]}}]}}]} as unknown as DocumentNode;
export const ArchiveWishlistItemForGiftCardCtaButtonsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ArchiveWishlistItemForGiftCardCtaButtons"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"itemId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"WishlistItemId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"archiveWishlistItem"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"itemId"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"wishlist"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"wishlistItems"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type ArchiveWishlistItemForGiftCardCtaButtonsMutationFn = Apollo.MutationFunction<ArchiveWishlistItemForGiftCardCtaButtonsMutation, ArchiveWishlistItemForGiftCardCtaButtonsMutationVariables>;
export type ArchiveWishlistItemForGiftCardCtaButtonsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ArchiveWishlistItemForGiftCardCtaButtonsMutation, ArchiveWishlistItemForGiftCardCtaButtonsMutationVariables>, 'mutation'>;

    export const ArchiveWishlistItemForGiftCardCtaButtonsComponent = (props: ArchiveWishlistItemForGiftCardCtaButtonsComponentProps) => (
      <ApolloReactComponents.Mutation<ArchiveWishlistItemForGiftCardCtaButtonsMutation, ArchiveWishlistItemForGiftCardCtaButtonsMutationVariables> mutation={ArchiveWishlistItemForGiftCardCtaButtonsDocument} {...props} />
    );
    

/**
 * __useArchiveWishlistItemForGiftCardCtaButtonsMutation__
 *
 * To run a mutation, you first call `useArchiveWishlistItemForGiftCardCtaButtonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveWishlistItemForGiftCardCtaButtonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveWishlistItemForGiftCardCtaButtonsMutation, { data, loading, error }] = useArchiveWishlistItemForGiftCardCtaButtonsMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useArchiveWishlistItemForGiftCardCtaButtonsMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveWishlistItemForGiftCardCtaButtonsMutation, ArchiveWishlistItemForGiftCardCtaButtonsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveWishlistItemForGiftCardCtaButtonsMutation, ArchiveWishlistItemForGiftCardCtaButtonsMutationVariables>(ArchiveWishlistItemForGiftCardCtaButtonsDocument, options);
      }
export type ArchiveWishlistItemForGiftCardCtaButtonsMutationHookResult = ReturnType<typeof useArchiveWishlistItemForGiftCardCtaButtonsMutation>;
export type ArchiveWishlistItemForGiftCardCtaButtonsMutationResult = Apollo.MutationResult<ArchiveWishlistItemForGiftCardCtaButtonsMutation>;
export type ArchiveWishlistItemForGiftCardCtaButtonsMutationOptions = Apollo.BaseMutationOptions<ArchiveWishlistItemForGiftCardCtaButtonsMutation, ArchiveWishlistItemForGiftCardCtaButtonsMutationVariables>;