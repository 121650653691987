import React from 'react'
import { ApolloQueryResult } from '@apollo/client'
import { Myself } from '#src/app/typings'
import { IdentityConsumer } from '#src/app/containers/Identity'
import { MyselfQuery } from './Myself.generated'

export interface WithIdentityProps {
  myself?: Myself
  userId?: string
  sessionId?: string
  refetchIdentity?: () => Promise<ApolloQueryResult<MyselfQuery>>
}

function withIdentity<P>(WrappedComponent: React.ComponentType<React.PropsWithChildren<P & WithIdentityProps>>) {
  const WithIdentity: React.FC<React.PropsWithChildren<P>> = props => {
    return (
      <IdentityConsumer>
        {({ myself, refetch, sessionId }) => {
          // Only expose the props that were passed in. These are created and used only by this HOC:
          const userId = (myself && myself.user.id) || undefined
          return (
            <WrappedComponent
              {...props}
              userId={userId}
              sessionId={sessionId}
              myself={myself || undefined}
              refetchIdentity={refetch}
            />
          )
        }}
      </IdentityConsumer>
    )
  }
  return WithIdentity
}

export { withIdentity }
