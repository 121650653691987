import React from 'react'
import loadable from '@loadable/component'
import { Route, Switch, useParams } from '@deal/router'
import { FullPageLoader } from '@deal/components'
import Page from '#src/app/components/Page'
import { NotFound } from '#src/app/components/Errors'
import { useProductQuickReviewForExpertProductReviewsRouteQuery } from './ExpertProductReviewsRoute.generated'

const PageComponent = loadable(
  () => import(/* webpackChunkName: "expert-product-reviews" */ './page'),
  {
    resolveComponent: components => components.ExpertProductReviewPage
  }
)

interface ExpertProductReviewsPageProps {}

const ExpertProductReviewsPage: React.FC<ExpertProductReviewsPageProps> = () => {
  const { productQuickReviewId } = useParams<{ productQuickReviewId: string }>()
  const query = useProductQuickReviewForExpertProductReviewsRouteQuery({
    variables: {
      id: productQuickReviewId
    }
  })

  return (
    <Page
      query={query}
      pageKey={'expert-product-review'}
      seoTitle={undefined}
      seoDescription={undefined}
      seoIndexable={false}
      chat={undefined}
      expert={undefined}
      category={data => data.productQuickReview.sellable.categories[0]}
      department={data => data.productQuickReview.sellable.department || undefined}
      sellable={data => data.productQuickReview.sellable}
      pageComponent={PageComponent}
      loadingComponent={() => <FullPageLoader />}
      ogImageUrl={undefined}
    />
  )
}

const ExpertProductReviewsPageContainer: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Switch>
      <Route
        path="/expert-product-reviews/:productQuickReviewId"
        component={ExpertProductReviewsPage}
      />
      <Route component={NotFound} />
    </Switch>
  )
}

export { ExpertProductReviewsPageContainer }
