import trim from 'lodash/trim'
import chunk from 'lodash/chunk'
import * as History from 'history'
import { ConsumerTracking } from '@deal/web-tracking'
import { matchPath } from '@deal/router'
import { SellableSearchFilter, SellableSort, SortOrder } from '#src/generated/types'
import loggerClient from '../loggerClient'
import { SearchSellablesPaginationQuery } from './types'
import { FACET_SERIALIZERS } from './serializers'

/**
 * Parse a URL for a PLA page. PLAs are a faceted UI, and support
 *   many of the features of SRPs: sorting, pagination, and filtering. Additionally,
 *   PLPs support marketing features, such as engagement channels and Page Builder
 *   functionality.
 *
 * Some example PLP URLs might look like:
 *
 *  /pla/sellableFriendlyId/sellableSlug
 *  /pla/sellableFriendlyId/sellableSlug?page=2
 *  /pla/sellableFriendlyId/sellableSlug?sort=trending
 *  /pla/sellableFriendlyId/sellableSlug/b/armada
 *  /pla/sellableFriendlyId/sellableSlug/b/armada?bucket%3Aattributes%3AskiBend=rocker=
 */
export function parsePlaPageUrl({
  pathname,
  search
}: History.Location): SearchSellablesPaginationQuery {
  const pathSegments = trim(pathname, '/').split('/')
  const searchParams = new URLSearchParams(search)

  const friendlyIdOrId = matchPath<{ friendlyIdOrId: string; slug: string }>(pathname, {
    path: '/pla/:friendlyIdOrId'
  })?.params.friendlyIdOrId

  if (!friendlyIdOrId) {
    throw new Error(`Can not parse invalid PLA page path: ${pathname}`)
  }

  // Find `/c/:categorySlug/:foo/:bar/deals`
  const isDealsSearch = pathSegments.at(-1) === 'deals'

  // Find the remaining segments (the facets)
  const facetPathSegments = pathSegments.slice(3, isDealsSearch ? -1 : undefined)

  // Create a basic query
  const query: SearchSellablesPaginationQuery = {
    selections: {},
    filters: [],
    page: 1
  }

  // Add the discounted filter
  if (isDealsSearch) {
    query.filters!.push(SellableSearchFilter.DISCOUNTED)
  }

  // Add the facets from the path portion of the URL (`/pla/:friendlyIdOrId/:slug/:facetAlias/:facetValue/:facetAlias/:facetValue`)
  if (facetPathSegments.length) {
    const facets = new Map(chunk(facetPathSegments, 2) as [string, string][])

    for (const [facetAlias, facetValue] of facets.entries()) {
      query.selections![facetAlias] = {
        name: facetAlias,
        alias: facetAlias,
        bucketValues: [decodeURIComponent(facetValue)]
      }
    }
  }

  // Add the facets from the query parameters (`/pla/:friendlyIdOrId/:slug?bucket:facetName=facetValue1,facetValue2`)
  for (const [key, value] of searchParams.entries()) {
    const serializer = FACET_SERIALIZERS.find(serializer =>
      key.startsWith(serializer.SERIALIZATION_PREFIX + ':')
    )

    if (serializer) {
      const facetName = key.slice(serializer.SERIALIZATION_PREFIX.length + 1)

      query.selections![facetName] = {
        name: facetName,
        alias: undefined,
        ...serializer.deserialize(value)
      }
    }
  }

  // Add the page nuber
  if (searchParams.has('page')) {
    query.page = parseInt(searchParams.get('page')!, 10)
  }

  // Add the sorting method
  switch (searchParams.get('sort')!) {
    case 'price':
      query.sortBy = SellableSort.AVG_SALE_PRICE
      break

    case 'savings':
      query.sortBy = SellableSort.MAX_PRICE_PERCENT_SAVINGS
      break

    case 'purchases':
      query.sortBy = SellableSort.BEST_SELLER
      break

    case 'trending':
      query.sortBy = SellableSort.POPULARITY
      break

    case 'recommended':
      query.sortBy = SellableSort.MOST_RECOMMENDED_BY_EXPERTS
      break

    case 'relevance':
      query.sortBy = SellableSort.RELEVANCE
      break

    default:
      query.sortBy = SellableSort.SIMILARITY
  }

  // Add the sorting order (descending by default)
  query.sortOrder = searchParams.get('order') === 'asc' ? SortOrder.ASC : SortOrder.DESC

  // Add additional filters
  if (searchParams.has('filters')) {
    searchParams
      .get('filters')!
      .split(',')
      .forEach((filterValue: string) => {
        const filterEnumValue = filterValue.toUpperCase()

        if (filterEnumValue in SellableSearchFilter) {
          query.filters!.push(SellableSearchFilter[filterEnumValue as SellableSearchFilter])
        } else {
          loggerClient.captureError(new Error(`Unknown filter in search URL: ${filterEnumValue}`))
        }
      })
  }

  // Add the entry point for tracking
  if (searchParams.has('source')) {
    query.entryPoint = searchParams.get('source')! as ConsumerTracking.SearchEntryPoint
  }

  return query
}
