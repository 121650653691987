import React from 'react'
import loadable from '@loadable/component'
import { useLocation } from '@deal/router'
import { CircleLoader } from '@deal/components'
import { SellableEditorialTopListState } from '#src/generated/types'
import Page from '#src/app/components/Page'
import { useTopListHubRouteQuery } from './TopListHubRoute.generated'

const PAGE_SIZE = 50

const PageComponent = loadable(() => import(/* webpackChunkName: "top-list-hub" */ './page'), {
  resolveComponent: components => components.TopListHubPage
})

interface TopListHubRouteProps {}

const TopListHubRoute: React.FC<TopListHubRouteProps> = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const page = params.get('page')
  const pageNumber = page ? parseInt(page, 10) : 1
  const offset = (pageNumber - 1) * PAGE_SIZE
  const limit = PAGE_SIZE
  const query = useTopListHubRouteQuery({
    variables: {
      input: {
        states: [SellableEditorialTopListState.PUBLISHED]
      },
      offset,
      limit
    }
  })

  return (
    <Page
      query={query}
      pageKey={'top-list-hub'}
      seoTitle={'Top lists'}
      seoDescription={undefined}
      seoIndexable={true}
      canonicalPath={'/top-lists'}
      chat={undefined}
      expert={undefined}
      category={undefined}
      department={undefined}
      sellable={undefined}
      pageComponent={PageComponent}
      pageProps={{ pageNumber, numberOfPaginatedResultsPerPage: PAGE_SIZE }}
      loadingComponent={() => <CircleLoader />}
      ogImageUrl={undefined}
    />
  )
}

export default TopListHubRoute
