import React, { useContext, useState } from 'react'
import { GlobalLoader } from '@deal/components'

type GlobalLoaderContext = {
  setLoading: (isLoading: boolean) => void
}

const GlobalLoaderContext = React.createContext<GlobalLoaderContext>({
  setLoading: () => {
    throw new Error('Can not set global loader state outside global loader provider')
  }
})

const { Consumer, Provider } = GlobalLoaderContext

const GlobalLoaderContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Provider
      value={{
        setLoading: setIsLoading
      }}
    >
      <GlobalLoader loading={isLoading} />
      {children}
    </Provider>
  )
}

const useGlobalLoaderContext = () => {
  return useContext(GlobalLoaderContext)
}

export {
  GlobalLoaderContext,
  GlobalLoaderContextProvider,
  Consumer as GlobalLoaderContextConsumer,
  useGlobalLoaderContext
}
