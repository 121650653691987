import React from 'react'
import { AuthenticationFlowViewLayoutProps } from '../../index'
import LogoutViewLegacy from './v1'
import { LogoutView as LogouutViewRebranded } from './rebranded'

interface LogoutViewProps extends AuthenticationFlowViewLayoutProps {
  onLogout: () => void
  isRebranded?: boolean
}

const LogoutView: React.FC<LogoutViewProps> = ({ isRebranded = false, ...rest }) => {
  if (isRebranded) {
    return <LogouutViewRebranded {...rest} />
  }
  return <LogoutViewLegacy {...rest} />
}

export default LogoutView
