import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import { useChatWidgetPathContext } from '#src/app/context/chatWidgetPath'
import { TrackingCodes } from '../services/path/hooks/useCreateRequestPath'
import { convertPromoIdToAttrInput, formatPathUrl, useCreateRequestPath } from '../services/path'

export const useAiChatContactCollection = (placement: 'widget' | 'default') => {
  const history = useHistory()
  const { setPathUrl } = useChatWidgetPathContext()
  const [createPath, { loading }] = useCreateRequestPath({
    onPathCreated: ({ pathGraphFlow, pathGraphFlowSlug, nodeSlugOrId }) => {
      if (pathGraphFlow) {
        const pathURL = formatPathUrl({
          pathGraphFlowId: pathGraphFlow.pathGraphFlowId,
          nodeSlugOrId,
          pathGraphFlowSlug,
          pathPlacement: placement
        })
        switch (placement) {
          case 'default':
            history.push(pathURL)
            break

          case 'widget':
            setPathUrl(pathURL)
            break
        }
      } else {
        throw new Error('Error creating path for `TrackableHiringPage` component!')
      }
    },
    onError: ({ errorMessage }) => {
      toast.error(errorMessage)
      throw new Error(errorMessage)
    }
  })

  const aiChatContactCollection = (props: {
    trackingCodes: TrackingCodes
    leadId?: string
    promotionId?: string
  }) => {
    const promotionAttributes = props.promotionId
      ? convertPromoIdToAttrInput(props.promotionId)
      : []
    createPath({
      pathSelector: { pathSchemaId: 'AgAAABIAN3HJcqcZOnWhcYLj4I4CaQ' },
      trackingCodes: { pageVariation: 'guest_ai_chat', ...props.trackingCodes },
      initialAttributes: promotionAttributes
    })
  }
  return { aiChatContactCollection, loading }
}
