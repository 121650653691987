// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { PotentialSourcesForGenerateReferralUrlFragmentDoc } from '../../hooks/useReferralUrl/GenerateReferralUrl.generated';
export type BusinessUserForExpertFooterFragment = { __typename: 'BusinessUser', id: any, shortName: string, expertAttributes: { __typename: 'BusinessUserExpertAttributes', placeholderExpert: boolean } };

export type ReferralCodeForExpertFooterFragment = { __typename: 'ReferralCode', id: any, code: string, source: { __typename: 'ReferralSourceConsumerGeneric' } | { __typename: 'ReferralSourceConsumerToExpert', referredToExpert: { __typename: 'BusinessUser', id: any } } | { __typename: 'ReferralSourceExpertDirect' } };

export const BusinessUserForExpertFooterFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BusinessUserForExpertFooter"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"shortName"}},{"kind":"Field","name":{"kind":"Name","value":"expertAttributes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"placeholderExpert"}}]}}]}}]} as unknown as DocumentNode;
export const ReferralCodeForExpertFooterFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ReferralCodeForExpertFooter"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ReferralCode"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PotentialSourcesForGenerateReferralUrl"}}]}}]} as unknown as DocumentNode;