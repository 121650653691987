import React from 'react'
import loadable from '@loadable/component'
// Routes that render the `Chrome` should use `RouteLoader`,
//   other routes may use `FullPageLoader`
import { FullPageLoader } from '@deal/components'
import RouteLoader from '#src/app/components/RouteLoader'

export const About = loadable(() => import('./about'), {
  resolveComponent: components => components.AboutUs,
  fallback: <RouteLoader />
})
export const Account = loadable(() => import('./account'), {
  resolveComponent: components => components.Account,
  fallback: <RouteLoader />
})
export const Apply = loadable(() => import('./apply'), {
  resolveComponent: components => components.Experts,
  fallback: <RouteLoader />
})
export const Auth = loadable(() => import('./auth'), {
  resolveComponent: components => components.Auth,
  fallback: <RouteLoader />
})
export const Company = loadable(() => import('./company'), {
  resolveComponent: components => components.V2,
  fallback: <RouteLoader />
})
export const ExpertShortLinkRedirect = loadable(
  () => import(/* webpackChunkName: 'expert-short-link-redirect' */ './e'),
  {
    resolveComponent: components => components.ExpertShortLinkRedirectRouter,
    fallback: <RouteLoader />
  }
)
export const ExpertAffiliateLanding = loadable(() => import('./expert-affiliate-landing'), {
  resolveComponent: components => components.ExpertAffiliateLandingRouter,
  fallback: <RouteLoader />
})
export const FAQ = loadable(() => import('./faq'), {
  resolveComponent: components => components.FAQ,
  fallback: <RouteLoader />
})
export const Feedback = loadable(() => import('./feedback'), {
  resolveComponent: components => components.Feedback,
  fallback: <RouteLoader />
})
export const Home = loadable(() => import('./home'), {
  resolveComponent: components => components.HomeRoute,
  fallback: <RouteLoader />
})
export const HowItWorks = loadable(() => import('./how-it-works'), {
  resolveComponent: components => components.HowItWorks,
  fallback: <RouteLoader />
})
export const InAction = loadable(() => import('./in-action'), {
  resolveComponent: components => components.InAction,
  fallback: <RouteLoader />
})
export const Insiders = loadable(() => import('./insiders'), {
  resolveComponent: components => components.InsidersFaq,
  fallback: <RouteLoader />
})
export const Journal = loadable(() => import('./journal'), {
  resolveComponent: components => components.JournalRoute,
  fallback: <RouteLoader />
})
export const Landing = loadable(() => import('./landing'), {
  resolveComponent: components => components.LandingPageRoute,
  fallback: <FullPageLoader />
})
export const Legal = loadable(() => import('./legal'), {
  resolveComponent: components => components.Legal,
  fallback: <RouteLoader />
})
export const GiftCardPromo = loadable(() => import('./gift-card-promo'), {
  resolveComponent: components => components.GiftCardPromoWrapper,
  fallback: <RouteLoader />
})
export const Giveaways = loadable(() => import('./giveaways'), {
  resolveComponent: components => components.Giveaways,
  fallback: <RouteLoader />
})
export const MostRecommended = loadable(() => import('./most-recommended'), {
  resolveComponent: components => components.MostRecommendedRoute,
  fallback: <RouteLoader />
})
export const Influencer = loadable(() => import('./influencer'), {
  resolveComponent: components => components.InfluencerRoute,
  fallback: <RouteLoader />
})
export const Checkout = loadable(() => import('./checkout'), {
  resolveComponent: components => components.CheckoutRouter,
  fallback: <RouteLoader />
})
export const Orders = loadable(() => import('./orders'), {
  resolveComponent: components => components.OrdersRouter,
  fallback: <RouteLoader />
})
export const GiftCards = loadable(() => import('./gift-cards'), {
  resolveComponent: components => components.GiftCardRouter,
  fallback: <RouteLoader />
})
export const ClaimPromotion = loadable(() => import('./claim-promotion'), {
  resolveComponent: components => components.ClaimPromotionRouter,
  fallback: <RouteLoader />
})
export const GroupSales = loadable(() => import('./group-sales'), {
  resolveComponent: components => components.GroupSalesRouter,
  fallback: <RouteLoader />
})
export const OrganicReferralSignup = loadable(() => import('./organic-referral-signup'), {
  resolveComponent: components => components.SignupSource,
  fallback: <FullPageLoader />
})
export const Path = loadable(() => import('./path'), {
  resolveComponent: components => components.Flow,
  fallback: <FullPageLoader />
})
export const Redirects = loadable(() => import('./redirects'), {
  resolveComponent: components => components.Redirects,
  fallback: <RouteLoader />
})
export const Requests = loadable(() => import('./requests'), {
  resolveComponent: components => components.Requests,
  fallback: <FullPageLoader />
})
export const Referral = loadable(() => import('./referral'), {
  resolveComponent: components => components.Referral,
  fallback: <FullPageLoader />
})
export const ReferralRewardCredit = loadable(() => import('./referral-reward-credit'), {
  resolveComponent: components => components.ReferralCredit,
  fallback: <FullPageLoader />
})
export const ReferAFriend = loadable(() => import('./refer-a-friend'), {
  resolveComponent: components => components.ReferAFriend,
  fallback: <RouteLoader />
})
export const Returns = loadable(() => import('./returns'), {
  resolveComponent: components => components.Returns,
  fallback: <RouteLoader />
})
export const Reviews = loadable(() => import('./reviews'), {
  resolveComponent: components => components.ReviewsPageRouter,
  fallback: <RouteLoader />
})
export const Sellable = loadable(() => import('./sellable'), {
  resolveComponent: components => components.Sellable,
  fallback: <RouteLoader />
})
export const SmsPromo = loadable(() => import('./sms-promo'), {
  resolveComponent: components => components.SmsPromo,
  fallback: <RouteLoader />
})
export const Support = loadable(() => import('./support'), {
  resolveComponent: components => components.Support,
  fallback: <RouteLoader />
})
export const Survey = loadable(() => import('./survey'), {
  resolveComponent: components => components.Flow,
  fallback: <FullPageLoader />
})
export const TrialFAQ = loadable(() => import('./trial-faq'), {
  resolveComponent: components => components.TrialFAQ,
  fallback: <RouteLoader />
})
export const Unsubscribe = loadable(() => import('./unsubscribe'), {
  resolveComponent: components => components.Unsubscribe,
  fallback: <RouteLoader />
})
export const WinterPackingList = loadable(() => import('./winter-packing-list'), {
  resolveComponent: components => components.WinterPackingList,
  fallback: <RouteLoader />
})
export const Partners = loadable(() => import('./partners'), {
  resolveComponent: components => components.Partners,
  fallback: <RouteLoader />
})
export const Scout = loadable(() => import('./scout'), {
  resolveComponent: components => components.ScoutRoute,
  fallback: <RouteLoader />
})
export const BFCM = loadable(() => import('./bfcm'), {
  resolveComponent: components => components.BFCMRoute,
  fallback: <RouteLoader />
})
