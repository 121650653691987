import React from 'react'
import EmptyIcon from './empty.svg'
import styles from './styles.css'

const EmptyOrder: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className={styles.empty}>
      <EmptyIcon className={styles.icon} />
      <div className={styles.label}>
        <div>You have no items in your cart.</div>
        <div className={styles.subLabel}>Add items to your cart to get started.</div>
      </div>
    </div>
  )
}

export default EmptyOrder
