// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { MediaFileFragmentDoc } from '../../fragments/MediaFile.generated';
import { BusinessUserForExpertAvatarWithPopoverFragmentDoc } from '../ExpertAvatarWithPopover/ExpertAvatarWithPopover.generated';
import { BusinessUserForExpertForAvatarWithPopoverFragmentDoc, BusinessUserForExpertForAvatarFragmentDoc } from '../../services/avatars/BusinessUserForExpertForAvatar.generated';
import { BusinessUserForGetExpertExpertiseFragmentDoc, BusinessUserForGetExpertProfileLinkFragmentDoc } from '../../services/expert/expert.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ConsumerExpertReviewForConsumerTestimonialCarouselFragment = { __typename: 'ConsumerExpertReview', id: any, headline?: string | null, comments?: string | null, reviewedAt: any, consumerName?: string | null, consumerLocation?: string | null, rating: number, verifiedPurchases: Array<{ __typename: 'Sellable', id: any, title: string, primaryImage?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null }>, expert: { __typename: 'BusinessUser', id: any, displayName: string, vanityId?: string | null, state: Types.BusinessUserState, expertProfile?: { __typename: 'ExpertProfile', id: any, background: string, backgroundSummaryLines: Array<string>, headline: string, profilePhoto?: { __typename: 'MediaFile', id: any, url: string } | null } | null, expertStats?: { __typename: 'ExpertStats', totalReviews: number, totalLeadsParticipated: number, averageRating?: number | null } | null, expertAttributes: { __typename: 'BusinessUserExpertAttributes', category: { __typename: 'Category', id: any, slug: string, singularDisplayName: string, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, expert?: { __typename: 'CategoryAssetNoun', singular: string } | null } | null }, specialtyCategory?: { __typename: 'Category', id: any, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, expert?: { __typename: 'CategoryAssetNoun', singular: string } | null } | null } | null }, articleAuthorProfile?: { __typename: 'ExpertProfile', id: any, headline: string } | null } };

export type ConsumerExpertReviewsForConsumerTestimonialCarouselQueryVariables = Types.Exact<{
  consumerExpertReviewIds: Array<Types.Scalars['ConsumerExpertReviewId']['input']> | Types.Scalars['ConsumerExpertReviewId']['input'];
}>;


export type ConsumerExpertReviewsForConsumerTestimonialCarouselQuery = { __typename: 'Query', consumerExpertReviews: Array<{ __typename: 'ConsumerExpertReview', id: any, headline?: string | null, comments?: string | null, reviewedAt: any, consumerName?: string | null, consumerLocation?: string | null, rating: number, verifiedPurchases: Array<{ __typename: 'Sellable', id: any, title: string, primaryImage?: { __typename: 'MediaFile', id: any, url: string, mimeType: string, blurHash?: string | null, videoStream?: { __typename: 'VideoStreamMediaFileMetadata', hlsUrl: string, thumbnailUrl: string } | null, dimensions?: { __typename: 'DimensionsMediaFileMetadata', width: number, height: number, aspectRatio: number } | null } | null }>, expert: { __typename: 'BusinessUser', id: any, displayName: string, vanityId?: string | null, state: Types.BusinessUserState, expertProfile?: { __typename: 'ExpertProfile', id: any, background: string, backgroundSummaryLines: Array<string>, headline: string, profilePhoto?: { __typename: 'MediaFile', id: any, url: string } | null } | null, expertStats?: { __typename: 'ExpertStats', totalReviews: number, totalLeadsParticipated: number, averageRating?: number | null } | null, expertAttributes: { __typename: 'BusinessUserExpertAttributes', category: { __typename: 'Category', id: any, slug: string, singularDisplayName: string, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, expert?: { __typename: 'CategoryAssetNoun', singular: string } | null } | null }, specialtyCategory?: { __typename: 'Category', id: any, categoryAssetRevision?: { __typename: 'CategoryAssetRevision', id: any, expert?: { __typename: 'CategoryAssetNoun', singular: string } | null } | null } | null }, articleAuthorProfile?: { __typename: 'ExpertProfile', id: any, headline: string } | null } }> };

export const ConsumerExpertReviewForConsumerTestimonialCarouselFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ConsumerExpertReviewForConsumerTestimonialCarousel"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ConsumerExpertReview"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"headline"}},{"kind":"Field","name":{"kind":"Name","value":"comments"}},{"kind":"Field","name":{"kind":"Name","value":"reviewedAt"}},{"kind":"Field","name":{"kind":"Name","value":"consumerName"}},{"kind":"Field","name":{"kind":"Name","value":"consumerLocation"}},{"kind":"Field","name":{"kind":"Name","value":"rating"}},{"kind":"Field","name":{"kind":"Name","value":"verifiedPurchases"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"limit"},"value":{"kind":"IntValue","value":"3"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"primaryImage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaFile"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"expert"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForExpertAvatarWithPopover"}}]}}]}}]} as unknown as DocumentNode;
export const ConsumerExpertReviewsForConsumerTestimonialCarouselDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ConsumerExpertReviewsForConsumerTestimonialCarousel"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"consumerExpertReviewIds"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ConsumerExpertReviewId"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"consumerExpertReviews"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"ids"},"value":{"kind":"Variable","name":{"kind":"Name","value":"consumerExpertReviewIds"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConsumerExpertReviewForConsumerTestimonialCarousel"}}]}}]}},...ConsumerExpertReviewForConsumerTestimonialCarouselFragmentDoc.definitions,...MediaFileFragmentDoc.definitions,...BusinessUserForExpertAvatarWithPopoverFragmentDoc.definitions,...BusinessUserForExpertForAvatarWithPopoverFragmentDoc.definitions,...BusinessUserForExpertForAvatarFragmentDoc.definitions,...BusinessUserForGetExpertExpertiseFragmentDoc.definitions,...BusinessUserForGetExpertProfileLinkFragmentDoc.definitions]} as unknown as DocumentNode;
export type ConsumerExpertReviewsForConsumerTestimonialCarouselComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ConsumerExpertReviewsForConsumerTestimonialCarouselQuery, ConsumerExpertReviewsForConsumerTestimonialCarouselQueryVariables>, 'query'> & ({ variables: ConsumerExpertReviewsForConsumerTestimonialCarouselQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ConsumerExpertReviewsForConsumerTestimonialCarouselComponent = (props: ConsumerExpertReviewsForConsumerTestimonialCarouselComponentProps) => (
      <ApolloReactComponents.Query<ConsumerExpertReviewsForConsumerTestimonialCarouselQuery, ConsumerExpertReviewsForConsumerTestimonialCarouselQueryVariables> query={ConsumerExpertReviewsForConsumerTestimonialCarouselDocument} {...props} />
    );
    

/**
 * __useConsumerExpertReviewsForConsumerTestimonialCarouselQuery__
 *
 * To run a query within a React component, call `useConsumerExpertReviewsForConsumerTestimonialCarouselQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsumerExpertReviewsForConsumerTestimonialCarouselQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsumerExpertReviewsForConsumerTestimonialCarouselQuery({
 *   variables: {
 *      consumerExpertReviewIds: // value for 'consumerExpertReviewIds'
 *   },
 * });
 */
export function useConsumerExpertReviewsForConsumerTestimonialCarouselQuery(baseOptions: Apollo.QueryHookOptions<ConsumerExpertReviewsForConsumerTestimonialCarouselQuery, ConsumerExpertReviewsForConsumerTestimonialCarouselQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsumerExpertReviewsForConsumerTestimonialCarouselQuery, ConsumerExpertReviewsForConsumerTestimonialCarouselQueryVariables>(ConsumerExpertReviewsForConsumerTestimonialCarouselDocument, options);
      }
export function useConsumerExpertReviewsForConsumerTestimonialCarouselLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsumerExpertReviewsForConsumerTestimonialCarouselQuery, ConsumerExpertReviewsForConsumerTestimonialCarouselQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsumerExpertReviewsForConsumerTestimonialCarouselQuery, ConsumerExpertReviewsForConsumerTestimonialCarouselQueryVariables>(ConsumerExpertReviewsForConsumerTestimonialCarouselDocument, options);
        }
export function useConsumerExpertReviewsForConsumerTestimonialCarouselSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ConsumerExpertReviewsForConsumerTestimonialCarouselQuery, ConsumerExpertReviewsForConsumerTestimonialCarouselQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ConsumerExpertReviewsForConsumerTestimonialCarouselQuery, ConsumerExpertReviewsForConsumerTestimonialCarouselQueryVariables>(ConsumerExpertReviewsForConsumerTestimonialCarouselDocument, options);
        }
export type ConsumerExpertReviewsForConsumerTestimonialCarouselQueryHookResult = ReturnType<typeof useConsumerExpertReviewsForConsumerTestimonialCarouselQuery>;
export type ConsumerExpertReviewsForConsumerTestimonialCarouselLazyQueryHookResult = ReturnType<typeof useConsumerExpertReviewsForConsumerTestimonialCarouselLazyQuery>;
export type ConsumerExpertReviewsForConsumerTestimonialCarouselSuspenseQueryHookResult = ReturnType<typeof useConsumerExpertReviewsForConsumerTestimonialCarouselSuspenseQuery>;
export type ConsumerExpertReviewsForConsumerTestimonialCarouselQueryResult = Apollo.QueryResult<ConsumerExpertReviewsForConsumerTestimonialCarouselQuery, ConsumerExpertReviewsForConsumerTestimonialCarouselQueryVariables>;