// @ts-nocheck
import type * as Types from '../../../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type WishlistItemPurchaseForGiftDetailsFragment = { __typename: 'WishlistItemPurchase', gifterMessage?: string | null, gifterLastName?: string | null, gifterFirstName?: string | null, orderConsumerFriendlyId: string, orderPaidAt: any, quantity: number, returns: Array<{ __typename: 'OrderReturnRequest', id: any, status: Types.OrderChangeStatus }>, shipmentTrackings: Array<{ __typename: 'ShipmentTracking', status: Types.ShippingStatus, deliveredAt?: any | null }> };

export type StartSupportConversationForGiftDetailsMutationVariables = Types.Exact<{
  input: Types.StartSupportConversationInput;
}>;


export type StartSupportConversationForGiftDetailsMutation = { __typename: 'Mutation', startSupportConversation: { __typename: 'UpdateSupportConversationPayload', supportConversation: { __typename: 'SupportConversation', id: any, conversation: { __typename: 'Conversation', id: any, externalId: string } } } };

export const WishlistItemPurchaseForGiftDetailsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"WishlistItemPurchaseForGiftDetails"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"WishlistItemPurchase"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"gifterMessage"}},{"kind":"Field","name":{"kind":"Name","value":"gifterLastName"}},{"kind":"Field","name":{"kind":"Name","value":"gifterFirstName"}},{"kind":"Field","name":{"kind":"Name","value":"orderConsumerFriendlyId"}},{"kind":"Field","name":{"kind":"Name","value":"orderPaidAt"}},{"kind":"Field","name":{"kind":"Name","value":"quantity"}},{"kind":"Field","name":{"kind":"Name","value":"returns"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}},{"kind":"Field","name":{"kind":"Name","value":"shipmentTrackings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"deliveredAt"}}]}}]}}]} as unknown as DocumentNode;
export const StartSupportConversationForGiftDetailsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"StartSupportConversationForGiftDetails"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"StartSupportConversationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"startSupportConversation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"supportConversation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"conversation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"externalId"}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type StartSupportConversationForGiftDetailsMutationFn = Apollo.MutationFunction<StartSupportConversationForGiftDetailsMutation, StartSupportConversationForGiftDetailsMutationVariables>;
export type StartSupportConversationForGiftDetailsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<StartSupportConversationForGiftDetailsMutation, StartSupportConversationForGiftDetailsMutationVariables>, 'mutation'>;

    export const StartSupportConversationForGiftDetailsComponent = (props: StartSupportConversationForGiftDetailsComponentProps) => (
      <ApolloReactComponents.Mutation<StartSupportConversationForGiftDetailsMutation, StartSupportConversationForGiftDetailsMutationVariables> mutation={StartSupportConversationForGiftDetailsDocument} {...props} />
    );
    

/**
 * __useStartSupportConversationForGiftDetailsMutation__
 *
 * To run a mutation, you first call `useStartSupportConversationForGiftDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSupportConversationForGiftDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSupportConversationForGiftDetailsMutation, { data, loading, error }] = useStartSupportConversationForGiftDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartSupportConversationForGiftDetailsMutation(baseOptions?: Apollo.MutationHookOptions<StartSupportConversationForGiftDetailsMutation, StartSupportConversationForGiftDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartSupportConversationForGiftDetailsMutation, StartSupportConversationForGiftDetailsMutationVariables>(StartSupportConversationForGiftDetailsDocument, options);
      }
export type StartSupportConversationForGiftDetailsMutationHookResult = ReturnType<typeof useStartSupportConversationForGiftDetailsMutation>;
export type StartSupportConversationForGiftDetailsMutationResult = Apollo.MutationResult<StartSupportConversationForGiftDetailsMutation>;
export type StartSupportConversationForGiftDetailsMutationOptions = Apollo.BaseMutationOptions<StartSupportConversationForGiftDetailsMutation, StartSupportConversationForGiftDetailsMutationVariables>;