import numeral from 'numeral'
import isAfter from 'date-fns/isAfter'
import { MonetaryAmountFragment } from '#src/app/fragments/MonetaryAmount.generated'
import {
  ExpertCuratedItemForFormatExpertCuratedItemPricingFragment,
  ExpertCuratedItemForGetExpertCuratedItemNotesFragment,
  ExpertCuratedItemForIsSpecialOfferExpiredFragment
} from './expertCuratedItem.generated'

export const getIsSpecialOfferExpired = ({
  specialOfferValidUntil
}: ExpertCuratedItemForIsSpecialOfferExpiredFragment): boolean =>
  !!specialOfferValidUntil && isAfter(new Date(), new Date(specialOfferValidUntil))

export interface ExpertCuratedItemPricing {
  price: string
  customizations?: string
  totalPrice: string
  msrp?: string
  savings?: string
  savingsPercentage?: string
}

interface FormatExpertCuratedItemPricingInput {
  curatedItem: ExpertCuratedItemForFormatExpertCuratedItemPricingFragment
  bundleOriginalPrice?: MonetaryAmountFragment
  formattingOptions?: {
    percentSavingsDisplayThreshold?: number
  }
}

export const formatExpertCuratedItemPricing = ({
  curatedItem,
  bundleOriginalPrice,
  formattingOptions
}: FormatExpertCuratedItemPricingInput): ExpertCuratedItemPricing | null => {
  const { customizations, priceOverride, salePrice, sellable, salePriceWithCustomizations } =
    curatedItem
  const customizationsPrice = customizations.reduce(
    (acc, customization) => acc + parseFloat(customization.price.amount),
    0
  )
  const sellablePrice = priceOverride?.amount || salePrice?.amount || sellable.salePrice?.amount
  if (!sellablePrice) {
    return null
  }

  const totalPrice = numeral(salePriceWithCustomizations?.amount).format('$0,0.00')
  const msrp = bundleOriginalPrice?.amount || sellable.originalPrice?.amount || undefined
  const savings = msrp ? parseFloat(msrp) - parseFloat(sellablePrice) : undefined
  const savingsPercentage = msrp && savings ? savings / parseFloat(msrp) : undefined
  const displaySavings =
    savingsPercentage &&
    savingsPercentage * 100 > (formattingOptions?.percentSavingsDisplayThreshold || 0)

  return {
    price: numeral(sellablePrice).format('$0,0.00'),
    customizations: customizations.length
      ? numeral(customizationsPrice).format('$0,0.00')
      : undefined,
    totalPrice,
    msrp: msrp && displaySavings ? numeral(msrp).format('$0,0.00') : undefined,
    savings: savings && displaySavings ? numeral(savings).format('$0,0.00') : undefined,
    savingsPercentage:
      savingsPercentage && displaySavings ? `${Math.round(savingsPercentage * 100)}` : undefined
  }
}

export const getExpertCuratedItemNotes = (
  item: ExpertCuratedItemForGetExpertCuratedItemNotesFragment
) => {
  // TODO: the alternative here, IMO, is not better
  // this entire data model needs to be simplified.
  const noteValue = item.note?.attributes
    .find(note => note.schema.name === 'expertNoteFitForConsumer')
    ?.values.at(0)
  const notes = noteValue?.__typename === 'AttributeValueText' && noteValue.text
  const recommendedForValue = item.note?.attributes
    .find(note => note.schema.name === 'expertNoteGoodFor')
    ?.values.at(0)
  const recommendedFor =
    recommendedForValue?.__typename === 'AttributeValueText' && recommendedForValue.text
  const notRecommendedForValue = item.note?.attributes
    .find(note => note.schema.name === 'expertNoteNotGoodFor')
    ?.values.at(0)
  const notRecommendedFor =
    notRecommendedForValue?.__typename === 'AttributeValueText' && notRecommendedForValue.text

  return notes || recommendedFor || notRecommendedFor
    ? {
        notes: notes || undefined,
        recommendedFor: recommendedFor || undefined,
        notRecommendedFor: notRecommendedFor || undefined
      }
    : undefined
}
