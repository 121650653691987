import React, { useEffect } from 'react'
import loadable from '@loadable/component'
import { loginLocation } from '#src/app/services/routing'
import { useDepartment } from '#src/app/context/department'
import Redirect from '#src/app/components/Redirect'
import Page from '#src/app/components/Page'
import { useMarkFeedNotificationsAsSeenMutation } from './MarkFeedFeedNotificationsAsSeen.generated'
import { useFeedNotificationPageQuery } from './FeedNotificationPage.generated'
import { useFeedIdentities } from '../hooks/useFeedAuthor'
import LoadingComponent from './loading'

const PageComponent = loadable(
  () => import(/* webpackChunkName: "feed-notification" */ './page/index'),
  {
    resolveComponent: components => components.FeedNotificationPage
  }
)

interface FeedNotificationPageProps {}

const FeedNotificationPage: React.FC<FeedNotificationPageProps> = () => {
  const department = useDepartment()
  const feedIdentities = useFeedIdentities()
  const isLoggedOut = feedIdentities.length === 0

  const query = useFeedNotificationPageQuery({
    variables: {
      recipientIds: feedIdentities
    },
    skip: isLoggedOut
  })

  const [markNotificationsAsSeen] = useMarkFeedNotificationsAsSeenMutation({
    variables: { recipientIds: feedIdentities }
  })

  useEffect(() => {
    if (!isLoggedOut) {
      markNotificationsAsSeen()
    }
  }, [markNotificationsAsSeen, isLoggedOut])

  if (isLoggedOut) {
    return <Redirect to={loginLocation('/for-you/notifications')} />
  }

  return (
    <Page
      query={query}
      pageKey="for-you-feed-notifications"
      chat={true}
      seoTitle="Feed Notifications"
      seoDescription="For You Feed Notifications"
      seoIndexable={false}
      category={() => undefined}
      department={() => department ?? undefined}
      expert={() => undefined}
      sellable={() => undefined}
      pageComponent={PageComponent}
      loadingComponent={LoadingComponent}
      ogImageUrl={() => undefined}
      canonicalPath={() => undefined}
    />
  )
}

export default FeedNotificationPage
