import { ConsumerTracking, Event, ViewerContext } from '@deal/web-tracking'

export interface ProductsSearchResultsViewedEventProperties {
  query: ConsumerTracking.SearchQuery
  total_results_count: number
  visible_results_count: number
  entry_point?: string
  list_id?: string
}

export class ProductsSearchResultsViewedEvent extends Event<ProductsSearchResultsViewedEventProperties> {
  static displayName = 'Products Search Results Viewed'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
