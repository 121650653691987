import { useLocation } from '@deal/router'
import { useCookieContext } from '../context/cookies'

export default function usePreferredExpert(): string | undefined {
  const cookies = useCookieContext()
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const preferredExpertVanityIdFromURL = queryParams.get('e')
  const preferredExpertVanityIdFromCookie = cookies.getCookie('preferred_expert')

  return preferredExpertVanityIdFromURL || preferredExpertVanityIdFromCookie
}
