import React from 'react'
import { Skeleton } from '#src/app/components/SkeletonLoader'
import { ColumnActions } from '../ColumnActions'
import styles from './styles.css'

export const ProductCardsComparison: React.FC = () => {
  return (
    <tr className={styles.compareTableRow}>
      <th className={styles.compareTableHeaderEmpty} />
      {new Array(4).fill(null).map((_, idx) => (
        <th scope="col" key={idx} className={styles.compareTableHeader}>
          <ColumnActions isPinned={idx === 0} />
          <div className={styles.productCard}>
            <div className={styles.square}>
              <Skeleton width="100%" height="100%" />
            </div>
            <div className={styles.productCardCopy}>
              <Skeleton width="100%" height="40px" />
              <Skeleton width="33%" height="20px" />
            </div>
            <Skeleton width="100%" height="44px" />
          </div>
        </th>
      ))}
    </tr>
  )
}
